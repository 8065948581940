import { useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Styled from "./styles";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import intlMessages from "../../locales/en";
import { Box, Checkbox } from "@mui/material";
import { validateEmail, validateInput } from "../sign-up/service";
import { deleteUserAccount, initiateDelete } from "../user-profile/service";
import { useNavigate } from "react-router-dom";
import { userLogOut } from "../../common/sign_in_service";
import { Categories, Events, logEvent } from "../../analytics";
import { getNavigateURL } from "../../util/signin-user-data";
import { deleteAccount } from "../../api/user-profile/methods";

function DeleteAccountModal(props) {
  const {
    isOpen,
    onClose,
    userEmail,
    OpenConnectionModal,
    errorMsg,
    alertBox,
    isMobile,
    isMobileLandscape,
    isIpad,
    isIpadLandscape,
  } = props;
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [deleteRemarks, setDeleteRemarks] = useState([]);
  const [checkboxStates, setCheckboxStates] = useState({
    checkbox1: false,
    checkbox2: false,
    checkbox3: false,
    checkbox4: false,
    checkbox5: false,
  });
  const navigate = useNavigate();

  const emailValidation = () => {
    if (email.length === 0) {
      return setError("Email cannot be empty");
    }
    if (!validateEmail(email)) {
      return setError(intlMessages.SignUpInvalidEmailError);
    }
    if (email !== userEmail) {
      return setError(intlMessages.deleteAccountEmailError);
    }
    return true;
  };

  const handleCheckBoxClick = (value, key) => {
    logEvent(
      Categories.user_management,
      Events.delete_account_modal_reason_selected
    );
    setCheckboxStates((prev) => ({ ...prev, [key]: !prev[key] }));
    if (checkboxStates[key] === true)
      setDeleteRemarks((prev) => prev.filter((element) => element !== value));
    else setDeleteRemarks((prev) => [...prev, value]);
  };

  const handleEmailChange = (e) => {
    const inp = validateInput(e.target.value);
    if (
      inp.length > 0 ||
      error === intlMessages.signUpEmailEmptyMessage ||
      validateEmail(inp)
    ) {
      setError("");
    }
    setEmail(inp);
  };
  const handleClose = () => {
    logEvent(
      Categories.user_management,
      Events.delete_account_modal_close_btn_clicked
    );
    onClose();
  };

  const handleEmailSubmission = async () => {
    logEvent(
      Categories.user_management,
      Events.delete_account_modal_confirm_btn_clicked
    );
    if (emailValidation()) {
      const res = await initiateDelete(email, deleteRemarks, "delete-flag");
      if (res?.data?.statusCode === 0) {
        onClose();
        OpenConnectionModal(true);
        return;
      }
      // if (
      //   res?.response?.data?.statusCode === 401 &&
      //   res?.response?.data?.message === "Unauthorized"
      // ) {
      //   errorMsg({
      //     message: intlMessages.deleteAccountErrorMsg,
      //     icon: "/svgs/erroricon.svg",
      //     isError: true,
      //   });
      //   onClose();
      //   alertBox(true);
      //   return;
      // }
      if (
        res?.status === 201 &&
        res?.data?.message === "Account deletion request has been initiated."
      ) {
        errorMsg({
          message: intlMessages.accountDeletionInitiationText,
          icon: "/svgs/successicon.svg",
          isError: false,
        });
        alertBox(true);
        onClose();
        return;
      }
    }
  };
  return (
    <>
      <Dialog
        componentsProps={{
          backdrop: { style: { backgroundColor: "rgba(0, 0, 0, 0.7)" } },
        }}
        open={isOpen}
      >
        <DialogTitle>
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Styled.deleteHeading>
              {intlMessages.deleteAccountHeading}
            </Styled.deleteHeading>
            <Styled.crossBtn onClick={handleClose}>
              <CloseOutlinedIcon />
            </Styled.crossBtn>
          </Box>
        </DialogTitle>
        <DialogContent
          sx={{
            backgroundColor: "#F8F8F8",
            height: isMobile ? "185px" : "unset",
          }}
        >
          <DialogContentText>
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Styled.deleteQuestion
                isMobileLandscape={isMobileLandscape ? true : false}
                isMobile={isMobile ? true : false}
              >
                {intlMessages.deleteAccountHeading2}
              </Styled.deleteQuestion>
              <Styled.deleteReasonBox>
                <Styled.ReasonContainer>
                  <Checkbox
                    color="secondary"
                    sx={{
                      padding: 0,
                      width: "18px",
                      height: "18px",
                      marginTop: isMobile ? "2px" : "unset",
                    }}
                    icon={
                      <img
                        style={{ marginTop: "5px" }}
                        src={"svgs/uncheck-checkBox.svg"}
                      ></img>
                    }
                    checkedIcon={<img src={"/svgs/Checkbox.svg"}></img>}
                    checked={checkboxStates.checkbox1}
                    onChange={() => {
                      handleCheckBoxClick(
                        intlMessages.deleteAccountReason1,
                        "checkbox1"
                      );
                    }}
                  />
                  <Styled.Reason
                    onClick={() => {
                      handleCheckBoxClick(
                        intlMessages.deleteAccountReason1,
                        "checkbox1"
                      );
                    }}
                  >
                    {intlMessages.deleteAccountReason1}
                  </Styled.Reason>
                </Styled.ReasonContainer>
                <Styled.ReasonContainer>
                  <Checkbox
                    color="secondary"
                    sx={{
                      padding: 0,
                      width: "18px",
                      height: "18px",
                      marginTop: isMobile ? "2px" : "unset",
                    }}
                    icon={
                      <img
                        style={{ marginTop: "5px" }}
                        src={"svgs/uncheck-checkBox.svg"}
                      ></img>
                    }
                    checkedIcon={<img src={"/svgs/Checkbox.svg"}></img>}
                    checked={checkboxStates.checkbox2}
                    onChange={() => {
                      handleCheckBoxClick(
                        intlMessages.deleteAccountReason2,
                        "checkbox2"
                      );
                    }}
                  />
                  <Styled.Reason
                    onClick={() => {
                      handleCheckBoxClick(
                        intlMessages.deleteAccountReason2,
                        "checkbox2"
                      );
                    }}
                  >
                    {intlMessages.deleteAccountReason2}
                  </Styled.Reason>
                </Styled.ReasonContainer>
                <Styled.ReasonContainer>
                  <Checkbox
                    color="secondary"
                    sx={{
                      padding: 0,
                      width: "18px",
                      height: "18px",
                      marginTop: isMobile ? "2px" : "unset",
                    }}
                    icon={
                      <img
                        style={{ marginTop: "5px" }}
                        src={"svgs/uncheck-checkBox.svg"}
                      ></img>
                    }
                    checkedIcon={<img src={"/svgs/Checkbox.svg"}></img>}
                    checked={checkboxStates.checkbox3}
                    onChange={() => {
                      handleCheckBoxClick(
                        intlMessages.deleteAccountReason3,
                        "checkbox3"
                      );
                    }}
                  />
                  <Styled.Reason
                    onClick={() => {
                      handleCheckBoxClick(
                        intlMessages.deleteAccountReason3,
                        "checkbox3"
                      );
                    }}
                  >
                    {intlMessages.deleteAccountReason3}
                  </Styled.Reason>
                </Styled.ReasonContainer>
                <Styled.ReasonContainer>
                  <Checkbox
                    color="secondary"
                    sx={{
                      padding: 0,
                      width: "18px",
                      height: "18px",
                      marginTop: isMobile ? "2px" : "unset",
                    }}
                    icon={
                      <img
                        style={{ marginTop: "5px" }}
                        src={"svgs/uncheck-checkBox.svg"}
                      ></img>
                    }
                    checkedIcon={<img src={"/svgs/Checkbox.svg"}></img>}
                    checked={checkboxStates.checkbox4}
                    onChange={() => {
                      handleCheckBoxClick(
                        intlMessages.deleteAccountReason4,
                        "checkbox4"
                      );
                    }}
                  />
                  <Styled.Reason
                    onClick={() => {
                      handleCheckBoxClick(
                        intlMessages.deleteAccountReason4,
                        "checkbox4"
                      );
                    }}
                  >
                    {intlMessages.deleteAccountReason4}
                  </Styled.Reason>
                </Styled.ReasonContainer>
                <Styled.ReasonContainer sx={{ mb: "20px" }}>
                  <Checkbox
                    color="secondary"
                    sx={{
                      padding: 0,
                      width: "18px",
                      height: "18px",
                      marginTop: isMobile ? "2px" : "unset",
                    }}
                    icon={
                      <img
                        style={{ marginTop: "5px" }}
                        src={"svgs/uncheck-checkBox.svg"}
                      ></img>
                    }
                    checkedIcon={<img src={"/svgs/Checkbox.svg"}></img>}
                    checked={checkboxStates.checkbox5}
                    onChange={() => {
                      handleCheckBoxClick(
                        intlMessages.deleteAccountReason5,
                        "checkbox5"
                      );
                    }}
                  />
                  <Styled.Reason
                    onClick={() => {
                      handleCheckBoxClick(
                        intlMessages.deleteAccountReason5,
                        "checkbox5"
                      );
                    }}
                  >
                    {intlMessages.deleteAccountReason5}
                  </Styled.Reason>
                </Styled.ReasonContainer>
              </Styled.deleteReasonBox>
              <Styled.StyledDivider></Styled.StyledDivider>
              <Styled.deleteDesc
                isMobileLandscape={isMobileLandscape ? true : false}
                isMobile={isMobile ? true : false}
              >
                {intlMessages.deleteAccountDesc}
              </Styled.deleteDesc>
              <Styled.largeTextField
                isMobileLandscape={isMobileLandscape ? true : false}
                isMobile={isMobile ? true : false}
                placeholder={intlMessages.deleteAccountEmailPlaceholder}
                size="small"
                variant="outlined"
                error={error ? true : false}
                helperText={error}
                onChange={handleEmailChange}
              ></Styled.largeTextField>
            </Box>
          </DialogContentText>
        </DialogContent>
        <DialogActions
          sx={{
            backgroundColor: "#F8F8F8",
            borderTop: "1px solid rgba(0, 0, 0, 0.2)",
            width: isMobile ? "300px" : "470px",
            height: isMobileLandscape ? "70px" : "80px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              width: isMobile ? "300px" : "470px",
            }}
          >
            <Styled.confirmBtn onClick={handleEmailSubmission}>
              {intlMessages.deleteAccountConfirmBtnText}
            </Styled.confirmBtn>
            <Styled.closeBtn onClick={handleClose}>
              {intlMessages.deleteAccountCancelBtnText}
            </Styled.closeBtn>
          </Box>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default DeleteAccountModal;
