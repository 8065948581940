import { useState, useEffect, useRef } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { CircularProgress, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Styled from "./styles";
import intlMessages from "../../locales/en";
import { Style } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import { setOpenPricingModal } from "../../redux/slices/featuresSlice";

const ImportFile = (props) => {
  const {
    openImportModal,
    closeImportFileModal,
    isMobile,
    selectedTypeCallback,
    isMobileLandscape,
  } = props;

  const { driveEnabled, googleClientId, googleApiKey } = useSelector(
    (state) => state.quiz
  );
  const dispatch = useDispatch()
  const {canImportFromGoogleDrive,canCaptureImage} = useSelector((state)=>state.feature)
  const { userData,stripeStatus } = useSelector(
    (state) => state.logIn
  );
  const [isSaving, setIsSaving] = useState(false);
  return (
    <>
      <Modal
        open={openImportModal}
        aria-labelledby="review-modal"
        aria-describedby="review-description"
      >
        <Styled.ModalPrmissionSignInBox sx={Styled.customStyling.exportModal}>
          <Styled.ModalHeader>
            <Typography
              id="review-modal-title"
              variant="h5"
              component="h5"
              style={{ fontWeight: "bold" }}
            >
              {intlMessages.importModalHeader}
            </Typography>
            <IconButton
              aria-label="closeReviewModal"
              onClick={() => {
                closeImportFileModal();
              }}
            >
              <CloseIcon color="seondary.light" />
            </IconButton>
          </Styled.ModalHeader>
          <Styled.ModalContentBox>
            <Styled.ContainerBox
              driveEnabled={driveEnabled}
              googleClientId={googleClientId}
              googleApiKey={googleApiKey}
              isMobile={isMobile}
            >
              <Box
                sx={{
                  border:
                    driveEnabled && !!googleClientId && !!googleApiKey
                      ? "1px dashed rgba(51, 51, 51, 0.20)"
                      : "unset",
                  borderRadius: "8px",
                  height:
                    driveEnabled && !!googleClientId && !!googleApiKey
                      ? stripeStatus===12 || userData===null?"220px":"180px"
                      : "unset",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Box
                  sx={{
                    display:
                      driveEnabled && !!googleClientId && !!googleApiKey
                        ? "flex"
                        : "none",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    opacity: isSaving ? 0.5 : 1,
                    pointerEvents: isSaving ? "none" : "auto",
                  }}
                  onClick={() => {}}
                >
                  <Styled.ContinueGoogleBtn
                    isMobile={isMobile}
                    onClick={() => {
                      selectedTypeCallback("drive");
                    }}
                  >
                    {isSaving && (
                      <CircularProgress
                        color="inherit"
                        sx={{ marginRight: "10px" }}
                        size={16}
                      />
                    )}
                    <img
                      style={{ height: 24, marginRight: "3px" }}
                      src={"/svgs/g-drive.svg"}
                    />
                    <Styled.TextTypography variant="body1">
                      {intlMessages.importModalDriveText}
                    </Styled.TextTypography>
                    {canImportFromGoogleDrive.enable===false && <img style={{position:'absolute',right:10}} src="/svgs/dark-grey-crown.svg"></img>}
                  </Styled.ContinueGoogleBtn>
                </Box>
                <Styled.CustomDivider
                  orientation="horizontal"
                  sx={{
                    display:
                      driveEnabled && !!googleClientId && !!googleApiKey
                        ? ""
                        : "none",
                  }}
                >
                  <span style={{ height: "10px" }}>
                    {intlMessages.signUpOrText}
                  </span>
                </Styled.CustomDivider>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    opacity: isSaving ? 0.5 : 1,
                    pointerEvents: isSaving ? "none" : "auto",
                  }}
                  onClick={() => {}}
                >
                  <Styled.ContinueGoogleBtn
                    isMobile={isMobile}
                    onClick={() => selectedTypeCallback("upload")}
                  >
                    {isSaving && (
                      <CircularProgress
                        color="inherit"
                        sx={{ marginRight: "10px" }}
                        size={16}
                      />
                    )}
                    <img
                      style={{ height: 24, marginRight: "3px" }}
                      src={"/svgs/upload-green.svg"}
                    />
                    <Styled.TextTypography
                      variant="body1"
                      style={{
                        color: "#333333",
                      }}
                    >
                      {intlMessages.uploadFileLabel}
                    </Styled.TextTypography>
                  </Styled.ContinueGoogleBtn>
                  <Styled.SupportTextTypography sx={{display:stripeStatus===12 || userData===null?"block":"none",width: isMobile ? "230px" : "230px",}} variant="body1">
                  {intlMessages.importModalSupportedFiles1}<span onClick={() => {
                            dispatch(setOpenPricingModal(true));
                          }} style={{marginLeft:"2px",cursor:'pointer',color: "#2062BB"}}>{intlMessages.importModalSupportedFiles2}</span>{intlMessages.importModalSupportedFiles3}
                </Styled.SupportTextTypography>
                </Box>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  width: "100%",
                  mb: "10px",
                }}
              >
                <Styled.SupportTextTypography variant="body1" sx={{display:stripeStatus===12 || userData===null?"none":"block"}}>
                  {intlMessages.importModalSupportedFiles}
                </Styled.SupportTextTypography>
              </Box>
              <Styled.CustomDivider
                orientation="horizontal"
                sx={{
                  display:
                    driveEnabled && !!googleClientId && !!googleApiKey
                      ? "none"
                      : "flex",
                }}
              >
                <span style={{ height: "10px" }}>
                  {intlMessages.signUpOrText}
                </span>
              </Styled.CustomDivider>
              <Box sx={{ display: "flex", justifyContent: "center" }}>
                <Styled.ContinueGoogleBtn
                  isMobile={isMobile}
                  onClick={() => selectedTypeCallback("camera")}
                >
                  {isSaving && (
                    <CircularProgress
                      color="inherit"
                      sx={{ marginRight: "10px" }}
                      size={16}
                    />
                  )}
                  <img
                    style={{ height: 24, marginRight: "3px" }}
                    src={"/svgs/camera.svg"}
                  />
                  <Styled.TextTypography
                    variant="body1"
                    style={{
                      color: "#333333",
                    }}
                  >
                    {intlMessages.uploadPhotoLabel}
                  </Styled.TextTypography>
                  { canCaptureImage.enable===false && <img style={{position:'absolute',right:10}} src="/svgs/dark-grey-crown.svg"></img>}
                </Styled.ContinueGoogleBtn>
              </Box>

              <Styled.PrivacyPolicyLink
                href="privacy-policies.html"
                target="_blank"
              >
                {intlMessages.loginNewsletterText2?.charAt(0)?.toUpperCase() +
                  intlMessages.loginNewsletterText2?.slice(1)}
              </Styled.PrivacyPolicyLink>
            </Styled.ContainerBox>
          </Styled.ModalContentBox>
        </Styled.ModalPrmissionSignInBox>
      </Modal>
    </>
  );
};

export default ImportFile;
