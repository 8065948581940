import { checkInternetConnection } from "../../api/service";
import { setUserData, getUserData, updateUserData } from "../user/service";

const validateInput = (i) => {
  let _input = i;
  // if (/^\s/.test(_input)) _input = "";
  return _input;
};

function validateEmail(inputText) {
  const mailformat = /^[a-zA-Z0-9]+([._%+-][a-zA-Z0-9]+)*@[a-zA-Z0-9]+([.-][a-zA-Z0-9]+)*\.[a-zA-Z]{2,}$/;
  return inputText.match(mailformat);
}

const getInternetConnectionStatus = async () => {
  const connectionStatus = await checkInternetConnection();
  return connectionStatus;
};

export {
  validateInput,
  validateEmail,
  getInternetConnectionStatus,
  setUserData,
  getUserData,
  updateUserData,
};
