import styled from "@mui/system/styled";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { Button, Divider, Link, Typography } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";

const ModalHeader = styled(Box)(({ theme, isSignedIn }) => ({
  padding: 8,
  paddingRight: 12,
  boxShadow: "rgba(100, 100, 111, 0.2) 0px 3px 29px 0px",
  paddingLeft: 25,
  alignItems: "center",
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
}));

const ModalActions = styled(Box)({
  padding: 5,
  paddingTop: 25,
  paddingBottom: 15,
  borderTop: "1px solid silver",
  display: "flex",
  justifyContent: "center",
});

const SubmitButton = styled(Button)(({ theme, isEditModal }) => ({
  color: theme.palette.primary.contrastText,
  textTransform: "none",
  width: isEditModal ? "150px" : "105px",
}));

const ModalTextField = styled(TextField)(({ theme }) => ({
  borderRadius: 3,
  // background: "#F6F6F6",
  "& .MuiInputBase-root": {
    // background: "#F6F6F6",
    fontSize: "14px",
    color: "#000000",
  },
  "& input::placeholder": {
    color: "#000000",
    fontSize: "14px",
    // paddingLeft: "8px",
  },
  "& .MuiFormHelperText-root.Mui-error": {
    position: "absolute",
    top: "100%",
  },
  // padding:5,

  ".MuiOutlinedInput-input": {
    padding: 7,
    paddingLeft: "18px",
  },
  width: "100%",
  [theme.breakpoints.down("md")]: {},
}));

const ModalGoogleurlTextField = styled(TextField)(({ theme }) => ({
  background: "#F6F6F6",
  "& .MuiInputBase-root": {
    background: "#F6F6F6",
  },
  "& input::placeholder": {
    color: "#000000",
    // opacity: 1,
  },
  "& .MuiFormHelperText-root.Mui-error": {
    position: "absolute",
    top: "100%",
  },
  borderRadius: 3,
  // padding:5,
  ".MuiOutlinedInput-root": {
    padding: "2px 5px",
  },

  ".MuiOutlinedInput-input": {
    padding: 0,
  },
  width: "100%",
  [theme.breakpoints.down("md")]: {},
}));

const ModalBox = styled(Box)(({ theme }) => ({
  width: "30%",
  [theme.breakpoints.down("lg")]: {
    width: "60%",
  },
  [theme.breakpoints.down("sm")]: {
    width: "95%",
  },
}));

const ModalSignInBox = styled(Box)(({ theme }) => ({
  width: "38%",
  [theme.breakpoints.down("lg")]: {
    width: "70%",
  },
  [theme.breakpoints.down("sm")]: {
    width: "95%",
  },
}));

const ModalPrmissionSignInBox = styled(Box)(({ theme }) => ({
  width: "470px",
  [theme.breakpoints.down("lg")]: {
    width: "470px",
  },
  [theme.breakpoints.down("sm")]: {
    width: "95%",
  },
}));

const ModalContentBox = styled(Box)({
  marginTop: 30,
  marginLeft: 25,
  marginRight: 25,
  marginBottom: 30,
  display: "flex",
  justifyContent: "flex-start",
});

const ModalGoogleContentBox = styled(Box)({
  marginTop: 15,
  marginLeft: 20,
  marginRight: 20,
  marginBottom: 61,
});

const CreateQuizButton = styled(LoadingButton)(({ theme, isSaving }) => ({
  color: theme.palette.primary.contrastText,
  textTransform: "none",
  border: "none",
  "&:hover": {
    border: "none",
  },
}));

const ContinueGoogleBtn = styled(Button)(
  ({ theme, isMobileLandscape, isMobile, isIpad, isIpadLandscape }) => ({
    width: isMobile ? "260px" : "410px",
    height: "54px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    background: "#FFFFFF",
    border: "1px solid #7DC242",
    borderRadius: "5px",
    textTransform: "none",
  })
);

const TextTypography = styled(Typography)(({ theme }) => ({
  fontSize: "14px",
  color: "#333",
  //   marginLeft: "15px",
  fontWeight: 400,
  marginBottom: "5px",
}));

const SupportTextTypography = styled(Typography)(({ theme }) => ({
  // width: "100%",
  color: "#000",
  fontSize: "9.232px",
  fontStyle: "normal",
  fontWeight: 400,
  lineHeight: "normal",
  marginTop: "5px",
}));

const PrivacyPolicyLink = styled(Link)(({ theme }) => ({
  // width: "100%",
  cursor: "pointer",
  textDecoration: "none",
  color: "#5e9beb",
  fontSize: "9.232px",
  fontStyle: "normal",
  fontWeight: 400,
  lineHeight: "normal",
  marginTop: "5px",
  marginRight: "2px",
}));

const ErrorText = styled(Typography)(({ theme }) => ({
  color: theme.palette.warning.main,
  fontSize: 12,
  padding: 2,
  display: "flex",
  textAlign: "flex-start",
}));

const CustomDivider = styled(Divider)(({ theme }) => ({
  width: "100%",
  marginBottom: "25px",
  color: "#33333380",
  marginTop: "25px",
  textTransform: "uppercase",
  "&::before": {
    borderColor: "#C3C3C3", // Change this to the desired divider line color
  },
}));

const ContainerBox = styled(Box)(({ theme, isMobile }) => ({
  width: isMobile ? "100%" : "410px",
}));

const BoxComponent = styled(Box)(({ theme, isMobile }) => ({
  marginTop: "20px",
}));

const customStyling = {
  exportModal: {
    position: "absolute",
    top: { xs: "45%", sm: "50%" },
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    borderRadius: 2,
    boxShadow: 24,
    overflowY: "auto",
    maxHeight: "90%",
    border: "none",
  },
  button: {
    width: "392px",
    height: "54px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    background: "#EBF5FA",
    border: "1px solid #A7D5EC",
    borderRadius: "4px",
    textTransform: "none",
  },
};

export default {
  CreateQuizButton,
  ModalHeader,
  ModalActions,
  ModalTextField,
  ModalBox,
  ModalContentBox,
  customStyling,
  ErrorText,
  ModalSignInBox,
  ModalGoogleContentBox,
  ModalGoogleurlTextField,
  ModalPrmissionSignInBox,
  ContinueGoogleBtn,
  CustomDivider,
  ContainerBox,
  TextTypography,
  SupportTextTypography,
  PrivacyPolicyLink,
  SubmitButton,
  BoxComponent,
};
