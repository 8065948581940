import { useState, useEffect } from "react";
import intlMessages from "../../locales/en";
import {
  Typography,
  IconButton,
  Modal,
  Box,
  CircularProgress,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Styled from "./styles";
import DoneIcon from "@mui/icons-material/Done";
import DataGridTable from "../../common/data-grid-table/component";
import { useTheme } from "@mui/material/styles";
import {
  cancelPlanDowngrade,
  getDatagridColumns,
  getDateAndTime,
  getHistory,
} from "./service";
import DowngradModal from "../../common/downgrade-modal/component";
import { useDispatch, useSelector } from "react-redux";
import {
  setOpenPaymentModal,
  setOpenPricingModal,
  setOpenSubscriptionModal,
} from "../../redux/slices/featuresSlice";
import ConnectionModal from "../connection-modal/component";
import { getCheckoutUrl, getPriceDetails } from "../../api/payment/methods";
import TrialInfoModal from "../../common/trial-info-modal/component";
import AlertBox from "../alert/component";
import {
  setCurrentlySelectedTab,
  setIsCardBtnClicked,
  setIsScreenPanelBtnClicked,
  setOpenErrorBox,
} from "../../redux/slices/paymentSlice";
import { Categories, Events, logEvent } from "../../analytics";
import { setStripeStatus } from "../../redux/slices/logInSlice";
export default function SubscriptionModal(props) {
  const { userData, stripeStatus } = useSelector((state) => state.logIn);
  const { openErrorBox, currentlySelectedTab } = useSelector(
    (state) => state.payment
  );
  const { quizInputText, quizData } = useSelector((state) => state.quiz);
  const {
    open,
    onClose,
    isMobile,
    isMobileLandscape,
    isIpad,
    isIpadLandscape,
    isTl,
  } = props;
  const [tabSelected, setTabSelected] = useState(currentlySelectedTab);
  const [downgradeModalState, setDowngradeModalStat] = useState(false);
  const theme = useTheme();
  const [currentPage, setPageNumber] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [size, setSize] = useState();
  const dispatch = useDispatch();
  const [billingList, setBillingList] = useState([]);
  const [openConnectionModal, setIsOpenConnectionModal] = useState(false);
  const [openTrialModal, setOpenTrialModal] = useState(false);
  const [trialModalText, setTrialModalText] = useState({
    heading: "",
    desc: "",
  });
  const [isSaving, setIsSaving] = useState(false);

  const handleOnClose = () => {
    logEvent(
      Categories.pricing_and_subscription_modal,
      Events.pricing_and_subscription_modal_close_btn_clicked
    );
    onClose(false);
    dispatch(setCurrentlySelectedTab(0));
  };

  const handleDowngradeBtnClick = () => {
    if (userData?.stripeStatus === 13) cancelDowngrade();
    else toggleDowngradeModalState(true);
  };

  const toggleDowngradeModalState = (flag) => {
    if (flag === true)
      logEvent(
        Categories.pricing_and_subscription_modal,
        Events.pricing_and_subscription_modal_downgrade_btn_clicked
      );
    setDowngradeModalStat(flag);
  };

  const updatePaymentMethod = async () => {
    setIsSaving(true);
    logEvent(
      Categories.pricing_and_subscription_modal,
      Events.payment_details_tab_update_btn_clicked
    );
    let quizDataExists = false;
    if (quizInputText.length !== 0) {
      localStorage.setItem("quizContent", quizInputText);
      localStorage.setItem("quizData", JSON.stringify(quizData));
      quizDataExists = true;
    }
    const url = await getCheckoutUrl(userData?.pricePlan, quizDataExists);
    setIsSaving(false);
    window.location.href = url?.data?.checkoutUrl || "#";
  };

  const cancelDowngrade = async () => {
    logEvent(
      Categories.pricing_and_subscription_modal,
      Events.pricing_and_subscription_modal_cancel_downgrade_btn_clicked
    );
    const res = await cancelPlanDowngrade();
    if (res?.data?.statusCode === 0) {
      setIsOpenConnectionModal(true);
      return;
    }
    if (
      res?.status === 201 &&
      res?.data.message === "subscription resumed successfully"
    ) {
      setTrialModalText({
        desc: intlMessages.cancelDowngradeRequestMessage,
      });
      setOpenTrialModal(true);
      dispatch(setStripeStatus(1));
      return;
    }
  };

  const getBillingRecords = async () => {
    const res = await getHistory(currentPage, size);
    if (res?.data?.statusCode === 0) {
      setIsOpenConnectionModal(true);
      return;
    }
    if (res.status === 200) {
      setBillingList(res.data.data);
      setTotalPages(res.data.totalPages);
      // setSize(res.data.totalCount);
      return;
    }
  };

  useEffect(() => {
    if (isMobile || isMobileLandscape) {
      setTabSelected(currentlySelectedTab);
    }
  }, [currentlySelectedTab]);

  useEffect(() => {
    if (tabSelected === 2) getBillingRecords();
  }, [currentPage, tabSelected]);

  const handlePageDataChange = (selectedPage) => {
    logEvent(
      Categories.pricing_and_subscription_modal,
      Events.billing_history_pagination_btn_clicked
    );
    if (selectedPage === currentPage) return;
    setPageNumber(selectedPage);
  };

  const handleSubscriptionTabSelected = () => {
    logEvent(
      Categories.pricing_and_subscription_modal,
      Events.subscription_details_tab_selected
    );
    dispatch(setCurrentlySelectedTab(0));
    setTabSelected(0);
  };
  const handlePaymentTabSelected = () => {
    logEvent(
      Categories.pricing_and_subscription_modal,
      Events.payment_details_tab_selected
    );
    dispatch(setCurrentlySelectedTab(1));
    setTabSelected(1);
  };
  const handleHistoryTabSelected = () => {
    logEvent(
      Categories.pricing_and_subscription_modal,
      Events.billing_history_tab_selected
    );
    dispatch(setCurrentlySelectedTab(2));
    setTabSelected(2);
  };

  return (
    <>
      <Modal open={open}>
        <Styled.ModalBox sx={Styled.customStyling.exportModal}>
          <Styled.ModalHeader>
            <Typography
              id="warning-paragraph-modal-title"
              variant="h5"
              component="h5"
              style={{ fontWeight: "bold" }}
            >
              {intlMessages.subscriptionLabel}
            </Typography>
            <IconButton
              sx={{ position: "sticky", top: 0, zIndex: 1 }}
              aria-label="closeWarningParagraphModal"
              onClick={handleOnClose}
            >
              <CloseIcon color="seondary.light" />
            </IconButton>
          </Styled.ModalHeader>
          <Box
            sx={{
              overflow: "auto",
              height: isMobileLandscape ? "200px" : "unset",
            }}
          >
            <Styled.ModalContentBox
              isMobile={isMobile ? true : false}
              isMobileLandscape={isMobileLandscape ? true : false}
              isIpad={isIpad ? true : false}
              isIpadLandscape={isIpadLandscape ? true : false}
              selectedTab={tabSelected === 2 ? true : false}
            >
              <Styled.MobileNavigationContainer
                isMobile={isMobile ? true : false}
                isMobileLandscape={isMobileLandscape ? true : false}
                isIpad={isIpad ? true : false}
                isIpadLandscape={isIpadLandscape ? true : false}
              >
                <Styled.MobileNavButton
                  onClick={handleSubscriptionTabSelected}
                  selectedTab={tabSelected === 0 ? true : false}
                >
                  {intlMessages.subscriptionModalSubscriptionLabel}
                </Styled.MobileNavButton>
                <Styled.MobileNavButton
                  onClick={handlePaymentTabSelected}
                  selectedTab={tabSelected === 1 ? true : false}
                >
                  {intlMessages.subscriptionModalPaymentLabel}
                </Styled.MobileNavButton>
                <Styled.MobileNavButton
                  onClick={handleHistoryTabSelected}
                  selectedTab={tabSelected === 2 ? true : false}
                >
                  {intlMessages.subscriptionModalHistoryLabel}
                </Styled.MobileNavButton>
              </Styled.MobileNavigationContainer>
              <Styled.NavigationBar
                isMobile={isMobile ? true : false}
                isMobileLandscape={isMobileLandscape ? true : false}
                isIpad={isIpad ? true : false}
                isIpadLandscape={isIpadLandscape ? true : false}
              >
                <Styled.NavLabel
                  selectedTab={tabSelected === 0 ? true : false}
                  onClick={handleSubscriptionTabSelected}
                >
                  {intlMessages.subscriptionModalSubscriptionLabel}
                </Styled.NavLabel>
                <Styled.NavLabel
                  selectedTab={tabSelected === 1 ? true : false}
                  onClick={handlePaymentTabSelected}
                >
                  {intlMessages.subscriptionModalPaymentLabel}
                </Styled.NavLabel>
                <Styled.NavLabel
                  selectedTab={tabSelected === 2 ? true : false}
                  onClick={handleHistoryTabSelected}
                >
                  {intlMessages.subscriptionModalHistoryLabel}
                </Styled.NavLabel>
              </Styled.NavigationBar>
              <Styled.NavIcon
                isMobile={isMobile ? true : false}
                isMobileLandscape={isMobileLandscape ? true : false}
                isIpad={isIpad ? true : false}
                isIpadLandscape={isIpadLandscape ? true : false}
                selectedTab={tabSelected}
                src="svgs/black-bottom-arrow.svg"
              ></Styled.NavIcon>
              <Styled.SubscriptionDetailsContainer
                isMobile={isMobile ? true : false}
                isMobileLandscape={isMobileLandscape ? true : false}
                isIpad={isIpad ? true : false}
                isIpadLandscape={isIpadLandscape ? true : false}
                selectedTab={tabSelected === 0 ? true : false}
              >
                <Styled.SubscriptionCurrentPlanBox
                  isMobile={isMobile ? true : false}
                  isMobileLandscape={isMobileLandscape ? true : false}
                  isIpad={isIpad ? true : false}
                  isIpadLandscape={isIpadLandscape ? true : false}
                >
                  <Styled.SubscriptionCurrentPlanText>
                    {intlMessages.subscriptionModalCurrentPlanLabel}
                  </Styled.SubscriptionCurrentPlanText>
                  <Styled.SubscriptionDescText>
                    {userData?.isScreenSubscription === true
                      ? intlMessages.subscriptionModalIPSText
                      : userData?.subscriptionPlan === null
                      ? "-"
                      : userData?.stripeStatus === 0
                      ? intlMessages.subscriptionModalTrialText.replace(
                          "#",
                          userData?.trialDays
                        )
                      : userData?.pricePlan === "Monthly"
                      ? userData?.subscriptionPlan === "Basic"
                        ? intlMessages.subscriptionModalBasicMonthlyLabel
                        : intlMessages.subscriptionModalProMonthlyLabel
                      : userData?.pricePlan === "Yearly" &&
                        userData?.subscriptionPlan === "Basic"
                      ? intlMessages.subscriptionModalBasicYearlyLabel
                      : userData?.pricePlan === null
                      ? intlMessages.subscriptionModalBasicMonthlyLabel
                      : intlMessages.subscriptionModalProYearlyLabel}
                  </Styled.SubscriptionDescText>
                  <Styled.SubscriptionDescText
                    sx={{
                      display:
                        userData?.isScreenSubscription === true
                          ? "block"
                          : "none",
                    }}
                  >
                    {intlMessages.subscriptionModalSerialNoText.replace(
                      "#",
                      userData?.serialNumber
                    )}
                  </Styled.SubscriptionDescText>
                  <Styled.SubscriptionDescText>
                    {userData?.isScreenSubscription === true
                      ? intlMessages.subscriptionModalIPSTrialText
                      : userData?.stripeStatus === 0
                      ? intlMessages.subscriptionModalTrialDaysLeft
                          .replace(
                            "#",
                            userData?.trialRemainingDays !== 0
                              ? userData?.trialRemainingDays
                              : userData?.trialRemainingHours != 0
                              ? userData?.trialRemainingHours
                              : userData?.trialRemainingMinutes
                          )
                          .replace(
                            "#2",
                            userData?.trialRemainingDays !== 0
                              ? intlMessages.daysLeftLabel
                              : userData?.trialRemainingHours != 0
                              ? intlMessages.hoursLeftLabel
                              : intlMessages.minutesLeftLabel
                          )
                      : userData?.pricePlan === "Monthly"
                      ? userData?.subscriptionPlan === "Basic"
                        ? intlMessages.pricingTabPlanCost.replace(
                            "#",
                           userData?.price
                          )
                        : intlMessages.pricingTabPlanCost.replace(
                            "#",
                            userData?.price
                          )
                      : userData?.pricePlan === "Yearly" &&
                        userData?.subscriptionPlan === "Basic"
                      ? intlMessages.pricingTabPlanCost.replace(
                          "#",
                          userData?.price
                        )
                      : userData?.pricePlan === null
                      ? intlMessages.pricingTabPlanCost.replace(
                          "#",
                          userData?.price
                        )
                      : intlMessages.pricingTabPlanCost.replace(
                          "#",
                          userData?.price
                        )}

                    <span
                      style={{
                        marginLeft: "5px",
                        display:
                          userData?.isScreenSubscription === true ||
                          userData?.stripeStatus === 0
                            ? "none"
                            : "inline-block",
                      }}
                    >
                      {userData?.pricePlan === "Yearly"
                        ? intlMessages.subscriptionModalPerYearLabel
                        : intlMessages.subscriptionModalPerMonthLabel}
                    </span>
                  </Styled.SubscriptionDescText>
                </Styled.SubscriptionCurrentPlanBox>
                <Styled.SubscriptionPlanDetailsBox>
                  <Styled.SubscriptionPlanDetailsText>
                    {intlMessages.subscriptionModalPlanDetailsLabel}
                  </Styled.SubscriptionPlanDetailsText>
                  <Styled.StyledDivider></Styled.StyledDivider>
                  <Styled.DateContainer>
                    <Styled.SubscriptionDescText
                      sx={{ width: "120px", mr: "5px" }}
                    >
                      {userData?.stripeStatus === 0 ||
                      userData?.isScreenSubscription === true
                        ? intlMessages.subscriptionModalStartDateText
                        : intlMessages.subscriptionModalPlanPurchaseDateLabel}
                    </Styled.SubscriptionDescText>
                    <Styled.SubscriptionDescText>
                      {userData?.purchacedDate == null &&
                      userData?.stripeStatus !== 0
                        ? "-"
                        : userData?.stripeStatus === 12
                        ? getDateAndTime(userData?.purchacedDate)?.date
                        : getDateAndTime(
                            isNaN(new Date(
                              parseInt(userData?.billingCycleStartDate) * 1000
                            ))?'':new Date(
                              parseInt(userData?.billingCycleStartDate) * 1000
                            ).toISOString()
                          )?.date
                          }
                    </Styled.SubscriptionDescText>
                  </Styled.DateContainer>
                  <Styled.StyledDivider></Styled.StyledDivider>
                  <Styled.DateContainer>
                    <Styled.SubscriptionDescText
                      sx={{ width: "120px", mr: "5px" }}
                    >
                      {userData?.stripeStatus === 0 ||
                      userData?.isScreenSubscription === true
                        ? intlMessages.subscriptionModalEndDateText
                        : intlMessages.subscriptionModalPlanRenewalDateLabel}
                    </Styled.SubscriptionDescText>
                    <Styled.SubscriptionDescText>
                      {userData?.expiryDate == null &&
                      userData?.stripeStatus !== 0
                        ? "-"
                        : getDateAndTime(userData?.expiryDate)?.date}
                    </Styled.SubscriptionDescText>
                  </Styled.DateContainer>
                  <Styled.StyledDivider
                    sx={{
                      display: userData?.stripeStatus === 13 ? "block" : "none",
                    }}
                  ></Styled.StyledDivider>
                  <Styled.SubscriptionDescText
                    sx={{
                      color: "red",
                      display:
                        userData?.stripeStatus === 13 ||
                        userData?.isDeleted === 1
                          ? "block"
                          : "none",
                    }}
                  >
                    {userData?.isDeleted === 1
                      ? intlMessages.cancelDeleteRequestText
                          .replace(
                            "#date",
                            getDateAndTime(userData?.expiryDate)?.date
                          )
                          .replace("#", userData?.trialRemainingDays)
                      : userData?.trialRemainingDays === 0
                      ? intlMessages.cancelDowngradeRequestText2
                          .replace(
                            "#date",
                            getDateAndTime(userData?.expiryDate)?.date
                          )
                          .replace(
                            "#",
                            getDateAndTime(userData?.expiryDate)?.time
                          )
                      : intlMessages.cancelDowngradeRequestText
                          .replace(
                            "#date",
                            getDateAndTime(userData?.expiryDate)?.date
                          )
                          .replace("#", userData?.trialRemainingDays)}
                  </Styled.SubscriptionDescText>
                </Styled.SubscriptionPlanDetailsBox>
              </Styled.SubscriptionDetailsContainer>
              <Styled.PaymentDetailsContainer
                selectedTab={tabSelected === 1 ? true : false}
                isMobile={isMobile ? true : false}
                isMobileLandscape={isMobileLandscape ? true : false}
                isIpad={isIpad ? true : false}
                isIpadLandscape={isIpadLandscape ? true : false}
              >
                {/* {userData?.stripeStatus === 0 && (
                  <Styled.ScreenText>
                    {intlMessages.PaymentDetailsEmptyMsg}
                  </Styled.ScreenText>
                )} */}
                <Styled.PaymentMethodLabel
                  isMobile={isMobile ? true : false}
                  isMobileLandscape={isMobileLandscape ? true : false}
                  isIpad={isIpad ? true : false}
                  isIpadLandscape={isIpadLandscape ? true : false}
                  isTl={isTl ? true : false}
                  showUpdateBtn={
                    userData?.isScreenSubscription === true ||
                    userData?.subscriptionPlan === "Basic" ||
                    userData?.subscriptionPlan === "Trial" ||
                    userData?.stripeStatus === 13
                      ? false
                      : true
                  }
                >
                  {intlMessages.paymentModalPaymentMethodLabel}
                </Styled.PaymentMethodLabel>
                <Styled.PaymentBtnContainer
                  isScreenSubscription={
                    userData?.isScreenSubscription === true ? true : false
                  }
                >
                  <Styled.PaymentBtn
                    sx={{ width: { xs: "300px", sm: "330px" } }}
                    onClick={() => {
                      if(userData?.isScreenSubscription===true)
                        return
                      if (
                        stripeStatus === 0 &&
                        userData?.subscriptionPlan === "Trial"
                      ) {
                        logEvent(
                          Categories.pricing_and_subscription_modal,
                          Events.payment_details_tab_i_own_screen_btn_clicked
                        );
                        dispatch(setOpenPaymentModal(true));
                        dispatch(setIsScreenPanelBtnClicked(true));
                        return;
                      }
                      if (
                        stripeStatus === 12 &&
                        userData?.subscriptionPlan === "Basic"
                      ) {
                        logEvent(
                          Categories.pricing_and_subscription_modal,
                          Events.payment_details_tab_pay_via_card_btn_clicked
                        );
                        dispatch(setOpenPricingModal(true));
                        dispatch(setOpenSubscriptionModal(false));
                        dispatch(setIsCardBtnClicked(true));
                        return;
                      }
                    }}
                    endIcon={
                      <DoneIcon
                        sx={{
                          color: "#7DC242",
                          height: "18px",
                          position: "absolute",
                          right: 10,
                          top: 15,
                          display:
                            userData?.isScreenSubscription === true ||
                            (userData?.subscriptionPlan !== "Trial" &&
                              userData?.subscriptionPlan !== "Basic")
                              ? "block"
                              : "none",
                        }}
                      ></DoneIcon>
                    }
                    startIcon={
                      <img
                        src={
                          userData?.isScreenSubscription === true ||
                          (stripeStatus === 0 &&
                            userData?.subscriptionPlan === "Trial")
                            ? "svgs/IFP.svg"
                            : "svgs/Card.svg"
                        }
                        style={{
                          position: "absolute",
                          left: 10,
                          top:
                            userData?.isScreenSubscription === true ||
                            (stripeStatus === 0 &&
                              userData?.subscriptionPlan === "Trial")
                              ? 10
                              : 15,
                        }}
                      ></img>
                    }
                  >
                    {userData?.isScreenSubscription === true ||
                    (stripeStatus === 0 &&
                      userData?.subscriptionPlan === "Trial")
                      ? intlMessages.paymentModalIPSLabel
                      : intlMessages.Payviacard}
                  </Styled.PaymentBtn>
                  <Styled.PaymentUpdateBtn
                    onClick={updatePaymentMethod}
                    disabled={isSaving === true ? true : false}
                    sx={{
                      display:
                        userData?.isScreenSubscription === true ||
                        userData?.subscriptionPlan === "Basic" ||
                        userData?.subscriptionPlan === "Trial" ||
                        userData?.stripeStatus === 13
                          ? "none"
                          : "block",
                    }}
                  >
                    {isSaving === true && (
                      <CircularProgress
                        color="inherit"
                        sx={{ marginRight: "10px" }}
                        size={16}
                      />
                    )}
                    {intlMessages.paymentModalUpdateBtnText}
                  </Styled.PaymentUpdateBtn>
                </Styled.PaymentBtnContainer>
                {stripeStatus === 12 &&
                  userData?.subscriptionPlan === "Basic" && (
                    <Styled.PaymentBtn
                      sx={{ mt: "20px", width: { xs: "300px", sm: "330px" } }}
                      onClick={() => {
                        dispatch(setOpenPaymentModal(true));
                        dispatch(setIsScreenPanelBtnClicked(true));
                        logEvent(
                          Categories.pricing_and_subscription_modal,
                          Events.payment_details_tab_i_own_screen_btn_clicked
                        );
                      }}
                      startIcon={
                        <img
                          src={"svgs/IFP.svg"}
                          style={{
                            position: "absolute",
                            left: 10,
                            top: 10,
                          }}
                        ></img>
                      }
                    >
                      {intlMessages.paymentModalIPSLabel}
                    </Styled.PaymentBtn>
                  )}
                <Styled.SubscriptionPolicyBtn
                  isMobile={isMobile ? true : false}
                  isMobileLandscape={isMobileLandscape ? true : false}
                  isIpad={isIpad ? true : false}
                  isIpadLandscape={isIpadLandscape ? true : false}
                  isTl={isTl ? true : false}
                  showUpdateBtn={
                    userData?.isScreenSubscription === true ||
                    userData?.subscriptionPlan === "Basic" ||
                    userData?.subscriptionPlan === "Trial" ||
                    userData?.stripeStatus === 13
                      ? false
                      : true
                  }
                  href="subscription-policy.html"
                  target="_blank"
                >
                  {intlMessages.subscriptionPolicyLabel}
                </Styled.SubscriptionPolicyBtn>
              </Styled.PaymentDetailsContainer>
              <Styled.HistoryContainer
                isTl={isTl ? true : false}
                isMobile={isMobile ? true : false}
                isMobileLandscape={isMobileLandscape ? true : false}
                isIpad={isIpad ? true : false}
                isIpadLandscape={isIpadLandscape ? true : false}
                selectedTab={tabSelected === 2 ? true : false}
              >
                {/* {userData?.isScreenSubscription === true ? (
                  <Styled.ScreenText>
                    {intlMessages.IPSBillingHistoryLabel}
                  </Styled.ScreenText>
                ) : ( */}
                <DataGridTable
                  billingModal={true}
                  rows={billingList}
                  disableSelectionOnClick
                  columns={getDatagridColumns(isMobile, isMobileLandscape)}
                  isDisabledPagination={false}
                  height={"100%"}
                  textLabels={intlMessages}
                  isLoading={false}
                  handleChangeSelectedItem={() => {}}
                  handleSortModelChange={() => {}}
                  handleTableRowsOnResizePage={() => {}}
                  handlePageDataChange={handlePageDataChange}
                  totalPagesCount={totalPages}
                  isMobile={isMobile ? true : false}
                  isMobileLandscape={isMobileLandscape ? true : false}
                  sortBy={""}
                  order={1}
                  page={currentPage}
                  size={10}
                />
                {/* )} */}
              </Styled.HistoryContainer>
            </Styled.ModalContentBox>
          </Box>
          <Styled.ModalActions
            isMobile={isMobile ? true : false}
            isMobileLandscape={isMobileLandscape ? true : false}
            isIpad={isIpad ? true : false}
            isIpadLandscape={isIpadLandscape ? true : false}
          >
            <Styled.ViewDetailsBtn
              isMobile={isMobile ? true : false}
              isMobileLandscape={isMobileLandscape ? true : false}
              isIpad={isIpad ? true : false}
              isIpadLandscape={isIpadLandscape ? true : false}
              onClick={() => {
                handleOnClose();
                logEvent(
                  Categories.pricing_and_subscription_modal,
                  Events.pricing_and_subscription_modal_view_plan_details_btn_clicked
                );
                dispatch(setIsCardBtnClicked(true));
                dispatch(setOpenPricingModal(true));
              }}
            >
              {intlMessages.subscriptionModalPlanDetailsBtnText}
            </Styled.ViewDetailsBtn>
            <Styled.DowngradeBtn
              isMobile={isMobile ? true : false}
              isMobileLandscape={isMobileLandscape ? true : false}
              isIpad={isIpad ? true : false}
              isIpadLandscape={isIpadLandscape ? true : false}
              disabled={userData?.isDeleted === 1 ? true : false}
              sx={{
                display:
                  userData?.isScreenSubscription === true ||
                  userData?.subscriptionPlan === "Basic" ||
                  userData?.subscriptionPlan === "Trial" ||
                  userData?.subscriptionPlan === null
                    ? "none"
                    : "block",
              }}
              onClick={handleDowngradeBtnClick}
            >
              {userData?.stripeStatus === 13 && userData?.isDeleted === 0
                ? intlMessages.cancelDowngradeBtnText
                : intlMessages.subscriptionModalDowngradeSubscriptionBtn}
            </Styled.DowngradeBtn>
          </Styled.ModalActions>
        </Styled.ModalBox>
      </Modal>
      {openConnectionModal && (
        <ConnectionModal
          isOpen={openConnectionModal}
          handleCloseConnectionModal={() => setIsOpenConnectionModal(false)}
        />
      )}
      {downgradeModalState && (
        <DowngradModal
          open={downgradeModalState}
          onClose={(flag, openTrialModal) => {
            if (!!openTrialModal) {
              setTrialModalText({
                desc: intlMessages.downgradeModalText,
              });
              setOpenTrialModal(true);
              dispatch(setStripeStatus(13));
            }
            toggleDowngradeModalState(flag);
          }}
        ></DowngradModal>
      )}
      {openTrialModal && (
        <TrialInfoModal
          downgradeFlag={true}
          text={trialModalText}
          open={openTrialModal}
          onClose={(flag) => setOpenTrialModal(flag)}
        ></TrialInfoModal>
      )}
      {openErrorBox && (
        <AlertBox
          isIpad={isIpad ? true : false}
          isMobile={isMobile ? true : false}
          isMobileLandscape={isMobileLandscape ? true : false}
          isIpadLandscape={isIpadLandscape ? true : false}
          loginBtn={false}
          libraryBtn={false}
          isOpen={openErrorBox}
          onClose={() => {
            dispatch(setOpenErrorBox(false));
          }}
          message={{
            message: intlMessages.invoiceErrorMessage,
            icon: "/svgs/erroricon.svg",
            isError: true,
          }}
        />
      )}
    </>
  );
}
