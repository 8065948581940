import { useDispatch, useSelector } from "react-redux";
import ImportFile from "./component";
import { checkUrlIsGoogleDrive } from "../../util/signin-user-data";
import intlMessages from "../../locales/en";
import { Snackbar } from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import DriveFileContent from "../google-picker/component-drive-file";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useEffect, useState } from "react";
import useDrivePicker from "react-google-drive-picker";
import { importFromGoogle, getInternetConnectionStatus } from "./service";
import GoogleSignSignupForm from "../google-signin-signup-modal/component";
import GooglePermissionModal from "../google-signin-signup-modal/component-permission";
import ImportLoaderModal from "../../common/import-loader-modal/component";
import AlertBox from "../alert/component";
import { Categories, Events, logEvent } from "../../analytics";
import { MAX_FILE_UPLOAD_SIZE, MAX_TXT_FILE_UPLOAD_SIZE } from "../../settings";
import { setQuizInputText } from "../../redux/slices/quizSlice";
import { containsOnlyNewlines } from "../google-picker/service";
import { setOpenUpgradeModel, setUpgradeModalText } from "../../redux/slices/featuresSlice";
const G_DRIVE_TOKEN = "G_DRIVE_TOKEN";

const ImportFileMain = (props) => {
  const {
    openImportModal,
    closeImportFileModal,
    isMobile,
    selectedTypeCallback,
    openConnectionErrorCallback,
    isIpad,
    isIpadLandscape,
    isMobileLandscape,
    clearQuizText,
    isTl7,
  } = props;

  const { googleClientId, googleApiKey } = useSelector((state) => state.quiz);
  const {canImportFromGoogleDrive} = useSelector((state)=>state.feature)

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const { isSignedIn } = useSelector((state) => state.logIn);
  const { userData } = useSelector((state) => state.logIn);

  const [driveMenu, setDriveMenu] = useState(false);
  const [openPicker, authResponse] = useDrivePicker();
  const [openGoogleSignSignupForm, setOpenGoogleSignSignupForm] =
    useState(false);
  const [openGooglePermissionForm, setopenGooglePermissionForm] =
    useState(false);
  const [googleAccessToken, setGoogleAccessToekn] = useState("");
  const [driveFileForQuest, setDriveFileForQuest] = useState(false);
  const [selectedFile, setSelectedFile] = useState({});
  const [importMenu, setImportMenu] = useState(false);
  const [loader, setLoader] = useState(false);
  const [openAlertBox, setOpenAlertBox] = useState(false);
  const [isWarning, setIsWarning] = useState(false);
  const [alertMsg, setAlertMsg] = useState({
    message: intlMessages.importModalLoadingError,
    icon: "/svgs/erroricon.svg",
    isError: true,
  });
  const [pdfFileData, setPdfFileData] = useState({});
  const [snackBarstate, setSnackBar] = useState({
    openSnackBar: false,
    vertical: "top",
    horizontal: "right",
    type: "warning",
    message: "",
    title: null,
    timeout: 3000,
  });

  const { vertical, horizontal, openSnackBar, title, type, message, timeout } =
    snackBarstate;

  const openImportMenu = () => {
    setImportMenu(true);
  };

  const closeImportMenu = () => {
    setImportMenu(false);
  };

  const openGoogleFormModal = () => {
    setOpenGoogleSignSignupForm(true);
  };

  const closeGoogleFormModal = () => {
    closeImportFileModal();
    setOpenGoogleSignSignupForm(false);
  };

  const openGooglePermissionModal = () => {
    setopenGooglePermissionForm(true);
  };
  const openDriveFileForQuest = () => {
    // console.log(selectedFile);
    setDriveFileForQuest(true);
  };

  const closeDriveFileForQuest = () => {
    setDriveFileForQuest(false);
    closeImportFileModal();
  };
  const backToMainMenu = () => {
    logEvent(Categories.drive, Events.drive_select_different_clicked);
    setOpenAlertBox(false);
    setDriveFileForQuest(false);
    setImportMenu(true);
  };

  const closeGooglePermissionModal = (decodedToken) => {
    setopenGooglePermissionForm(false);
    if (decodedToken) {
      // console.log(decodedToken);
      setGoogleAccessToekn(decodedToken.accessToken);
      handleOpenPicker(decodedToken.accessToken);
      logEvent(Categories.drive, Events.drive_Library_opened);
    } else {
      closeImportMenu();
      closeImportFileModal();
    }
  };

  const openLoaderModal = () => {
    setLoader(true);
  };

  const closeLoaderModal = () => {
    setLoader(false);
  };

  const handleOpenDriveFileImportModal = () => {
    if (!isSignedIn) {
      openGoogleFormModal();
    } else if (googleAccessToken) {
      handleOpenPicker(googleAccessToken);
    } else {
      openGooglePermissionModal();
    }
    setDriveMenu(true);
  };

  const handleRemoveSearchParams = () => {
    searchParams.delete("google-drive"); // Replace with the desired parameter name(s)
    navigate(`?${searchParams.toString()}`);
  };

  useEffect(() => {
    if (checkUrlIsGoogleDrive()) {
      handleRemoveSearchParams();
      if (!isSignedIn) {
        openGoogleFormModal();
      } else {
        // open permission modal
        openGooglePermissionModal();
      }
    } else {
      openImportMenu();
    }
  }, []);

  const closeDriveFileImportModal = () => {
    setDriveMenu(false);
    closeImportFileModal();
  };

  const handleOpenPicker = (accessToken) => {
    const config = {
      clientId: googleClientId,
      developerKey: googleApiKey,
      // viewId: 'PDFS',
      viewMimeTypes:
        "application/vnd.google-apps.document,application/pdf,application/msword,text/plain,application/vnd.google-apps.presentation,application/vnd.google-apps.photo,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,image/jpeg,image/png,application/vnd.ms-powerpoint,application/vnd.openxmlformats-officedocument.presentationml.presentation,application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      setIncludeFolders: true,
      token: accessToken || googleAccessToken,
      callbackFunction: (data) => {
        if (data.action === "loaded") {
          // console.info("Opened popup");
          return;
        } else if (data.action === "cancel") {
          // console.info("User clicked cancel/close button");
          closeDriveFileImportModal();
          return;
        } else {
          // closeDriveFileImportModal();
          handleShowList(data.docs);
        }
      },
    };

    if (authResponse?.access_token || sessionStorage.getItem(G_DRIVE_TOKEN)) {
      config.token =
        authResponse?.access_token || sessionStorage.getItem(G_DRIVE_TOKEN);
    }
    openPicker(config);
    // event.preventDefault();
  };

  const handleShowList = async (files) => {
    logEvent(Categories.drive, Events.drive_file_selected);
    if (
      (files[0].sizeBytes >= MAX_TXT_FILE_UPLOAD_SIZE &&
        files[0].mimeType == "text/plain") ||
      files[0].sizeBytes >= MAX_FILE_UPLOAD_SIZE
    ) {
      setAlertMsg({
        ...alertMsg,
        message:
          files[0].mimeType == "text/plain"
            ? intlMessages.maxFileSize
            : intlMessages.maxpdfSizeWarning,
      });
      closeLoaderModal();
      setOpenAlertBox(true);
      closeImportMenu();
      return;
    }
    openLoaderModal();
    setSelectedFile(files[0]);
    const internetConnection = await getInternetConnectionStatus();
    if (!internetConnection?.isConnected) {
      openConnectionErrorCallback();
      closeImportFileModal();
      return;
    }
    //call api here
    importFromGoogle(files[0].id, files[0].mimeType)
      .then((res) => {
        closeLoaderModal();
        if (res.status == 400) {
          setAlertMsg({
            ...alertMsg,
            message: res?.data?.message || intlMessages.importModalLoadingError,
          });
          setOpenAlertBox(true);
          closeImportMenu();
          return;
        }
        if (res.status == 201) {
          if (res?.data?.isImage) {
            if (
              res?.data?.text?.length === 0 ||
              containsOnlyNewlines(res?.data?.text)
            ) {
              setAlertMsg({
                ...alertMsg,
                message: intlMessages.imageErrorMsg,
              });
              setOpenAlertBox(true);
              closeImportMenu();
              return;
            }
            clearQuizText();
            setTimeout(() => {
              dispatch(setQuizInputText(res?.data?.text));
              closeDriveFileForQuest();
            }, 100);
            return;
          }
          setPdfFileData(res.data);
          openDriveFileForQuest();
          return;
        }
        //open error modal
        setAlertMsg({
          ...alertMsg,
          message: intlMessages.importModalLoadingError,
        });
        setOpenAlertBox(true);
        closeImportMenu();
      })
      .catch((e) => {
        closeLoaderModal();
        backToMainMenu();
        showWarningMessage(intlMessages.importDriveModalApiErrorEndIndexText);
      });
  };

  const closeErrorModal = () => {
    closeDriveFileForQuest();
  };

  const errorCallback = (
    errMsg = intlMessages.importModalLoadingError,
    iserror
  ) => {
    setAlertMsg({
      ...alertMsg,
      message: errMsg || intlMessages.importModalLoadingError,
      ...(iserror === null
        ? {
            isError: null,
            icon: "/svgs/warning.svg",
          }
        : {}),
    });
    iserror === null ? setIsWarning(true) : setIsWarning(false);
    setDriveFileForQuest(false);
    setOpenAlertBox(true);
  };

  const showWarningMessage = (msgText, title = null) => {
    return setSnackBar({
      ...snackBarstate,
      type: "warning",
      message: msgText,
      openSnackBar: true,
      title: title,
      timeout: 7000,
    });
  };

  const renderSnackBar = () => {
    return (
      <Snackbar
        open={openSnackBar}
        anchorOrigin={{ vertical, horizontal }}
        autoHideDuration={snackBarstate.timeout}
        // onClose={handleCloseSnackBar}
        key={vertical + horizontal}
      >
        <MuiAlert
          elevation={6}
          variant="filled"
          sx={{ color: "white" }}
          // onClose={handleCloseSnackBar}
          severity={snackBarstate.type}
        >
          {snackBarstate.message}
        </MuiAlert>
      </Snackbar>
    );
  };
  const texts = {
    header: intlMessages.googleDrivelabes,
    text: intlMessages.googleDriveTitlePermission,
  };

  const callBackSelected = (selectedType) => {
    if (selectedType === "drive") {
      if(canImportFromGoogleDrive.enable===true){
        handleOpenDriveFileImportModal();
        }
        else{
          dispatch(setUpgradeModalText({
            heading:intlMessages.upgradeModelHeading,
            desc:intlMessages.upgradeModelDescText
          }))
          dispatch(setOpenUpgradeModel(true))
          closeImportFileModal();
          
        }
      logEvent(Categories.drive, Events.drive_file_clicked);
    } else if (selectedType === "upload") {
      selectedTypeCallback("upload");
    } else if (selectedType === "camera") {
      selectedTypeCallback("camera");
      logEvent(
        Categories.content_for_quiz,
        Events.capture_photo_button_clicked
      );
    }
    closeImportMenu();
  };

  return (
    <>
      {importMenu && (
        <ImportFile
          openImportModal={importMenu}
          closeImportFileModal={closeImportFileModal}
          isMobile={isMobile}
          isMobileLandscape={isMobileLandscape}
          selectedTypeCallback={callBackSelected}
        ></ImportFile>
      )}

      {openGoogleSignSignupForm && (
        <GoogleSignSignupForm
          openGoogleSignSignupForm={openGoogleSignSignupForm}
          closeGoogleFormModal={closeGoogleFormModal}
          signInparams="google-drive=true"
          isMobile={isMobile}
        />
      )}

      {openGooglePermissionForm && (
        <GooglePermissionModal
          openGooglePermissionForm={openGooglePermissionForm}
          closeGooglePermissionModal={(decodedToken) =>
            closeGooglePermissionModal(decodedToken)
          }
          texts={texts}
          queryParams={"drive"}
          isMobile={isMobile}
        />
      )}
      {loader && (
        <ImportLoaderModal
          openImportLoaderModal={loader}
          isMobile={isMobile}
        ></ImportLoaderModal>
      )}
      {driveFileForQuest && (
        <DriveFileContent
          openDriveMenu={driveFileForQuest}
          closeDriveFileContent={closeDriveFileForQuest}
          backToMainMenu={backToMainMenu}
          selectedFile={selectedFile}
          pdfFileData={pdfFileData}
          isMobile={isMobile}
          errorCallback={errorCallback}
          clearQuizText={clearQuizText}
          isTl7={isTl7}
        ></DriveFileContent>
      )}
      {openAlertBox && (
        <AlertBox
          isIpad={isIpad ? true : false}
          isMobile={isMobile ? true : false}
          isMobileLandscape={isMobileLandscape ? true : false}
          isIpadLandscape={isIpadLandscape ? true : false}
          message={alertMsg}
          loginBtn={false}
          isOpen={openAlertBox}
          onClose={closeErrorModal}
          showDifferentChooser={!isWarning}
          backToMainMenu={backToMainMenu}
        ></AlertBox>
      )}
    </>
  );
};

export default ImportFileMain;
