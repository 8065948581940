import {
  validateEmail,
  } from "../review/service";
  import {
    saveSelectedQuzies,
  } from "../quiz/service";

  const validateEmailList = (emailList) => {
    let emailListArray = emailList.split(",");
    if(emailListArray.length == 0){
        return false;
    }
    let response = emailListArray.every(validateEveryEmail);
    if(!response){
        return false;
    }
    return true;
  }

  const validateEveryEmail = (email) => {
    return validateEmail(email.trim());
  }

  export {
    validateEmailList,
    saveSelectedQuzies
  };