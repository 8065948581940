import { resendEmail } from "../../api/sign-up/methods";

const resendEmailAddress = async (email) => {
    const controller = new AbortController();
    const signal = controller.signal;
    const response = await resendEmail(email,signal)
    return response
  }



  export {
    resendEmailAddress
  }