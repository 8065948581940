import api from "../index";
import { checkInternetConnection, getAuthToken } from "../service";

async function userLogIn(email, password) {
    try {
        const payload = {
            username: email,
            password: password,
        };
        const connectionStatus = await checkInternetConnection();
        const isInternetConnected = connectionStatus.isConnected;
        if (!isInternetConnected)
            return connectionStatus;
        const res = await api.post(`/user/login`, payload);
        return res;
    } catch (e) {
        return e;
    }
}

async function userInfo() {
    try {
        const connectionStatus = await checkInternetConnection();
        const isInternetConnected = connectionStatus.isConnected;
        if (!isInternetConnected)
            return connectionStatus;
        const res = await api.get(`/user/user-info`);
        return res;
    } catch (e) {
        return e;
    }
}

export {
    userLogIn,
    userInfo
}