import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  quizInputText: "",
  showQuizContentPage: "",
  quizData: { questionAndAnswers: [] },
  openwelcomemodal: false,
  consentBtnClicked: false,
  openSampleTextModal: false,
  isAllQuestionsSelected: true,
  isquizLandscapeBackBtnClicked: false,
  isLoading: false,
  microsoftEnabled: false,
  googleEnabled: false,
  quizTitle: "",
  quizId: "",
  isSaved: false,
  openExportMenu: false,
  driveEnabled: false,
  formsEnabled: false,
  checkAllQuestionsDrive: true,
  googleApiKey: "",
  googleClientId: "",
  showGuruCall: false,
  quizSettingHeight:79,
  quizType:'Multiple choice',
  quizCount:5,
  quizDifficultyLevel:'Medium',
  quizSettingMobileHeight:60,
  openCameraModal:false,
  isDisableQuizSetting:false,
  showCameraWarning:false,
  isQuizLoaded:false,
  quizGenerationCurrentState:0,
  libraryWarning:null,
  testingVariation:0
};

const quizSlice = createSlice({
  name: "quiz",
  initialState,
  reducers: {
    setQuizInputText: (state, action) => {
      state.quizInputText = action.payload;
    },
    setShowQuizContentPage: (state, action) => {
      state.showQuizContentPage = action.payload;
    },
    setQuizData: (state, action) => {
      state.quizData = action.payload;
    },
    setShowWelcomeModal: (state, action) => {
      state.openwelcomemodal = action.payload;
    },
    setIsConsentBtnClicked: (state, action) => {
      state.consentBtnClicked = action.payload;
    },
    setShowSampleTextModal: (state, action) => {
      state.openSampleTextModal = action.payload;
    },
    setAllQuestionsSelected: (state, action) => {
      state.isAllQuestionsSelected = action.payload;
    },
    setquizLandscapeBackBtnClicked: (state, action) => {
      state.isquizLandscapeBackBtnClicked = action.payload;
    },
    setIsLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setisMicrosoftEnabled: (state, action) => {
      state.microsoftEnabled = action.payload;
    },
    setisGoogleEnabled: (state, action) => {
      state.googleEnabled = action.payload;
    },
    setQuizTitle: (state, action) => {
      state.quizTitle = action.payload;
    },
    setIsQuizSaved: (state, action) => {
      state.isSaved = action.payload;
    },
    setQuizId: (state, action) => {
      state.quizId = action.payload;
    },
    setOpenExportMenu: (state, action) => {
      state.openExportMenu = action.payload;
    },
    setisDriveEnabled: (state, action) => {
      state.driveEnabled = action.payload;
    },
    setisFromsEnabled: (state, action) => {
      state.formsEnabled = action.payload;
    },
    setisAllDriveQuestionsEnabled: (state, action) => {
      state.checkAllQuestionsDrive = action.payload;
    },
    setGoogleDriveClientId: (state, action) => {
      state.googleClientId = action.payload;
    },
    setGoogleDriveApiKey: (state, action) => {
      state.googleApiKey = action.payload;
    },
    setisGuruCallEnabled: (state, action) => {
      state.showGuruCall = action.payload;
    },
    setQuizSettingHeight: (state,action)=>{
      state.quizSettingHeight=action.payload
    },
    setQuizType: (state,action)=>{
      state.quizType = action.payload
    },
    setQuizCount: (state,action)=>{
      state.quizCount = action.payload
    },
    setQuizDifficultyLevel: (state,action)=>{
      state.quizDifficultyLevel = action.payload
    },
    setQuizMobileSettingHeight: (state,action)=>{
      state.quizSettingMobileHeight=action.payload
    },
    setOpenCameraModal: (state,action)=>{
      state.openCameraModal= action.payload
    },
    setIsDisableQuizSettings: (state,action)=>{
      state.isDisableQuizSetting = action.payload
    },
    setIsShowCameraWarning:(state,action)=>{
      state.showCameraWarning =  action.payload
    },
    setIsQuizLoaded: (state,action)=>{
      state.isQuizLoaded = action.payload
    },
    setQuizGenerationCurrentState: (state,action)=>{
      state.quizGenerationCurrentState = action.payload
    },
    setLibraryWarning: (state,action)=>{
      state.libraryWarning = action.payload
    },
    setTestingVariation: (state,action)=>{
      state.testingVariation = action.payload
    }
  },
});

export const {
  setQuizInputText,
  setShowQuizContentPage,
  setQuizData,
  setShowWelcomeModal,
  setIsConsentBtnClicked,
  setShowSampleTextModal,
  setAllQuestionsSelected,
  setquizLandscapeBackBtnClicked,
  setIsLoading,
  setisMicrosoftEnabled,
  setisGoogleEnabled,
  setQuizTitle,
  setIsQuizSaved,
  setQuizId,
  setOpenExportMenu,
  setisDriveEnabled,
  setisFromsEnabled,
  setisAllDriveQuestionsEnabled,
  setGoogleDriveClientId,
  setGoogleDriveApiKey,
  setisGuruCallEnabled,
  setQuizSettingHeight,
  setQuizCount,
  setQuizDifficultyLevel,
  setQuizType,
  setQuizMobileSettingHeight,
  setOpenCameraModal,
  setIsDisableQuizSettings,
  setIsShowCameraWarning,
  setIsQuizLoaded,
  setQuizGenerationCurrentState,
  setLibraryWarning,
  setTestingVariation
} = quizSlice.actions;
const { reducer } = quizSlice;
export default reducer;
