import styled from "@mui/system/styled";
import { Grid, Typography,Box } from "@mui/material";

const routes = [
    '/verify',
    '/forgotpassword',
    '/resetpassword',
  ]

const FooterMainContainer = styled(Grid)(({ theme, isMobile, isMobileLandscape,isAboutPage,isIpad,isIpadLandscape }) => ({
    //150
    // height:  isMobile && isAboutPage ? '150px' :isMobileLandscape && isAboutPage?'150px' : !isAboutPage && !isMobile && !isMobileLandscape? '50px':'100px',
    height:  isMobile && isAboutPage ? '150px' :isMobileLandscape && isAboutPage?'150px' : isAboutPage?"100px":"unset",
    width: '100%',
    background: `linear-gradient(90deg, #4E0E89 0%, #7C00B9 100%)`,
    color: 'white',
    display: 'flex',
    paddingLeft : isMobile || isMobileLandscape ? '20px' : !isAboutPage && !isMobileLandscape && !isIpad ? '4%' : isIpad && isAboutPage? '2%': !isAboutPage && isIpad ? '6%': '10%',
    paddingRight : !isAboutPage && !isMobile && !isMobileLandscape && !isIpad ? '4%' : isIpad && isAboutPage? '2%': !isAboutPage && isIpad ? '6%': '2%',
    alignItems : isMobile || isMobileLandscape ? 'unset' : 'center',
    justifyContent : isMobile || isMobileLandscape ? 'flex-start' : 'space-between',
    flexDirection: isMobile || isMobileLandscape ? 'column' : 'row',
    paddingBottom: isAboutPage? "unset": "0",
    bottom: isAboutPage? "unset":"0",
    position:isIpadLandscape?"absolute": isAboutPage?"relative":"fixed",
    paddingBottom: !isAboutPage && !isMobile && !isMobileLandscape && !isIpad ?'20px':'unset',
}));

const FooterContainer = styled(Grid)(({ theme,isMobile, isMobileLandscape,route}) => ({
    height:  isMobileLandscape || (isMobile &&  !routes.includes(route)) ?'135px': isMobile &&  routes.includes(route) ?'130px':'90px',
    width: '100%',
    background: `linear-gradient(90deg, #4E0E89 0%, #7C00B9 100%)`,
    color: 'white',
    display: 'flex',
    paddingLeft : isMobile || isMobileLandscape?'4%':'3%',
    paddingRight : '4%' ,
    alignItems : isMobile?'flex-start':'flex-start',
    justifyContent : isMobile|| isMobileLandscape?'flex-start':'space-between',
    flexDirection: isMobile || isMobileLandscape?'column':'row',
    bottom: routes.includes(route) ?"0px":"unset",
    position: isMobile && routes.includes(route)?"absolute": isMobileLandscape?"unset":"absolute",
    paddingTop: '20px',
    marginBottom: isMobile && routes.includes(route)? '2%' : 'unset',
    [route!=='/verify' && `${theme.breakpoints.down("sm")} and (max-height: 680px) and (max-width: 680px)`]: {
        position:'unset'
      },
}));

// paddingRight: !isAboutPage && !isMobile && !isMobileLandscape ? '4%' : isIpad && isAboutPage ? '2%' : !isAboutPage && isIpad ? '6%' : '10%'
const QuizWizLogo = styled('img')(({ theme, isMobile,isMobileLandscape }) => ({
    display: isMobile || isMobileLandscape?'block':'none',
    width: '64px',
    height: '65px',
}))

const FacebookLogo = styled('img')(({ theme }) => ({
    width: '35px',
    height: '35px',
    marginRight: '21px',
    cursor: 'pointer'
}))

const TwitterLogo = styled('img')(({ theme }) => ({
    width: '35px',
    height: '35px',
    marginRight: '21px',
    cursor: 'pointer'
}))

const InstagramLogo = styled('img')(({ theme }) => ({
    width: '35px',
    height: '35px',
    cursor: 'pointer'
}))

const copyrightContainer = styled(Box)(({theme,isMobile,isMobileLandscape})=>({
    display: 'flex',
    flexDirection: 'row',
    justifyContent:isMobile || isMobileLandscape?'flex-start':'flex-end',
    marginTop: isMobile || isMobileLandscape?'10px':'unset'
    // paddingRight:'5px'
}))

const copyrightlabel = styled(Typography)(({theme})=>({
    fontSize:'12px',
    fontFamily:'Open sans',
    fontWeight:400,
    lineHeight:'33.64px',
    color:'rgba(255, 255, 255, 1)',
    opacity:'0.7'
}))

const Anchor = styled('a')(({theme})=>({
    color:'white',
    marginLeft:5
}))


export default {
    FooterMainContainer,
    QuizWizLogo,
    FacebookLogo,
    TwitterLogo,
    InstagramLogo,
    copyrightlabel,
    copyrightContainer,
    Anchor,
    FooterContainer
};
