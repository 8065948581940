const QUIZ_SETTINGS_DATA = "quizSettings";
const QuizType = Object.freeze({
    ALL: 'ALL',
    MCQS: 'MCQ',
    BOOLEAN: 'TrueFalse',
  });
  
const DifficultyLevel = Object.freeze({
    AUTO: '',
    EASY: 'EASY',
    MEDIUM: 'MEDIUM',
    HARD: 'HARD',
  });
const DEFAULT_QUIZ_SETTINGS_DATA = {
    type: QuizType.MCQS,
    difficulty: DifficultyLevel.AUTO,
    count: 0
};


const setQuestSettingsData = (key = QUIZ_SETTINGS_DATA, data) => {
    if(key == QUIZ_SETTINGS_DATA && !data){
        data = DEFAULT_QUIZ_SETTINGS_DATA
    }
    localStorage.setItem(key,JSON.stringify(data));
}

const getQuestSettingsData = (key = QUIZ_SETTINGS_DATA) => {
    let questionData = JSON.parse(localStorage.getItem(key));
    if(key == QUIZ_SETTINGS_DATA && !questionData){
        questionData = DEFAULT_QUIZ_SETTINGS_DATA;
    }
    return questionData;
}

const updateQuestSettingsData = (key = QUIZ_SETTINGS_DATA, newData) => {
    const userData = JSON.parse(localStorage.getItem(key));
    const updatedData = JSON.stringify({...userData,...newData})
    localStorage.setItem(key, updatedData);
}

export {
    QUIZ_SETTINGS_DATA,
    setQuestSettingsData,
    getQuestSettingsData,
    updateQuestSettingsData,
    QuizType,
    DifficultyLevel
}