import api from "../index";
import { checkInternetConnection, getAuthToken } from "../service";
import sampleTextList from "../../content/content";
import {
  QUIZ_SETTINGS_DATA,
  getQuestSettingsData,
} from "../../util/set_quiz_settings";
import { decodeToken } from "../../common/sign_in_service";

function getCookie(cookieName) {
  const name = cookieName + "=";
  const decodedCookie = decodeURIComponent(document.cookie);
  const cookieArray = decodedCookie.split(";");

  for (let i = 0; i < cookieArray.length; i++) {
    let cookie = cookieArray[i].trim();
    if (cookie.indexOf(name) === 0) {
      return cookie.substring(name.length, cookie.length);
    }
  }
  return null;
}

const handleErrorMsg = (e) => {
  if (e.response) {
    let resMessage = e?.response?.data?.message;
    if (Array.isArray(resMessage)) {
      resMessage = resMessage[0];
    }
    e.response.isConnected = false;
    e.response.data.message = resMessage;
    return e?.response;
  }
  return e;
};

async function generateQuizFromParagraph(
  paraText,
  quizSettings,
  uid,
  abortControllerSignal
) {
  try {
    if(uid===null){
      const bcrypt = require("bcryptjs");
      const salt = bcrypt.genSaltSync(10);
      const randonnumber = bcrypt.hashSync("B4c0//", salt);
      localStorage.setItem('guestId',`guestId-${randonnumber}`);
      uid=localStorage.getItem("guestId")
    }
    const authToken = getAuthToken()
    let email ='' 
    if(authToken!==null){
    const user = await decodeToken(authToken)
    email = user.username
    }
    const payload = {
      text: paraText,
      noOfQuestions: quizSettings.noOfQuestions?.toString(),
      difficultyLevel: quizSettings.difficultyLevel,
      questionType: quizSettings.quizType,
      paragraphChecks: false,
      ...(authToken===null?{uId:uid}:{email:email})
    };
    const connectionStatus = await checkInternetConnection();
    const isInternetConnected = connectionStatus.isConnected;
    if (!isInternetConnected) return connectionStatus;
    let csrfHeader = {};
    if (getCookie("_csrf")) {
      csrfHeader = {
        "x-csrf-token": getCookie("_csrf"),
      };
    } else {
      //console.error("CSRF token is missing.");
      // return { error: "CSRF token missing" };
    }
    const res = await api.post(`/quiz/generate`, payload, {
      signal: abortControllerSignal,
      headers: csrfHeader,
    });
    return res;
  } catch (e) {
    return handleErrorMsg(e);
  }
}

async function generateQuizFromImage(file) {
  let formData = new FormData();
  formData.append("file", file);
  try {
    const connectionStatus = await checkInternetConnection();
    const isInternetConnected = connectionStatus.isConnected;
    if (!isInternetConnected) return connectionStatus;
    const res = await api.post(`/quiz/import-image`, formData);
    return res;
  } catch (e) {
    return handleErrorMsg(e);
  }
}

async function generateQuizFromURL(url) {
  try {
    const payload = { url: url };
    const connectionStatus = await checkInternetConnection();
    const isInternetConnected = connectionStatus.isConnected;
    if (!isInternetConnected) return connectionStatus;
    const res = await api.post(`/quiz/import-Url`, payload);
    return res;
  } catch (e) {
    return handleErrorMsg(e);
  }
}

async function getLearningHubServerList(abortControllerSignal) {
  try {
    const connectionStatus = await checkInternetConnection();
    const isInternetConnected = connectionStatus.isConnected;
    if (!isInternetConnected) return connectionStatus;
    const res = await api.get(`/quiz/learninghub/server_list`, {
      signal: abortControllerSignal,
    });
    return res;
  } catch (e) {
    return handleErrorMsg(e);
  }
}

async function loginToLearningHub(
  server,
  email,
  password,
  abortControllerSignal
) {
  try {
    const connectionStatus = await checkInternetConnection();
    const isInternetConnected = connectionStatus.isConnected;
    if (!isInternetConnected) return connectionStatus;

    const payload = {
      server: server,
      email: email,
      password: password,
    };

    const res = await api.post(`/quiz/learninghub/login`, payload, {
      signal: abortControllerSignal,
    });
    return res;
  } catch (e) {
    return handleErrorMsg(e);
  }
}

async function sendQuestionToLearningHub(
  server,
  email,
  password,
  Question,
  abortControllerSignal
) {
  try {
    const connectionStatus = await checkInternetConnection();
    const isInternetConnected = connectionStatus.isConnected;
    if (!isInternetConnected) return connectionStatus;

    const payload = {
      server: server,
      email: email,
      password: password,
      data: Question,
    };

    const res = await api.post(`/quiz/learninghub/import`, payload, {
      signal: abortControllerSignal,
    });
    return res;
  } catch (e) {
    return handleErrorMsg(e);
  }
}

async function saveSelectedQuzies(
  questionAndAnswers,
  fileType,
  quiz_id,
  quiz_hash,
  prety,
  email = "",
  emailList = [],
  shareEmailType = "2",
  isSignedIn,
  sendEmail = false,
  exportId = null
) {
  try {
    const payload = {
      ques_ans_pairs: questionAndAnswers,
      file_type: fileType,
      quiz_id: quiz_id,
      quiz_hash: quiz_hash,
      prety: prety,
      email: email,
      receiversEmails: emailList,
      emailShareType: shareEmailType,
      isSignedIn: isSignedIn,
      sendEmail: sendEmail,
      exportId: exportId,
    };
    const connectionStatus = await checkInternetConnection();
    const isInternetConnected = connectionStatus.isConnected;
    if (!isInternetConnected) return connectionStatus;
    const res = await api.post(`/quiz/export`, payload);
    return res;
  } catch (e) {
    return handleErrorMsg(e);
  }
}

async function saveSelectedQuziesPdf(quizdata, feedback) {
  try {
    const payload = {
      exportQuiz: quizdata,
      feedBack: feedback,
    };
    const connectionStatus = await checkInternetConnection();
    const isInternetConnected = connectionStatus.isConnected;
    if (!isInternetConnected) return connectionStatus;
    const res = await api.post(`/quiz/exportpdf`, payload);
    return res;
  } catch (e) {
    return handleErrorMsg(e);
  }
}

async function saveFeedBack(exportedId, feedback, feedbackId) {
  try {
    const payload = {
      feedBack: feedback,
      exportId: exportedId,
      feedbackId: feedbackId,
    };
    const connectionStatus = await checkInternetConnection();
    const isInternetConnected = connectionStatus.isConnected;
    if (!isInternetConnected) return connectionStatus;
    const res = await api.post(`/quiz/feedback`, payload);
    return res;
  } catch (e) {
    return handleErrorMsg(e);
  }
}
async function exportFileFromApi(
  email,
  questionAndAnswers,
  fileType,
  quiz_id,
  quiz_hash,
  prety,
  exportId,
  isWithCorrectAns
) {
  try {
    const payload = {
      email:email,
      ques_ans_pairs: questionAndAnswers,
      file_type: fileType,
      quiz_id: quiz_id,
      quiz_hash: quiz_hash,
      prety: prety,
      exportId: exportId,
      isWithCorrectAns: isWithCorrectAns,
    };
    const connectionStatus = await checkInternetConnection();
    const isInternetConnected = connectionStatus.isConnected;
    if (!isInternetConnected) return connectionStatus;
    const res = await api.post(`/quiz/download`, payload);
    return res;
  } catch (e) {
    return handleErrorMsg(e);
  }
}

async function exportXMLFile(
  email,
  questionAndAnswers,
  quiz_id,
  file_type,
  quiz_hash,
  quiz_type,
  isWithCorrectAns,
  file_type_flag,
  exportId,
  feedbackId
) {
  try {
    const payload = {
      email:email,
      ques_ans_pairs: questionAndAnswers,
      quiz_id: quiz_id,
      file_type:file_type,
      quiz_hash: quiz_hash,
      quiz_type:quiz_type,
      file_type_flag:file_type_flag,
      isWithCorrectAns: isWithCorrectAns,
      exportId:exportId,
      feedbackId:feedbackId
    };
    const connectionStatus = await checkInternetConnection();
    const isInternetConnected = connectionStatus.isConnected;
    if (!isInternetConnected) return connectionStatus;
    const res = await api.post(`/quiz/export-moodle`, payload);
    return res;
  } catch (e) {
    return handleErrorMsg(e);
  }
}

async function exportAikenFile(
  email,
  questionAndAnswers,
  quiz_id,
  file_type,
  quiz_hash,
  quiz_type,
  isWithCorrectAns,
  file_type_flag,
  exportId,
  feedbackId
) {
  try {
    const payload = {
      email:email,
      ques_ans_pairs: questionAndAnswers,
      quiz_id: quiz_id,
      file_type:file_type,
      quiz_hash: quiz_hash,
      quiz_type:quiz_type,
      file_type_flag:file_type_flag,
      isWithCorrectAns: isWithCorrectAns,
      exportId:exportId,
      feedbackId:feedbackId
    };
    const connectionStatus = await checkInternetConnection();
    const isInternetConnected = connectionStatus.isConnected;
    if (!isInternetConnected) return connectionStatus;
    const res = await api.post(`/quiz/export-akin`, payload);
    return res;
  } catch (e) {
    return handleErrorMsg(e);
  }
}

async function exportQT12File(
  email,
  questionAndAnswers,
  quiz_id,
  file_type,
  quiz_hash,
  quiz_type,
  isWithCorrectAns,
  file_type_flag,
  exportId,
  feedbackId
) {
  try {
    const payload = {
      email:email,
      ques_ans_pairs: questionAndAnswers,
      quiz_id: quiz_id,
      file_type:file_type,
      quiz_hash: quiz_hash,
      quiz_type:quiz_type,
      file_type_flag:file_type_flag,
      isWithCorrectAns: isWithCorrectAns,
      exportId:exportId,
      feedbackId:feedbackId
    };
    const connectionStatus = await checkInternetConnection();
    const isInternetConnected = connectionStatus.isConnected;
    if (!isInternetConnected) return connectionStatus;
    const res = await api.post(`/quiz/export-qt1`,payload,{responseType: "blob"});
    return res;
  } catch (e) {
    return handleErrorMsg(e);
  }
}

async function exportGoogleSheetFile(
  email,
  questionAndAnswers,
  quiz_id,
  file_type,
  quiz_hash,
  quiz_type,
  isWithCorrectAns,
  file_type_flag,
  exportId,
  feedbackId
) {
  try {
    const payload = {
      email:email,
      ques_ans_pairs: questionAndAnswers,
      quiz_id: quiz_id,
      file_type:file_type,
      quiz_hash: quiz_hash,
      quiz_type:quiz_type,
      file_type_flag:file_type_flag,
      isWithCorrectAns: isWithCorrectAns,
      exportId:exportId,
      feedbackId:feedbackId
    };
    const connectionStatus = await checkInternetConnection();
    const isInternetConnected = connectionStatus.isConnected;
    if (!isInternetConnected) return connectionStatus;
    const res = await api.post(`/quiz/export-google-sheet`, payload);
    return res;
  } catch (e) {
    return handleErrorMsg(e);
  }
}

async function submitQuizFeedback(name, email, message, purpose,isScreenUser) {
  try {
    const payload = {
      name: name,
      email: email,
      comments: message,
      purpose: purpose,
      isScreenUser:isScreenUser
    };
    const connectionStatus = await checkInternetConnection();
    const isInternetConnected = connectionStatus.isConnected;
    if (!isInternetConnected) return connectionStatus;
    const res = await api.post(`/quiz/contactus`, payload);
    return res;
  } catch (e) {
    return handleErrorMsg(e);
  }
}

async function getSampleText() {
  try {
    // const res = await api.get('/')
    return sampleTextList;
  } catch (e) {
    return handleErrorMsg(e);
  }
}

async function saveQuizToLibrary(title, quizData) {
  try {
    const payload = {
      title: title,
      quiz: quizData,
    };
    const connectionStatus = await checkInternetConnection();
    const isInternetConnected = connectionStatus.isConnected;
    if (!isInternetConnected) return connectionStatus;
    const res = await api.post(`/library/save`, payload);
    return res;
  } catch (e) {
    return handleErrorMsg(e);
  }
}

async function importFile(file) {
  let formData = new FormData();
  formData.append("file", file);
  try {
    const connectionStatus = await checkInternetConnection();
    const isInternetConnected = connectionStatus.isConnected;
    if (!isInternetConnected) return connectionStatus;
    const res = await api.post(`/quiz/import-file`, formData);
    return res;
  } catch (e) {
    return handleErrorMsg(e);
  }
}

async function loadQuizContent(id) {
  const params = {
    id: id
  };
  try {
    const connectionStatus = await checkInternetConnection();
    const isInternetConnected = connectionStatus.isConnected;
    if (!isInternetConnected) return connectionStatus;
    const res = await api.get(`/quiz/scrybe-content`,{params});
    return res;
  } catch (e) {
    return handleErrorMsg(e);
  }
}

export {
  generateQuizFromParagraph,
  exportFileFromApi,
  submitQuizFeedback,
  getLearningHubServerList,
  loginToLearningHub,
  sendQuestionToLearningHub,
  saveFeedBack,
  saveSelectedQuzies,
  getSampleText,
  saveSelectedQuziesPdf,
  saveQuizToLibrary,
  generateQuizFromImage,
  importFile,
  generateQuizFromURL,
  exportXMLFile,
  exportAikenFile,
  exportQT12File,
  exportGoogleSheetFile,
  loadQuizContent
};
