import { Typography } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import Box from "@mui/material/Box";
import Styled from "./styles";
import CircularLoader from "../loader/component";
import { ArrowBackIos, ArrowForwardIos } from "@mui/icons-material";

export default function DataGridTable(props) {
  const {
    rows,
    columns,
    isDisabledPagination,
    height,
    textLabels,
    handleChangeSelectedItem,
    handleSortModelChange,
    isLoading,
    handlePageDataChange,
    totalPagesCount,
    page,
    size,
    sortBy,
    order,
    isMobile,
    isMobileLandscape,
    billingModal
  } = props;
  const handlePageChange = (event, value) => {
    document.querySelector(".MuiDataGrid-virtualScroller").scrollTop = 0;
    handlePageDataChange(value);
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ height: height ? height : "35vh", width: "100%" }}>
        <Styled.StyledDataGrid
          billingModal={billingModal?true:false}
          rowHeight={isMobile || isMobileLandscape ? 90 : 52}
          rows={rows}
          columns={columns}
          pageSize={size}
          rowsPerPageOptions={[3]}
          checkboxSelection={false}
          isRowSelectable={false}
          disableMultipleSelection={false}
          disableColumnFilter={true}
          disableColumnMenu={true}
          rowBuffer={size}
          disableColumnSelector={true}
          disableSelectionOnClick={true}
          experimentalFeatures={{ newEditingApi: true }}
          getRowId={(row) => billingModal?row.invoiceId:row._id}
          isdisabledpagination={isDisabledPagination}
          hideFooter={billingModal?true:false}
          components={{
            Pagination: () => (
              <Styled.FlexBox
                isMobile={isMobile ? true : false}
                isMobileLandscape={isMobileLandscape ? true : false}
                style={{ display: rows?.length <= 0 && "none" }}
              >
                <Styled.SimpleButton
                  isMobile={isMobile ? true : false}
                  isMobileLandscape={isMobileLandscape ? true : false}
                  disabled={isLoading || page === 1}
                  onClick={() => handlePageChange(null, 1)}
                >
                  {textLabels.firstPage}
                </Styled.SimpleButton>
                {!isMobile && !isMobileLandscape && (
                  <Styled.StyledPagination
                    count={totalPagesCount || 1}
                    color="primary"
                    variant="outlined"
                    shape="rounded"
                    page={page}
                    onChange={handlePageChange}
                    disabled={isLoading}
                  />
                )}
                <Styled.SimpleButton
                  isMobile={isMobile ? true : false}
                  isMobileLandscape={isMobileLandscape ? true : false}
                  disabled={isLoading || page === totalPagesCount}
                  onClick={() => handlePageChange(null, totalPagesCount)}
                >
                  {textLabels.lastPage}
                </Styled.SimpleButton>
                <IconButton
                  disabled={isLoading || page === 1}
                  sx={{
                    display: !isMobile && !isMobileLandscape ? "none" :"block",
                    padding:0,
                    mr:'15px'
                  }}
                  size={"small"}
                  onClick={() => handlePageChange(null,page-1)}
                >
                  <ArrowBackIos fontSize="5px" />
                </IconButton>
                <IconButton
                  disabled={isLoading || page === totalPagesCount}
                  sx={{
                    display: !isMobile && !isMobileLandscape ? "none" : "block",
                    padding:0
                  }}
                  size={"small"}
                  onClick={() => handlePageChange(null,page+1)}
                >
                  <ArrowForwardIos fontSize="5px" />
                </IconButton>
              </Styled.FlexBox>
            ),
            NoRowsOverlay: () => (
              <Styled.NoRowsTextBox>
                <Typography variant="p">{textLabels.noTableData}</Typography>
              </Styled.NoRowsTextBox>
            ),
            LoadingOverlay: () => (
              <Styled.NoRowsTextBox>
                <CircularLoader />
              </Styled.NoRowsTextBox>
            ),
          }}
          onSelectionModelChange={(item) => {
            handleChangeSelectedItem(item);
          }}
          sortingMode="server"
          onSortModelChange={handleSortModelChange}
          initialState={{
            sorting: {
              sortModel: [
                { field: sortBy, sort: order === 1 ? "asc" : "desc" },
              ],
            },
          }}
          loading={isLoading}
        />
      </Box>
    </Box>
  );
}
