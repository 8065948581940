
import { Grid, List, ListItem, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import styled from "@mui/system/styled";

const customStyling = {
  modal: {
    position: "absolute",
    top: "50%",
    left: "50%",
    bottom:'none',
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    borderRadius: 3,
    overflowY: "auto",
    maxHeight: {xs:"98%" ,sm:"90%"},
    border: "none",
  },
};

const Header = styled(Box)(({ theme }) => ({
  padding: 18,
  boxShadow: "rgba(100, 100, 111, 0.2) 0px 3px 29px 0px",
  paddingLeft: 40,
  alignItems: "center",
  display:"flex",
  justifyContent:"space-between",
  [theme.breakpoints.down("xsm")]: {
    paddingLeft: 20,
  },
  [`${theme.breakpoints.down("md")}  and (orientation: landscape) and (hover: none) and (pointer: coarse)`]: {
    paddingLeft:20
  },
}));

const ModalBox = styled(Box)(({ theme }) => ({
  width: "30%",
  [theme.breakpoints.down("lg")]: {
    width: "60%",
  },
  [theme.breakpoints.down("sm")]: {
    width: "95%",
  },
}));

const HeaderText = styled(Typography)(({ theme }) => ({
  [theme.breakpoints.down("sm")]: {
    fontSize:14
  },
}));

const ModalBody = styled(Grid)(({ theme }) => ({
  padding: 18,
  paddingLeft: 40,
  paddingTop: 15,
  paddingRight: 40,
  [theme.breakpoints.down("xsm")]: {
    paddingLeft: 20,
    paddingTop: 20,
    paddingRight: 20,
  },
  [`${theme.breakpoints.down("md")}  and (orientation: landscape) and (hover: none) and (pointer: coarse)`]: {
    paddingLeft: 20,
    paddingTop: 10,
    paddingRight: 20,
  },
}));


export default {
  customStyling,
  Header,
  ModalBody,
  ModalBox,
  HeaderText
};
