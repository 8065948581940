import { useState, useEffect, useRef } from "react";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {
  Button,
  CircularProgress,
  Divider,
  IconButton,
  Input,
  InputAdornment,
  RadioGroup,
  TextField,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { Snackbar } from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import Styled from "./styles";
import intlMessages from "../../locales/en";
import { containsOnlyNewlines, createQuizFromData } from "./service";
import {
  setQuizData,
  setQuizInputText,
  setAllQuestionsSelected,
  setShowQuizContentPage,
  setquizLandscapeBackBtnClicked,
  setQuizTitle,
  setisAllDriveQuestionsEnabled,
} from "../../redux/slices/quizSlice";
import { useDispatch, useSelector } from "react-redux";
import { Categories, Events, logEvent } from "../../analytics";
import { MAX_QUIZ_WORDS } from "../../settings";
export default function DriveFileContent(props) {
  const {
    openDriveMenu,
    closeDriveFileContent,
    isMobile,
    backToMainMenu,
    selectedFile,
    pdfFileData,
    errorCallback,
    clearQuizText,
    isTl7,
  } = props;

  useEffect(() => {
    // console.log(selectedFile);
  }, []);
  const [isSaving, setIssaving] = useState(false);
  const [allPages, setAllPages] = useState(true);
  const [startIndex, setStartIndex] = useState();
  const [endIndex, setEndIndex] = useState();
  const createDriveForm = useRef(null);
  const createGoogleForm = useRef(null);
  const [openedModal, setOpenedModal] = useState(false);
  const [indexError, setIndexError] = useState(
    intlMessages.importDriveModalErrorStartIndexText
  );

  const dispatch = useDispatch();
  const [showError, setShowError] = useState(false);
  const [snackBarstate, setSnackBar] = useState({
    openSnackBar: false,
    vertical: "top",
    horizontal: "right",
    type: "warning",
    message: "",
    title: null,
    timeout: 3000,
  });

  const { vertical, horizontal, openSnackBar, title, type, message, timeout } =
    snackBarstate;
  const handleCountRadioChange = (event) => {
    setAllPages(event.target.value === "true");
    resetError();
  };
  const resetGoogleButtonState = () => {
    setIssaving(false);
    if (createGoogleForm.current) {
      createGoogleForm.current.style.backgroundColor = "#7DC242";
      createGoogleForm.current.style.color = "white";
    }
  };

  const disableGoogleButtonState = () => {
    setIssaving(true);
    if (createGoogleForm.current) {
      createGoogleForm.current.style.backgroundColor = "#E0E0E0";
      createGoogleForm.current.style.color = "#a6a6bd";
    }
  };

  const handleInputChangeStartPage = (event) => {
    let newValue = event.target.value.replace(/[^0-9]/g, "");
    setStartIndex(newValue); // Ensure startPage is less than or equal to endPage
  };

  const handleInputChangeEndPage = (event) => {
    let newValue = event.target.value.replace(/[^0-9]/g, "");
    setEndIndex(newValue); // Ensure endPage is greater than or equal to startPage
    newValue = Math.min(2000, Number(newValue)); // Limit to 2000
  };

  const submitFileForQuiz = () => {
    disableGoogleButtonState();
    let eventType = Events.google_drive_file_all_pages_selected;
    const startIndexNum = +startIndex;
    const endIndexNum = +endIndex;
    if (!allPages) {
      if (!startIndexNum) {
        setIndexError(intlMessages.importDriveModalErrorStartIndexText);
        setShowError(true);
        resetGoogleButtonState();
        return;
      } else if (startIndexNum == 0) {
        setIndexError(intlMessages.importDriveModalErrorStartGreaterzeroText);
        setShowError(true);
        resetGoogleButtonState();
        return;
      } else if (!endIndexNum) {
        setIndexError(intlMessages.importDriveModalErrorEndIndexText);
        setShowError(true);
        resetGoogleButtonState();
        return;
      } else if (endIndexNum == 0) {
        setIndexError(intlMessages.importDriveModalErrorEndGreaterzeroText);
        setShowError(true);
        resetGoogleButtonState();
        return;
      } else if (endIndexNum < startIndexNum) {
        setIndexError(intlMessages.importDriveModalErrorEndIndexValueText);
        setShowError(true);
        resetGoogleButtonState();
        return;
      } else if (startIndexNum > pdfFileData.fileTotalPages) {
        setIndexError(
          intlMessages.importDriveModalErrorStartIndexValueTextMoreThanMax
        );
        setShowError(true);
        resetGoogleButtonState();
        return;
      } else if (endIndexNum > pdfFileData.fileTotalPages) {
        setIndexError(
          intlMessages.importDriveModalErrorEndIndexValueTextMoreThanMax
        );
        setShowError(true);
        resetGoogleButtonState();
        return;
      }
      eventType = Events.google_drive_file_start_end_page_selected;
    } else {
      // setStartIndex(1);
      // setEndIndex(pdfFileData.fileTotalPages);
    }
    logEvent(Categories.drive, eventType);
    logEvent(Categories.drive, Events.drive_import_file_submit_clicked);
    dispatch(setisAllDriveQuestionsEnabled(false));
    //call api here
    createQuizFromData(
      selectedFile.id,
      pdfFileData.filePath,
      allPages ? 1 : startIndexNum,
      allPages ? pdfFileData.fileTotalPages : endIndexNum
    )
      .then((response) => {
        if (!createDriveForm.current) return;
        if (response.status == 204) {
          showWarningMessage(intlMessages.driveEmptyFileError);
          resetGoogleButtonState();
          errorCallback(intlMessages.driveEmptyFileError);
          return;
        } else if (response.status == 201) {
          if (response?.data?.isWarning === true) {
            resetGoogleButtonState();
            errorCallback(
              intlMessages.extractedTextLimitWarning?.replaceAll(
                "#",
                MAX_QUIZ_WORDS
              ),
              null
            );
            clearQuizText();
            dispatch(setQuizInputText(response?.data?.text));
            // closeDriveFileContent();
            return;
          }
          if (containsOnlyNewlines(response?.data?.text)) {
            showWarningMessage(intlMessages.driveEmptyFileError);
            resetGoogleButtonState();
            errorCallback(intlMessages.driveEmptyFileError);
            return;
          }
          clearQuizText();
          setTimeout(() => {
            dispatch(setQuizInputText(response?.data?.text));
            resetGoogleButtonState();
            closeDriveFileContent();
          }, 100);
          // dispatch(
          //   setQuizData({
          //     questionAndAnswers: response?.data?.ques_ans_pairs,
          //     quiz_id: response?.data?.id,
          //     quiz_hash: response?.data?.hash,
          //   })
          // );
          // dispatch(setAllQuestionsSelected(true));
          // dispatch(setShowQuizContentPage(true));
          // // dispatch(setquizLandscapeBackBtnClicked(true));
          // dispatch(setisAllDriveQuestionsEnabled(true));
        } else {
          showWarningMessage(intlMessages.importDriveModalApiErrorEndIndexText);
          resetGoogleButtonState();
          errorCallback(intlMessages.importModalLoadingError);
        }
        // console.log(response.data);
      })
      .catch((e) => {
        resetGoogleButtonState();
        errorCallback(intlMessages.importModalLoadingError);
        // closeLoaderModal();
        // backToMainMenu();
        // showWarningMessage(intlMessages.importDriveModalApiErrorEndIndexText);
      });
  };

  const showWarningMessage = (msgText, title = null) => {
    return setSnackBar({
      ...snackBarstate,
      type: "warning",
      message: msgText,
      openSnackBar: true,
      title: title,
      timeout: 7000,
    });
  };

  const renderSnackBar = () => {
    return (
      <Snackbar
        open={openSnackBar}
        anchorOrigin={{ vertical, horizontal }}
        autoHideDuration={snackBarstate.timeout}
        // onClose={handleCloseSnackBar}
        key={vertical + horizontal}
      >
        <MuiAlert
          elevation={6}
          variant="filled"
          sx={{ color: "white" }}
          // onClose={handleCloseSnackBar}
          severity={snackBarstate.type}
        >
          {snackBarstate.message}
        </MuiAlert>
      </Snackbar>
    );
  };

  const resetError = () => {
    setShowError(false);
  };

  return (
    <>
      <Modal
        open={openDriveMenu}
        aria-labelledby="review-modal"
        aria-describedby="review-description"
        ref={createDriveForm}
      >
        <Styled.ModalPrmissionSignInBox
          sx={Styled.customStyling.exportModal}
          isTl7={isTl7}
        >
          <Styled.ModalHeader>
            <Typography
              id="review-modal-title"
              variant="h5"
              component="h5"
              style={{ fontWeight: "bold" }}
            >
              {intlMessages.importModalHeader}
            </Typography>
            <IconButton
              aria-label="closeReviewModal"
              onClick={() => {
                closeDriveFileContent();
              }}
            >
              <CloseIcon color="seondary.light" />
            </IconButton>
          </Styled.ModalHeader>
          <Styled.ModalContentBox isTl7={isTl7}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                opacity: isSaving ? 0.5 : 1,
                pointerEvents: isSaving ? "none" : "auto",
              }}
              onClick={() => {}}
            >
              <Box sx={{ width: isMobile ? "260px" : "420px" }}>
                <TextField
                  id="outlined-read-only-input"
                  defaultValue={selectedFile.name}
                  InputProps={{
                    readOnly: true,
                    startAdornment: (
                      <InputAdornment
                        position="start"
                        sx={{ marginRight: "10px" }}
                      >
                        <img src="/svgs/file_icon.svg" alt="Icon" />
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment
                        position="end"
                        sx={{ marginLeft: "10px" }}
                      >
                        <Typography
                          variant="body2"
                          sx={{ textAlign: "right", fontSize: "12px" }}
                        >
                          {`${pdfFileData.fileTotalPages} ${intlMessages.pages}`}
                          <br />
                          {intlMessages.Uploaded}
                        </Typography>
                      </InputAdornment>
                    ),
                  }}
                  sx={{
                    width: "100%",
                    color: "#333333E5",
                    fontWeight: 400,
                    fontSize: isMobile ? "14px" : "16px",
                  }}
                />
                {/* <Styled.SupportTextTypography variant="body1">
                  {intlMessages.importDriveModalsupportText}
                </Styled.SupportTextTypography> */}
                <Typography
                  color="secondary.dark"
                  variant="body1"
                  mt={2}
                  mb={"8px"}
                  style={{
                    fontSize: "14px",
                    color: "#333333",
                    width: "100%",
                    fontWeight: 400,
                    paddingLeft: "2px",
                  }}
                >
                  {intlMessages.importDriveModalSelectFileText}
                </Typography>
                <Box
                  mt={1.4}
                  sx={{
                    display: isMobile ? "block" : "flex",
                    flexDirection: isMobile ? "column" : "column",
                    width: "100%",
                    paddingLeft: "7px",
                  }}
                >
                  <RadioGroup
                    row
                    aria-labelledby="demo-form-control-label-placement"
                    name="position"
                    defaultValue="top"
                    value={allPages}
                    onChange={handleCountRadioChange}
                  >
                    <Styled.StyledFormControlLabel
                      value={true}
                      control={<Styled.RadioBtn />}
                      label={intlMessages.importDriveModalAllText}
                    />
                    {isMobile ? <br></br> : ""}
                    <Box
                      sx={{
                        display: isMobile ? "flex" : "flex",
                        // flexDirection: isMobile ? "" : "column",
                        // width: "100%",
                        mt: isMobile ? 3 : "",
                      }}
                    >
                      <Styled.RadioBtn
                        isMobile={isMobile}
                        value={false}
                        checked={allPages === false}
                      />
                      <Box
                        disabled={allPages == true ? true : undefined}
                        sx={{
                          display: isMobile ? "flex" : "flex",
                        }}
                      >
                        <Styled.TextFieldIndex
                          value={startIndex}
                          sx={Styled.customStyling.inputNumberField}
                          onChange={handleInputChangeStartPage}
                          disabled={allPages == true ? true : undefined}
                          label="Start Page"
                        />
                        <Divider
                          sx={{ margin: "0 10px" }}
                          orientation="vertical"
                          variant="middle"
                          flexItem
                        />
                        <Styled.TextFieldIndex
                          value={endIndex}
                          sx={Styled.customStyling.inputNumberField}
                          onChange={handleInputChangeEndPage}
                          disabled={allPages == true ? true : undefined}
                          label="End Page"
                        />
                      </Box>
                    </Box>
                    {/* <Styled.typographyText variant="body1">
                      {intlMessages.importDriveModalRangeText}
                    </Styled.typographyText> */}
                  </RadioGroup>
                </Box>
                <Styled.SupportTextTypography
                  variant="body1"
                  errorText={true}
                  sx={{ display: showError ? "" : "none" }}
                >
                  {indexError}
                </Styled.SupportTextTypography>
                <Styled.CustomDivider orientation="horizontal">
                  {intlMessages.signUpOrText}
                </Styled.CustomDivider>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  opacity: isSaving ? 0.5 : 1,
                  pointerEvents: isSaving ? "none" : "auto",
                }}
              >
                <Typography
                  variant="body1"
                  style={{
                    fontSize: "16px",
                    color: "#1A73E8",
                    cursor: "pointer",
                  }}
                  onClick={backToMainMenu}
                >
                  {intlMessages.importDriveModalAgainText}
                </Typography>
              </Box>
            </Box>
          </Styled.ModalContentBox>
          <Styled.ModalActions>
            <Styled.CreateQuizButton
              variant="contained"
              color="secondary"
              onClick={submitFileForQuiz}
              loading={isSaving}
              loadingPosition="start"
              disabled={isSaving ? "true" : undefined}
              loadingIndicator={
                <CircularProgress
                  sx={{ ml: "25px" }}
                  color="inherit"
                  size={16}
                />
              }
              ref={createGoogleForm}
            >
              {intlMessages.continueLabel}
            </Styled.CreateQuizButton>
            <Button
              sx={{
                textTransform: "none",
                marginLeft: 1,
              }}
              variant="outlined"
              color="secondary"
              disabled={isSaving ? "true" : undefined}
              onClick={() => closeDriveFileContent()}
            >
              {intlMessages.cancelLabel}
            </Button>
          </Styled.ModalActions>
        </Styled.ModalPrmissionSignInBox>
      </Modal>
    </>
  );
}
