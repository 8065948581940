import intlMessages from "../../locales/en";
import {
  COMPANY_NAME,
  CONTACT_EMAIL,
  FACEBOOK_LINK,
  INSTAGRAM_LINK,
  ONESCREEN_WEBSITE_URL,
  TWITTER_LINK,
} from "../../settings";
import { Events, logEvent, Categories } from "../../analytics";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";
import { useMediaQuery } from "@mui/material";
import Styled from "./styles";
import { useLocation } from "react-router-dom";
import footerImages from "./footer-images";
import { useEffect, useState } from "react";
function Footer() {
  const theme = useTheme();
  const location = useLocation();
  const isAboutPage = location.pathname === "/about";
  const otherRoutes =
    location.pathname !== "/" && location.pathname !== "/about";
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"), {
    noSsr: true,
  });
  const isMobileLandscape = useMediaQuery(
    `${theme.breakpoints.down(
      "md"
    )}  and (orientation: landscape) and (hover: none) and (pointer: coarse)`,
    {
      noSsr: true,
    }
  );
  const isIpad = useMediaQuery(
    window.navigator.userAgent.includes("OneScreen_TL7")
      ? "(min-width:620px) and (max-width:960px) and (orientation: landscape) "
      : "(min-width:620px) and (max-width:840px) and (orientation: portrait) and  (hover: none) and (pointer: coarse)",
    {
      noSsr: true,
    }
  );
  const isIpadLandscape = useMediaQuery(
    "(min-width: 800px) and (max-width: 1400px) and (orientation: landscape) and  (hover: none) and (pointer: coarse)",
    {
      noSsr: true,
    }
  );

  function handleContactSupportClick() {
    const mailtoUrl = `mailto:${encodeURIComponent(CONTACT_EMAIL)}`;
    window.location.href = mailtoUrl;
  }

  const handleImageClick = (url) => {
    if (url === FACEBOOK_LINK)
      logEvent(Categories.social_links, Events.social_facebook_link_clicked);
    if (url === INSTAGRAM_LINK)
      logEvent(Categories.social_links, Events.social_instagram_link_clicked);
    if (url === TWITTER_LINK)
      logEvent(Categories.social_links, Events.social_twitter_link_clicked);
    window.open(url);
  };

  return (
    <footer>
      {otherRoutes ? (
        <Styled.FooterContainer
          id="footer2"
          route={location.pathname}
          isIpad={isIpad ? true : false}
          isMobileLandscape={isMobileLandscape ? true : false}
          isMobile={isMobile ? true : false}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              width: isMobile || isMobileLandscape ? "100%" : "unset",
            }}
          >
            {/* <Styled.QuizWizLogo sx={{display:"none"}} src={footerImages[0]}></Styled.QuizWizLogo> */}
            <Box
              sx={{
                marginLeft: isMobile || isMobileLandscape ? "unset" : "17px",
                marginTop: isMobile || isMobileLandscape ? "0px" : "5%",
              }}
            >
              <Typography
                sx={{
                  color: "rgba(255, 255, 255, 0.9)",
                  fontSize: isMobile || isMobileLandscape ? "14px" : "12px",
                  mt: "-5px",
                }}
              >
                {intlMessages.aboutFooterText}
              </Typography>
              <Typography
              component={"a"}
                href={ONESCREEN_WEBSITE_URL}
                target="_blank" 
                sx={{
                  fontWeight: 600,
                  fontSize: isMobile || isMobileLandscape ? "24px" : "20px",
                  color: "rgba(255, 255, 255, 0.9)",
                  mb: "2px",
                  textDecoration:'none'
                }}
              >
                {COMPANY_NAME}
              </Typography>
            </Box>
            <Box
              sx={{
                pl: isMobile || isMobileLandscape ? "unset" : "30px",
                pt: isMobile || isMobileLandscape ? "6px" : "25px",
              }}
            >
              <Typography
                sx={{
                  fontWeight: 400,
                  fontSize: "14px",
                  color: "#FFFFFF",
                  lineHeight: "16.34px",
                  display: "block",
                  mt:
                    isMobile || isMobileLandscape || isIpad || isIpadLandscape
                      ? "5px"
                      : "0px",
                }}
              >
                <a
                  href="privacy-policies.html"
                  target="_blank"
                  style={{
                    cursor: "pointer",
                    textDecoration: "none",
                    fontSize: "12px",
                    color: "#5e9beb",
                    fontWeight: 400,
                    lineHeight: "16.34px",
                  }}
                >
                  {intlMessages.loginNewsletterText2?.charAt(0)?.toUpperCase() +
                    intlMessages.loginNewsletterText2?.slice(1)}
                </a>{" "}
                |{" "}
                <a
                  href="terms.html"
                  target="_blank"
                  style={{
                    cursor: "pointer",
                    textDecoration: "none",
                    fontSize: "12px",
                    color: "#5e9beb",
                    fontWeight: 400,
                    lineHeight: "16.34px",
                  }}
                >
                  {intlMessages.loginNewsletterText4?.charAt(0)?.toUpperCase() +
                    intlMessages.loginNewsletterText4?.slice(1)}
                </a>
              </Typography>
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: isMobile || isMobileLandscape ? "column" : "row",
              mt: isMobile || isMobileLandscape ? "unset" : "8px",
            }}
          >
            {/* <Box sx={{ display: 'flex', flexDirection: 'column', mr: '54px' }}>
          <Typography sx={{ fontWeight: 400, fontSize: '14px', lineHeight: '20px', color: ' rgba(255, 255, 255, 0.7)' }}>{intlMessages.aboutContactText}</Typography>
          <Typography onClick={handleContactSupportClick} variant="button" sx={{ cursor: 'pointer', fontWeight: 400, fontSize: '14px', lineHeight: '20px', textTransform: "none", }}>{CONTACT_EMAIL}</Typography>
        </Box> */}
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  mt: isMobile || isMobileLandscape ? "10px" : "unset",
                }}
              >
                <Styled.FacebookLogo
                  onClick={() => handleImageClick(FACEBOOK_LINK)}
                  src={footerImages[1]}
                ></Styled.FacebookLogo>
                <Styled.TwitterLogo
                  onClick={() => handleImageClick(TWITTER_LINK)}
                  src={footerImages[2]}
                ></Styled.TwitterLogo>
                <Styled.InstagramLogo
                  onClick={() => handleImageClick(INSTAGRAM_LINK)}
                  src={footerImages[3]}
                ></Styled.InstagramLogo>
              </Box>
              {/* <Styled.copyrightContainer sx={{display:isMobile || isMobileLandscape?"block":"unset",mt:0}} isMobile={isMobile?true:false}>
          <Styled.copyrightlabel>
          © {intlMessages.copyrightlabel} 
          <Styled.Anchor
          target="_blank"
          href={ONESCREEN_WEBSITE_URL}>
            {COMPANY_NAME}
          </Styled.Anchor>
          </Styled.copyrightlabel>
        </Styled.copyrightContainer> */}
            </Box>
          </Box>
        </Styled.FooterContainer>
      ) : (
        <Styled.FooterMainContainer
        id="footer"
          isIpadLandscape={isIpadLandscape ? true : false}
          isIpad={isIpad ? true : false}
          isAboutPage={isAboutPage ? true : false}
          isMobileLandscape={isMobileLandscape ? true : false}
          isMobile={isMobile ? true : false}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent:
                isMobile || isMobileLandscape ? "space-between" : "unset",
            }}
          >
            <Box
              sx={{
                marginLeft: isMobile || isMobileLandscape ? "unset" : "17px",
                marginTop: isMobile || isMobileLandscape ? "28px" : "5%",
              }}
            >
              <Typography
                sx={{ color: "rgba(255, 255, 255, 0.9)", fontSize: "12px" }}
              >
                {intlMessages.aboutFooterText}
              </Typography>
              <Typography
              component={"a"}
              href={ONESCREEN_WEBSITE_URL}
              target="_blank" 
                sx={{
                  fontWeight: 600,
                  fontSize: "20px",
                  color: "rgba(255, 255, 255, 0.9)",
                  textDecoration:'none'
                }}
              >
                {COMPANY_NAME}
              </Typography>
            </Box>
            <Box
              sx={{
                pl: isMobile || isMobileLandscape ? "unset" : "30px",
                pt: "25px",
              }}
            >
              <Typography
                sx={{
                  fontWeight: 400,
                  fontSize: "14px",
                  color: "#FFFFFF",
                  lineHeight: "16.34px",
                  display: "block",
                  mt: isAboutPage ? "10px" : "5px",
                }}
              >
                <a
                  href="privacy-policies.html"
                  target="_blank"
                  style={{
                    cursor: "pointer",
                    textDecoration: "none",
                    fontSize: "12px",
                    color: "#5e9beb",
                    fontWeight: 400,
                    lineHeight: "16.34px",
                  }}
                >
                  {intlMessages.loginNewsletterText2?.charAt(0)?.toUpperCase() +
                    intlMessages.loginNewsletterText2?.slice(1)}
                </a>{" "}
                |{" "}
                <a
                  href="terms.html"
                  target="_blank"
                  style={{
                    cursor: "pointer",
                    textDecoration: "none",
                    fontSize: "12px",
                    color: "#5e9beb",
                    fontWeight: 400,
                    lineHeight: "16.34px",
                  }}
                >
                  {intlMessages.loginNewsletterText4?.charAt(0)?.toUpperCase() +
                    intlMessages.loginNewsletterText4?.slice(1)}
                </a>
              </Typography>
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: isMobile || isMobileLandscape ? "column" : "row",
              mt: isMobile || isMobileLandscape ? "-10px" : "25px",
            }}
          >
            {/* <Box sx={{ display: 'flex', flexDirection: 'column', mr: '54px' }}>
        <Typography sx={{ fontWeight: 400, fontSize: '14px', lineHeight: '20px', color: ' rgba(255, 255, 255, 0.7)' }}>{intlMessages.aboutContactText}</Typography>
        <Typography onClick={handleContactSupportClick} variant="button" sx={{ cursor: 'pointer', fontWeight: 400, fontSize: '14px', lineHeight: '20px', textTransform: "none", }}>{CONTACT_EMAIL}</Typography>
      </Box> */}
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  mt: isMobile || isMobileLandscape ? "30px" : "-10px",
                }}
              >
                <Styled.FacebookLogo
                  onClick={() => handleImageClick(FACEBOOK_LINK)}
                  src={footerImages[1]}
                ></Styled.FacebookLogo>
                <Styled.TwitterLogo
                  onClick={() => handleImageClick(TWITTER_LINK)}
                  src={footerImages[2]}
                ></Styled.TwitterLogo>
                <Styled.InstagramLogo
                  onClick={() => handleImageClick(INSTAGRAM_LINK)}
                  src={footerImages[3]}
                ></Styled.InstagramLogo>
              </Box>
              {/* <Typography sx={{ fontWeight: 400, fontSize: '14px',color: '#FFFFFF',lineHeight:'16.34px',display: isAboutPage && isMobile || isMobileLandscape?'block':'none',mt:'15px' }} >
        <a
                  href="privacy-policies.html"
                  target="_blank"
                  style={{
                    cursor: "pointer",
                    textDecoration: "none",
                    fontSize: "12px",
                    color:'#5e9beb',
                    fontWeight:400,
                    lineHeight:'16.34px'
                  }}
                >
                  {intlMessages.loginNewsletterText2?.charAt(0)?.toUpperCase() + intlMessages.loginNewsletterText2?.slice(1)}
                </a> |  <a
                 href="terms.html"
                 target="_blank"
                  style={{
                    cursor: "pointer",
                    textDecoration: "none",
                    fontSize: "12px",
                    color:'#5e9beb',
                    fontWeight:400,
                    lineHeight:'16.34px'
                  }}
                >
                  {intlMessages.loginNewsletterText4?.charAt(0)?.toUpperCase() + intlMessages.loginNewsletterText4?.slice(1)}
                </a>
        </Typography> */}
              {/* <Styled.copyrightContainer isMobileLandscape={isMobileLandscape?true:false} isMobile={isMobile?true:false}>
        <Styled.copyrightlabel>
        © {intlMessages.copyrightlabel} 
        <Styled.Anchor
        target="_blank"
        href={ONESCREEN_WEBSITE_URL}>
          {COMPANY_NAME}
        </Styled.Anchor>
        </Styled.copyrightlabel>
      </Styled.copyrightContainer> */}
              {/* <Typography sx={{ fontWeight: 400, fontSize: '12px',color: '#FFFFFF',lineHeight:'16.34px',display: isAboutPage && isMobileLandscape?'block':'none',mt:'0px' }} >
        <a
                  href="privacy-policies.html"
                  target="_blank"
                  style={{
                    cursor: "pointer",
                    textDecoration: "none",
                    fontSize: "12px",
                    color:'#FFFFFF'
                  }}
                >
                  {intlMessages.loginNewsletterText2}
                </a> |  <a
                 href="terms.html"
                 target="_blank"
                  style={{
                    cursor: "pointer",
                    textDecoration: "none",
                    fontSize: "12px",
                    color:'#FFFFFF'
                  }}
                >
                  {intlMessages.loginNewsletterText4}
                </a>
        </Typography> */}
            </Box>
          </Box>
        </Styled.FooterMainContainer>
      )}
    </footer>
  );
}

export default Footer;
