import ReactPixel from "react-facebook-pixel";
import { getAuthToken, getBaseUrlOfBrowser } from "./api/service";
import { store } from "./redux/store";
import {
  setMicrosoftEnabled,
  setGoogleEnabled,
  setDriveEnabled,
  setFormsEnabled,
  setGoogleDriveApiKeyRedux,
  setGoogleDriveClientIdRedux,
  setGuruCallEabled,
  decodeToken,
} from "./common/sign_in_service";
import {
  setCanCaptureImage,
  setCanCopyToClipboard,
  setCanDeleteQuiz,
  setCanEditQuiz,
  setCanExportAikenFile,
  setCanExportGoogleSheet,
  setCanExportPdfFile,
  setCanExportQT12File,
  setCanExportToGoogleForm,
  setCanExportTxtFile,
  setCanExportXMLFile,
  setCanExtractDataFromUrl,
  setCanImportFromGoogleDrive,
  setCanImportSampleText,
  setCanImportTextFromSystem,
  setCanManageProfile,
  setCanSaveQuiz,
  setCanShareQuiz,
  setCanUploadExcelSheet,
  setCanUploadGoogleDoc,
  setCanUploadGoogleSheet,
  setCanUploadGoogleSlide,
  setCanUploadImage,
  setCanUploadPdf,
  setCanUploadPowerPointSlide,
  setCanUploadWordDoc,
  setGuruSupport,
  setQuestionCount,
  setQuizContentLimit,
  setDifficultyLevel,
  setQuizGenerationCount,
  setQuizGenerationType,
  setTrialDays,
} from "./redux/slices/featuresSlice";
import {
  setQuizCount,
  setQuizDifficultyLevel,
  setQuizType,
} from "./redux/slices/quizSlice";
import {
  setBasicPlanPrice,
  setProMonthlyPlanPrice,
  setProYearlyPlanPrice,
} from "./redux/slices/paymentSlice";
import { setStripeStatus } from "./redux/slices/logInSlice";
import { Categories, Events, logEvent } from "./analytics";
let google_ads_key = null;
let fb_ads_key = null;

export const initAdds = async () => {
  const authToken = getAuthToken();
  let params = new URL(document.location).searchParams;
  let queryParams = "";
  let header = {}
  if (!!authToken) {
    const { username } = await decodeToken(authToken);
    header['Authorization'] = `Bearer ${authToken}`
    queryParams = new URLSearchParams({ email: username });
  }
  fetch(`${getBaseUrlOfBrowser()}/api/v1/adds/settings?${queryParams}`, {
    method: "GET",
    headers: header,
  })
    .then((response) => response.json())
    .then((conf) => {
      const subscriptionList = conf.subscriptionList;
      store.dispatch(setTrialDays(subscriptionList.trialDays));
      store.dispatch(
        setQuizGenerationCount(subscriptionList.quizGenerationCount)
      );
      store.dispatch(setQuizContentLimit(subscriptionList.quizContentLimit));
      store.dispatch(setQuestionCount(subscriptionList.questionCount));
      store.dispatch(setQuizGenerationType(subscriptionList.quizType));
      store.dispatch(setDifficultyLevel(subscriptionList.quizDifficultyLevel));
      store.dispatch(
        setCanImportSampleText(subscriptionList.canImportSampleText)
      );
      store.dispatch(
        setCanImportTextFromSystem(subscriptionList.canImportTextFromSystem)
      );
      store.dispatch(
        setCanImportFromGoogleDrive(subscriptionList.canImportFromGoogleDrive)
      );
      store.dispatch(setCanUploadPdf(subscriptionList.canUploadPDF));
      store.dispatch(
        setCanUploadGoogleSheet(subscriptionList.canUploadGoogleSheet)
      );
      store.dispatch(
        setCanUploadExcelSheet(subscriptionList.canUploadExcelSheet)
      );
      store.dispatch(
        setCanUploadPowerPointSlide(subscriptionList.canUploadPowerPointSlide)
      );
      store.dispatch(
        setCanUploadGoogleSlide(subscriptionList.canUploadGoogleSlide)
      );
      store.dispatch(setCanUploadWordDoc(subscriptionList.canUploadWordDoc));
      store.dispatch(
        setCanUploadGoogleDoc(subscriptionList.canUploadGoogleDoc)
      );
      store.dispatch(setCanUploadImage(subscriptionList.canUploadImage));
      store.dispatch(setCanCaptureImage(subscriptionList.canCaptureImage));
      store.dispatch(
        setCanExtractDataFromUrl(subscriptionList.canExtractDataFromUrl)
      );
      store.dispatch(setCanSaveQuiz(subscriptionList.canSaveQuiz));
      store.dispatch(setCanEditQuiz(subscriptionList.canEditQuiz));
      store.dispatch(setCanDeleteQuiz(subscriptionList.canDeleteQuiz));
      store.dispatch(setCanShareQuiz(subscriptionList.canShareQuiz));
      store.dispatch(setCanManageProfile(subscriptionList.canManageProfile));
      store.dispatch(setGuruSupport(subscriptionList.guruSupport));
      store.dispatch(setCanExportPdfFile(subscriptionList.canExportPdfFile));
      store.dispatch(setCanExportTxtFile(subscriptionList.canExportTxtFile));
      store.dispatch(setCanExportXMLFile(subscriptionList.canExportXMLFile));
      store.dispatch(
        setCanExportAikenFile(subscriptionList.canExportAikenFile)
      );
      store.dispatch(setCanExportQT12File(subscriptionList.canExportQT12File));
      store.dispatch(
        setCanExportGoogleSheet(subscriptionList.canExportGoogleSheet)
      );
      store.dispatch(
        setCanExportToGoogleForm(subscriptionList.canExportToGoogleForm)
      );
      store.dispatch(
        setCanCopyToClipboard(subscriptionList.canCopyToClipboard)
      );
      store.dispatch(setQuizType("Multiple choice"));
      store.dispatch(setQuizCount(5));
      store.dispatch(setQuizDifficultyLevel("Medium"));
      subscriptionList.stripeStatus &&
        store.dispatch(setStripeStatus(subscriptionList.stripeStatus));
      store.dispatch(
        setBasicPlanPrice(conf.productPriceDetails.basicPrice || "0.00")
      );
      store.dispatch(
        setProMonthlyPlanPrice(conf.productPriceDetails.monthlyPrice || "0.00")
      );
      store.dispatch(
        setProYearlyPlanPrice(conf.productPriceDetails.yearlyPrice || "0.00")
      );
      const subscriptionPlan =
        store.getState().logIn.userData?.subscriptionPlan || "Basic";
      if (subscriptionPlan === "Pro") {
        logEvent(Categories.start_adding, Events.user_subscription_pro_plan);
      } else if (subscriptionPlan === "Trial") {
        logEvent(Categories.start_adding, Events.user_subscription_trial_plan);
      } else {
        logEvent(Categories.start_adding, Events.user_subscription_basic_plan);
      }
      if (conf.facebook_ads_key) {
        const advancedMatching = { em: null };
        fb_ads_key = conf.facebook_ads_key;
        const options = {
          autoConfig: true,
          debug: true,
        };
        ReactPixel.init(conf.facebook_ads_key, advancedMatching, options);
      }
      google_ads_key = conf.google_ads_key;
      setMicrosoftEnabled(conf.microsoft_enabled);
      setGoogleEnabled(conf.google_enabled);
      setDriveEnabled(conf.drive_enabled);
      setFormsEnabled(conf.forms_enabled);
      setGoogleDriveApiKeyRedux(conf.google_apiKey);
      setGoogleDriveClientIdRedux(conf.google_clientId);
      setGuruCallEabled(conf.guru_enabled);
      let utm_source = params.get("utm_source");
      if (utm_source) {
        sessionStorage.setItem("utm_source", utm_source);
        sessionStorage.setItem("fb_covSent_event", false);
        sessionStorage.setItem("fb_covSent_event_signup", false);
      }
    })
    .catch((error) => {
      // console.error(error);
    });
};
export const sendPageView = () => {
  ReactPixel.pageView();
};
export const sendConversionEvent = () => {
  let params = new URL(document.location).searchParams;
  let utm_source = sessionStorage.getItem("utm_source");
  let gtm_debug = params.get("gtm_debug");
  let isConversionSend = sessionStorage.getItem("fb_covSent_event");

  if (utm_source == "facebook" && fb_ads_key && isConversionSend == "false") {
    ReactPixel.trackCustom("conversion", {
      value: 0.0,
      currency: "USD",
    });
    sessionStorage.setItem("fb_covSent_event", true);
  }
  if ((gtm_debug || utm_source == "google") && google_ads_key) {
    //window.gtag('config', google_ads_key);
    window.gtag("event", "conversion", {
      send_to: `${google_ads_key}/tYPtCNWn85gYEJSy0cUp
          `,
    });
  }

  if (!utm_source) return;
  const url = window.location.href;
  const cleanUrl = url.split("?")[0];
  window.history.replaceState({}, document.title, cleanUrl);
};

export const sendSignUpConversionEvent = () => {
  let params = new URL(document.location).searchParams;
  let utm_source = sessionStorage.getItem("utm_source");
  let gtm_debug = params.get("gtm_debug");
  let isConversionSend = sessionStorage.getItem("fb_covSent_event_signup");

  if (utm_source == "facebook" && fb_ads_key && isConversionSend == "false") {
    ReactPixel.trackCustom("signup", {
      value: 0.0,
      currency: "USD",
    });
    sessionStorage.getItem("fb_covSent_event_signup", true);
  }
  if ((gtm_debug || utm_source == "google") && google_ads_key) {
    //window.gtag('config', google_ads_key);
    window.gtag("event", "signup", {
      send_to: `${google_ads_key}/tYPtCNWn85gYEJSy0cUp
        `,
    });
  }

  if (!utm_source) return;
  const url = window.location.href;
  const cleanUrl = url.split("?")[0];
  window.history.replaceState({}, document.title, cleanUrl);
};

export const sendSigUpEmailConversionEvent = () => {
  let params = new URL(document.location).searchParams;
  let utm_source = sessionStorage.getItem("utm_source");
  let gtm_debug = params.get("gtm_debug");
  let isConversionSendEmail = sessionStorage.getItem("fb_covSent_event_email");

  // if(utm_source  == "facebook" && fb_ads_key && !isConversionSendEmail) {
  //         ReactPixel.trackCustom('signup_by_email', {
  //             value: 0.0,
  //             currency: 'USD'
  //         });
  // sessionStorage.setItem("fb_covSent_event_email",true);

  // }
  if ((gtm_debug || utm_source == "google") && google_ads_key) {
    //window.gtag('config', google_ads_key);
    window.gtag("event", "signup_by_email", {
      send_to: `${google_ads_key}/tYPtCNWn85gYEJSy0cUp
        `,
    });
  }

  if (!utm_source) return;
  const url = window.location.href;
  const cleanUrl = url.split("?")[0];
  window.history.replaceState({}, document.title, cleanUrl);
};

export const sendSigUpSocialConversionEvent = () => {
  let params = new URL(document.location).searchParams;
  let utm_source = sessionStorage.getItem("utm_source");
  let gtm_debug = params.get("gtm_debug");
  let conv = sessionStorage.getItem("fb_covSent_event_social");

  // if(utm_source  == "facebook" && fb_ads_key && !conv) {
  //         ReactPixel.trackCustom('signup_by_social', {
  //             value: 0.0,
  //             currency: 'USD'
  //         });
  // sessionStorage.setItem("fb_covSent_event_social",true);

  //}
  if ((gtm_debug || utm_source == "google") && google_ads_key) {
    //window.gtag('config', google_ads_key);
    window.gtag("event", "signup_by_social", {
      send_to: `${google_ads_key}/tYPtCNWn85gYEJSy0cUp
          `,
    });
  }

  if (!utm_source) return;
  const url = window.location.href;
  const cleanUrl = url.split("?")[0];
  window.history.replaceState({}, document.title, cleanUrl);
};
