import wordsCount from "words-count";
import { checkInternetConnection } from "../../api/service";
import { useState, useEffect } from "react";
import {
  MAX_ALLOWED_ANSWERS,
  MAX_QUIZ_WORDS,
  MIN_QUIZ_WORDS,
  TEXT_EXPORT_FILE_TYPE,
  PDF_EXPORT_FILE_TYPE,
  CLIPBOARD_EXPORT_TYPE,
  CORRECT_OPTION_SYMBOL,
  CORRECT_OPTION_SYMBOL_PDF,
  MAX_TXT_FILE_UPLOAD_SIZE,
  COMMENT_MAX_WORD_COUNT,
  APP_NAME,
  COMPANY_NAME,
  MAX_WORD_LENGTH,
} from "../../settings";
import {
  generateQuizFromParagraph,
  exportFileFromApi,
  saveSelectedQuzies,
  saveSelectedQuziesPdf,
  saveFeedBack,
  getSampleText,
  saveQuizToLibrary,
  generateQuizFromImage,
  importFile,
  generateQuizFromURL,
  exportXMLFile,
  exportAikenFile,
  exportGoogleSheetFile,
  exportQT12File,
  loadQuizContent,
} from "../../api/quiz/methods";
import { getBaseUrlOfBrowser } from "../../api/service";
import copy from "copy-to-clipboard";
import intlMessages from "../../locales/en";
import { editQuiz, forceCreateQuiz } from "../../api/library/method";

function checkNotOnlySplChar(str) {
  // to check if string contains only special characters
  var validatedStr = str
    .trim()
    .replace(/[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi, "");
  return validatedStr.length > 0;
}

function getSentenceCount(text) {
  if (!text || text.length === 0) return 0;
  const sentences = text.trim().split(".");
  const filteredSentences = sentences.filter(
    (sentence) => sentence.trim().length > 0
  );
  return filteredSentences.length;
}

const validateInput = (i) => {
  let _input = i;
  // if (/^\s/.test(_input)) _input = "";
  return _input;
};

const isStringsEqual = (str1, str2) => {
  const res = str1.localeCompare(str2);
  return res === 0;
};

function validateEmail(inputText) {
  const mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
  return inputText.match(mailformat);
}

const isUrl = (string) => {
  try {
    return Boolean(new URL(string));
  } catch (e) {
    return false;
  }
};

function iOS() {
  return (
    [
      "iPad Simulator",
      "iPhone Simulator",
      "iPod Simulator",
      "iPad",
      "iPhone",
      "iPod",
    ].includes(navigator.platform) ||
    // iPad on iOS 13 detection
    (navigator.userAgent.includes("Mac") && "ontouchend" in document)
  );
}

function getFileFromUrl(dataurl, filename, exportFileType) {
  if (!isUrl(dataurl)) {
    return false;
  }

  // iOS devices handle downloads differently
  if (iOS()) {
    const link = document.createElement("a");
    link.href = dataurl;
    link.download = `${filename}.${exportFileType}`;
    link.target = "_blank";
    link.click();
    return true;
  } else {
    var link = document.createElement("a");
    link.href = dataurl;
    link.download = `${filename}.${exportFileType}`;
    link.target = "_blank";
    link.click();
    return true;
  }
}

function copiedToClipboard(textToCopy) {
  return copy(textToCopy, {
    debug: false,
    message: intlMessages.clipboardMobileCopyMessage,
    format: "text/plain",
  });
}

const validatedQuizText = (str) => {
  let validPara = str
    .trim()
    .split(/\s+/)
    .splice(0, MAX_QUIZ_WORDS)
    .map((word) => {
      if (word.length > MAX_WORD_LENGTH) {
        const firstHalf = word.slice(0, MAX_WORD_LENGTH);
        const secondHalf = word.slice(MAX_WORD_LENGTH);
        return `${firstHalf} ${secondHalf}`;
      }
      return word;
    })
    .join(" ");

  return validPara;
};

const checkIfObjHasAnyEmptyVal = (obj) => {
  const objIsValid = Object.values(obj).every((value) =>
    Boolean(String(value).trim())
  );
  return objIsValid;
};

const isQuestionAndAnswersAreValid = (questionAndAnswers) => {
  let isAnyValEmpty = false;
  questionAndAnswers.forEach((item) => {
    if (item.isChecked) {
      if (!item.question) {
        isAnyValEmpty = true;
        return;
      }
      if (!isAnyCorrectAnswer(item.answers)) {
        isAnyValEmpty = true;
        return;
      }
      item.answers.forEach((ans) => {
        if (!checkIfObjHasAnyEmptyVal(ans)) {
          isAnyValEmpty = true;
          return;
        }
      });
      if (isAnyValEmpty) return;
      if (item.answers.length < 2) {
        isAnyValEmpty = true;
        return;
      }
    }
  });
  return !isAnyValEmpty;
};

const getInvalidParagraphsOfText = (text, MIN_QUIZ_WORDS) => {
  const paragraphs = text.split("\n");
  const invalidParagraphs = false;
  // paragraphs
  //   .filter((para) => para.trim())
  //   .forEach((para, i) => {
  //     const validPara = para.trim();
  //     if (validPara) {
  //       const wordCount = getWordCount(validPara);
  //       if (wordCount < MIN_QUIZ_WORDS) {
  //         invalidParagraphs.push(i + 1);
  //       }
  //     }
  //   });
  const joinedParas = paragraphs
    .filter((para) => para.trim()) // Remove empty paragraphs
    .join(" ");
  const wordCount = getWordCount(joinedParas);
  if (wordCount < MIN_QUIZ_WORDS) {
    return true;
  }
  return invalidParagraphs;
};

const getInternetConnectionStatus = async () => {
  const connectionStatus = await checkInternetConnection();
  return connectionStatus;
};

function getWordCount(text) {
  // var matches = text.match(/\S+/g);
  // return matches ? matches.length : 0;
  // const wordsArray = text?.split(/\s+/);
  // const filteredWordsArray = wordsArray.filter(word => word !== '');
  // return filteredWordsArray.length;
  return wordsCount(text, {
    punctuation: [],
    disableDefaultPunctuation: true,
  });
}

const isAnyCorrectAnswer = (answers) => {
  let isCorrectAns = false;
  answers.forEach((ans) => {
    if (ans.is_correct) {
      isCorrectAns = true;
      return;
    }
  });
  return isCorrectAns;
};

const checkForQuestionAnswerErrorsAndScroll = (
  questionAnswers,
  questionAnswerBoxLabelId
) => {
  let indexIncorrectQuestion = null;
  questionAnswers.every((item, i) => {
    const isItemSelected = item.isChecked;
    const isNotAnyCorrectAns = !isAnyCorrectAnswer(item.answers);
    const isErrorInQuestion = !item.question.trim();
    if (isItemSelected && (isNotAnyCorrectAns || isErrorInQuestion)) {
      indexIncorrectQuestion = i;
      return false;
    }
    item.answers.forEach((ans) => {
      const isErrorInAnswer = !ans.value.trim();
      if (isItemSelected && isErrorInAnswer) {
        indexIncorrectQuestion = i;
        return false;
      }
    });
    if (indexIncorrectQuestion === null) return true;
  });
  if (indexIncorrectQuestion >= 0) {
    const id = `${indexIncorrectQuestion + questionAnswerBoxLabelId}`;
    document.getElementById(id).scrollIntoView({
      behavior: "smooth",
    });
  }
};

const getExportFileName = () => {
  const today = Date.now();
  const newDate = new Intl.DateTimeFormat("en-US", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  })
    .format(today)
    .replace("/", "-");
  const fileName = `${APP_NAME}-${newDate.replace("/", "-")}`;
  return fileName;
};

const getSelectedQuizsCount = (quizs) => {
  let count = 0;
  quizs.forEach((quiz) => {
    if (quiz.isChecked) count += 1;
  });
  return count;
};

const useWindowSize = () => {
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
  });
  useEffect(() => {
    if (iOS()) {
      return;
    }
    function handleResize() {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }
    window?.addEventListener("resize", handleResize);
    handleResize();
    return () => window?.removeEventListener("resize", handleResize);
  }, []);
  return windowSize;
};

const handleImportSampleText = async () => {
  return await getSampleText();
};

const isMobileDevice = () => {
  const userAgent = navigator.userAgent.toLowerCase();
  const mobileKeywords = [
    "mobile",
    "android",
    "iphone",
    "ipod",
    "blackberry",
    "windows phone",
  ];
  for (const keyword of mobileKeywords) {
    if (userAgent.indexOf(keyword) !== -1) {
      if (
        userAgent.indexOf("ipad") === -1 &&
        userAgent.indexOf("tablet") === -1
      ) {
        return true;
      }
    }
  }
  return false;
};

const getQuestionAnswer = (quesAnsPairs) => {
  const isTl7 = navigator?.userAgent?.indexOf("OneScreen_TL7") >= 0;
  const checkedQueAndAns = [];
  const checkedQueAndAnsWithCorrectOptions = [];
  let count = 0;
  quesAnsPairs.forEach((item) => {
    if (item.isChecked) {
      let answers = [];
      count += 1;
      let correctAnswers = [];
      item.answers.forEach((ans) => {
        if (ans.is_correct) {
          answers.push(`${ans.value}`);
          correctAnswers.push(
            `${ans.value} ${
              "pdf" === PDF_EXPORT_FILE_TYPE
                ? true
                  ? CORRECT_OPTION_SYMBOL_PDF.replace("{0}", 20)
                  : CORRECT_OPTION_SYMBOL_PDF.replace("{0}", 10)
                : isTl7
                ? " (Correct)"
                : CORRECT_OPTION_SYMBOL
            }`
          );
        } else {
          answers.push(ans.value);
          correctAnswers.push(`${ans.value}`);
        }
      });
      checkedQueAndAns.push({
        question: item.question,
        answers: answers,
      });
      checkedQueAndAnsWithCorrectOptions.push({
        question: item.question,
        answers: correctAnswers,
      });
    }
  });
  return { checkedQueAndAns, checkedQueAndAnsWithCorrectOptions };
};

const saveQuiz = async (title, quizData) => {
  const response = await saveQuizToLibrary(title, quizData);
  return response;
};

const updateQuiz = async (id, title, quizData) => {
  const response = await editQuiz(id, title, quizData);
  return response;
};

const forceUpdateQuiz = async (title, quizData) => {
  const response = await forceCreateQuiz(title, quizData);
  return response;
};

const generateQuizFromCapturedImage = async (file) => {
  const response = await generateQuizFromImage(file);
  return response;
};

const importUploadFile = async (file) => {
  const response = await importFile(file);
  return response;
};

const generateContentFromURL = async (url) => {
  const response = await generateQuizFromURL(url);
  return response;
};

const isValidURL = (url) => {
  const urlRegex = /^[^\s/$.?#].[^\s]*$/i;
  return urlRegex.test(url);
};

const wordsWithSubstring = (text, words,limit) => {
  if (!words || words.length === 0) {
    return text;
  }

  if (words && words.length <= limit) {
    return text;
  }

  const punctuationReg = /[–’]/g;
  text = text.replace(punctuationReg, "");

  // Limit the number of words to 6000
  const limitedWords = words.slice(0, limit);

  let lastPosition = 0;
  limitedWords.map((word) => {
    const currentPosition = text.indexOf(word, lastPosition);
    if (currentPosition < 0) {
      return 0;
    }

    lastPosition = currentPosition + word.length; // Update lastPosition for the next search
    return currentPosition;
  });

  // Get the substring from the original string until the last position and trim
  const substring = text.slice(0, lastPosition).trim();
  return substring;
};

const exportXML = async (
  questionAndAnswers,
  quiz_id,
  file_type,
  quiz_hash,
  quiz_Type,
  isWithCorrectAns,
  file_type_flag,
  exportId,
  feedbackId
) => {
  const response = await exportXMLFile(
    questionAndAnswers,
  quiz_id,
  file_type,
  quiz_hash,
  quiz_Type,
  isWithCorrectAns,
  file_type_flag,
  exportId,
  feedbackId
  );
  return response;
};

const exportAiken = async (
  questionAndAnswers,
  quiz_id,
  file_type,
  quiz_hash,
  quiz_Type,
  isWithCorrectAns,
  file_type_flag,
  exportId,
  feedbackId
) => {
  const response = await exportAikenFile(
    questionAndAnswers,
    quiz_id,
    file_type,
    quiz_hash,
    quiz_Type,
    isWithCorrectAns,
    file_type_flag,
    exportId,
    feedbackId
  );
  return response;
};

const exportQT12 = async (
  questionAndAnswers,
  quiz_id,
  file_type,
  quiz_hash,
  quiz_Type,
  isWithCorrectAns,
  file_type_flag,
  exportId,
  feedbackId
) => {
  const response = await exportQT12File(
    questionAndAnswers,
  quiz_id,
  file_type,
  quiz_hash,
  quiz_Type,
  isWithCorrectAns,
  file_type_flag,
  exportId,
  feedbackId
  );
  return response;
};

const exportGoogleSheet = async (
  questionAndAnswers,
  quiz_id,
  file_type,
  quiz_hash,
  quiz_Type,
  isWithCorrectAns,
  file_type_flag,
  exportId,
  feedbackId
) => {
  const response = await exportGoogleSheetFile(
    questionAndAnswers,
  quiz_id,
  file_type,
  quiz_hash,
  quiz_Type,
  isWithCorrectAns,
  file_type_flag,
  exportId,
  feedbackId
  );
  return response;
};

const loadScrybeContent = async (id)=>{
  return await loadQuizContent(id)
}

export {
  checkNotOnlySplChar,
  getSentenceCount,
  getWordCount,
  validateInput,
  validateEmail,
  isUrl,
  getFileFromUrl,
  copiedToClipboard,
  validatedQuizText,
  checkIfObjHasAnyEmptyVal,
  isQuestionAndAnswersAreValid,
  getInvalidParagraphsOfText,
  getInternetConnectionStatus,
  isStringsEqual,
  generateQuizFromParagraph,
  exportFileFromApi,
  getBaseUrlOfBrowser,
  isAnyCorrectAnswer,
  checkForQuestionAnswerErrorsAndScroll,
  getSelectedQuizsCount,
  getExportFileName,
  iOS,
  useWindowSize,
  saveFeedBack,
  saveSelectedQuzies,
  saveSelectedQuziesPdf,
  MAX_ALLOWED_ANSWERS,
  MAX_QUIZ_WORDS,
  MIN_QUIZ_WORDS,
  TEXT_EXPORT_FILE_TYPE,
  PDF_EXPORT_FILE_TYPE,
  CLIPBOARD_EXPORT_TYPE,
  CORRECT_OPTION_SYMBOL,
  MAX_TXT_FILE_UPLOAD_SIZE,
  CORRECT_OPTION_SYMBOL_PDF,
  COMMENT_MAX_WORD_COUNT,
  COMPANY_NAME,
  APP_NAME,
  handleImportSampleText,
  getQuestionAnswer,
  isMobileDevice,
  saveQuiz,
  updateQuiz,
  forceUpdateQuiz,
  generateQuizFromCapturedImage,
  importUploadFile,
  isValidURL,
  generateContentFromURL,
  wordsWithSubstring,
  exportXML,
  exportAiken,
  exportQT12,
  exportGoogleSheet,
  loadScrybeContent
};
