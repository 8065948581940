import api from "../index";
import { checkInternetConnection } from "../service";

  async function forgotPassword(email) {
    try {
      const payload = {
        email:email
      }
      const connectionStatus = await checkInternetConnection();
      const isInternetConnected = connectionStatus.isConnected;
      if (!isInternetConnected)
        return connectionStatus;
      const res = await api.post(`/user/forgotpassword`,payload);
      return res;
    } catch (e) {
      return e;
    }
  }

  async function resetPassword(token,password){
    try {
      const payload = {
        token:token,
        password:password
      }
      const connectionStatus = await checkInternetConnection();
      const isInternetConnected = connectionStatus.isConnected;
      if (!isInternetConnected)
        return connectionStatus;
      const res = await api.post(`/user/resetpassword`,payload);
      return res;
    } catch (e) {
      return e;
    }
  }

export {
    forgotPassword,
    resetPassword
}