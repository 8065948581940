import api from "../../api";
import { checkInternetConnection } from "../../api/service";

const getInternetConnectionStatus = async () => {
  const connectionStatus = await checkInternetConnection();
  return connectionStatus;
};

const handleErrorMsg = (e) => {
  if (e.response) {
    let resMessage = e?.response?.data?.message;
    if (Array.isArray(resMessage)) {
      resMessage = resMessage[0];
    }
    e.response.isConnected = false;
    e.response.data.message = resMessage;
    return e?.response;
  }
  return e;
};

async function updateStatus(isSkipped, userInfo) {
  try {
    const payload = {
      isSkipped,
      userInfo,
    };
    const res = await api.patch(`/user/updateUserInfo`, payload);
    return res;
  } catch (e) {
    return handleErrorMsg(e);
  }
}

export { getInternetConnectionStatus, updateStatus };
