import styled from "@mui/system/styled";
import { Typography, Button, IconButton, Checkbox, Box } from "@mui/material";

const crossBtn = styled(IconButton)(({ theme }) => ({
  width: "14px",
  height: "14px",
  color: "black",
  marginTop: "10px",
}));

const qLogo = styled("img")(({ theme, isMobile, isMobileLandscape }) => ({
  width: isMobile ? "72px" : isMobileLandscape ? "60px" : "95px",
  height: isMobile ? "72px" : isMobileLandscape ? "60px" : "95px",
  display: "block",
  margin: "auto",
}));

const welcomeHeading = styled(Typography)(
  ({ theme, isMobile, isMobileLandscape, provider }) => ({
    width: isMobile ? "240px" : "440px",
    marginTop: isMobile
      ? "15px"
      : isMobileLandscape && !provider
      ? "15px"
      : "20px",
    color: "#231F20",
    fontFamily: "Open Sans",
    fontWeight: isMobile ? "700" : "600",
    lineHeight: isMobile ? "32px" : "20px",
    fontSize: "24px",
    textAlign: "center",
  })
);

const welcomeDesc = styled(Typography)(
  ({ theme, isMobile, isMobileLandscape, provider }) => ({
    marginTop: isMobile
      ? "15px"
      : isMobileLandscape && !provider
      ? "10px"
      : "20px",
    width: isMobile ? "240px" : "440px",
    fontFamily: "Open Sans",
    color: "#231F20",
    fontWeight: "400",
    lineHeight: "24px",
    fontSize: isMobile || isMobileLandscape ? "14px" : "18px",
    textAlign: "center",
    marginBottom: isMobile
      ? "15px"
      : isMobileLandscape && !provider
      ? "0px"
      : "20px",
  })
);

const closeBtn = styled(Button)(({ theme, isMobile, isMobileLandscape }) => ({
  backgroundColor: "#7DC243",
  width: "96.44px",
  height: "33px",
  borderRadius: "4px",
  color: "white",
  margin: "auto",
  textTransform: "none",
  marginBottom: isMobileLandscape ? "20px" : "unset",
  marginTop: isMobile ? "-22px" : "unset",
  "&:hover": {
    backgroundColor: "#89d44a",
  },
}));

const checkBox = styled(Checkbox)(({ theme, isMobile, isMobileLandscape }) => ({
  marginLeft: isMobile ? "-5px" : isMobileLandscape ? "-10px" : "10px",
  marginBottom: "5px",
  "&.Mui-checked": {
    color: "#7DC242",
  },
}));

const unCheckedIcon = styled(Box)(({ theme }) => ({
  border: "2px solid #D5D5D5",
  width: "24px",
  height: "24px",
  borderRadius: "4px",
}));

const newslettersText = styled(Typography)(({ theme }) => ({
  color: "#777777",
  fontFamily: "Open Sans",
  fontWeight: "400",
  lineHeight: "20px",
  fontSize: "12px",
  cursor:'pointer'
}));

export default {
  closeBtn,
  crossBtn,
  qLogo,
  welcomeDesc,
  welcomeHeading,
  checkBox,
  unCheckedIcon,
  newslettersText,
};
