import { useEffect, useState } from "react";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import { IconButton, Button } from "@mui/material";
import Styled from "./styles";
import intlMessages from "../../locales/en";
import { Categories, Events, logEvent } from "../../analytics";
import { useSelector } from "react-redux";
import { verifyNumber } from "./service";
const IFPSModal = (props) => {
  const { userInformation } = useSelector((state) => state.logIn);
  const [isSaving, setIsSaving] = useState(false);
  const [serialNumber, setSerialNumber] = useState("");
  const [errorText, setErrorText] = useState("");
  const {
    isMobile,
    isMobileLandscape,
    isIpad,
    isIpadLandscape,
    open,
    onClose,
    openFeedbackModal,
    subscriptionModalFlag
  } = props;

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      handleSubmit();
    }
  };
  const handleSubmit = async () => {
    logEvent(
      Categories.smart_screen_modal,
      Events.smart_screen_modal_submit_btn_clicked
    );
    if (!serialNumber || serialNumber.trim().length === 0) {
      setErrorText(intlMessages.Serialnumbershouldnotbeempty);
      return;
    }
    setIsSaving(true);
    const isValidSerialNumber = await verifyNumber(serialNumber);
    if (isValidSerialNumber?.data?.status == "1") {
      setErrorText(intlMessages.Pleaseenteravalidserialnumber);
    } else if (
      isValidSerialNumber?.data?.status == "2" &&
      isValidSerialNumber?.data?.message == "Invalid serial number."
    ) {
      setErrorText(intlMessages.Pleaseenteravalidserialnumber);
    } else if (
      isValidSerialNumber?.data?.status == "2" &&
      isValidSerialNumber?.data?.message == "already exists"
    ) {
      setErrorText(intlMessages.Thisserialnumberalreadybindedwithanaccount);
    } else if (isValidSerialNumber?.data?.status == "3") {
      setErrorText(intlMessages.Couponservicecannotbeavailmorethanonetime);
    } else if (
      isValidSerialNumber?.data?.status == "0" &&
      isValidSerialNumber?.data?.message ==
        "subscription created against the serial number"
    ) {
      onClose(false, true, false);
    }
    setIsSaving(false);
  };
  const handleSerialNumberChange = (event) => {
    const serialNumber = event.target.value;
    if (serialNumber.length > 0) {
      setErrorText("");
    }
    setSerialNumber(serialNumber);
  };

  return (
    <>
      <Modal
        open={open}
        aria-labelledby="review-modal"
        aria-describedby="review-description"
      >
        <Styled.ModalPrmissionSignInBox sx={Styled.customStyling.exportModal}>
          <Styled.ModalHeader>
            <Typography
              id="review-modal-title"
              variant="h5"
              component="h5"
              style={{ fontWeight: "bold" }}
            >
              {intlMessages.SelectedPaymentMethod}
            </Typography>
            <IconButton
              aria-label="closeReviewModal"
              onClick={() => {
                logEvent(
                  Categories.smart_screen_modal,
                  Events.smart_screen_modal_close_btn_clicked
                );
                if(subscriptionModalFlag===true){
                  return onClose(false,false,false)
                }
                onClose(false, false, true);
              }}
            >
              <CloseIcon color="seondary.light" />
            </IconButton>
          </Styled.ModalHeader>
          <Styled.ModalContentBox>
            <Styled.ContainerBox
            // isMobile={
            //   isMobile || isMobileLandscape || isIpad || isIpadLandscape
            // }
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "flex-start",
                  // width: isMobile || isMobileLandscape || isIpad ? "100%" : "",
                }}
                onClick={() => {}}
              >
                <Styled.HeadingTextContainer>
                  <Styled.HeadingTextTypography>
                    {intlMessages.Subscribetoprotrial}
                  </Styled.HeadingTextTypography>
                  <Styled.HeadingTextTypography
                    style={{
                      fontWeight: "600",
                      fontSize: "18px",
                      lineHeight: "24.51px",
                      mb: "15px",
                    }}
                  >
                    {intlMessages.IownOneScreenTouchscreendevice}
                  </Styled.HeadingTextTypography>
                </Styled.HeadingTextContainer>
                <Styled.largeTextField
                  placeholder={intlMessages.Enterdeviceserialnumber}
                  size="small"
                  variant="outlined"
                  error={errorText.length > 0 ? true : false}
                  helperText={errorText}
                  onChange={(e) => handleSerialNumberChange(e)}
                  onKeyDown={handleKeyDown}
                ></Styled.largeTextField>
                <Styled.SupportText showError={errorText.length > 0 ? true : false}>
                  {intlMessages.IFPSModalSupportText}
                  <span
                    onClick={() => {
                      logEvent(
                        Categories.smart_screen_modal,
                        Events.smart_screen_modal_contact_support_btn_clicked
                      );
                      openFeedbackModal(true, true);
                    }}
                    style={{
                      color: "#2062BB",
                      marginLeft: "2px",
                      cursor: "pointer",
                    }}
                  >
                    {intlMessages.IFPSModalSupportText2}
                  </span>
                </Styled.SupportText>
              </Box>
            </Styled.ContainerBox>
          </Styled.ModalContentBox>
          <Styled.ModalActions>
            <Styled.SubmitButton
              variant="contained"
              color="secondary"
              onClick={() => handleSubmit()}
              sx={{
                opacity: isSaving ? 0.5 : 1,
                pointerEvents: isSaving ? "none" : "auto",
              }}
            >
              {intlMessages.submitLabel}
            </Styled.SubmitButton>
            <Button
              sx={{
                textTransform: "none",
                marginLeft: 2,
                width: "105px",
                opacity: isSaving ? 0.5 : 1,
                pointerEvents: isSaving ? "none" : "auto",
              }}
              variant="outlined"
              color="secondary"
              onClick={() => {
                logEvent(
                  Categories.smart_screen_modal,
                  Events.smart_screen_modal_close_btn_clicked
                );
                if(subscriptionModalFlag===true){
                  return onClose(false,false,false)
                }
                onClose(false, false, true);
              }}
              // disabled={userRolesArray.length < 1 ? true : false}
            >
              {intlMessages.cancelLabel}
            </Button>
          </Styled.ModalActions>
        </Styled.ModalPrmissionSignInBox>
      </Modal>
    </>
  );
};

export default IFPSModal;
