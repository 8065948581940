import { useState, useEffect } from "react";
import { useTheme } from "@mui/material/styles";
import { useLocation, useNavigate } from "react-router-dom";
import Footer from "../footer/component";
import intlMessages from "../../locales/en";
import Styled from "./styles";
import ConnectionModal from "../connection-modal/component";
import { Box, InputAdornment, useMediaQuery } from "@mui/material";
import { validateInput, validatePassword } from "../sign-up/service";
import { resetUserPassword } from "./service";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import AlertBox from "../alert/component";
import CircularProgress from "@mui/material/CircularProgress";
import { Categories, Events, logEvent } from "../../analytics";
import { getToken, userLogOut } from "../../common/sign_in_service";
import { iOS, isMobileDevice } from "../quiz/service";
import { getNavigateURL } from "../../util/signin-user-data";

function ResetPassword() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"), {
    noSsr: true,
  });
  const isMobileLandscape = useMediaQuery(
    `${theme.breakpoints.down(
      "md"
    )}  and (orientation: landscape) and (hover: none) and (pointer: coarse)`,
    {
      noSsr: true,
    }
  );

  const isIpad = useMediaQuery(
    "(min-width:620px) and (max-width:840px) and (orientation: portrait) and  (hover: none) and (pointer: coarse)",
    {
      noSsr: true,
    }
  );
  const isIpadLandscape = useMediaQuery(
    "(min-width: 768px) and (max-width: 1024px) and (orientation: landscape) and  (hover: none) and (pointer: coarse)",
    {
      noSsr: true,
    }
  );
  const navigate = useNavigate();
  const [isSaving, setIsSaving] = useState(false);
  const [openConnectionModal, setIsOpenConnectionModal] = useState(false);
  const [openAlertBox, setIsOpenAlertBox] = useState(false);
  const [error, setError] = useState({
    password: "",
    confirmPassword: "",
  });
  const [showpassword, setShowPassword] = useState(false);
  const [showconfirmpassword, setShowConfirmPassword] = useState(false);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [message, setMessage] = useState({
    message: "",
    icon: "",
  });
  const [msg, setMsg] = useState(false);
  const location = useLocation();
  const [addDelay, setAddDelay] = useState(true);
  const [mainBoxHeight,setMainBoxHeight]= useState(0)


  useEffect(() => {
    handleOrientationChange();
    window.addEventListener("resize", handleOrientationChange);
    return () => {
      window.removeEventListener("resize", handleOrientationChange);
    };
  }, []);

  useEffect(()=>{
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  },[])

  async function handleOrientationChange() {
    const screenStats = window.screen;
    let mainBoxHeight_;
    switch (true) {
      case (navigator.standalone === "boolean" ||
        navigator.userAgent.match(/iPhone/i) ||
        navigator.userAgent.match(/iPod/i)) &&
        iOS() &&
        window.matchMedia(
          "screen and (max-device-height: 900px) and (orientation: landscape)"
        ).matches:
        mainBoxHeight_ = 160;
        break;
      case window.matchMedia(
        "(min-width: 800px) and (max-width: 1400px) and (orientation: portrait) and (hover: none) and (pointer: coarse)"
      ).matches:
        mainBoxHeight_ = screenStats.availHeight * 0.74; // iPad Portrait
        break;
      case screenStats.availHeight > 600 && screenStats.availHeight <= 700 && window.matchMedia('(pointer: coarse)').matches:     
        mainBoxHeight_ = screenStats.availHeight; // Small mobiles
        break;
      case screenStats.availHeight > 650 && screenStats.availHeight <= 700:
        mainBoxHeight_ = screenStats.availHeight - 260;
        break;
      case screenStats.availHeight > 700 &&
        screenStats.availHeight < 750 &&
        window.matchMedia("(pointer: fine)").matches &&
        window.screen.width < 1100:
        mainBoxHeight_ = screenStats.availHeight - 275; // Mobile landscape
        break;
      case screenStats.availWidth > 750 && screenStats.availWidth <= 960 && screenStats.availHeight < 930 : //mobile Landscape
      if(window.innerHeight>350){
        mainBoxHeight_ = screenStats.availHeight-200 ;
      }
      else{
        mainBoxHeight_ = screenStats.availHeight-250
      }
        break;
      case screenStats.availHeight > 800 && window.matchMedia('(orientation: portrait)').matches && window.matchMedia('(pointer: coarse)').matches:
        mainBoxHeight_ = screenStats.availHeight - 450 // iPad
        break;
      case screenStats.availHeight === 720 && screenStats.availWidth === 1280:
        var headerHeight = document.getElementById('header').offsetHeight;
        var footerHeight = document.getElementById('footer2').offsetHeight;
        var headerFooterSum = headerHeight + footerHeight;
        var fullPageHeight = window.innerHeight;
        var contentHeight = fullPageHeight - headerFooterSum;
        mainBoxHeight_ = contentHeight // TL
        break;
      case screenStats.availWidth >= 1920 && screenStats.height > 1000:
        var headerHeight = document.getElementById('header').offsetHeight;
        var footerHeight = document.getElementById('footer2').offsetHeight;
        var headerFooterSum = headerHeight + footerHeight;
        var fullPageHeight = window.innerHeight;
        var contentHeight = fullPageHeight - headerFooterSum;
        mainBoxHeight_ = contentHeight // Desktop
        break;
     case window.matchMedia('(min-width: 800px) and (max-width: 1400px) and (orientation: landscape) and (hover: none) and (pointer: coarse)').matches:
      mainBoxHeight_ = screenStats.availHeight - 535; // iPad Landscape
        if(mainBoxHeight_ < 400) 
          mainBoxHeight_ = 430;
      break;
     case screenStats.height > 800:
      var headerHeight = document.getElementById('header').offsetHeight;
      var footerHeight = document.getElementById('footer2').offsetHeight;
      var headerFooterSum = headerHeight + footerHeight;
      var fullPageHeight = window.innerHeight;
      var contentHeight = fullPageHeight - headerFooterSum;
      mainBoxHeight_ = contentHeight // Desktop
        break;
     default:
        mainBoxHeight_ = screenStats.availHeight - 260;
        if(mainBoxHeight_ < 200) 
           mainBoxHeight_ = 173;
           break;   
    }
    const height = window.innerHeight-200
    var viewport = document.querySelector("meta[name=viewport]");
    isMobileDevice() && !iOS() && (window.screen.orientation.type==="landscape-primary" || window.screen.orientation.type==="landscape-secondary" )?viewport.setAttribute("content", viewport.content + ", height=" + mainBoxHeight_):
    viewport.setAttribute("content", viewport.content + ", height=" + height);
    setMainBoxHeight(mainBoxHeight_);
  }

  const getQueryString = () => {
    const { search } = location;
    const queryParams = new URLSearchParams(search);
    if (queryParams.get("exp") === "1") {
      setMsg(true);
    } else {
      setMsg(false);
    }
  };

  useEffect(() => {
    getQueryString();
    const timer = setTimeout(() => {
      setAddDelay(false);
    }, 100);
    return () => clearTimeout(timer);
  }, []);

  const navigateToLogIn = () => {
    logEvent(
      Categories.user_management,
      Events.user_management_login_click_reset_password_page
    );
    if (getToken() === null) {
      navigate("/login");
    } else {
      const quizURL = getNavigateURL('/')
      navigate(quizURL);
    }
  };

  const handlePassword = (e) => {
    e.preventDefault();
    const inp = validateInput(e.target.value);
    if (inp.length > 0) {
      setError({
        ...error,
        password: null,
        confirmPassword: null,
      });
    }
    if (
      error.password === intlMessages.signUpPasswordEmptyMessage ||
      validatePassword(inp)
    ) {
      setError({
        ...error,
        password: null,
        confirmPassword: null,
      });
    }
    setPassword(inp);
  };
  const handleConfirmPassword = (e) => {
    const inp = validateInput(e.target.value);
    if (inp.length > 0) {
      setError({
        ...error,
        confirmPassword: null,
      });
    }
    if (
      error.confirmPassword === intlMessages.signUpConfirmPasswordEmptyMessage
    ) {
      setError({
        ...error,
        confirmPassword: null,
      });
    }
    setConfirmPassword(inp);
  };

  const passwordValidation = () => {
    if (password.length === 0 && confirmPassword.length === 0) {
      return setError({
        confirmPassword: intlMessages.SignUpEmptyFieldsError,
      });
    }
    if (password.length === 0) {
      return setError({
        ...error,
        password: intlMessages.signUpPasswordEmptyMessage,
      });
    }
    if (!validatePassword(password) && confirmPassword.length !== 0) {
      return setError({
        ...error,
        password: intlMessages.SignUpInvalidPasswordError,
      });
    }
    if (confirmPassword.length == 0) {
      return setError({
        ...error,
        confirmPassword: intlMessages.signUpConfirmPasswordEmptyMessage,
      });
    }
    if (confirmPassword !== password && confirmPassword.length !== 0) {
      return setError({
        ...error,
        confirmPassword: intlMessages.SignUpPasswordDontMatchError,
      });
    }
    return true;
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      submitPassword();
    }
  };

  const submitPassword = () => {
    const queryString = new URLSearchParams(window.location.search);
    const token = queryString.get("token");
    if (passwordValidation()) {
      logEvent(
        Categories.user_management,
        Events.user_management_password_reset_update_click
      );
      setIsSaving(true);
      resetUserPassword(token, password)
        .then((res) => {
          if (res?.data?.statusCode === 0) {
            setIsSaving(false);
            setIsOpenConnectionModal(true);
            return;
          }
          if (
            res?.status === 201 &&
            res?.data?.error === "Invalid or expired token"
          ) {
            setMessage({
              message: intlMessages.resetPasswordErrorMsg,
              icon: "/svgs/erroricon.svg",
              isError: true,
            });
            setIsSaving(false);
            setIsOpenAlertBox(true);
          }
          if (
            res?.status === 201 &&
            res?.data?.message === "Password updated"
          ) {
            setIsSaving(false);
            setMessage({
              message: intlMessages.resetPasswordConfirmationMsg,
              icon: "/svgs/successicon.svg",
              isError: false,
            });
            setIsOpenAlertBox(true);
            userLogOut();
          }
        })
        .catch((error) => {
          setIsSaving(false);
        });
    }
  };

  return (
    <>
      {msg ? (
        <Styled.MainContainer height={mainBoxHeight} isMobile={isMobile?true:false} isMobileLandscape={isMobileLandscape?true:false}  delay={addDelay}>
          <Styled.verifyText
            isMobile={isMobile ? true : false}
            isMobileLandscape={isMobileLandscape ? true : false}
            sx={{ mb: "50px" }}
          >
            {intlMessages.resetPasswordExpiredLinkTitle}
          </Styled.verifyText>
          <Styled.verifyTextDesc
            sx={{ mb: "50px" }}
            isMobile={isMobile ? true : false}
            isMobileLandscape={isMobileLandscape ? true : false}
          >
            {intlMessages.resetPasswordExpiredMsg}
          </Styled.verifyTextDesc>
          <Styled.logOutBtn
            isMobile={isMobile ? true : false}
            isMobileLandscape={isMobileLandscape ? true : false}
            onClick={navigateToLogIn}
          >
            {intlMessages.forgotPasswordLoginBtn}
          </Styled.logOutBtn>
        </Styled.MainContainer>
      ) : (
        <Styled.MainContainer
        height={mainBoxHeight}
        isMobileLandscape={isMobileLandscape?true:false}
          delay={addDelay}
          isMobile={isMobile ? true : false}
        >
          <Styled.verifyText
            isMobile={isMobile ? true : false}
            isMobileLandscape={isMobileLandscape ? true : false}
          >
            {intlMessages.resetPasswordChangePasswordLabel}
          </Styled.verifyText>
          <Styled.verifyTextDesc
            isMobile={isMobile ? true : false}
            isMobileLandscape={isMobileLandscape ? true : false}
          >
            {intlMessages.resetPasswordDesc}
          </Styled.verifyTextDesc>
          <Styled.largeTextField
            isMobile={isMobile ? true : false}
            isMobileLandscape={isMobileLandscape ? true : false}
            isError={error.password ? true : false}
            onChange={handlePassword}
            required
            type={showpassword ? "text" : "password"}
            variant="outlined"
            placeholder={intlMessages.signUpPasswordPlaceholder}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <img src="/svgs/password-icon.svg" alt="Icon" />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  {showpassword ? (
                    <RemoveRedEyeOutlinedIcon
                      style={{ cursor: "pointer" }}
                      onClick={() => setShowPassword(false)}
                    />
                  ) : (
                    <img
                      style={{ cursor: "pointer" }}
                      onClick={() => setShowPassword(true)}
                      src="/svgs/eye-slash.svg"
                      alt="Icon"
                    />
                  )}
                </InputAdornment>
              ),
            }}
            onKeyDown={handleKeyDown}
            helperText={error.password}
            error={
              error.confirmPassword === intlMessages.SignUpEmptyFieldsError ||
              error.confirmPassword ===
                intlMessages.SignUpPasswordDontMatchError
                ? true
                : error.password
                ? true
                : false
            }
          ></Styled.largeTextField>
          <Styled.largeTextField
            isMobile={isMobile ? true : false}
            isMobileLandscape={isMobileLandscape ? true : false}
            onChange={handleConfirmPassword}
            required
            type={showconfirmpassword ? "text" : "password"}
            variant="outlined"
            placeholder={intlMessages.signUpConfirmPasswordlaceholder}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <img src="/svgs/password-icon.svg" alt="Icon" />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  {showconfirmpassword ? (
                    <RemoveRedEyeOutlinedIcon
                      style={{ cursor: "pointer" }}
                      onClick={() => setShowConfirmPassword(false)}
                    />
                  ) : (
                    <img
                      style={{ cursor: "pointer" }}
                      onClick={() => setShowConfirmPassword(true)}
                      src="/svgs/eye-slash.svg"
                      alt="Icon"
                    />
                  )}
                </InputAdornment>
              ),
            }}
            onKeyDown={handleKeyDown}
            isError={error.confirmPassword ? true : false}
            helperText={error.confirmPassword}
            error={
              error.confirmPassword === intlMessages.SignUpEmptyFieldsError
                ? true
                : error.confirmPassword
                ? true
                : false
            }
          ></Styled.largeTextField>
          <Styled.resendBtn
            isMobile={isMobile ? true : false}
            isMobileLandscape={isMobileLandscape ? true : false}
            variant="contained"
            loading={isSaving}
            loadingPosition="start"
            disabled={isSaving ? true : false}
            loadingIndicator={<CircularProgress color="inherit" size={16} />}
            onClick={submitPassword}
          >
            {intlMessages.resetPasswordSubmitBtnText}
          </Styled.resendBtn>
          <Styled.logOutBtn
            isMobile={isMobile ? true : false}
            isMobileLandscape={isMobileLandscape ? true : false}
            onClick={navigateToLogIn}
          >
            {intlMessages.forgotPasswordLoginBtn}
          </Styled.logOutBtn>
        </Styled.MainContainer>
      )}
      <Footer></Footer>
      {openConnectionModal && (
        <ConnectionModal
          isOpen={openConnectionModal}
          handleCloseConnectionModal={() => setIsOpenConnectionModal(false)}
        />
      )}
      {openAlertBox && (
        <AlertBox
          isIpad={isIpad ? true : false}
          isMobile={isMobile ? true : false}
          isMobileLandscape={isMobileLandscape ? true : false}
          isIpadLandscape={isIpadLandscape ? true : false}
          isOpen={openAlertBox}
          onClose={() => {
            setIsOpenAlertBox(false);
            const loginURL = getNavigateURL("/login")
            navigate(loginURL);
          }}
          message={message}
        ></AlertBox>
      )}
    </>
  );
}

export default ResetPassword;
