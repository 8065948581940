import api from "../../api";
import { checkInternetConnection } from "../../api/service";

const getInternetConnectionStatus = async () => {
  const connectionStatus = await checkInternetConnection();
  return connectionStatus;
};

const handleErrorMsg = (e) => {
  if (e.response) {
    let resMessage = e?.response?.data?.message;
    if (Array.isArray(resMessage)) {
      resMessage = resMessage[0];
    }
    e.response.isConnected = false;
    e.response.data.message = resMessage;
    return e?.response;
  }
  return e;
};

async function importFromGoogle(questionAndAnswers, fileType) {
  try {
    const payload = {
      file_id: questionAndAnswers,
      file_mime_type: fileType,
    };
    // const connectionStatus = await checkInternetConnection();
    // const isInternetConnected = connectionStatus.isConnected;
    // if (!isInternetConnected) {
    //   handleErrorMsg();
    //   return connectionStatus;
    // }
    const res = await api.post(`/quiz/import-drive`, payload);
    return res;
  } catch (e) {
    return handleErrorMsg(e);
  }
}

export { getInternetConnectionStatus, importFromGoogle };
