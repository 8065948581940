import Styled from "./styles";
import FormControl from "@mui/material/FormControl";
import { Box, Stack, Typography } from "@mui/material";
import intlMessages from "../../locales/en";
import { FileCopyOutlined } from "@mui/icons-material";
import PictureAsPdfOutlinedIcon from "@mui/icons-material/PictureAsPdfOutlined";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import { useState,useEffect } from "react";
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import {useTheme } from "@mui/material/styles";
import { logEvent , Categories,Events,logPageView ,initGA} from "./../../analytics";
import { useDispatch, useSelector } from "react-redux";
import { setOpenExportMenu } from "../../redux/slices/quizSlice";
 
export default function ExportMenu(props) {
  const { handleMenuItemSelection,isMobileLandscape,isMobile ,isIpad,isIpadLandscape} = props;
  const {  openExportMenu,formsEnabled} = useSelector((state) => state.quiz);
  const {canSaveQuiz,canExportToGoogleForm,canExportGoogleSheet,canExportXMLFile,canExportAikenFile,canExportQT12File}= useSelector((state)=>state.feature)
  const dispatch = useDispatch()
  const onClickMenuItem = (clickedItemText) => {
    // const clickedItemText = e.target.innerText;
    handleClose();
    handleMenuItemSelection(clickedItemText);
  };

  const handleClose = () => {
    dispatch(setOpenExportMenu(false))
  };

  const handleOpen = () => {
    logEvent(Categories.content_for_quiz,Events.export_menu_click);
    dispatch(setOpenExportMenu(true))
  };

  async function handleOrientationChange_ () {
     dispatch(setOpenExportMenu(false))
  }
  useEffect(() => {
    window.addEventListener('orientationchange', handleOrientationChange_);
    return () => {
      window.removeEventListener("orientationchange", handleOrientationChange_);
    };
  }, []);

  useEffect (()=>{
    const handleCtrlZ = (event)=> {
      if ( openExportMenu && event.ctrlKey && event.key === 'z') {
        event.preventDefault();
      }
    }
    window.addEventListener('keydown', handleCtrlZ);
    return ()=>{
      window.removeEventListener('keydown', handleCtrlZ);
    }
  },[openExportMenu])

  return (
    <Styled.BoxContainer  className={'fourth-step'}  isMobileLandscape={isMobileLandscape?true:false} isIpad={isIpad?true:false} >
      <FormControl fullWidth sx={{border:'0.7px solid #7dc242',borderRadius:1, backgroundColor:' #7dc242', width: isMobileLandscape?63:isMobile?140:200,height: isMobile || isMobileLandscape? 'unset':'42px'}}>
        <Styled.SelectInputBase
          IconComponent={ArrowDropUpIcon}
          value={""}
          displayEmpty
          open={openExportMenu}
          onClose={handleClose}
          onOpen={handleOpen}
          isMobile={isMobile ? true : false}
          isMobileLandscape={isMobileLandscape ? true : false}
          MenuProps={{
            sx: Styled.customStyling(useTheme()).menu,
            disableScrollLock: true,
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            },
            transformOrigin: {
              vertical: "bottom",
              horizontal: "right",
            },
            getContentAnchorEl: null,
          }}
          // style={{marginTop: '-5px'}}
        >
          <Styled.StyledMenuItem
            value={""}
            onClick={(e) => onClickMenuItem(e)}
            sx={{
              display: "none",
              width: 15,
              fontSize: "1.0666666666666667rem",
            }}
          >
            {isMobileLandscape?null:isMobile?intlMessages.mobileExportQuizLabel:intlMessages.exportQuizLabel}
            {isMobileLandscape?<br></br>:null}
            <Box sx={{ml: window.innerWidth>970?'0px':'5px',mt:'-5px',display: isMobileLandscape?'block':'none',wordWrap:'break-word'}}>{intlMessages.mobileLandscapeExportLabel}</Box>
          </Styled.StyledMenuItem>
          <Box sx={{ padding: "0 20px" }}>
            <Styled.StyledMenuItemLabel variant="p" color="secondary.light">
                {intlMessages.exportMenuExportAs}
              </Styled.StyledMenuItemLabel>
            <Box sx={{display:'flex', marginTop: '10px'}}>
           <Stack onClick={() => {
              logEvent(Categories.quiz_library,Events.quiz_library_save_button_clicked)
              onClickMenuItem(intlMessages.saveLabel)
              }} >
                <Styled.BoxBtn>
                {canSaveQuiz.enable===false && <img style={{position:'absolute',top:'45.74px',left:'95px'}} src="/svgs/grey-crown.svg"></img>}
                    <Styled.CircledBox>
                    <Styled.SvgIcon src={"/svgs/save-icon.svg"} />
                    </Styled.CircledBox>
                    <Styled.TypographyText variant="body1" >
                      {intlMessages.saveLabel}
                    </Styled.TypographyText>
                </Styled.BoxBtn>
              </Stack>
              <Stack onClick={() => onClickMenuItem(intlMessages.pdf)} >
                <Styled.BoxBtn>
                  <Styled.CircledBox>
                    <Styled.SvgIcon src={"/svgs/pdf.svg"} />
                  </Styled.CircledBox>
                  <Styled.TypographyText variant="body1">
                    {intlMessages.pdf}
                  </Styled.TypographyText>
                </Styled.BoxBtn>
              </Stack>
              <Stack onClick={() => onClickMenuItem(intlMessages.textFile)}>
                <Styled.BoxBtn>
                  <Styled.CircledBox>
                    <Styled.SvgIcon src={"/svgs/text-file.svg"} />
                  </Styled.CircledBox>
                  <Styled.TypographyText variant="body1">
                    {intlMessages.textFile}
                  </Styled.TypographyText>
                </Styled.BoxBtn>
              </Stack>
            </Box>
          </Box>
          <Styled.HorizontalDivider />
          <Box sx={{ padding: "0 20px" }}>
            <Styled.StyledMenuItemLabel variant="p" color="secondary.light">
              {intlMessages.exportMenuSharevia}
            </Styled.StyledMenuItemLabel>
            <Box sx={{ display: "flex", marginTop: "10px" }}>
              <Stack
                onClick={() => onClickMenuItem(intlMessages.googleFormlabes)}
                sx={{ display: formsEnabled ? "" : "none" }}
              >
                <Styled.BoxBtn>
               { canExportToGoogleForm.enable===false && <img style={{position:'absolute',top:'170px',left:'95px'}} src="/svgs/grey-crown.svg"></img>}
                  <Styled.CircledBox>
                    <Styled.SvgIcon src={"/svgs/google-form.svg"} />
                  </Styled.CircledBox>
                  <Styled.TypographyText variant="body1" type="google">
                    {intlMessages.googleFormsLabelCapital}
                  </Styled.TypographyText>
                </Styled.BoxBtn>
              </Stack>
              <Stack
                onClick={() => onClickMenuItem(intlMessages.exportFileLabel)}
              >
                <Styled.BoxBtn>
                {(canExportAikenFile.enable===false && canExportGoogleSheet.enable===false && canExportQT12File.enable===false && canExportXMLFile.enable===false) && <img style={{position:'absolute',top:'170px',left:'205px'}} src="/svgs/grey-crown.svg"></img>}
                  <Styled.CircledBox>
                    <Styled.SvgIcon  src={"/svgs/integrate-file.svg"} />
                  </Styled.CircledBox>
                  <Styled.TypographyText variant="body1" type="google">
                    {intlMessages.exportFileLabel}
                  </Styled.TypographyText>
                </Styled.BoxBtn>
              </Stack>
              <Stack
                onClick={() => onClickMenuItem(intlMessages.cliboardLabel)}
              >
                <Styled.BoxBtn>
                  <Styled.CircledBox>
                    <Styled.SvgIcon src={"/svgs/clipboard.svg"} />
                  </Styled.CircledBox>
                  <Styled.TypographyText variant="body1">
                    {intlMessages.copyToClipboardLabel}
                  </Styled.TypographyText>
                </Styled.BoxBtn>
              </Stack>
            </Box>
          </Box>
          {/* <Box>
            <Styled.StyledMenuItemLabel variant="p" color="secondary.light">
              {intlMessages.postTo}
            </Styled.StyledMenuItemLabel>
            <Styled.StyledMenuItem onClick={(e) => onClickMenuItem(e)}>
              <Styled.MenuItemText>
                <Styled.SvgIcon src={"/svgs/Learning-Hub.svg"} />
                {intlMessages.learningHubLabel}
              </Styled.MenuItemText>
            </Styled.StyledMenuItem>
          </Box> */}
        </Styled.SelectInputBase>
      </FormControl>
    </Styled.BoxContainer>
  );
}
