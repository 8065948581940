import { useState, useEffect } from "react";
import { useTheme } from "@mui/material/styles";
import { useNavigate, useLocation } from "react-router-dom";
import Box from "@mui/material/Box";
import { useDispatch, useSelector } from "react-redux";
import Footer from "../footer/component";
import intlMessages from "../../locales/en";
import aboutImages from "../about-page/about-page-images";
import Styled from "./styles";
import { useMediaQuery } from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import CreateAndGetTokenminiWindow from "../../util/mini_window_handler";
import { decodeToken } from "../../common/sign_in_service";

import {
  setAuthToken,
  setIsSignedIn,
  setUserData,
  setUserState,
} from "../../redux/slices/logInSlice";
import {
  GOOGLE_LOGIN_URL,
  MICROSOFT_LOGIN_URL,
  USER_STATE,
} from "../../settings";
import Alert from "@mui/material/Alert";
import { Snackbar } from "@mui/material";
import AlertTitle from "@mui/material/AlertTitle";
import {
  checkUrlIsGoogleForm,
  getNavigateURL,
} from "../../util/signin-user-data";
import { Categories, Events, logEvent } from "../../analytics";
import { getAuthToken } from "../../api/service";
import { initAdds } from "../../adds_conversion";

function TrialPage() {
  const { microsoftEnabled, googleEnabled } = useSelector(
    (state) => state.quiz
  );
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"), {
    noSsr: true,
  });
  const isMobileLandscape = useMediaQuery(
    `${theme.breakpoints.down(
      "md"
    )}  and (orientation: landscape) and (hover: none) and (pointer: coarse)`,
    {
      noSsr: true,
    }
  );

  const isIpad = useMediaQuery(
    window.navigator.userAgent.includes("OneScreen_TL7")
      ? "(min-width:620px) and (max-width:960px) and (orientation: landscape) "
      : "(min-width:620px) and (max-width:840px) and (orientation: portrait) and  (hover: none) and (pointer: coarse)",
    {
      noSsr: true,
    }
  );
  const isIpadLandscape = useMediaQuery(
    "(min-width: 800px) and (max-width: 1400px) and (orientation: landscape) and  (hover: none) and (pointer: coarse)",
    {
      noSsr: true,
    }
  );

  const dispatch = useDispatch();
  const { authToken } = useSelector((state) => state.logIn);
  const [snackBarstate, setSnackBar] = useState({
    openSnackBar: false,
    vertical: "top",
    horizontal: "right",
    type: "warning",
    message: "",
    title: null,
    timeout: 3000,
  });
  const { vertical, horizontal, openSnackBar, title, type, message } =
    snackBarstate;
  const [isGoogleForm, setIsGoogleForm] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    setIsGoogleForm(checkUrlIsGoogleForm());
  }, []);

  useEffect(() => {
    if (getAuthToken() !== null) {
      const quizUrl = getNavigateURL("/");
      navigate(quizUrl);
    }
  }, [authToken]);
  const openSSOLogin = async (login_url, eventType) => {
    let resp = await CreateAndGetTokenminiWindow(login_url)
      .then(async (tokenData) => {
        logEvent(
          Categories.user_management,
          eventType,
          isGoogleForm ? "google-forms" : "local"
        );
        initAdds();
        localStorage.setItem("enableWalkthrough", "true");
        setUserDataState(tokenData.token);
        const decodedData = await decodeToken(tokenData.token);
        const data = {email:decodedData.username,rating:null}
        localStorage.setItem("userData",JSON.stringify(data))
        const url = getNavigateURL("/", tokenData.isFirstTimeUser);
        navigate(`${url}`);
      })
      .catch((error) => {
        if (error === "allowpopup") {
          showWarningMessage(
            intlMessages.popUpError,
            intlMessages.popUpErrorTitle
          );
          return;
        }
        if (error === "windowError") {
          showWarningMessage(
            intlMessages.googleFormsUnauthorizedError,
            intlMessages.googleFormsUnauthorizedTitie
          );
          return;
        }
        // // console.log(error);
      });
  };

  const setUserDataState = async (token) => {
    dispatch(setAuthToken(token));
    const decodedData = await decodeToken(token);
    dispatch(setUserData(decodedData));
    dispatch(setIsSignedIn(true));
    dispatch(setUserState(USER_STATE.SIGNED_IN_USER));
  };
  const navigateToLogin = () => {
    const loginUrl = getNavigateURL("/login");
    logEvent(
      Categories.user_management,
      Events.user_management_login_click_signup_page
    );
    // // console.log(loginUrl);
    navigate(loginUrl);
  };

  const navigateToSignupEmail = () => {
    const loginUrl = getNavigateURL("/signup-email");
    logEvent(
      Categories.user_management,
      Events.user_management_signup_click_local_signup_page
    );
    // // console.log(loginUrl);
    navigate(loginUrl);
  };

  const handleCloseSnackBar = () => {
    setSnackBar({ ...snackBarstate, openSnackBar: false });
  };

  const showWarningMessage = (msgText, title = null) => {
    return setSnackBar({
      ...snackBarstate,
      type: "warning",
      message: msgText,
      openSnackBar: true,
      title: title,
      timeout: 7000,
    });
  };

  const renderSnackBar = () => {
    return (
      <Snackbar
        open={openSnackBar}
        anchorOrigin={{ vertical, horizontal }}
        autoHideDuration={snackBarstate.timeout}
        onClose={handleCloseSnackBar}
        key={vertical + horizontal}
      >
        <MuiAlert
          elevation={6}
          variant="filled"
          sx={{ color: "white" }}
          onClose={handleCloseSnackBar}
          severity={snackBarstate.type}
        >
          {snackBarstate.message}
        </MuiAlert>
      </Snackbar>
    );
  };

  return (
    <>
      {renderSnackBar()}
      <Box style={{ minHeight: "82vh" }}>
        <Box
          sx={{
            background: "linear-gradient(90deg, #4E0F89 0%, #7D00BA 100%)",
            minHeight: "80vh",
          }}
        >
          <Styled.MainContainer>
            <Styled.WelcomeTextBox
              isMobile={isMobile ? true : false}
              isMobileLandscape={isMobileLandscape ? true : false}
              isIpad={isIpad}
              isIpadLandscape={isIpadLandscape}
            >
              <Styled.WelcomeText
                sx={{
                  fontSize:
                    isMobile || isMobileLandscape || isIpad || isIpadLandscape
                      ? "24px"
                      : "32px",
                  fontWeight: "700",
                  lineHeight: "32px",
                  mb: "15px",
                }}
              >
                {intlMessages.signUpWelcomeText1}
              </Styled.WelcomeText>
              <Styled.WelcomeText
                isMobile={isMobile ? true : false}
                isMobileLandscape={isMobileLandscape ? true : false}
                isIpad={isIpad}
                isIpadLandscape={isIpadLandscape}
                sx={{
                  pl:
                    isMobile || isMobileLandscape || isIpad || isIpadLandscape
                      ? "5%"
                      : "unset",
                  pr:
                    isMobile || isMobileLandscape || isIpad || isIpadLandscape
                      ? "5%"
                      : "unset",
                }}
              >
                {intlMessages.signUpWelcomeText2}
              </Styled.WelcomeText>
            </Styled.WelcomeTextBox>
            <Styled.imageAndFormContainer
              isMobile={isMobile ? true : false}
              isMobileLandscape={isMobileLandscape ? true : false}
              isIpad={isIpad}
              isIpadLandscape={isIpadLandscape}
            >
              <Styled.imageContainer
                isMobile={isMobile ? true : false}
                isMobileLandscape={isMobileLandscape ? true : false}
                isIpad={isIpad}
                isIpadLandscape={isIpadLandscape}
              >
                <Styled.image
                  isMobile={isMobile ? true : false}
                  isMobileLandscape={isMobileLandscape ? true : false}
                  isIpad={isIpad}
                  isIpadLandscape={isIpadLandscape}
                  src={aboutImages["teacherImg"]}
                ></Styled.image>
                <Styled.imageText
                  isMobile={isMobile ? true : false}
                  isMobileLandscape={isMobileLandscape ? true : false}
                  isIpad={isIpad}
                  isIpadLandscape={isIpadLandscape}
                >
                  {intlMessages.signUpImageText}
                </Styled.imageText>
              </Styled.imageContainer>
              <Styled.formContainer>
                <Styled.trialText>
                  {isMobile || isMobileLandscape
                    ? intlMessages.signUpMobileTrialText
                    : intlMessages.signUpTrialText}
                </Styled.trialText>
                <Styled.iconAndTextBox
                  isMobile={isMobile ? true : false}
                  isMobileLandscape={isMobileLandscape ? true : false}
                  isIpad={isIpad}
                  isIpadLandscape={isIpadLandscape}
                  onClick={() =>
                    openSSOLogin(
                      GOOGLE_LOGIN_URL,
                      Events.user_management_login_google
                    )
                  }
                  sx={{ display: googleEnabled ? "" : "none" }}
                >
                  <Styled.icon
                    isMobile={isMobile ? true : false}
                    isMobileLandscape={isMobileLandscape ? true : false}
                    isIpad={isIpad}
                    isIpadLandscape={isIpadLandscape}
                    src={"/svgs/google-icon.svg"}
                  ></Styled.icon>
                  {intlMessages.signUpGoogleText}
                </Styled.iconAndTextBox>
                <Styled.iconAndTextBox
                  isMobile={isMobile ? true : false}
                  isMobileLandscape={isMobileLandscape ? true : false}
                  isIpad={isIpad}
                  isIpadLandscape={isIpadLandscape}
                  onClick={() =>
                    openSSOLogin(
                      MICROSOFT_LOGIN_URL,
                      Events.user_management_login_microsoft
                    )
                  }
                  sx={{ display: microsoftEnabled ? "" : "none" }}
                >
                  <Styled.icon
                    isMobile={isMobile ? true : false}
                    isMobileLandscape={isMobileLandscape ? true : false}
                    isIpad={isIpad}
                    isIpadLandscape={isIpadLandscape}
                    src={"/svgs/microsoft-icon.svg"}
                  ></Styled.icon>
                  {intlMessages.signUpMicrosoftText}
                </Styled.iconAndTextBox>
                <Box>
                  <Styled.divider
                    isMobile={isMobile ? true : false}
                    isMobileLandscape={isMobileLandscape ? true : false}
                    isIpad={isIpad}
                    isIpadLandscape={isIpadLandscape}
                    orientation="horizontal"
                    sx={{
                      display: googleEnabled && microsoftEnabled ? "" : "none",
                    }}
                  >
                    {intlMessages.signUpOrText}
                  </Styled.divider>
                  <Styled.iconAndTextBox
                    isMobile={isMobile ? true : false}
                    isMobileLandscape={isMobileLandscape ? true : false}
                    isIpad={isIpad}
                    isIpadLandscape={isIpadLandscape}
                    onClick={navigateToSignupEmail}
                    sx={{ mb: "22px" }}
                  >
                    <Styled.icon
                      isMobile={isMobile ? true : false}
                      isMobileLandscape={isMobileLandscape ? true : false}
                      isIpad={isIpad}
                      isIpadLandscape={isIpadLandscape}
                      src={"/svgs/email-icon.svg"}
                    ></Styled.icon>
                    {intlMessages.signUpEmailText}
                  </Styled.iconAndTextBox>
                  <Styled.loginText>
                    {intlMessages.signUpAlreadyText}
                    <span
                      onClick={navigateToLogin}
                      style={{
                        color: "#1A73E8",
                        marginLeft: "5px",
                        cursor: "pointer",
                      }}
                    >
                      {intlMessages.signUpLoginText}
                    </span>
                  </Styled.loginText>
                </Box>
              </Styled.formContainer>
            </Styled.imageAndFormContainer>
          </Styled.MainContainer>
        </Box>
        <Footer></Footer>
      </Box>
    </>
  );
}

export default TrialPage;
