import styled from "@mui/system/styled";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import LoadingButton from "@mui/lab/LoadingButton";

const ModalHeader = styled(Box)(({ theme, isSignedIn }) => ({
  padding: 8,
  paddingRight: 12,
  boxShadow: "rgba(100, 100, 111, 0.2) 0px 3px 29px 0px",
  paddingLeft: 25,
  alignItems: "center",
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
}));

const ModalActions = styled(Box)({
  padding: 5,
  paddingTop: 25,
  paddingBottom: 15,
  borderTop: "1px solid silver",
  display: "flex",
  justifyContent: "center",
});

const ModalBox = styled(Box)(({ theme }) => ({
  width: "25%",
  [theme.breakpoints.down("lg")]: {
    width: "60%",
  },
  [theme.breakpoints.down("sm")]: {
    width: "95%",
  },
}));

const ModalContentBox = styled(Box)({
  marginTop: 30,
  marginLeft: 25,
  marginRight: 25,
  marginBottom: 30,
});

const largeTextField = styled(TextField)(({theme})=>({
    background: '#F6F6F6',
    '& .MuiInputBase-root': {
        background: '#F6F6F6',
    },  
    '& input::placeholder': {
      color: '#000000',
      opacity: 0.75,
    },
    '& .MuiFormHelperText-root.Mui-error': {
        position: 'absolute',
        top: '100%'
      },
    // width:'410px',
    height: '39px',
    borderRadius:'1px',
    marginBottom:'20px'
}))

const CreateQuizButton = styled(LoadingButton)(({ theme, isSaving }) => ({
  color: theme.palette.primary.contrastText,
  textTransform: "none",
  border: "none",
  "&:hover": {
    border: "none",
  },
}));

const customStyling = {
  exportModal: {
    position: "absolute",
    top: { xs: "45%", sm: "50%" },
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    borderRadius: 2,
    boxShadow: 24,
    overflowY: "auto",
    maxHeight: "90%",
    border: "none",
  },
  button: {
    width: "392px",
    height: "54px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    background: "#EBF5FA",
    border: "1px solid #A7D5EC",
    borderRadius: "4px",
    textTransform: "none",
  },
};

export default {
  CreateQuizButton,
  ModalHeader,
  ModalActions,
  largeTextField,
  ModalBox,
  ModalContentBox,
  customStyling,
};
