import { useState, useEffect, useRef } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { Divider, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Link from '@mui/material/Link';
import Styled from "./styles";
import intlMessages from "../../locales/en";
import { Categories, Events, logEvent } from "../../analytics";

const GoogleSignSignupForm = (props) => {
    const navigate = useNavigate();

    const {
        openGoogleSignSignupForm,
        signInparams,
        closeGoogleFormModal
    } = props;

    const [isSaving, setIsSaving] = useState(false);

    const navigateToUrl = (url) => {
        logEvent(Categories.user_management, Events.google_forms_signin_signup_modal_signup_click);
        navigate(`${url}${signInparams ? '?'+signInparams : ''}`);
      }
    return (
    <Modal
            open={openGoogleSignSignupForm }
            aria-labelledby="review-modal"
            aria-describedby="review-description"
            >
            <Styled.ModalBox sx={Styled.customStyling.exportModal}>
            <Styled.ModalHeader>
                <Typography
                id="review-modal-title"
                variant="h5"
                component="h5"
                style={{ fontWeight: "bold" }}
                >
                {intlMessages.googleSignInModalHeader}
                </Typography>
                <IconButton
                aria-label="closeReviewModal"
                onClick={() => {
                    closeGoogleFormModal();
                }}
                >
                <CloseIcon color="seondary.light" />
                </IconButton>
            </Styled.ModalHeader>
            <Styled.ModalContentBox>
                <Box mt={'30px'} mb={'30px'} sx={{ display: "flex", flexDirection: "column", alignItems: 'center', justifyContent: 'center', opacity: isSaving ? 0.5 : 1, pointerEvents: isSaving ? 'none' : 'auto',  }} >
                <Typography variant="body1" style={{ fontSize: '14px', color: '#000000', textAlign: 'center' }}>
                        {`${intlMessages.googleSignUpModalText} `}
                        <Link
                        component="button"
                        variant="body2"
                        underline="none"
                        sx={{ color: "#1A73E8", opacity: isSaving ? 0.5 : 1, pointerEvents: isSaving ? 'none' : 'auto',}}
                        onClick={()=> {
                            navigateToUrl('/signup')
                        } }
                        >
                        {`${intlMessages.googleLinkForSignUp} `}
                        </Link>
                </Typography>
                <Styled.CustomDivider orientation="horizontal" >{intlMessages.signUpOrText}</Styled.CustomDivider>
                <Typography variant="body1" style={{ fontSize: '14px', color: '#000000' }}>
                        {`${intlMessages.googlesignupalreadytext} `}
                        <Link
                        component="button"
                        variant="body2"
                        underline="none"
                        sx={{ color: "#1A73E8", opacity: isSaving ? 0.5 : 1, pointerEvents: isSaving ? 'none' : 'auto',}}
                        onClick={ ()=>{
                            navigateToUrl('/login') 
                        }}
                        >
                        {`${intlMessages.signUpLoginText} `}
                        </Link>
                </Typography>
                </Box>
            </Styled.ModalContentBox>
            </Styled.ModalBox>
            </Modal>

    );
}

export default GoogleSignSignupForm;
