import { cancelDeleteAccount, deleteAccount, initiateDeleteAccount, updateAccountDetails, updatePassword } from "../../api/user-profile/methods"

const updateUserAccountDetails = async (user) => {
    const usr = {
      firstName: user.firstName?.trimEnd(),
      lastName: user.lastName?.trimEnd(),
      userName: user.userName?.trimEnd()
    }
    const response = await updateAccountDetails(usr)
    return response
  }

const updateUserPassword =  async(password)=>{
  const response = await updatePassword(password)
  return response
}

const deleteUserAccount =  async()=>{
  const response = await deleteAccount()
  return response
}

const initiateDelete = async(email,remarks,type)=>{
  const response = await initiateDeleteAccount(email,remarks,type)
  return response
}

const cancelDelete = async(email)=>{
  const response = await cancelDeleteAccount(email)
  return response
}

export {
    updateUserAccountDetails,
    updateUserPassword,
    deleteUserAccount,
    initiateDelete,
    cancelDelete
}