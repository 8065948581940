import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Styled from "./styles";
import intlMessages from "../../../locales/en";
import { Events, logEvent, Categories } from "../../../analytics";
import { useLocation } from "react-router-dom";
import Footer from "../../footer/component";
import AboutMobileLandscapeHeader from "../../about-mobile-landscape-header/component";
import aboutImages from "../about-page-images";
import { Avatar } from "@mui/material";
import { getFirstCharacters } from "../../../util/signin-user-data";

function MobileAboutPage(props) {
  const {
    sliderImage,
    list,
    reviews,
    navigateToMainPage,
    isMobileLandscape,
    isIpad,
    navigateToSignUp,
    userState,
    userReviews,
    userReviewCurrentIndex,
    setReviewCurrentIndex,
  } = props;
  const location = useLocation();
  const isAboutPage = location.pathname === "/about";
  return (
    <Box>
      <Box sx={{ display: "flex", flexDirection: "row" }}>
        <Styled.sliderBox
          isIpad={isIpad ? true : false}
          isMobileLandscape={isMobileLandscape ? true : false}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: isMobileLandscape ? "unset" : "center",
            }}
          >
            <Typography
              sx={{
                fontFamily: "Open Sans",
                fontSize: isIpad ? "48px" : isMobileLandscape ? "36px" : "32px",
                fontWeight: 300,
                color: "white",
                lineHeight: isIpad
                  ? "33.64px"
                  : isMobileLandscape
                  ? "33.64px"
                  : "32px",
              }}
            >
              {intlMessages.aboutSliderText1}
            </Typography>
            <Styled.SliderText
              isMobileLandscape={isMobileLandscape ? true : false}
              isIpad={isIpad ? true : false}
            >
              {intlMessages.aboutSliderText2}
            </Styled.SliderText>
            <Styled.sliderText2
              isMobileLandscape={isMobileLandscape ? true : false}
            >
              {intlMessages.aboutPageSliderText2}
              <Styled.sliderText3
                isMobileLandscape={isMobileLandscape ? true : false}
              >
                {intlMessages.aboutPageSliderText3}
              </Styled.sliderText3>
            </Styled.sliderText2>
            <Box
              sx={{
                display: "flex",
                flexDirection: isMobileLandscape || isIpad ? "row" : "column",
              }}
            >
              {userState !== true ? (
                <Styled.SliderBtn
                  isMobileLandscape={isMobileLandscape ? true : false}
                  isIpad={isIpad ? true : false}
                  onClick={() => {
                    navigateToSignUp();
                  }}
                  sx={{ ":hover": { bgcolor: "#d6511d" } }}
                >
                  {intlMessages.aboutPageCreateBtnText}
                </Styled.SliderBtn>
              ) : null}
            </Box>
          </Box>
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Box>
              <Styled.SliderImage
                isIpad={isIpad ? true : false}
                isMobileLandscape={isMobileLandscape ? true : false}
                src={sliderImage}
              ></Styled.SliderImage>
            </Box>
            <Styled.SliderDotBox
              isMobileLandscape={isMobileLandscape ? true : false}
            >
              {list}
            </Styled.SliderDotBox>
          </Box>
        </Styled.sliderBox>
        {/* {isAboutPage && isMobileLandscape ? <AboutMobileLandscapeHeader isAboutPage={isAboutPage ? true : false} isMobileLandscape={isMobileLandscape ? true : false} /> : null} */}
      </Box>
      <Styled.FeaturesBox
        isMobileLandscape={isMobileLandscape ? true : false}
        isIpad={isIpad ? true : false}
      >
        <Styled.featureBoxHeading>
          {intlMessages.aboutPageWhyText}
        </Styled.featureBoxHeading>
        <Box sx={{ mt: "10px" }}>
          <Styled.FeatureIcon
            src={aboutImages["feature1Img"]}
          ></Styled.FeatureIcon>
          <Styled.FeatureText
            isIpad={isIpad ? true : false}
            isMobileLandscape={isMobileLandscape ? true : false}
          >
            {intlMessages.aboutMobileFeatureText1}
          </Styled.FeatureText>
        </Box>
        <Box>
          <Styled.FeatureIcon
            src={aboutImages["feature2Img"]}
          ></Styled.FeatureIcon>
          <Styled.FeatureText
            isIpad={isIpad ? true : false}
            isMobileLandscape={isMobileLandscape ? true : false}
          >
            {intlMessages.aboutMobileFeatureText2}
          </Styled.FeatureText>
        </Box>
        <Box>
          <Styled.FeatureIcon
            src={aboutImages["feature3Img"]}
          ></Styled.FeatureIcon>
          <Styled.FeatureText
            isIpad={isIpad ? true : false}
            isMobileLandscape={isMobileLandscape ? true : false}
          >
            {intlMessages.aboutMobileFeatureText3}
          </Styled.FeatureText>
        </Box>
      </Styled.FeaturesBox>
      <Styled.ForTeachersBox
        isIpad={isIpad ? true : false}
        isMobileLandscape={isMobileLandscape ? true : false}
      >
        <Box sx={{ textAlign: "center" }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              width: isMobileLandscape ? "420px" : isIpad ? "726px" : "280px",
            }}
          >
            <Styled.DoubleQuotesBlack
              src={aboutImages["blackQuotes"]}
            ></Styled.DoubleQuotesBlack>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                ml: "10px",
              }}
            >
              <Typography
                sx={{
                  fontFamily: "Open Sans",
                  fontSize: "16px",
                  fontWeight: 400,
                  lineHeight: "21.79px",
                  textAlign: "left",
                }}
              >
                {reviews[0].comment}
              </Typography>
              <Typography
                sx={{
                  fontFamily: "Open Sans",
                  fontSize: "16px",
                  fontWeight: 700,
                  lineHeight: "21.79px",
                  mt: "10px",
                  textAlign: "left",
                }}
              >
                {reviews[0].name} <span style={{ color: "#3333334D" }}>|</span>{" "}
                <span
                  style={{
                    fontSize: "16px",
                    fontWeight: 700,
                    lineHeight: "24.51px",
                    color: "#7DC242",
                  }}
                >
                  {reviews[0].role}
                </span>
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            border: "1px #00000033 solid",
            width: isMobileLandscape ? "420px" : isIpad ? "726px" : "280px",
            mt: "20px",
          }}
        ></Box>
        <Styled.TeacherImg
          isIpad={isIpad ? true : false}
          isMobileLandscape={isMobileLandscape ? true : false}
          src={aboutImages["teacherImg"]}
        ></Styled.TeacherImg>
        <Styled.TeacherLongDesc
          isMobileLandscape={isMobileLandscape ? true : false}
          isIpad={isIpad ? true : false}
        >
          {intlMessages.aboutPageTeacherBoxText1}
        </Styled.TeacherLongDesc>
        <Styled.TeacherLongDesc
          isIpad={isIpad ? true : false}
          isMobileLandscape={isMobileLandscape ? true : false}
          sx={{ mt: "20px" }}
        >
          {intlMessages.aboutPageTeacherBoxText2}
        </Styled.TeacherLongDesc>
      </Styled.ForTeachersBox>
      <Styled.ForStudentsBox
        isIpad={isIpad ? true : false}
        isMobileLandscape={isMobileLandscape ? true : false}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            width: isMobileLandscape ? "420px" : isIpad ? "726px" : "280px",
          }}
        >
          <Styled.DoubleQuotesBlack
            src={aboutImages["blackQuotes"]}
          ></Styled.DoubleQuotesBlack>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              ml: "10px",
            }}
          >
            <Typography
              sx={{
                fontFamily: "Open Sans",
                fontSize: "16px",
                fontWeight: 400,
                lineHeight: "21.79px",
                textAlign: "left",
              }}
            >
              {reviews[1].comment}
            </Typography>
            <Typography
              sx={{
                fontFamily: "Open Sans",
                fontSize: "18px",
                fontWeight: 700,
                lineHeight: "21.79px",
                mt: "10px",
                textAlign: "left",
              }}
            >
              {reviews[1].name} <span style={{ color: "#3333334D" }}>|</span>{" "}
              <span
                style={{
                  fontSize: "18px",
                  fontWeight: 700,
                  lineHeight: "24.51px",
                  color: "#7DC242",
                }}
              >
                {reviews[1].role}
              </span>
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            border: "1px #00000033 solid",
            width: isMobileLandscape ? "420px" : isIpad ? "726px" : "280px",
            mt: "20px",
          }}
        ></Box>
        <Styled.StudentImg
          isIpad={isIpad ? true : false}
          isMobileLandscape={isMobileLandscape ? true : false}
          src={aboutImages["studentImg"]}
        ></Styled.StudentImg>
        <Box>
          <Styled.StudentLongDesc
            isIpad={isIpad ? true : false}
            isMobileLandscape={isMobileLandscape ? true : false}
            sx={{ mb: "20px" }}
          >
            {intlMessages.aboutPageStudentBoxText1}
          </Styled.StudentLongDesc>
          <Styled.StudentLongDesc
            isIpad={isIpad ? true : false}
            isMobileLandscape={isMobileLandscape ? true : false}
          >
            {intlMessages.aboutPageStudentBoxText2}
          </Styled.StudentLongDesc>
        </Box>
      </Styled.ForStudentsBox>

      <Styled.SchoolBox
        isIpad={isIpad ? true : false}
        isMobileLandscape={isMobileLandscape ? true : false}
      >
        <Styled.SchoolImg
          isIpad={isIpad ? true : false}
          isMobileLandscape={isMobileLandscape ? true : false}
          src={aboutImages["schoolImg"]}
        ></Styled.SchoolImg>
        <Box>
          <Styled.SchoolDesc
            isIpad={isIpad ? true : false}
            isMobileLandscape={isMobileLandscape ? true : false}
            sx={{ mb: "20px" }}
          >
            {intlMessages.aboutPageSchoolBoxText1}
          </Styled.SchoolDesc>
          <Styled.SchoolDesc
            isIpad={isIpad ? true : false}
            isMobileLandscape={isMobileLandscape ? true : false}
          >
            {intlMessages.aboutPageSchoolBoxText2}
          </Styled.SchoolDesc>
        </Box>
      </Styled.SchoolBox>

      <Styled.CreateQuizBox
        isIpad={isIpad ? true : false}
        isMobileLandscape={isMobileLandscape ? true : false}
      >
        <Styled.GenerateQuizBtn
          isMobileLandscape={isMobileLandscape ? true : false}
          onClick={() => {
            logEvent(
              Categories.about_page,
              Events.about_screen_tagline_generate_quiz_button_clicked
            );
            navigateToMainPage();
          }}
          sx={{ ":hover": { bgcolor: "#d6511d" } }}
        >
          {intlMessages.aboutGenerateQuizBtnText}
        </Styled.GenerateQuizBtn>
        <Styled.QuizContentBox
          isIpad={isIpad ? true : false}
          isMobileLandscape={isMobileLandscape ? true : false}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Styled.QuizContentBoxImage
              src={aboutImages["pasteIcon"]}
            ></Styled.QuizContentBoxImage>
            <Styled.QuizContentBoxText>
              {intlMessages.aboutPageCreateQuizBoxText1}
            </Styled.QuizContentBoxText>
          </Box>
          <Styled.ArrowIcon
            isIpad={isIpad ? true : false}
            src={
              isIpad ? aboutImages["arrowIcon"] : aboutImages["downwardArrow"]
            }
          ></Styled.ArrowIcon>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Styled.QuizContentBoxImage
              src={aboutImages["selectIcon"]}
            ></Styled.QuizContentBoxImage>
            <Styled.QuizContentBoxText>
              {intlMessages.aboutPageCreateQuizBoxText2}
            </Styled.QuizContentBoxText>
          </Box>
          <Styled.ArrowIcon
            isIpad={isIpad ? true : false}
            src={
              isIpad ? aboutImages["arrowIcon"] : aboutImages["downwardArrow"]
            }
          ></Styled.ArrowIcon>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Styled.QuizContentBoxImage
              src={aboutImages["exportIcon"]}
            ></Styled.QuizContentBoxImage>
            <Styled.QuizContentBoxText>
              {intlMessages.aboutPageCreateQuizBoxText3}
            </Styled.QuizContentBoxText>
          </Box>
        </Styled.QuizContentBox>
      </Styled.CreateQuizBox>

      <Styled.ReviewBox
        isMobileLandscape={isMobileLandscape ? true : false}
        isIpad={isIpad ? true : false}
      >
        <Box
          sx={{
            width: isMobileLandscape ? "440px" : isIpad ? "646px" : "280px",
          }}
        >
          <Typography
            sx={{
              fontFamily: "Open Sans",
              fontSize: "24px",
              fontWeight: 400,
              lineHeight: "30px",
              width: "100%",
              textAlign: "center",
            }}
          >
            {intlMessages.aboutReviewLabel1}{" "}
            <span
              style={{
                fontFamily: "Open Sans",
                fontSize: "24px",
                fontWeight: 700,
                lineHeight: "30px",
                marginRight: "10px",
              }}
            >
              {intlMessages.aboutReviewLabel2}
            </span>
            {intlMessages.aboutReviewLabel3}{" "}
          </Typography>
        </Box>
        <Box
          sx={{
            mt: "20px",
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Styled.ArrowBtn
            isIpad={isIpad ? true : false}
            sx={{
              mr: "30px",
              cursor: userReviewCurrentIndex === 0 ? "not-allowed" : "pointer",
            }}
            src={
              userReviewCurrentIndex === 0
                ? aboutImages["greyBackArrow"]
                : aboutImages["blackBackArrow"]
            }
            onClick={() => {
              if (userReviewCurrentIndex > 0)
                setReviewCurrentIndex(userReviewCurrentIndex - 1);
            }}
          ></Styled.ArrowBtn>
          <Styled.Review
            isIpad={isIpad ? true : false}
            isMobileLandscape={isMobileLandscape ? true : false}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: isMobileLandscape || isIpad ? "row" : "column",
                alignItems: "center",
                justifyContent: "center",
                paddingLeft: isMobileLandscape || isIpad ? "unset" : "10%",
              }}
            >
              <Avatar
                sx={{
                  bgcolor: "#7D00BA",
                  fontSize: "50px",
                  height: isMobileLandscape || isIpad ? "100px" : "90px",
                  width: isMobileLandscape || isIpad ? "100px" : "90px",
                  ml: isMobileLandscape || isIpad ? "15px" : "-5px",
                }}
              >
                {
                  getFirstCharacters(
                    userReviews[userReviewCurrentIndex]?.name,
                    null
                  ).avatar
                }
              </Avatar>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  width: isMobileLandscape || isIpad ? "unset" : "300px",
                  height: isMobileLandscape ? "unset" : "200px",
                  marginTop: "25px",
                  marginLeft: isMobileLandscape || isIpad ? "25px" : "unset",
                }}
              >
                <Typography
                  sx={{
                    fontSize: "16px",
                    fontFamily: "Open Sans",
                    lineHeight: "21px",
                    fontWeight: "700",
                    mb: "10px",
                  }}
                >
                  {userReviews[userReviewCurrentIndex]?.name}
                </Typography>
                <Box sx={{ display: "flex", flexDirection: "row", mb: "10px" }}>
                  {Array.from(
                    {
                      length: Math.round(
                        userReviews[userReviewCurrentIndex]?.rating
                      ),
                    },
                    (_, index) => (
                      <Styled.RatingStar
                        key={index}
                        src={
                          userReviews[userReviewCurrentIndex]?.rating === 4.5 &&
                          index === 4
                            ? aboutImages["halfFilledStar"]
                            : aboutImages["star"]
                        }
                      ></Styled.RatingStar>
                    )
                  )}
                </Box>
                <Typography
                  sx={{
                    fontSize: "16px",
                    fontFamily: "Open Sans",
                    lineHeight: "30px",
                    fontWeight: "400",
                    width: "280px",
                  }}
                >
                  {userReviews[userReviewCurrentIndex]?.comment}
                </Typography>
              </Box>
            </Box>
          </Styled.Review>
          <Styled.ArrowBtn
            isIpad={isIpad ? true : false}
            sx={{
              ml: "30px",
              cursor: userReviewCurrentIndex === 4 ? "not-allowed" : "pointer",
            }}
            src={
              userReviewCurrentIndex === 4
                ? aboutImages["greyForwardArrow"]
                : aboutImages["blackForwardArrow"]
            }
            onClick={() => {
              if (userReviewCurrentIndex < 4)
                setReviewCurrentIndex(userReviewCurrentIndex + 1);
            }}
          ></Styled.ArrowBtn>
        </Box>
      </Styled.ReviewBox>
      <Footer></Footer>
    </Box>
  );
}

export default MobileAboutPage;
