import { header_images_base64 } from "../../components/header/header-images-base64";

const GuruIcon = (props) => {
  const {
    clickedGuruIcon,
    isMobile,
    isIpadLandscape,
    isIpad,
    isMobileLandscape,
  } = props;

  const renderIcon = () => {
    return (
      <div className={"iconWrapper"}>
        <img src={`${header_images_base64[3]}`}></img>
      </div>
    );
  };
  return (
    <>
      {isMobile || isMobileLandscape || isIpad ? (
        <div></div>
      ) : (
        <div class="dropdown" onClick={clickedGuruIcon}>
          {renderIcon()}
        </div>
      )}
    </>
  );
};

export default GuruIcon;
