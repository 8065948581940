 const sampleTextList = [
    {
        id:0,
        title:"Solar System",
        summary:"The Solar System is the gravitationally bound system of the Sun and the objects that orbit it.",
        description:`The Solar System is the gravitationally bound system of the Sun and the objects that orbit it. It formed 4.6 billion years ago from the gravitational collapse of a giant interstellar molecular cloud. The vast majority (99.86%) of the system's mass is in the Sun, with most of the remaining mass contained in the planet Jupiter. The four inner system planets—Mercury, Venus, Earth, and Mars—are terrestrial planets, being composed primarily of rock and metal. The four giant planets of the outer system are substantially larger and more massive than the terrestrials. The two largest, Jupiter and Saturn, are gas giants, being composed mainly of hydrogen and helium; the next two, Uranus and Neptune, are ice giants, being composed mostly of volatile substances with relatively high melting points compared with hydrogen and helium, such as water, ammonia, and methane. All eight planets have nearly circular orbits that lie near the plane of Earth's orbit, called the ecliptic.\n\nThere are an unknown number of smaller dwarf planets and innumerable small Solar System bodies orbiting the Sun. Six of the major planets, the six largest possible dwarf planets, and many of the smaller bodies are orbited by natural satellites, commonly called "moons" after Earth's Moon. Two natural satellites, Jupiter's moon Ganymede and Saturn's moon Titan, are larger but not more massive than Mercury, the smallest terrestrial planet, and Jupiter's moon Callisto is nearly as large. Each of the giant planets and some smaller bodies are encircled by planetary rings of ice, dust, and moonlets. The asteroid belt, which lies between the orbits of Mars and Jupiter, contains objects composed of rock, metal, and ice. Beyond Neptune's orbit lie the Kuiper belt and scattered disc, which are populations of objects composed mostly of ice and rock.`
        },
        {
          id:1,
          title:"Photosynthesis",
          summary:"Most life on Earth depends on photosynthesis. The process is carried out by plants, algae, and some types of bacteria.",
          description:`Most life on Earth depends on photosynthesis. The process is carried out by plants, algae, and some types of bacteria, which capture energy from sunlight to produce oxygen (O2) and chemical energy stored in glucose (a sugar). Herbivores then obtain this energy by eating plants, and carnivores obtain it by eating herbivores.\n\nDuring photosynthesis, plants take in carbon dioxide (CO2) and water (H2O) from the air and soil. Within the plant cell, the water is oxidized, meaning it loses electrons, while the carbon dioxide is reduced, meaning it gains electrons. This transforms the water into oxygen and the carbon dioxide into glucose. The plant then releases the oxygen back into the air, and stores energy within the glucose molecules.\n\nInside the plant cell are small organelles called chloroplasts, which store the energy of sunlight. Within the thylakoid membranes of the chloroplast is a light-absorbing pigment called chlorophyll, which is responsible for giving the plant its green color. During photosynthesis, chlorophyll absorbs energy from blue- and red-light waves, and reflects green-light waves, making the plant appear green.`
        },
        {
          id:2,
          title:"Northeast United States",
          summary:"The United States is a big country with many different regions. One of the regions is the Northeast.",
          description:`The United States is a big country with many different regions. One of the regions is the Northeast. The Northeast is located in the northeastern part of the United States and it is made up of states such as Maine, New Hampshire, Vermont, Massachusetts, Rhode Island, Connecticut, New York, Pennsylvania, New Jersey, and Delaware.\n\nThe Northeast is known for its cold winters and colorful fall foliage. The region is also known for its historic cities, such as Boston and Philadelphia. Boston is the capital of Massachusetts, and it is famous for its history and landmarks, such as the Freedom Trail, a 2.5-mile long path that takes visitors through 16 historical sites. Philadelphia is the capital of Pennsylvania, and it is famous for its Liberty Bell, which is an important symbol of American independence.\r\nThe Northeast is also known for its natural beauty. The Adirondack Mountains are located in New York, and they are a popular spot for hiking and camping. The Catskill Mountains are also located in New York, and they are a popular spot for skiing and snowboarding. The White Mountains are located in New Hampshire. and they are a popular spot for hiking and climbing.\r\n\r\nThe Northeast is also known for its coastal areas. The Maine coast is famous for its rocky cliffs and lobster. The Cape Cod coast is famous for its sandy beaches and lighthouses. The Jersey Shore is famous for its boardwalks and amusement parks. The Northeast is an interesting region with a rich history, natural beauty, and cultural diversity. It offers a great opportunity for students to learn about geography, history, and natural resources.`
        }
]

export default sampleTextList;