import styled from "@mui/system/styled";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { Button,Typography } from "@mui/material";

const ModalHeader = styled(Box)({
  padding: 8,
  paddingRight: 12,
  boxShadow: "rgba(100, 100, 111, 0.2) 0px 3px 29px 0px",
  paddingLeft: 25,
  alignItems: "center",
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
});

const ModalActions = styled(Box)({
  padding: 5,
  paddingTop: '20px',
  paddingBottom: '15px',
  borderTop: "1px solid silver",
  display: "flex",
  justifyContent: "center",
});

const ModalTextField = styled(TextField)(({ theme }) => ({
  borderRadius: 3,
  background: '#F6F6F6',
  '& .MuiInputBase-root': {
      background: '#F6F6F6',
      paddingLeft:'10px'
  },  
  '& input::placeholder': {
    color: '#000000',
    opacity: 1,
  },
  '& .MuiFormHelperText-root.Mui-error': {
    position: 'absolute',
    top: '100%'
  },
  // padding:5,

  ".MuiOutlinedInput-input": {
    padding: 7,
  },
  width: "100%",
  [theme.breakpoints.down("md")]: {},
}));

const ModalBox = styled(Box)(({ theme }) => ({
  width: "30%",
  [theme.breakpoints.down("lg")]: {
    width: "60%",
  },
  [theme.breakpoints.down("sm")]: {
    width: "95%",
  },
}));

const ModalContentBox = styled(Box)(({ theme,rating, isIpad,isIpadLandscape,isMobile,isMobileLandscape,isTL7})=>({
  marginTop: 30,
  marginLeft: 25,
  marginRight: 25,
  marginBottom: 30,
  overflow:'auto',
  height: navigator?.userAgent?.indexOf("OneScreen_TL7") >= 0 && rating?'300px':'unset',
  [`${theme.breakpoints.down("sm")} and (max-height: 680px) and (max-width: 680px)`]: {
    height: rating? '300px':'unset'
  },
}));

const CreateQuizButton = styled(Button)(({ theme, isquizloading }) => ({
    color: theme.palette.primary.contrastText,
    textTransform: "none",
    paddingLeft: isquizloading ? 35 : 15,
    [`${theme.breakpoints.down("md")}  and (orientation: landscape) and (hover: none) and (pointer: coarse)`]:{
      paddingRight: isquizloading && 0,
    },
}));

const ErrorText = styled(Typography)(({ theme }) => ({
    color: theme.palette.warning.main,
    fontSize: 12,
    padding: 2,
    display: "flex",
    textAlign: "flex-start",
  }));

const customStyling = {
  exportModal: {
    position: "absolute",
    top: {xs:'45%' ,sm:'50%'},
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    borderRadius: 2,
    boxShadow: 24,
    overflowY: "auto",
    maxHeight: "90%",
    border: "none",
  },
};

const  headerText = styled(Typography)(({theme})=>({
  fontWeight: "600",
  fontSize:'17px',
  fontFamily:'Open Sans',
  lineHeight:'23.15px'
}))

const  ratingText = styled(Typography)(({theme})=>({
  marginBottom:'10px',
  fontWeight:'400',
  fontSize:'16px',
  lineHeight:'21.79px',
  textAlign:'center',
  color:'#333333'
}))

const  questionText = styled(Typography)(({theme})=>({
  marginBottom:'15px',
  marginTop:'10px',
  fontWeight:'400',
  fontSize:'14px',
  lineHeight:'19.07px',
  textAlign:'center',
  color:'#333333'
}))

const optionBox = styled(Box)(({theme})=>({
  display:'flex',
  justifyContent:'center',
  flexWrap:'wrap',
}))

const optionBtn = styled(Button)(({theme})=>({
  borderRadius:'4px',
  border: '1px solid #B0B0B0',
  marginRight:'10px',
  marginBottom:'11px',
  color:'#333333',
  textTransform:'none',
  height:'29px'
}))

export default {
  CreateQuizButton,
  ModalHeader,
  ModalActions,
  ModalTextField,
  ModalBox,
  ModalContentBox,
  customStyling,
  ErrorText,
  headerText,
  ratingText,
  questionText,
  optionBox,
  optionBtn
};
