import { useEffect, useState } from "react";
import { useTheme } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import Footer from "../footer/component";
import intlMessages from "../../locales/en";
import Styled from "./styles";
import ConnectionModal from "../connection-modal/component";
import { Box, InputAdornment, useMediaQuery } from "@mui/material";
import { validateEmail, validateInput } from "../sign-up/service";
import { sendForgotPasswordLink } from "./service";
import CircularProgress from "@mui/material/CircularProgress";
import { Categories, Events, logEvent } from "../../analytics";
import { iOS, isMobileDevice } from "../quiz/service";
import { getNavigateURL } from "../../util/signin-user-data";

function ForgotPassword() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"), {
    noSsr: true,
  });
  const isMobileLandscape = useMediaQuery(
    `${theme.breakpoints.down(
      "md"
    )}  and (orientation: landscape) and (hover: none) and (pointer: coarse)`,
    {
      noSsr: true,
    }
  );

  const navigate = useNavigate();
  const [isSaving, setIsSaving] = useState(false);
  const [openConnectionModal, setIsOpenConnectionModal] = useState(false);
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [successMsg, setSuccessMsg] = useState(false);
  const [mainBoxHeight,setMainBoxHeight]= useState(0)
  const navigateToLogIn = () => {
    logEvent(Categories.user_management, Events.user_management_login_click_forgot_password_page);
    const loginURL = getNavigateURL("/login")
    navigate(loginURL);
  };

  const handleEmail = (e) => {
    const inp = validateInput(e.target.value);
    setSuccessMsg(false);
    if (inp.length > 0) {
      setError("");
    }
    if (error === intlMessages.signUpEmailEmptyMessage || validateEmail(inp)) {
      setError("");
    }
    setEmail(inp);
  };

  const emailValidation = () => {
    if (email.length === 0) {
      return setError(intlMessages.signUpEmailEmptyMessage);
    }
    if (!validateEmail(email)) {
      return setError(intlMessages.SignUpInvalidEmailError);
    }
    return true;
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      resendEmail();
    }
  };

  useEffect(() => {
    handleOrientationChange();
    window.addEventListener("resize", handleOrientationChange);
    return () => {
      window.removeEventListener("resize", handleOrientationChange);
    };
  }, []);

  useEffect(()=>{
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  },[])

  async function handleOrientationChange() {
    const screenStats = window.screen;
    let mainBoxHeight_;
    switch (true) {
      case (navigator.standalone === "boolean" ||
        navigator.userAgent.match(/iPhone/i) ||
        navigator.userAgent.match(/iPod/i)) &&
        iOS() &&
        window.matchMedia(
          "screen and (max-device-height: 900px) and (orientation: landscape)"
        ).matches:
        mainBoxHeight_ = 160;
        break;
      case window.matchMedia(
        "(min-width: 800px) and (max-width: 1400px) and (orientation: portrait) and (hover: none) and (pointer: coarse)"
      ).matches:
        mainBoxHeight_ = screenStats.availHeight * 0.74; // iPad Portrait
        break;
      case screenStats.availHeight > 600 && screenStats.availHeight <= 700 && window.matchMedia('(pointer: coarse)').matches:     
        mainBoxHeight_ = screenStats.availHeight; // Small mobiles
        break;
      case screenStats.availHeight > 650 && screenStats.availHeight <= 700:
        mainBoxHeight_ = screenStats.availHeight - 260;
        break;
      case screenStats.availHeight > 700 &&
        screenStats.availHeight < 750 &&
        window.matchMedia("(pointer: fine)").matches &&
        window.screen.width < 1100:
        mainBoxHeight_ = screenStats.availHeight - 275; // Mobile landscape
        break;
      case screenStats.availWidth > 750 && screenStats.availWidth <= 960 && screenStats.availHeight < 930 : //mobile Landscape
      if(window.innerHeight>350){
        mainBoxHeight_ = screenStats.availHeight-200 ;
      }
      else{
        mainBoxHeight_ = screenStats.availHeight-250
      }
        break;
      case screenStats.availHeight > 800 && window.matchMedia('(orientation: portrait)').matches && window.matchMedia('(pointer: coarse)').matches:
        mainBoxHeight_ = screenStats.availHeight - 450 // iPad
        break;
      case screenStats.availHeight === 720 && screenStats.availWidth === 1280:
        var headerHeight = document.getElementById('header').offsetHeight;
        var footerHeight = document.getElementById('footer2').offsetHeight;
        var headerFooterSum = headerHeight + footerHeight;
        var fullPageHeight = window.innerHeight;
        var contentHeight = fullPageHeight - headerFooterSum;
        mainBoxHeight_ = contentHeight // TL
        break;
      case screenStats.availWidth >= 1920 && screenStats.height > 1000:
        var headerHeight = document.getElementById('header').offsetHeight;
        var footerHeight = document.getElementById('footer2').offsetHeight;
        var headerFooterSum = headerHeight + footerHeight;
        var fullPageHeight = window.innerHeight;
        var contentHeight = fullPageHeight - headerFooterSum;
        mainBoxHeight_ = contentHeight // Desktop
        break;
     case window.matchMedia('(min-width: 800px) and (max-width: 1400px) and (orientation: landscape) and (hover: none) and (pointer: coarse)').matches:
      mainBoxHeight_ = screenStats.availHeight - 535; // iPad Landscape
        if(mainBoxHeight_ < 400) 
          mainBoxHeight_ = 430;
      break;
     case screenStats.height > 800:
      var headerHeight = document.getElementById('header').offsetHeight;
      var footerHeight = document.getElementById('footer2').offsetHeight;
      var headerFooterSum = headerHeight + footerHeight;
      var fullPageHeight = window.innerHeight;
      var contentHeight = fullPageHeight - headerFooterSum;
      mainBoxHeight_ = contentHeight // Desktop
        break;
     default:
        mainBoxHeight_ = screenStats.availHeight - 260;
        if(mainBoxHeight_ < 200) 
           mainBoxHeight_ = 173;
           break;   
    }
    const height = window.innerHeight-200
    var viewport = document.querySelector("meta[name=viewport]");
    isMobileDevice() && !iOS() && (window.screen.orientation.type==="landscape-primary" || window.screen.orientation.type==="landscape-secondary" )?viewport.setAttribute("content", viewport.content + ", height=" + mainBoxHeight_):
    viewport.setAttribute("content", viewport.content + ", height=" + height);
    setMainBoxHeight(mainBoxHeight_)
  }

  const resendEmail = () => {
    if (emailValidation()) {
      logEvent(Categories.user_management, Events.user_management_forgot_password_click);
      setIsSaving(true);
      sendForgotPasswordLink(email)
        .then((res) => {
          if (res?.data?.statusCode === 0) {
            setIsSaving(false);
            setIsOpenConnectionModal(true);
            return;
          }
          if (res?.status === 201 && res?.data?.error === "Email not found") {
            setIsSaving(false);
            setError(intlMessages.forgotPasswordAccountError);
          }
          if (
            res?.status === 201 &&
            res?.data?.message === "Reset password email sent"
          ) {
            setIsSaving(false);
            setSuccessMsg(true);
            setError(intlMessages.forgotPasswordSuccessMsg);
          }
        })
        .catch((error) => {
          setError('');
          setIsSaving(false);
        });
    }
  };

  return (
    <>
      <Box
        sx={{
          background: "linear-gradient(90deg, #4E0F89 0%, #7D00BA 100%)",
        }}
      >
        <Styled.MainContainer
          isMobile={isMobile ? true : false}
          isMobileLandscape={isMobileLandscape ? true : false}
          height={mainBoxHeight}
        >
          <Styled.verifyText
            isMobile={isMobile ? true : false}
            isMobileLandscape={isMobileLandscape ? true : false}
          >
            {intlMessages.forgotPasswordResetPasswordLabel}
          </Styled.verifyText>
          <Styled.verifyTextDesc
          isMobile={isMobile ? true : false}
          isMobileLandscape={isMobileLandscape ? true : false}
          >
            {intlMessages.forgotPasswordDescText}
          </Styled.verifyTextDesc>
          <Styled.largeTextField
            onFocus={()=>setError("")}
            isMobile={isMobile ? true : false}
            isMobileLandscape={isMobileLandscape ? true : false}
            successMsg={successMsg ? true : false}
            helperText={error}
            isError={error?true:false}
            error={!error || successMsg ? false : true}
            onChange={handleEmail}
            value={email}
            required
            type="email"
            variant="outlined"
            placeholder={intlMessages.signUpEmailPlaceholder}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <img src="/svgs/email-icon.svg" alt="Icon" />
                </InputAdornment>
              ),
            }}
            onKeyDown={handleKeyDown}
          ></Styled.largeTextField>
          <Styled.resendBtn
          isMobile={isMobile ? true : false}
          isMobileLandscape={isMobileLandscape ? true : false}
            onClick={resendEmail}
            variant="contained"
            loading={isSaving}
            loadingPosition="start"
            disabled={isSaving ? true : false}
            loadingIndicator={<CircularProgress color="inherit" size={16} />}
          >
            {intlMessages.forgotPasswordSubmitBtn}
          </Styled.resendBtn>
          <Styled.logOutBtn isMobile={isMobile ? true : false}
          isMobileLandscape={isMobileLandscape ? true : false} onClick={navigateToLogIn}>
            {intlMessages.forgotPasswordLoginBtn}
          </Styled.logOutBtn>
        </Styled.MainContainer>
        <Footer></Footer>
      </Box>
      {openConnectionModal && (
        <ConnectionModal
          isOpen={openConnectionModal}
          handleCloseConnectionModal={() => setIsOpenConnectionModal(false)}
        />
      )}
    </>
  );
}

export default ForgotPassword;
