import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Styled from "./styles";
import intlMessages from "../../locales/en";
import { Box, Snackbar, useMediaQuery, useTheme } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { CONTACT_EMAIL } from "../../settings";
import { useState } from "react";
import MuiAlert from "@mui/material/Alert";
import Feedback from "../feedback/component";
import ConnectionModal from "../connection-modal/component";
import { userLogOut } from "../../common/sign_in_service";
import { getNavigateURL } from "../../util/signin-user-data";

function LoginAlertBox(props) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"), {
    noSsr: true,
  });
  const isMobileLandscape = useMediaQuery(
    `${theme.breakpoints.down(
      "md"
    )}  and (orientation: landscape) and (hover: none) and (pointer: coarse)`,
    {
      noSsr: true,
    }
  );
  const { isOpen, title, desc, onClose,purpose } = props;
  const [isOpenFeedback, setIsOpenFeedback] = useState(false);
  const navigate = useNavigate();
  const [isOpenConnectionModal, setIsOpenConnectionModal] = useState(false);
  const [snackBarstate, setSnackBar] = useState({
    openSnackBar: false,
    vertical: "top",
    horizontal: "right",
    type: "success",
    message: "",
  });
  const { vertical, horizontal, openSnackBar } = snackBarstate;
  const handleOpenFeedback = () => {
    setIsOpenFeedback(true);
  };

  const handleLogOut = () => {
    onClose();
    userLogOut();
    const loginURL = getNavigateURL("/login")
    navigate(loginURL);
  };

  const handleCloseFeedback = () => {
    setIsOpenFeedback(false);
  };

  const viewFeedbackSubmissionMessage = () => {
    return setSnackBar({
      ...snackBarstate,
      type: "success",
      message: intlMessages.feedbackSubmissionMsg,
      openSnackBar: true,
    });
  };
  const showErrorMessage = (msgText) => {
    return setSnackBar({
      ...snackBarstate,
      type: "error",
      message: msgText,
      openSnackBar: true,
    });
  };
  const handleOpenConnectionModal = () => {
    setIsOpenConnectionModal(true);
  };
  const handleCloseConnectionModal = () => {
    setIsOpenConnectionModal(false);
  };
  const handleCloseSnackBar = () => {
    setSnackBar({ ...snackBarstate, openSnackBar: false });
  };

  const renderSnackBar = () => {
    return (
      <Snackbar
        open={openSnackBar}
        anchorOrigin={{ vertical, horizontal }}
        autoHideDuration={3000}
        onClose={handleCloseSnackBar}
        key={vertical + horizontal}
      >
        <MuiAlert
          elevation={6}
          variant="filled"
          sx={{ color: "white" }}
          onClose={handleCloseSnackBar}
          severity={snackBarstate.type}
        >
          {snackBarstate.message}
        </MuiAlert>
      </Snackbar>
    );
  };

  function handleContactSupportClick() {
    const mailtoUrl = `mailto:${encodeURIComponent(CONTACT_EMAIL)}`;
    window.location.href = mailtoUrl;
  }

  return (
    <>
      {renderSnackBar()}
      <Dialog open={isOpen}>
        <DialogTitle>
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Styled.deleteHeading>{title}</Styled.deleteHeading>
          </Box>
        </DialogTitle>
        <DialogContent sx={{ backgroundColor: "#F8F8F8" }}>
          <DialogContentText>
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Styled.deleteDesc
                isMobile={isMobile ? true : false}
                isMobileLandscape={isMobileLandscape ? true : false}
              >
                {desc?.slice(0, desc?.indexOf("${0}"))}
                <span
                  onClick={handleContactSupportClick}
                  style={{
                    color: "#2F95C7",
                    cursor: "pointer",
                    fontSize: "18px",
                  }}
                >
                  {CONTACT_EMAIL}
                </span>
                {desc?.slice(desc?.indexOf("${0}") + 4)}
              </Styled.deleteDesc>
            </Box>
          </DialogContentText>
        </DialogContent>
        <DialogActions
          sx={{
            backgroundColor: "#F8F8F8",
            borderTop: "1px solid rgba(0, 0, 0, 0.2)",
            width: "100%",
            height: "80px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              width: "470px",
            }}
          >
            <Styled.confirmBtn
              isMobile={isMobile ? true : false}
              isMobileLandscape={isMobileLandscape ? true : false}
              onClick={handleOpenFeedback}
            >
              {intlMessages.accountContactSupportText}
            </Styled.confirmBtn>
            <Styled.closeBtn
              isMobile={isMobile ? true : false}
              isMobileLandscape={isMobileLandscape ? true : false}
              onClick={handleLogOut}
            >
              {intlMessages.verifyEmailLogOutBtnText}
            </Styled.closeBtn>
          </Box>
        </DialogActions>
      </Dialog>
      {isOpenFeedback && (
        <Feedback
          purpose={purpose}
          isOpen={isOpenFeedback}
          closeFeedbackCallback={handleCloseFeedback}
          viewFeedbackSubmissionMessage={viewFeedbackSubmissionMessage}
          showErrorMessageCallback={showErrorMessage}
          handleOpenConnectionModalCallback={handleOpenConnectionModal}
        />
      )}
      {isOpenConnectionModal && (
        <ConnectionModal
          isOpen={isOpenConnectionModal}
          handleCloseConnectionModal={handleCloseConnectionModal}
        />
      )}
    </>
  );
}

export default LoginAlertBox;
