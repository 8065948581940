import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { useState } from "react";
import Styled from "./styles";
import Modal from "@mui/material/Modal";
import { Grid, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import LanguageSharpIcon from '@mui/icons-material/LanguageSharp';
import { useTheme } from "@mui/material/styles";
import intlMessages from "../../locales/en";
export default function ConnectionModal(props) {
  const { handleCloseConnectionModal, isOpen } = props;
  const [open, setIsOpen] = useState(isOpen);
  const theme = useTheme();
  const handleClose = () => {
    handleCloseConnectionModal();
  };
  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        sx={{ backgroundColor: "rgba(0, 0, 0, 0.5)" }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Styled.ModalBox sx={Styled.customStyling.modal}>
          <Styled.Header id={"modal-welcome-header"}>
            <Box>
              <Styled.HeaderText variant="h3" sx={{ fontWeight: "bold" }}>
                {intlMessages.noInternetHeader}
              </Styled.HeaderText>
            </Box>
            <IconButton aria-label="closeConnectionModal" onClick={handleClose}>
              <CloseIcon color="seondary.light" />
            </IconButton>
          </Styled.Header>
          <Styled.ModalBody>
            <Grid container>
              <Box sx={{width:'100%', textAlign:'center'}}>
                <LanguageSharpIcon sx={{fontSize:'100px'}}/>
              </Box>
              <Typography variant="p">
                {intlMessages.noInternetMessage}
              </Typography>
              <Button
                variant="contained"
                color="secondary"
                sx={{
                  color: theme.palette.primary.contrastText,
                  mt: 2,
                }}
                onClick={handleClose}
              >
                {intlMessages.okLabel}
              </Button>
            </Grid>
          </Styled.ModalBody>
        </Styled.ModalBox>
      </Modal>
    </div>
  );
}
