import styled from "@mui/system/styled";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import { Typography } from "@mui/material";

const ModalHeader = styled(Box)({
    padding: 8,
    paddingRight:12,
    boxShadow: "rgba(100, 100, 111, 0.2) 0px 3px 29px 0px",
    paddingLeft:25,
    alignItems:'center',
    display:'flex',
    flexDirection:'row',
    justifyContent: 'space-between'
  });
  
  const ModalActions = styled(Box)({
    padding: 5,
    paddingTop:15,
    paddingBottom:15,
    borderTop:'1px solid silver',
    display:'flex',
    justifyContent:'center'
  });
  
  const ModalTextField = styled(TextField)(({ theme,error }) => ({
    borderRadius: 3,
    // padding:5,
    marginBottom:error===true?'20px':'unset',
    background: '#F6F6F6',
    '& .MuiInputBase-root': {
        background: '#F6F6F6',
    },  
    '& input::placeholder': {
      color: '#000000',
      opacity: 1,
    },
    '& .MuiFormHelperText-root.Mui-error': {
      position: 'absolute',
      top: '100%'
    },
    ".css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input": {
      padding: 7,
    },
       width: "100%",
    [theme.breakpoints.down("md")]: {},
  }));
  
  const ModalBox = styled(Box)(({ theme }) => ({
    width: '30%',
    [theme.breakpoints.down("lg")]: {
      width: '60%',
    },
    [theme.breakpoints.down("sm")]: {
      width: '94%',
    },
  }));
  
  const SubmitButton = styled(Button)(({ theme }) => ({
    color: theme.palette.primary.contrastText,
    textTransform: "none",
  }));

const customStyling = {
    exportModal: {
      position: 'absolute',
      top: {xs:'45%' ,sm:'50%'},
      left: '50%',
      transform: 'translate(-50%, -50%)',
      bgcolor: 'background.paper',
      borderRadius:2,
      boxShadow: 24,
      overflowY:'auto',
      maxHeight: "90%",
      border:'none'
    }
  };
  
  const ModalContentBox = styled(Box)({
    marginTop: 30,
    marginLeft:25,
    marginRight:25,
    marginBottom:30,
  });

  const ErrorText = styled(Typography)(({theme }) => ({
    color:theme.palette.warning.main,
    fontSize:12,
    padding:2,
    display:'flex',
    textAlign:'flex-start',
  }))

  export default {
    ModalHeader,
    ModalActions,
    ModalTextField,
    ModalBox,
    ModalContentBox,
    customStyling,
    SubmitButton,
    ErrorText
  };