import {useState } from "react";
import Menu from "@mui/material/Menu";
import Styled from "./styles";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { Box} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { setOpenUpgradeModel, setUpgradeModalText } from "../../redux/slices/featuresSlice";
import intlMessages from "../../locales/en";

export default function DropDownMenu(props) {
  const {canEditQuiz}= useSelector((state)=>state.feature)
  const dispatch = useDispatch()
  const {row,editQuiz,shareQuiz,deleteQuiz} = props
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [editSrc,setEditSrc] = useState('/svgs/edit-icon.svg')
  const [deleteSrc,setDeleteSrc]= useState('/svgs/delete-icon.svg')
  const [shareSrc,setShareSrc] = useState('/svgs/share-icon.svg')
  const handleClick = (event) => {
    setDeleteSrc('/svgs/delete-icon.svg')
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <Styled.IconBtn onClick={handleClick}>
        <MoreVertIcon />
      </Styled.IconBtn>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            marginLeft: "-40px",
            backgroundColor: "white",
            color: "#7DC242",
            // width:'208px',
          },
        }}
      >
        <Styled.menuItem disableTouchRipple>
          <Box
            onClick={() => {
              setAnchorEl(null);
            }}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "row",
            }}
          >
            <img
              src={canEditQuiz.enable===false?'/svgs/edit-icon-crown.svg':editSrc}
              onClick={()=>{
                if(canEditQuiz.enable===true){
                setEditSrc("/svgs/edit-icon-green.svg")
                editQuiz(row._id)
                }
                else{
                  dispatch(setUpgradeModalText({
                    heading:intlMessages.upgradeModelHeading,
                    desc:intlMessages.upgradeModelDescText
                  }))
                  dispatch(setOpenUpgradeModel(true))
                }
              }}
              style={{ height: "34px", width: "48px", marginRight: "10px" }}
            ></img>

            <img
              src={deleteSrc}
              onClick={()=>{
                setDeleteSrc("/svgs/delete-icon-green.svg")
                deleteQuiz(row._id)
              }}
              style={{ height: "34px", width: "48px", marginRight: "10px" }}
            ></img>

            <img
              src={shareSrc}
              onClick={()=>{
                setShareSrc("/svgs/share-icon-green.svg")
                shareQuiz(row._id)
              }}
              style={{ height: "34px", width: "48px"}}
            ></img>
          </Box>
        </Styled.menuItem>
      </Menu>
    </div>
  );
}
