import styled from "@mui/system/styled";
import { Typography, Box, Divider } from "@mui/material";


const MainContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    margin: "0% 3%",
    borderRadius: "10px",
    backgroundColor: 'white',
}))

const WelcomeTextBox = styled(Box)(({ theme, isMobile, isMobileLandscape, isIpad, isIpadLandscape }) => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: isMobile || isMobileLandscape ? '20px' : '50px',
}))

const WelcomeText = styled(Typography)(({ theme, isMobile, isMobileLandscape, isIpad, isIpadLandscape }) => ({
    fontFamily: 'Open Sans',
    fontWeight: '400',
    lineHeight: isMobile || isMobileLandscape ? '24px' : '46px',
    fontSize: isMobile || isMobileLandscape ? '16px' : '18px',
    textAlign: 'center'
}))

const imageAndFormContainer = styled(Box)(({ theme, isMobile, isMobileLandscape, isIpad, isIpadLandscape }) => ({
    display: 'flex',
    flexDirection: isMobile || isMobileLandscape || isIpad || isIpadLandscape ? 'column-reverse' : 'row',
    alignItems: isMobile || isMobileLandscape || isIpad || isIpadLandscape ? 'center' : 'flex-start',
    justifyContent: 'center',
}))

const imageContainer = styled(Box)(({ theme, isMobile, isMobileLandscape, isIpad, isIpadLandscape }) => ({
    paddingTop: '42px',
    display: 'flex',
    flexDirection: 'column',
    width: isMobile || isMobileLandscape || isIpad || isIpadLandscape ? '100%' : '35%',
    // marginRight:'5%'
}))

const image = styled('img')(({ theme, isMobile, isMobileLandscape, isIpad, isIpadLandscape }) => ({
    height: isMobile ? '181.81px' : isMobileLandscape ?'242px': isIpad|| isIpadLandscape ? '378px' : '309px',
}))

const imageText = styled(Typography)(({ theme, isMobile, isMobileLandscape, isIpad, isIpadLandscape }) => ({
    fontWeight: '400',
    fontFamily: 'Open Sans',
    lineHeight: '28px',
    fontSize: '18px',
    textAlign: 'center',
    width: isMobile? '290px': isMobileLandscape?'410px':'unset',
    display:isMobile || isMobileLandscape?'block':'unset',
    margin:isMobile || isMobileLandscape?'auto':'unset',
    paddingLeft: isMobile || isMobileLandscape?'unset':'10%',
    paddingRight: isMobile || isMobileLandscape?'unset':'10%',
    marginBottom: isMobile || isMobileLandscape ? '20px' : '45px',
    marginTop: isMobile || isMobileLandscape ? '15px' : '30px'
}))

const formContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '50%',
}))

const trialText = styled(Typography)(({ theme }) => ({
    color: '#7DC242',
    fontWeight: '600',
    fontFamily: 'Open Sans',
    lineHeight: '32px',
    fontSize: '24px',
    textAlign: 'center',
    marginTop: '24px',
    marginBottom: '22px'
}))

const iconAndTextBox = styled(Box)(({ theme, isMobile, isMobileLandscape, isIpad, isIpadLandscape }) => ({
    height: isMobile ? '48px' :  isMobileLandscape? '50px' :'55.75px',
    width: isMobile ? '250px':  isIpadLandscape || isIpad ? '490px' : '410px',
    border: '1px solid #A7D5EC',
    borderRadius: '4px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: isMobile || isMobileLandscape ? '15px' : '30px',
    backgroundColor: '#FFFFFF',
    cursor: 'pointer',
    fontWeight: '400',
    fontFamily: 'Open Sans',
    lineHeight: '24px',
    fontSize: isMobile || isMobileLandscape?'14px':'16px',
    textAlign:'center'
}))

const icon = styled("img")(({ theme, isMobile, isMobileLandscape, isIpad, isIpadLandscape }) => ({
    height: isMobile || isMobileLandscape ? '18px' : '24px',
    width: isMobile || isMobileLandscape ? '18px' : '24px',
    marginRight: isMobile || isMobileLandscape ? '10px' : '15px'
}))

const divider = styled(Divider)(({ theme, isMobile, isMobileLandscape, isIpad, isIpadLandscape }) => ({
    width: isMobile ? '250px': '410px',
    marginBottom: isMobile || isMobileLandscape ? '15px' : '30px',
    color: '#DDDDDD'
}))

const loginText = styled(Typography)(({ theme }) => ({
    fontFamily: 'Open Sans',
    fontWeight: '400',
    lineHeight: '24px',
    fontSize: '14px',
    textAlign: 'center'
}))
export default {
    MainContainer,
    WelcomeTextBox,
    WelcomeText,
    imageAndFormContainer,
    imageContainer,
    image,
    imageText,
    formContainer,
    trialText,
    iconAndTextBox,
    loginText,
    divider,
    icon
}