import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  price: "",
  plan: "",
  duration: "yearly",
  openErrorBox: false,
  currentlySelectedTab:0,
  isScreenPanelBtnClicked:false,
  isCardBtnClicked:false,
  basicPlanPrice:'0.00',
  proMonthlyPlanPrice:'0.00',
  proYearlyPlanPrice:'0.00'
};

const paymentSlice = createSlice({
  name: "payment",
  initialState,
  reducers: {
    setPrice: (state, action) => {
      state.price = action.payload;
    },
    setPlan: (state, action) => {
      state.plan = action.payload;
    },
    setDuration: (state, action) => {
      state.duration = action.payload;
    },
    setOpenErrorBox: (state, action) => {
      state.openErrorBox = action.payload;
    },
    setCurrentlySelectedTab: (state,action)=>{
      state.currentlySelectedTab= action.payload
    },
    setIsScreenPanelBtnClicked: (state,action)=>{
      state.isScreenPanelBtnClicked=action.payload
    },
    setIsCardBtnClicked: (state,action)=>{
      state.isCardBtnClicked=action.payload
    },
    setBasicPlanPrice:(state,action)=>{
      state.basicPlanPrice=action.payload
    },
    setProMonthlyPlanPrice: (state,action)=>{
      state.proMonthlyPlanPrice=action.payload
    },
    setProYearlyPlanPrice: (state,action)=>{
      state.proYearlyPlanPrice=action.payload
    }
  },
});

export const { setPrice, setDuration, setPlan, setOpenErrorBox,setCurrentlySelectedTab,setIsScreenPanelBtnClicked,setIsCardBtnClicked,setBasicPlanPrice,setProMonthlyPlanPrice,setProYearlyPlanPrice } =
  paymentSlice.actions;
const { reducer } = paymentSlice;
export default reducer;
