import Styled from "./styles";
import FormControl from "@mui/material/FormControl";
import { Box, Typography, IconButton, ListSubheader } from "@mui/material";
import { useState, useEffect } from "react";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import CheckIcon from "@mui/icons-material/Check";
import { useMediaQuery } from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import CloseIcon from "@mui/icons-material/Close";
export default function SelectMenu(props) {
  const {
    isIpad,
    isMobile,
    isMobileLandscape,
    list,
    listTitle,
    defaultValue,
    setQuizSettings,
    width,
    isEnabled,
    isUserInfoProps = false,
    userInfoArray,
    openUpWard = false,
    isUserInfoDropdown,
    isError = false,
    removeError,
    setDefaultStates,
    hasIcon
  } = props;
  const [open, setOpen] = useState(false);
  const [quizType, setQuizType] = useState(defaultValue);
  const [personName, setPersonName] = useState([]);
  const isIpadLandscape = useMediaQuery(
    "(min-width: 800px) and (max-width: 1400px) and (orientation: landscape) and  (hover: none) and (pointer: coarse)",
    {
      noSsr: true,
    }
  );
  const handleChange = (event) => {
    setQuizType(event.target.value);
    setQuizSettings(event.target.value);
    const {
      target: { value },
    } = event;
    setPersonName(typeof value === "string" ? value.split(",") : value);
    typeof removeError != "undefined" && removeError();
    if (typeof setDefaultStates != "undefined") {
      setDefaultStates();
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  async function handleOrientationChange_() {
    setOpen(false);
  }
  useEffect(() => {
    window.addEventListener("orientationchange", handleOrientationChange_);
    return () => {
      window.removeEventListener("orientationchange", handleOrientationChange_);
    };
  }, []);

  useEffect(() => {
    if (isUserInfoProps) {
      if (!!userInfoArray || userInfoArray?.length != 0) {
        setPersonName(
          typeof userInfoArray === "string"
            ? userInfoArray.split(",")
            : userInfoArray
        );
      }
    }
  }, [userInfoArray]);

  return (
    <Styled.BoxContainer
      sx={{
        width:
          (isUserInfoProps || isUserInfoDropdown) && isMobile
            ? "100%"
            : "unset",
      }}
    >
      <FormControl
        disabled={isEnabled}
        sx={{
          border: isError ? "1px solid #d32f2f" : "1px solid #AAA",
          background: "#FFF",
          borderRadius: 1,
          height: "35px",
          width: width,
          mr: "10px",
        }}
      >
        <Styled.SelectInputBase
          IconComponent={
            (isUserInfoProps || isUserInfoDropdown) && !openUpWard
              ? ArrowDropDownIcon
              : ArrowDropUpIcon
          }
          name={listTitle}
          onChange={handleChange}
          open={open}
          onClose={handleClose}
          onOpen={handleOpen}
          multiple={isUserInfoProps ? true : false}
          displayEmpty
          value={isUserInfoProps ? personName : defaultValue}
          isMobile={isMobile ? true : false}
          isMobileLandscape={isMobileLandscape ? true : false}
          renderValue={(selected) => {
            if (isUserInfoProps) {
              if (selected.length === 0) {
                return listTitle;
              }
              return selected.join(", ");
            } else {
              if (selected.length === 0) {
                return listTitle;
              }
              return selected;
            }
          }}
          MenuProps={{
            sx:
              isUserInfoProps || isUserInfoDropdown
                ? Styled.customStyling(listTitle).user_info_role
                : Styled.customStyling(listTitle).menu,
            anchorOrigin: {
              vertical:
                isUserInfoProps || isUserInfoDropdown
                  ? openUpWard
                    ? "top"
                    : "bottom"
                  : isMobileLandscape
                  ? -15
                  : -5,
              horizontal:
                isUserInfoProps || isUserInfoDropdown ? "right" : "left",
            },
            transformOrigin: {
              vertical:
                isUserInfoProps || isUserInfoDropdown
                  ? openUpWard
                    ? "bottom"
                    : "top"
                  : "bottom",
              horizontal:
                isUserInfoProps || isUserInfoDropdown ? "right" : "left",
            },
            getContentAnchorEl: null,
            PaperProps: {
              style:
                isUserInfoProps || isUserInfoDropdown
                  ? {
                      minWidth:
                        isUserInfoProps || isUserInfoDropdown
                          ? "230px"
                          : "unset",
                      maxHeight:
                        isUserInfoProps || isUserInfoDropdown
                          ? "288px"
                          : "unset",
                    }
                  : {},
            },
          }}
          style={{
            marginTop: isMobile || isMobileLandscape ? "0px" : "-5px",
            width: width,
          }}
        >
          <ListSubheader
            sx={{
              paddingLeft: 0,
              paddingRight: 0,
              paddingTop: "8px",
              pointerEvents:
                isUserInfoProps || isUserInfoDropdown ? "" : "none",
              cursor: "unset",
            }}
          >
            <Styled.StyledMenuItem
              isUserInfoProps={isUserInfoProps || isUserInfoDropdown}
              sx={{
                borderBottom: "1px solid rgba(0, 0, 0, 0.10)",
                mb: "10px",
                pointerEvents:
                  isUserInfoProps || isUserInfoDropdown ? "" : "none",
                paddingRight: 0,
                cursor: "unset",
              }}
            >
              {isUserInfoProps || isUserInfoDropdown ? (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    width: "100%",
                    cursor: "unset",
                  }}
                >
                  <Typography
                    variant="body1"
                    sx={{
                      pointerEvents: "none",
                    }}
                  >
                    {listTitle}
                  </Typography>
                  <IconButton
                    aria-label="closeReviewModal"
                    onClick={() => {
                      handleClose();
                    }}
                  >
                    <CloseIcon color="seondary.light" />
                  </IconButton>
                </Box>
              ) : (
                <>{listTitle}</>
              )}
            </Styled.StyledMenuItem>
          </ListSubheader>
          {list.map((element, index) => (
            <Styled.StyledMenuItem
              key={index}
              isSelected={
                isUserInfoProps
                  ? personName.includes(element)
                  : defaultValue == element
              }
              value={element}
              sx={{ mb: index === list.length - 1 ? "0px" : "10px" }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems:"center",
                }}
              >
                {(isUserInfoProps && personName.includes(element)) ||
                (!isUserInfoProps && defaultValue == element) ? (
                  <CheckIcon
                    sx={{
                      width: "17px",
                      height: "17px",
                      marginTop: "3px",
                      marginRight: "5px",
                    }}
                  />
                ) : null}

                <Styled.MenuItemText
                  sx={{
                    pointerEvents: "none",
                  }}
                >
                  {element}
                </Styled.MenuItemText>
                {hasIcon &&  defaultValue!==element && <img style={{position:'absolute',right:'10px'}} src="/svgs/crown.svg"></img>}
              </Box>
            </Styled.StyledMenuItem>
          ))}
        </Styled.SelectInputBase>
      </FormControl>
    </Styled.BoxContainer>
  );
}
