import { useState, useEffect, useRef } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { Divider, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Link from "@mui/material/Link";
import Styled from "./styles";
import intlMessages from "../../locales/en";
import { copiedToClipboard, saveSelectedQuzies } from "../quiz/service";
import CircularProgress from "@mui/material/CircularProgress";
import {
  getInternetConnectionStatus,
  getBaseUrlOfBrowser,
  exportToGoogle,
  extractBaseTokenFromURL,
  isUserSignedIn,
} from "./service";
import { getExportFileName } from "../quiz/service";
import CreateAndGetTokenminiWindow from "../../util/mini_window_handler";
import { useDispatch, useSelector } from "react-redux";
import {
  setAuthToken,
  setIsSignedIn,
  setUserData,
  setUserState,
} from "../../redux/slices/logInSlice";
import { decodeToken } from "../../common/sign_in_service";
import { GOOGLE_LOGIN_URL, USER_STATE } from "../../settings";
import Alert from "@mui/material/Alert";
import { Snackbar } from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import { checkUrlIsGoogleForm } from "../../util/signin-user-data";
import { Categories, Events, logEvent } from "../../analytics";
import { setQuizData } from "../../redux/slices/quizSlice";
import GoogleSignSignupForm from "../google-signin-signup-modal/component";
import GooglePermissionModal from "../google-signin-signup-modal/component-permission";

const GoogleForms = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { isSignedIn } = useSelector((state) => state.logIn);
  const { userData } = useSelector((state) => state.logIn);
  const { enableEditMode, enableShareMode } = useSelector(
    (state) => state.library
  );
  const { quizTitle } = useSelector((state) => state.quiz);
  const {
    isOpen,
    closeGoogleFormModalCallback,
    openConnectionErrorCallback,
    quizData,
    exportFile,
    isMobileLandscape,
    isMobile,
    isIpad,
    isIpadLandscape,
  } = props;
  const [openGoogleFormModal, setopenGoogleFormModal] = useState(isOpen);
  const textFieldRef = useRef(null);
  const createGoogleForm = useRef(null);
  const [googleTitle, setGoogleTitle] = useState("");
  const [googleTitleError, setGoogleTitleError] = useState("");
  const [isSaving, setIsSaving] = useState(false);
  const [sharedOnGoogle, setsharedOnGoogle] = useState(false);
  const [googleFormUrl, setGoogleFormUrl] = useState("");
  const [miniWindow, setMiniWindow] = useState(null);
  const miniWindowRef = useRef(null);
  const [permissionError, setpermissionError] = useState(true);
  const [copied, setCopied] = useState(false);
  const [exportId, setExportId] = useState(null);
  const [openGoogleSignSignupForm, setOpenGoogleSignSignupForm] =
    useState(false);
  const [openGooglePermissionForm, setopenGooglePermissionForm] =
    useState(false);
  const [snackBarstate, setSnackBar] = useState({
    openSnackBar: false,
    vertical: "top",
    horizontal: "right",
    type: "warning",
    message: "",
    title: null,
    timeout: 3000,
  });
  const { vertical, horizontal, openSnackBar, title, type, message, timeout } =
    snackBarstate;

  useEffect(() => {
    if (openGoogleFormModal && textFieldRef.current) {
      textFieldRef.current.focus();
    }
    const handleKeyDown = (event) => {
      if (openGoogleFormModal && event.ctrlKey && event.key === "z") {
        event.preventDefault();
      }
    };
    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [openGoogleFormModal]);

  useEffect(() => {
    miniWindowRef.current = miniWindow; // Update the ref when miniWindow state changes
  }, [miniWindow]);

  const openGoogleSignInFormModal = () => {
    setOpenGoogleSignSignupForm(true);
  };

  const closeGoogleSignInFormModal = () => {
    closeGoogleFormModal();
    setOpenGoogleSignSignupForm(false);
  };

  const openGooglePermissionModal = () => {
    setopenGooglePermissionForm(true);
  };

  const closeGooglePermissionModal = (decodedToken) => {
    setopenGooglePermissionForm(false);
    if (decodedToken) {
      dispatch(setUserData(decodedToken));
      dispatch(setIsSignedIn(true));
      // setIsSaving(false);
      setpermissionError(false);
      dispatch(setUserState(USER_STATE.SIGNED_IN_USER));
      // here needs to call the api to save export data
      // setIsSaving(true);
      saveExportData();
    } else {
      closeGoogleFormModal();
    }
  };

  const saveExportData = async () => {
    let data = quizData.questionAndAnswers.filter((item) => item.isChecked);
    const { quiz_id, quiz_hash } = quizData;
    let resp = await saveSelectedQuzies(
      data,
      "google",
      quiz_id,
      quiz_hash,
      true,
      userData.username
    );
    setIsSaving(false);
    setExportId(resp?.data?.id || "");
    dispatch(
      setQuizData({
        ...quizData,
        exportData: resp.data,
      })
    );
  };

  const texts = {
    header: intlMessages.googleFormsLabelCapital,
    text: intlMessages.googleFormsTitlePermission,
  };

  useEffect(() => {
    // // console.log("token", authToken);
    // // console.log(userData, userData);
    if (checkUrlIsGoogleForm()) {
      logEvent(
        Categories.user_management,
        Events.google_forms_user_loggedin_with_google
      );
      // setpermissionError(false);
      handleRemoveSearchParams();
    }
    if (isSignedIn) {
      logEvent(
        Categories.user_management,
        Events.google_forms_login_modal_for_loggedin_user
      );
      // setpermissionError(true);
      openGooglePermissionModal();
    } else {
      logEvent(
        Categories.user_management,
        Events.google_forms_signin_signup_modal_for_nonsignedin_user
      );
      // setpermissionError(false);
      openGoogleSignInFormModal();
    }
    setGoogleTitle(enableEditMode || enableShareMode ? quizTitle : "");
  }, []);

  const handleRemoveSearchParams = () => {
    searchParams.delete("google-forms"); // Replace with the desired parameter name(s)
    navigate(`?${searchParams.toString()}`);
  };

  const openMiniWindow = async () => {
    setIsSaving(true);
    let url = GOOGLE_LOGIN_URL;
    if (isSignedIn) {
      url = `${url}?userId=${userData?.id}&onlyFormsPermission=true`;
      logEvent(
        Categories.user_management,
        Events.google_forms_login_modal_for_loggedin_user
      );
    } else {
      logEvent(
        Categories.user_management,
        Events.google_forms_signin_signup_modal_login_click
      );
    }
    // // console.log("Google_login_url", url)
    let resp = await CreateAndGetTokenminiWindow(url)
      .then(async (tokenData) => {
        if (tokenData.isFirstTimeUser) {
          navigate(`?v=1`);
        }
        const token = tokenData.token;
        logEvent(
          Categories.user_management,
          Events.user_management_login_google,
          "google-forms"
        );
        dispatch(setAuthToken(token));
        const decodedData = await decodeToken(token);
        dispatch(setUserData(decodedData));
        dispatch(setIsSignedIn(true));
        setIsSaving(false);
        setpermissionError(false);
        dispatch(setUserState(USER_STATE.SIGNED_IN_USER));
      })
      .catch((error) => {
        if (error === "allowpopup") {
          showWarningMessage(
            intlMessages.popUpError,
            intlMessages.popUpErrorTitle
          );
          // return;
        }
        if (error === "windowError") {
          showWarningMessage(
            intlMessages.googleFormsUnauthorizedError,
            intlMessages.googleFormsUnauthorizedTitie
          );
          // return;
        }
        // // console.log(error);
        setIsSaving(false);
      });
  };

  const navigateToSignUp = () => {
    logEvent(
      Categories.user_management,
      Events.google_forms_signin_signup_modal_signup_click
    );
    navigate("/signup?google-forms=true");
  };

  const closeGoogleFormModal = () => {
    // handle all events before closing modal
    closeGoogleFormModalCallback(sharedOnGoogle);
  };

  const onChangeGoogleTitle = (e) => {
    setGoogleTitleError("");
    setGoogleTitle(e.target.value);
  };

  const resetGoogleButtonState = () => {
    if (createGoogleForm.current) {
      createGoogleForm.current.style.backgroundColor = "#7DC242";
      createGoogleForm.current.style.color = "white";
    }
  };

  const disableGoogleButtonState = () => {
    if (createGoogleForm.current) {
      createGoogleForm.current.style.backgroundColor = "#E0E0E0";
      createGoogleForm.current.style.color = "#a6a6bd";
    }
  };

  const onSubmitGoogleForms = async () => {
    setIsSaving(true);
    disableGoogleButtonState();
    if (googleTitle.trim().length === 0) {
      setGoogleTitleError(intlMessages.googleFormsTitleError);
      setIsSaving(false);
      resetGoogleButtonState();
      return;
    }

    const internetConnection = await getInternetConnectionStatus();
    if (!internetConnection?.isConnected) {
      setIsSaving(false);
      resetGoogleButtonState();
      return openConnectionErrorCallback();
    }

    const { questionAndAnswers: quesAnsPairs, quiz_id, quiz_hash } = quizData;
    let { name: fileName, prety } = exportFile;
    fileName = fileName ? fileName : getExportFileName();
    // // console.log("Question and pair", quesAnsPairs );
    let QuesAnsPairArray = getSelectedAnswersArray(quesAnsPairs);
    // // console.log("QuesAnsPairArray", QuesAnsPairArray );
    exportToGoogle(
      QuesAnsPairArray,
      "google",
      quiz_id,
      quiz_hash,
      true,
      true,
      googleTitle,
      {
        comment: "...",
        email: userData.username,
        rating: 5,
      },
      userData.username,
      exportId
    )
      .then((res) => {
        setIsSaving(false);
        resetGoogleButtonState();
        if (!res) {
          setGoogleTitleError(intlMessages.invalidError);
          return openConnectionErrorCallback();
        }
        if (res.data.formUrl) {
          // return handleOpenConnectionModal();
          setGoogleFormUrl(res.data.formUrl);
          dispatch(
            setQuizData({
              ...quizData,
              exportData: res.data,
            })
          );
          setsharedOnGoogle(true);
          // // console.log(res.data)
        } else if (res.data.statusCode == 401) {
          setpermissionError(true);
        } else {
          return openConnectionErrorCallback();
        }
      })
      .catch((error) => {
        setIsSaving(false);
        resetGoogleButtonState();
        // console.error("Google error", error);
        return openConnectionErrorCallback();
      });
  };

  const getSelectedAnswersArray = (quesAnsPairs) => {
    let quesAnsPairArray = [];
    quesAnsPairs.forEach((item) => {
      if (item.isChecked) {
        quesAnsPairArray.push(item);
      }
    });
    return quesAnsPairArray;
  };

  const copyGoogleFormUrl = () => {
    copiedToClipboard(googleFormUrl);
    copiedSnackbar(intlMessages.googleFormsCopiedLink);
  };

  const openGoogleForm = () => {
    window.open(googleFormUrl, "_blank", "noreferrer");
  };

  const handleCloseSnackBar = () => {
    // console.log("onclosed call");
    setSnackBar({ ...snackBarstate, openSnackBar: false });
  };

  const copiedSnackbar = (msgText, title = null) => {
    return setSnackBar({
      ...snackBarstate,
      type: "success",
      message: msgText,
      openSnackBar: true,
      title: title,
      timeout: 3000,
    });
  };

  const showWarningMessage = (msgText, title = null) => {
    return setSnackBar({
      ...snackBarstate,
      type: "warning",
      message: msgText,
      openSnackBar: true,
      title: title,
      timeout: 7000,
    });
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      onSubmitGoogleForms();
    }
  };

  const renderSnackBar = () => {
    return (
      <Snackbar
        open={openSnackBar}
        anchorOrigin={{ vertical, horizontal }}
        autoHideDuration={snackBarstate.timeout}
        onClose={handleCloseSnackBar}
        key={vertical + horizontal}
      >
        <MuiAlert
          elevation={6}
          variant="filled"
          sx={{ color: "white" }}
          onClose={handleCloseSnackBar}
          severity={snackBarstate.type}
        >
          {snackBarstate.message}
        </MuiAlert>
      </Snackbar>
    );
  };

  return (
    <>
      {renderSnackBar()}
      {openGoogleSignSignupForm && (
        <GoogleSignSignupForm
          openGoogleSignSignupForm={openGoogleSignSignupForm}
          closeGoogleFormModal={closeGoogleSignInFormModal}
          signInparams="google-forms=true"
        />
      )}
      {openGooglePermissionForm && (
        <GooglePermissionModal
          openGooglePermissionForm={openGooglePermissionForm}
          closeGooglePermissionModal={(decodedToken) =>
            closeGooglePermissionModal(decodedToken)
          }
          texts={texts}
          queryParams={"forms"}
          isMobile={isMobile}
        />
      )}
      <Modal
        open={openGoogleFormModal && isSignedIn && !permissionError}
        aria-labelledby="review-modal"
        aria-describedby="review-description"
      >
        <Styled.ModalBox sx={Styled.customStyling.exportModal}>
          <Styled.ModalHeader>
            <Typography
              id="review-modal-title"
              variant="h5"
              component="h5"
              style={{ fontWeight: "bold" }}
            >
              {!permissionError
                ? intlMessages.googleFormTitle
                : intlMessages.googleFormsLabelCapital}
            </Typography>
            <IconButton
              aria-label="closeReviewModal"
              onClick={() => {
                closeGoogleFormModal();
              }}
            >
              <CloseIcon color="seondary.light" />
            </IconButton>
          </Styled.ModalHeader>
          <Styled.ModalContentBox
            sx={{ display: !permissionError ? "" : "none" }}
          >
            <Typography
              color="secondary.dark"
              variant="body1"
              mt={2}
              style={{
                fontSize: "14px",
                display: !sharedOnGoogle ? "" : "none",
                marginBottom: "10px",
              }}
            >
              {`${intlMessages.googleFormQuizTitle} `}
            </Typography>
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Styled.ModalTextField
                value={googleTitle}
                onChange={onChangeGoogleTitle}
                placeholder={`${intlMessages.googleFormTitlePlaceholder}`}
                type="email"
                required
                helperText={googleTitleError}
                error={googleTitleError ? true : false}
                sx={{ display: !sharedOnGoogle ? "" : "none" }}
                ref={textFieldRef}
                onKeyDown={handleKeyDown}
              />
              <Styled.ModalGoogleurlTextField
                value={googleFormUrl}
                placeholder={intlMessages.googleFormPlaceholder}
                type="text"
                multiline
                rows={3}
                sx={{
                  mt: 2,
                  padding: "0px",
                  display: sharedOnGoogle ? "" : "none",
                }}
                InputProps={{
                  readOnly: true,
                  height: "71px",
                  padding: "0px 5px",
                  fontSize: "14px",
                }}
              />
            </Box>
          </Styled.ModalContentBox>
          <Box>
            <Styled.ModalActions
              sx={{ display: !permissionError ? "" : "none" }}
            >
              <Styled.CreateQuizButton
                variant="contained"
                color="secondary"
                onClick={onSubmitGoogleForms}
                sx={{ display: !sharedOnGoogle ? "" : "none" }}
                loading={isSaving}
                loadingPosition="start"
                disabled={isSaving ? "true" : undefined}
                loadingIndicator={
                  <CircularProgress
                    sx={{ ml: "25px" }}
                    color="inherit"
                    size={16}
                  />
                }
                ref={createGoogleForm}
              >
                {intlMessages.continueLabel}
              </Styled.CreateQuizButton>
              <Button
                sx={{
                  textTransform: "none",
                  marginLeft: 1,
                  display: !sharedOnGoogle ? "" : "none",
                }}
                variant="outlined"
                color="secondary"
                disabled={isSaving ? "true" : undefined}
                onClick={() => closeGoogleFormModal()}
              >
                {intlMessages.cancelLabel}
              </Button>

              <Styled.CreateQuizButton
                variant="contained"
                color="secondary"
                sx={{
                  display: sharedOnGoogle ? "" : "none",
                  marginRight: "15px",
                }}
                onClick={copyGoogleFormUrl}
              >
                {copied
                  ? intlMessages.copiedLinkLabel
                  : intlMessages.copyLinkLabel}
              </Styled.CreateQuizButton>

              <Styled.CreateQuizButton
                variant="contained"
                color="secondary"
                sx={{ display: sharedOnGoogle ? "" : "none" }}
                onClick={openGoogleForm}
              >
                {intlMessages.googleFormQuizOpen}
              </Styled.CreateQuizButton>
            </Styled.ModalActions>
          </Box>
        </Styled.ModalBox>
      </Modal>

      {/* <Modal
          open={openGoogleFormModal && !isSignedIn && !permissionError}
          aria-labelledby="review-modal"
          aria-describedby="review-description"
        >
          <Styled.ModalBox sx={Styled.customStyling.exportModal}>
          <Styled.ModalHeader>
            <Typography
              id="review-modal-title"
              variant="h5"
              component="h5"
              style={{ fontWeight: "bold" }}
            >
              {intlMessages.googleSignInModalHeader}
            </Typography>
            <IconButton
              aria-label="closeReviewModal"
              onClick={() => {
                closeGoogleFormModal();
              }}
            >
              <CloseIcon color="seondary.light" />
            </IconButton>
          </Styled.ModalHeader>
          <Styled.ModalContentBox>
            <Box mt={'30px'} mb={'30px'} sx={{ display: "flex", flexDirection: "column", alignItems: 'center', justifyContent: 'center', opacity: isSaving ? 0.5 : 1, pointerEvents: isSaving ? 'none' : 'auto',  }} >
              <Typography variant="body1" style={{ fontSize: '14px', color: '#000000', textAlign: 'center' }}>
                    {`${intlMessages.googleSignUpModalText} `}
                    <Link
                      component="button"
                      variant="body2"
                      underline="none"
                      sx={{ color: "#1A73E8", opacity: isSaving ? 0.5 : 1, pointerEvents: isSaving ? 'none' : 'auto',}}
                      onClick={ navigateToSignUp }
                    >
                      {`${intlMessages.googleLinkForSignUp} `}
                    </Link>
              </Typography>
              <Styled.CustomDivider orientation="horizontal" >{intlMessages.signUpOrText}</Styled.CustomDivider>
              <Typography variant="body1" style={{ fontSize: '14px', color: '#000000' }}>
                    {`${intlMessages.googlesignupalreadytext} `}
                    <Link
                      component="button"
                      variant="body2"
                      underline="none"
                      sx={{ color: "#1A73E8", opacity: isSaving ? 0.5 : 1, pointerEvents: isSaving ? 'none' : 'auto',}}
                      onClick={ ()=>{navigate('/login?google-forms=true') }}
                    >
                      {`${intlMessages.signUpLoginText} `}
                    </Link>
              </Typography>
            </Box>
          </Styled.ModalContentBox>
        </Styled.ModalBox>
        </Modal>

        <Modal
          open={openGoogleFormModal && permissionError && isSignedIn}
          aria-labelledby="review-modal"
          aria-describedby="review-description"
        >
          <Styled.ModalPrmissionSignInBox sx={Styled.customStyling.exportModal}>
            <Styled.ModalHeader>
              <Typography
                id="review-modal-title"
                variant="h5"
                component="h5"
                style={{ fontWeight: "bold"}}
              >
                {intlMessages.googleFormsLabelCapital}
              </Typography>
              <IconButton
                aria-label="closeReviewModal"
                onClick={() => {
                    closeGoogleFormModal();
                }}
              >
                <CloseIcon color="seondary.light" />
              </IconButton>
            </Styled.ModalHeader>
                    <Styled.ModalContentBox >
                        <Typography color="secondary.dark" variant="body1" mt={2} mb={3} style={{ fontSize: '18px', display: !sharedOnGoogle  ? "" : "none", color: '#231F20' }}>
                              {`${intlMessages.googleFormsTitlePermission} `}
                        </Typography>
                        <Box sx={{ display: "flex", flexDirection: "column", alignItems: 'center', justifyContent: 'center', opacity: isSaving ? 0.5 : 1, pointerEvents: isSaving ? 'none' : 'auto',  }} onClick={openMiniWindow} >
                            <Styled.ContinueGoogleBtn isMobile={isMobile}>
                            {isSaving && <CircularProgress color="inherit" sx={{marginRight: "10px"}} size={16} />}
                            <img style={{ height: 24, marginRight:'3px'}} src={"/svgs/Google.svg"} />
                                <Typography variant="body1" style={{ fontSize: '16px', color: '#000000', marginLeft: '15px' }}>{intlMessages.googleFormQuizSIgnInBtnText}</Typography>
                            </Styled.ContinueGoogleBtn>
                        </Box>
                    </Styled.ModalContentBox>

          </Styled.ModalPrmissionSignInBox>
        </Modal> */}
    </>
  );
};

export default GoogleForms;
