import { useEffect, useRef, useState } from "react";
import intlMessages from "../../locales/en";
import { webserviceCall } from "./service";
import { GURU_SERVER_URL } from "../../settings";
import { useDispatch, useSelector } from "react-redux";
import { store } from "../../redux/store";
import {
  createGuruCall,
  pollCallAPI,
  setCallStatusString,
  setResetStates,
  updateCallAPI,
  setCancelClick,
  setDisableBtns,
  setCallHref,
  setCallConnected,
} from "../../redux/slices/guruSlice";
import { header_images_base64 } from "../../components/header/header-images-base64";
import { Categories, Events, logEvent } from "../../analytics";

const GuruCall = (props) => {
  const {
    userDetails,
    // callData,
    isAudio,
    closeCallModal,
    guestUniqueId,
    isMobile,
    isIpadLandscape,
    isIpad,
    isMobileLandscape,
    openModalOnOrientationChange = false,
  } = props;

  const {
    callStatusString,
    callData,
    cancelClick,
    disableBtns,
    callHref,
    callConnected,
  } = useSelector((state) => state.guru);

  const dispatch = useDispatch();
  const [callStatusText, setCallStatus] = useState("");
  const [meetingSubType, setMeetingSubType] = useState(`1_0`);
  const endCallBtn = useRef(null);
  const closeModalBtn = useRef(null);
  const modalRef = useRef(null);

  useEffect(() => {
    if (!openModalOnOrientationChange) {
      dispatch(setCallStatusString(callStatusMessageMap(0)));
      if (isAudio) {
        setMeetingSubType(`1_1`);
      }
      resetStatus();
      enableBtns();
      createCall(isAudio ? "1_1" : "1_0");
    }
  }, []);

  useEffect(() => {
    // Set focus to the modal or another element when the modal is opened
    modalRef.current.focus();
  }, []);

  const disableBtnsFunction = () => {
    if (endCallBtn.current) {
      endCallBtn.current.disabled = true;
      endCallBtn.current.style.opacity = 0.7;
    }
    if (closeModalBtn.current) {
      closeModalBtn.current.disabled = true;
      // closeModalBtn.current.style.opacity = 0.7;
    }
  };

  const enableBtns = () => {
    if (endCallBtn.current) {
      endCallBtn.current.disabled = false;
      endCallBtn.current.style.opacity = 1;
    }
    if (closeModalBtn.current) {
      closeModalBtn.current.disabled = false;
      // closeModalBtn.current.style.opacity = 1;
    }
  };

  const setLocalItem = (itemName, data) => {
    localStorage.setItem(itemName, data);
  };
  const getLocalItem = (itemName) => {
    return localStorage.getItem(itemName);
  };
  const callStatusMessageMap = (msgType) => {
    let msg = intlMessages.GURU_STR_3;
    switch (msgType) {
      case 0:
        msg = intlMessages.GURU_STR_3;
        break;
      case 1:
        msg = intlMessages.GURU_STR_4;
        break;
      case 2:
        msg = intlMessages.GURU_STR_5;
        break;
      case 3:
        msg = intlMessages.GURU_STR_6;
        break;
      case 4:
        msg = intlMessages.GURU_STR_7;
        break;
      case 5:
        msg = intlMessages.GURU_STR_3;
        break;
      case 6:
        msg = intlMessages.GURU_STR_8;
        break;
      case 7:
        msg = intlMessages.GURU_STR_9;
        break;
      case 8:
        msg = intlMessages.GURU_STR_10;
        break;
      default:
        msg = intlMessages.GURU_STR_3;
    }
    return msg;
  };

  const callStatusMap = (map) => {
    let callMap = {
      HYPE_INVITE_STATUS_PENDING: 0,
      HYPE_INVITE_STATUS_DISPLAYED: 1,
      HYPE_INVITE_STATUS_ACCEPTED: 2,
      HYPE_INVITE_STATUS_REJECTED: 3,
      HYPE_INVITE_STATUS_TIMEOUT: 4,
      HYPE_INVITE_STATUS_JOINING: 5,
      HYPE_INVITE_STATUS_JOINED: 6,
      HYPE_INVITE_STATUS_CANCELED: 7,
      HYPE_INVITE_STATUS_NOANSWER: 8,
    };
    return callMap[map];
  };

  const errorConnectedCall = (error) => {
    //disable button
    dispatch(setCallStatusString(callStatusMessageMap(4)));
    setTimeout(() => {
      hideModal();
    }, 1000);
  };

  const createCall = (subType) => {
    dispatch(createGuruCall({ subType, callData, guestUniqueId }))
      .unwrap()
      .then((res) => {
        // // console.log(res, res);
        pollCallStatus();
      })
      .catch((error) => {
        // console.log(error, error);
        errorConnectedCall();
      });
  };

  const updateCallStatus = (newStatus, successCallback, errorCallback) => {
    let payload = {
      url: `${GURU_SERVER_URL}/api/v1/user/${
        userDetails.userId
      }/meetings/invite/${getLocalItem("refId")}`,
      type: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": userDetails.token,
        version: "4.1.1",
      },
      body: { status: newStatus },
    };
    webserviceCall
      .webserviceHelper(payload)
      .then((response) => {
        // console.log("Success in call connected", response);
        if (response && response.status == 200) {
          if (response.data && response.data.statusCode == 0) {
            successCallback(response.data);
            return;
          }
        }
        errorCallback(response);
      })
      .catch((error) => {
        // console.log("error found", error);
        errorCallback(error);
        //no guru found
      });
  };

  const onCancelClick = () => {
    setLocalItem("cancelClick", true);
    dispatch(setCancelClick(true));
    dispatch(setCallStatusString(intlMessages.GURU_STR_25));
    disableBtnsFunction();
    setLocalItem("disableEndBtn", true);
    dispatch(setDisableBtns(true));
    logEvent(Categories.guru, Events.guru_call_ended);
    dispatch(updateCallAPI({ status: 7 }))
      .unwrap()
      .then((res) => {
        // console.log(res, res);
        setTimeout(() => {
          hideModal();
        }, 1000);
      })
      .catch((error) => {
        // console.log(error, error);
        setTimeout(() => {
          hideModal();
        }, 1000);
      });
  };

  const pollCallStatus = () => {
    const refId = store.getState().guru.refId;
    if (!refId) {
      return errorConnectedCall();
    }
    dispatch(pollCallAPI({ status: 7 }))
      .unwrap()
      .then((response) => {
        if (response.data && response.statusCode == 0) {
          successCB(response);
          return;
        }
      })
      .catch((error) => {
        // console.log(error, error);
        setTimeout(() => {
          hideModal();
        }, 1000);
      });
    // let payload = {
    //   url: `${GURU_SERVER_URL}/api/v1/user/${userDetails.userId}/meetings/invite/${refId}`,
    //   type: "GET",
    //   headers: {
    //     "Content-Type": "application/json",
    //     "x-access-token": userDetails.token,
    //     version: "4.0.0",
    //   },
    // };
    // webserviceCall
    //   .webserviceHelper(payload)
    //   .then((response) => {
    //     // console.log("Success in call connected", response);
    //     if (response && response.status == 200) {
    //       if (response.data && response.data.statusCode == 0) {
    //         successCB(response.data);
    //         return;
    //       }
    //     }
    //     errorConnectedCall(response);
    //   })
    //   .catch((error) => {
    //     // console.log("error found", error);
    //     errorConnectedCall(error);
    //     //no guru found
    //   });
  };

  const successCB = (data) => {
    const isCancelClicked = store.getState().guru.cancelClick;
    if (isCancelClicked) {
      return;
    }
    let callStatusReceiv = data.data.callStatus;
    let inviterStatus = data.data.inviterStatus;
    if (callStatusReceiv == callStatusMap("HYPE_INVITE_STATUS_DISPLAYED")) {
      //set call status displayed
      dispatch(setCallStatusString(intlMessages.GURU_STR_4));
    }
    // if call status accepted
    if (callStatusReceiv == callStatusMap("HYPE_INVITE_STATUS_ACCEPTED")) {
      if (isCancelClicked) {
        return;
      }
      logEvent(Categories.guru, Events.guru_call_accepted);
      dispatch(updateCallAPI({ status: 5 }))
        .unwrap()
        .then((res) => {
          // console.log(res, res);
          setTimeout(() => {
            let meetingUrl = `${GURU_SERVER_URL}/deeplink-test/deepLink.html?username=&roomId=${data.data.meetingId}&participantEmail=&meetingSubType=${meetingSubType}#guestUser`;
            openGuruNewTab(meetingUrl);
          }, 1000);
          return;
        })
        .catch((error) => {
          dispatch(setCallStatusString(intlMessages.GURU_STR_7));
          setTimeout(() => {
            hideModal();
          }, 1000);
          return;
        });
      //REDIRECT TO HYPE
      // updateCallStatus(
      //   5,
      //   () => {
      //     setTimeout(() => {
      //       let meetingUrl = `${GURU_SERVER_URL}/guest.html?username=&roomId=${data.data.meetingId}&participantEmail=&meetingSubType=${meetingSubType}#guestUser`;
      //       openGuruNewTab(meetingUrl);
      //     }, 1000);
      //     return;
      //   },
      //   () => {
      //     dispatch(setCallStatusString(intlMessages.GURU_STR_7));
      //     setTimeout(() => {
      //       hideModal();
      //     }, 1000);
      //     return;
      //   }
      // );
      return;
    }

    if (callStatusReceiv == callStatusMap("HYPE_INVITE_STATUS_REJECTED")) {
      //show call status rejected
      // setDisbaleEndBtn(true);
      logEvent(Categories.guru, Events.guru_call_rejected_by_guru);
      setLocalItem("disableEndBtn", true);
      dispatch(setDisableBtns(true));
      dispatch(setCallStatusString(intlMessages.GURU_STR_6));
      setTimeout(() => {
        hideModal();
        //hide call model
      }, 1000);
      return;
    }

    if (
      callStatusReceiv == callStatusMap("HYPE_INVITE_STATUS_TIMEOUT") ||
      callStatusReceiv == callStatusMap("HYPE_INVITE_STATUS_CANCELED") ||
      inviterStatus == callStatusMap("HYPE_INVITE_STATUS_CANCELED")
    ) {
      // setDisbaleEndBtn(true);
      logEvent(Categories.guru, Events.guru_call_not_connected);
      setLocalItem("disableEndBtn", true);
      dispatch(setDisableBtns(true));
      dispatch(setCallStatusString(intlMessages.GURU_STR_7));
      setTimeout(() => {
        hideModal();
      }, 1000);
      return;
    }

    setTimeout(() => {
      pollCallStatus(data);
    }, 1000);
  };

  const openGuruNewTab = (meetingUrl) => {
    //here set url to href
    // setCallHref(meetingUrl);
    setLocalItem("calHref", meetingUrl);
    dispatch(setCallHref(meetingUrl));
    let x = window.open(meetingUrl, "_blank");
    // console.log(x);
    if (!x) {
      // setDisbaleEndBtn(true);
      // setCallconnected(true);
      setLocalItem("disableEndBtn", true);
      dispatch(setDisableBtns(true));
      setLocalItem("callConnected", true);
      dispatch(setCallConnected(true));
      dispatch(setCallStatusString(intlMessages.GURU_STR_5));
    } else {
      logEvent(Categories.guru, Events.guru_call_joined);
      hideModal();
    }
  };

  const hideModal = () => {
    resetStatus();
    setTimeout(() => {
      enableBtns();
      closeCallModal();
      dispatch(setResetStates());
    }, 1000);
  };

  const resetStatus = () => {
    localStorage.removeItem("cancelClick");
    localStorage.removeItem("refId");
    localStorage.removeItem("callConnected");
    localStorage.removeItem("disableEndBtn");
    localStorage.removeItem("calHref");
  };

  return (
    <>
      <div id="myModal" className="modal" ref={modalRef} tabIndex="0">
        <div
          className={
            "modal-content " +
            (isMobile
              ? " modal-content-width-mobile "
              : isMobileLandscape
              ? " modal-content-width-mobile-landscape "
              : " modal-content-width-desktop ")
          }
        >
          <div
            className={
              isMobileLandscape ? " callMainDivLandscape " : "callMainDiv"
            }
          >
            <div className={"insideContents"}>
              <div className={"cardTitle"} style={{ textAlign: "left" }}>
                <h5>{intlMessages.GURU_STR_45}</h5>
              </div>

              <div className="mt-5" style={{ display: "flex" }}>
                <div className={"iconWrapperCall"}>
                  <img
                    id="guruIcon"
                    className={"iconWrapperCall"}
                    src={`${header_images_base64[3]}`}
                    width="70"
                    height="70"
                  />
                </div>
                <div className={"modalText"}>
                  <p className={"guruStatus"} id="guruCallStatus">
                    {callStatusString}
                  </p>
                  <p className={"guruTitle"} id="guruName">
                    {callData.username}
                  </p>
                  <div className={isMobile ? " display-grid" : " display-f"}>
                    <a
                      className={`btn  btnCancel  ${
                        callConnected ? "displayNone" : ""
                      }`}
                      onClick={onCancelClick}
                      ref={endCallBtn}
                    >
                      {intlMessages.GURU_STR_28}
                    </a>
                    <a
                      className={`btn btnPrimary callJoinBtn ${
                        !callConnected ? "displayNone" : " "
                      }`}
                      href={callHref}
                      target="_blank"
                      onClick={() =>
                        logEvent(Categories.guru, Events.guru_call_joined)
                      }
                    >
                      {intlMessages.GURU_STR_35}
                    </a>
                    <a
                      className={`btn  btnSecondary  closeBtn  ${
                        !callConnected ? "displayNone" : ""
                      }`}
                      onClick={() => hideModal()}
                      style={{ marginTop: "0" }}
                      ref={closeModalBtn}
                    >
                      {intlMessages.GURU_STR_29}
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div
              className={
                isMobile ? " mobile-footer-class" : " cardFooter repliesClass"
              }
            >
              <p>{intlMessages.GURU_STR_46}</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default GuruCall;
