import React from "react";
import { Outlet } from "react-router-dom";
import Header from "../header/component";
import Styled from "./styles";
import Footer from "../footer/component";
const Layout = () => {
  return (
    <>
      <Header />
      <Styled.Children  style={{
          background: "linear-gradient(90deg, #4E0F89 0%, #7D00BA 100%)",
        }}>
        <Outlet/>
      </Styled.Children>
    </>
  );
};

export default Layout;
