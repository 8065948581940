import { useState, useEffect, useRef } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Styled from "./styles";
import Checkbox from "@mui/material/Checkbox";
import Modal from "@mui/material/Modal";
import FileCopyOutlinedIcon from "@mui/icons-material/FileCopyOutlined";
import CloseIcon from "@mui/icons-material/Close";
import AddIcon from "@mui/icons-material/Add";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import Fade from "@mui/material/Fade";
import { FormControlLabel, IconButton, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import Radio from "@mui/material/Radio";
import Slide from "@mui/material/Slide";
import DownloadIcon from "@mui/icons-material/Download";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import CircularProgress from "@mui/material/CircularProgress";
import WelcomeModal from "../welcome-modal/component";
import Footer from "../footer/component";
import { debounce } from "lodash";
import {
  getSentenceCount,
  getWordCount,
  validateInput,
  getFileFromUrl,
  copiedToClipboard,
  isAnyCorrectAnswer,
  validatedQuizText,
  isQuestionAndAnswersAreValid,
  getInvalidParagraphsOfText,
  isStringsEqual,
  generateQuizFromParagraph,
  exportFileFromApi,
  saveFeedBack,
  saveSelectedQuzies,
  saveSelectedQuziesPdf,
  getExportFileName,
  getBaseUrlOfBrowser,
  getSelectedQuizsCount,
  iOS,
  useWindowSize,
  MAX_ALLOWED_ANSWERS,
  MAX_QUIZ_WORDS,
  MIN_QUIZ_WORDS,
  TEXT_EXPORT_FILE_TYPE,
  PDF_EXPORT_FILE_TYPE,
  CLIPBOARD_EXPORT_TYPE,
  CORRECT_OPTION_SYMBOL,
  CORRECT_OPTION_SYMBOL_PDF,
  MAX_TXT_FILE_UPLOAD_SIZE,
  checkForQuestionAnswerErrorsAndScroll,
  COMPANY_NAME,
  APP_NAME,
  handleImportSampleText,
  isMobileDevice,
  getQuestionAnswer,
  saveQuiz,
  updateQuiz,
  forceUpdateQuiz,
  generateQuizFromCapturedImage,
  importUploadFile,
  generateContentFromURL,
  wordsWithSubstring,
  exportXML,
  exportAiken,
  exportQT12,
  exportGoogleSheet,
  loadScrybeContent,
} from "./service";
import ConnectionModal from "../connection-modal/component";
import intlMessages from "../../locales/en";
import Review from "../review/component";
import {
  setQuizData,
  setQuizInputText,
  setShowQuizContentPage,
  setShowWelcomeModal,
  setShowSampleTextModal,
  setAllQuestionsSelected,
  setquizLandscapeBackBtnClicked,
  setIsQuizSaved,
  setQuizTitle,
  setQuizId,
  setOpenCameraModal,
  setIsDisableQuizSettings,
  setIsShowCameraWarning,
  setIsQuizLoaded,
  setQuizGenerationCurrentState,
  setIsLoading,
} from "../../redux/slices/quizSlice";
import { useDispatch, useSelector } from "react-redux";
import Link from "@mui/material/Link";
import {
  logEvent,
  Categories,
  Events,
  logPageView,
  initGA,
} from "./../../analytics";
import { initAdds, sendPageView } from "../../adds_conversion";
import { sendConversionEvent } from "../../adds_conversion";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import * as React from "react";
import ExportMenu from "../export-menu/component";
import ImportMenu from "../import-menu/component";
import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";
import RemoveIcon from "@mui/icons-material/Remove";
import SampleTextMobileModal from "../sample-text-mobile-modal/component";
import GenerateQuizMobileLandscapeHeader from "../quiz-mobile-landscape-header/component";
import GoogleForms from "../google-forms/component";
import SignUpWelcomeModal from "../sign-up-welcome-modal/component";
import { getToken } from "../../common/sign_in_service";
import {
  ABORT_CONTROLLER_MAP,
  MAX_FILE_UPLOAD_SIZE,
  USER_STATE,
} from "../../settings";
import LoginAlertBox from "../login-alert/component";
import {
  checkUrlIsGoogleDrive,
  checkUrlIsGoogleForm,
  checkUrlIsSaveQuiz,
  getFirstCharacters,
} from "../../util/signin-user-data";
import { BounceLoader } from "react-spinners";
import EmailModal from "../email-modal/component";
import SettingsModal from "../settings/component";
import ShareModal from "../share-modal/component";
import TextfieldModal from "../../common/textfield-modal/component";
import AlertBox from "../alert/component";
import {
  setEnableEditMode,
  setEnableShareMode,
  setNavigateToQuizPage,
} from "../../redux/slices/librarySlice";
import ExisitingQuizWarningModal from "../../common/warning-modal/component";
import ImportFileMain from "../import-file/component-main";
import GoogleSignSignupForm from "../google-signin-signup-modal/component";
import { containsOnlyNewlines } from "../google-picker/service";
import QuizSettings from "../../common/quiz-setting-menu/component";
import CameraModal from "../camera-modal/component";
import ImportLoaderModal from "../../common/import-loader-modal/component";
import DriveFileContent from "../google-picker/component-drive-file";
import UserInformation from "../user-information/component";
import { generateQuizFromURL } from "../../api/quiz/methods";
import {
  setIsShowWelcomeModal,
  setIsVerified,
  setIsVerifyPage,
  setOpenExpiryModal,
} from "../../redux/slices/logInSlice";
import { wordsSplit } from "words-count";
import { store } from "../../redux/store";
import UpgradeModal from "../../common/upgrade-modal/component";
import {
  setOpenPricingModal,
  setOpenSubscriptionModal,
  setOpenUpgradeModel,
  setUpgradeModalText,
} from "../../redux/slices/featuresSlice";
import ExportFileModal from "../export-file-modal/component";
import SubscriptionModal from "../subscription-modal/component";
import TrialInfoModal from "../../common/trial-info-modal/component";
import IFPSModal from "../../common/ifps-modal/component";
import ExpiryModal from "../../common/expiry-modal/component";

let controller = null;
initGA();
initAdds();

function Quiz() {
  const BASE_URL_EXPORT_FILE = getBaseUrlOfBrowser();
  const theme = useTheme();
  const windowSize = useWindowSize();
  const inputFile = useRef(null);
  const dispatch = useDispatch();
  const [isUpload, setIsUpload] = useState(false);
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"), {
    noSsr: true,
  });
  const isMobileLandscape = useMediaQuery(
    `${theme.breakpoints.down(
      "md"
    )}  and (orientation: landscape) and (hover: none) and (pointer: coarse)`,
    {
      noSsr: true,
    }
  );

  const isIpad = useMediaQuery(
    window.navigator.userAgent.includes("OneScreen_TL7")
      ? "(min-width:620px) and (max-width:960px) and (orientation: landscape) "
      : "(min-width:620px) and (max-width:840px) and (orientation: portrait) and  (hover: none) and (pointer: coarse)",
    {
      noSsr: true,
    }
  );
  const isT7 = useMediaQuery(
    "(min-width: 1280px) and (max-width: 1280px) and (min-height: 540px) and (max-height: 540px)",
    {
      noSsr: true,
    }
  );
  let isIpadLandscape = useMediaQuery(
    "(min-width: 1000px) and (max-width: 1400px) and (orientation: landscape) and  (hover: none) and (pointer: coarse)",
    {
      noSsr: true,
    }
  );
  isIpadLandscape = isIpadLandscape && !isT7 ? true : false;
  const isTl7 = navigator?.userAgent?.indexOf("OneScreen_TL7") >= 0;
  const {
    quizInputText,
    showQuizContentPage,
    quizData,
    consentBtnClicked,
    openSampleTextModal,
    isAllQuestionsSelected,
    isquizLandscapeBackBtnClicked,
    quizId,
    quizTitle,
    checkAllQuestionsDrive,
    isLoading,
    quizSettingHeight,
    quizSettingMobileHeight,
    quizType,
    quizCount,
    quizDifficultyLevel,
    openCameraModal,
    showCameraWarning,
    isQuizLoaded,
    quizGenerationCurrentState,
    testingVariation,
  } = useSelector((state) => state.quiz);
  const {
    openUpgradeModel,
    canSaveQuiz,
    canExportToGoogleForm,
    canImportTextFromSystem,
    canUploadPDF,
    canUploadGoogleSheet,
    canUploadExcelSheet,
    canUploadPowerPointSlide,
    canUploadGoogleSlide,
    canUploadWordDoc,
    canUploadGoogleDoc,
    canUploadImage,
    canCaptureImage,
    canExtractDataFromUrl,
    canImportFromGoogleDrive,
    quizContentLimit,
    openPricingModal,
    openPaymentModal,
    openSubscriptionModal,
  } = useSelector((state) => state.feature);
  const [initialQuizParagrapgh, setInitialQuizParagrapgh] = useState("");
  const [quizText, setQuizText] = useState(quizInputText);
  const [learningHubConnected, setLearningHubConnected] = useState(false);
  const [driveMenu, setDriveMenu] = useState(false);
  const [importMenu, setImportMenu] = useState(false);
  const [snackBarstate, setSnackBar] = useState({
    openSnackBar: false,
    vertical: "top",
    horizontal: "right",
    type: "success",
    message: "",
  });
  const [showActionBtns, setShowActionBtns] = useState(false);
  const [openExportModal, setOpenExportModal] = useState(false);
  const [isViewForwardBtn, setIsViewForwardBtn] = useState(false);
  const [exportFile, setExportFile] = useState({
    name: "",
    prety: false,
  });
  const [openReviewModal, setOpenReviewModal] = useState(false);
  const [openGoogleFormsModal, setOpenGoogleFormsModal] = useState(false);
  const [review, setReview] = useState({
    rating: 0,
    email: "",
    comment: "",
  });
  const [userEmail, setUserEmail] = useState({ email: "" });
  const [isKeyboardOpen, setIsKeyboardOpen] = useState(false);
  const [openWarningModal, setOpenWarningModal] = useState(false);
  const [openNewFileImportModal, setOpenNewFileImportModal] = useState(false);
  const [openNewSampleText, setOpenNewSampleTextImportModal] = useState(false);
  const [openConnectionModal, setIsOpenConnectionModal] = useState(false);
  const [openClipboardModal, setIsOpenClipboardModal] = useState(false);
  const [openDownloadOptionsModal, setOpenDownloadOptionsModal] =
    useState(false);
  const [errors, setErrors] = useState({
    quizTextError: null,
    emptyQuestionAnswerError: null,
    connection: null,
    quizTextWarning: null,
    lengthExportFileNameError: null,
  });
  const [isQuizCreating, setIsQuizCreating] = useState(false);
  const [isQuizLoading, setIsQuizLoading] = useState(false);
  const [exportFileType, setExportFileType] = useState(PDF_EXPORT_FILE_TYPE);
  // const [quizData, setQuizData] = useState({});
  const [showQuizExportBox, setShowQuizExportBox] = useState(
    !!quizData?.questionAndAnswers?.find((element) => element.isChecked)
  );
  const [invalidParagraphs, setInvalidParagraphs] = useState(false);
  const [isOpenLearninghubPost, setIsOpenLearninghubPost] = useState(false);
  const [isOpenMaxWOrdsWarningModal, setIsOpenMaxWOrdsWarningModal] =
    useState(false);
  const { vertical, horizontal, openSnackBar } = snackBarstate;
  const [openSampleTextMobileModal, setOpenSampleTextMobileModal] =
    useState(false);
  const [mainBoxHeight, setMainBoxHeight] = useState("");
  const location = useLocation();
  const [openWelcomemodal, setOpenwelcomemodal] = useState(false);
  const isQuizPage = location.pathname === "/";
  const [exportFileNameError, setExportFileNameError] = useState(false);
  const createQuizBtnRef = useRef(null);
  const [showMainDiv, setShowMainDiv] = useState(false);
  const {
    userState,
    isSignedIn,
    userData,
    isSkipped,
    isShowWelcomeModal,
    stripeStatus,
    openExpiryModal,
    isVerifyPage,
    verified,
  } = useSelector((state) => state.logIn);
  const { price, plan, duration } = useSelector((state) => state.payment);
  const [openLoginAlertBox, setIsOpenLoginAlertBox] = useState(false);
  const [isGoogleForm, setIsGoogleForm] = useState(false);
  const [isGoogleDrive, setIsGoogleDrive] = useState(false);
  const [userNameData, setUserNameData] = useState({
    avatar: "--",
    name: " ",
  });

  const isAboutPage = location.pathname === "/about";
  const isLoginPage = location.pathname === "/login";
  const isSignUpPage =
    location.pathname === "/signup" || location.pathname === "/signup-email";
  const [accountStatus, setAccountStatus] = useState({
    title: "",
    desc: "",
    purpose: "",
  });
  const [openTextfieldModal, setOpenTextfieldModal] = useState(false);
  const [openAlertBox, setOpenAlertBox] = useState(false);
  const { enableEditMode, navigateToQuizPage, enableShareMode, openLibrary } =
    useSelector((state) => state.library);
  const [openExistingQuizWarningModal, setOpenExistingQuizWarningModal] =
    useState(false);
  const [openSaveModal, setOpenSaveModal] = useState(false);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const mobileSentenceBoxRef = useRef(null);
  const [openFileErrorAlertBox, setOpenFileErrorAlertBox] = useState(false);
  const [msg, setMsg] = useState({});
  const [isLibraryBtn, setIsLibraryBtn] = useState(false);
  const [loader, setLoader] = useState(false);
  const [openPageSelectionModal, setIsOpenPageSelectionModal] = useState(false);
  const [fileData, setFileData] = useState({
    name: "",
    fileTotalPages: 0,
    filePath: "",
  });
  const [textfieldData, setTextfieldData] = useState({
    title: "",
    label: "",
    placeholder: "",
    btn1Text: "",
    btn2Text: "",
    emptyErrorMsg: "",
    lengthErrorMsg: "",
    invalidErrorMsg: "",
  });

  const [openUserInfoModal, setOpenUserInfoModal] = useState(false);
  const [openTrialModal, setOpenTrialModal] = useState(false);
  const [isWebUrl, setIsWebUrl] = useState(false);
  const [openNewWebUrlImportModal, setIsOpenNewWebUrlImportModal] =
    useState(false);
  const [disableCursor, setIsDisableCursor] = useState(false);
  const [disableCreateBtn, setDisableCreateBtn] = useState(true);
  const [trialModalText, setTrialModalText] = useState({
    heading: "",
    desc: "",
  });
  const [loadContent, setIsContentLoaded] = useState(false);
  const [clipboardFlag, setClipboardFlag] = useState(false);

  const closeOpenUserInfoModal = () => {
    setOpenUserInfoModal(false);
  };

  const showOpenUserInfoModal = () => {
    setOpenUserInfoModal(true);
  };

  useEffect(() => {
    sessionStorage.setItem("google-form", checkUrlIsGoogleForm());
    sessionStorage.setItem("google-drive", checkUrlIsGoogleDrive());
    sessionStorage.setItem("save-quiz", checkUrlIsSaveQuiz());
  }, []);

  useEffect(() => {
    if ((isTl7 || isT7) && getToken() === null) {
      dispatch(setIsLoading(true));
    }
    const timer = setTimeout(() => {
      dispatch(setIsLoading(false));
    }, 1000);
    return () => {
      clearTimeout(timer);
    };
  }, []);

  useEffect(() => {
    dispatch(setNavigateToQuizPage(false));
  }, [navigateToQuizPage]);

  useEffect(() => {
    if (quizGenerationCurrentState === 0 && isQuizLoaded === false) {
      setIsQuizLoading(false);
      if (createQuizBtnRef.current) {
        createQuizBtnRef.current.style.backgroundColor = "#7DC242";
        createQuizBtnRef.current.style.color = "white";
      }
    }
  }, [quizGenerationCurrentState, isQuizLoaded]);

  useEffect(() => {
    if (userState === USER_STATE.BLOCKED_USER_USER) {
      setOpenGoogleFormsModal(false);
      setOpenTextfieldModal(false);
      closeOpenUserInfoModal();
      setDriveMenu(false);
      setOpenSaveModal(false);
      setAccountStatus({
        title: intlMessages.accountBlockedHeading,
        desc: intlMessages.accountBlockedDesc,
        purpose: intlMessages.feedbackBlockedUserFlag,
      });
      setIsOpenLoginAlertBox(true);
    }
    if (userState === USER_STATE.EXPIRED_USER) {
      setOpenGoogleFormsModal(false);
      setOpenTextfieldModal(false);
      closeOpenUserInfoModal();
      setOpenSaveModal(false);
      setDriveMenu(false);
      dispatch(setOpenExpiryModal(true));
    }
    if (userState === USER_STATE.PAYMENT_FAILED) {
      setOpenGoogleFormsModal(false);
      setOpenTextfieldModal(false);
      closeOpenUserInfoModal();
      setOpenSaveModal(false);
      setDriveMenu(false);
      dispatch(setOpenExpiryModal(true));
    }
    if (userState === USER_STATE.SIGNED_IN_USER) {
      setAccountStatus({
        title: "",
        desc: "",
        purpose: intlMessages.feedbackActiveUserFlag,
      });
      setIsOpenLoginAlertBox(false);
      dispatch(setOpenExpiryModal(false));
    }
  }, [userState]);

  const getScrybeParams=() => {
    const { search } = location;
    const queryParams = new URLSearchParams(search);
    if (
      queryParams.get("id") &&
      userState !== USER_STATE.BLOCKED_USER_USER &&
      userState !== USER_STATE.EXPIRED_USER
    ) {
      readClipboard(queryParams.get("id"));
    }
  }

  const getQueryString = () => {
    const { search } = location;
    const queryParams = new URLSearchParams(search);
    // if (
    //   queryParams.get("id") &&
    //   userState !== USER_STATE.BLOCKED_USER_USER &&
    //   userState !== USER_STATE.EXPIRED_USER &&
    //   scrybeFlag === true
    // ) {
    //   readClipboard(queryParams.get("id"));
    // }
    if (
      queryParams.get("quizState") &&
      userState !== USER_STATE.BLOCKED_USER_USER &&
      userState !== USER_STATE.EXPIRED_USER
    ) {
      const text = localStorage.getItem("quizContent");
      const quizData = JSON.parse(localStorage.getItem("quizData"));
      if (!!text) dispatch(setQuizInputText(text));
      if (quizData !== null) {
        dispatch(setQuizData(quizData));
        setIsSelectedAllQuestions(true);
        dispatch(setAllQuestionsSelected(true));
        dispatch(setShowQuizContentPage(true));
      }
      searchParams.delete("quizState");
      navigate(`?${searchParams.toString()}`);
      setTimeout(() => {
        localStorage.removeItem("quizContent");
        localStorage.removeItem("quizData");
      }, 3000);
    }
    if (
      queryParams.get("showStripeSuccess") === "true" &&
      userState !== USER_STATE.BLOCKED_USER_USER &&
      userState !== USER_STATE.EXPIRED_USER
    ) {
      setTrialModalText({
        heading: intlMessages.proSubscriptionConfirmationText1,
        desc: intlMessages.proSubscriptionConfirmationText2,
      });
      setOpenTrialModal(true);
    }
    if (queryParams.get("v") === "1") {
      dispatch(setIsVerified(true));
      localStorage.setItem("isWelcomeModalShown", "true");
    } else {
      dispatch(setIsVerified(false));
    }
    if (
      sessionStorage.getItem("save-quiz") === "true" &&
      userState !== USER_STATE.BLOCKED_USER_USER &&
      userState !== USER_STATE.EXPIRED_USER
    ) {
      if (getToken() !== null) {
        setTextfieldData({
          title: intlMessages.saveModalHeader,
          label: intlMessages.saveModalTextfieldLabel,
          placeholder: intlMessages.saveModalTextfieldPlaceholder,
          btn1Text: intlMessages.saveModalConfirmBtnText,
          btn2Text: intlMessages.cancelLabel,
          emptyErrorMsg: intlMessages.saveModalEmptyTextfieldError,
          lengthErrorMsg: intlMessages.lengthNameError,
          invalidErrorMsg: intlMessages.saveModalInvalidTextfieldError,
          pasteURL: false,
        });
        setOpenTextfieldModal(true);
      } else {
        searchParams.delete("save-quiz");
        sessionStorage.removeItem("save-quiz");
        navigate(`?${searchParams.toString()}`);
        setOpenTextfieldModal(false);
        setOpenSaveModal(true);
      }
    }
    if (
      sessionStorage.getItem("google-form") === "true" &&
      userState !== USER_STATE.BLOCKED_USER_USER &&
      userState !== USER_STATE.EXPIRED_USER
    ) {
      setOpenGoogleFormsModal(true);
    } else if (
      sessionStorage.getItem("google-drive") === "true" &&
      userState !== USER_STATE.BLOCKED_USER_USER &&
      userState !== USER_STATE.EXPIRED_USER
    ) {
      setDriveMenu(true);
    } else {
      setOpenGoogleFormsModal(false);
    }

    if (!!userData) {
      const userNameDataObject = getFirstCharacters(
        userData.firstName,
        userData.lastName
      );
      setUserNameData({
        ...userNameData,
        avatar: userNameDataObject.avatar,
        name: userNameDataObject.name,
      });
    }
  };

  useEffect(() => {
    setIsGoogleForm(checkUrlIsGoogleForm());
    setIsGoogleDrive(checkUrlIsGoogleDrive());
    setOpenTextfieldModal(checkUrlIsSaveQuiz());
    getQueryString();
  }, [userState]);

  useEffect(() => {
    if (userData) {
      const userNameDataObject = getFirstCharacters(
        userData.firstName,
        userData.lastName
      );
      setUserNameData({
        ...userNameData,
        avatar: userNameDataObject.avatar,
        name: userNameDataObject.name,
      });
    }
  }, [userData]);

  useEffect(() => {
    getQueryString();
    getScrybeParams()
  }, []);


  useEffect(() => {
    if (
      localStorage.getItem("isWelcomeModalShown") === null &&
      isVerifyPage === true
    ) {
      dispatch(setIsVerifyPage(false));
      dispatch(setIsShowWelcomeModal(true));
    }
    if (
      isSkipped === 0 &&
      sessionStorage.getItem("save-quiz") === "false" &&
      sessionStorage.getItem("google-form") === "false" &&
      sessionStorage.getItem("google-drive") === "false"
    ) {
      showOpenUserInfoModal();
    }
  }, [isSkipped]);

  useEffect(() => {
    if (stripeStatus === -1) {
      setTrialModalText({
        heading: intlMessages.Afreetrialfortheproplanhasbeenawardedtoyou,
        desc: intlMessages.trialInfoText.replace("#", userData?.trialDays),
      });
      setOpenTrialModal(true);
      return;
    }
    if (stripeStatus === -12) {
      setTrialModalText({
        desc: intlMessages.accountDowngradedText,
      });
      setOpenTrialModal(true);
      return;
    }
  }, [stripeStatus, isSkipped]);

  function scrollToTop() {
    window.scrollTo(0, document.body.scrollHeight);
  }

  useEffect(() => {
    if (quizData.questionAndAnswers.length !== 0) {
      setIsQuizCreating(true);
    }
    handleOrientationChange();
    window.addEventListener("orientationchange", handleOrientationChange_);
    window.addEventListener("resize", handleOrientationChange);
    return () => {
      window.removeEventListener("orientationchange", handleOrientationChange_);
      window.removeEventListener("resize", handleOrientationChange);
    };
  }, [isLoading, windowSize]);

  useEffect(() => {
    if (isAllQuestionsSelected == true) {
      setIsSelectedAllQuestions(true);
    } else {
      setIsSelectedAllQuestions(false);
    }
  }, [isAllQuestionsSelected]);

  useEffect(() => {
    if (quizData.questionAndAnswers.length > 0) {
      setIsQuizCreating(true);
    }
  }, [quizData]);

  useEffect(() => {
    if (consentBtnClicked == false && getToken() === null) {
      setOpenwelcomemodal(true);
      dispatch(setShowWelcomeModal(true));
    }
  }, []);

  useEffect(() => {
    setShowMainDiv(true);
  }, []);

  async function handleOrientationChange() {
    if (
      window.innerWidth < window.innerHeight &&
      openSubscriptionModal === true &&
      (isMobile || isMobileLandscape)
    ) {
      dispatch(setOpenSubscriptionModal(false));
      setTimeout(() => {
        dispatch(setOpenSubscriptionModal(true));
      }, 700);
    }
    const screenStats = window.screen;
    setMainBoxHeight(0);
    let mainBoxHeight_;
    switch (true) {
      case (navigator.standalone === "boolean" ||
        navigator.userAgent.match(/iPhone/i) ||
        navigator.userAgent.match(/iPod/i)) &&
        iOS() &&
        window.matchMedia(
          "screen and (max-device-height: 900px) and (orientation: landscape)"
        ).matches:
        mainBoxHeight_ = 160;
        break;
      case window.matchMedia(
        "(min-width: 800px) and (max-width: 1400px) and (orientation: portrait) and (hover: none) and (pointer: coarse)"
      ).matches:
        mainBoxHeight_ = screenStats.availHeight * 0.74; // iPad Portrait
        break;
      case screenStats.availHeight > 600 &&
        screenStats.availHeight <= 700 &&
        window.matchMedia("(pointer: coarse)").matches:
        mainBoxHeight_ = screenStats.availHeight; // Small mobiles
        break;
      case screenStats.availHeight > 650 && screenStats.availHeight <= 700:
        mainBoxHeight_ = screenStats.availHeight - 260;
        break;
      case screenStats.availHeight > 700 &&
        screenStats.availHeight < 750 &&
        window.matchMedia("(pointer: fine)").matches &&
        window.screen.width < 1100:
        mainBoxHeight_ = screenStats.availHeight - 275; // Mobile landscape
        break;
      case screenStats.availWidth > 750 &&
        screenStats.availWidth <= 960 &&
        screenStats.availHeight < 930: //mobile Landscape
        if (window.innerHeight > 350) {
          mainBoxHeight_ = screenStats.availHeight - 200;
        } else {
          mainBoxHeight_ = screenStats.availHeight - 250;
        }
        break;
      case screenStats.availHeight > 800 &&
        window.matchMedia("(orientation: portrait)").matches &&
        window.matchMedia("(pointer: coarse)").matches:
        mainBoxHeight_ = screenStats.availHeight - 450; // iPad
        break;
      case screenStats.availHeight === 720 && screenStats.availWidth === 1280:
        //  mainBoxHeight_ = screenStats.availHeight - 270; // TL
        var headerHeight = document.getElementById("header").offsetHeight;
        var footerHeight = document.getElementById("footer")?.offsetHeight;
        var headerFooterSum = headerHeight + footerHeight;
        var fullPageHeight = window.innerHeight;
        var contentHeight = fullPageHeight - headerFooterSum;
        mainBoxHeight_ = contentHeight; //TL

        break;
      case screenStats.availWidth >= 1920 && screenStats.height > 1000:
        var headerHeight = document.getElementById("header").offsetHeight;
        var footerHeight = document.getElementById("footer")?.offsetHeight;
        var headerFooterSum = headerHeight + footerHeight;
        var fullPageHeight = window.innerHeight;
        var contentHeight = fullPageHeight - headerFooterSum;
        mainBoxHeight_ = contentHeight; // Desktop
        break;
      case window.matchMedia(
        "(min-width: 800px) and (max-width: 1400px) and (orientation: landscape) and (hover: none) and (pointer: coarse)"
      ).matches:
        //800 //1080
        mainBoxHeight_ = screenStats.availHeight - 535; // iPad Landscape
        if (mainBoxHeight_ < 400) mainBoxHeight_ = 480;
        break;
      case screenStats.height > 800:
        var headerHeight = document.getElementById("header").offsetHeight;
        var footerHeight = document.getElementById("footer")?.offsetHeight;
        var headerFooterSum = headerHeight + footerHeight;
        var fullPageHeight = window.innerHeight;
        var contentHeight = fullPageHeight - headerFooterSum;
        mainBoxHeight_ = contentHeight; // Desktop
        break;
      default:
        mainBoxHeight_ = screenStats.availHeight - 260;
        if (mainBoxHeight_ < 200) mainBoxHeight_ = 173;
        break;
    }
    const height = window.innerHeight - 200;
    var viewport = document.querySelector("meta[name=viewport]");
    // viewport.setAttribute("content", viewport.content + ", height=" + window.innerHeight);
    isMobileDevice() &&
    !iOS() &&
    (window.screen.orientation.type === "landscape-primary" ||
      window.screen.orientation.type === "landscape-secondary")
      ? viewport.setAttribute(
          "content",
          viewport.content + ", height=" + mainBoxHeight_
        )
      : viewport.setAttribute(
          "content",
          viewport.content + ", height=" + height
        );
    setMainBoxHeight(mainBoxHeight_);
  }

  async function handleOrientationChange_() {
    if (
      (isMobile || isMobileLandscape) &&
      (openLibrary === true || openSubscriptionModal === true)
    )
      return;
    if (isIpad) return;
    setTimeout(function () {
      scrollToTop();
    }, 100);
    try {
      document.getElementById("aboutheaderbtn").style.color = "white";
      document.getElementById("quizheaderbtn").style.color = "#7DC243";
      document.getElementById("quizheaderbtn").style.cursor = "default";
      document.getElementById("aboutheaderbtn").style.cursor = "pointer";
    } catch (err) {
      // // console.log(err)
    }
  }

  async function question_check_click(value) {
    logEvent(
      Categories.export_quiz,
      !value ? Events.question_checked : Events.question_unchecked
    );
  }
  async function onClickSelectAl() {
    logEvent(
      Categories.export_quiz,
      !isSelectedAllQuestions
        ? Events.select_all_checked
        : Events.select_all_unchecked
    );
  }
  const [openNameModal, setOpenNameModal] = useState(false);
  const [commentAfterPdf, setCommentAfterPdf] = useState(false);
  const [openSettingsModal, setopenSettingsModal] = useState(false);
  const [openShareModal, setopenShareModal] = useState(false);

  const optionsLabels = [
    intlMessages.aLabel,
    intlMessages.bLabel,
    intlMessages.cLabel,
    intlMessages.dLabel,
    intlMessages.eLabel,
  ];
  const [isSelectedAllQuestions, setIsSelectedAllQuestions] = useState(true);
  const [openDropDown, setDropDownOpen] = useState(false);
  const [sampleTextList, setSampleTextList] = useState([]);
  const [openExportFileModal, setIsOpenExportFileModal] = useState(false);

  const handleDropDownOpen = () => {
    if (quizText.trim().length > 0)
      return setOpenNewSampleTextImportModal(true);
    setDropDownOpen(true);
    logEvent(
      Categories.content_for_quiz,
      Events.import_sample_text_button,
      testingVariation === 0
        ? Categories.value_color_blue
        : Categories.value_color_green,
      testingVariation === 0 ? "blue" : "green"
    );
  };

  const handleMobileDropDownOpen = () => {
    if (quizText.trim().length > 0)
      return setOpenNewSampleTextImportModal(true);
    setOpenSampleTextMobileModal(true);
    dispatch(setShowSampleTextModal(true));
    logEvent(
      Categories.content_for_quiz,
      Events.import_sample_text_button,
      testingVariation === 0
        ? Categories.value_color_blue
        : Categories.value_color_green,
      testingVariation === 0 ? "blue" : "green"
    );
  };

  const handleDropDownClose = () => {
    setOpenNewSampleTextImportModal(false);
    setDropDownOpen(false);
  };

  const openImportFileModal = () => {
    logEvent(
      Categories.quiz_from_content,
      Events.upload_file_icon_btn_clicked,
      testingVariation === 0
        ? Categories.value_color_blue
        : Categories.value_color_green,
      testingVariation === 0 ? "blue" : "green"
    );
    setDriveMenu(true);
  };

  const closeImportFileModal = () => {
    setDriveMenu(false);
  };

  useEffect(() => {
    const handleCancel = () => {
      setIsUpload(false);
    };
    if (!!inputFile.current) {
      inputFile.current.addEventListener("cancel", handleCancel);
    }
  }, [inputFile.current]);

  const selectedTypeCallback = (selectedType) => {
    // if (selectedType === "drive") {
    //   console.log(canImportFromGoogleDrive)
    //   if(canImportFromGoogleDrive.enable===true){
    //   setDriveMenu(true);
    //   }
    //   else{
    //     dispatch(setUpgradeModalText({
    //       heading:intlMessages.monthlyLimitText,
    //       desc:intlMessages.monthlyLimitDesc
    //     }))
    //     dispatch(setOpenUpgradeModel(true))
    //   }
    // }
    if (selectedType === "upload") {
      renderFileInputBox("file-upload-btn");
      inputFile.current.click();
    } else if (selectedType === "camera") {
      if (canCaptureImage.enable === true) {
        dispatch(setOpenCameraModal(true));
      } else {
        dispatch(
          setUpgradeModalText({
            heading: intlMessages.upgradeModelHeading,
            desc: intlMessages.upgradeModelDescText,
          })
        );
        dispatch(setOpenUpgradeModel(true));
      }
    }
    closeImportFileModal();
  };

  const handleOpenWebURLModal = () => {
    if (canExtractDataFromUrl.enable === false) {
      dispatch(
        setUpgradeModalText({
          heading: intlMessages.upgradeModelHeading,
          desc: intlMessages.upgradeModelDescText,
        })
      );
      dispatch(setOpenUpgradeModel(true));
      return;
    }
    setTextfieldData({
      title: intlMessages.pasteURLModalHeader,
      label: intlMessages.pasteURLModalTitle,
      placeholder: intlMessages.pasteURLModalPlaceholder,
      btn1Text: intlMessages.saveModalConfirmBtnText,
      btn2Text: intlMessages.cancelLabel,
      emptyErrorMsg: intlMessages.pasteURLModalEmptyTextfieldError,
      lengthErrorMsg: "",
      invalidErrorMsg: intlMessages.pasteURLModalInvalidTextfieldError,
      pasteURL: true,
    });
    setOpenTextfieldModal(true);
    logEvent(
      Categories.content_for_quiz,
      Events.paste_web_url_button_clicked,
      testingVariation === 0
        ? Categories.value_color_blue
        : Categories.value_color_green,
      testingVariation === 0 ? "blue" : "green"
    );
  };

  useEffect(() => {
    handleImportSampleText().then((data) => setSampleTextList(data));
  }, []);

  useEffect(() => {
    // handling here for api calling in case of tab close
    logEvent(Categories.page_view, Events.main_screen);
    sendPageView();
  }, []);

  const handleKeyboardOpenClose = (inpText) => {
    if (iOS()) return false;
    const MIN_KEYBOARD_HEIGHT = 300;
    const isOpen =
      isMobile &&
      window.screen.height - MIN_KEYBOARD_HEIGHT >
        window.visualViewport.height &&
      inpText.length > 0;
    if (isOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
    setIsKeyboardOpen(isOpen);

    setTimeout(function () {
      handleOrientationChange();
    });
  };

  useEffect(() => {
    if (!(isMobile || isMobileLandscape || isIpad)) {
      document.getElementById("aboutheaderbtn").style.color = "white";
      document.getElementById("quizheaderbtn").style.color = "#7DC243";
      document.getElementById("quizheaderbtn").style.cursor = "default";
      document.getElementById("aboutheaderbtn").style.cursor = "pointer";
    }
  }, []);
  useEffect(() => {
    onChangeQuizText(quizInputText);
    if (quizInputText.trim().length === 0) {
      const field = document.getElementById("quiz-input-field");
      field && field.focus();
    }
  }, [quizInputText]);

  useEffect(() => {
    isMobile && handleKeyboardOpenClose(quizText);
  }, [windowSize]);

  useEffect(() => {
    if (isMobile || isMobileLandscape) {
      dispatch(setquizLandscapeBackBtnClicked(false));
      // setIsQuizCreating(true);
    }
  }, [quizType, quizCount, quizDifficultyLevel]);

  const onChangeQuizText = (val, isTextImportedFromFile) => {
    if (isTextImportedFromFile === true || disableCursor === true) {
      const field = document.getElementById("quiz-input-field");
      field && field.blur();
      mobileSentenceBoxRef.current?.click();
      setIsDisableCursor(false);
    }
    // validateInput(quizInputText).length !== 0 &&
    //   dispatch(setIsDisableQuizSettings(false));
    if (validateInput(quizInputText).length > 0) {
      if (createQuizBtnRef.current)
        createQuizBtnRef.current.style.backgroundColor = "#7DC242";
      setDisableCreateBtn(false);
    }
    const inputText = validateInput(val);
    isMobile && handleKeyboardOpenClose(inputText);
    if (quizText?.trim().length > 0 && inputText?.trim().length === 0) {
      return clearQuizText();
    }
    if (
      isQuizCreating &&
      !isTextImportedFromFile &&
      !isStringsEqual(inputText.trim(), initialQuizParagrapgh.trim())
    ) {
      if (enableEditMode === false && isTextImportedFromFile === false) {
        setErrors({
          ...errors,
          quizTextWarning: intlMessages.changedQuizTextWarning,
        });
      }
      dispatch(setquizLandscapeBackBtnClicked(false));
      setIsViewForwardBtn(false);
    }
    setQuizText(inputText);
    dispatch(setQuizInputText(inputText));
    clearErrorsIfAny(inputText);
  };
  const learningHubConnectedCallBack = (value) => {
    setLearningHubConnected(value);
  };
  const showErrorMessage = (msgText) => {
    return setSnackBar({
      ...snackBarstate,
      type: "error",
      message: msgText,
      openSnackBar: true,
    });
  };

  const showWarningMessage = (msgText) => {
    return setSnackBar({
      ...snackBarstate,
      type: "warning",
      message: msgText,
      openSnackBar: true,
    });
  };

  const handleDownloadFile = (fileType) => {
    const userData = JSON.parse(window.localStorage.getItem("userData"));
    const checkedQuestions = quizData.questionAndAnswers.filter(
      (item) => item.isChecked === true
    );
    if (fileType === "xml") {
      logEvent(Categories.content_for_quiz, Events.download_xml_btn_clicked);
      return exportXML(
        userData?.email,
        checkedQuestions,
        quizData.quiz_id,
        fileType,
        quizData.quiz_hash,
        quizType === intlMessages.quizTrueFalseType ? "truefalse" : quizType,
        true,
        true,
        quizData?.exportData?.id,
        quizData?.exportData?.feedbackId
      )
        .then((res) => {
          if (!res) {
            setErrors({ ...errors, connection: intlMessages.invalidError });
            return showErrorMessage(intlMessages.invalidError);
          }
          if (res.data.statusCode === 0) {
            return handleOpenConnectionModal();
          }
          if (res?.data && res.data.statusCode !== 201 && res.data.message) {
            setErrors({ ...errors, connection: res.data.message });
            return showErrorMessage(res.data.message);
          }
          setErrors({ ...errors, connection: null });
          const blob = new Blob([res.data]);
          const url = window.URL.createObjectURL(blob);
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute(
            "download",
            `${getExportFileName()}-xml.${fileType}`
          );
          document.body.appendChild(link);
          link.click();
          link.parentNode.removeChild(link);
        })
        .catch((error) => {});
    }
    if (fileType === "text") {
      logEvent(Categories.content_for_quiz, Events.download_aiken_btn_clicked);
      return exportAiken(
        userData?.email,
        checkedQuestions,
        quizData.quiz_id,
        fileType,
        quizData.quiz_hash,
        quizType === intlMessages.quizTrueFalseType
          ? "truefalse"
          : quizType === intlMessages.quizMCQType
          ? "multichoice"
          : quizType,
        true,
        true,
        quizData?.exportData?.id,
        quizData?.exportData?.feedbackId
      )
        .then((res) => {
          if (!res) {
            setErrors({ ...errors, connection: intlMessages.invalidError });
            return showErrorMessage(intlMessages.invalidError);
          }
          if (res.data.statusCode === 0) {
            return handleOpenConnectionModal();
          }
          if (res?.data && res.data.statusCode !== 201 && res.data.message) {
            setErrors({ ...errors, connection: res.data.message });
            return showErrorMessage(res.data.message);
          }
          setErrors({ ...errors, connection: null });
          const blob = new Blob([res.data]);
          const url = window.URL.createObjectURL(blob);
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute(
            "download",
            `${getExportFileName()}-aiken.${fileType}`
          );
          document.body.appendChild(link);
          link.click();
          link.parentNode.removeChild(link);
        })
        .catch((error) => {});
    }
    if (fileType === "zip") {
      logEvent(Categories.content_for_quiz, Events.download_qt12_btn_clicked);
      return exportQT12(
        userData?.email,
        checkedQuestions,
        quizData.quiz_id,
        fileType,
        quizData.quiz_hash,
        quizType,
        true,
        true,
        quizData?.exportData?.id,
        quizData?.exportData?.feedbackId
      )
        .then((res) => {
          if (!res) {
            setErrors({ ...errors, connection: intlMessages.invalidError });
            return showErrorMessage(intlMessages.invalidError);
          }
          if (res.data.statusCode === 0) {
            return handleOpenConnectionModal();
          }
          if (res?.data && res.data.statusCode !== 201 && res.data.message) {
            setErrors({ ...errors, connection: res.data.message });
            return showErrorMessage(res.data.message);
          }
          setErrors({ ...errors, connection: null });
          const blob = new Blob([res.data], { type: "application/zip" });
          const url = window.URL.createObjectURL(blob);
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute(
            "download",
            `${getExportFileName()}-QT12.1.${fileType}`
          );
          document.body.appendChild(link);
          link.click();
          link.parentNode.removeChild(link);
        })
        .catch((error) => {});
    }
    if (fileType === "csv") {
      logEvent(
        Categories.content_for_quiz,
        Events.download_google_sheet_clicked
      );
      return exportGoogleSheet(
        userData?.email,
        checkedQuestions,
        quizData.quiz_id,
        fileType,
        quizData.quiz_hash,
        quizType === intlMessages.quizTrueFalseType ? "truefalse" : quizType,
        true,
        true,
        quizData?.exportData?.id,
        quizData?.exportData?.feedbackId
      )
        .then((res) => {
          if (!res) {
            setErrors({ ...errors, connection: intlMessages.invalidError });
            return showErrorMessage(intlMessages.invalidError);
          }
          if (res.data.statusCode === 0) {
            return handleOpenConnectionModal();
          }
          if (res?.data && res.data.statusCode !== 201 && res.data.message) {
            setErrors({ ...errors, connection: res.data.message });
            return showErrorMessage(res.data.message);
          }
          setErrors({ ...errors, connection: null });
          const blob = new Blob([res.data]);
          const url = window.URL.createObjectURL(blob);
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute(
            "download",
            `${getExportFileName()}-googlesheet.${fileType}`
          );
          document.body.appendChild(link);
          link.click();
          link.parentNode.removeChild(link);
        })
        .catch((error) => {});
    }
  };

  const handleOpenReviewModal = () => {
    setOpenReviewModal(true);
  };

  const handleOpenGoogleFormsModal = () => {
    logEvent(
      Categories.export_quiz,
      "google_forms_export_clicked",
      Events.export_event + "_Google_forms"
    );
    setOpenGoogleFormsModal(true);
  };
  const handleOpenNameModal = () => {
    setOpenNameModal(true);
  };

  const handleOpenSettingsModal = () => {
    setopenSettingsModal(true);
  };

  const handleOpenShareModal = () => {
    setopenShareModal(true);
  };

  const handleCommentAfterPdf = (value = false) => {
    setCommentAfterPdf(value);
  };

  const handleOpenClipboardModal = () => {
    setIsOpenClipboardModal(true);
  };

  const handleCloseClipboardModal = () => {
    setIsOpenClipboardModal(false);
    handleOpenReviewModal();
  };

  const handleOpenNewFileImportModal = () => {
    if (quizText.trim().length > 0) return setOpenNewFileImportModal(true);
    renderFileInputBox("file-upload-btn");
    document.getElementById("file-upload-btn").click();
  };

  const handleCloseNewFileImportModal = () => {
    setOpenNewFileImportModal(false);
  };

  const validateExportFileName = (name) => {
    if (name.length === 0) {
      return setErrors({
        ...errors,
        lengthExportFileNameError: intlMessages.emptyExportFileNameError,
      });
    }
    if (name.length > 255) {
      return setErrors({
        ...errors,
        lengthExportFileNameError: intlMessages.lengthExportFileNameError,
      });
    }
    return true;
  };

  const handleOpenDownloadOptionsModal = () => {
    const { name } = exportFile;
    if (validateExportFileName(name)) {
      setOpenExportModal(false);
      setOpenDownloadOptionsModal(true);
      setErrors({
        ...errors,
        lengthExportFileNameError: null,
      });
      setExportFileNameError(false);
    } else setExportFileNameError(true);
  };

  useEffect(() => {
    const handleCtrlZ = (event) => {
      if (openExportModal && event.ctrlKey && event.key === "z") {
        event.preventDefault();
      }
    };
    window.addEventListener("keydown", handleCtrlZ);
    return () => {
      window.removeEventListener("keydown", handleCtrlZ);
    };
  }, [openExportModal]);

  useEffect(() => {
    const handleCtrlZ = (event) => {
      if (openDownloadOptionsModal && event.ctrlKey && event.key === "z") {
        event.preventDefault();
      }
    };
    window.addEventListener("keydown", handleCtrlZ);
    return () => {
      window.removeEventListener("keydown", handleCtrlZ);
    };
  }, [openDownloadOptionsModal]);

  useEffect(() => {
    const handleCtrlZ = (event) => {
      if (openClipboardModal && event.ctrlKey && event.key === "z") {
        event.preventDefault();
      }
    };
    window.addEventListener("keydown", handleCtrlZ);
    return () => {
      window.removeEventListener("keydown", handleCtrlZ);
    };
  }, [openClipboardModal]);

  useEffect(() => {
    const handleCtrlZ = (event) => {
      if (openWarningModal && event.ctrlKey && event.key === "z") {
        event.preventDefault();
      }
    };
    window.addEventListener("keydown", handleCtrlZ);
    return () => {
      window.removeEventListener("keydown", handleCtrlZ);
    };
  }, [openWarningModal]);

  useEffect(() => {
    const handleCtrlZ = (event) => {
      if (openNewSampleText && event.ctrlKey && event.key === "z") {
        event.preventDefault();
      }
    };
    window.addEventListener("keydown", handleCtrlZ);
    return () => {
      window.removeEventListener("keydown", handleCtrlZ);
    };
  }, [openNewSampleText]);

  useEffect(() => {
    const handleCtrlZ = (event) => {
      if (openNewFileImportModal && event.ctrlKey && event.key === "z") {
        event.preventDefault();
      }
    };
    window.addEventListener("keydown", handleCtrlZ);
    return () => {
      window.removeEventListener("keydown", handleCtrlZ);
    };
  }, [openNewFileImportModal]);

  useEffect(() => {
    const handleCtrlZ = (event) => {
      if (openDropDown && event.ctrlKey && event.key === "z") {
        event.preventDefault();
      }
    };
    window.addEventListener("keydown", handleCtrlZ);
    return () => {
      window.removeEventListener("keydown", handleCtrlZ);
    };
  }, [openDropDown]);

  const handleCloseDownloadOptionsModal = (isBackClicked = false) => {
    setOpenDownloadOptionsModal(false);
    if (
      (exportFileType === PDF_EXPORT_FILE_TYPE ||
        exportFileType === TEXT_EXPORT_FILE_TYPE) &&
      !isBackClicked
    ) {
      setCommentAfterPdf(true);
      handleOpenReviewModal();
    }
  };

  const handleCloseExportModal = () => {
    setOpenExportModal(false);
    setErrors({
      ...errors,
      lengthExportFileNameError: null,
    });
    setExportFileNameError(false);
    setExportFile({
      name: "",
      prety: false,
    });
  };

  const closeQuizContentBox = () => {
    try {
      dispatch(setquizLandscapeBackBtnClicked(true));
      // if(isMobileLandscape){
      // setIsQuizLoading(false);
      // dispatch(setQuizData({
      //   questionAndAnswers: [],
      // }))
      // }
    } catch (err) {}
    dispatch(setShowQuizContentPage(false));
    setIsViewForwardBtn(true);
  };

  const handleExportFileCheckbox = () => {
    setExportFile((prevState) => {
      return {
        ...prevState,
        prety: !prevState.prety,
      };
    });
  };

  const handleOpenWarningModal = () => {
    setOpenWarningModal(true);
  };

  const handleOpenMaxWordsWarningModal = () => {
    setIsOpenMaxWOrdsWarningModal(true);
  };

  const handleOpenConnectionModal = () => {
    logEvent(Categories.learning_hub_intgration, Events.learning_hub_clicked);
    setIsOpenConnectionModal(true);
  };
  const isPassedValidationsForCreatingQuiz = () => {
    const wordCount = getWordCount(quizText);
    if (quizText.length === 0) {
      // dispatch(setIsDisableQuizSettings(true));
      const errMsg = intlMessages.emptyTextError;
      return setErrors({ ...errors, quizTextError: errMsg });
    }
    if (wordCount < MIN_QUIZ_WORDS) {
      const errMsg = intlMessages.minQuizTextError.replace(
        "{0}",
        MIN_QUIZ_WORDS
      );
      return setErrors({ ...errors, quizTextError: errMsg });
    }
    return true;
  };

  const stopQuizLoading = () => {
    setIsQuizCreating(false);
    dispatch(setShowQuizContentPage(false));
    dispatch(setIsQuizLoaded(false));
    setIsQuizLoading(false);
  };

  const createQuiz = () => {
    dispatch(setQuizGenerationCurrentState(1));
    dispatch(setIsDisableQuizSettings(true));
    logEvent(Categories.content_for_quiz, Events.genrate_event);
    logEvent(
      Categories.content_for_quiz,
      Events.quiz_content_words,
      getWordCount(quizText)
    );
    sendConversionEvent();
    setErrors({
      ...errors,
      emptyQuestionAnswerError: false,
      quizTextWarning: null,
    });
    setIsQuizCreating(true);
    setIsSelectedAllQuestions(true);
    dispatch(setAllQuestionsSelected(true));
    dispatch(setShowQuizContentPage(true));
    dispatch(setIsQuizLoaded(true));
    setIsQuizLoading(true);
    setShowQuizExportBox(false);
    dispatch(setquizLandscapeBackBtnClicked(false));
    if (createQuizBtnRef.current) {
      createQuizBtnRef.current.style.backgroundColor = "#E0E0E0";
      createQuizBtnRef.current.style.color = "#a6a6bd";
    }
    // setQuizData({
    //   questionAndAnswers: [],
    // });
    dispatch(
      setQuizData({
        questionAndAnswers: [],
      })
    );
    const validatedPara = validatedQuizText(quizText);
    setInitialQuizParagrapgh(validatedPara);
    controller = new AbortController();
    const signal = controller.signal;
    //getting quiz questions and answers from api
    const start = Date.now();
    const quizSettings = {
      quizType:
        quizType === "Fill in the blanks"
          ? "MCQ (Fill in the blanks)"
          : quizType === "True or False"
          ? "TrueFalse"
          : "MCQ",
      noOfQuestions: quizCount === "Auto" ? "Auto" : parseInt(quizCount),
      difficultyLevel:
        quizDifficultyLevel === "Hard"
          ? "HARD"
          : quizDifficultyLevel === "Easy"
          ? "EASY"
          : "MEDIUM",
    };
    ABORT_CONTROLLER_MAP.set("quizGenerationSignal", controller);
    generateQuizFromParagraph(
      validatedPara,
      quizSettings,
      localStorage.getItem("guestId"),
      signal
    )
      .then((res) => {
        const finish = Date.now();
        const time = (finish - start) / 100;
        logEvent(
          Categories.content_for_quiz,
          Events.quiz_genratiion_time_ms,
          time,
          testingVariation === 0 ? "blue" : "green"
        );
        //clearQuizData();
        if (
          res.status === 429 &&
          res.data.message === "Your request exceeded the allowed limit."
        ) {
          stopQuizLoading();
          dispatch(setIsDisableQuizSettings(false));
          if (createQuizBtnRef.current) {
            createQuizBtnRef.current.style.color = "white";
            createQuizBtnRef.current.style.backgroundColor = "#7DC242";
          }
          dispatch(
            setUpgradeModalText({
              heading: intlMessages.monthlyLimitText,
              desc: intlMessages.monthlyLimitDesc,
            })
          );
          dispatch(setOpenUpgradeModel(true));
          return;
        }
        if (!res) {
          stopQuizLoading();
          dispatch(setIsDisableQuizSettings(false));
          createQuizBtnRef.current.style.color = "white";
          createQuizBtnRef.current.style.backgroundColor = "#7DC242";
          return showErrorMessage(intlMessages.invalidError);
        }
        if (res.data.statusCode === 0) {
          stopQuizLoading();
          dispatch(setIsDisableQuizSettings(false));
          createQuizBtnRef.current.style.color = "white";
          createQuizBtnRef.current.style.backgroundColor = "#7DC242";
          return handleOpenConnectionModal();
        }
        if (res?.data && res.data.statusCode !== 201 && res.data.message) {
          stopQuizLoading();
          dispatch(setIsDisableQuizSettings(false));
          createQuizBtnRef.current.style.color = "white";
          createQuizBtnRef.current.style.backgroundColor = "#7DC242";
          return showErrorMessage(res.data.message);
        }
        const resData = res?.data;
        if (resData) {
          try {
            document
              .getElementById("scrollable-quiz-content-box")
              .scrollTo(0, 0);
          } catch (ex) {}
          resData.ques_ans_pairs.map(
            (element) => (element["isChecked"] = true)
          );
          const quesAnsPairs = resData.ques_ans_pairs;
          if (Array.isArray(quesAnsPairs) && quesAnsPairs.length === 0) {
            createQuizBtnRef.current.style.color = "white";
            createQuizBtnRef.current.style.backgroundColor = "#7DC242";
            stopQuizLoading();
            dispatch(setIsDisableQuizSettings(false));
            return showWarningMessage(intlMessages.noQuizGenerated);
          }
          logEvent(
            Categories.quiz_from_content,
            Events.ans_respose_count,
            quesAnsPairs.length
          );
          // setQuizData({
          //   questionAndAnswers: quesAnsPairs,
          //   quiz_id: resData.id,
          //   quiz_hash: resData.hash,
          // });

          // dispatch(setIsQuizLoaded(false))
          // setIsQuizLoading(false);

          // if (createQuizBtnRef.current) {
          //   setTimeout(() => {
          //     if (createQuizBtnRef.current) {
          //       createQuizBtnRef.current.style.color = "white";
          //       createQuizBtnRef.current.style.backgroundColor = "#7DC242";
          //     }
          //   }, 0);
          // }
          dispatch(
            setQuizData({
              questionAndAnswers: quesAnsPairs,
              quiz_id: resData.id,
              quiz_hash: resData.hash,
            })
          );
          if (store.getState().quiz.quizGenerationCurrentState === 1) {
            dispatch(setIsQuizLoaded(false));
            setIsQuizLoading(false);
            dispatch(setQuizGenerationCurrentState(0));
            dispatch(setIsDisableQuizSettings(false));
            createQuizBtnRef.current.style.backgroundColor = "#7DC242";
            createQuizBtnRef.current.style.color = "white";
          }
          if (store.getState().quiz.quizGenerationCurrentState === -1) {
            dispatch(setIsQuizLoaded(true));
            setIsQuizLoading(true);
            dispatch(setIsDisableQuizSettings(true));
            // createQuizBtnRef.current.style.color = "white";
            // createQuizBtnRef.current.style.backgroundColor = "#7DC242";
          }
          setShowQuizExportBox(true);
          let paragraphId = window.localStorage.getItem("paragraphId");
          if (paragraphId) {
            if (paragraphId != resData.id) {
              let data = JSON.parse(window.localStorage.getItem("userData"));
              if (data) {
                data.rating = 0;
                window.localStorage.setItem("userData", JSON.stringify(data));
              }
            }
          }
          window.localStorage.setItem("paragraphId", resData.id);
        }
      })
      .catch((error) => {
        return false;
      });
  };

  const closeReviewModal = () => {
    resetCommentAfterPdf();
    setOpenReviewModal(false);
  };

  const closeGoogleFormsModal = (showFeedback = false) => {
    setOpenGoogleFormsModal(false);
    if (showFeedback) {
      handleOpenReviewModal();
    }
  };

  const closeNameModal = () => {
    setOpenNameModal(false);
  };

  const closeOpenSettingsModal = () => {
    setopenSettingsModal(false);
  };

  const closeOpenShareModal = () => {
    setopenShareModal(false);
  };

  const resetCommentAfterPdf = () => {
    setCommentAfterPdf(false);
  };

  const handleCloseSnackBar = () => {
    setSnackBar({ ...snackBarstate, openSnackBar: false });
  };

  const clearErrorsIfAny = (inputText) => {
    const { quizTextError, quizTextWarning } = errors;
    const wordCount = getWordCount(inputText);
    if (inputText.length > 0 && quizTextError === intlMessages.emptyTextError) {
      return setErrors({ ...errors, quizTextError: null });
    } else if (
      wordCount >= MIN_QUIZ_WORDS &&
      quizTextError ===
        intlMessages.minQuizTextError.replace("{0}", MIN_QUIZ_WORDS)
    ) {
      return setErrors({ ...errors, quizTextError: null });
    } else if (
      wordCount <= quizContentLimit.contentLimit &&
      quizTextError ===
        intlMessages.maxQuizTextError.replace(
          "{0}",
          quizContentLimit.contentLimit
        )
    ) {
      return setErrors({ ...errors, quizTextError: null });
    } else if (
      quizTextWarning === intlMessages.changedQuizTextWarning &&
      isStringsEqual(inputText.trim(), initialQuizParagrapgh.trim())
    ) {
      setErrors({
        ...errors,
        quizTextWarning: null,
      });
      setIsViewForwardBtn(true);
    } else {
      return true;
    }
  };

  const onChangeQuestionTextHandler = debounce((index, inputText) => {
    const questionText = validateInput(inputText);
    const questionAnswers = [...quizData.questionAndAnswers];
    const updatedQuestionAnswers = questionAnswers.map((question, i) => {
      if (index == i) {
        const updatedQuestion = { ...question };
        updatedQuestion.question = questionText;
        return updatedQuestion;
      }
      return question;
    });
    dispatch(
      setQuizData({ ...quizData, questionAndAnswers: updatedQuestionAnswers })
    );
  });

  const onChangeQuestionCheckBox = (index) => {
    const questionAnswers = [...quizData.questionAndAnswers];
    questionAnswers[index] = {
      ...questionAnswers[index],
      isChecked: !questionAnswers[index].isChecked,
    };
    if (!questionAnswers[index].isChecked) {
      setIsSelectedAllQuestions(false);
      dispatch(setAllQuestionsSelected(false));
    } else {
      let selectedQuestions = 0;
      questionAnswers.forEach((element) => {
        if (element.isChecked) {
          selectedQuestions++;
        }
      });
      setIsSelectedAllQuestions(questionAnswers.length === selectedQuestions);
      dispatch(
        setAllQuestionsSelected(questionAnswers.length === selectedQuestions)
      );
    }
    const isAnyBoxChecked = questionAnswers.find(
      (element) => element.isChecked
    );
    dispatch(setQuizData({ ...quizData, questionAndAnswers: questionAnswers }));
    setShowQuizExportBox(!!isAnyBoxChecked);
  };

  useEffect(() => {
    if (isAllQuestionsSelected == true) {
      const questionAnswers = quizData.questionAndAnswers.map((item) => ({
        ...item,
        isChecked: true,
      }));
      dispatch(
        setQuizData({
          ...quizData,
          questionAndAnswers: [...questionAnswers],
        })
      );
    }
  }, [checkAllQuestionsDrive]);

  const onChangeSelectAllQuestions = (e) => {
    const isChecked = e.target.checked;
    const questionAnswers = quizData.questionAndAnswers.map((item) => ({
      ...item,
      isChecked: isChecked,
    }));
    const isAnyBoxChecked = questionAnswers.find(
      (question) => question.isChecked
    );
    dispatch(
      setQuizData({
        ...quizData,
        questionAndAnswers: [...questionAnswers],
      })
    );
    setIsSelectedAllQuestions(isChecked);
    dispatch(setAllQuestionsSelected(isChecked));
    setShowQuizExportBox(!!isAnyBoxChecked);
    // // console.log(quizData)
  };

  const handleOpenDriveFileImportModal = () => {
    setDriveMenu(true);
  };

  const closeDriveFileImportModal = () => {
    setDriveMenu(false);
  };

  const backToMainMenu = () => {
    closeEmptyAlert();
    openImportFileModal();
  };

  const openEmptyAlert = () => {
    setOpenFileErrorAlertBox(true);
  };

  const closeEmptyAlert = () => {
    setOpenFileErrorAlertBox(false);
  };

  const handleExportMenuItemSelection = (selectedItem) => {
    if (selectedItem === intlMessages.clipboard) {
      handleExportFile(CLIPBOARD_EXPORT_TYPE);
      // handleCopyClipboard();
    }
    if (selectedItem === intlMessages.saveLabel) {
      if (canSaveQuiz.enable === true) {
        if (!isQuestionAndAnswersAreValid(quizData.questionAndAnswers)) {
          return handleQuestionAnswersValidation();
        }
        setErrors({ ...errors, emptyQuestionAnswerError: false });
        if (getToken() === null) {
          return setOpenSaveModal(true);
        }
        setTextfieldData({
          title: intlMessages.saveModalHeader,
          label: intlMessages.saveModalTextfieldLabel,
          placeholder: intlMessages.saveModalTextfieldPlaceholder,
          btn1Text: intlMessages.saveModalConfirmBtnText,
          btn2Text: intlMessages.cancelLabel,
          emptyErrorMsg: intlMessages.saveModalEmptyTextfieldError,
          lengthErrorMsg: intlMessages.lengthNameError,
          invalidErrorMsg: intlMessages.saveModalInvalidTextfieldError,
          pasteURL: false,
        });
        setOpenTextfieldModal(true);
      } else {
        dispatch(
          setUpgradeModalText({
            heading: intlMessages.upgradeModelHeading,
            desc: intlMessages.upgradeModelDescText,
          })
        );
        dispatch(setOpenUpgradeModel(true));
      }
    }
    if (selectedItem === intlMessages.pdf) {
      handleExportFile(PDF_EXPORT_FILE_TYPE);
    }
    if (selectedItem === intlMessages.textFile) {
      handleExportFile(TEXT_EXPORT_FILE_TYPE);
    }
    if (selectedItem === intlMessages.exportFileLabel) {
      if (canSaveQuiz.enable === true) {
        if (!isQuestionAndAnswersAreValid(quizData.questionAndAnswers)) {
          return handleQuestionAnswersValidation();
        }
        setErrors({ ...errors, emptyQuestionAnswerError: false });
        handleExportFile(intlMessages.exportFileLabel);
        logEvent(
          Categories.content_for_quiz,
          Events.integrate_file_btn_clicked
        );
      } else {
        dispatch(
          setUpgradeModalText({
            heading: intlMessages.upgradeModelHeading,
            desc: intlMessages.upgradeModelDescText,
          })
        );
        dispatch(setOpenUpgradeModel(true));
      }
    } else if (selectedItem === intlMessages.googleFormlabes) {
      if (canExportToGoogleForm.enable === true) {
        if (!isQuestionAndAnswersAreValid(quizData.questionAndAnswers)) {
          return handleQuestionAnswersValidation();
        }
        handleOpenGoogleFormsModal();
      } else {
        dispatch(
          setUpgradeModalText({
            heading: intlMessages.upgradeModelHeading,
            desc: intlMessages.upgradeModelDescText,
          })
        );
        dispatch(setOpenUpgradeModel(true));
      }
    }
    // if(selectedItem === intlMessages.learningHubLabel){
    //   setIsOpenLearninghubPost(true);
    // }
  };

  const handleImportMenuItemSelection = (selectedItem) => {
    selectedItem = selectedItem.trim();
    if (selectedItem.trim() == intlMessages.uploadFileLabel) {
      // handleOpenGoogleFormsModal();
      renderFileInputBox("file-upload-btn");
      inputFile.current.click();
    } else if (selectedItem === intlMessages.quizImportSampleTextLabel) {
      handleDropDownOpen();
    } else if (selectedItem === intlMessages.questionsettings) {
      handleOpenSettingsModal();
    } else if (selectedItem === intlMessages.pasteURLModalHeader) {
      if (canExtractDataFromUrl.enable === true) {
        if (quizText.trim().length > 0)
          return setIsOpenNewWebUrlImportModal(true);
        handleOpenWebURLModal();
      }
      dispatch(
        setUpgradeModalText({
          heading: intlMessages.upgradeModelHeading,
          desc: intlMessages.upgradeModelDescText,
        })
      );
      dispatch(setOpenUpgradeModel(true));
      return;
    } else if (selectedItem === intlMessages.importModalHeadercapitalsmall) {
      if (quizText.trim().length > 0 && !openNewFileImportModal) {
        handleOpenNewFileImportModal();
        logEvent(
          Categories.content_for_quiz,
          Events.impiort_file_button_clicked
        );
      } else {
        openImportFileModal();
      }
    }
  };

  const onChangeQuestionAnswersHandler = debounce(
    (questionIndex, answerIndex, e) => {
      const inputText = validateInput(e);
      const questionAnswers = [...quizData.questionAndAnswers];
      const updatedQuestionAnswers = questionAnswers.map((question, i) => {
        if (i == questionIndex) {
          const updatedQuestion = { ...question };
          const answersList = [...updatedQuestion.answers];
          const updatedAnswer = { ...updatedQuestion.answers[answerIndex] };
          if (updatedAnswer.isNew) {
            updatedAnswer.isNew = false;
          }
          updatedAnswer.value = inputText;
          updatedAnswer.isChanged = true;
          answersList[answerIndex] = updatedAnswer;
          updatedQuestion.answers = answersList;
          return updatedQuestion;
        }
        return question;
      });
      dispatch(
        setQuizData({ ...quizData, questionAndAnswers: updatedQuestionAnswers })
      );
    }
  );

  const changeCorrectAnswer = (index, ansIndex) => {
    const questionAnswers = [...quizData.questionAndAnswers];
    const updatedQuestionAnswers = questionAnswers.map((question, i) => {
      if (i === index) {
        const updatedQuestion = { ...question };
        updatedQuestion.answers = question.answers.map((ans, j) => {
          return {
            ...ans,
            is_correct: j === ansIndex,
          };
        });
        return updatedQuestion;
      }
      return question;
    });
    dispatch(
      setQuizData({ ...quizData, questionAndAnswers: updatedQuestionAnswers })
    );
  };

  const clearQuizText = () => {
    if (createQuizBtnRef.current) {
      createQuizBtnRef.current.style.backgroundColor = "#CCCCCC";
      createQuizBtnRef.current.style.color = "white";
      setDisableCreateBtn(true);
    }
    dispatch(setquizLandscapeBackBtnClicked(false));
    controller?.abort();
    setIsQuizCreating(false);
    dispatch(setShowQuizContentPage(false));
    setIsViewForwardBtn(false);
    dispatch(setIsQuizLoaded(false));
    setIsQuizLoading(false);
    setQuizText("");
    setShowActionBtns(false);
    setOpenExportModal(false);
    setOpenReviewModal(false);
    setOpenGoogleFormsModal(false);
    // setDriveMenu(false);
    setOpenSaveModal(false);
    setOpenNameModal(false);
    setopenSettingsModal(false);
    setopenShareModal(false);
    setCommentAfterPdf(false);
    setShowQuizExportBox(false);
    setOpenWarningModal(false);
    setInvalidParagraphs(false);
    setIsOpenConnectionModal(false);
    setOpenNewFileImportModal(false);
    setOpenNewSampleTextImportModal(false);
    setIsSelectedAllQuestions(true);
    dispatch(setAllQuestionsSelected(true));
    setIsOpenMaxWOrdsWarningModal(false);
    setInitialQuizParagrapgh("");
    dispatch(setEnableEditMode(false));
    dispatch(setEnableShareMode(false));
    setIsDisableCursor(false);
    setExportFile({
      name: "",
      prety: false,
    });
    setErrors({
      quizTextError: null,
      quizTextWarning: null,
      emptyQuestionAnswerError: false,
      connection: null,
    });
    setReview({
      rating: 0,
      email: "",
      comment: "",
    });
    clearQuizData();
    dispatch(setQuizInputText(""));
    logEvent(Categories.content_for_quiz, Events.clear_button_clicked);
    // dispatch(setQuizType("Multiple choice"));
    // dispatch(setQuizDifficultyLevel("Medium"));
    // dispatch(setQuizCount("Auto"));
    dispatch(setQuizGenerationCurrentState(0));
    dispatch(setIsQuizLoaded(false));
    dispatch(setIsDisableQuizSettings(false));
  };

  const handleCopyClipboard = async () => {
    logEvent(Categories.export_quiz, Events.copy_to_clip_board_clicked);

    if (!isQuestionAndAnswersAreValid(quizData.questionAndAnswers)) {
      return handleQuestionAnswersValidation();
    }
    setErrors({ ...errors, emptyQuestionAnswerError: false });
    let data = quizData.questionAndAnswers.filter((item) => item.isChecked);

    const { quiz_id, quiz_hash } = quizData;

    let resp = await saveSelectedQuzies(
      data,
      CLIPBOARD_EXPORT_TYPE,
      quiz_id,
      quiz_hash,
      true
    );
    // handleOpenReviewModal();
    setExportFileType(CLIPBOARD_EXPORT_TYPE);
    if (!isSignedIn) handleOpenNameModal();
    else
      onEmailSubmission(
        { email: userData.username },
        false,
        CLIPBOARD_EXPORT_TYPE
      );
    // handleOpenNameModal();
    dispatch(
      setQuizData({
        ...quizData,
        exportData: resp.data,
      })
    );
  };

  const addNewAnswerOfQuestion = (questionIndex) => {
    const questionAnswers = [...quizData.questionAndAnswers];
    if (questionAnswers[questionIndex]?.answers.length < MAX_ALLOWED_ANSWERS) {
      const newAnswer = {
        value: "",
        isNew: true,
      };

      const updatedQuestionAnswers = questionAnswers.map((question, index) => {
        if (index === questionIndex) {
          const updatedQuestion = { ...question };
          updatedQuestion.answers = [...updatedQuestion.answers, newAnswer];
          return updatedQuestion;
        }
        return question;
      });
      return dispatch(
        setQuizData({
          ...quizData,
          questionAndAnswers: updatedQuestionAnswers,
        })
      );
    }
    return null;
  };

  const clearQuizData = () => {
    dispatch(
      setQuizData({
        questionAndAnswers: [],
      })
    );
    // setQuizData({
    //   questionAndAnswers: [],
    // });
  };

  const deleteAnswerFromQuesion = (questionIndex, answerIndex) => {
    const questionAnswers = [...quizData.questionAndAnswers];
    const updatedQuestionAnswers = questionAnswers.map((question, i) => {
      if (i === questionIndex) {
        const updatedQuestion = { ...question };
        updatedQuestion.answers = question.answers.filter(
          (_, index) => index !== answerIndex
        );
        return updatedQuestion;
      }
      return question;
    });
    dispatch(
      setQuizData({ ...quizData, questionAndAnswers: updatedQuestionAnswers })
    );
  };

  const handleQuestionAnswersValidation = () => {
    const updatedQuestionAnswers = quizData.questionAndAnswers.map(
      (questionAnswer) => {
        const updatedQuestionAnswer = { ...questionAnswer };
        updatedQuestionAnswer.answers = questionAnswer.answers.map((ans) => {
          const updatedAnswer = { ...ans };
          updatedAnswer.isNew = false;
          return updatedAnswer;
        });
        return updatedQuestionAnswer;
      }
    );
    checkForQuestionAnswerErrorsAndScroll(
      updatedQuestionAnswers,
      "-Question-Answer-box"
    );
    dispatch(
      setQuizData({
        ...quizData,
        questionAndAnswers: updatedQuestionAnswers,
      })
    );
    return setErrors({ ...errors, emptyQuestionAnswerError: true });
  };

  const handleExportFile = async (fileType) => {
    if (!isQuestionAndAnswersAreValid(quizData.questionAndAnswers)) {
      return handleQuestionAnswersValidation();
    }
    setErrors({ ...errors, emptyQuestionAnswerError: false });
    setExportFileType(fileType);
    logEvent(
      Categories.export_quiz,
      fileType + "_export_clicked",
      Events.export_event
    );

    //   if(fileType !== PDF_EXPORT_FILE_TYPE && fileType !== TEXT_EXPORT_FILE_TYPE)
    //   {
    //     let data = quizData.questionAndAnswers.filter(item=> item.isChecked);

    //     const { quiz_id, quiz_hash } = quizData;
    //     let resp = await saveSelectedQuzies(data,fileType,quiz_id,quiz_hash,true);
    //     // console.log("resp", resp);
    //     dispatch(setQuizData({
    //       ...quizData,
    //       exportData: resp.data,
    //     }))
    // }
    // fileType === PDF_EXPORT_FILE_TYPE || fileType === TEXT_EXPORT_FILE_TYPE ? handleOpenNameModal() : handleOpenReviewModal();
    if (!isSignedIn) handleOpenNameModal();
    else onEmailSubmission({ email: userData.username }, false, fileType);
    // handleOpenReviewModal();
  };

  const onReviewSubmission = async (userReview) => {
    resetCommentAfterPdf();
    closeReviewModal();
    logEvent(Categories.feed_back, Events.feedback_event, userReview.rating);
    setReview(userReview);

    await saveFeedBack(
      quizData?.exportData?.id,
      userReview,
      quizData?.exportData?.feedbackId
    );
    if (
      exportFileType === CLIPBOARD_EXPORT_TYPE ||
      exportFileType === PDF_EXPORT_FILE_TYPE ||
      exportFileType === TEXT_EXPORT_FILE_TYPE ||
      exportFileType === "Integrate via file"
    ) {
      return;
    }
    setExportFile({ ...exportFile, name: getExportFileName() });
    setOpenExportModal(true);
  };

  const onEmailSubmission = async (
    userEmail,
    sendEmail,
    fileType = exportFileType
  ) => {
    // logEvent(Categories.user_email,Events.user_email_click, userEmail.email);
    setUserEmail(userEmail);
    //=========== EXPORT QUIZ AND FEEDBACK DATA SENT HERE
    const { quiz_id, quiz_hash } = quizData;
    let data = quizData.questionAndAnswers.filter((item) => item.isChecked);
    //await saveFeedBack(quizData.exportData.id, userReview);
    const { email } = userEmail;
    let resp = await saveSelectedQuzies(
      data,
      fileType,
      quiz_id,
      quiz_hash,
      true,
      email,
      [],
      "2",
      isSignedIn,
      sendEmail
    );
    closeNameModal();
    dispatch(
      setQuizData({
        ...quizData,
        exportData: resp.data,
      })
    );
    //========== END OF EXPORT AND FEEDBACK DATA HERE
    if (fileType === intlMessages.exportFileLabel) {
      return setIsOpenExportFileModal(true);
    }
    if (fileType === CLIPBOARD_EXPORT_TYPE) {
      return handleOpenClipboardModal();
    }
    setExportFile({
      ...exportFile,
      name: enableEditMode || enableShareMode ? quizTitle : getExportFileName(),
    });
    setOpenExportModal(true);
  };

  const downloadFileFromApi = (
    email,
    checkedQueAndAns,
    quiz_id,
    quiz_hash,
    fileName,
    prety,
    isMarkedCorrectAnswers,
    cb
  ) => {
    exportFileFromApi(
      email,
      checkedQueAndAns,
      exportFileType,
      quiz_id,
      quiz_hash,
      prety,
      quizData.exportData.id,
      isMarkedCorrectAnswers
    )
      .then((res) => {
        if (!res) {
          setErrors({ ...errors, connection: intlMessages.invalidError });
          return showErrorMessage(intlMessages.invalidError);
        }
        if (res.data.statusCode === 0) {
          return handleOpenConnectionModal();
        }
        if (
          exportFileType !== CLIPBOARD_EXPORT_TYPE &&
          res?.data &&
          res.data.statusCode !== 201 &&
          res.data.message
        ) {
          setErrors({ ...errors, connection: res.data.message });
          return showErrorMessage(res.data.message);
        }
        setErrors({ ...errors, connection: null });
        if (exportFileType === CLIPBOARD_EXPORT_TYPE) {
          if (cb) cb(res.data.alreadyExported);
          return;
        }
        if (cb) cb(res.data.alreadyExported);

        let filePath = res.data.file;
        filePath = filePath
          .split("/")
          .filter((val) => val.length > 0)
          .join("/");
        const fileUrl = BASE_URL_EXPORT_FILE + "/" + filePath;
        const isFileExported = getFileFromUrl(
          fileUrl,
          fileName,
          exportFileType
        );
        if (!isFileExported) {
          return showErrorMessage(intlMessages.invalidFileUrl);
        }
      })
      .catch((error) => {
        return false;
      });
  };
  const exportQuestionAnswers = (isMarkedCorrectAnswers) => {
    const { questionAndAnswers: quesAnsPairs, quiz_id, quiz_hash } = quizData;
    let { name: fileName, prety } = exportFile;
    fileName = fileName ? fileName : getExportFileName();
    const checkedQueAndAns = [];
    const checkedQueAndAnsWithCorrectOptions = [];
    let count = 0;
    quesAnsPairs.forEach((item) => {
      if (item.isChecked) {
        let answers = [];
        count += 1;
        let correctAnswers = [];
        item.answers.forEach((ans) => {
          if (ans.is_correct) {
            answers.push(`${ans.value}`);
            correctAnswers.push(
              `${ans.value} ${
                exportFileType === PDF_EXPORT_FILE_TYPE
                  ? prety
                    ? CORRECT_OPTION_SYMBOL_PDF.replace("{0}", 20)
                    : CORRECT_OPTION_SYMBOL_PDF.replace("{0}", 10)
                  : isTl7
                  ? " (Correct)"
                  : CORRECT_OPTION_SYMBOL
              }`
            );
          } else {
            answers.push(ans.value);
            correctAnswers.push(`${ans.value}`);
          }
        });
        checkedQueAndAns.push({
          question: item.question,
          answers: answers,
        });
        checkedQueAndAnsWithCorrectOptions.push({
          question: item.question,
          answers: correctAnswers,
        });
      }
    });

    let exportedCb = function (alreadyExported) {
      if (!alreadyExported) {
        if (exportFileType == CLIPBOARD_EXPORT_TYPE) {
          let labl = isMarkedCorrectAnswers
            ? "_with_correct_answers"
            : "_without_correct_answers";
          logEvent(
            Events.export_event,
            Events.export_quiz_via,
            "",
            Events.copy_to_clip_board + labl
          );
          return;
        }
        if (prety && isMarkedCorrectAnswers) {
          if (exportFileType == PDF_EXPORT_FILE_TYPE) {
            logEvent(
              Categories.export_quiz,
              Events.export_quiz_via,
              "",
              exportFileType + "_file_for_learninghub_with_correct_answers"
            );
          } else {
            logEvent(
              Categories.export_quiz,
              Events.export_quiz_via,
              "",
              exportFileType + "_file_with_correct_answers"
            );
          }
        }
        if (!prety && isMarkedCorrectAnswers) {
          logEvent(
            Categories.export_quiz,
            Events.export_quiz_via,
            "",
            exportFileType + "_file_with_correct_answers"
          );
        }

        if (prety && !isMarkedCorrectAnswers) {
          if (exportFileType == PDF_EXPORT_FILE_TYPE) {
            logEvent(
              Categories.export_quiz,
              Events.export_quiz_via,
              "",
              exportFileType + "_file_for_learninghub_without_correct_answers"
            );
          } else {
            logEvent(
              Categories.export_quiz,
              Events.export_quiz_via,
              "",
              exportFileType + "_file_without_correct_answers"
            );
          }
        }
        if (!prety && !isMarkedCorrectAnswers) {
          logEvent(
            Categories.export_quiz,
            Events.export_quiz_via,
            "",
            exportFileType + "_file_without_correct_answers"
          );
        }
      }
    };
    const userData = JSON.parse(window.localStorage.getItem("userData"));
    if (isMarkedCorrectAnswers) {
      downloadFileFromApi(
        userData?.email,
        checkedQueAndAnsWithCorrectOptions,
        quiz_id,
        quiz_hash,
        `${fileName}-${intlMessages.correctLabel}`,
        prety,
        isMarkedCorrectAnswers,
        exportedCb
      );
    } else {
      downloadFileFromApi(
        userData?.email,
        checkedQueAndAns,
        quiz_id,
        quiz_hash,
        fileName,
        prety,
        isMarkedCorrectAnswers,
        exportedCb
      );
    }
    if (exportFileType == CLIPBOARD_EXPORT_TYPE) {
      copyQuestionAnswersToClipboard(isMarkedCorrectAnswers);
    }
  };

  const copyQuestionAnswersToClipboard = (isCopyWithCorrectAnswers) => {
    let copiedQuestionAndAnswers = "";
    let selectCount = 0;
    quizData.questionAndAnswers.forEach((item) => {
      if (item.isChecked) {
        let answersOfQuestion = "";
        item.answers.forEach((ans, ansIndex) => {
          if (ans.is_correct && isCopyWithCorrectAnswers)
            answersOfQuestion += `${optionsLabels[ansIndex]}) ${ans.value} ${CORRECT_OPTION_SYMBOL}\n`;
          else
            answersOfQuestion += `${optionsLabels[ansIndex]}) ${ans.value}\n`;
        });
        copiedQuestionAndAnswers += `${intlMessages.questionLabel}#${
          selectCount + 1
        }\n${item.question}\n${answersOfQuestion}\n`;
        selectCount += 1;
      }
    });
    let labl = isCopyWithCorrectAnswers
      ? "_with_correct_answers"
      : "_without_correct_answers";
    //logEvent(Events.export_event,Events.export_quiz_via,selectCount,Events.copy_to_clip_board+labl)
    logEvent(
      Events.export_event,
      Events.exported_ans_count,
      selectCount,
      selectCount
    );
    const isCopied = copiedToClipboard(copiedQuestionAndAnswers);
    // handleCloseClipboardModal();
    // handleOpenReviewModal();
    if (isCopied) {
      return setSnackBar({
        ...snackBarstate,
        type: "success",
        message: intlMessages.copyToClipboardSuccess,
        openSnackBar: true,
      });
    }
  };

  const readTextFile = async (e) => {
    e.preventDefault();
    const file = e.target.files[0];
    const validExtensions = [
      "text/plain",
      "image/jpeg",
      "image/png",
      "application/pdf",
      "application/msword",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      "application/vnd.ms-excel",
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      "application/vnd.ms-powerpoint",
      "application/vnd.openxmlformats-officedocument.presentationml.presentation",
    ];
    const isValidExtension = validExtensions.includes(file?.type);
    if (!file) {
      return false;
    }
    const extensions = acceptedFormats().validExtensions;
    if (isValidExtension && !extensions.includes(file?.type)) {
      dispatch(
        setUpgradeModalText({
          heading: intlMessages.upgradeModelHeading,
          desc: intlMessages.upgradeModelDescText,
        })
      );
      dispatch(setOpenUpgradeModel(true));
      return false;
    }
    if (!isValidExtension) {
      return setSnackBar({
        ...snackBarstate,
        type: "error",
        message: intlMessages.invalidFileType,
        openSnackBar: true,
      });
    }
    if (
      file?.type === validExtensions[0] &&
      file?.size > MAX_TXT_FILE_UPLOAD_SIZE
    ) {
      return setSnackBar({
        ...snackBarstate,
        type: "error",
        message: intlMessages.maxFileSize,
        openSnackBar: true,
      });
    }
    if (
      file?.type !== validExtensions[0] &&
      file?.size > MAX_FILE_UPLOAD_SIZE
    ) {
      return setSnackBar({
        ...snackBarstate,
        type: "error",
        message: intlMessages.maxpdfSizeWarning,
        openSnackBar: true,
      });
    }
    if (file?.type === validExtensions[0]) {
      const reader = new FileReader();
      reader.onload = (e) => {
        let text = e.target.result;
        if (text.trim().length == 0 || containsOnlyNewlines(text)) {
          showEmptyFileError();
          return;
        }
        clearQuizText();
        if (getWordCount(text) > quizContentLimit.contentLimit) {
          setMsg({
            message:
              userData?.subscriptionPlan === "Basic" || userData === null
                ? [
                    intlMessages.maxWordBasicPlanText
                      .replace("#1", APP_NAME)
                      .replaceAll("#2", quizContentLimit.contentLimit)
                      .replaceAll("#3", MAX_QUIZ_WORDS),
                    intlMessages.maxWordBasicPlanText2,
                    intlMessages.maxWordBasicPlanText3.replaceAll(
                      "#3",
                      MAX_QUIZ_WORDS
                    ),
                  ]
                : intlMessages.extractedTextLimitWarning?.replaceAll(
                    "#",
                    quizContentLimit.contentLimit
                  ),
            icon: "/svgs/warning.svg",
            isError: null,
          });
          setOpenAlertBox(true);
          dispatch(setIsShowCameraWarning(true));
        }
        text = wordsWithSubstring(
          e.target.result,
          wordsSplit(e.target.result, {
            punctuation: [],
            disableDefaultPunctuation: true,
          }),
          quizContentLimit.contentLimit
        );
        onChangeQuizText(text, true);
        // document.execCommand("insertText", false, text.trim())
      };
      reader.readAsText(file);
    } else if (file?.type?.startsWith("image")) {
      uploadCapturedImageFile(file);
    } else {
      uploadFile(file);
    }
  };

  const uploadFile = async (file) => {
    setLoader(true);
    try {
      const response = await importUploadFile(file);
      if (response?.data?.statusCode === 0) {
        setLoader(false);
        setIsOpenConnectionModal(true);
        return;
      }
      if (
        response?.status === 201 &&
        response?.data?.message === "File uploaded successfully!"
      ) {
        setLoader(false);
        setFileData({
          name: response?.data?.data?.fileName,
          fileTotalPages: response?.data?.data?.pages,
          filePath: response?.data?.data?.filePath,
          id: new Date().getTime().toString(),
        });
        setIsOpenPageSelectionModal(true);
        return;
      } else {
        setLoader(false);
        setIsLibraryBtn(false);
        setMsg({
          message: intlMessages.fileErrorMsg,
          icon: "/svgs/erroricon.svg",
          isError: true,
        });
        setOpenAlertBox(true);
        return;
      }
    } catch (error) {}
  };

  const showEmptyFileError = () => {
    openEmptyAlert();
  };

  const renderAlertBoxForFile = () => {
    return (
      <>
        {openFileErrorAlertBox && (
          <AlertBox
            isIpad={isIpad ? true : false}
            isMobile={isMobile ? true : false}
            isMobileLandscape={isMobileLandscape ? true : false}
            isIpadLandscape={isIpadLandscape ? true : false}
            message={{
              message: intlMessages.driveEmptyFileError,
              icon: "/svgs/erroricon.svg",
              isError: true,
            }}
            loginBtn={false}
            isOpen={openFileErrorAlertBox}
            onClose={closeEmptyAlert}
            showDifferentChooser={true}
            backToMainMenu={backToMainMenu}
          />
        )}
      </>
    );
  };

  const renderSnackBar = () => {
    return (
      <Snackbar
        open={openSnackBar}
        anchorOrigin={{ vertical, horizontal }}
        autoHideDuration={3000}
        onClose={handleCloseSnackBar}
        key={vertical + horizontal}
      >
        <MuiAlert
          elevation={6}
          variant="filled"
          sx={{ color: "white" }}
          onClose={handleCloseSnackBar}
          severity={snackBarstate.type}
        >
          {snackBarstate.message}
        </MuiAlert>
      </Snackbar>
    );
  };

  const renderExportModal = () => {
    return (
      <Modal
        open={openExportModal}
        // onClose={() => setOpenExportModal(false)}
        aria-labelledby="export-question-modal"
        aria-describedby="export-question-description"
      >
        <Styled.ModalBox sx={Styled.customStyling().exportModal}>
          <Styled.ModalHeader>
            <Typography
              id="export-modal-title"
              variant="h5"
              component="h5"
              style={{ fontWeight: "bold" }}
            >
              {intlMessages.exportLabel} .{exportFileType}
            </Typography>
            <IconButton
              aria-label="closeExportModal"
              onClick={() => {
                handleCloseExportModal();
              }}
            >
              <CloseIcon color="seondary.light" />
            </IconButton>
          </Styled.ModalHeader>
          <Styled.ModalContentBox>
            <Styled.ModalTextField
              required
              value={exportFile?.name}
              helperText={errors.lengthExportFileNameError}
              error={exportFileNameError ? true : false}
              onChange={(e) =>
                setExportFile({
                  ...exportFile,
                  name: validateInput(e.target.value),
                })
              }
            />
            <Styled.ErrorText>{errors?.connection}</Styled.ErrorText>
            <Box
              sx={{
                display:
                  exportFileType === TEXT_EXPORT_FILE_TYPE ? "none" : "flex",
                flexDirection: "row",
                mt: 1.5,
              }}
            >
              <Checkbox
                color="secondary"
                sx={{
                  padding: 0,
                }}
                checked={exportFile?.prety}
                onChange={() => handleExportFileCheckbox()}
              />
              <Link
                component="button"
                variant="p"
                sx={{
                  pl: 1,
                  color: "GrayText",
                  textDecoration: "none",
                  fontSize: 13.8,
                  mt: "10px",
                  textAlign: "left",
                }}
                onClick={() => handleExportFileCheckbox()}
                onmouseover={"this.style.cursor='pointer'"}
              >
                {intlMessages.exportForLearningHubLabel.replace(
                  "{0}",
                  COMPANY_NAME
                )}
              </Link>
            </Box>
          </Styled.ModalContentBox>
          <Styled.ModalActions>
            <Styled.CreateQuizButton
              variant="contained"
              color="secondary"
              onClick={handleOpenDownloadOptionsModal}
              startIcon={<AddIcon sx={{ display: "none" }} />}
            >
              {intlMessages.continueLabel}
            </Styled.CreateQuizButton>
            <Button
              sx={{
                textTransform: "none",
                marginLeft: 1,
              }}
              variant="outlined"
              color="secondary"
              onClick={() => {
                handleCloseExportModal();
              }}
            >
              {intlMessages.cancelLabel}
            </Button>
          </Styled.ModalActions>
        </Styled.ModalBox>
      </Modal>
    );
  };

  const renderCopyClipboardModal = () => {
    return (
      <Modal
        open={openClipboardModal}
        // onClose={handleCloseClipboardModal}
        aria-labelledby="clipboard-question-modal"
        aria-describedby="clipboard-question-description"
      >
        <Styled.ModalBox sx={Styled.customStyling().exportModal}>
          <Styled.ModalHeader>
            <Typography
              id="clipboard-modal-title"
              variant="h5"
              component="h5"
              style={{ fontWeight: "bold" }}
            >
              {intlMessages.copyToClipboardLabel}
            </Typography>
            <IconButton
              aria-label="closeClipboardModal"
              onClick={handleCloseClipboardModal}
            >
              <CloseIcon color="seondary.light" />
            </IconButton>
          </Styled.ModalHeader>
          <Styled.ModalContentBox>
            <Box
              sx={{
                display: "flex",
                mt: 1.5,
                alignItems: "left",
                flexDirection: "column",
              }}
            >
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Button
                  sx={{ textTransform: "none" }}
                  color="primary"
                  startIcon={<FileCopyOutlinedIcon />}
                  onClick={() => {
                    const withCorrectAnswers = false;
                    exportQuestionAnswers(withCorrectAnswers);
                  }}
                >
                  {intlMessages.copyWithoutCorrectAnswersLabel}
                </Button>
              </Box>

              <Box
                sx={{ display: "flex", alignItems: "center", marginTop: 1.3 }}
              >
                <Button
                  sx={{ textTransform: "none" }}
                  color="primary"
                  startIcon={<FileCopyOutlinedIcon />}
                  onClick={() => {
                    const withCorrectAnswers = true;
                    exportQuestionAnswers(withCorrectAnswers);
                  }}
                >
                  {intlMessages.copyWithCorrectAnswersLabel}
                </Button>
              </Box>
            </Box>
          </Styled.ModalContentBox>
          <Styled.ModalActions>
            <Button
              sx={{
                textTransform: "none",
                marginLeft: 1,
              }}
              variant="contained"
              color="secondary"
              onClick={handleCloseClipboardModal}
            >
              {intlMessages.doneLabel}
            </Button>
          </Styled.ModalActions>
        </Styled.ModalBox>
      </Modal>
    );
  };

  const renderDownloadOptionsModal = () => {
    return (
      <Modal
        open={openDownloadOptionsModal}
        aria-labelledby="download-options-modal"
        aria-describedby="download-options-description"
      >
        <Styled.ModalBox sx={Styled.customStyling().exportModal}>
          <Styled.ModalHeader>
            <IconButton
              aria-label="downloadOptionsModal"
              onClick={() => {
                handleCloseDownloadOptionsModal(true);
                setOpenExportModal(true);
              }}
            >
              <ArrowBackIcon color="seondary.light" />
            </IconButton>
            <Typography
              id="download-options-modal-title"
              variant="h5"
              component="h5"
              style={{ fontWeight: "bold" }}
            >
              {intlMessages.exportLabel} .{exportFileType}
            </Typography>
            <IconButton
              aria-label="downloadOptionsModal"
              onClick={() => {
                setExportFile({
                  ...exportFile,
                  prety: false,
                });
                handleCloseDownloadOptionsModal();
              }}
            >
              <CloseIcon color="seondary.light" />
            </IconButton>
          </Styled.ModalHeader>
          <Styled.ModalContentBox>
            <Box
              sx={{
                display: "flex",
                mt: 1.5,
                alignItems: "left",
                flexDirection: "column",
              }}
            >
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Button
                  sx={{ textTransform: "none" }}
                  color="primary"
                  startIcon={<DownloadIcon />}
                  onClick={() => {
                    const withCorrectAnswers = false;
                    exportQuestionAnswers(withCorrectAnswers);
                  }}
                >
                  {intlMessages.downloadWithoutCorrectAnswersLabel}
                </Button>
              </Box>

              <Box
                sx={{ display: "flex", alignItems: "center", marginTop: 1.3 }}
              >
                <Button
                  sx={{ textTransform: "none" }}
                  color="primary"
                  startIcon={<DownloadIcon />}
                  onClick={() => {
                    const withCorrectAnswers = true;
                    exportQuestionAnswers(withCorrectAnswers);
                  }}
                >
                  {intlMessages.downloadWithCorrectAnswersLabel}
                </Button>
              </Box>
            </Box>
          </Styled.ModalContentBox>
          <Styled.ModalActions>
            <Button
              sx={{
                textTransform: "none",
              }}
              variant="contained"
              color="secondary"
              onClick={() => {
                setExportFile({
                  ...exportFile,
                  prety: false,
                });
                handleCloseDownloadOptionsModal();
              }}
            >
              {intlMessages.doneLabel}
            </Button>
          </Styled.ModalActions>
        </Styled.ModalBox>
      </Modal>
    );
  };

  const renderWarningModal = () => {
    return (
      <Modal
        open={openWarningModal}
        // onClose={() => setOpenWarningModal(false)}
        aria-labelledby="paragraph-warning-modal"
        aria-describedby="paragraph-warning-description"
      >
        <Styled.ModalBox sx={Styled.customStyling().exportModal}>
          <Styled.ModalHeader>
            <Typography
              id="warning-paragraph-modal-title"
              variant="h5"
              component="h5"
              style={{ fontWeight: "bold" }}
            >
              {intlMessages.warningLabel}
            </Typography>
            <IconButton
              aria-label="closeWarningParagraphModal"
              onClick={() => setOpenWarningModal(false)}
            >
              <CloseIcon color="seondary.light" />
            </IconButton>
          </Styled.ModalHeader>
          <Styled.ModalContentBox sx={{ mt: "0px", mb: "20px" }}>
            {getWordCount(quizText) > quizContentLimit.contentLimit ? (
              <Typography variant="p">
                {userData === null || userData?.subscriptionPlan === "Basic" ? (
                  <>
                    <span>
                      <>
                        <span>
                          {intlMessages.maxWordBasicPlanText
                            .replace("#1", APP_NAME)
                            .replaceAll("#2", quizContentLimit.contentLimit)
                            .replaceAll("#3", MAX_QUIZ_WORDS)}
                        </span>
                        <span
                          onClick={() => {
                            setOpenWarningModal(false);
                            dispatch(setOpenPricingModal(true));
                          }}
                          style={{
                            color: "#2062BB",
                            cursor: "pointer",
                            marginLeft: "3px",
                            marginRight: "0px",
                          }}
                        >
                          {intlMessages.maxWordBasicPlanText2}
                        </span>
                        <span>
                          {intlMessages.maxWordBasicPlanText3.replaceAll(
                            "#3",
                            MAX_QUIZ_WORDS
                          )}
                        </span>
                      </>
                    </span>
                    <span
                      onClick={() => {
                        setOpenWarningModal(false);
                        dispatch(setOpenPricingModal(true));
                      }}
                      style={{
                        color: "#2062BB",
                        cursor: "pointer",
                        marginLeft: "3px",
                        marginRight: "0px",
                      }}
                    >
                      {intlMessages.maxWordBasicPlanText2}
                    </span>
                    <span>
                      {intlMessages.maxWordBasicPlanText3.replaceAll(
                        "#3",
                        MAX_QUIZ_WORDS
                      )}
                    </span>
                  </>
                ) : (
                  intlMessages.maxWordsWarningModalText
                    .replace("{0}", APP_NAME)
                    .replaceAll(
                      "{1}",
                      `${quizContentLimit.contentLimit}
                `
                    )
                )}
              </Typography>
            ) : (
              ""
            )}
            <br></br>
            <Typography variant="p">
              {intlMessages.paragraphMinWordsWarning
                .replace("{0}", MIN_QUIZ_WORDS)
                .replace("{1}", ``)}
            </Typography>
          </Styled.ModalContentBox>
          <Styled.ModalActions>
            <Styled.CreateQuizButton
              variant="contained"
              color="secondary"
              onClick={() => setOpenWarningModal(false)}
            >
              {intlMessages.editLabel}
            </Styled.CreateQuizButton>
            <Button
              sx={{
                textTransform: "none",
                marginLeft: 1,
              }}
              variant="outlined"
              color="secondary"
              onClick={() => {
                setOpenWarningModal(false);
                if (isPassedValidationsForCreatingQuiz()) createQuiz();
              }}
            >
              {intlMessages.continueLabel}
            </Button>
          </Styled.ModalActions>
        </Styled.ModalBox>
      </Modal>
    );
  };

  const renderMaxInputWarningModal = () => {
    return (
      <Modal
        open={isOpenMaxWOrdsWarningModal}
        // onClose={() => setIsOpenMaxWOrdsWarningModal(false)}
        aria-labelledby="max-words-warning-modal"
        aria-describedby="max-words-warning-description"
      >
        <Styled.ModalBox sx={Styled.customStyling().exportModal}>
          <Styled.ModalHeader>
            <Typography
              id="warning-max-words-modal-title"
              variant="h5"
              component="h5"
              style={{ fontWeight: "bold" }}
            >
              {intlMessages.warningLabel}
            </Typography>
            <IconButton
              aria-label="closeWarningMaxWordsModal"
              onClick={() => setIsOpenMaxWOrdsWarningModal(false)}
            >
              <CloseIcon color="seondary.light" />
            </IconButton>
          </Styled.ModalHeader>
          <Styled.ModalContentBox>
            <Typography variant="p">
              {userData === null || userData?.subscriptionPlan === "Basic" ? (
                <>
                  <span>
                    {intlMessages.maxWordBasicPlanText
                      .replace("#1", APP_NAME)
                      .replaceAll("#2", quizContentLimit.contentLimit)
                      .replaceAll("#3", MAX_QUIZ_WORDS)}
                  </span>
                  <span
                    onClick={() => {
                      dispatch(setOpenPricingModal(true));
                    }}
                    style={{
                      color: "#2062BB",
                      cursor: "pointer",
                      marginLeft: "3px",
                      marginRight: "0px",
                    }}
                  >
                    {intlMessages.maxWordBasicPlanText2}
                  </span>
                  <span>
                    {intlMessages.maxWordBasicPlanText3.replaceAll(
                      "#3",
                      MAX_QUIZ_WORDS
                    )}
                  </span>
                </>
              ) : (
                intlMessages.maxWordsWarningModalText
                  .replace("{0}", APP_NAME)
                  .replaceAll("{1}", `${quizContentLimit.contentLimit}`)
              )}
            </Typography>
          </Styled.ModalContentBox>
          <Styled.ModalActions>
            <Styled.CreateQuizButton
              variant="contained"
              color="secondary"
              onClick={() => setIsOpenMaxWOrdsWarningModal(false)}
            >
              {intlMessages.editLabel}
            </Styled.CreateQuizButton>
            <Button
              sx={{
                textTransform: "none",
                marginLeft: 1,
              }}
              variant="outlined"
              color="secondary"
              onClick={() => {
                setIsOpenMaxWOrdsWarningModal(false);
                if (isPassedValidationsForCreatingQuiz()) {
                  const invalidParas = getInvalidParagraphsOfText(
                    quizText,
                    MIN_QUIZ_WORDS
                  );
                  setInvalidParagraphs(invalidParas);
                  if (invalidParas) {
                    return handleOpenWarningModal();
                  }
                  createQuiz();
                }
              }}
            >
              {intlMessages.continueLabel}
            </Button>
          </Styled.ModalActions>
        </Styled.ModalBox>
      </Modal>
    );
  };

  const renderWarningNewFileImportModal = () => {
    return (
      <Modal
        open={openNewFileImportModal}
        aria-labelledby="new-file-import-warning-modal"
        aria-describedby="new-file-import-warning-description"
      >
        <Styled.ModalBox sx={Styled.customStyling().exportModal}>
          <Styled.ModalHeader>
            <Typography
              id="new-file-import-paragraph-modal-title"
              variant="h5"
              component="h5"
              style={{ fontWeight: "bold" }}
            >
              {intlMessages.warningLabel}
            </Typography>
            <IconButton
              aria-label="new-file-import-btn"
              onClick={handleCloseNewFileImportModal}
            >
              <CloseIcon color="seondary.light" />
            </IconButton>
          </Styled.ModalHeader>
          <Styled.ModalContentBox>
            <Typography variant="p">
              {`${intlMessages.warningNewFileImport}`}
            </Typography>
          </Styled.ModalContentBox>
          <Styled.ModalActions>
            <Styled.CreateQuizButton
              variant="contained"
              color="secondary"
              onClick={() => {
                handleCloseNewFileImportModal();
                // inputFile.current.click();
                openImportFileModal();
              }}
            >
              {intlMessages.okLabel}
            </Styled.CreateQuizButton>
            <Button
              sx={{
                textTransform: "none",
                marginLeft: 1,
              }}
              variant="outlined"
              color="secondary"
              onClick={() => {
                handleCloseNewFileImportModal();
              }}
            >
              {intlMessages.cancelLabel}
            </Button>
          </Styled.ModalActions>
        </Styled.ModalBox>
      </Modal>
    );
  };

  const renderWarningNewWebUrlImportModal = () => {
    return (
      <Modal
        open={openNewWebUrlImportModal}
        aria-labelledby="new-file-import-warning-modal"
        aria-describedby="new-file-import-warning-description"
      >
        <Styled.ModalBox sx={Styled.customStyling().exportModal}>
          <Styled.ModalHeader>
            <Typography
              id="new-file-import-paragraph-modal-title"
              variant="h5"
              component="h5"
              style={{ fontWeight: "bold" }}
            >
              {intlMessages.warningLabel}
            </Typography>
            <IconButton
              aria-label="new-file-import-btn"
              onClick={() => setIsOpenNewWebUrlImportModal(false)}
            >
              <CloseIcon color="seondary.light" />
            </IconButton>
          </Styled.ModalHeader>
          <Styled.ModalContentBox>
            <Typography variant="p">
              {`${intlMessages.warningWebUrlImport}`}
            </Typography>
          </Styled.ModalContentBox>
          <Styled.ModalActions>
            <Styled.CreateQuizButton
              variant="contained"
              color="secondary"
              onClick={() => {
                setIsOpenNewWebUrlImportModal(false);
                handleOpenWebURLModal();
              }}
            >
              {intlMessages.okLabel}
            </Styled.CreateQuizButton>
            <Button
              sx={{
                textTransform: "none",
                marginLeft: 1,
              }}
              variant="outlined"
              color="secondary"
              onClick={() => setIsOpenNewWebUrlImportModal(false)}
            >
              {intlMessages.cancelLabel}
            </Button>
          </Styled.ModalActions>
        </Styled.ModalBox>
      </Modal>
    );
  };

  const renderWarningSampleTextImportModal = () => {
    return (
      <Modal
        open={openNewSampleText}
        aria-labelledby="new-sample-text-import-warning-modal"
        aria-describedby="new-sample-text-import-warning-description"
      >
        <Styled.ModalBox sx={Styled.customStyling().exportModal}>
          <Styled.ModalHeader>
            <Typography
              id="new-file-import-paragraph-modal-title"
              variant="h5"
              component="h5"
              style={{ fontWeight: "bold" }}
            >
              {intlMessages.warningLabel}
            </Typography>
            <IconButton
              aria-label="new-file-import-btn"
              onClick={handleDropDownClose}
            >
              <CloseIcon color="seondary.light" />
            </IconButton>
          </Styled.ModalHeader>
          <Styled.ModalContentBox>
            <Typography variant="p">
              {`${intlMessages.warningSampleTextImport}`}
            </Typography>
          </Styled.ModalContentBox>
          <Styled.ModalActions>
            <Styled.CreateQuizButton
              variant="contained"
              color="secondary"
              onClick={() => {
                if (isMobile || isMobileLandscape) {
                  setOpenNewSampleTextImportModal(false);
                  const field = document.getElementById("quiz-input-field");
                  field && field.blur();
                  setOpenSampleTextMobileModal(true);
                  dispatch(setShowSampleTextModal(true));
                  logEvent(
                    Categories.content_for_quiz,
                    Events.import_sample_text_button
                  );
                } else {
                  // setQuizText('')
                  setOpenNewSampleTextImportModal(false);
                  setDropDownOpen(true);
                  logEvent(
                    Categories.content_for_quiz,
                    Events.import_sample_text_button
                  );
                }
              }}
            >
              {intlMessages.okLabel}
            </Styled.CreateQuizButton>
            <Button
              sx={{
                textTransform: "none",
                marginLeft: 1,
              }}
              variant="outlined"
              color="secondary"
              onClick={() => {
                if (isMobile || isMobileLandscape) {
                  setOpenNewSampleTextImportModal(false);
                  setOpenSampleTextMobileModal(false);
                  dispatch(setShowSampleTextModal(false));
                } else handleDropDownClose();
              }}
            >
              {intlMessages.cancelLabel}
            </Button>
          </Styled.ModalActions>
        </Styled.ModalBox>
      </Modal>
    );
  };

  const importSampleText = (index) => {
    setDisableCreateBtn(false);
    createQuizBtnRef.current.style.backgroundColor = "#7DC242";
    setIsDisableCursor(true);
    setIsKeyboardOpen(false);
    setErrors({
      quizTextError: null,
      quizTextWarning: null,
      emptyQuestionAnswerError: false,
      connection: null,
    });
    setDropDownOpen(false);
    setQuizText(sampleTextList[index].description);
    logEvent(Categories.content_for_quiz, Events.import_sample_text_paragraph);
    dispatch(setQuizInputText(sampleTextList[index].description));
  };

  const saveQuizToLibrary = async (quiz, flag, title) => {
    const response = await saveQuiz(title, quiz);
    if (response?.data?.statusCode === 0) {
      setIsOpenConnectionModal(true);
      return;
    }
    if (
      response?.status === 201 &&
      response?.data?.message === "Item saved successfully."
    ) {
      dispatch(setIsQuizSaved(true));
      setOpenTextfieldModal(flag);
      setIsLibraryBtn(true);
      setMsg({
        message: intlMessages.saveQuizConfirmationText,
        icon: "/svgs/successicon.svg",
        isError: false,
      });
      setOpenAlertBox(true);
      return;
    }
    if (
      response?.status === 403 &&
      response?.data?.message === "Already Exist with title"
    ) {
      setOpenExistingQuizWarningModal(true);
      return;
    }
  };

  const readClipboard = async (id) => {
    setClipboardFlag(true);
    const resp = await loadScrybeContent(id);
    setIsContentLoaded(true);
    setLoader(true);
    if (resp?.data?.statusCode === 0) {
      setLoader(false);
      setIsOpenConnectionModal(true);
      return;
    }
    if (resp?.status === 200) {
      setLoader(false);
      if (resp?.data?.text?.length > 0) {
        setIsContentLoaded(false);
        setQuizText(resp.data.text);
        dispatch(setQuizInputText(resp.data.text));
        if (resp?.data?.showWarning === true) {
          setMsg({
            message:
              userData?.stripeStatus === 12 || userData === null
                ? [
                    intlMessages.maxWordBasicPlanText
                      .replace("#1", APP_NAME)
                      .replaceAll("#2", quizContentLimit.contentLimit)
                      .replaceAll("#3", MAX_QUIZ_WORDS),
                    intlMessages.maxWordBasicPlanText2,
                    intlMessages.maxWordBasicPlanText3.replaceAll(
                      "#3",
                      MAX_QUIZ_WORDS
                    ),
                  ]
                : intlMessages.extractedTextLimitWarning?.replaceAll(
                    "#",
                    quizContentLimit.contentLimit
                  ),
            icon: "/svgs/warning.svg",
            isError: null,
          });
          setOpenAlertBox(true);
        }
      }
    }
    searchParams.delete("id");
    navigate(`?${searchParams.toString()}`);
  };

  const handleReadClipboard = async () => {
    try {
      const clipboardContent = await navigator.clipboard.readText();
      setQuizText(clipboardContent);
      dispatch(setQuizInputText(clipboardContent));
    } catch (error) {
      setMsg({
        message: intlMessages.clipboardPermissionError,
        icon: "/svgs/erroricon.svg",
        isError: true,
      });
      setOpenAlertBox(true);
    }
  };

  const updateLibraryQuiz = async (title, quiz) => {
    const response = await updateQuiz(quizId, title, quiz);
    if (response?.data?.statusCode === 0) {
      setIsOpenConnectionModal(true);
      return;
    }
    if (response?.status === 200) {
      dispatch(setQuizTitle(response?.data?.title));
      dispatch(setQuizId(response?.data?.id));
      dispatch(setIsQuizSaved(true));
      setOpenTextfieldModal(false);
      setIsLibraryBtn(true);
      setMsg({
        message: intlMessages.saveQuizConfirmationText,
        icon: "/svgs/successicon.svg",
        isError: false,
      });
      setOpenAlertBox(true);
      return;
    }
    if (
      response?.response?.status === 403 &&
      response?.response?.data?.message === "Already Exist with title"
    ) {
      setOpenExistingQuizWarningModal(true);
      return;
    }
  };

  const forceUpdateLibraryQuiz = async () => {
    const quiz = {
      text: quizInputText,
      ques_ans_pairs: quizData.questionAndAnswers,
      id: quizData.quiz_id,
      hash: quizData.quiz_hash,
      questionType:
        quizType === "Fill in the blanks"
          ? "MCQ (Fill in the blanks)"
          : quizType === "True or False"
          ? "TrueFalse"
          : "MCQ",
      noOfQuestions: quizCount === "Auto" ? "Auto" : parseInt(quizCount),
      difficultyLevel:
        quizDifficultyLevel === "Hard"
          ? "HARD"
          : quizDifficultyLevel === "Easy"
          ? "EASY"
          : "MEDIUM",
    };
    const response = await forceUpdateQuiz(quizTitle, quiz);
    if (response?.data?.statusCode === 0) {
      setIsOpenConnectionModal(true);
      return;
    }
    if (
      response?.status === 200 &&
      response?.data?.message === "Item updated successfully."
    ) {
      setOpenExistingQuizWarningModal(false);
      setOpenTextfieldModal(false);
      return;
    }
  };

  const uploadCapturedImageFile = async (file) => {
    setLoader(true);
    try {
      const response = await generateQuizFromCapturedImage(file);
      if (response?.data?.statusCode === 0) {
        setLoader(false);
        setIsOpenConnectionModal(true);
        return;
      }
      if (
        response?.status === 201 &&
        response?.data?.message === "File uploaded successfully!"
      ) {
        setLoader(false);
        if (response?.data?.data?.text?.length === 0) {
          setIsLibraryBtn(false);
          setMsg({
            message: intlMessages.imageErrorMsg,
            icon: "/svgs/erroricon.svg",
            isError: true,
          });
          setOpenAlertBox(true);
          return;
        }
        clearQuizText();
        onChangeQuizText(response?.data?.data?.text, true);
        dispatch(setOpenCameraModal(false));
        return;
      } else {
        setLoader(false);
        setIsLibraryBtn(false);
        setMsg({
          message: intlMessages.imageErrorMsg,
          icon: "/svgs/erroricon.svg",
          isError: true,
        });
        setOpenAlertBox(true);
        return;
      }
    } catch (error) {}
  };

  const sendWebURL = async (url) => {
    setIsWebUrl(true);
    setLoader(true);
    try {
      const response = await generateQuizFromURL(url);
      if (response?.data?.statusCode === 0) {
        setLoader(false);
        setIsWebUrl(false);
        setIsOpenConnectionModal(true);
        return;
      }
      if (response?.status === 201) {
        setLoader(false);
        setIsWebUrl(false);
        if (response?.data?.isWarning === true) {
          setIsLibraryBtn(false);
          setMsg({
            message:
              userData?.subscriptionPlan === "Basic" || userData === null
                ? [
                    intlMessages.maxWordBasicPlanText
                      .replace("#1", APP_NAME)
                      .replaceAll("#2", quizContentLimit.contentLimit)
                      .replaceAll("#3", MAX_QUIZ_WORDS),
                    intlMessages.maxWordBasicPlanText2,
                    intlMessages.maxWordBasicPlanText3.replaceAll(
                      "#3",
                      MAX_QUIZ_WORDS
                    ),
                  ]
                : intlMessages.extractedTextLimitWarning?.replaceAll(
                    "#",
                    quizContentLimit.contentLimit
                  ),
            icon: "/svgs/warning.svg",
            isError: null,
          });
          setOpenAlertBox(true);
          dispatch(setIsShowCameraWarning(true));
        }
        clearQuizText();
        onChangeQuizText(response?.data?.text, true);
        return;
      } else {
        setIsWebUrl(false);
        setLoader(false);
        setIsLibraryBtn(false);
        setMsg({
          message: intlMessages.urlErrorMsg,
          icon: "/svgs/erroricon.svg",
          isError: true,
        });
        setOpenAlertBox(true);
        return;
      }
    } catch (error) {}
  };

  const renderQuizInputBox = () => {
    return (
      <Styled.ContentGridBox item>
        <Styled.MainContentContainer
          isMobile={isMobile ? true : false}
          isleftbox={"true"}
          mainBoxHeight={mainBoxHeight}
        >
          <Styled.BoxHeader isleftbox={"true"}>
            <Styled.HeaderLabels variant="p" ml={2} componenet="p">
              {intlMessages.quizInputBoxLeftHeader}
            </Styled.HeaderLabels>
            {/* <Styled.SentenceAndWordCount
              sx={{
                color: "white",
                display:
                  isMobile || isMobileLandscape || isIpad ? "none" : "block",
              }}
            >
              {getSentenceCount(quizText).toString()}{" "}
              {intlMessages.sentencesLabel} |{" "}
              {getWordCount(quizText).toString()} {intlMessages.wordsLabel}
            </Styled.SentenceAndWordCount> */}
            {quizText && isIpad ? (
              <ImportMenu
                isMobile={isMobile ? true : false}
                isMobileLandscape={isMobileLandscape ? true : false}
                isIpad={isIpad ? true : false}
                handleMenuItemSelection={handleImportMenuItemSelection}
              ></ImportMenu>
            ) : null}
          </Styled.BoxHeader>
          <Styled.ContentInputMainBox
            component="form"
            noValidate
            autoComplete="off"
            isMobileLandscape={isMobileLandscape ? true : false}
            isIpad={isIpad ? true : false}
            mainBoxHeight={mainBoxHeight}
            sx={{
              backgroundColor:
                isQuizLoading && (isMobile || isMobileLandscape) && "#f5f5f5",
            }}
          >
            <Slide
              timeout={{ enter: 500 }}
              direction={"right"}
              in={true}
              style={{ height: "100%" }}
              mountOnEnter
              unmountOnExit
              appear={isMobile && !isQuizLoading ? true : false}
            >
              <Styled.PlaceHolderArea
                mobileHeight={quizSettingMobileHeight}
                mobileLandscapeHeight={quizSettingHeight}
                istext={quizText.length > 0 ? true : false}
                // img={"Write-Quiz-green"}
                id="quiz-input-placeholder-box"
                iskeyboardopen={isKeyboardOpen ? true : false}
                windowHeight={windowSize.height}
                isios={iOS() ? true : false}
                iserror={errors.quizTextError || errors.quizTextWarning}
                mainBoxHeight={mainBoxHeight}
                isT7={isT7 ? true : false}
              >
                <Styled.placeHolderDivText
                  className={"first-step"}
                  istext={quizText ? true : false}
                  isMobile={isMobile ? true : false}
                  isMobileLandscape={isMobileLandscape ? true : false}
                  isIpadLandscape={isIpadLandscape ? true : false}
                  isTl7={isTl7 ? true : false}
                  sx={{ color: "#555555" }}
                >
                  <Styled.WriteQuizImg
                    title={intlMessages.pasteIconPlaceholder}
                    isMobileLandscape={isMobileLandscape ? true : false}
                    isIpadLandscape={isIpadLandscape ? true : false}
                    hasText={quizText.length > 0 ? true : false}
                    isT7={isT7 ? true : false}
                    className={"noSelect"}
                    onClick={() => {
                      logEvent(
                        Categories.quiz_from_content,
                        Events.paste_icon_btn_clicked,
                        testingVariation === 0
                          ? Categories.value_color_blue
                          : Categories.value_color_green,
                        testingVariation === 0 ? "blue" : "green"
                      );
                      handleReadClipboard();
                    }}
                    src={
                      testingVariation === 0
                        ? "/svgs/write-quiz-blue.svg"
                        : "/svgs/write-quiz-green.svg"
                    }
                  ></Styled.WriteQuizImg>
                  {intlMessages.placeHolderText1}
                  {/* <span id={"sample-text-btn"} onClick={isMobile || 
                    isMobileLandscape ? handleMobileDropDownOpen : handleDropDownOpen}
                     style={{ color: '#2161BB', zIndex: 5, cursor: 'pointer',position:'relative' }}>
                     {'\t'+intlMessages.placeHolderText3}</span>
                     {'.'}{intlMessages.placeHolderText2} {isMobileLandscape? intlMessages.placeHolderText4:null} */}
                  <Styled.PlaceholderDivider
                    isIpadLandscape={isIpadLandscape ? true : false}
                    isMobileLandscape={isMobileLandscape ? true : false}
                    isT7={isT7 ? true : false}
                    orientation="horizontal"
                  >
                    {intlMessages.quizInputBoxPlaceholderText3}
                  </Styled.PlaceholderDivider>
                  {/* <span>
                    {intlMessages.placeHolderText2}{" "}
                    <span
                      id={"sample-text-btn"}
                      onClick={
                        isMobile || isMobileLandscape
                          ? handleMobileDropDownOpen
                          : handleDropDownOpen
                      }
                      style={{
                        color: "#004ECA",
                        zIndex: 5,
                        cursor: "pointer",
                        position: "relative",
                      }}
                    >
                      {"\t" + intlMessages.placeHolderText3}
                    </span>
                  </span>
                  <span
                    onClick={openImportFileModal}
                    style={{
                      color: "#004ECA",
                      zIndex: 5,
                      cursor: "pointer",
                      position: "relative",
                    }}
                  >
                    {"\t" + intlMessages.importModalHeadersmall}
                    {renderFileInputBox("file-upload-btn")}
                  </span>
                  <span>
                    {intlMessages.quizInputBoxPlaceholderText3}{" "}
                    <span
                      onClick={handleOpenWebURLModal}
                      style={{
                        color: "#004ECA",
                        zIndex: 5,
                        cursor: "pointer",
                        position: "relative",
                      }}
                    >
                      {"\t" + intlMessages.placeHolderText4}
                    </span>
                  </span> */}
                  <Styled.PlaceholderIconBtn
                    isT7={isT7 ? true : false}
                    isVariationEnabled={testingVariation === 0 ? false : true}
                    disableRipple
                    component="label"
                    onClick={
                      isMobile || isMobileLandscape
                        ? handleMobileDropDownOpen
                        : handleDropDownOpen
                    }
                  >
                    <img
                      style={{
                        height: "15px",
                        width:
                          isMobile ||
                          isMobileLandscape ||
                          isIpad ||
                          isIpadLandscape
                            ? "13px"
                            : "unset",
                        marginRight: "5px",
                      }}
                      src={
                        testingVariation === 0
                          ? "/svgs/sample-text-blue-icon.svg"
                          : "/svgs/sample-text-green-icon.svg"
                      }
                    />
                    {intlMessages.placeHolderText2}
                  </Styled.PlaceholderIconBtn>
                  {renderFileInputBox("file-upload-btn")}
                  <Styled.PlaceholderIconBtn
                    isT7={isT7 ? true : false}
                    isVariationEnabled={testingVariation === 0 ? false : true}
                    disableRipple
                    onClick={openImportFileModal}
                    component="label"
                  >
                    <img
                      style={{
                        height: "15px",
                        width:
                          isMobile ||
                          isMobileLandscape ||
                          isIpad ||
                          isIpadLandscape
                            ? "13px"
                            : "unset",
                        marginRight: "5px",
                      }}
                      src={
                        testingVariation === 0
                          ? "/svgs/upload-file-blue-icon.svg"
                          : "/svgs/upload-file-green-icon.svg"
                      }
                    />
                    {intlMessages.placeHolderText3}
                  </Styled.PlaceholderIconBtn>
                  <Styled.PlaceholderIconBtn
                    isT7={isT7 ? true : false}
                    isVariationEnabled={testingVariation === 0 ? false : true}
                    disableRipple
                    onClick={handleOpenWebURLModal}
                    component="label"
                  >
                    <img
                      style={{
                        height: "15px",
                        width:
                          isMobile ||
                          isMobileLandscape ||
                          isIpad ||
                          isIpadLandscape
                            ? "13px"
                            : "unset",
                        marginRight: "5px",
                      }}
                      src={
                        testingVariation === 0
                          ? "/svgs/web-url-blue-icon.svg"
                          : "/svgs/web-url-green-icon.svg"
                      }
                    />
                    {intlMessages.placeHolderText4}
                  </Styled.PlaceholderIconBtn>
                </Styled.placeHolderDivText>
                <Styled.InputTextField
                  id="quiz-input-field"
                  variant="outlined"
                  isIos={iOS() ? true : false}
                  isMobile={isMobile ? true : false}
                  fullWidth
                  error
                  multiline
                  rows={20}
                  mobileHeight={quizSettingMobileHeight}
                  mainBoxHeight={
                    isMobile
                      ? mainBoxHeight - quizSettingMobileHeight
                      : mainBoxHeight - quizSettingHeight
                  }
                  isKeyboardOpen={isKeyboardOpen}
                  onKeyDown={(e) => {
                    if (e.ctrlKey && e.keyCode == 90) {
                      if (!quizText.trim()) {
                        e.preventDefault();
                        setQuizText("");
                      }
                    }
                  }}
                  sx={{
                    ...Styled.customStyling({
                      isError: errors.quizTextError,
                      isWarning: errors.quizTextWarning,
                      iskeyboardopen: isKeyboardOpen,
                    }).inputFieldCustom,
                  }}
                  hiddenLabel
                  value={quizText}
                  onChange={(e) => onChangeQuizText(e.target.value)}
                  helperText={errors.quizTextError || errors.quizTextWarning}
                  iserror={errors.quizTextError}
                  iswarning={errors.quizTextWarning}
                />
              </Styled.PlaceHolderArea>
            </Slide>
            <QuizSettings
              isMobile={isMobile ? true : false}
              quizText={quizText}
              isMobileLandscape={isMobileLandscape ? true : false}
              isIpad={isIpad ? true : false}
              isIpadLandscape={isIpadLandscape ? true : false}
              isT7={isT7 ? true : false}
            ></QuizSettings>
            {/* {isMobile || isIpad ? (
              <Styled.MobileSentenceBox
                isIos={iOS()}
                isMobileLandscape={isMobileLandscape ? true : false}
                isIpad={isIpad ? true : false}
                iskeyboardopen={isKeyboardOpen ? true : false}
              >
                <Styled.SentenceAndWordCount
                  sx={{
                    color: " #777777",
                    display: "flex",
                    alignItems: "center",
                    textAlign: "start",
                    ml: "3%",
                    fontSize: { xs: "0.7rem" },
                  }}
                >
                  {getSentenceCount(quizText)} {intlMessages.sentencesLabel} |{" "}
                  {getWordCount(quizText)} {intlMessages.wordsLabel}
                </Styled.SentenceAndWordCount>
              </Styled.MobileSentenceBox>
            ) : null} */}
            <Styled.createQuizBox
              ref={mobileSentenceBoxRef}
              isIpad={isIpad ? true : false}
              position={quizText ? true : false}
              iskeyboardopen={isKeyboardOpen ? true : false}
              isios={iOS() ? true : false}
            >
              {quizText && !isIpad ? (
                <ImportMenu
                  isMobile={isMobile ? true : false}
                  isMobileLandscape={isMobileLandscape ? true : false}
                  isIpad={isIpad ? true : false}
                  handleMenuItemSelection={handleImportMenuItemSelection}
                ></ImportMenu>
              ) : null}
              <Styled.CreateQuizBtnDesktopBox
                isMobileLandscape={isMobileLandscape ? true : false}
                order={isquizLandscapeBackBtnClicked ? true : false}
              >
                <Styled.CreateQuizButton
                  className={!isMobile && "second-step"}
                  variant="contained"
                  color="secondary"
                  id="create-btn"
                  ref={createQuizBtnRef}
                  isDisabled={disableCreateBtn ? true : false}
                  disabled={disableCreateBtn}
                  order={isquizLandscapeBackBtnClicked ? true : false}
                  onClick={() => {
                    if (isquizLandscapeBackBtnClicked) {
                      dispatch(setShowQuizContentPage(true));
                      return false;
                    }
                    if (
                      userData?.subscriptionPlan === "Basic" &&
                      enableShareMode === true &&
                      isquizLandscapeBackBtnClicked === false
                    ) {
                      setMsg({
                        message: [
                          intlMessages.basicPlanShareModeWarningText,
                          intlMessages.basicPlanShareModeWarningText2,
                          intlMessages.basicPlanShareModeWarningText3,
                        ],
                        icon: "/svgs/warning.svg",
                        isError: null,
                      });
                      setOpenAlertBox(true);
                      return;
                    }
                    if (isPassedValidationsForCreatingQuiz()) {
                      // if(getWordCount(quizText) > MAX_QUIZ_WORDS){
                      //   return handleOpenMaxWordsWarningModal();
                      // }

                      const invalidParas = getInvalidParagraphsOfText(
                        quizText,
                        MIN_QUIZ_WORDS
                      );
                      setInvalidParagraphs(invalidParas);
                      if (invalidParas) {
                        return handleOpenWarningModal();
                      } else if (
                        getWordCount(quizText) > quizContentLimit.contentLimit
                      ) {
                        return handleOpenMaxWordsWarningModal();
                      }
                      createQuiz();
                    }
                  }}
                  loading={isQuizLoaded}
                  loadingPosition="start"
                  isquizloading={isQuizLoaded ? "true" : undefined}
                  startIcon={<AddIcon sx={{ display: "none" }} />}
                >
                  {(!isMobileLandscape && intlMessages.createQuizLabel) ||
                    (isMobileLandscape &&
                      !isQuizLoading &&
                      !isquizLandscapeBackBtnClicked &&
                      intlMessages.createQuizLabel) ||
                    (isMobileLandscape &&
                      !isQuizLoading &&
                      isquizLandscapeBackBtnClicked &&
                      intlMessages.gotoQuizLabel)}
                </Styled.CreateQuizButton>
                <Button
                  sx={{
                    textTransform: "none",
                    marginLeft: 1,
                    display: !quizText ? "none" : "flex",
                  }}
                  variant="outlined"
                  color="secondary"
                  onClick={clearQuizText}
                >
                  {intlMessages.clearLabel}
                </Button>
              </Styled.CreateQuizBtnDesktopBox>
              <Styled.CreateQuizBtnMobileBox>
                <Button
                  sx={{
                    textTransform: "none",
                    marginRight: 1,
                    display: !quizText ? "none" : "flex",
                  }}
                  variant="outlined"
                  color="secondary"
                  onClick={clearQuizText}
                >
                  {intlMessages.clearLabel}
                </Button>
                <Styled.RightArrowBtn
                  variant="contained"
                  color="secondary"
                  className={isMobile && "second-step"}
                  isDisabled={disableCreateBtn ? true : false}
                  disabled={disableCreateBtn}
                  onClick={() => {
                    if (isquizLandscapeBackBtnClicked) {
                      dispatch(setShowQuizContentPage(true));
                      return false;
                    }
                    if (
                      userData?.subscriptionPlan === "Basic" &&
                      enableShareMode === true &&
                      isquizLandscapeBackBtnClicked === false
                    ) {
                      setMsg({
                        message: [
                          intlMessages.basicPlanShareModeWarningText,
                          intlMessages.basicPlanShareModeWarningText2,
                          intlMessages.basicPlanShareModeWarningText3,
                        ],
                        icon: "/svgs/warning.svg",
                        isError: null,
                      });
                      setOpenAlertBox(true);
                      return;
                    }
                    if (isPassedValidationsForCreatingQuiz()) {
                      const invalidParas = getInvalidParagraphsOfText(
                        quizText,
                        MIN_QUIZ_WORDS
                      );
                      setInvalidParagraphs(invalidParas);
                      if (invalidParas) {
                        return handleOpenWarningModal();
                      } else if (
                        getWordCount(quizText) > quizContentLimit.contentLimit
                      ) {
                        return handleOpenMaxWordsWarningModal();
                      }
                      createQuiz();
                    }
                  }}
                  loading={isQuizLoading}
                  loadingPosition="start"
                  isquizloading={isQuizLoading ? "true" : undefined}
                  startIcon={<AddIcon sx={{ display: "none" }} />}
                  isarrow={isquizLandscapeBackBtnClicked}
                >
                  {!isquizLandscapeBackBtnClicked
                    ? intlMessages.createQuizLabel
                    : intlMessages.gotoQuizLabel}
                </Styled.RightArrowBtn>
              </Styled.CreateQuizBtnMobileBox>
            </Styled.createQuizBox>
          </Styled.ContentInputMainBox>
        </Styled.MainContentContainer>
      </Styled.ContentGridBox>
    );
  };

  const renderQuizQuestionAnswersBox = () => {
    return (
      <Styled.QuizContent
        className={!isMobile && !isMobileLandscape && "third-step"}
        mainBoxHeight={isMobileLandscape ? mainBoxHeight : mainBoxHeight}
        sx={{
          display: !isQuizCreating ? "none" : "block",
        }}
        id={"scrollable-quiz-content-box"}
        isvisibleactionbox={!showQuizExportBox ? false : true}
        isquizloading={isQuizLoading ? true : false}
        onScroll={() => {
          // if (isMobile || isMobileLandscape) {
          //   document.activeElement.blur();
          // }
        }}
      >
        {isQuizLoaded === false &&
          quizData.questionAndAnswers.map((obj, i) => {
            const isErrorInQuestion = obj.isChecked && !obj?.question.trim();
            const isVisibleActionBtn = showActionBtns === i;
            const anyCorrectAns = obj.isChecked
              ? isAnyCorrectAnswer(obj.answers)
              : true;
            return (
              <Styled.QuestionAnswersBox
                // className={
                //   i == 1 && isMobile && !isMobileLandscape && "third-step"
                // }
                key={i + "-Question-Answer-box"}
                id={i + "-Question-Answer-box"}
                onMouseOver={() => setShowActionBtns(i)}
                onMouseLeave={() => setShowActionBtns(false)}
                showactionbtns={isVisibleActionBtn && obj.answers.length > 0}
              >
                <Styled.FlexGrid key={i + "-Question-Answer-grid"}>
                  <Box style={{ display: "flex" }}>
                    <Styled.QuestionCountCheckBox
                      color="secondary"
                      sx={{
                        padding: 0,
                        mr: { sm: 2.1, xs: 1 },
                        ml: { xs: 0, md: 0 },
                        alignItems: "flex-start",
                        mt: 1,
                        height: "20px",
                      }}
                      checkedIcon={
                        <Styled.StyledUnCheckedIcon>
                          <CheckOutlinedIcon
                            style={{
                              width: "20px",
                              height: "20px",
                              color: "white",
                            }}
                          />
                        </Styled.StyledUnCheckedIcon>
                      }
                      icon={
                        <Styled.StyledUnCheckedIcon
                          sx={{
                            backgroundColor: "unset",
                            border: "2px solid #626361",
                          }}
                        ></Styled.StyledUnCheckedIcon>
                      }
                      checked={obj.isChecked ?? false}
                      onChange={() => onChangeQuestionCheckBox(i)}
                      onClick={() => question_check_click(obj.isChecked)}
                    />
                    <Styled.QuestionLabel variant="p">
                      {`${intlMessages.questionLabel}: `}
                    </Styled.QuestionLabel>
                  </Box>
                  <Styled.QuestionAnswerTextField
                    style={{ display: "flex" }}
                    className={
                      isMobileLandscape && i == 0 ? "third-step" : null
                    }
                    key={i}
                    maxRows={3}
                    multiline={true}
                    value={obj.question}
                    sx={Styled.customStyling().questionAnswerCustom}
                    iscorrect={false}
                    // defaultValue={obj.question+'  hello   '}
                    helperText={
                      isErrorInQuestion && errors.emptyQuestionAnswerError
                        ? intlMessages.emptyTextError
                        : !anyCorrectAns
                        ? intlMessages.selectCorrectAnswerError
                        : obj?.answers.length < 2
                        ? intlMessages.selectOptionError
                        : null
                    }
                    error={
                      (isErrorInQuestion && errors.emptyQuestionAnswerError) ||
                      !anyCorrectAns ||
                      obj?.answers.length < 2
                        ? true
                        : false
                    }
                    onChange={(e) => {
                      onChangeQuestionTextHandler(i, e.target.value);
                    }}
                  />
                  {
                    <div
                      style={{
                        marginLeft:
                          isVisibleActionBtn && obj.answers.length > 0 ? 19 : 6,
                      }}
                    ></div>
                  }
                </Styled.FlexGrid>

                <Styled.FlexGrid sx={{ display: "flex", marginTop: 0 }}>
                  <Styled.AnswerLabel
                    showactionbtns={
                      isVisibleActionBtn && obj.answers.length > 0
                    }
                    variant="p"
                  >
                    {`${intlMessages.answersLabel}: `}
                  </Styled.AnswerLabel>
                  <Styled.AnswersBox
                    className={isMobile && i == 1 ? "third-step" : null}
                    isnoanswer={obj.answers.length > 0 ? false : true}
                  >
                    {obj.answers.length === 0 && (
                      <IconButton
                        aria-label="AddAnswer"
                        sx={{ p: 0, background: "transparent" }}
                        style={{ backgroundColor: "transparent" }}
                        onClick={() => addNewAnswerOfQuestion(i)}
                        disableRipple
                        color="primary"
                      >
                        <AddIcon
                          color="primary"
                          disabled={
                            obj.answers.length === MAX_ALLOWED_ANSWERS
                              ? true
                              : false
                          }
                          style={{
                            padding: 2,
                            marginTop: 7,
                            marginLeft: 5,
                            background: "transparent",
                          }}
                        />
                      </IconButton>
                    )}
                    {obj.answers.map((ans, ansIndex) => {
                      const isNewAnswer = ans.isNew;
                      const isErrorInAnswer =
                        !obj.answers.length < 2 &&
                        !isNewAnswer &&
                        obj.isChecked &&
                        !ans.value.trim() &&
                        errors.emptyQuestionAnswerError;
                      const isAddBtnDisabled =
                        obj.answers.length === MAX_ALLOWED_ANSWERS;
                      return (
                        <Styled.AnswerAndIconBox key={i + ansIndex}>
                          {isVisibleActionBtn && (
                            <Radio
                              color="secondary"
                              sx={{
                                padding: 0,
                                mr: { xs: 0 },
                                alignItems: "flex-start",
                                transform: "scale(0.8)",
                                mt: 1.2,
                                marginBottom: isErrorInAnswer && 2.7,
                              }}
                              checked={ans.is_correct ?? false}
                              onChange={() => changeCorrectAnswer(i, ansIndex)}
                            />
                          )}
                          <Styled.QuestionAnswerTextField
                            sx={Styled.customStyling().questionAnswerCustom}
                            value={ans.value}
                            onChange={(e) =>
                              onChangeQuestionAnswersHandler(
                                i,
                                ansIndex,
                                e.target.value
                              )
                            }
                            helperText={
                              isErrorInAnswer
                                ? intlMessages.emptyTextError
                                : null
                            }
                            maxRows={2}
                            multiline={true}
                            autoFocus={isNewAnswer}
                            error={isErrorInAnswer ? true : false}
                            iscorrect={ans.is_correct}
                          />
                          {ansIndex === obj.answers.length - 1 && (
                            <IconButton
                              aria-label="AddAnswer"
                              sx={{ p: 0 }}
                              style={{ backgroundColor: "transparent" }}
                              color="primary"
                              disabled={isAddBtnDisabled ? true : false}
                              onClick={() => addNewAnswerOfQuestion(i)}
                            >
                              <AddIcon
                                color="primary"
                                style={{
                                  padding: 2,
                                  marginTop: 7,
                                  marginLeft: 5,
                                  color: isAddBtnDisabled && "silver",
                                  marginBottom: isErrorInAnswer ? 25 : 2,
                                  background: "transparent",
                                }}
                              />
                            </IconButton>
                          )}
                          {isVisibleActionBtn && (
                            <IconButton
                              aria-label="deleteAnswer"
                              sx={{
                                p: 0,
                                pb: 0.7,
                                pr: 0,
                                alignItems: "flex-start",
                                mb: isErrorInAnswer && 2.4,
                              }}
                              style={{ backgroundColor: "transparent" }}
                              color="primary"
                              onClick={() =>
                                deleteAnswerFromQuesion(i, ansIndex)
                              }
                            >
                              <Styled.ImgButton
                                src={"/svgs/Delete.svg"}
                                color="primary"
                                style={{
                                  padding: 0,
                                  marginTop: 8,
                                  marginLeft:
                                    ansIndex === obj.answers.length - 1 ? 2 : 7,
                                }}
                              />
                            </IconButton>
                          )}
                        </Styled.AnswerAndIconBox>
                      );
                    })}
                  </Styled.AnswersBox>
                </Styled.FlexGrid>
              </Styled.QuestionAnswersBox>
            );
          })}
        {isQuizLoaded && (
          <Styled.CircularLoaderBox sx={{ display: "flex" }}>
            <CircularProgress color="primary" />
          </Styled.CircularLoaderBox>
        )}
      </Styled.QuizContent>
    );
  };

  const acceptedFormats = () => {
    const formats = [];
    const validExtensions = [];
    if (canImportTextFromSystem.enable) {
      formats.push(".txt");
      validExtensions.push("text/plain");
    }
    if (canUploadPDF.enable) {
      formats.push(".pdf");
      validExtensions.push("application/pdf");
    }
    if (canUploadWordDoc.enable) {
      formats.push(".doc");
      validExtensions.push("application/msword");
    }
    if (canUploadGoogleDoc.enable) {
      formats.push(".docx");
      validExtensions.push(
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
      );
    }
    if (canUploadPowerPointSlide.enable) {
      formats.push(".ppt");
      validExtensions.push("application/vnd.ms-powerpoint");
    }
    if (canUploadGoogleSlide.enable) {
      formats.push(".pptx");
      validExtensions.push(
        "application/vnd.openxmlformats-officedocument.presentationml.presentation"
      );
    }
    if (canUploadExcelSheet.enable) {
      formats.push(".xls");
      validExtensions.push("application/vnd.ms-excel");
    }
    if (canUploadGoogleSheet.enable) {
      formats.push(".xlsx");
      validExtensions.push(
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      );
    }
    if (canUploadImage.enable) {
      canUploadImage.supportedFormats.forEach((element) =>
        formats.push(element)
      );
      validExtensions.push("image/jpeg");
      validExtensions.push("image/png");
    }
    return {
      formats,
      validExtensions,
    };
  };

  const renderFileInputBox = (id) => {
    const supportedFormats = acceptedFormats().formats.toString();
    return (
      <input
        hidden
        accept={supportedFormats}
        type="file"
        onChange={(e) => {
          const field = document.getElementById("quiz-input-field");
          field && field.blur();
          setIsUpload(false);
          readTextFile(e);
        }}
        id={id}
        ref={inputFile}
        onClick={(e) => {
          setIsUpload(true);
          logEvent(Categories.content_for_quiz, Events.upload_file_click);
          e.target.value = null;
          // if (quizText.trim().length > 0 && !openNewFileImportModal) {
          //   e.preventDefault();
          //   handleOpenNewFileImportModal();
          //   logEvent(Categories.content_for_quiz, Events.upload_file);
          // }
        }}
      />
    );
  };

  const renderSampleTextModal = () => {
    return (
      <Styled.SampleTextModal open={openDropDown}>
        <DialogTitle>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <Typography
              variant="h3"
              component={"h3"}
              sx={{
                color: "black",
                textAlign: "center",
                fontWeight: "bold",
                ml: { lg: "31%", md: 0 },
              }}
            >
              {intlMessages.quizImportSampleTextModelLabel}
            </Typography>
            <IconButton
              aria-label="close"
              onClick={handleDropDownClose}
              sx={{
                mt: -1.3,
                mr: -2,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon></CloseIcon>
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent sx={{ backgroundColor: "#F8F8F8" }}>
          {sampleTextList.map((element, index) => (
            <Box
              onClick={() => {
                clearQuizText();
                importSampleText(index);
              }}
              key={index}
              sx={{
                pl: 3,
                pr: 3,
                pt: 1,
                pb: 1.3,
                display: "grid",
                cursor: "pointer",
                "&:hover": { color: "#7DC242" },
              }}
            >
              <Typography variant="h5" sx={{ fontWeight: "bold", mb: 1 }}>
                {element.title}
              </Typography>
              <Typography variant="p" sx={{ width: "90%" }}>
                {element.summary}
              </Typography>
              {index != sampleTextList.length - 1 ? (
                <Styled.Seperator
                  orientation="horizontal"
                  sx={{ width: "100%", height: "2px", ml: -0.1, mt: 1, mb: 0 }}
                ></Styled.Seperator>
              ) : null}
            </Box>
          ))}
        </DialogContent>
      </Styled.SampleTextModal>
    );
  };

  const renderQuizContentBox = () => {
    const selectedQuizsCount = getSelectedQuizsCount(
      quizData.questionAndAnswers
    );
    const quizsCount = quizData?.questionAndAnswers?.length;
    return (
      <Styled.ContentGridBox iscontentbox={isQuizLoading ? false : true} item>
        <Styled.MainContentContainer
          isMobile={isMobile ? true : false}
          mainBoxHeight={mainBoxHeight}
        >
          <Styled.BoxHeader
            sx={{ justifyContent: isIpad ? "flex-end" : "space-between" }}
          >
            {isIpad ? null : (
              <Styled.HeaderLabels variant="p" ml={2} componenet="p">
                {enableEditMode
                  ? intlMessages.quizLibraryEditText
                  : intlMessages.quizGenerationBoxLeftHeader}
              </Styled.HeaderLabels>
            )}
            {isIpad
              ? null
              : enableEditMode && (
                  <Styled.HeaderLabels
                    sx={{ fontWeight: "600" }}
                    variant="p"
                    ml={2}
                    componenet="p"
                  >
                    {intlMessages.quizEditModeText}
                  </Styled.HeaderLabels>
                )}
            <Styled.HeaderLabels variant="p" ml={2} componenet="p">
              {`${intlMessages.quizGenerationBoxRightHeader}: ${
                isQuizLoaded === true ? 0 : quizsCount
              }`}
            </Styled.HeaderLabels>
          </Styled.BoxHeader>
          <Styled.PlaceHolderArea
            mobileHeight={quizSettingMobileHeight}
            mobileLandscapeHeight={quizSettingHeight}
            mainBoxHeight={mainBoxHeight}
            sx={{ display: isQuizCreating ? "none" : "block" }}
            img={"Quiz"}
          >
            {/* <Box sx={{height:"9px"}}></Box> */}
            <Styled.NoQuizText
              mobileHeight={quizSettingMobileHeight}
              isMobile={isMobile ? true : false}
              isMobileLandscape={isMobileLandscape ? true : false}
              desktopHeight={quizSettingHeight}
              isIpad={isIpad ? true : false}
              isIpadLandscape={isIpadLandscape ? true : false}
              className={"noSelect"}
              isT7={isT7 ? true : false}
            >
              {intlMessages.quizGenerationBoxPleaceholder}
            </Styled.NoQuizText>
          </Styled.PlaceHolderArea>
          <Styled.SelectTextBox
            sx={{
              display: !isQuizCreating > 0 ? "none" : "flex",
              alignItems: isMobile ? "space-between" : "unset",
              justifyContent: isMobile ? "space-between" : "unset",
              mr: isMobile ? "10px" : "unset",
              ml: { sm: 1.2, md: 1.4 },
            }}
          >
            <Box
              sx={{
                mr: 2,
                ml: { xs: 1.3, sm: 0 },
                display: (!isQuizCreating || isQuizLoading) && "none",
              }}
            >
              <FormControlLabel
                sx={{ display: isQuizLoaded === true ? "none" : "block" }}
                control={
                  <Styled.QuestionCountCheckBox
                    sx={{
                      padding: 0,
                      // mr: 0.8,
                      // ml: { sm: 1.2, md: 1.4 },
                      alignItems: "flex-start",
                    }}
                    checkedIcon={
                      <Styled.StyledUnCheckedIcon>
                        <CheckOutlinedIcon
                          style={{
                            width: "20px",
                            height: "20px",
                            color: "white",
                          }}
                        />
                      </Styled.StyledUnCheckedIcon>
                    }
                    icon={
                      selectedQuizsCount > 0 ? (
                        <Styled.StyledUnCheckedIcon>
                          <RemoveIcon
                            style={{
                              width: "20px",
                              height: "20px",
                              color: "white",
                            }}
                          />
                        </Styled.StyledUnCheckedIcon>
                      ) : (
                        <Styled.StyledUnCheckedIcon
                          sx={{
                            backgroundColor: "unset",
                            border: "2px solid #626361",
                          }}
                        ></Styled.StyledUnCheckedIcon>
                      )
                    }
                    checked={isSelectedAllQuestions}
                    onChange={onChangeSelectAllQuestions}
                    onClick={onClickSelectAl}
                  />
                }
                label={
                  <Typography
                    color="secondary.light"
                    variant="p"
                    sx={{
                      height: "100%",
                      alignItems: "center",
                      ml: 0.8,
                    }}
                  >
                    {isSelectedAllQuestions == true
                      ? `${intlMessages.allQuestionsSelected}`
                      : selectedQuizsCount > 0
                      ? `${intlMessages.selectedQuizsCount}: ${selectedQuizsCount}`
                      : `${intlMessages.notSelectedQuestions}`}
                  </Typography>
                }
              />
            </Box>
            <Typography
              color="secondary.light"
              variant="p"
              sx={{
                height: "100%",
                fontSize: "12px",
                fontWeight: "400",
                color: "#363638",
                alignItems: "center",
                mt: 0.5,
                display:
                  (isMobileLandscape || isMobile) && !isQuizLoading
                    ? "block"
                    : "none",
              }}
            >
              {`${intlMessages.quizGenerationMobileBoxRightHeader}: ${quizsCount}`}
            </Typography>
          </Styled.SelectTextBox>

          {isMobile ? (
            <Slide
              direction={"right"}
              timeout={{ enter: 500 }}
              in={isQuizCreating}
              mountOnEnter
              unmountOnExit
              appear={isMobile && !isQuizLoading ? true : false}
            >
              {renderQuizQuestionAnswersBox()}
            </Slide>
          ) : (
            renderQuizQuestionAnswersBox()
          )}
          <Styled.QuizActionsBox
            isIpad={isIpad ? true : false}
            isIos={iOS()}
            isvisible={
              isQuizLoaded === true
                ? false
                : isMobileLandscape
                ? false
                : quizData.questionAndAnswers.find(
                    (question) => question.isChecked
                  )
                ? true
                : false
            }
          >
            {/* <Button
                  sx={{
                    textTransform: "none",
                    marginRight: 1,
                    display: !quizText ? "none" : "flex",
                  }}
                  variant="outlined"
                  color="secondary"
                  onClick={handleOpenShareModal}
                >
                  {isMobile? intlMessages.shareLabel : intlMessages.sharequizLabel}
                </Button> */}
            {!isMobile && !isMobileLandscape && (
              <ExportMenu
                handleMenuItemSelection={handleExportMenuItemSelection}
                isMobileLandscape={isMobileLandscape ? true : false}
                isMobile={isMobile ? true : false}
                isIpad={isIpad ? true : false}
                isIpadLandscape={isIpadLandscape ? true : false}
              />
            )}
            {renderFileInputBox("file-upload-btn-header")}
          </Styled.QuizActionsBox>
          {/* for mobile */}
          <Styled.MobileQuizActionsBox
            sx={{ display: !isQuizLoading ? "flex" : "none" }}
            id="mobile-quiz-actions-bar"
          >
            <Styled.LeftArrowBtn
              variant="contained"
              color="secondary"
              onClick={() => closeQuizContentBox()}
            >
              {intlMessages.back}
            </Styled.LeftArrowBtn>
            <Box
              style={{
                display: !quizData.questionAndAnswers.find(
                  (question) => question.isChecked
                )
                  ? "none"
                  : "flex",
                alignItems: "center",
              }}
            >
              {/* <Button
                  sx={{
                    textTransform: "none",
                    marginRight: 1,
                    display: !quizText ? "none" : "flex",
                  }}
                  variant="outlined"
                  color="secondary"
                  onClick={handleOpenShareModal}
                >
                  {isMobile? intlMessages.shareLabel : intlMessages.sharequizLabel}
                </Button> */}
              {isMobile && !isMobileLandscape && (
                <ExportMenu
                  handleMenuItemSelection={handleExportMenuItemSelection}
                  isMobileLandscape={isMobileLandscape ? true : false}
                  isMobile={isMobile ? true : false}
                  isIpad={isIpad ? true : false}
                  isIpadLandscape={isIpadLandscape ? true : false}
                />
              )}
            </Box>
          </Styled.MobileQuizActionsBox>
        </Styled.MainContentContainer>
      </Styled.ContentGridBox>
    );
  };

  return (
    <>
      {isLoading ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            pt: isMobile ? "50%" : isIpad ? "43%" : "15%",
            height: `${mainBoxHeight - 40}px`,
          }}
        >
          <BounceLoader color="#6dd615" size={"150px"} />
        </Box>
      ) : (
        showMainDiv && (
          <Fade in={true} timeout={1000}>
            <Styled.MainContainer
              sx={{
                // mb: isMobileLandscape ? "unset" : "2.9%",
                width: isMobileLandscape ? "100%" : "unset",
                ml: isMobileLandscape ? 0 : "52px",
              }}
            >
              <Box
                sx={{
                  flexGrow: 1,
                  height: isMobile && "100%",
                  display: isMobileLandscape ? "flex" : "unset",
                  flexDirection: isMobileLandscape ? "row" : "unset",
                }}
              >
                <Grid
                  container
                  spacing={0.2}
                  sx={{ height: isMobile && "100%" }}
                >
                  {((!showQuizContentPage && (isMobile || isMobileLandscape)) ||
                    !(isMobile || isMobileLandscape)) &&
                    renderQuizInputBox()}
                  {((showQuizContentPage && (isMobile || isMobileLandscape)) ||
                    !(isMobile || isMobileLandscape)) &&
                    renderQuizContentBox()}
                </Grid>
                {isMobileLandscape && (
                  <GenerateQuizMobileLandscapeHeader
                    handleExportMenuItemSelection={
                      handleExportMenuItemSelection
                    }
                    backClickHandler={closeQuizContentBox}
                    sentenceCount={getSentenceCount(quizText).toString()}
                    isQuizPage={isQuizPage}
                    wordCount={getWordCount(quizText).toString()}
                    isMobileLandscape={isMobileLandscape}
                    mainBoxHeight={mainBoxHeight}
                    isIpad={isIpad}
                    isAboutPage={isAboutPage}
                    isIpadLandscape={isIpadLandscape}
                    isMobile={isMobile}
                    isSignedIn={isSignedIn}
                    isSignUpPage={isSignUpPage}
                    isGoogleForm={isGoogleForm}
                    isGoogleDrive={isGoogleDrive}
                    isLoginPage={isLoginPage}
                    userNameData={userNameData}
                    isTl={isTl7}
                    handleOpenShareModal={handleOpenShareModal}
                  />
                )}
              </Box>
            </Styled.MainContainer>
          </Fade>
        )
      )}
      {renderExportModal()}
      {renderSnackBar()}
      {renderWarningModal()}
      {renderWarningNewWebUrlImportModal()}
      {renderCopyClipboardModal()}
      {renderDownloadOptionsModal()}
      {renderWarningNewFileImportModal()}
      {renderWarningSampleTextImportModal()}
      {renderMaxInputWarningModal()}
      {renderSampleTextModal()}
      {renderAlertBoxForFile()}
      {/* {renderCamera()} */}
      {
        <SampleTextMobileModal
          open={openSampleTextMobileModal}
          sampleTextList={sampleTextList}
          onClose={() => {
            setOpenSampleTextMobileModal(false);
            dispatch(setShowSampleTextModal(false));
          }}
          onSelectSampleText={(index) => {
            clearQuizText();
            importSampleText(index);
          }}
        />
      }
      {/* quizData.questionAndAnswers.length === 0 */}
      {getToken() === null &&
        verified === false &&
        openUserInfoModal === false &&
        openWelcomemodal &&
        openAlertBox === false &&
        clipboardFlag === false && (
          <WelcomeModal onClose={(flag) => setOpenwelcomemodal(flag)} />
        )}
      {openReviewModal && (
        <Review
          openConnectionErrorCallback={() => handleOpenConnectionModal()}
          reviewSubmissionCallback={(userReview) =>
            onReviewSubmission(userReview)
          }
          isOpen={openReviewModal}
          closeReviewModalCallback={closeReviewModal}
          isCommentAfterPdf={commentAfterPdf}
        />
      )}
      {verified === false &&
        openUserInfoModal === false &&
        userState !== USER_STATE.BLOCKED_USER_USER &&
        USER_STATE.EXPIRED_USER &&
        openGoogleFormsModal && (
          <GoogleForms
            openConnectionErrorCallback={() => handleOpenConnectionModal()}
            isOpen={openGoogleFormsModal}
            closeGoogleFormModalCallback={(showFeedback) =>
              closeGoogleFormsModal(showFeedback)
            }
            quizData={quizData}
            exportFile={exportFile}
            isMobileLandscape={isMobileLandscape ? true : false}
            isMobile={isMobile ? true : false}
            isIpad={isIpad ? true : false}
            isIpadLandscape={isIpadLandscape ? true : false}
          />
        )}
      {openNameModal && (
        <EmailModal
          openConnectionErrorCallback={() => handleOpenConnectionModal()}
          emailSubmissionCallback={(userEmail, sendEmail) =>
            onEmailSubmission(userEmail, sendEmail)
          }
          isOpen={openNameModal}
          closeEmailModalCallback={closeNameModal}
        />
      )}
      {openSettingsModal && (
        <SettingsModal
          openConnectionErrorCallback={() => handleOpenConnectionModal()}
          isOpen={openSettingsModal}
          closeSettingsModalCallback={closeOpenSettingsModal}
        />
      )}
      {openShareModal && (
        <ShareModal
          openConnectionErrorCallback={() => handleOpenConnectionModal()}
          isOpen={openShareModal}
          closeShareModalCallback={closeOpenShareModal}
          quizData={quizData}
        />
      )}
      {/* {
        isOpenLearninghubPost && (
          <LearninghubPost 
            isOpen={isOpenLearninghubPost}renderQuizInputBox
            closeCallback={() => setIsOpenLearninghubPost(false)}
            showErrorMessageCallback={showErrorMessage}
            handleOpenConnectionModalCallback={() => handleOpenConnectionModal()}
            learningHubConnectedCallBack = {learningHubConnectedCallBack}
            learningHubConnected  = {learningHubConnected}
            quizData={quizData?.questionAndAnswers}
          />
        )
      } */}
      {openConnectionModal && (
        <ConnectionModal
          isOpen={openConnectionModal}
          handleCloseConnectionModal={() => setIsOpenConnectionModal(false)}
        />
      )}
      {verified &&
        userState !== USER_STATE.BLOCKED_USER_USER &&
        USER_STATE.EXPIRED_USER && (
          <SignUpWelcomeModal
            onClose={() => {
              if (
                getToken() == null ||
                isGoogleForm ||
                userData?.provider == "google" ||
                userData?.provider == "microsoft" ||
                userData?.provider == "local" ||
                isGoogleDrive ||
                (sessionStorage.getItem("save-quiz") &&
                  sessionStorage.getItem("save-quiz") === "true")
              ) {
                dispatch(setIsVerified(false));
                dispatch(setIsShowWelcomeModal(true));
              }
            }}
          />
        )}
      {openLoginAlertBox && (
        <LoginAlertBox
          desc={accountStatus?.desc}
          title={accountStatus?.title}
          purpose={accountStatus?.purpose}
          isOpen={true}
          onClose={() => setIsOpenLoginAlertBox(false)}
        />
      )}
      {openExpiryModal &&
        openPricingModal === false &&
        openPaymentModal === false && (
          <ExpiryModal
            isTl7={isTl7?true:false}
            openConnectionModal={() => setIsOpenConnectionModal(true)}
            onClose={() => dispatch(setOpenExpiryModal(false))}
          />
        )}
      {verified === false &&
        openUserInfoModal === false &&
        userState !== USER_STATE.BLOCKED_USER_USER &&
        USER_STATE.EXPIRED_USER &&
        openTextfieldModal && (
          <TextfieldModal
            title={textfieldData.title}
            label={textfieldData.label}
            placeholder={textfieldData.placeholder}
            emptyErrorMsg={textfieldData.emptyErrorMsg}
            lengthErrorMsg={textfieldData.lengthErrorMsg}
            invalidErrorMsg={textfieldData.invalidErrorMsg}
            pasteURL={textfieldData.pasteURL}
            btn1Text={textfieldData.btn1Text}
            btn2Text={textfieldData.btn2Text}
            open={openTextfieldModal}
            onClose={(flag) => setOpenTextfieldModal(flag)}
            onSubmit={async (flag, title, isURL) => {
              if (isURL === true) {
                setOpenTextfieldModal(flag);
                sendWebURL(title);
                return;
              }
              const quiz = {
                text: quizInputText,
                ques_ans_pairs: quizData.questionAndAnswers,
                id: quizData.quiz_id,
                hash: quizData.quiz_hash,
                questionType:
                  quizType === "Fill in the blanks"
                    ? "MCQ (Fill in the blanks)"
                    : quizType === "True or False"
                    ? "TrueFalse"
                    : "MCQ",
                noOfQuestions:
                  quizCount === "Auto" ? "Auto" : parseInt(quizCount),
                difficultyLevel:
                  quizDifficultyLevel === "Hard"
                    ? "HARD"
                    : quizDifficultyLevel === "Easy"
                    ? "EASY"
                    : "MEDIUM",
              };
              if (enableEditMode) {
                updateLibraryQuiz(title, quiz);
              } else {
                saveQuizToLibrary(quiz, flag, title);
              }
            }}
          />
        )}
      {openExistingQuizWarningModal && (
        <ExisitingQuizWarningModal
          open={openExistingQuizWarningModal}
          onClose={(flag) => setOpenExistingQuizWarningModal(flag)}
          handleOnConfirm={forceUpdateLibraryQuiz}
        />
      )}
      {openAlertBox && (
        <AlertBox
          isIpad={isIpad ? true : false}
          isMobile={isMobile ? true : false}
          isMobileLandscape={isMobileLandscape ? true : false}
          isIpadLandscape={isIpadLandscape ? true : false}
          message={msg}
          loginBtn={false}
          libraryBtn={isLibraryBtn}
          isOpen={openAlertBox}
          onClose={() => {
            dispatch(setIsShowCameraWarning(false));
            setOpenAlertBox(false);
          }}
        />
      )}
      {showMainDiv && !(isMobile || isMobileLandscape) && <Footer />}
      {verified === false &&
        openUserInfoModal === false &&
        userState !== USER_STATE.BLOCKED_USER_USER &&
        USER_STATE.EXPIRED_USER &&
        driveMenu && (
          <ImportFileMain
            openConnectionErrorCallback={() => handleOpenConnectionModal()}
            openImportModal={driveMenu}
            closeImportFileModal={closeImportFileModal}
            isMobile={isMobile}
            selectedTypeCallback={selectedTypeCallback}
            isIpad={isIpad}
            isMobileLandscape={isMobileLandscape}
            isIpadLandscape={isIpadLandscape}
            clearQuizText={clearQuizText}
            isTl7={isTl7}
          ></ImportFileMain>
        )}
      {openSaveModal && (
        <GoogleSignSignupForm
          openGoogleSignSignupForm={openSaveModal}
          closeGoogleFormModal={() => setOpenSaveModal(false)}
          signInparams="save-quiz=true"
          isMobile={isMobile}
        />
      )}
      {verified === false &&
        openUserInfoModal === false &&
        userState !== USER_STATE.BLOCKED_USER_USER &&
        USER_STATE.EXPIRED_USER &&
        openCameraModal && (
          <CameraModal
            open={openCameraModal}
            isIpad={isIpad}
            isIpadLandscape={isIpadLandscape}
            onClose={(flag, permissionFlag, errorMsg) => {
              if (
                permissionFlag !== undefined &&
                permissionFlag === false &&
                errorMsg !== undefined
              ) {
                setIsLibraryBtn(false);
                setMsg({
                  message: errorMsg.includes("Requested device not found")
                    ? intlMessages.cameraNotAvailableErrorMsg
                    : errorMsg.includes("Device in use")
                    ? intlMessages.cameraInUseErrorMsg
                    : errorMsg.includes("Permission denied")
                    ? intlMessages.cameraPermissionErrorMsg
                    : intlMessages.cameraGenericErrorMsg,
                  icon: "/svgs/erroricon.svg",
                  isError: true,
                });
                setOpenAlertBox(true);
                dispatch(setIsShowCameraWarning(true));
              }
              dispatch(setOpenCameraModal(flag));
            }}
            isMobile={isMobile ? true : false}
            isMobileLandscape={isMobileLandscape ? true : false}
            uploadFile={(file) => uploadCapturedImageFile(file)}
          />
        )}
      {verified === false &&
        openUserInfoModal === false &&
        userState !== USER_STATE.BLOCKED_USER_USER &&
        USER_STATE.EXPIRED_USER &&
        loader && (
          <ImportLoaderModal
            openImportLoaderModal={loader}
            isMobile={isMobile}
            isWebURL={isWebUrl ? true : false}
            isImportedFromScrybe={loadContent === true ? true : false}
          />
        )}
      {verified === false &&
        openUserInfoModal === false &&
        userState !== USER_STATE.BLOCKED_USER_USER &&
        USER_STATE.EXPIRED_USER &&
        openPageSelectionModal && (
          <DriveFileContent
            openDriveMenu={openPageSelectionModal}
            closeDriveFileContent={() => {
              setIsOpenPageSelectionModal(false);
            }}
            backToMainMenu={() => {
              setIsOpenPageSelectionModal(false);
              setDriveMenu(true);
            }}
            selectedFile={fileData}
            pdfFileData={fileData}
            isMobile={isMobile}
            errorCallback={(msg, iserror) => {
              setLoader(false);
              setIsLibraryBtn(false);
              if (iserror === null) {
                setMsg({
                  message: msg,
                  icon: "/svgs/warning.svg",
                  isError: null,
                });
                dispatch(setIsShowCameraWarning(true));
              } else {
                setMsg({
                  message: intlMessages.fileErrorMsg,
                  icon: "/svgs/erroricon.svg",
                  isError: true,
                });
              }
              setIsOpenPageSelectionModal(false);
              // clearQuizText()
              setOpenAlertBox(true);
            }}
            clearQuizText={clearQuizText}
            isTl7={isTl7}
          ></DriveFileContent>
        )}
      {openUserInfoModal &&
        verified === false &&
        isShowWelcomeModal == true &&
        userState !== USER_STATE.BLOCKED_USER_USER &&
        USER_STATE.EXPIRED_USER && (
          <UserInformation
            openUserInformationModal={openUserInfoModal}
            closeUserInformationModal={closeOpenUserInfoModal}
            isMobile={isMobile}
            isMobileLandscape={isMobileLandscape}
            isIpad={isIpad}
            isIpadLandscape={isIpadLandscape}
            isFirstTime={isSkipped == 0}
          />
        )}
      {openUpgradeModel && (
        <UpgradeModal
          open={openUpgradeModel}
          onClose={() => dispatch(setOpenUpgradeModel(false))}
          onSubmit={() => dispatch(setOpenPricingModal(true))}
        ></UpgradeModal>
      )}
      {openExportFileModal && (
        <ExportFileModal
          isOpen={openExportFileModal}
          handleExportFile={(filetype) => handleDownloadFile(filetype)}
          onClose={() => {
            setIsOpenExportFileModal(false);
            logEvent(
              Categories.content_for_quiz,
              Events.integrate_file_btn_close_btn_clicked
            );
            handleOpenReviewModal();
          }}
        />
      )}
      {openTrialModal &&
        verified === false &&
        openUserInfoModal === false &&
        userState !== USER_STATE.BLOCKED_USER_USER &&
        USER_STATE.EXPIRED_USER &&
        (isSkipped === 1 || isSkipped===2) && (
          <TrialInfoModal
            text={trialModalText}
            open={openTrialModal}
            onClose={(flag) => {
              setOpenTrialModal(flag);
              searchParams.delete("showStripeSuccess");
              navigate(`?${searchParams.toString()}`);
              localStorage.removeItem("isWelcomeModalShown");
            }}
          ></TrialInfoModal>
        )}
    </>
  );
}

export default Quiz;
