import { useState, useEffect } from "react";
import intlMessages from "../../locales/en";
import { Button, Typography,IconButton,Modal,Box } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Styled from "./styles";

export default function ExisitingQuizWarningModal(props){
  const { open,onClose,handleOnConfirm} = props;

  const handleOnClose = ()=>{
    onClose(false)
  }
  return (
    <>
   <Modal
      open={open}
    >
      <Styled.ModalBox sx={Styled.customStyling.exportModal}>
        <Styled.ModalHeader>
        <Typography
            id="warning-paragraph-modal-title"
            variant="h5"
            component="h5"
            style={{ fontWeight: "bold" }}
          >
            {intlMessages.warningLabel}
          </Typography>
          <IconButton
            aria-label="closeWarningParagraphModal"
            onClick={handleOnClose}
          >
            <CloseIcon color="seondary.light" />
          </IconButton>
        </Styled.ModalHeader>
        <Styled.ModalContentBox>
        <Typography variant="p">
            {intlMessages.quizAlreadyExistWarning}
          </Typography>

          <br></br>
          <Typography variant="p"></Typography>
        </Styled.ModalContentBox>
        <Box>
          <Styled.ModalActions>
          <Button
            sx={{
              textTransform: "none",
              marginLeft: 1,
              boxShadow:'none'
            }}
            variant="contained"
            color="secondary"
            onClick={handleOnConfirm}
          >
            {intlMessages.deleteAccountConfirmBtnText}
          </Button>
          <Button
            sx={{
              textTransform: "none",
              marginLeft: 1,
            }}
            variant="outlined"
            color="secondary"
            onClick={handleOnClose}
          >
            {intlMessages.deleteAccountCancelBtnText}
          </Button>
          </Styled.ModalActions>
        </Box>
      </Styled.ModalBox>
    </Modal>
    </>
  );
}
