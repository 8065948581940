import {
  BrowserRouter,
  Routes,
  Route,
  Navigate,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import Layout from "../layout/component";
import Main from "../quiz/component";
import AboutPage from "../about-page/component";
import { useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import TrialPage from "../trial-page/component";
import SignUp from "../sign-up/component";
import VerifyEmail from "../verify-email/component";
import LogIn from "../log-in/component";
import ForgotPassword from "../forgot-password/component";
import ResetPassword from "../reset-password/component";
import UserProfile from "../user-profile/component";
import { isUserSignedIn } from "../google-forms/service";
import {
  getAuthToken,
  protectedRoutes,
  authUserNotAllowedRoutes,
  API_DELAY_TIME,
  extractBaseTokenFromURL,
} from "../../api/service";
import {
  decodeToken,
  getUserInformation,
  userLogOut,
} from "../../common/sign_in_service";
import {
  setAuthToken,
  setIsScreenSubscription,
  setIsShowWelcomeModal,
  setIsSignedIn,
  setIsSkippedState,
  setRechargeTries,
  setStripeStatus,
  setUserData,
  setUserInformationState,
  setUserState,
} from "../../redux/slices/logInSlice";
import { SPLIT_IO_API_KEY, USER_STATE } from "../../settings";
import {
  setIsLoading,
  setTestingVariation,
} from "../../redux/slices/quizSlice";
import useClarity from "../../useClarity";
import { getNavigateURL } from "../../util/signin-user-data";
import { initAdds } from "../../adds_conversion";
import { setBasicPlanPrice, setProMonthlyPlanPrice, setProYearlyPlanPrice } from "../../redux/slices/paymentSlice";
import { store } from "../../redux/store";

function AppRoutes() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { quizData } = useSelector((state) => state.quiz);
  const { userState, stripeStatus } = useSelector((state) => state.logIn);
  const theme = useTheme();
  const location = useLocation();
  const isAboutPage = location.pathname === "/about";
  const [searchParams] = useSearchParams();
  const isMobileLandscape = useMediaQuery(
    `${theme.breakpoints.down(
      "md"
    )}  and (orientation: landscape) and (hover: none) and (pointer: coarse)`,
    {
      noSsr: true,
    }
  );
  const splitio = require("@splitsoftware/splitio").SplitFactory;
  const generateUserId = () => {
    const bcrypt = require("bcryptjs");
    const salt = bcrypt.genSaltSync(10);
    const randonnumber = bcrypt.hashSync("B4c0//", salt);
    return "user_" + randonnumber;
  };

  useEffect(() => {
    const userId = generateUserId();
    const factory = splitio({
      core: {
        authorizationKey: SPLIT_IO_API_KEY,
        key: userId,
      },
    });
    const client = factory.client();
    client.on(client.Event.SDK_READY, function () {
      const treatment = client.getTreatment("quizwiz_initial_content");
      if (treatment === "green_variation") {
        dispatch(setTestingVariation(1));
      } else {
        dispatch(setTestingVariation(0));
      }
    });
  }, []);

  useEffect(() => {
    const uId = localStorage.getItem("guestId");
    if (uId === null) {
      const bcrypt = require("bcryptjs");
      const salt = bcrypt.genSaltSync(10);
      const randonnumber = bcrypt.hashSync("B4c0//", salt);
      localStorage.setItem("guestId", `guestId-${randonnumber}`);
    }
    const setIsSignedInOrNot = async () => {
      if (isUserSignedIn()) {
        const token = getAuthToken();
        // // console.log("App token data", token);
        dispatch(setAuthToken(token));
        const decodedData = await decodeToken(token);
        dispatch(setUserData(decodedData));
        dispatch(setIsSignedIn(true));
        dispatch(setUserState(USER_STATE.SIGNED_IN_USER));
        if (authUserNotAllowedRoutes.includes(location.pathname)) {
          const quizURL = getNavigateURL("/");
          navigate(quizURL);
        }
      } else {
        if (protectedRoutes.includes(location.pathname)) {
          // // console.log("logged out");
          const quizURL = getNavigateURL("/");
          navigate(quizURL);
        }
      }
    };
    handleRemoveSearchParams();
    setIsSignedInOrNot();
    checkLocalStorageURL();
  }, []);

  const checkLocalStorageURL = () => {
    const url = window.location.href;
    if (url.indexOf("login-success") > 0) {
      localStorage.setItem("miniWindowToken", url);
      window.close();
    }
  };

  const handleRemoveSearchParams = () => {
    searchParams.delete("google-drive");
    searchParams.delete("google-forms");
    searchParams.delete("save-quiz");
    navigate(`?${searchParams.toString()}`);
  };

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    try {
      if (location.pathname === "/") {
        document.getElementById("aboutheaderbtn").style.color = "white";
        document.getElementById("quizheaderbtn").style.color = "#7DC243";
        document.getElementById("quizheaderbtn").style.cursor = "default";
        document.getElementById("aboutheaderbtn").style.cursor = "pointer";
      } else if (location.pathname === "/about") {
        document.getElementById("aboutheaderbtn").style.color = "#7DC243";
        document.getElementById("quizheaderbtn").style.color = "white";
        document.getElementById("aboutheaderbtn").style.cursor = "default";
        document.getElementById("quizheaderbtn").style.cursor = "pointer";
      } else {
        document.getElementById("aboutheaderbtn").style.color = "white";
        document.getElementById("quizheaderbtn").style.color = "white";
        document.getElementById("quizheaderbtn").style.cursor = "pointer";
        document.getElementById("aboutheaderbtn").style.cursor = "pointer";
      }
      // if (!location.pathname.includes("/verify")) {
      //   dispatch(setIsShowWelcomeModal(true));
      // }
    } catch (err) {
      // // console.log(err)
    }
  }, [location.pathname]);

  useEffect(() => {
    let timer;
    if (userState !== USER_STATE.LOGGED_OUT_USER) {
      dispatch(setIsLoading(true));
      const isUserVerified = () => {
        timer = setTimeout(async () => {
          const token = getAuthToken();
          const userData = await getUserInformation(token);
          dispatch(setIsLoading(false));
          if (userData && userData?.status === 200) {
            if (userData?.data?.stripeStatus !== store.getState().logIn.stripeStatus && store.getState().logIn.stripeStatus!==null) {
              initAdds();
            }
            dispatch(setBasicPlanPrice(userData.data.productPriceDetails.basicPrice || "0.00"))
            dispatch(setProMonthlyPlanPrice(userData.data.productPriceDetails.monthlyPrice || "0.00"))
            dispatch(setProYearlyPlanPrice(userData.data.productPriceDetails.yearlyPrice || "0.00"))
            dispatch(setUserData(userData.data));
            dispatch(setUserState(USER_STATE.SIGNED_IN_USER));
            dispatch(setIsSkippedState(userData.data.is_skipped || 0));
            dispatch(setStripeStatus(userData.data.stripeStatus));
            dispatch(
              setUserInformationState(userData.data.userInformation || null)
            );
          }
          if (
            userData &&
            userData?.response?.data?.statusCode === 412 &&
            userData?.response?.data?.message ===
              "Your account is not verified."
          ) {
            dispatch(setUserState(USER_STATE.NOT_VERIFIED_USER));
            // dispatch(setIsShowWelcomeModal(false));
            if (!window.location.pathname.includes("/verify")) {
              const verifyURL = getNavigateURL("/verify");
              navigate(verifyURL);
            }
          }
          if (
            userData &&
            userData?.response?.data?.statusCode === 403 &&
            userData?.response?.data?.message === "Your account is blocked."
          ) {
            // // console.log("blocked");
            dispatch(setUserState(USER_STATE.BLOCKED_USER_USER));
            const quizURL = getNavigateURL("/");
            navigate(quizURL);
          }
          if (userData && userData?.response?.data?.statusCode === 403 && userState!==USER_STATE.BLOCKED_USER_USER) {
            const resp = JSON.parse(userData?.response?.data?.message);
            // userData?.response?.data?.message === "Your account has expired."
            // console.log("expired");
            dispatch(setStripeStatus(resp.stripeStatus));
            dispatch(setRechargeTries(resp.rechargeTries))
            dispatch(setIsScreenSubscription(resp?.isScreenSubscription))
            dispatch(setUserState(USER_STATE.EXPIRED_USER));
            const quizURL = getNavigateURL("/");
            navigate(quizURL);
          }
          if (userData && userData?.data?.rechargeTries > 0 && userData?.data?.rechargeTries <=3 && !sessionStorage.getItem("payment-modal")) {
            dispatch(setRechargeTries(userData?.data?.rechargeTries))
            dispatch(setUserState(USER_STATE.PAYMENT_FAILED));
            const quizURL = getNavigateURL("/");
            navigate(quizURL);
          }
          if (userData && userData?.data?.rechargeTries >=3) {
            dispatch(setRechargeTries(userData?.data?.rechargeTries))
            dispatch(setUserState(USER_STATE.PAYMENT_FAILED));
            const quizURL = getNavigateURL("/");
            navigate(quizURL);
          }
          if (
            userData &&
            userData?.response?.data?.statusCode === 400 &&
            userData?.response?.data?.message === "User not found"
          ) {
            userLogOut();
            const loginURL = getNavigateURL("/login");
            navigate(loginURL);
          }
          if (
            userData &&
            userData?.response?.data?.statusCode === 401 &&
            userData?.response?.data?.message === "Unauthorized"
          ) {
            userLogOut();
            const loginURL = getNavigateURL("/login");
            navigate(loginURL);
          } else {
            isUserVerified();
          }
        }, API_DELAY_TIME);
      };
      isUserVerified();
    } else {
      clearTimeout(timer);
    }
    return () => {
      clearTimeout(timer);
    };
  }, [userState]);

  // useEffect(() => {
  //   const localStorageValue = localStorage.getItem("consent")
  //   if (localStorageValue!==null && localStorageValue=="true"){
  //     if( window.location.pathname != '/main'){
  //     window.location = '/main'
  //   }
  //   setShowQuiz(true)
  //   }
  //   else {
  //     if( window.location.pathname != '/'){
  //       window.location = '/'
  //     }
  //   setShowSplash(true)
  //   }
  // }, [showQuiz])

  return (
    <Routes>
      <Route
        path="/"
        element={
          isMobileLandscape && !isAboutPage ? (
              <Main quiz={quizData} />
          ) : (
            <Layout />
          )
        }
      >
        {isMobileLandscape && !isAboutPage ? null : (
          <Route
            index
            element={
                <Main quiz={quizData} />
            }
          />
        )}
      </Route>
      <Route path="/verify" element={<Layout />}>
        <Route index element={<VerifyEmail />} />
      </Route>
      <Route path="/about" element={<Layout />}>
        <Route index element={<AboutPage />} />
      </Route>
      <Route path="/signup" element={<Layout />}>
        <Route index element={<TrialPage />} />
      </Route>
      <Route path="/signup-email" element={<Layout />}>
        <Route index element={<SignUp />} />
      </Route>
      <Route path="/login" element={<Layout />}>
        <Route index element={<LogIn />} />
      </Route>
      <Route path="/forgotpassword" element={<Layout />}>
        <Route index element={<ForgotPassword />} />
      </Route>
      <Route path="/resetpassword" element={<Layout />}>
        <Route index element={<ResetPassword />} />
      </Route>
      <Route path="/profile" element={<Layout />}>
        <Route index element={<UserProfile />} />
      </Route>
      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  );
}

function App() {
  useClarity();
  return (
    <BrowserRouter>
      <AppRoutes />
    </BrowserRouter>
  );
}

export default App;
