import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getQuizList } from "../../api/library/method";
import intlMessages from "../../locales/en";

const initialState = {
  quizList: [],
  totalQuizzes: 0,
  page: 1,
  sortBy: "",
  order: "",
  size: 7,
  search: "",
  openLibrary: false,
  enableEditMode: false,
  openDeleteBox: false,
  totalPages: 0,
  mobileSortLabel: intlMessages.libraryCreatedAtLabel,
  mobileSortingLoader:false,
  navigateToQuizPage:false,
  enableShareMode: false
};

export const getList = createAsyncThunk(
  "library",
  async (payload, thunkApi) => {
    try {
      const res = await getQuizList(payload);
      if(res?.status === 200 && res.statusText === 'OK'){
        const data = res?.data;
        if(data){
          return data;
        }
        else{
          return thunkApi.rejectWithValue("Payload is undefined");
        }
      }
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

const librarySlice = createSlice({
  name: "library",
  initialState,
  reducers: {
    setQuizList: (state, action) => {
      state.quizList = action.payload;
    },
    setTotalQuizzes: (state, action) => {
      state.totalQuizzes = action.payload;
    },
    setOpenLibrary: (state, action) => {
      state.openLibrary = action.payload;
    },
    setEnableEditMode: (state, action) => {
      state.enableEditMode = action.payload;
    },
    setOpenDeleteBox: (state, action) => {
      state.openDeleteBox = action.payload;
    },
    setLibraryPage: (state, action) => {
      state.page = action.payload;
    },
    setSortOrder: (state, action) => {
      const { sortBy, order } = action.payload;
      state.sortBy = sortBy;
      state.order = order;
    },
    setSearchQuery: (state, action) => {
      state.search = action.payload;
      state.page = 1;
    },
    setMobileSortLabel: (state,action)=>{
      state.mobileSortLabel = action.payload
    },
    setMobileSortingLoader: (state,action)=>{
      state.mobileSortingLoader = action.payload
    },
    setNavigateToQuizPage: (state,action)=>{
      state.navigateToQuizPage = action.payload
    },
    setEnableShareMode: (state,action)=>{
      state.enableShareMode = action.payload
    },
    setResetStates: (state,action)=>{
      state.quizList =  []
      state.totalQuizzes = 0
      state.page = 1
      state.sortBy = ""
      state.order = ""
      state.size = 7
      state.search = ""
      state.enableEditMode = false
      state.openDeleteBox = false
      state.totalPages= 0
    }
  },
  extraReducers: {
    [getList.fulfilled]: (state, action) => {
     if(action.payload.data){
      const { docs, totalDocs, totalPages, page } = action.payload.data;
      state.quizList = docs;
      state.totalQuizzes = totalDocs;
      state.totalPages = totalPages;
      state.page = page;
     }
     else{
      state.quizList =  []
     }
    },
    [getList.rejected]: (state, action) => {
      state.quizList = [];
    },
  },
});

export const {
  setQuizList,
  setTotalQuizzes,
  setOpenLibrary,
  setEnableEditMode,
  setOpenDeleteBox,
  setLibraryPage,
  setSortOrder,
  setSearchQuery,
  setResetStates,
  setMobileSortLabel,
  setMobileSortingLoader,
  setNavigateToQuizPage,
  setEnableShareMode
} = librarySlice.actions;
const { reducer } = librarySlice;
export default reducer;
