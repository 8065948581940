import styled from "@mui/system/styled";
import { Typography, Button, Box, TextField, Checkbox } from "@mui/material";
import { LoadingButton } from "@mui/lab";

const MainContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  margin: "0% 3%",
  borderRadius: "10px",
  backgroundColor: "white",
  overflow: "auto",
}));

const backButton = styled(Button)(({ theme }) => ({
  color: "white",
  backgroundColor: "#616161",
  width: "84px",
  marginTop: "25px",
  marginLeft: "34px",
  textTransform: "none",
  height: "27px",
  borderRadius: "4px",
  "&:hover": {
    backgroundColor: "#969595",
  },
}));

const formContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
}));

const continueEmailText = styled(Typography)(
  ({ theme, isMobile, isMobileLandscape }) => ({
    fontFamily: "Open Sans",
    fontWeight: "700",
    fontSize: "32px",
    lineHeight: "32px",
    paddingLeft: "15%",
    paddingRight: "15%",
    textAlign: "center",
    marginBottom: "25px",
    marginTop: isMobile || isMobileLandscape ? "10px" : "unset",
  })
);

const nameTextField = styled(TextField)(
  ({ theme, isError, isMobile, isMobileLandscape }) => ({
    background: '#F6F6F6',
    '& .MuiInputBase-root': {
        background: '#F6F6F6',
    },  
    '& input::placeholder': {
      color: '#000000',
      opacity: 1,
    },
    width: isMobile? "250px" :isMobileLandscape?"410px": "235px",
    height: isMobile ? "48px" : "54px",
    borderRadius: "4px",
    marginBottom: isError && (isMobile || isMobileLandscape)
      ? "40px": isError && !isMobile && !isMobileLandscape? "20px"
      : (isMobile || isMobileLandscape) && !isError
      ? "15px"
      : "unset",
  })
);

const largeTextField = styled(TextField)(
  ({ theme, isError, isMobile, isMobileLandscape }) => ({
    background: '#F6F6F6',
    '& .MuiInputBase-root': {
        background: '#F6F6F6',
    },  
    '& input::placeholder': {
      color: '#000000',
      opacity: 1,
    },
    '& .MuiFormHelperText-root.Mui-error': {
      position: 'absolute',
      top: '100%'
    },
    width: isMobile ? "250px" : isMobileLandscape?"410px": "490px",
    height: isMobile ? "48px" : "54px",
    borderRadius: "4px",
    // marginBottom: isError ? "50px" : "25px",
    marginBottom: isError && (isMobile || isMobileLandscape)
    ? "70px": isError && !isMobile && !isMobileLandscape? "50px"
    : (isMobile || isMobileLandscape) && !isError
    ? "15px"
    : "25px",
  })
);

const checkBox = styled(Checkbox)(({ theme, isMobile, isMobileLandscape }) => ({
  marginLeft: isMobile || isMobileLandscape ? "-10px" : "10px",
  marginBottom: isMobile || isMobileLandscape ? "20px" : "5px",
  "&.Mui-checked": {
    color: "#7D242",
  },
}));

const unCheckedIcon = styled(Box)(({ theme }) => ({
  border: "2px solid #D5D5D5",
  width: "24px",
  height: "24px",
  borderRadius: "4px",
}));

const consentText = styled(Typography)(
  ({ theme, isMobile, isMobileLandscape }) => ({
    fontFamily: "Open Sans",
    fontWeight: "400",
    fontSize: isMobile || isMobileLandscape ? "12px" : "14px",
    lineHeight: "20px",
    color: "#777777",
    marginTop: "10px",
    width: "85%",
  })
);

const signUpBtn = styled(LoadingButton)(({ theme,isMobile,isMobileLandscape }) => ({
  // isLoading?'#E0E0E0':
  width: "210px",
  height: isMobile || isMobileLandscape?'48px':"54px",
  borderRadius: "4px",
  backgroundColor: "#7DC243",
  color: "white",
  marginBottom: "25px",
  textTransform: "none",
  ".MuiLoadingButton-loadingIndicator": {
    left: "30px",
  },
  "&:hover": {
    backgroundColor: "#89d44a",
  },
}));

const loginText = styled(Typography)(
  ({ theme, isMobile, isMobileLandscape }) => ({
    fontFamily: "Open Sans",
    fontWeight: "400",
    lineHeight: "24px",
    fontSize: "14px",
    marginBottom: isMobile || isMobileLandscape ? "22px" : "40px",
  })
);

export default {
  MainContainer,
  backButton,
  formContainer,
  continueEmailText,
  nameTextField,
  largeTextField,
  checkBox,
  unCheckedIcon,
  consentText,
  signUpBtn,
  loginText,
};
