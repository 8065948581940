import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Styled from "./styles";
import { useTheme } from "@mui/material/styles";
import { useMediaQuery } from "@mui/material";
import {useRef} from 'react';
import intlMessages from "../../locales/en";

export default function SampleTextMobileModal(props) {
  const { open, onClose,sampleTextList,onSelectSampleText } = props;
  const textRef = useRef([])
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"), {
    noSsr: true,
  });
  const isIpad = useMediaQuery(
    '(min-width:620px) and (max-width:840px) and (orientation: portrait) and  (hover: none) and (pointer: coarse)',
    {
      noSsr: true,
    }
  );
  
  const handleSelectText = (index)=>{
    textRef.current[index].style.color='#7DC242'
    onClose()
    onSelectSampleText(index)
  }
  return (
    <>
      <Styled.StyledMobileModal
      PaperProps={{
        sx: {
          margin: isMobile && !isIpad?'10px':'32px'
        },
      }}
        open={open}
      >
      <DialogTitle >
        <Box sx={{display:'flex',flexDirection:'row',justifyContent:'space-between'}}>
          <Typography
          variant='h3'
          component={'h3'}
          sx={{color:'black',textAlign:'center'}}
          >
            {intlMessages.quizImportSampleTextModelLabel}
          </Typography>
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            mt:-1.3,
            mr:-2,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        </Box>
        </DialogTitle>
        <DialogContent sx={{backgroundColor:"#F8F8F8"}} >
        {sampleTextList.map((element,index)=>(
                  <Box ref={el => textRef.current[index] = el}  onClick={()=>handleSelectText(index)}  key={index} sx={{pt:1,pb:1.3,display:'grid',color:'black'}}>
                  <Typography variant='h5' sx={{fontWeight:'bold',mb:1}}>{element.title}</Typography>
                  <Typography variant="p" sx={{width:'90%'}} >{element.summary}</Typography>
                  {index!=sampleTextList.length-1?
                  <Styled.Seperator
                  orientation="horizontal"
                  sx={{width:"100%",height:'1px',ml:-0.1,mt:1,mb:0}}
                   ></Styled.Seperator>
                  :null
                  }
                </Box>
                ))}
        </DialogContent>
      </Styled.StyledMobileModal>
    </>
  )
}
