import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  openPricingModal:false,
  openUpgradeModel:false,
  openSubscriptionModal:false,
  openPaymentModal:false, 
  trialDays:0,
  upgradeModalText:{
    heading:'',
    desc:'',
  },
  quizGenerationCount: {
    enable: false,
    maxHours: 0,
    maxDays: 0,
    desc: "",
  },
  quizContentLimit: {
    enable: false,
    maxHours: "",
    maxDays: "",
    desc: "",
    contentLimit: 1000,
  },
  questionCount: {
    enable: false,
    maxHours: "",
    maxDays: "",
    desc: "",
    count: [5],
  },
  quizGenerationType: {
    enable: false,
    maxHours: "",
    maxDays: "",
    desc: "",
    type: [],
  },
  difficultyLevel: {
    enable: false,
    maxHours: "",
    maxDays: "",
    desc: "",
    levels: [],
  },
  canImportSampleText: {
    enable: false,
    maxHours: "",
    maxDays: "",
    desc: "",
  },
  canImportTextFromSystem: {
    enable: false,
    maxHours: "",
    maxDays: "",
    desc: "",
  },
  canImportFromGoogleDrive: {
    enable: false,
    maxHours: "",
    maxDays: "",
    desc: "",
  },
  canUploadPDF: {
    enable: false,
    maxHours: "",
    maxDays: "",
    desc: "",
  },
  canUploadGoogleSheet: {
    enable: false,
    maxHours: "",
    maxDays: "",
    desc: "",
  },
  canUploadExcelSheet: {
    enable: false,
    maxHours: "",
    maxDays: "",
    desc: "",
  },
  canUploadPowerPointSlide: {
    enable: false,
    maxHours: "",
    maxDays: "",
    desc: "",
  },
  canUploadGoogleSlide: {
    enable: false,
    maxHours: "",
    maxDays: "",
    desc: "",
  },
  canUploadWordDoc: {
    enable: false,
    maxHours: "",
    maxDays: "",
    desc: "",
  },
  canUploadGoogleDoc: {
    enable: false,
    maxHours: "",
    maxDays: "",
    desc: "",
  },
  canUploadImage: {
    enable: false,
    maxHours: "",
    maxDays: "",
    desc: "",
    supportedFormats: [],
  },
  canCaptureImage: {
    enable: false,
    maxHours: "",
    maxDays: "",
    desc: "",
  },
  canExtractDataFromUrl: {
    enable: false,
    maxHours: "",
    maxDays: "",
    desc: "",
  },
  canSaveQuiz: {
    enable: false,
    maxHours: "",
    maxDays: "",
    desc: "",
  },
  canEditQuiz: {
    enable: false,
    maxHours: "",
    maxDays: "",
    desc: "",
  },
  canShareQuiz: {
    enable: true,
    maxHours: "",
    maxDays: "",
    desc: "",
  },
  canDeleteQuiz: {
    enable: false,
    maxHours: "",
    maxDays: "",
    desc: "",
  },
  canManageProfile: {
    enable: false,
    maxHours: "",
    maxDays: "",
    desc: "",
  },
  guruSupport: {
    enable: false,
    maxHours: "",
    maxDays: "",
    desc: "",
  },
  canExportPdfFile: {
    enable: false,
    withMarkedCorrectAnswers: false,
    withUnmarkedCorrectAnswers: false,
    maxHours: "",
    maxDays: "",
    desc: "",
  },
  canExportTxtFile: {
    enable: false,
    withMarkedCorrectAnswers: false,
    withUnmarkedCorrectAnswers: false,
    maxHours: "",
    maxDays: "",
    desc: "",
  },
  canExportXMLFile: {
    enable: false,
    maxHours: "",
    maxDays: "",
    desc: "",
  },
  canExportAikenFile: {
    enable: false,
    maxHours: "",
    maxDays: "",
    desc: "",
  },
  canExportQT12File: {
    enable: false,
    maxHours: "",
    maxDays: "",
    desc: "",
  },
  canExportGoogleSheet: {
    enable: false,
    maxHours: "",
    maxDays: "",
    desc: "",
  },
  canExportToGoogleForm: {
    enable: false,
    maxHours: "",
    maxDays: "",
    desc: "",
  },
  canCopyToClipboard: {
    enable: false,
    withMarkedCorrectAnswers: false,
    withUnmarkedCorrectAnswers: false,
    maxHours: "",
    maxDays: "",
    desc: "",
  },
};

const featureSlice = createSlice({
  name: "feature",
  initialState,
  reducers: {
    setOpenUpgradeModel: (state,action)=>{
      state.openUpgradeModel = action.payload
    },
    setOpenPricingModal: (state,action)=>{
      state.openPricingModal = action.payload
    },
    setOpenSubscriptionModal: (state,action)=>{
      state.openSubscriptionModal = action.payload
    },
    setOpenPaymentModal:(state,action)=>{
      state.openPaymentModal = action.payload
    },
    setUpgradeModalText: (state,action)=>{
      state.upgradeModalText = action.payload
    },
    setTrialDays: (state,action)=>{
      state.trialDays = action.payload
    },
    setQuizGenerationCount: (state, action) => {
      state.quizGenerationCount = action.payload;
    },
    setQuizContentLimit: (state, action) => {
      state.quizContentLimit = action.payload;
    },
    setQuestionCount: (state, action) => {
      state.questionCount = action.payload;
    },
    setQuizGenerationType: (state, action) => {
      state.quizGenerationType = action.payload;
    },
    setDifficultyLevel: (state, action) => {
      state.difficultyLevel = action.payload;
    },
    setCanImportSampleText: (state, action) => {
      state.canImportSampleText = action.payload;
    },
    setCanImportTextFromSystem: (state, action) => {
      state.canImportTextFromSystem = action.payload;
    },
    setCanImportFromGoogleDrive: (state, action) => {
      state.canImportFromGoogleDrive = action.payload;
    },
    setCanUploadPdf: (state, action) => {
      state.canUploadPDF = action.payload;
    },
    setCanUploadGoogleSheet: (state, action) => {
      state.canUploadGoogleSheet = action.payload;
    },
    setCanUploadExcelSheet: (state, action) => {
      state.canUploadExcelSheet = action.payload;
    },
    setCanUploadPowerPointSlide: (state, action) => {
      state.canUploadPowerPointSlide = action.payload;
    },
    setCanUploadGoogleSlide: (state, action) => {
      state.canUploadGoogleSlide = action.payload;
    },
    setCanUploadWordDoc: (state, action) => {
      state.canUploadWordDoc = action.payload;
    },
    setCanUploadGoogleDoc: (state, action) => {
      state.canUploadGoogleDoc = action.payload;
    },
    setCanUploadImage: (state, action) => {
      state.canUploadImage = action.payload;
    },
    setCanCaptureImage: (state, action) => {
      state.canCaptureImage = action.payload;
    },
    setCanExtractDataFromUrl: (state, action) => {
      state.canExtractDataFromUrl = action.payload;
    },
    setCanSaveQuiz: (state, action) => {
      state.canSaveQuiz = action.payload;
    },
    setCanEditQuiz: (state, action) => {
      state.canEditQuiz = action.payload;
    },
    setCanShareQuiz: (state, action) => {
      state.canShareQuiz = action.payload;
    },
    setCanDeleteQuiz: (state, action) => {
      state.canDeleteQuiz = action.payload;
    },
    setCanManageProfile: (state, action) => {
      state.canManageProfile = action.payload;
    },
    setGuruSupport: (state, action) => {
      state.guruSupport = action.payload;
    },
    setCanExportPdfFile: (state, action) => {
      state.canExportPdfFile = action.payload;
    },
    setCanExportTxtFile: (state, action) => {
      state.canExportTxtFile = action.payload;
    },
    setCanExportXMLFile: (state, action) => {
      state.canExportXMLFile = action.payload;
    },
    setCanExportAikenFile: (state, action) => {
      state.canExportAikenFile = action.payload;
    },
    setCanExportQT12File: (state, action) => {
      state.canExportQT12File = action.payload;
    },
    setCanExportGoogleSheet: (state, action) => {
      state.canExportGoogleSheet = action.payload;
    },
    setCanExportToGoogleForm: (state, action) => {
      state.canExportToGoogleForm = action.payload;
    },
    setCanCopyToClipboard: (state, action) => {
      state.canCopyToClipboard = action.payload;
    },
  },
});

export const {
  setOpenUpgradeModel,
  setOpenPricingModal,
  setQuizGenerationCount,
  setQuizContentLimit,
  setQuestionCount,
  setQuizGenerationType,
  setDifficultyLevel,
  setCanImportSampleText,
  setCanImportTextFromSystem,
  setCanImportFromGoogleDrive,
  setCanUploadPdf,
  setCanUploadGoogleSheet,
  setCanUploadExcelSheet,
  setCanUploadPowerPointSlide,
  setCanUploadGoogleSlide,
  setCanUploadWordDoc,
  setCanUploadGoogleDoc,
  setCanUploadImage,
  setCanCaptureImage,
  setCanExtractDataFromUrl,
  setCanSaveQuiz,
  setCanEditQuiz,
  setCanDeleteQuiz,
  setCanShareQuiz,
  setCanManageProfile,
  setGuruSupport,
  setCanExportPdfFile,
  setCanExportTxtFile,
  setCanExportXMLFile,
  setCanExportAikenFile,
  setCanExportQT12File,
  setCanExportGoogleSheet,
  setCanExportToGoogleForm,
  setCanCopyToClipboard,
  setOpenSubscriptionModal,
  setOpenPaymentModal,
  setUpgradeModalText,
  setTrialDays
} = featureSlice.actions;
const { reducer } = featureSlice;
export default reducer;
