import { Button } from "@mui/material";
import styled from "@mui/system/styled";

const FacebookLogo = styled('img')(({ theme }) => ({
    width: '30px',
    height: '30px',
    marginRight: '5px',
    cursor: 'pointer'
}))

const TwitterLogo = styled('img')(({ theme }) => ({
    width: '30px',
    height: '30px',
    marginRight: '5px',
    cursor: 'pointer'
}))

const InstagramLogo = styled('img')(({ theme }) => ({
    width: '30px',
    height: '30px',
    cursor: 'pointer'
}))

const SignUpButtonMobile = styled(Button)(({ theme }) => ({
    color: theme.palette.primary.contrastText,
    textTransform: "none",
    width: '150px',
    fontWeight: '600',
    fontSize: '14px'
  }));

export default {
    FacebookLogo,
    TwitterLogo,
    InstagramLogo,
    SignUpButtonMobile
};