import axios from "axios";
import { checkInternetConnection } from "../../api/service";
import { GURU_CODE, GURU_SERVER_URL } from "../../settings";
import intlMessages from "../../locales/en";
import { store } from "../../redux/store";
import _ from "lodash";

const getInternetConnectionStatus = async () => {
  const connectionStatus = await checkInternetConnection();
  return connectionStatus;
};

const handleErrorMsg = (e) => {
  if (e.response) {
    let resMessage = e?.response?.data?.message;
    if (Array.isArray(resMessage)) {
      resMessage = resMessage[0];
    }
    e.response.isConnected = false;
    e.response.data.message = resMessage;
    return e?.response;
  }
  return e;
};

const webserviceCall = {
  CALL_TIMEOUT: 60000,
  webserviceHelper: (payload) => {
    return new Promise((resolve, reject) => {
      axios({
        method: payload.type,
        url: payload.url,
        headers: payload.headers,
        data: payload.body,
        timeout: webserviceCall.CALL_TIMEOUT,
      })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};

async function getGuruList() {
  try {
    const payload = {
      url: `${GURU_SERVER_URL}/api/v1/user/hype/roles?role=1&guruCode=${GURU_CODE}`,
      type: `GET`,
      headers: { "Content-Type": "application/json" },
    };
    const resp = await webserviceCall.webserviceHelper(payload);
    return resp;
  } catch (e) {
    return handleErrorMsg(e);
  }
}

// const errorConnectedCall = (error) => {
//   //disable button
//   setCallStatus(callStatusMessageMap(4));
//   setTimeout(() => {
//     hideModal();
//   }, 1000);
// };

const createCall = async ({ subType, callData, guestUniqueId }) => {
  const userDetails = store.getState().guru.userDetails;
  const participantObjsArray = [{ id: callData.id + "", type: 1 }];
  const timeStamp = Math.floor(new Date().getTime() / 1000);
  const meetingType = "0";
  const formData = {
    participantObjsArray: JSON.stringify(participantObjsArray),
    meetingType: meetingType + "",
    meetingSubType: subType,
    timeStamp: timeStamp + "",
    guestUniqueId: guestUniqueId,
  };
  const payload = {
    url: `${GURU_SERVER_URL}/api/v1.3/user/${userDetails.userId}/meetings/invite`,
    type: `POST`,
    headers: {
      "Content-Type": "application/json",
      "x-access-token": userDetails.token,
      version: "4.1.1",
    },
    body: formData,
  };
  const res = await webserviceCall.webserviceHelper(payload);
  return res;
  // webserviceCall
  //   .webserviceHelper(payload)
  //   .then((response) => {
  //     if (response && response.status == 200) {
  //       if (response.data && response.data.statusCode == 0) {
  //         setLocalItem("refId", response.data.data.referenceId);
  //         //poll call status
  //         pollCallStatus();
  //         return;
  //       }
  //     }
  //     errorConnectedCall(response); //show error and hide modal
  //   })
  //   .catch((error) => {
  //     // console.log("error found", error); //show error and hide modal
  //     errorConnectedCall(error);
  //   });
};

const updateCallStatus = async ({ status }) => {
  const userDetails = store.getState().guru.userDetails;
  const refId = store.getState().guru.refId;
  const isCancelClicked = store.getState().guru.cancelClick;
  // if (isCancelClicked) {
  //   return;
  // }
  let payload = {
    url: `${GURU_SERVER_URL}/api/v1/user/${userDetails.userId}/meetings/invite/${refId}`,
    type: "POST",
    headers: {
      "Content-Type": "application/json",
      "x-access-token": userDetails.token,
      version: "4.1.1",
    },
    body: { status: status },
  };
  const resp = await webserviceCall.webserviceHelper(payload);
  return resp;
  // .then((response) => {
  //   // console.log("Success in call connected", response);
  //   if (response && response.status == 200) {
  //     if (response.data && response.data.statusCode == 0) {
  //       successCallback(response.data);
  //       return;
  //     }
  //   }
  //   errorCallback(response);
  // })
  // .catch((error) => {
  //   // console.log("error found", error);
  //   errorCallback(error);
  //   //no guru found
  // });
};

// const onCancelClick = () => {
//   // setCancelClick(true);
//   setLocalItem("cancelClick", true);
//   setCallStatus(intlMessages.GURU_STR_25);
//   disableBtns();
//   setLocalItem("disableEndBtn", true);
//   updateCallStatus(
//     7,
//     function (callback) {
//       setTimeout(() => {
//         hideModal();
//       }, 1000);
//     },
//     function (error) {
//       setTimeout(() => {
//         hideModal();
//         getLocalItem("refId");
//       }, 1000);
//     }
//   );
// };

// const successCB = (data) => {
//   if (getLocalItem("cancelClick")) {
//     return;
//   }
//   let callStatusReceiv = data.data.callStatus;
//   let inviterStatus = data.data.inviterStatus;
//   if (callStatusReceiv == callStatusMap("HYPE_INVITE_STATUS_DISPLAYED")) {
//     //set call status displayed
//     setCallStatus(intlMessages.GURU_STR_4);
//   }
//   // if call status accepted
//   if (callStatusReceiv == callStatusMap("HYPE_INVITE_STATUS_ACCEPTED")) {
//     //REDIRECT TO HYPE
//     updateCallStatus(
//       5,
//       () => {
//         setTimeout(() => {
//           let meetingUrl = `${GURU_SERVER_URL}/guest.html?username=&roomId=${data.data.meetingId}&participantEmail=&meetingSubType=${meetingSubType}#guestUser`;
//           openGuruNewTab(meetingUrl);
//         }, 1000);
//         return;
//       },
//       () => {
//         setCallStatus(intlMessages.GURU_STR_7);
//         setTimeout(() => {
//           hideModal();
//         }, 1000);
//         return;
//       }
//     );
//     return;
//   }

//   if (callStatusReceiv == callStatusMap("HYPE_INVITE_STATUS_REJECTED")) {
//     //show call status rejected
//     // setDisbaleEndBtn(true);
//     setLocalItem("disableEndBtn", true);
//     setCallStatus(intlMessages.GURU_STR_6);
//     setTimeout(() => {
//       hideModal();
//       //hide call model
//     }, 1000);
//     return;
//   }

//   if (
//     callStatusReceiv == callStatusMap("HYPE_INVITE_STATUS_TIMEOUT") ||
//     callStatusReceiv == callStatusMap("HYPE_INVITE_STATUS_CANCELED") ||
//     inviterStatus == callStatusMap("HYPE_INVITE_STATUS_CANCELED")
//   ) {
//     // setDisbaleEndBtn(true);
//     setLocalItem("disableEndBtn", true);
//     setCallStatus(intlMessages.GURU_STR_7);
//     setTimeout(() => {
//       hideModal();
//     }, 1000);
//     return;
//   }

//   setTimeout(() => {
//     pollCallStatus(data);
//   }, 1000);
// };
const pollCallStatus = async () => {
  const userDetails = store.getState().guru.userDetails;
  const refId = store.getState().guru.refId;
  const isCancelClicked = store.getState().guru.cancelClick;
  if (isCancelClicked) {
    return;
  }

  let payload = {
    url: `${GURU_SERVER_URL}/api/v1/user/${userDetails.userId}/meetings/invite/${refId}`,
    type: "GET",
    headers: {
      "Content-Type": "application/json",
      "x-access-token": userDetails.token,
      version: "4.0.0",
    },
  };
  const resp = await webserviceCall.webserviceHelper(payload);
  return resp;
  // webserviceCall
  //   .webserviceHelper(payload)
  //   .then((response) => {
  //     // console.log("Success in call connected", response);
  //     if (response && response.status == 200) {
  //       if (response.data && response.data.statusCode == 0) {
  //         successCB(response.data);
  //         return;
  //       }
  //     }
  //     errorConnectedCall(response);
  //   })
  //   .catch((error) => {
  //     // console.log("error found", error);
  //     errorConnectedCall(error);
  //     //no guru found
  //   });
};

const resetStatus = () => {
  localStorage.removeItem("cancelClick");
  localStorage.removeItem("refId");
  localStorage.removeItem("callConnected");
  localStorage.removeItem("disableEndBtn");
  localStorage.removeItem("calHref");
};

const setLocalItem = (itemName, data) => {
  localStorage.setItem(itemName, data);
};
const getLocalItem = (itemName) => {
  return localStorage.getItem(itemName);
};

const callStatusMap = (map) => {
  let callMap = {
    HYPE_INVITE_STATUS_PENDING: 0,
    HYPE_INVITE_STATUS_DISPLAYED: 1,
    HYPE_INVITE_STATUS_ACCEPTED: 2,
    HYPE_INVITE_STATUS_REJECTED: 3,
    HYPE_INVITE_STATUS_TIMEOUT: 4,
    HYPE_INVITE_STATUS_JOINING: 5,
    HYPE_INVITE_STATUS_JOINED: 6,
    HYPE_INVITE_STATUS_CANCELED: 7,
    HYPE_INVITE_STATUS_NOANSWER: 8,
  };
  return callMap[map];
};

// const hideModal = () => {
//   resetStatus();
//   setTimeout(() => {
//     enableBtns();
//     closeCallModal();
//   }, 1000);
// };

const callStatusMessageMap = (msgType) => {
  let msg = intlMessages.GURU_STR_3;
  switch (msgType) {
    case 0:
      msg = intlMessages.GURU_STR_3;
      break;
    case 1:
      msg = intlMessages.GURU_STR_4;
      break;
    case 2:
      msg = intlMessages.GURU_STR_5;
      break;
    case 3:
      msg = intlMessages.GURU_STR_6;
      break;
    case 4:
      msg = intlMessages.GURU_STR_7;
      break;
    case 5:
      msg = intlMessages.GURU_STR_3;
      break;
    case 6:
      msg = intlMessages.GURU_STR_8;
      break;
    case 7:
      msg = intlMessages.GURU_STR_9;
      break;
    case 8:
      msg = intlMessages.GURU_STR_10;
      break;
    default:
      msg = intlMessages.GURU_STR_3;
  }
  return msg;
};

const getGuestUniqueId = () => {
  let uuid = localStorage.getItem(`OS-guestUniqueId`);
  return uuid;
};

const setGuestUniqueId = () => {
  var bcrypt = require("bcryptjs");
  var salt = bcrypt.genSaltSync(10);
  const randonnumber = bcrypt.hashSync("B4c0//", salt);
  let uuid = `guestUniqueId-${randonnumber}`;
  localStorage.setItem(`OS-guestUniqueId`, uuid);
};

export {
  getInternetConnectionStatus,
  getGuruList,
  webserviceCall,
  callStatusMessageMap,
  createCall,
  updateCallStatus,
  pollCallStatus,
  getGuestUniqueId,
  setGuestUniqueId,
};
