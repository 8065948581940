import styled from "@mui/system/styled";
import { Grid,Box, Button, Typography } from "@mui/material";

const HeaderMainContainer = styled(Grid)(({ theme }) => ({
    background: 'linear-gradient(90deg, #4E0F89 0%, #7D00BA 100%)',
    width:'80px',
    display:'flex',
    flexDirection:'column',
    alignItems:'center',
    justifyContent:'space-between',
    paddingTop:'12px',
    paddingBottom:'18px',
}));

const IconContainer = styled(Box)(({ theme }) => ({
    display:'flex',
    flexDirection:'column',
    alignItems:'center',
}))

const FeedbackLogo = styled('img')(({ theme }) => ({
    width: '31px',
    height: '31px',
    marginBottom:'20px'
    
}))

const DrawerLogo = styled('img')(({ theme }) => ({
    width: '27.62px',
    height: '17px',
}))

const StyledBackBtn = styled(Button)(({theme})=>({
    width:'62px',
    height:'34px',
    backgroundColor:'#7DC243',
    color:'white',
    borderRadius:'4px',
    textTransform:'none',
    margin:'5px',
}))

const SignUpButtonMobile = styled(Button)(({ theme }) => ({
    color: theme.palette.primary.contrastText,
    textTransform: "none",
    width: '62px',
    fontWeight: '400',
    fontSize: '13px',
    height:'46px',
    borderRadius:'4px',
  }));

  const PlanBox = styled(Box)(
    ({ theme,isMobile,isMobileLandscape}) => ({
      backgroundColor:'#7DC242',
      color:'#FFFFFF',
      width:'70px',
      display:'flex',
      height:'35px',
      flexDirection:'row',
      justifyContent:'center',
      alignItems:'center',
      margin:'5px',
    //   position:'absolute',
    //   top: isMobile || isMobileLandscape?"100px":'52px',
    //   left: isMobile || isMobileLandscape?"60px":'190px',
      borderRadius:'2px',
      paddingRight:'5px',
      paddingLeft:'5px'
    })
  );
  
  const PlanText = styled(Typography)(
    ({ theme}) => ({
      fontFamily:'Open Sans',
      fontSize:'10px',
      fontWeight:500,
      lineHeight:'10.89px',
    })
  );

export default {
    HeaderMainContainer,
    IconContainer,
    FeedbackLogo,
    DrawerLogo,
    StyledBackBtn,
    SignUpButtonMobile,
    PlanBox,
    PlanText
};
