import { Box, Dialog } from "@mui/material";
import { useState,useRef } from "react";
import Webcam from "react-webcam";
import Styled from "./styles";
import intlMessages from "../../locales/en";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
import FlipCameraAndroidIcon from "@mui/icons-material/FlipCameraAndroid";
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";
import { FadeLoader } from "react-spinners";
import { Categories, Events, logEvent } from "../../analytics";

export default function CameraModal(props) {
  const {
    open,
    isMobile,
    isMobileLandscape,
    onClose,
    uploadFile,
    isIpad,
    isIpadLandscape,
  } = props;
  const FACING_MODE_USER = "user";
  const FACING_MODE_ENVIRONMENT = "environment";
  const webcamRef = useRef(null);
  const [imgSrc, setImgSrc] = useState("");
  const [enableCamera, setIsCameraEnabled] = useState(true);
  const [facingMode, setFacingMode] = useState(FACING_MODE_ENVIRONMENT);
  const videoConstraints = {
    facingMode: FACING_MODE_ENVIRONMENT,
  };
  const [cameraAllowed, setIsCameraAllowed] = useState(false);
 
  const capture = () => {
    const imageSrc = webcamRef.current.getScreenshot();
    const img = new Image();
    img.src = imageSrc;
    setImgSrc(imageSrc);
    setIsCameraEnabled(!enableCamera);
    logEvent(Categories.capture_photo, Events.capture_photo_modal_capture_button_clicked);
  };

  const resetCamera = () => {
    logEvent(Categories.capture_photo, Events.capture_photo_modal_cancel_button_clicked);
    setIsCameraEnabled(true);
  };
  const uploadImage = async (dataUrl, fileName, fileType) => {
    const byteCharacters = atob(dataUrl.split(",")[1]);
    const byteNumbers = new Array(byteCharacters.length);

    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: fileType });
    const file = new File([blob], fileName, { type: fileType });
    uploadFile(file);
  };

  const handleSwitchCamera = () => {
    logEvent(Categories.capture_photo, Events.capture_photo_modal_switch_button_clicked);
    setFacingMode((prevState) =>
      prevState === FACING_MODE_USER
        ? FACING_MODE_ENVIRONMENT
        : FACING_MODE_USER
    );
  };

  const renderCamera = () => {
    return (
      <Box
        sx={{
          boxShadow: "0 0 0 10px #c4c4c4",
          width: isMobile ? "100%" : isMobileLandscape ? "500px" : "400px",
          height: isMobile ? "70vh" : isMobileLandscape ? "60vh" : "300px",
          mb: isMobile || isMobileLandscape ? "0px" : "5%",
          mt: isMobile || isMobileLandscape ? "4%" : " 5px",
          ml: "2%",
          mr: "2%",
        }}
      >
        {
          enableCamera === true ? (
            <Webcam
              className={
                isMobile
                  ? "webcam-mobile-portrait"
                  : isMobileLandscape
                  ? "webcam-mobile-landscape"
                  : "webcam-desktop"
              }
              ref={webcamRef}
              screenshotFormat="image/jpeg"
              videoConstraints={{
                ...videoConstraints,
                facingMode:facingMode
              }}
              onUserMedia={(stream)=>setIsCameraAllowed(true)}
              onUserMediaError={(error) => {
                setIsCameraAllowed(false);
                onClose(false, false,error.toString());
              }}
              forceScreenshotSourceSize="true"
              style={{ objectFit: "cover" }}
            />
          ) : (
            <img
              alt={"img"}
              style={{
                width: "100%",
                height: isMobile ? "90%" : isMobileLandscape ? "90%" : "90%",
              }}
              src={imgSrc}
            ></img>
          )}
          <Box
            sx={{
              position:'relative',
              top:'-50%',
              left:'45%',
              display: cameraAllowed===true?'none':'block'
            }}
          >
            <FadeLoader></FadeLoader>
          </Box>
       
        <Box
          sx={{
            backgroundColor: "#d4d4d4",
            width: isMobile ? "96%" : "100.1%",
            height: isMobileLandscape ? "15%" : "10%",
            display: cameraAllowed ? "flex" : "none",
            alignItems: "center",
            position: isMobile ? "absolute" : "relative",
            bottom: isMobile ? "0px" : isMobileLandscape ? "6px" : "6px",
            justifyContent: "space-between",
            pl: "10px",
            pr: "10px",
          }}
        >
          <RadioButtonCheckedIcon
            onClick={capture}
            sx={{
              color: "white",
              transform: "scale(1)",
              cursor: "pointer",
              display: enableCamera ? "block" : "unset",
              margin: "auto",
            }}
          />
          {enableCamera === true ? (
            <FlipCameraAndroidIcon
              onClick={handleSwitchCamera}
              sx={{
                color: "white",
                transform: "scale(1)",
                cursor: "pointer",
              }}
            />
          ) : (
            <Box sx={{ display: "flex", pt: "1px" }}>
              <DoneIcon
                onClick={() =>
                  {uploadImage(
                    imgSrc,
                    `image_${new Date().getTime()}.jpeg`,
                    "image/jpeg"
                  )
                  logEvent(Categories.capture_photo, Events.capture_photo_modal_done_button_clicked);
                  onClose(false);
                }
                }
                sx={{
                  color: "green",
                  transform: "scale(1)",
                  cursor: "pointer",
                }}
              />
              <CloseIcon
                onClick={resetCamera}
                sx={{
                  color: "red",
                  transform: "scale(1)",
                  cursor: "pointer",
                  ml: "10px",
                }}
              />
            </Box>
          )}
        </Box>
      </Box>
    );
  };

  const handleOnClose = async () => {
    logEvent(Categories.capture_photo, Events.capture_photo_modal_close_button_clicked);
    onClose(false);
  };
  return (
    <Dialog
      fullScreen
      open={open}
      onClose={handleOnClose}
      componentsProps={{
        backdrop: {
          style: {
            backgroundColor: "rgba(0, 0, 0, 0.51)",
          },
        },
      }}
      sx={{
        bgcolor: "background.paper",
        background: "transparent",
      }}
      PaperProps={{
        style:
          isMobile || isMobileLandscape
            ? {}
            : {
                backgroundColor: "transparent",
                boxShadow: "none",
              },
      }}
    >
      {(isMobile || isMobileLandscape) && (
        <Styled.HeadingBar>
          <Styled.Heading>{intlMessages.capturePhotoLabel}</Styled.Heading>
          <Styled.CrossBtn onClick={handleOnClose}>
            <CloseOutlinedIcon />
          </Styled.CrossBtn>
        </Styled.HeadingBar>
      )}
      <Styled.Container
        isMobile={isMobile ? true : false}
        isMobileLandscape={isMobileLandscape ? true : false}
        isIpad={isIpad ? true : false}
        isIpadLandscape={isIpadLandscape ? true : false}
      >
        {!isMobile && !isMobileLandscape && (
          <Styled.HeadingBar>
            <Styled.Heading>{intlMessages.capturePhotoLabel}</Styled.Heading>
            <Styled.CrossBtn onClick={handleOnClose}>
              <CloseOutlinedIcon />
            </Styled.CrossBtn>
          </Styled.HeadingBar>
        )}
        <Styled.CameraBox>{renderCamera()}</Styled.CameraBox>
      </Styled.Container>
    </Dialog>
  );
}
