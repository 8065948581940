import "./styles.css"
const CircularLoader = () => {
  return (
    <>
      <div className="loadingio-spinner-rolling-jlj8b1n28mb">
        <div className="ldio-7tp0i48m8g7">
          <div></div>
        </div>
      </div>
    </>
  );
};

export default CircularLoader;
