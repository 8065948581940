import ReactGA from "react-ga4";
import { conf } from "./version";

export const Categories = {
  content_for_quiz: "quiz_content",
  quiz_from_content: "quiz_from_content",
  export_quiz: "export_quiz",
  feed_back: "quiz_feedback",
  start_adding: "home_page",
  learning_hub_intgration: "learning_hub_integration",
  page_view: "page_view",
  user_email: "user_email",
  about_page: "about_page",
  social_links: "social_links",
  user_management: "user_management",
  quiz_library: "quiz_library",
  walkthrough: "walkthrough",
  drive: "drive",
  guru: "guru",
  quiz_settings: "quiz_settings",
  paste_web_url: "paste_web_url",
  capture_photo: "capture_photo",
  alert_modal: "alert_modal",
  pricing_modal: "pricing_modal",
  pricing_and_subscription_modal: "pricing_and_subscription_modal",
  downgrade_modal: "downgrade_modal",
  select_payment_method_modal: "select_payment_method_modal",
  smart_screen_modal: "smart_screen_modal",
  upgrade_modal:"upgrade_modal",
  confirmation_modal:"confirmation_modal",
  value_color_green: 1,
  value_color_blue: 2,
};
export const Events = {
  genrate_event: "quiz_create_clicked",
  export_event: "quiz_export_clicked",
  feedback_event: "feedback_rating",
  contact_us_event: "contact_us",
  download_pdf: "download_pdf",
  download_text: "download_text",
  copy_to_clip_board: "copy_to_clipboard",
  copy_to_clip_board_clicked: "copy_to_clipboard_clicked",
  upload_file: "content_from_text_uploaded",
  upload_file_click: "content_from_text_clicked",
  import_menu_click: "import_menu_clicked",
  ans_respose_count: "generated_questions_count",
  exported_ans_count: "exported_questions_count",
  start_adding: "start_adding_content_clicked",
  total_quiz_created: "total_quiz_created",
  quiz_content_words: "quiz_content_words",
  quiz_genratiion_time_ms: "quiz_genration_time_ms",
  export_quiz_via: "export_quiz_via",
  quiz_from_content: "quiz_from_content",
  learning_hub_clicked: "learning_hub_clicked",
  learning_hub_connect_clicked: "learning_hub_connect_clicked",
  learning_hub_logout_clicked: "learning_hub_logout_clicked",
  learning_hub_refresh_clicked: "learning_hub_refresh_clicked",
  learning_hub_post_clicked: "learning_hub_post_clicked",
  learning_hub_class_select_clicked: "learning_hub_class_select_clicked",
  slide_clicked: "slide_clicked",
  splash_screen: "splash_screen_visited",
  main_screen: "main_screen_visited",
  import_sample_text_button: "import_sample_text_button_clicked",
  import_sample_text_paragraph: "import_sample_text_paragraph_selected",
  clear_button_clicked: "clear_button_clicked",
  select_all_checked: "select_all_checked",
  select_all_unchecked: "select_all_unchecked",
  question_checked: "question_checked",
  question_unchecked: "question_unchecked",
  export_menu_click: "export_menu_click",
  user_email_click: "user_email_click",
  about_screen: "about_screen_visited",
  about_screen_slider_generate_quiz_button_clicked:
    "about_screen_generate_quiz_button_clicked",
  about_screen_slider_dot_button_clicked:
    "about_screen_slider_dot_button_clicked",
  about_screen_tagline_generate_quiz_button_clicked:
    "about_screen_tagline_generate_quiz_button_clicked",
  social_facebook_link_clicked: "social_facebook_link_clicked",
  social_twitter_link_clicked: "social_twitter_link_clicked",
  social_instagram_link_clicked: "social_instagram_link_clicked",
  user_management_login_local: "user_management_login_local",
  user_management_login_google: "user_management_login_google",
  user_management_login_google_for_drive:
    "user_management_login_google_for_drive",
  user_management_login_microsoft: "user_management_login_microsoft",
  user_management_forgot_password: "user_management_forgot_password",
  user_management_reset_password: "user_management_reset_password",
  user_management_privacy_policy: "user_management_privacy_policy",
  user_management_terms_of_service: "user_management_terms_of_service",
  user_management_signup_local: "user_management_signup_local",
  user_management_signup_google: "user_management_signup_google",
  user_management_signup_microsoft: "user_management_signup_microsoft",
  user_management_login_click_header: "user_management_login_click_header_link",
  user_management_signup_click_header:
    "user_management_signup_click_header_link",
  user_management_login_click_signup_page:
    "user_management_login_click_signup_page_link",
  user_management_signup_click_login_page:
    "user_management_signup_click_login_page_link",
  user_management_login_Clicked: "user_management_login_Clicked_btn",
  user_management_signup_click_local_signup_page:
    "user_management_signup_click_local_signup_page",
  user_management_forgot_password_click:
    "user_management_forgot_password_click_link",
  user_management_login_click_forgot_password_page:
    "user_management_login_click_forgot_password_page_link",
  user_management_login_click_reset_password_page:
    "user_management_login_click_reset_password_page_btn",
  user_management_password_reset_update_click:
    "user_management_password_reset_update_click_btn",
  user_management_profile_link_click: "user_management_profile_link_click",
  user_management_logout_click_btn: "user_management_logout_click_btn",
  user_management_signup_click_generate_quiz_btn:
    "user_management_signup_click_generate_quiz_btn",
  user_management_update_username: "user_management_update_username",
  user_management_update_password: "user_management_update_password",
  user_management_delete_account: "user_management_delete_account",
  user_management_delete_account_btn_clicked:
    "user_management_delete_account_btn_clicked",
  user_management_cancel_delete_account_btn_clicked:
    "user_management_cancel_delete_account_btn_clicked",
  delete_account_modal_close_btn_clicked:
    "delete_account_modal_close_btn_clicked",
  delete_account_modal_confirm_btn_clicked:
    "delete_account_modal_confirm_btn_clicked",
  delete_account_modal_reason_clicked: "delete_account_modal_reason_clicked",
  google_forms_login_modal_for_loggedin_user:
    "google_forms_login_modal_for_loggedin_user",
  google_forms_user_loggedin_with_google:
    "google_forms_user_loggedin_with_google",
  google_forms_signin_signup_modal_for_nonsignedin_user:
    "google_forms_signin_signup_modal_for_nonsignedin_user",
  google_forms_signin_signup_modal_login_click:
    "google_forms_signin_signup_modal_login_click",
  google_forms_signin_signup_modal_signup_click:
    "google_forms_signin_signup_modal_signup_click",
  signup: "signup",
  signup_by_email: "signup_by_email",
  signup_by_social: "signup_by_social",
  quiz_library_button_clicked: "quiz_library_button_clicked",
  quiz_library_close_button_clicked: "quiz_library_close_button_clicked",
  quiz_library_save_button_clicked: "quiz_library_save_button_clicked",
  quiz_library_edit_button_clicked: "quiz_library_edit_button_clicked",
  quiz_library_delete_button_clicked: "quiz_library_delete_button_clicked",
  quiz_library_share_button_clicked: "quiz_library_share_button_clicked",
  quiz_library_search_clear_button_clicked:
    "quiz_library_search_clear_button_clicked",
  quiz_library_pagination_button_clicked:
    "quiz_library_pagination_button_clicked",
  quiz_library_delete_modal_confirm_button_clicked:
    "quiz_library_delete_modal_confirm_button_clicked",
  quiz_library_delete_modal_cancel_button_clicked:
    "quiz_library_delete_modal_cancel_button_clicked",
  quiz_library_save_modal_confirm_button_clicked:
    "quiz_library_save_modal_confirm_button_clicked",
  quiz_library_save_modal_cancel_button_clicked:
    "quiz_library_save_modal_cancel_button_clicked",
  quiz_library_sort_by_quiz_title_button_clicked:
    "quiz_library_sort_by_quiz_title_button_clicked",
  quiz_library_sort_by_created_date_button_clicked:
    "quiz_library_sort_by_created_date_button_clicked",
  quiz_generated_walkthrough_completed: "quiz_generated_walkthrough_completed",
  quiz_exported_walkthrough_completed: "quiz_exported_walkthrough_completed",
  drive_Library_opened: "drive_Library_opened",
  drive_file_selected: "drive_file_selected",
  guru_call_icon_clicked: "guru_call_icon_clicked",
  guru_audio_call_started: "guru_audio_call_clicked",
  guru_video_call_started: "guru_video_call_clicked",
  impiort_file_button_clicked: "import_file_button_clicked",
  google_drive_login_modal_for_loggedin_user:
    "google_drive_login_modal_for_loggedin_user",
  google_drive_file_all_pages_selected: "google_drive_file_all_pages_selected",
  google_drive_file_start_end_page_selected:
    "google_drive_file_start_end_page_selected",
  guru_call_ended: "guru_call_ended_by_user",
  guru_call_accepted: "guru_call_accepted",
  guru_call_rejected_by_guru: "guru_call_rejected_by_guru",
  guru_call_not_connected: "guru_call_not_connected",
  drive_file_clicked: "drive_file_button_clicked",
  drive_import_file_submit_clicked: "drive_import_file_submit_clicked",
  guru_call_joined: "guru_call_joined",
  drive_select_different_clicked: "drive_select_different_clicked",
  quiz_settings_button_clicked: "quiz_settings_button_clicked",
  quiz_settings_question_type_selected: "quiz_settings_question_type_selected",
  quiz_settings_question_difficulty_level_selected:
    "quiz_settings_difficulty_level_selected",
  quiz_settings_question_count_selected:
    "quiz_settings_question_count_selected",
  capture_photo_button_clicked: "capture_photo_button_clicked",
  capture_photo_modal_capture_button_clicked:
    "capture_photo_modal_capture_button_clicked",
  capture_photo_modal_switch_button_clicked:
    "capture_photo_modal_switch_button_clicked",
  capture_photo_modal_done_button_clicked:
    "capture_photo_modal_done_button_clicked",
  capture_photo_modal_cancel_button_clicked:
    "capture_photo_modal_cancel_button_clicked",
  capture_photo_modal_close_button_clicked:
    "capture_photo_modal_close_button_clicked",
  paste_web_url_button_clicked: "paste_web_url_button_clicked",
  paste_web_url_confirm_button_clicked: "paste_web_url_confirm_button_clicked",
  paste_web_url_cancel_button_clicked: "paste_web_url_cancel_button_clicked",
  user_managment_info_modal_opened_from_profile:
    "user_managment_info_modal_opened_from_profile",
  user_managment_info_modal_info_skipped:
    "user_managment_info_modal_info_skipped",
  user_managment_info_modal_closed_without_savings:
    "user_managment_info_modal_closed_without_savings",
  user_managment_info_modal_info_saved: "user_managment_info_modal_info_saved",
  alert_modal_close_btn_clicked: "alert_modal_close_btn_clicked",
  paste_icon_btn_clicked: "paste_icon_btn_clicked",
  upload_file_icon_btn_clicked: "upload_file_icon_btn_clicked",
  integrate_file_btn_clicked: "integrate_file_btn_clicked",
  download_xml_btn_clicked: "download_xml_btn_clicked",
  download_aiken_btn_clicked: "download_aiken_btn_clicked",
  download_qt12_btn_clicked: "download_qt12_btn_clicked",
  download_google_sheet_clicked: "download_google_sheet_clicked",
  integrate_file_btn_close_btn_clicked: "integrate_file_btn_close_btn_clicked",
  pricing_modal_tab_clicked: "pricing_modal_tab_clicked",
  pricing_modal_upgrade_tab_clicked: "pricing_modal_upgrade_tab_clicked",
  pricing_modal_cross_icon_clicked: "pricing_modal_cross_icon_clicked",
  pricing_modal_yearly_tab_btn_selected:
    "pricing_modal_yearly_tab_btn_selected",
  pricing_modal_monthly_tab_btn_selected:
    "pricing_modal_monthly_tab_btn_selected",
  pricing_modal_start_trial_btn_clicked:
    "pricing_modal_start_trial_btn_clicked",
  pricing_modal_full_feature_breakdown_btn_clicked:
    "pricing_modal_full_feature_breakdown_btn_clicked",
  pricing_modal_basic_plan_tab_selected:
    "pricing_modal_basic_plan_tab_selected",
  pricing_modal_downgrade_btn_clicked: "pricing_modal_downgrade_btn_clicked",
  pricing_modal_cancel_downgrade_btn_clicked:
    "pricing_modal_cancel_downgrade_btn_clicked",
  pricing_modal_basic_plan_current_plan_btn_clicked:
    "pricing_modal_basic_plan_current_plan_btn_clicked",
  pricing_modal_pro_plan_current_plan_btn_clicked:
    "pricing_modal_pro_plan_current_plan_btn_clicked",
  pricing_modal_basic_plan_free_btn_clicked:
    "pricing_modal_basic_plan_free_btn_clicked",
  pricing_modal_pro_plan_free_btn_clicked:
    "pricing_modal_pro_plan_free_btn_clicked",
  pricing_modal_pro_plan_tab_selected: "pricing_modal_pro_plan_tab_selected",
  pricing_and_subscription_modal_btn_clicked:
    "pricing_and_subscription_modal_btn_clicked",
  subscription_details_tab_selected: "subscription_details_tab_selected",
  payment_details_tab_selected: "payment_details_tab_selected",
  payment_details_tab_update_btn_clicked:
    "payment_details_tab_update_btn_clicked",
  payment_details_tab_pay_via_card_btn_clicked:
  "payment_details_tab_pay_via_card_btn_clicked",
  payment_details_tab_i_own_screen_btn_clicked:"payment_details_tab_i_own_screen_btn_clicked",
  billing_history_tab_selected: "billing_history_tab_selected",
  billing_history_pagination_btn_clicked:
    "billing_history_pagination_btn_clicked",
  billing_history_invoice_btn_clicked: "billing_history_invoice_btn_clicked",
  pricing_and_subscription_modal_view_plan_details_btn_clicked:
    "pricing_and_subscription_modal_view_plan_details_btn_clicked",
  pricing_and_subscription_modal_close_btn_clicked:
    "pricing_and_subscription_modal_close_btn_clicked",
  pricing_and_subscription_modal_downgrade_btn_clicked:
    "pricing_and_subscription_modal_downgrade_btn_clicked",
  pricing_and_subscription_modal_cancel_downgrade_btn_clicked:
    "pricing_and_subscription_modal_cancel_downgrade_btn_clicked",
  downgrade_modal_close_btn_clicked: "downgrade_modal_close_btn_clicked",
  downgrade_modal_downgrade_btn_clicked:
    "downgrade_modal_downgrade_btn_clicked",
  downgrade_modal_consent_checkbox_clicked:
    "downgrade_modal_consent_checkbox_clicked",
  downgrade_modal_reason_clicked: "downgrade_modal_reason_clicked",
  downgrade_modal_confirm_downgrade_btn_clicked:
    "downgrade_modal_confirm_downgrade_btn_clicked",
  select_payment_method_modal_opened: "select_payment_method_modal_opened",
  select_payment_method_modal_close_btn_clicked:
    "select_payment_method_modal_close_btn_clicked",
  select_payment_method_modal_back_btn_clicked:
    "select_payment_method_modal_back_btn_clicked",
  select_payment_method_modal_pay_card_btn_clicked:
    "select_payment_method_modal_pay_card_btn_clicked",
  select_payment_method_modal_own_screen_btn_clicked:
    "select_payment_method_modal_own_screen_btn_clicked",
  smart_screen_modal_submit_btn_clicked:
    "smart_screen_modal_submit_btn_clicked",
  smart_screen_modal_contact_support_btn_clicked:
    "smart_screen_modal_contact_support_btn_clicked",
  smart_screen_modal_close_btn_clicked: "smart_screen_modal_close_btn_clicked",
  upgrade_modal_update_btn_clicked:"upgrade_modal_update_btn_clicked",
  upgrade_modal_continue_with_basic_plan_btn_clicked:"upgrade_modal_continue_with_basic_plan_btn_clicked",
  upgrade_modal_close_btn_clicked:"upgrade_modal_close_btn_clicked",
  confirmation_modal_close_btn_clicked:"confirmation_modal_close_btn_clicked",
  user_subscription_pro_plan:"Pro Plan",
  user_subscription_basic_plan:"Basic Plan",
  user_subscription_trial_plan:"Trial Plan"
};
export const initGA = () => {
  if (conf.analytics_key) {
    ReactGA.initialize(conf.analytics_key);
  }
};

export const logPageView = () => {
  if (conf.analytics_key) {
    ReactGA.send("pageview");
  }
};

export const logEvent = (
  category = "",
  action = "",
  value = undefined,
  label = ""
) => {
  if (category && action && conf.analytics_key) {
    setTimeout(function () {
      ReactGA.event({
        category: category,
        action: action,
        value: value,
        label: label,
      });
    }, 500);
  }
};

export const logException = (description = "", fatal = false) => {
  if (description) {
    ReactGA.exception({ description, fatal });
  }
};
