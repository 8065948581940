import { Box, IconButton } from "@mui/material";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import styled from "@mui/system/styled";

const Container = styled(Grid)(
  ({ isIpad, theme, isMobile, isMobileLandscape }) => ({
    width:
      isMobile || isMobileLandscape
        ? "100%"
        : isIpad
        ? "80% !important"
        : "40%",
    // height: '60%',
    top: isMobile || isMobileLandscape ? "12%" : `48%`,
    left: isMobile || isMobileLandscape ? "10px" : `50%`,
    position: "absolute",
    transform:
      isMobile || isMobileLandscape ? "unset" : `translate(-50%, -50%)`,
    backgroundColor: isMobile || isMobileLandscape ? "unset" : "#F8F8F8",
    [`${theme.breakpoints.down(
      "xxl"
    )}  and (orientation: landscape) and (hover: none) and (pointer: coarse)`]:
      {
        width: "85%",
      },
    [`${theme.breakpoints.down("md")}`]: {
      width: "95%",
    },
  })
);

const CameraBox = styled(Box)(({}) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
}));

const HeadingBar = styled(Box)(({}) => ({
  height: "45px",
  backgroundColor: "white",
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
  paddingLeft: "30px",
  paddingRight: "20px",
  marginBottom: "30px",
  borderRadius: "8px !important",
}));

const Heading = styled(Typography)(({}) => ({
  fontFamily: "Open Sans",
  fontWeight: 600,
  fontSize: "17px",
  lineHeight: "23.15px",
  color: "#333333",
}));

const CrossBtn = styled(IconButton)(({}) => ({
  width: "14px",
  height: "14px",
  color: "black",
}));

export default {
  Container,
  HeadingBar,
  Heading,
  CrossBtn,
  CameraBox,
};
