import styled from "@mui/system/styled";
import Box from "@mui/material/Box";
import { Button, Divider, Icon, Typography, Image } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
const ModalHeader = styled(Box)({
  // padding: 8,
  // paddingRight: 12,
  // paddingLeft: 25,
  alignItems: "center",
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  backgroundColor: "#FFFFFF",
  boxShadow: "rgba(100, 100, 111, 0.2) 0px 3px 29px 0px",
});

const ModalActions = styled(Box)({
  padding: 5,
  paddingTop: 15,
  paddingBottom: 15,
  borderTop: "1px solid silver",
  display: "flex",
  justifyContent: "center",
});

const ModalBox = styled(Box)(({ theme }) => ({
  width: "25%",
  [theme.breakpoints.down("xxl")]: {
    width: "30%",
  },
  [theme.breakpoints.down("lg")]: {
    width: "50%",
  },
  [theme.breakpoints.down("md")]: {
    width: "65%",
  },
  [theme.breakpoints.down("sm")]: {
    width: "95%",
  },
}));

const StyledHeading = styled(Typography)(({ theme }) => ({
  fontFamily: "Open Sans",
  fontWeight: 600,
  fontSize: "24px",
  lineHeight: "20px",
  textAlign: "center",
  paddingRight: "6px",
}));

const StyledDesc = styled(Typography)(({ theme }) => ({
  fontFamily: "Open Sans",
  fontWeight: 400,
  fontSize: "18px",
  lineHeight: "20px",
  textAlign: "center",
}));

const ModalContentBox = styled(Box)(({ theme,isT7 }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  height:isT7?'360px':'unset',
  overflow:isT7?'auto':'unset'
}));

const ModalButton = styled(Button)(({ borderColor }) => ({
  color: "#333333",
  textTransform: "none",
  height: "49px",
  width: "280px",
  marginTop: "10px",
  marginBottom: "10px",
  border: `1px solid ${borderColor}`,
  backgroundColor: "#FFFFFF",
}));
const StyledDivider = styled(Divider)(({}) => ({
  width: "90%",
  marginBottom: "20px",
  color: "#333333",
  marginTop: "10px",
  marginBottom: "10px",
}));

const StyledBtnDivider = styled(Divider)(({}) => ({
  width: "280px",
  marginBottom: "20px",
  color: "#333333",
  marginTop: "10px",
  marginBottom: "10px",
}));
const ActionText = styled(Typography)(({ theme }) => ({
  fontFamily: "Open Sans",
  fontWeight: 400,
  fontSize: "18px",
  lineHeight: "20px",
  textAlign: "center",
  marginBottom: "20px",
}));

const customStyling = {
  exportModal: {
    position: "absolute",
    top: { xs: "45%", sm: "50%" },
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    borderRadius: 2,
    boxShadow: 24,
    overflowY: "auto",
    maxHeight: "90%",
    border: "none",
  },
};
const PriceSection = styled(Typography)(({ theme }) => ({
  fontFamily: "Open Sans",
  fontWeight: 400,
  fontSize: "18px",
  lineHeight: "20px",
  height: "15%",
  paddingBottom: "12px",
}));
const TextContainer = styled(Typography)(({}) => ({
  fontWeight: 400,
  lineHeight: "21.79px",
  fontFamily: "Open Sans",
  color: "#333333",
  fontSize: "16px",
  width: "90%",
  marginBottom: "15px",
}));

const PriceTextContainer = styled(Typography)(({}) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
}));

const PaymentOptionContainer = styled(Typography)(({}) => ({
  fontFamily: "Open Sans",
  fontWeight: 400,
  fontSize: "18px",
  lineHeight: "20px",
  // height: '15%',
  paddingBottom: "12px",
  width: "100%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "column",
}));

const ButtonIcon = styled("img")({
  width: "24px",
  height: "24px",
  marginRight: "12px",
});
const ModalFooter = styled(Box)(({}) => ({
  width: "100%",
  padding: "10px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  borderTop: "1px solid #00000033",
}));
const FooterButton = styled(Button)(
  ({ backgroundColor, fontColor, borderColor }) => ({
    width: "95px",
    height: "39px",
    textTransform: "none",
    backgroundColor: backgroundColor,
    color: fontColor,
    border: 1,
    borderColor: borderColor,
  })
);
const ListIcon = styled("img")({
  width: "10px",
  height: "10px",
  marginRight: "6px",
});

const ListContainer = styled(Box)(({}) => ({
  width: "100%",
  display: "flex",
  paddingLeft: "25px",
  alignItems: "center",
  height: "22px",
  marginBottom: "15px",
}));

const ListTextContainer = styled(Typography)(({}) => ({
  width: "100%",
  padding: "5px 3px",
  fontWeight: "400",
  fontFamily: "Open Sans",
  fontSize: "14px",
  lineHeight: "19.07px",
  color: "#333333B2",
}));

const ReasonListText = styled(Typography)(({}) => ({
  width: "100%",
  fontWeight: "400",
  fontFamily: "Open Sans",
  fontSize: "16px",
  lineHeight: "21.79px",
  color: "#333333",
  marginTop: "-15px",
  marginLeft: "4px",
  cursor:'pointer'
}));

const ConsentMsg = styled(Typography)(({ error }) => ({
  fontWeight: "400",
  fontFamily: "Open Sans",
  fontSize: "16px",
  lineHeight: "21.79px",
  color: error === true ? "red" : "#333333",
  marginTop: "30px",
  marginBottom: "25px",
  cursor:'pointer'
}));

const CheckBoxStyles = styled(Checkbox)(({ theme, downgradeModal }) => ({
  width: "18px",
  height: "18px",
  marginRight: "10px",
  marginTop: downgradeModal === false ? "30px" : "10px",
  marginBottom: "25px",
  "&.Mui-checked": {
    color: theme.palette.secondary.main,
  },
}));
const DisclaimerBox = styled(Box)(({isT7}) => ({
  width: "90%",
  display: "flex",
  alignItems: "center",
  padding: "10px",
  border: "1px solid #7D00BA",
  borderRadius: "5px",
  marginBottom: isT7?"10px":"30px",
}));

const DisclaimerText = styled(Typography)(({}) => ({
  fontWeight: 400,
  lineHeight: "21.79px",
  fontFamily: "Open Sans",
  color: "#7D00BA",
  fontSize: "16px",
  width: "100%",
}));
export default {
  customStyling,
  ModalHeader,
  ModalActions,
  ModalBox,
  ModalContentBox,
  StyledHeading,
  ModalButton,
  StyledDesc,
  StyledDivider,
  StyledBtnDivider,
  ActionText,
  PriceSection,
  TextContainer,
  PriceTextContainer,
  PaymentOptionContainer,
  ButtonIcon,
  ModalFooter,
  FooterButton,
  ListIcon,
  ListContainer,
  ListTextContainer,
  CheckBoxStyles,
  DisclaimerBox,
  DisclaimerText,
  ConsentMsg,
  ReasonListText,
};
