import styled from "@mui/system/styled";
import Box from "@mui/material/Box";
import { Button, Divider, Icon, Typography, Image, Link } from "@mui/material";

const ModalHeader = styled(Box)({
  // padding: 8,
  // paddingRight: 12,
  // paddingLeft: 25,
  alignItems: "center",
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  boxShadow: "rgba(100, 100, 111, 0.2) 0px 3px 29px 0px",
});

const ModalActions = styled(Box)({
  padding: 5,
  paddingTop: 15,
  paddingBottom: 15,
  borderTop: "1px solid silver",
  display: "flex",
  justifyContent: "center",
});

const ModalBox = styled(Box)(({ theme }) => ({
  width: "25%",
  [theme.breakpoints.down("lg")]: {
    width: "60%",
  },
  [theme.breakpoints.down("sm")]: {
    width: "95%",
  },
}));

const StyledHeading = styled(Typography)(({theme})=>({
    fontFamily:'Open Sans',
    fontWeight:600,
    fontSize:'24px',
    lineHeight:'20px',
    textAlign:'center',
    paddingRight: '6px',
}))

const StyledDesc = styled(Typography)(({theme})=>({
    fontFamily:'Open Sans',
    fontWeight:400,
    fontSize:'18px',
    lineHeight:'20px',
    textAlign:'center'
}))

const ModalContentBox = styled(Box)({
  display:'flex',
  flexDirection:'column',
  justifyContent:'center',
  alignItems:'center',
});

const ModalButton = styled(Button)(({ borderColor,disabledColor }) => ({
  color:"#333333",
  textTransform:'none',
  height:"49px",
  width:"280px",
  marginTop:"10px",
  marginBottom:"10px",
  border: disabledColor===true?"1px solid grey":`1px solid ${borderColor}`,
  backgroundColor: '#FFFFFF'
}));
const StyledDivider = styled(Divider)(({}) => ({
    width: "90%",
    marginBottom: "20px",
    color: "#00000033",
    marginTop:"10px",
    marginBottom:"10px",
}));

const StyledBtnDivider = styled(Divider)(({}) => ({
  width: "280px",
  marginBottom: "20px",
  color: "#333333",
  marginTop:"10px",
  marginBottom:"10px",
}));
const ActionText = styled(Typography)(({theme})=>({
    fontFamily:'Open Sans',
    fontWeight:400,
    fontSize:'18px',
    lineHeight:'20px',
    textAlign:'center',
    marginBottom:"20px"
}))

const customStyling = {
    exportModal: {
      position: "absolute",
      top: { xs: "45%", sm: "50%" },
      left: "50%",
      transform: "translate(-50%, -50%)",
      bgcolor: "background.paper",
      borderRadius: 2,
      boxShadow: 24,
      overflowY: "auto",
      maxHeight: "90%",
      border: "none",
    },
};
const PriceSection = styled(Typography)(({theme})=> ({
  fontFamily:'Open Sans',
  fontWeight:400,
  fontSize:'18px',
  lineHeight:'20px',
  height: '15%',
  paddingBottom: '12px'
}));
const TextContainer = styled(Typography)(({}) =>({
  gap: '10px',
  border: '0px 0px 1px 0px',
  opacity: '0px',
  fontSize: '16px',
  paddingBottom: '7px 7px',
  
}));

const PriceTextContainer = styled(Typography)(({}) =>({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}));

const PaymentOptionContainer = styled(Typography)(({}) =>({
  fontFamily:'Open Sans',
  fontWeight:400,
  fontSize:'18px',
  lineHeight:'20px',
  // height: '15%',
  paddingBottom: '12px',
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  
}));

const ButtonIcon = styled('img')(({disabledColor}) =>({
  width: "24px", 
  height: "24px",
  marginRight: '12px',
  opacity: disabledColor===true?0.4:1
}));
const ModalFooter = styled(Box)(({}) =>({
  borderTop: "1px solid #00000033",
  width: '100%',
  padding: '20px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}));
const FooterButton = styled(Button)(({backgroundColor, fontColor, borderColor}) =>({
  width: '95px',
  height:'33px',
  backgroundColor: backgroundColor,
  color: fontColor,
  border: 1,
  borderColor: borderColor,
  textTransform:'none',
}));

const SubscriptionPolicyBtn = styled(Link)(
  ({
    theme,
  }) => ({
    cursor: "pointer",
    textDecoration: "none",
    color: "#5e9beb",
    fontSize: "11px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "normal",
    marginTop: "10px",
    marginLeft:"180px",
  })
);
export default {
  customStyling,
  ModalHeader,
  ModalActions,
  ModalBox,
  ModalContentBox,
  StyledHeading,
  ModalButton,
  StyledDesc,
  StyledDivider,
  StyledBtnDivider,
  ActionText,
  PriceSection,
  TextContainer,
  PriceTextContainer,
  PaymentOptionContainer,
  ButtonIcon,
  ModalFooter,
  FooterButton,
  SubscriptionPolicyBtn
};
