import { IconButton, useMediaQuery } from "@mui/material";
import Grid from "@mui/system/Unstable_Grid";
import Styled from "./styles";
import Feedback from "../feedback/component";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import React, { useState, useEffect, useRef } from "react";
import { conf } from "../../version";
import ConnectionModal from "../connection-modal/component";
import {
  setQuizInputText,
  setShowQuizContentPage,
} from "../../redux/slices/quizSlice";
import {
  APP_NAME,
  COMPANY_NAME,
  MAX_TXT_FILE_UPLOAD_SIZE,
  ONESCREEN_WEBSITE_URL,
  USER_STATE,
} from "../../settings";
import { useDispatch, useSelector } from "react-redux";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { useTheme } from "@mui/material/styles";
import { Box } from "@mui/system";
import intlMessages from "../../locales/en";
import { useNavigate } from "react-router-dom";
import DrawerMenu from "../drawer/component";
import { useLocation } from "react-router-dom";
import { Events, logEvent, Categories } from "../../analytics";
import { decodeToken } from "../../common/sign_in_service";
import {
  setAuthToken,
  setIsSignedIn,
  setOpenExpiryModal,
  setUserData,
} from "../../redux/slices/logInSlice";
import { isUserSignedIn } from "../google-forms/service";
import { getAuthToken } from "../../api/service";
import {
  checkUrlIsGoogleForm,
  getFirstCharacters,
  getNavigateURL,
} from "../../util/signin-user-data";
import UserDrawerMenu from "../user-menu/component";
import AvatarUI from "../avatar/component";
import { header_images_base64 } from "./header-images-base64";
import QuizLibrary from "../quiz-library/component";
import GuruDropDown from "../../common/guru/component-dropdown";
import { store } from "../../redux/store";
import GuruCall from "../../common/guru/component-call-modal";
import { getGuestUniqueId } from "../../common/guru/service";
import GuruIcon from "../../common/guru/component";
import svgs from "../../common/svgs";
import api from "../../../src/api/index";
import {
  setOpenPaymentModal,
  setOpenPricingModal,
  setOpenSubscriptionModal,
} from "../../redux/slices/featuresSlice";
import PricingModal from "../pricing/component";
import SubscriptionModal from "../subscription-modal/component";
import IFPSModal from "../../common/ifps-modal/component";
import TrialInfoModal from "../../common/trial-info-modal/component";
import PlanIFPS from "../../common/plan-ifps-modal/component";
import AlertBox from "../alert/component";
import { initAdds } from "../../adds_conversion";
import { getPriceDetails } from "../../api/payment/methods";
const ver = conf.version;
function Header() {
  const theme = useTheme();
  const location = useLocation();
  const isAboutPage = location.pathname === "/about";
  const isLoginPage = location.pathname === "/login";
  const isSignUpPage =
    location.pathname === "/signup" || location.pathname === "/signup-email";
  const isForgotResetPage =
    location.pathname === "/resetpassword" ||
    location.pathname === "/forgotpassword" ||
    location.pathname === "/verify";
  const isQuizPage =
    location.pathname === "/" || isAboutPage || isForgotResetPage;

  const isMobile = useMediaQuery(theme.breakpoints.down("sm"), {
    noSsr: true,
  });
  const isMobileLandscape = useMediaQuery(
    `${theme.breakpoints.down(
      "md"
    )}  and (orientation: landscape) and (hover: none) and (pointer: coarse)`,
    {
      noSsr: true,
    }
  );
  const isIpadLandscape = useMediaQuery(
    "(min-width: 800px) and (max-width: 1400px) and (orientation: landscape) and  (hover: none) and (pointer: coarse)",
    {
      noSsr: true,
    }
  );
  const isTl = useMediaQuery("(min-width: 1280px) and (max-width: 1280px) ", {
    noSsr: true,
  });
  const isIpad = useMediaQuery(
    window.navigator.userAgent.includes("OneScreen_TL7")
      ? "(min-width:620px) and (max-width:960px) and (orientation: landscape) "
      : "(min-width:620px) and (max-width:840px) and (orientation: portrait) and  (hover: none) and (pointer: coarse)",
    {
      noSsr: true,
    }
  );
  const dispatch = useDispatch();
  const { quizInputText, showQuizContentPage } = useSelector(
    (state) => state.quiz
  );
  const { openPricingModal, openSubscriptionModal, openPaymentModal } =
    useSelector((state) => state.feature);

  const { openLibrary } = useSelector((state) => state.library);
  const { isSignedIn, userData, userState, stripeStatus} = useSelector(
    (state) => state.logIn
  );
  const { price, plan, duration } = useSelector((state) => state.payment);
  const { isLoading, showGuruCall } = useSelector((state) => state.quiz);
  const [isOpenFeedback, setIsOpenFeedback] = useState(false);
  const [isOpenConnectionModal, setIsOpenConnectionModal] = useState(false);
  const [snackBarstate, setSnackBar] = useState({
    openSnackBar: false,
    vertical: "top",
    horizontal: "right",
    type: "success",
    message: "",
  });
  const [visible, setVisible] = useState(true);
  const [anchorEl, setAnchorEl] = useState(null);
  const openMenu = Boolean(anchorEl);
  const navigate = useNavigate();
  const quizBtnRef = useRef(null);
  const aboutBtnRef = useRef(null);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [quizBtn, setQuizBtn] = useState(false);
  const [aboutBtn, setAboutBtn] = useState(false);
  const [isClickedGuru, setIsClickedGuru] = useState(false);
  const [userNameData, setUserNameData] = useState({
    avatar: "--",
    name: " ",
  });
  const [profileAnchorEl, setProfileAnchorEl] = useState(null);
  const [isGoogleForm, setIsGoogleForm] = useState(false);
  const [hideLinks, setHideLinks] = useState(false);
  const [openGuruCallModal, setOpenGuruCallModal] = useState(false);
  const [isAudio, setIsAudio] = useState(false);
  const [clickedGuruIcon, setClickedGuruIcon] = useState(false);
  const [ifpsModalState, setIfpsModalState] = useState(false);
  const [trialModalText, setTrialModalText] = useState({
    heading: "",
    desc: "",
  });
  const [openTrialModal, setOpenTrialModal] = useState(false);
  const [feedbackHeading, setFeedbackHeading] = useState(false);
  const [purpose, setPurpose] = useState("");
  const open = Boolean(profileAnchorEl);
  const [openAlertBox, setOpenAlertBox] = useState(false);
  const [alertMsg, setAlertMsg] = useState({
    message: "",
    icon: "",
  });
  const [lastModalVisited, setLastModalVisited] = useState(false);
  const [showBadge, setShowBadge] = useState(false);
  const [subscriptionModalFlag,setSubscriptionModalFlag]= useState(false)
  const handleClick = (event) => {
    setProfileAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setProfileAnchorEl(null);
  };

  async function handleOrientationChange_() {
    if (
      (isMobile || isMobileLandscape) &&
      (openLibrary === true || openSubscriptionModal === true)
    )
      return;
    setAnchorEl(false);
    setProfileAnchorEl(false);
    const callInProgress = store.getState().guru.callInProgress;
    const callConnected = store.getState().guru.callConnected;
    const cancelClick = store.getState().guru.cancelClick;
    if (callInProgress && !cancelClick) {
      // open call modal
      setOpenGuruCallModal(true);
    }
  }
  useEffect(() => {
    const callInProgress = store.getState().guru.callInProgress;
    const callConnected = store.getState().guru.callConnected;
    const cancelClick = store.getState().guru.cancelClick;
    if (callInProgress && !cancelClick) {
      // open call modal
      setOpenGuruCallModal(true);
    }
    window.addEventListener("orientationchange", handleOrientationChange_);
    return () => {
      window.removeEventListener("orientationchange", handleOrientationChange_);
    };
  }, []);

  useEffect(() => {
    setIsGoogleForm(checkUrlIsGoogleForm());
    const setIsSignedInOrNot = async () => {
      if (isUserSignedIn()) {
        const token = getAuthToken();
        dispatch(setAuthToken(token));
        const decodedData = await decodeToken(token);
        dispatch(setUserData(decodedData));
        dispatch(setIsSignedIn(true));
        // // console.log("userData", decodedData);
        const userNameDataObject = getFirstCharacters(
          decodedData.firstName,
          decodedData.lastName
        );
        setUserNameData({
          ...userNameData,
          avatar: userNameDataObject.avatar,
          name: userNameDataObject.name,
        });
      }
    };
    setIsSignedInOrNot();
    if (isAboutPage) {
      setAboutBtn(true);
    } else {
      setQuizBtn(true);
    }
  }, []);

  useEffect(() => {
    // // console.log("updated event triggered");
    setIsGoogleForm(checkUrlIsGoogleForm());
    if (!!userData) {
      const userNameDataObject = getFirstCharacters(
        userData.firstName,
        userData.lastName
      );
      setUserNameData({
        ...userNameData,
        avatar: userNameDataObject.avatar,
        name: userNameDataObject.name,
      });
    }
  }, [userData]);

  useEffect(() => {
    if (
      userState === USER_STATE.BLOCKED_USER_USER ||
      userState === USER_STATE.EXPIRED_USER ||
      userState === USER_STATE.NOT_VERIFIED_USER
    ) {
      setHideLinks(true);
    } else if (
      userState === USER_STATE.LOGGED_OUT_USER ||
      userState === USER_STATE.SIGNED_IN_USER
    ) {
      setHideLinks(false);
    }
  }, [userState]);

  useEffect(() => {
    setIsGoogleForm(checkUrlIsGoogleForm());
  }, [location.search]);

  useEffect(() => {
    let timer = null;
    if (
      isSignedIn === true &&
      stripeStatus !== null &&
      userData?.subscriptionPlan !== null && userState!==USER_STATE.NOT_VERIFIED_USER
    ) {
      timer = setTimeout(() => {
        setShowBadge(true);
      }, 100);
    } else {
      setShowBadge(false);
    }
    return () => clearTimeout(timer);
  }, [isSignedIn, stripeStatus,userState,userData]);

  const handleClickMenuBtn = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseMenuBtn = () => {
    setAnchorEl(null);
  };
  const readTextFile = async (e) => {
    e.preventDefault();
    const file = e.target.files[0];
    const validExtensions = [
      "text/plain",
      "image/jpeg",
      "image/png",
      "application/pdf",
      "application/msword",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      "application/vnd.ms-excel",
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      "application/vnd.ms-powerpoint",
      "application/vnd.openxmlformats-officedocument.presentationml.presentation",
    ];
    const isValidExtension = validExtensions.includes(file.type);
    if (!isValidExtension) {
      return setSnackBar({
        ...snackBarstate,
        type: "error",
        message: intlMessages.invalidFileType,
        openSnackBar: true,
      });
    }
    if (file?.size > MAX_TXT_FILE_UPLOAD_SIZE) {
      return setSnackBar({
        ...snackBarstate,
        type: "error",
        message: intlMessages.maxFileSize,
        openSnackBar: true,
      });
    }
    dispatch(setQuizInputText(""));
    const reader = new FileReader();
    reader.onload = async (e) => {
      const text = e.target.result;
      dispatch(setQuizInputText(text));
      dispatch(setShowQuizContentPage(false));
      document.getElementById("quiz-input-field").focus();
    };
    reader.readAsText(file);
  };
  const { vertical, horizontal, openSnackBar } = snackBarstate;
  const handleOpenFeedback = () => {
    setIsOpenFeedback(true);
  };
  const handleCloseFeedback = () => {
    setIsOpenFeedback(false);
    setFeedbackHeading(false);
    setPurpose("");
    if (userState === USER_STATE.EXPIRED_USER && openAlertBox === false) {
      return dispatch(setOpenExpiryModal(true));
    }
    if (lastModalVisited === true) {
      setLastModalVisited(false);
      setIfpsModalState(true);
    }
  };

  const handleOnSubmitFeedback = () => {
    setIsOpenFeedback(false);
    setFeedbackHeading(false);
    setLastModalVisited(false);
    setPurpose("");
    if (userState === USER_STATE.EXPIRED_USER && openAlertBox === false) {
      return dispatch(setOpenExpiryModal(true));
    }
  };

  const handleOpenConnectionModal = () => {
    setIsOpenConnectionModal(true);
  };
  const handleCloseConnectionModal = () => {
    setIsOpenConnectionModal(false);
  };
  const handleCloseSnackBar = () => {
    setSnackBar({ ...snackBarstate, openSnackBar: false });
  };

  const handleOpenGuru = () => {
    setIsClickedGuru(true);
  };

  const handleCloseGuru = () => {
    setIsClickedGuru(false);
  };

  const handleOpenDrawer = () => {
    handleCloseGuru();
    setOpenDrawer(true);
  };

  const closeGuruCallModal = () => {
    setOpenGuruCallModal(false);
  };

  // const openGuruCallModal = () => {
  //   setStartCall(false);
  // };

  const openClickedGuruIcon = () => {
    setClickedGuruIcon(true);
  };

  const closeClickedGuruIcon = () => {
    setClickedGuruIcon(false);
  };

  const initiateCall = (data) => {
    handleCloseGuru();
    // send params to start the call
    setIsAudio(data.isAudio);
    setOpenGuruCallModal(true);
  };

  const viewFeedbackSubmissionMessage = () => {
    setAlertMsg({
      message:
        feedbackHeading === true
          ? intlMessages.feedbackModalSerialSuccessMessage
          : intlMessages.feedbackSubmissionMsg,
      icon: "/svgs/successicon.svg",
      isError: false,
    });
    setOpenAlertBox(true);
    return;
    // return setSnackBar({
    //   ...snackBarstate,
    //   type: "success",
    //   message: intlMessages.feedbackSubmissionMsg,
    //   openSnackBar: true,
    // });
  };
  const showErrorMessage = (msgText) => {
    setAlertMsg({
      message: msgText,
      icon: "/svgs/erroricon.svg",
      isError: true,
    });
    setOpenAlertBox(true);
    return;
    // return setSnackBar({
    //   ...snackBarstate,
    //   type: "error",
    //   message: msgText,
    //   openSnackBar: true,
    // });
  };

  const quizBtnHandler = () => {
    setQuizBtn(true);
    setAboutBtn(false);
    const quizURL = getNavigateURL("/");
    navigate(quizURL);
    logEvent(Categories.page_view, Events.main_screen);
  };

  const aboutBtnHandler = () => {
    const aboutURL = getNavigateURL("/about");
    navigate(aboutURL);
    setQuizBtn(false);
    setAboutBtn(true);
    logEvent(Categories.page_view, Events.about_screen);
  };

  const pricingBtnHandler = () => {
    if (userData?.subscriptionPlan === "Basic")
      logEvent(
        Categories.pricing_modal,
        Events.pricing_modal_upgrade_tab_clicked
      );
    else logEvent(Categories.pricing_modal, Events.pricing_modal_tab_clicked);
    dispatch(setOpenPricingModal(true));
  };

  const navigateToUrl = (url, eventType) => {
    logEvent(Categories.user_management, eventType);
    const forgotpasswordUrl = getNavigateURL(url);
    // // console.log(forgotpasswordUrl);
    navigate(forgotpasswordUrl);
    setQuizBtn(false);
    setAboutBtn(false);
  };
  const renderMoreMenu = () => {
    return (
      <Menu
        PopoverProps={{
          style: {
            position: "fixed",
            left: "0% !important",
            top: "50%",
            transform: "translate(-50%, -50%)",
          },
        }}
        id="more-menu"
        anchorEl={anchorEl}
        open={openMenu}
        onClose={handleCloseMenuBtn}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        // ml:'-9%',position:'absolute',left:'-8.5%',
        // ml:'-8.5% !important'
        sx={{
          ml: "-8.5% !important",
          fontSize: "0.9rem",
          ".MuiList-root": { padding: 0 },
        }}
      >
        <MenuItem
          sx={{
            pt: 2,
            pb: 2,
            ml: "-2px",
            fontSize: "0.9rem",
            color: theme.palette.success.main,
            backgroundColor: theme.palette.secondary.dark,
          }}
          component="label"
        >
          <input
            hidden
            accept="text/plain"
            type="file"
            onChange={(e) => {
              readTextFile(e);
              handleCloseMenuBtn();
            }}
            onClick={(e) => {
              e.target.value = null;
              if (quizInputText.trim().length > 0) {
                e.preventDefault();
                if (!showQuizContentPage)
                  document.getElementById("file-upload-btn").click();
                else document.getElementById("file-upload-btn-header").click();
              }
            }}
          />
          <img
            style={{ height: 15, marginRight: "11px", marginLeft: "7px" }}
            src={"/svgs/upload-green.svg"}
          />
          {intlMessages.uploadFileLabel}
        </MenuItem>
        <Box sx={{ background: "gray", height: "2px" }}></Box>
        <MenuItem
          onClick={() => {
            document.getElementById("sample-text-btn").click();
            handleCloseMenuBtn();
          }}
          sx={{
            pt: 2,
            pb: 2,
            ml: "-2px",
            fontSize: "0.9rem",
            color: theme.palette.success.main,
            backgroundColor: theme.palette.secondary.dark,
          }}
          component="label"
        >
          <img
            style={{ height: 15, marginRight: "11px", marginLeft: "7px" }}
            src={"/svgs/import-green.svg"}
          />
          {intlMessages.quizImportSampleTextLabel}
        </MenuItem>
      </Menu>
    );
  };
  const renderSnackBar = () => {
    return (
      <Snackbar
        open={openSnackBar}
        anchorOrigin={{ vertical, horizontal }}
        autoHideDuration={3000}
        onClose={handleCloseSnackBar}
        key={vertical + horizontal}
      >
        <MuiAlert
          elevation={6}
          variant="filled"
          sx={{ color: "white" }}
          onClose={handleCloseSnackBar}
          severity={snackBarstate.type}
        >
          {snackBarstate.message}
        </MuiAlert>
      </Snackbar>
    );
  };
  return (
    <Styled.AppBarContainer
      id="header"
      isAboutPage={isAboutPage ? true : false}
      isIpad={isIpad ? true : false}
      isvisible={visible ? true : false}
      elevation={0}
    >
      {renderSnackBar()}
      <Styled.Toolbar
        isMobile={isMobile ? true : false}
        isMobileLandscape={isMobileLandscape ? true : false}
      >
        <Grid sx={{ display: "flex", flexDirection: "row" }}>
          <Styled.OneScreenLogo
            onClick={() => {
              const quizURL = getNavigateURL("/");
              navigate(quizURL);
            }}
            src={`${header_images_base64[0]}`}
          />
          <Box sx={{ display: "flex", flexDirection: "row" }}>
            <Styled.version>{ver}</Styled.version>
            <Styled.CopyrightText
              sx={{
                fontSize: "0.7em!important",
                position: "absolute",
                bottom: isMobileLandscape
                  ? "4px"
                  : isIpadLandscape
                  ? "5px"
                  : isTl
                  ? "4px"
                  : "11px",
                left: isMobileLandscape ? "155px" : "175px",
                display: isMobile ? "block" : "none",
              }}
              variant="p"
            >
              ©
              <Styled.OneScreenLink
                target="_blank"
                href={ONESCREEN_WEBSITE_URL}
              >
                {COMPANY_NAME}
              </Styled.OneScreenLink>
            </Styled.CopyrightText>
            <Styled.PlanBox
              isAboutPage={isAboutPage ? true : false}
              isTl={isTl ? true : false}
              isIpad={isIpad ? true : false}
              isIpadLandscape={isIpadLandscape ? true : false}
              sx={{
                display: showBadge === true ? "flex" : "none",
              }}
            >
              <Styled.PlanText>
                {userData?.isScreenSubscription === true
                  ? intlMessages.subscriptionModalIPSTrialText
                  :stripeStatus === 0
                  ? intlMessages.headerTrialLabel
                      .replace(
                        "#",
                        userData?.trialRemainingDays !== 0
                          ? userData?.trialRemainingDays
                          : userData?.trialRemainingHours != 0
                          ? userData?.trialRemainingHours
                          : userData?.trialRemainingMinutes
                      )
                      .replace(
                        "#2",
                        userData?.trialRemainingDays !== 0
                          ? intlMessages.daysLeftLabel
                          : userData?.trialRemainingHours != 0
                          ? intlMessages.hoursLeftLabel
                          : intlMessages.minutesLeftLabel
                      )
                  : userData?.subscriptionPlan === "Pro"
                  ? userData?.pricePlan === "Monthly"
                    ? intlMessages.headerProPlanMonthLabel
                    : intlMessages.headerProPlanYearLabel
                  : intlMessages.headerBasicPlanLabel}
              </Styled.PlanText>
            </Styled.PlanBox>
          </Box>
        </Grid>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-end",
          }}
        >
          <Styled.FeedbackContainer
            isAboutPage={isAboutPage ? true : false}
            sx={{ display: isLoading ? "none" : "" }}
          >
            {/* <Typography color="secondary" variant="p" component="div">
              {intlMessages.headerRightLabel.replace('{0}', APP_NAME)}
            </Typography> */}
            <Styled.HeaderButton
              id={"aboutheaderbtn"}
              sx={{
                ":hover": { color: aboutBtn ? "unset" : "#bce09f !important" },
                height: "24px",
                width: "47px",
                display: hideLinks ? "none" : "",
              }}
              onClick={aboutBtnHandler}
              ref={aboutBtnRef}
            >
              {intlMessages.aboutButtonLabel}
            </Styled.HeaderButton>
            <Styled.HeaderButton
              id={"quizheaderbtn"}
              onClick={quizBtnHandler}
              ref={quizBtnRef}
              sx={{
                ":hover": { color: quizBtn ? "unset" : "#bce09f !important" },
                display: hideLinks ? "none" : "",
              }}
            >
              {intlMessages.quizButtonLabel}
            </Styled.HeaderButton>
            <Styled.HeaderButton
              onClick={pricingBtnHandler}
              sx={{
                width: "56px",
                ":hover": { color: "#bce09f !important" },
                display: hideLinks ? "none" : "",
              }}
            >
              {stripeStatus === 12
                ? intlMessages.upgradeTabLabel
                : intlMessages.pricingTabLabel}
            </Styled.HeaderButton>
            <Styled.LoginButton
              sx={{
                display: !isSignedIn && !isLoginPage ? "" : "none",
              }}
              color="secondary"
              onClick={() =>
                navigateToUrl(
                  "/login",
                  Events.user_management_login_click_header
                )
              }
            >
              {" "}
              {intlMessages.signUpLoginText}{" "}
            </Styled.LoginButton>
            <Styled.SignUpButton
              variant="contained"
              color="secondary"
              sx={{
                display: !isSignedIn && !isSignUpPage ? "" : "none",
              }}
              onClick={() =>
                navigateToUrl(
                  "/signup",
                  Events.user_management_signup_click_header
                )
              }
              isIpad={isIpad}
            >
              {" "}
              {intlMessages.loginSignUp}{" "}
            </Styled.SignUpButton>
            {/* <Box  sx={{display:isSignedIn ? 'flex' : 'none', flexDirection:'row', alignItems: 'center', cursor: 'pointer'}}
              onClick={handleClick}
              aria-controls={open ? 'account-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={open ? 'true' : undefined}
            >
              <Avatar sx={{ bgcolor: '#F5F5F5', color: '#7DC242', marginRight: '7px', marginLeft: '10px', textTransform: 'uppercase' }}>{userNameData.avatar}</Avatar>
              <Typography color="secondary.dark" variant="body1" style={{ fontSize: '16px', color:'#FFFFFF', marginRight: '40px', }}>
              {userNameData.name}
              </Typography>

            </Box> */}
            {isSignedIn && (
              <AvatarUI
                isIpad={isIpad}
                isAboutPage={isAboutPage}
                isIpadLandscape={isIpadLandscape}
                isMobile={isMobile}
                isMobileLandscape={isMobileLandscape}
                isSignedIn={isSignedIn}
                isSignUpPage={isSignUpPage}
                isGoogleForm={isGoogleForm}
                isLoginPage={isLoginPage}
                userNameData={userNameData}
                isForgotResetPage={isForgotResetPage}
              />
            )}
            {/* <Styled.VerticalSeperator
              sx={Styled.customStyling.divider}
              orientation="vertical"
              flexItem
            /> */}
            <GuruIcon
              clickedGuruIcon={openClickedGuruIcon}
              isIpadLandscape={isIpadLandscape}
              isMobile={isMobile}
              isMobileLandscape={isMobileLandscape}
              isIpad={isIpad}
            ></GuruIcon>
            <IconButton
              aria-label="AddAnswer"
              sx={{ p: 0 }}
              onClick={handleOpenFeedback}
              color="primary"
            >
              <Styled.FeedbackLogo src={`${header_images_base64[2]}`} />
            </IconButton>
            {isMobileLandscape && (
              <IconButton
                aria-label="more-options"
                sx={{
                  p: 0,
                  color: "white",
                  ml: 2,
                  width: "6px",
                  mt: 0.5,
                  display: quizInputText.trim().length > 0 ? "flex" : "none",
                }}
                onClick={handleClickMenuBtn}
              >
                <MoreVertIcon sx={{ fontSize: 20, mb: 0.35 }} />
              </IconButton>
            )}
          </Styled.FeedbackContainer>
          {/* { (
            <Styled.CopyrightText
              sx={{ fontSize: isMobile || isMobileLandscape?"0.5em!important":"0.7em !important", pt: 0.3,mt: isMobile || isMobileLandscape? 'unset':1.1} }
              variant="p"
            >
              © {intlMessages.copyrightlabel}
              <Styled.OneScreenLink
                target="_blank"
                href="https://www.onescreensolutions.com/pk"
              >
                {COMPANY_NAME}
              </Styled.OneScreenLink>
            </Styled.CopyrightText>
          )} */}
        </Box>
      </Styled.Toolbar>
      <Styled.ToolbarMobile
        isMobile={isMobile ? true : false}
        isMobileLandscape={isMobileLandscape ? true : false}
      >
        <Grid sx={{ display: "flex", flexDirection: "row" }}>
          <Styled.OneScreenLogo
            src={"/svgs/QuizWiz-Logo.svg"}
            onClick={() => {
              const quizURL = getNavigateURL("/");
              navigate(quizURL);
            }}
          />
          <Styled.PlanBox
            isAboutPage={isAboutPage ? true : false}
            isIpad={isIpad ? true : false}
            isIpadLandscape={isIpadLandscape ? true : false}
            isMobile={isMobile ? true : false}
            isMobileLandscape={isMobileLandscape ? true : false}
            isTl={isTl ? true : false}
            sx={{
              display: showBadge === true ? "flex" : "none",
            }}
          >
            <Styled.PlanText>
              {userData?.isScreenSubscription === true
                ? intlMessages.subscriptionModalIPSTrialText
                : stripeStatus === 0
                ? intlMessages.headerTrialLabel
                    .replace(
                      "#",
                      userData?.trialRemainingDays !== 0
                        ? userData?.trialRemainingDays
                        : userData?.trialRemainingHours != 0
                        ? userData?.trialRemainingHours
                        : userData?.trialRemainingMinutes
                    )
                    .replace(
                      "#2",
                      userData?.trialRemainingDays !== 0
                        ? intlMessages.daysLeftLabel
                        : userData?.trialRemainingHours != 0
                        ? intlMessages.hoursLeftLabel
                        : intlMessages.minutesLeftLabel
                    )
                : userData?.subscriptionPlan === "Pro"
                ? userData?.pricePlan === "Monthly"
                  ? intlMessages.headerProPlanMonthLabel
                  : intlMessages.headerProPlanYearLabel
                : intlMessages.headerBasicPlanLabel}
            </Styled.PlanText>
          </Styled.PlanBox>
          <Styled.BetaLogo src={"/svgs/Beta.svg"} />
          {/* <Box
            sx={{
              display: isMobile && isAboutPage ? "flex" : "none",
              flexDirection: "row",
            }}
          >
            <Styled.version>{ver}</Styled.version>
            <Styled.CopyrightText
              sx={{
                fontSize: "0.6em!important",
                position: "absolute",
                bottom: isIpad ? "14px" : "4px",
                left: isIpad ? "115px" : "95px",
                display: isMobile ? "flex" : "none",
              }}
              variant="p"
            >
              ©
              <Styled.OneScreenLink
                target="_blank"
                href={ONESCREEN_WEBSITE_URL}
              >
                {COMPANY_NAME}
              </Styled.OneScreenLink>
            </Styled.CopyrightText>
          </Box> */}
        </Grid>
        <Grid
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-end",
          }}
        >
          <Grid
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "flex-end",
              justifyContent: "flex-end",
              mr: 0.8,
            }}
          >
            {/* <IconButton
              aria-label="feedback-user"
              sx={{ p: 0.7, pb: 0.24, pt: 0.3 }}
              onClick={handleOpenFeedback}
              color="primary"
            >
              <Styled.FeedbackLogo isIpad={isIpad?true:false} src={"/svgs/Feedback.svg"} />
            </IconButton> */}
            <Styled.SignUpButtonMobile
              variant="contained"
              color="secondary"
              sx={{
                display:
                  !isSignedIn && !isLoginPage && !isQuizPage ? "" : "none",
                marginBottom: isIpad ? "10px" : "unset",
              }}
              onClick={() =>
                navigateToUrl(
                  "/login",
                  Events.user_management_login_click_header
                )
              }
            >
              {" "}
              {intlMessages.loginLabel}{" "}
            </Styled.SignUpButtonMobile>
            <Styled.SignUpButtonMobile
              isMobile={isMobile ? true : false}
              variant="contained"
              color="secondary"
              sx={{
                display:
                  (!isSignedIn && !isSignUpPage) || (!isSignedIn && isQuizPage)
                    ? ""
                    : "none",
                marginBottom: isIpad ? "10px" : "unset",
              }}
              onClick={() =>
                navigateToUrl(
                  "/signup",
                  Events.user_management_signup_click_header
                )
              }
              isMobileLandscape={isMobileLandscape ? true : false}
              isIpad={isIpad}
            >
              {" "}
              {isIpad
                ? intlMessages.loginSignUp
                : intlMessages.mobileSignBtnText}{" "}
            </Styled.SignUpButtonMobile>
            <AvatarUI
              isIpad={isIpad}
              isAboutPage={isAboutPage}
              isIpadLandscape={isIpadLandscape}
              isMobile={isMobile}
              isMobileLandscape={isMobileLandscape}
              isSignedIn={isSignedIn}
              isSignUpPage={isSignUpPage}
              isGoogleForm={isGoogleForm}
              isLoginPage={isLoginPage}
              userNameData={userNameData}
              isQuizPage={isQuizPage}
              isForgotResetPage={isForgotResetPage}
            />
            <IconButton
              aria-label="drawer-icon"
              onClick={handleOpenDrawer}
              color="primary"
              sx={{
                pb: isIpad ? 2 : isMobile || isMobileLandscape ? "4px" : 1,
                ml: isIpad ? 2 : "unset",
              }}
            >
              <Styled.DrawerIcon
                isMobileLandscape={isMobileLandscape ? true : false}
                isIpad={isIpad ? true : false}
                src={openDrawer ? svgs["drawer-green"] : svgs["drawer-white"]}
                isMobile={isMobile}
              />
            </IconButton>
            {/* <IconButton
              aria-label="more-options"
              sx={{ p: 0, color: "white", ml: 2, width: "6px",display:quizInputText.trim().length>0?'flex':'none' }}
              onClick={handleClickMenuBtn}
            >
              <MoreVertIcon sx={{ fontSize: 20, mb: 0.35 }} />
            </IconButton> */}
          </Grid>
          {/* <Styled.CopyrightText
            sx={{ fontSize: "0.5em!important", pt: 0.2, pr: 0.45 }}
            variant="p"
          >
            © {intlMessages.copyrightlabel}
            <Styled.OneScreenLink
              target="_blank"
              href="https://www.onescreensolutions.com/pk"
            >
              {COMPANY_NAME}
            </Styled.OneScreenLink>
          </Styled.CopyrightText> */}
        </Grid>
        {renderMoreMenu()}
      </Styled.ToolbarMobile>
      {isOpenFeedback && (
        <Feedback
          purpose={feedbackHeading === true ? purpose : undefined}
          isOpen={isOpenFeedback}
          closeFeedbackCallback={handleCloseFeedback}
          closeFeedbackOnSubmit={handleOnSubmitFeedback}
          viewFeedbackSubmissionMessage={viewFeedbackSubmissionMessage}
          showErrorMessageCallback={showErrorMessage}
          handleOpenConnectionModalCallback={handleOpenConnectionModal}
          heading={feedbackHeading === true ? feedbackHeading : undefined}
        />
      )}
      {isOpenConnectionModal && (
        <ConnectionModal
          isOpen={isOpenConnectionModal}
          handleCloseConnectionModal={handleCloseConnectionModal}
        />
      )}
      {openDrawer && (
        <DrawerMenu
          isIpad={isIpad}
          isMobile={isMobile}
          isOpen={openDrawer}
          isAboutPage={isAboutPage}
          onClose={(flag) => setOpenDrawer(flag)}
          handleOpenFeedback={handleOpenFeedback}
          isQuizPage={isQuizPage}
          isMobileLandscape={isMobileLandscape}
          isForgotResetPage={isForgotResetPage}
          isIpadLandscape={isIpadLandscape}
          handleOpenGuru={openClickedGuruIcon}
        ></DrawerMenu>
      )}
      {profileAnchorEl && (
        <UserDrawerMenu
          isIpad={isIpad}
          isOpen={profileAnchorEl}
          isAboutPage={isAboutPage}
          onClose={(flag) => handleClose(flag)}
          anchorEl={profileAnchorEl}
          isForgotResetPage={isForgotResetPage}
        ></UserDrawerMenu>
      )}
      {openLibrary && (
        <QuizLibrary
          isIpad={isIpad ? true : false}
          isMobile={isMobile ? true : false}
          isMobileLandscape={isMobileLandscape ? true : false}
          isIpadLandscape={isIpadLandscape ? true : false}
        />
      )}
      {clickedGuruIcon && (
        <GuruDropDown
          isIpadLandscape={isIpadLandscape}
          isMobile={isMobile}
          isMobileLandscape={isMobileLandscape}
          isIpad={isIpad}
          isClicked={isClickedGuru}
          handleCloseGuru={handleCloseGuru}
          initiateCall={initiateCall}
          clickedGuruIcon={clickedGuruIcon}
          closeCallModal={closeClickedGuruIcon}
        ></GuruDropDown>
      )}

      {openGuruCallModal && (
        <GuruCall
          isAudio={isAudio}
          guestUniqueId={getGuestUniqueId()}
          closeCallModal={closeGuruCallModal}
          isIpadLandscape={isIpadLandscape}
          isMobile={isMobile}
          isMobileLandscape={isMobileLandscape}
          isIpad={isIpad}
        />
      )}
      {openPricingModal && (
        <PricingModal
          isIpadLandscape={isIpadLandscape}
          isMobile={isMobile}
          isMobileLandscape={isMobileLandscape}
          isIpad={isIpad}
          isTl={isTl}
          open={openPricingModal}
          onClose={() => {
            if (userState === USER_STATE.EXPIRED_USER) {
              dispatch(setOpenExpiryModal(true));
            }
            dispatch(setOpenPricingModal(false));
          }}
          onSubmit={() => {}}
        />
      )}
      {openSubscriptionModal && (
        <SubscriptionModal
          isTl={isTl}
          isIpadLandscape={isIpadLandscape}
          isMobile={isMobile}
          isMobileLandscape={isMobileLandscape}
          isIpad={isIpad}
          open={openSubscriptionModal}
          onClose={() => dispatch(setOpenSubscriptionModal(false))}
        ></SubscriptionModal>
      )}
      {openPaymentModal && (
        <PlanIFPS
          packagPrice={price}
          packageType={duration}
          open={openPaymentModal}
          onClose={() => {
            dispatch(setOpenPaymentModal(false));
            if (userState === USER_STATE.EXPIRED_USER) {
              dispatch(setOpenExpiryModal(true));
            }
          }}
          onSubmitIFPS={(flag,isSubscriptionModal) => {
            dispatch(setOpenPaymentModal(false));
            setSubscriptionModalFlag(isSubscriptionModal)
            setIfpsModalState(flag);
          }}
        ></PlanIFPS>
      )}
      {openTrialModal && (
        <TrialInfoModal
          text={trialModalText}
          open={openTrialModal}
          onClose={(flag) => setOpenTrialModal(flag)}
        ></TrialInfoModal>
      )}
      {ifpsModalState && (
        <IFPSModal
          open={ifpsModalState}
          subscriptionModalFlag={subscriptionModalFlag}
          openFeedbackModal={(open, isIFPS) => {
            setFeedbackHeading(isIFPS);
            setPurpose(intlMessages.feedbackModalSerialSubject);
            setLastModalVisited(true);
            setIsOpenFeedback(open);
            setIfpsModalState(false);
          }}
          onClose={(flag, trialModalFlag, cancelBtnFlag) => {
            setIfpsModalState(flag);
            if (!!cancelBtnFlag && userState !== USER_STATE.EXPIRED_USER)
              dispatch(setOpenPaymentModal(true));
            if (!!trialModalFlag) {
              initAdds();
              setTrialModalText({
                heading: intlMessages.trialModalYearHeading.replace(
                  "#",
                  userData?.trialDays
                ),
                desc: intlMessages.trialModalYearDesc,
              });
              setOpenTrialModal(true);
            }
            if (userState === USER_STATE.EXPIRED_USER) {
              dispatch(setOpenExpiryModal(true));
            }
          }}
        ></IFPSModal>
      )}
      {openAlertBox && (
        <AlertBox
          isIpad={isIpad ? true : false}
          isMobile={isMobile ? true : false}
          isMobileLandscape={isMobileLandscape ? true : false}
          isIpadLandscape={isIpadLandscape ? true : false}
          message={alertMsg}
          isOpen={openAlertBox}
          onClose={() => {
            setOpenAlertBox(false);
            if (userState === USER_STATE.EXPIRED_USER) {
              dispatch(setOpenExpiryModal(true));
            }
          }}
        ></AlertBox>
      )}
    </Styled.AppBarContainer>
  );
}

export default Header;
