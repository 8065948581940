import { useState, useEffect } from "react";
import intlMessages from "../../locales/en";
import { Button, Typography, IconButton, Modal, Box } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Styled from "./styles";
import { useSelector } from "react-redux";
import { Categories, Events, logEvent } from "../../analytics";

export default function UpgradeModal(props) {
  const { open, onClose, onSubmit } = props;
  const { userData } = useSelector((state) => state.logIn);
  const { upgradeModalText } = useSelector((state) => state.feature);
  const handleOnClose = () => {
    logEvent(Categories.upgrade_modal, Events.upgrade_modal_close_btn_clicked);
    onClose(false);
  };

  return (
    <>
      <Modal open={open}>
        <Styled.ModalBox sx={Styled.customStyling.exportModal}>
          <Styled.ModalHeader>
            <IconButton
              aria-label="closeWarningParagraphModal"
              onClick={handleOnClose}
            >
              <CloseIcon color="seondary.light" />
            </IconButton>
          </Styled.ModalHeader>
          <Styled.ModalContentBox>
            <Styled.StyledHeading>
              {upgradeModalText.heading}
            </Styled.StyledHeading>

            <br></br>
            <Styled.StyledDesc>{upgradeModalText.desc}</Styled.StyledDesc>
            <Styled.UpgradeBtn
              variant="contained"
              color="secondary"
              onClick={() => {
                logEvent(
                  Categories.upgrade_modal,
                  Events.upgrade_modal_update_btn_clicked
                );
                onClose(false);
                onSubmit();
              }}
            >
              {userData===null?intlMessages.pricingTabStartFreeBtnText:intlMessages.upgradeModelBtnText}
            </Styled.UpgradeBtn>
            {/* <Styled.StyledDivider orientation="horizontal">
              {intlMessages.quizInputBoxPlaceholderText3}
            </Styled.StyledDivider>
            <Styled.ActionText>{intlMessages.upgradeModelContinueText1}
            <span onClick={()=>{
              navigate('/signup')
              onClose(false)
              }} style={{marginLeft:'5px',color:'#1A73E8',cursor:'pointer'}}>{intlMessages.upgradeModelSignUpText}</span>
            </Styled.ActionText>
            <Styled.ActionText sx={{mb:'40px'}}>{intlMessages.upgradeModelAlreadyText}
            <span onClick={()=>{
              navigate('/login')
              onClose(false)
              }} style={{marginLeft:'5px',color:'#1A73E8',cursor:'pointer'}}>{intlMessages.upgradeModelLoginText}</span>
            </Styled.ActionText> */}
          </Styled.ModalContentBox>
        </Styled.ModalBox>
      </Modal>
    </>
  );
}
