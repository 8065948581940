import { useState, useEffect, useRef } from "react";
import { useTheme } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from "@mui/material";
import MobileAboutPage from "./mobile-about-page/component";
import DesktopAboutPage from "./desktop-about-page/component";
import { Events, logEvent, Categories } from "../../analytics";
import sliderImages from "../welcome-modal/slider";
import Box from "@mui/material/Box";
import { useDispatch, useSelector } from "react-redux";
import { setShowWelcomeModal } from "../../redux/slices/quizSlice";
import Fade from "@mui/material/Fade";
import { USER_STATE } from "../../settings";
import { getNavigateURL } from "../../util/signin-user-data";

function AboutPage() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { userState } = useSelector((state) => state.logIn);
  const { navigateToQuizPage } = useSelector((state) => state.library);
  const [showMainDiv, setShowMainDiv] = useState(false);
  const { consentBtnClicked } = useSelector((state) => state.quiz);
  const [sliderImage, setSliderImage] = useState(sliderImages[1]);
  const [currentIndex, setCurrentIndex] = useState(1);
  const bgRef = useRef([]);
  const theme = useTheme();
  const [reviewCurrentIndex,setReviewCurrentIndex]= useState(0)
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"), {
    noSsr: true,
  });
  const isMobileLandscape = useMediaQuery(
    `${theme.breakpoints.down(
      "md"
    )}  and (orientation: landscape) and (hover: none) and (pointer: coarse)`,
    {
      noSsr: true,
    }
  );

  const isIpad = useMediaQuery(
    window.navigator.userAgent.includes("OneScreen_TL7")
      ? "(min-width:620px) and (max-width:960px) and (orientation: landscape) "
      : "(min-width:620px) and (max-width:840px) and (orientation: portrait) and  (hover: none) and (pointer: coarse)",
    {
      noSsr: true,
    }
  );
  const isIpadLandscape = useMediaQuery(
    "(min-width: 800px) and (max-width: 1400px) and (orientation: landscape) and  (hover: none) and (pointer: coarse)",
    {
      noSsr: true,
    }
  );
  const isTl = useMediaQuery("(min-width: 1280px) and (max-width: 1280px) ", {
    noSsr: true,
  });
  const reviews = [
    {
      name: "Tony B.",
      role: "Teacher & Digital Learning Coach",
      comment:
        "I love QuizWiz. I can choose the content I want the questions focused on, copy and paste it in QuizWiz and quickly get a great quality quiz to use in Google classroom.",
    },
    {
      name: "Dylan A.",
      role: "Student",
      comment:
        "Quick, easy and super accurate - QuizWiz helps me prepare for a good grade in every exam!",
    },
  ];
  const userReviews = [
    {
      name:'Anonymous',
      rating:5,
      comment:'Excellent tool. I would recommend to anyone who want to use this awesome quiz maker.'
    },
    {
      name:'Anonymous',
      rating:4.5,
      comment:'Excellent multiple choice questions generator. Thank you.'
    },
    {
      name:'Anonymous',
      rating:5,
      comment:'This was very quick and easy to operate on the first try. Directions were simple.'
    },
    {
      name:'Anonymous',
      rating:5,
      comment:'I loved your quiz maker it easily turned my class notes into something that could help me memorize and make sure i am learning right'
    },
    {
      name:'Eric Borrego',
      rating:5,
      comment:'This is such a great tool and it really saves a lot of time.'
    }
  ]
  let list = [];
  for (let i = 0; i < 4; i++) {
    list.push(
      <div
        ref={(el) => (bgRef.current[i] = el)}
        key={i}
        style={{
          backgroundColor: i == 0 ? "#7DC243" : "transparent",
          borderRadius: "50%",
          border: i == 0 ? "1px solid #7DC243" : "1px solid white",
          width: isMobile
            ? "12px"
            : isMobileLandscape
            ? "9px"
            : isIpad || isIpadLandscape
            ? "14px"
            : "16px",
          height: isMobile
            ? "12px"
            : isMobileLandscape
            ? "9px"
            : isIpad || isIpadLandscape
            ? "14px"
            : "16px",
          cursor: "pointer",
          marginRight: 12,
        }}
        onClick={() => {
          handleClick(i);
        }}
      ></div>
    );
  }

  function handleClick(index) {
    bgRef.current[index].style.backgroundColor = "#7DC243";
    bgRef.current[index].style.borderColor = "#7DC243";
    bgRef.current
      .filter((el, ind) => ind !== index)
      .map((element) => {
        element.style.backgroundColor = "transparent";
        element.style.borderColor = "white";
      });
    setSliderImage(sliderImages[index + 1]);
    setCurrentIndex(index + 2);
    logEvent(
      Categories.about_page,
      Events.about_screen_slider_dot_button_clicked
    );
  }

  const handleChangeTab = () => {
    // if (!(isMobile || isMobileLandscape || isIpad)) {
    try {
      document.getElementById("aboutheaderbtn").style.color = "#7DC243";
      document.getElementById("quizheaderbtn").style.color = "white";
      document.getElementById("aboutheaderbtn").style.cursor = "default";
      document.getElementById("quizheaderbtn").style.cursor = "pointer";
    } catch (err) {
      // // console.log(err)
    }
    // }
  };

  useEffect(() => {
    handleChangeTab();
  }, []);

  useEffect(() => {
    setShowMainDiv(true);
  }, []);

  useEffect(() => {
    document.body.style.overflow = "auto";
    // return ()=>{
    //     document.body.style.overflow="hidden"
    // }
  }, []);

  useEffect(() => {
    window.addEventListener("orientationchange", handleChangeTab);
    return () => {
      window.removeEventListener("orientationchange", handleChangeTab);
    };
  }, []);

  useEffect(() => {
    if (consentBtnClicked === false) {
      dispatch(setShowWelcomeModal(false));
    }
  }, []);

  useEffect(() => {
    if (navigateToQuizPage === true) {
      navigate("/");
    }
  }, [navigateToQuizPage]);

  function scrollToTop() {
    window.scrollTo(0, 0);
  }
  useEffect(() => {
    scrollToTop();
  }, []);

  useEffect(()=>{
    const interval = setInterval(()=>{
      if(reviewCurrentIndex>=4){
        setReviewCurrentIndex(0)
      }
      if(reviewCurrentIndex<4){
        setReviewCurrentIndex((reviewCurrentIndex)=>reviewCurrentIndex+1)
      }
    },3000)
    return ()=>clearInterval(interval)
  },[reviewCurrentIndex])

  useEffect(() => {
    const interval = setInterval(() => {
      if (currentIndex >= 5) {
        setCurrentIndex(1);
      }

      if (
        bgRef.current.some((element) => element !== null) &&
        currentIndex < 5
      ) {
        bgRef.current[currentIndex - 1].style.backgroundColor = "#7DC243";
        bgRef.current[currentIndex - 1].style.borderColor = "#7DC243";
        bgRef.current
          .filter((el, ind) => ind !== currentIndex - 1)
          .map((element) => {
            element.style.backgroundColor = "transparent";
            element.style.borderColor = "white";
          });
        setSliderImage(sliderImages[currentIndex]);
        setCurrentIndex((currentIndex) => currentIndex + 1);
      }
    }, 3000);
    return () => clearInterval(interval);
  }, [currentIndex]);

  const navigateToMainPage = () => {
    const quizURL = getNavigateURL("/");
    navigate(quizURL);
  };

  const navigateToSignUp = () => {
    const signupURL = getNavigateURL("/signup");
    navigate(signupURL);
  };

  return (
    <>
      {showMainDiv && (
        <Fade in={true} timeout={1000}>
          <Box>
            {isMobile || isMobileLandscape || isIpad ? (
              <MobileAboutPage
                userState={
                  userState === USER_STATE.SIGNED_IN_USER ? true : false
                }
                navigateToSignUp={navigateToSignUp}
                isIpad={isIpad}
                isMobileLandscape={isMobileLandscape}
                sliderImage={sliderImage}
                list={list}
                reviews={reviews}
                userReviews={userReviews}
                navigateToMainPage={navigateToMainPage}
                userReviewCurrentIndex={reviewCurrentIndex}
                setReviewCurrentIndex= {(index)=>setReviewCurrentIndex(index)}
              ></MobileAboutPage>
            ) : (
              <DesktopAboutPage
                userState={
                  userState === USER_STATE.SIGNED_IN_USER ? true : false
                }
                navigateToSignUp={navigateToSignUp}
                isTl={isTl}
                isIpadLandscape={isIpadLandscape}
                sliderImage={sliderImage}
                list={list}
                reviews={reviews}
                userReviews={userReviews}
                navigateToMainPage={navigateToMainPage}
                userReviewCurrentIndex={reviewCurrentIndex}
                setReviewCurrentIndex= {(index)=>setReviewCurrentIndex(index)}
              ></DesktopAboutPage>
            )}
          </Box>
        </Fade>
      )}
    </>
  );
}

export default AboutPage;
