import { useState } from "react";
import intlMessages from "../../locales/en";
import { IconButton, Modal, DialogTitle } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Styled from "./styles";
import { useSelector } from "react-redux";
import { downgradSubscription } from "./service";
import ConnectionModal from "../../components/connection-modal/component";
import { useMediaQuery } from "@mui/material";
import { Categories, Events, logEvent } from "../../analytics";

export default function DowngradModal(props) {
  const { userData } = useSelector((state) => state.logIn);
  const isT7 = useMediaQuery(
    "(min-width: 1280px) and (max-width: 1280px) and (min-height: 540px) and (max-height: 540px)",
    {
      noSsr: true,
    }
  );
  const [downGradeConfirmationState, setDownGradeConfirmationState] =
    useState(false);
  const [userConfirmation, setUserConfirmation] = useState(false);
  const [lossingFeatureList, setLoosingFeatureList] = useState([
    intlMessages.Maximumwordspercontent,
    intlMessages.Unlimitedgeneratequestionset,
    intlMessages.VarietyofallQuestions,
    intlMessages.Numberofquestions,
    intlMessages.SaveQuizzes,
  ]);

  const [reasonTextList, setReasonTextList] = useState([
    intlMessages.Itisexpensiveforme,
    intlMessages.Myusageisnotalignedwiththisplan,
    intlMessages.Itisnotprovidingmeenoughvalue,
    intlMessages.Ihavefoundabettertool,
    intlMessages.deleteAccountReason5,
  ]);
  const [isChecked, setIsChecked] = useState(new Array(5).fill(false));
  const [openConnectionModal, setIsOpenConnectionModal] = useState(false);
  const { open, onClose } = props;
  const [errorMsgFlag, setErrorMsgFlag] = useState(false);
  const handleOnClose = () => {
    logEvent(
      Categories.downgrade_modal,
      Events.downgrade_modal_close_btn_clicked
    );
    onClose(false);
  };

  const downGradeConfirmation = async () => {
    if (downGradeConfirmationState) {
      logEvent(
        Categories.downgrade_modal,
        Events.downgrade_modal_confirm_downgrade_btn_clicked
      );
      const selectedStrings = reasonTextList.filter(
        (string, index) => isChecked[index]
      );
      const res = await downgradSubscription(
        userData?.pricePlan,
        userData?.username,
        selectedStrings,
        false
      );
      if (res?.data?.statusCode === 0) {
        setIsOpenConnectionModal(true);
        return;
      }
      if (
        res?.status === 201 
      ) {
        if(res?.data?.message==="Subscription downgraded successfully"){
          onClose(false)
          return
        }
        onClose(false, true);
        return;
      }
      if (
        res?.data?.status === 409 &&
        res?.data?.message === "Subscription already exists"
      ) {
        onClose(false, true);
        return;
      }
      if (
        res?.data?.status === 409 &&
        res?.data?.message.toLowerCase() === "already on basic plan"
      ) {
        onClose(false);
        return;
      }
    } else {
      setErrorMsgFlag(true);
      if (!userConfirmation) return;
      logEvent(
        Categories.downgrade_modal,
        Events.downgrade_modal_downgrade_btn_clicked
      );
      setDownGradeConfirmationState(!downGradeConfirmationState);
    }
  };
  const handleChange = (index, event) => {
    const updatedChecked = [...isChecked];
    updatedChecked[index] = event.target.checked;
    setIsChecked(updatedChecked);
  };

  const handleTextClick = (index) => {
    logEvent(Categories.downgrade_modal, Events.downgrade_modal_reason_clicked);
    const newChecked = [...isChecked];
    newChecked[index] = !newChecked[index];
    setIsChecked(newChecked);
  };

  const userConfirmationChange = (event) => {
    if (event.target.checked === true) {
      setErrorMsgFlag(false);
    }
    setUserConfirmation(event.target.checked);
  };

  const handleConsentTextClick = () => {
    logEvent(
      Categories.downgrade_modal,
      Events.downgrade_modal_consent_checkbox_clicked
    );
    setErrorMsgFlag(false);
    setUserConfirmation(!userConfirmation);
  };
  return (
    <>
      <Modal open={open}>
        <Styled.ModalBox sx={Styled.customStyling.exportModal}>
          <Styled.ModalHeader>
            <DialogTitle sx={{ fontSize: "17px", fontWeight: "600" }}>
              {intlMessages.downgradeModalHeading}
            </DialogTitle>
            <IconButton
              aria-label="closeWarningParagraphModal"
              onClick={handleOnClose}
            >
              <CloseIcon color="seondary.light" />
            </IconButton>
          </Styled.ModalHeader>
          {!downGradeConfirmationState && (
            <Styled.ModalContentBox isT7={isT7}>
              <br></br>
              <Styled.TextContainer>
                {
                  intlMessages.Youwillbeloosingthefollowingbenefitsafterdowngrading
                }
              </Styled.TextContainer>
              {lossingFeatureList.map((losingFeature, index) => (
                <Styled.ListContainer key={index}>
                  <Styled.ListIcon src={"svgs/tick-green.svg"} />
                  <Styled.ListTextContainer>
                    {losingFeature}
                  </Styled.ListTextContainer>
                </Styled.ListContainer>
              ))}
              <Styled.ListContainer>
                <Styled.CheckBoxStyles
                  downgradeModal={
                    downGradeConfirmationState === true ? true : false
                  }
                  checked={userConfirmation}
                  onChange={(e) => userConfirmationChange(e)}
                  icon={<img src="/svgs/uncheck-checkBox.svg"></img>}
                  checkedIcon={<img src="/svgs/Checkbox.svg"></img>}
                ></Styled.CheckBoxStyles>
                <Styled.ConsentMsg
                  onClick={handleConsentTextClick}
                  error={errorMsgFlag === true ? true : false}
                >
                  {intlMessages.downgradeModalConsentMsg}
                </Styled.ConsentMsg>
              </Styled.ListContainer>
            </Styled.ModalContentBox>
          )}

          {downGradeConfirmationState && (
            <Styled.ModalContentBox>
              <br></br>
              <Styled.TextContainer>
                {
                  intlMessages.YouareavaluablecustomerQuizWizwouldliketoknowthereason
                }
              </Styled.TextContainer>
              {reasonTextList.map((reasonTextList, index) => (
                <Styled.ListContainer>
                  <Styled.CheckBoxStyles
                    downgradeModal={
                      downGradeConfirmationState === true ? true : false
                    }
                    key={index}
                    checked={isChecked[index]}
                    onChange={(e) => handleChange(index, e)}
                    icon={<img src="/svgs/uncheck-checkBox.svg"></img>}
                    checkedIcon={<img src="/svgs/Checkbox.svg"></img>}
                  ></Styled.CheckBoxStyles>
                  <Styled.ReasonListText onClick={() => handleTextClick(index)}>
                    {reasonTextList}
                  </Styled.ReasonListText>
                </Styled.ListContainer>
              ))}
              <Styled.DisclaimerBox isT7={isT7}>
                <Styled.DisclaimerText>
                  <span
                    style={{
                      fontWeight: "700",
                      marginRight: "5px",
                      color: "#333333",
                    }}
                  >
                    {intlMessages.disclaimerText1}
                  </span>
                  {intlMessages.disclaimerText2}
                  <span
                    style={{
                      fontWeight: "700",
                      marginRight: "5px",
                      marginLeft: "5px",
                    }}
                  >
                    {intlMessages.disclaimerText3}
                  </span>
                  <span>{intlMessages.disclaimerText4}</span>
                </Styled.DisclaimerText>
              </Styled.DisclaimerBox>
            </Styled.ModalContentBox>
          )}
          <Styled.ModalFooter>
            <Styled.FooterButton
              sx={{ ":hover": { bgcolor: "#89d44a" } }}
              backgroundColor="#7DC242"
              fontColor="#ffffff"
              borderColor="#7DC242"
              onClick={handleOnClose}
            >
              {intlMessages.cancelLabel}
            </Styled.FooterButton>
            <Styled.FooterButton
              sx={{
                marginLeft: "15px",
                border: "1px solid #A6A6A6",
                width: downGradeConfirmationState === true ? "187px" : "128px",
              }}
              backgroundColor=""
              fontColor="#A6A6A6"
              borderColor="#A6A6A6"
              onClick={downGradeConfirmation}
            >
              {downGradeConfirmationState === true
                ? intlMessages.ConfirmDowngrade
                : intlMessages.Downgrade}
            </Styled.FooterButton>
          </Styled.ModalFooter>
        </Styled.ModalBox>
      </Modal>
      {openConnectionModal && (
        <ConnectionModal
          isOpen={openConnectionModal}
          handleCloseConnectionModal={() => setIsOpenConnectionModal(false)}
        />
      )}
    </>
  );
}
