import axios from "axios";
import {getBaseUrlOfBrowser, BASE_API_PATH_PARAMS, protectedRoutes} from "./service"
import { getToken } from "../common/sign_in_service";
import { conf } from "../version";

const api =  axios.create({
	baseURL: getBaseUrlOfBrowser() + BASE_API_PATH_PARAMS,
	// withCredentials: false,
	// headers: {
	//   'Access-Control-Allow-Origin' : true,
	//   'Access-Control-Allow-Methods':'GET,PUT,POST,DELETE,PATCH,OPTIONS',
	//   'Accept':'*/*'
	//   }
});


api.interceptors.request.use(
	(config) => {
	  if (config.url.startsWith('/library/') || protectedRoutes.includes(config.url)) {
		const token = getToken();
		if (token) {
			config.headers.Authorization = `Bearer ${token}`;
		}
	  }
	  config.headers['web-client-version']=conf.version
	  config.timeout = 120000;
	  return config;
	},
	(error) => {
	  return Promise.reject(error);
	}
  );
  
  export default api
