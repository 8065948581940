import styled from "@mui/system/styled";
import Box from "@mui/material/Box";
import { Button, Divider, Typography } from "@mui/material";

const ModalHeader = styled(Box)(({ theme,isPaymentDue,isTl7,showCloseButton }) =>({
  padding: 8,
  paddingRight: 12,
  paddingLeft: 25,
  alignItems: "center",
  display: "flex",
  flexDirection: "row",
  justifyContent: "flex-end",
  marginBottom:isTl7 && (isPaymentDue===true || showCloseButton===true)?'0px':showCloseButton===false && isPaymentDue===true?'20px':showCloseButton===true && isPaymentDue===false?'0px':'40px'
}));

const ModalActions = styled(Box)({
  padding: 5,
  paddingTop: 15,
  paddingBottom: 15,
  borderTop: "1px solid silver",
  display: "flex",
  justifyContent: "center",
});

const ModalBox = styled(Box)(({ theme }) => ({
  width: "30%",
  [theme.breakpoints.down("xxl")]: {
    width: "35%",
  },
  [theme.breakpoints.down("lg")]: {
    width: "60%",
  },
  [theme.breakpoints.down("sm")]: {
    width: "95%",
  },
}));

const StyledHeading = styled(Typography)(({theme})=>({
    fontFamily:'Open Sans',
    fontWeight:600,
    fontSize:'24px',
    lineHeight:'30px',
    textAlign:'center'
}))

const StyledDesc = styled(Typography)(({theme,isPaymentDue})=>({
    fontFamily:'Open Sans',
    fontWeight:400,
    fontSize:'18px',
    lineHeight:'25px',
    textAlign:'center',
    width: isPaymentDue===true?'400px':'unset',
    [theme.breakpoints.down("sm")]: {
      width: isPaymentDue===true?'100%':'unset',
    },

    
}))

const ModalContentBox = styled(Box)({
  display:'flex',
  flexDirection:'column',
  justifyContent:'center',
  alignItems:'center',
  paddingLeft:'20px',
  paddingRight:'20px'
});

const UpgradeBtn = styled(Button)({
    color:"white",
    textTransform:'none',
    height:"49px",
    width:"180px",
    marginTop:"20px",
    marginBottom:"20px"
  });

const StyledDivider = styled(Divider)(({}) => ({
    width: "90%",
    marginBottom: "20px",
    color: "#333333",
}));

const ActionText = styled(Typography)(({theme})=>({
    fontFamily:'Open Sans',
    fontWeight:400,
    fontSize:'18px',
    lineHeight:'20px',
    textAlign:'center',
    marginBottom:"40px",
    color:'#1A73E8',cursor:'pointer'
}))

const customStyling = {
    exportModal: {
      position: "absolute",
      top: { xs: "45%", sm: "50%" },
      left: "50%",
      transform: "translate(-50%, -50%)",
      bgcolor: "background.paper",
      borderRadius: 2,
      boxShadow: 24,
      overflowY: "auto",
      maxHeight: "90%",
      border: "none",
    },
  };

export default {
  customStyling,
  ModalHeader,
  ModalActions,
  ModalBox,
  ModalContentBox,
  StyledHeading,
  UpgradeBtn,
  StyledDesc,
  StyledDivider,
  ActionText
};
