import { Box, Button, Pagination } from "@mui/material";
import styled from "@mui/system/styled";
import { DataGrid } from "@mui/x-data-grid";
const TABLE_PADDING = 12;
const StyledDataGrid = styled(DataGrid)(({ theme, isdisabledpagination,billingModal }) => ({
  margin: billingModal?'unset':'0px 30px 30px 30px',
   height:'100%',
   overflow:'auto',
  border: "none",
  '-webkit-overflow-scrolling': 'touch',
  scrollBehavior:"smooth",
  ".MuiDataGrid-columnHeaders": {
    backgroundColor: "#e5e5e5",
    minHeight: "0 !important",
    // maxHeight:"45px !important",
  },
  ".MuiDataGrid-selectedRowCount":{
    display:'none'
  },
  ".MuiDataGrid-columnHeadersInner": {
    backgroundColor:billingModal?'#E5E5E5':'#333333',
    color:billingModal?'#333333':'white',
    paddingLeft: TABLE_PADDING,
    paddingRight: TABLE_PADDING,
  },
  ".MuiDataGrid-row": {
    paddingLeft: TABLE_PADDING,
  },
  ".MuiDataGrid-footerContainer": {
    display: isdisabledpagination && "none",
    alignItems:"unset"
  },
  ".MuiDataGrid-columnHeader": {
    height: "auto !important",
  },
  ".MuiDataGrid-virtualScrollerRenderZone": {
    // borderBottom: "1px solid #e5e5e5",
  },
  ".MuiDataGrid-virtualScroller":{
    [`${theme.breakpoints.up("sm")} and (max-width: 1400px) and (max-height: 800px)`]: {
      // height:'55vh !important',
    },
    '-webkit-overflow-scrolling': 'touch',
    scrollBehavior:"smooth"
  },
  ".MuiDataGrid-cell:focus,.MuiDataGrid-columnHeader:focus,.MuiDataGrid-cell:focus-within,.MuiDataGrid-columnHeader:focus-within":{
    outline:'none !important'
  },
  ".MuiDataGrid-columnSeparator--sideRight": {
    display: "none" 
  },
  "& .MuiDataGrid-sortIcon": {
    opacity: 1,
    color: "white",
    },
    "& .MuiDataGrid-menuIconButton": {
    opacity: 1,
    color: "white"
    },
  
}));

const FlexBox = styled(Box)(({ theme,isMobile,isMobileLandscape }) => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: isMobile || isMobileLandscape?"flex-end":"center",
  alignItems: "center",
  width: "100%",
  paddingTop:15,
  paddingBottom:15,
  borderBottomLeftRadius:8,
  borderBottomRightRadius:8,
  '@media (max-width: 1400px) and (max-height: 650px)': {
    paddingTop:0,
    paddingTop:10,
    paddingBottom:10,
  },
}));

const SimpleButton = styled(Button)(({ theme,isMobile,isMobileLandscape}) => ({
  display: isMobile || isMobileLandscape?'none':'block',
  textTransform: "none",
  paddingTop: 2.2,
  paddingBottom: 2.2,
  color:'#666666',
}));

const StyledPagination = styled(Pagination)(({ theme }) => ({
  ".Mui-selected.MuiPaginationItem-page":{
    backgroundColor: '#7DC242',
    color:'#fff',
    opacity:0.75
  }
}));

const NoRowsTextBox = styled(Box)(({ theme }) => ({
  display:"flex",
  justifyContent:'center',
  alignItems:'center',
  height:'100%',
  width:'100%'
}));

export default {
  StyledDataGrid,
  FlexBox,
  SimpleButton,
  StyledPagination,
  NoRowsTextBox,
};
