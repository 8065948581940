import React, { useState } from "react";
import Styled from "./styles";
import { useDispatch, useSelector } from "react-redux";
import {
  setQuizCount,
  setQuizDifficultyLevel,
  setQuizMobileSettingHeight,
  setQuizSettingHeight,
  setQuizType,
} from "../../redux/slices/quizSlice";
import { getSentenceCount, getWordCount } from "../../components/quiz/service";
import intlMessages from "../../locales/en";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { Box } from "@mui/material";
import SelectMenu from "../select-component/component";
import { Categories, Events, logEvent } from "../../analytics";
import { setOpenUpgradeModel, setUpgradeModalText } from "../../redux/slices/featuresSlice";

export default function QuizSettings(props) {
  const [arrowIcon, setArrowIcon] = useState(false);
  const { quizText, isMobile, isMobileLandscape, isIpad, isIpadLandscape } =
    props;
  const dispatch = useDispatch();
  const {
    quizSettingHeight,
    quizType,
    quizCount,
    quizDifficultyLevel,
    quizSettingMobileHeight,
    isDisableQuizSetting,
  } = useSelector((state) => state.quiz);
  const {
    questionCount,
    quizGenerationType,
    difficultyLevel
  } = useSelector((state)=>state.feature)
  return (
    <>
      {isMobile || isIpad || isIpadLandscape ? (
        <Styled.MobileContainer
          isIpad={isIpad ? true : false}
          isIpadLandscape={isIpadLandscape ? true : false}
          height={quizSettingMobileHeight}
        >
          <Box
            sx={{
              width: "100%",
              display: quizSettingMobileHeight === 60 ? "none" : "flex",
              alignItems: "flex-start",
              flexDirection: "column",
              pr: "5%",
            }}
          >
            <SelectMenu
              isEnabled={isDisableQuizSetting}
              isIpad={isIpad}
              isMobile={isMobile}
              hasIcon={quizGenerationType.type.length===3?false:true}
              isMobileLandscape={isMobileLandscape}
              width={isIpad ? "40vw" : isIpadLandscape ? "42.5vw" : "90vw"}
              defaultValue={quizType}
              setQuizSettings={(value) => {
                if(quizGenerationType.type.includes(value)){
                dispatch(setQuizType(value))
                logEvent(Categories.quiz_settings, Events.quiz_settings_question_type_selected, undefined, value);
                }
                else{
                  dispatch(setUpgradeModalText({
                    heading:intlMessages.upgradeModelHeading,
                    desc:intlMessages.upgradeModelDescText
                  }))
                  dispatch(setOpenUpgradeModel(true))
                }
              }}
              listTitle={intlMessages.quizTypeLabel}
              list={[
                intlMessages.quizMCQType,
                intlMessages.quizFillBlankType,
                intlMessages.quizTrueFalseType,
              ]}
            ></SelectMenu>
            <Box sx={{ display: "flex", flexDirection: "row", mt: "10px" }}>
              <SelectMenu
                isIpad={isIpad}
                isMobile={isMobile}
                isMobileLandscape={isMobileLandscape}
                isEnabled={isDisableQuizSetting}
                width={
                  isIpad ? "19.4vw" : isIpadLandscape ? "20.8vw" : "43.5vw"
                }
                defaultValue={quizDifficultyLevel}
                hasIcon={difficultyLevel.levels.length===3?false:true}
                setQuizSettings={(value) =>{
                  if(difficultyLevel.levels.includes(value)){
                  dispatch(setQuizDifficultyLevel(value))
                  logEvent(Categories.quiz_settings, Events.quiz_settings_question_difficulty_level_selected, undefined, value);
                  }
                  else{
                    dispatch(setUpgradeModalText({
                      heading:intlMessages.upgradeModelHeading,
                      desc:intlMessages.upgradeModelDescText
                    }))
                    dispatch(setOpenUpgradeModel(true))
                  }
                }
                }
                listTitle={intlMessages.quizDifficultyLabel}
                list={[
                  intlMessages.quizEasyLevel,
                  intlMessages.quizMediumLevel,
                  intlMessages.quizHardLevel,
                ]}
              ></SelectMenu>
              <SelectMenu
                isIpad={isIpad}
                isMobile={isMobile}
                isMobileLandscape={isMobileLandscape}
                isEnabled={isDisableQuizSetting}
                width={
                  isIpad ? "19.4vw" : isIpadLandscape ? "20.8vw" : "43.5vw"
                }
                defaultValue={quizCount}
                hasIcon={questionCount.count.length===5?false:true}
                setQuizSettings={(value) => {
                  if(questionCount.count.includes(value)){
                  dispatch(setQuizCount(value))
                  logEvent(Categories.quiz_settings, Events.quiz_settings_question_count_selected, undefined, value);
                  }
                  else{
                    dispatch(setUpgradeModalText({
                      heading:intlMessages.upgradeModelHeading,
                      desc:intlMessages.upgradeModelDescText
                    }))
                    dispatch(setOpenUpgradeModel(true))
                  }
                }}
                listTitle={intlMessages.quizCountLabel}
                list={[intlMessages.quizDefaultCount, 5, 10, 15, 20]}
              ></SelectMenu>
            </Box>
          </Box>
          <Styled.MobileSentenceBox height={quizSettingMobileHeight}>
            <Styled.SentenceLabel>
              {getSentenceCount(quizText).toString()}{" "}
              {intlMessages.sentencesLabel} |{" "}
              {getWordCount(quizText).toString()} {intlMessages.wordsLabel}
            </Styled.SentenceLabel>
            <Styled.QuizSettingBtn
              disableTouchRipple
              endIcon={
                arrowIcon === false ? (
                  <KeyboardArrowDownIcon />
                ) : (
                  <KeyboardArrowUpIcon />
                )
              }
              onClick={() => {
                setArrowIcon(!arrowIcon);
                logEvent(Categories.quiz_settings, Events.quiz_settings_button_clicked);
                if (quizSettingMobileHeight === 60)
                  dispatch(setQuizMobileSettingHeight(160));
                else dispatch(setQuizMobileSettingHeight(60));
              }}
            >
              {intlMessages.quizSettingsLabel}
            </Styled.QuizSettingBtn>
          </Styled.MobileSentenceBox>
        </Styled.MobileContainer>
      ) : (
        <Styled.Container
          height={isMobileLandscape ? quizSettingHeight : quizSettingHeight}
        >
          <Box
            sx={{
              width: "100%",
              display: quizSettingHeight === 34 ? "none" : "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <SelectMenu
              isIpad={isIpad}
              isMobile={isMobile}
              isMobileLandscape={isMobileLandscape}
              isEnabled={isDisableQuizSetting}
              hasIcon={quizGenerationType.type.length===3?false:true}
              width={isMobileLandscape ? "25vw" : "14vw"}
              defaultValue={quizType}
              setQuizSettings={(value) => {
                if(quizGenerationType.type.includes(value)){
                dispatch(setQuizType(value))
                logEvent(Categories.quiz_settings, Events.quiz_settings_question_type_selected, undefined, value);
                }
                else{
                  dispatch(setUpgradeModalText({
                    heading:intlMessages.upgradeModelHeading,
                    desc:intlMessages.upgradeModelDescText
                  }))
                  dispatch(setOpenUpgradeModel(true))
                }
              }}
              listTitle={intlMessages.quizTypeLabel}
              list={[
                intlMessages.quizMCQType,
                intlMessages.quizFillBlankType,
                intlMessages.quizTrueFalseType,
              ]}
            ></SelectMenu>
            <SelectMenu
              isIpad={isIpad}
              isMobile={isMobile}
              isMobileLandscape={isMobileLandscape}
              isEnabled={isDisableQuizSetting}
              hasIcon={difficultyLevel.levels.length===3?false:true}
              width={isMobileLandscape ? "25vw" : "14vw"}
              defaultValue={quizDifficultyLevel}
              setQuizSettings={(value) =>{
                if(difficultyLevel.levels.includes(value)){
                dispatch(setQuizDifficultyLevel(value))
                logEvent(Categories.quiz_settings, Events.quiz_settings_question_difficulty_level_selected, undefined, value);
                }
                else{
                  dispatch(setUpgradeModalText({
                    heading:intlMessages.upgradeModelHeading,
                    desc:intlMessages.upgradeModelDescText
                  }))
                  dispatch(setOpenUpgradeModel(true))
                }
              }}
              listTitle={intlMessages.quizDifficultyLabel}
              list={[
                intlMessages.quizEasyLevel,
                intlMessages.quizMediumLevel,
                intlMessages.quizHardLevel,
              ]}
            ></SelectMenu>
            <SelectMenu
              isIpad={isIpad}
              isMobile={isMobile}
              isMobileLandscape={isMobileLandscape}
              isEnabled={isDisableQuizSetting}
              width={isMobileLandscape ? "25vw" : "14vw"}
              defaultValue={quizCount}
              hasIcon={questionCount.count.length===5?false:true}
              setQuizSettings={(value) => {
                if(questionCount.count.includes(value)){
                dispatch(setQuizCount(value))
                logEvent(Categories.quiz_settings, Events.quiz_settings_question_count_selected, undefined, value);
                }
                else{
                  dispatch(setUpgradeModalText({
                    heading:intlMessages.upgradeModelHeading,
                    desc:intlMessages.upgradeModelDescText
                  }))
                  dispatch(setOpenUpgradeModel(true))
                }
              }}
              listTitle={intlMessages.quizCountLabel}
              list={[intlMessages.quizDefaultCount, 5, 10, 15, 20]}
            ></SelectMenu>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
              marginTop: quizSettingHeight === 34 ?"unset":"-10px",
            }}
          >
            <Styled.SentenceLabel>
              {getSentenceCount(quizText).toString()}{" "}
              {intlMessages.sentencesLabel} |{" "}
              {getWordCount(quizText).toString()} {intlMessages.wordsLabel}
            </Styled.SentenceLabel>
            <Styled.QuizSettingBtn
              disableTouchRipple
              endIcon={
                arrowIcon === false ? (
                  <KeyboardArrowDownIcon />
                ) : (
                  <KeyboardArrowUpIcon />
                )
              }
              onClick={() => {
                logEvent(Categories.quiz_settings, Events.quiz_settings_button_clicked);
                setArrowIcon(!arrowIcon);
                if (quizSettingHeight === 34)
                  dispatch(setQuizSettingHeight(79));
                else dispatch(setQuizSettingHeight(34));
              }}
            >
              {intlMessages.quizSettingsLabel}
            </Styled.QuizSettingBtn>
          </Box>
        </Styled.Container>
      )}
    </>
  );
}
