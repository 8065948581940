import { styled } from "@mui/material/styles";
import { Box, Typography } from "@mui/material";

const ModalBox = styled(Box)(({ theme }) => ({
  width: "25%",
  [theme.breakpoints.down("lg")]: {
    width: "60%",
  },
  [theme.breakpoints.down("sm")]: {
    width: "95%",
  },
  "@media screen and (min-width: 1000px) and (max-width: 1400px) and (orientation: landscape) and  (hover: none) and (pointer: coarse)":
    {
      width: "40%",
    },
}));

const ModalDesc = styled(Typography)(() => ({
  fontFamily: "open sans",
  fontWeight: "400",
  fontSize: "13px",
  lineHeight: "17.7px",
  color: "#333333",
}));

const ModalHeader = styled(Box)({
  padding: 8,
  paddingRight: 12,
  boxShadow: "rgba(100, 100, 111, 0.2) 0px 3px 29px 0px",
  paddingLeft: 25,
  alignItems: "center",
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
});

const ModalContentBox = styled(Box)({
  marginTop: 30,
  marginLeft: 25,
  marginRight: 25,
  marginBottom: 30,
});

const DownloadContainer = styled(Box)({
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
  marginTop: "20px",
  cursor: "pointer",
});

const DownloadLabel = styled(Typography)({
  fontFamily: "open sans",
  fontWeight: "400",
  fontSize: "16px",
  lineHeight: "21.79px",
  color: "#2062BB",
  marginLeft: "10px",
});

const DownloadDesc = styled(Typography)({
  fontFamily: "open sans",
  fontWeight: "400",
  fontSize: "12px",
  lineHeight: "16.34px",
  color: "#333333B2",
  marginLeft: "29px",
});

const DownloadImage = styled("img")({
  width: "18px",
  height: "18px",
});

const customStyling = function (theme) {
  return {
    exportModal: {
      position: "absolute",
      top: { xs: "45%", sm: "50%" },
      left: "50%",
      transform: "translate(-50%, -50%)",
      bgcolor: "background.paper",
      borderRadius: 2,
      boxShadow: 24,
      overflowY: "auto",
      maxHeight: "90%",
      border: "none",
    },
  };
};

const ModalActions = styled(Box)({
  padding: 5,
  paddingTop: 15,
  paddingBottom: 15,
  borderTop: "1px solid silver",
  display: "flex",
  justifyContent: "center",
});

export default {
  ModalContentBox,
  ModalHeader,
  customStyling,
  ModalBox,
  ModalDesc,
  DownloadDesc,
  DownloadContainer,
  DownloadLabel,
  DownloadImage,
  ModalActions,
};
