import { useState, useEffect } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Styled from "./styles";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import intlMessages from "../../locales/en";
import { Box, Divider, useMediaQuery } from "@mui/material";
import { useNavigate, useSearchParams } from "react-router-dom";
import { decodeToken, getToken } from "../../common/sign_in_service";
import { UpdateUserConsent } from "../../api/sign-up/methods";
import { useTheme } from "@mui/material/styles";
import {
  sendSigUpSocialConversionEvent,
  sendSignUpConversionEvent,
} from "../../adds_conversion";
import { Events, Categories, logEvent } from "../../analytics";

function SignUpWelcomeModal(props) {
  const [searchParams] = useSearchParams();
  const { onClose } = props;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"), {
    noSsr: true,
  });
  const isMobileLandscape = useMediaQuery(
    `${theme.breakpoints.down(
      "md"
    )}  and (orientation: landscape) and (hover: none) and (pointer: coarse)`,
    {
      noSsr: true,
    }
  );

  const isIpad = useMediaQuery(
    "(min-width:620px) and (max-width:840px) and (orientation: portrait) and  (hover: none) and (pointer: coarse)",
    {
      noSsr: true,
    }
  );
  const isIpadLandscape = useMediaQuery(
    "(min-width: 768px) and (max-width: 1024px) and (orientation: landscape) and  (hover: none) and (pointer: coarse)",
    {
      noSsr: true,
    }
  );
  const [open, setIsOpen] = useState(true);
  const [newsletterChecked, setNewsletterChecked] = useState(true);
  const [provider, setProvider] = useState(false);
  const navigate = useNavigate();
  const handleClose = () => {
    if (provider) {
      UpdateUserConsent(newsletterChecked);
    }
    searchParams.delete("v"); // Replace with the desired parameter name(s)
    navigate(`/?${searchParams.toString()}`);
    setIsOpen(false);
    onClose(false);
  };

  useEffect(() => {
    (async () => {
      const token = getToken();
      const data = await decodeToken(token);
      if (data?.provider !== "local") {
        setProvider(true);
        sendSignUpConversionEvent();
        //sendSigUpSocialConversionEvent();
        logEvent(Categories.user_management, Events.signup);
        logEvent(Categories.user_management, Events.signup_by_social);
      }
    })();
  }, []);

  const handleNewsletterChange = (event) => {
    setNewsletterChecked(event.target.checked);
  };
  return (
    <>
      <Dialog open={open}>
        {!isMobile && !isMobileLandscape && (
          <DialogTitle>
            <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
              <Styled.crossBtn onClick={handleClose}>
                <CloseOutlinedIcon />
              </Styled.crossBtn>
            </Box>
          </DialogTitle>
        )}
        <DialogContent
          sx={{ mt: isMobile || isMobileLandscape ? "unset" : "-20px" }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "space-betweeen",
            }}
          >
            <Styled.qLogo
              isMobile={isMobile ? true : false}
              isMobileLandscape={isMobileLandscape ? true : false}
              src="/svgs/signup-welcome-logo.svg"
            ></Styled.qLogo>
            {(isMobile || isMobileLandscape) && (
              <Styled.crossBtn onClick={handleClose}>
                <CloseOutlinedIcon />
              </Styled.crossBtn>
            )}
          </Box>
          <DialogContentText>
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Styled.welcomeHeading
                provider={provider ? true : false}
                isMobile={isMobile ? true : false}
                isMobileLandscape={isMobileLandscape ? true : false}
              >
                {intlMessages.signUpWelcomModalHeading}
              </Styled.welcomeHeading>
              <Styled.welcomeDesc
                provider={provider ? true : false}
                isMobile={isMobile ? true : false}
                isMobileLandscape={isMobileLandscape ? true : false}
              >
                {intlMessages.signUpWelcomModalDesc}
              </Styled.welcomeDesc>
              <Box
                sx={{
                  display: provider ? "flex" : "none",
                  flexDirection: "row",
                  mb: isMobile || isMobileLandscape ? "0px" : "10px",
                  width: isMobile ? "240px" : "unset",
                }}
              >
                <Styled.checkBox
                  isMobile={isMobile ? true : false}
                  isMobileLandscape={isMobileLandscape ? true : false}
                  sx={{ mt: "-10px" }}
                  icon={<Styled.unCheckedIcon />}
                  checkedIcon={
                    <img
                      style={{ width: "24px", height: "24px" }}
                      src="/svgs/Checkbox.svg"
                    ></img>
                  }
                  onChange={handleNewsletterChange}
                  checked={newsletterChecked}
                ></Styled.checkBox>
                <Styled.newslettersText
                  onClick={() => setNewsletterChecked(!newsletterChecked)}
                >
                  {intlMessages.signUpWelcomModalNewsletterText}
                </Styled.newslettersText>
              </Box>
            </Box>
          </DialogContentText>
        </DialogContent>
        <Divider
          sx={{
            border: "1px solid rgba(0, 0, 0, 0.2)",
            mb: isMobile || isMobileLandscape ? "26px" : "unset",
            width: "100%",
            mt: isMobile || isMobileLandscape ? "unset" : "-10px",
          }}
        ></Divider>
        <DialogActions
          sx={{ height: isMobile || isMobileLandscape ? "40px" : "83px" }}
        >
          <Styled.closeBtn
            isMobile={isMobile ? true : false}
            isMobileLandscape={isMobileLandscape ? true : false}
            onClick={handleClose}
          >
            {intlMessages.signUpWelcomModalBtnText}
          </Styled.closeBtn>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default SignUpWelcomeModal;
