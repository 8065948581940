import styled from "@mui/system/styled";
import {Typography, Button, IconButton, TextField, Divider, Box} from "@mui/material";

const crossBtn = styled(IconButton)(({theme})=>({
    width:'12.6px',
    height:'13px',
    color:'black',
}))

const confirmBtn = styled(Button)(({theme})=>({
    backgroundColor:'#7DC243',
    width:'105px',
    height:'33px',
    borderRadius:'4px',
    color:'white',
    textTransform:'none',
    marginRight:'15px',
    '&:hover':{
        backgroundColor: '#89d44a', 
    }
}))

const closeBtn = styled(Button)(({theme})=>({
    width:'95px',
    height:'33px',
    borderRadius:'4px',
    color:'#A6A6A6',
    textTransform:'none',
    marginRight:'15px',
    border:'1px solid rgba(166, 166, 166, 1)',
}))

const deleteHeading = styled(Typography)(({theme})=>({
    fontFamily:'Open Sans',
    fontWeight:'600',
    lineHeight:'23.15px',
    fontSize:'17px',
    color: '#333333',
}))

const deleteQuestion = styled(Typography)(({theme,isMobile,isMobileLandscape})=>({
    fontFamily:'Open Sans',
    fontWeight:'400',
    lineHeight:'21.79px',
    fontSize:'16px',
    color: '#333333',
    width:isMobile?'250px':'410px',
    marginTop:isMobileLandscape?'10px':'30px',
    marginBottom:'15px'
}))

const deleteReasonBox = styled(Box)(({theme,isMobile,isMobileLandscape})=>({
    display:'flex',
    flexDirection:'column',
    alignItems:'flex-start'
}))

const ReasonContainer = styled(Box)(({theme,isMobile,isMobileLandscape})=>({
    display:'flex',
    flexDirection:'row',
    justifyContent:'flex-start',
    marginBottom:'15px'
}))

const Reason = styled(Typography)(({theme,isMobile,isMobileLandscape})=>({
    fontFamily:'Open Sans',
    fontWeight:'400',
    lineHeight:'21.79px',
    fontSize:'15px',
    color: '#333333',
    marginLeft:'10px',
    cursor:'pointer'
}))

const StyledDivider = styled(Divider)(({}) => ({
    width: "95%",
    color: "#333333",
  }));

const deleteDesc = styled(Typography)(({theme,isMobile,isMobileLandscape})=>({
    fontFamily:'Open Sans',
    fontWeight:'400',
    lineHeight:'19.07px',
    fontSize:'14px',
    color: '#333333',
    width:isMobile?'250px':'410px',
    marginTop:isMobileLandscape?'10px':'20px'
}))

const largeTextField = styled(TextField)(({theme,isMobile,isMobileLandscape})=>({
    background: '#F6F6F6',
    '& .MuiInputBase-root': {
        background: '#F6F6F6',
    },  
    '& input::placeholder': {
      color: '#000000',
      opacity: 1,
    },
    '& .MuiFormHelperText-root.Mui-error': {
        position: 'absolute',
        top: '100%'
      },
    width:isMobile?'250px':'410px',
    height: isMobile || isMobileLandscape?'unset':'39px',
    borderRadius:'1px',
    marginTop:'10px',
    marginBottom: isMobileLandscape || isMobile?'0px':'20px'
}))


export default {
    confirmBtn,
    crossBtn,
    deleteHeading,
    deleteQuestion,
    deleteReasonBox,
    ReasonContainer,
    Reason,
    StyledDivider,
    deleteDesc,
    largeTextField,
    closeBtn
}