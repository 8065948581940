import styled from "@mui/system/styled";
import { Typography, Button, IconButton, Divider } from "@mui/material";

const crossBtn = styled(IconButton)(({ theme }) => ({
  width: "12.6px",
  height: "13px",
  color: "black",
}));

const icon = styled("img")(({ theme }) => ({
  width: "102px",
  height: "102px",
  display: "block",
  margin: "auto",
}));

const styledText = styled(Typography)(
  ({ theme, isError, isMobileLandscape, isMobile }) => ({
    fontFamily: "Open Sans",
    fontWeight: "600",
    lineHeight: "19.07px",
    fontSize: "14px",
    textAlign: "center",
    color: isError==true ? "red" : isError==null? "#AD8600":"#7DC243",
    marginTop: "10px",
    marginBottom: isMobileLandscape ? "unset" : "15px",
    width: isMobile || isMobileLandscape ? "unset" : "400px",
  })
);

const divider = styled(Divider)(({ theme }) => ({
  width: "100%",
  border: "1px rgba(0, 0, 0, 0.2) solid",
}));

const closeBtn = styled(Button)(({theme,isMobile,isMobileLandscape,deleteBtn})=>({
    backgroundColor: deleteBtn?'#FFFFFF':'#7DC243',
    width: isMobile?'73.01px':isMobileLandscape?'77px':'111.23px',
    height:'33px',
    borderRadius:'4px',
    color: deleteBtn?'#7DC243':'white',
    margin: deleteBtn?'unset':'auto',
    textTransform:'none',
    border: deleteBtn?'1px solid #7DC243':'unset',
    // marginBottom:'15px',
    '&:hover':{
        backgroundColor: deleteBtn? '#F2F1F1':'#89d44a', 
    }
}))

const confirmBtn = styled(Button)(({theme,isMobile,isMobileLandscape})=>({
    backgroundColor: '#7DC243',
    width: isMobile?'73.01px':isMobileLandscape?'77px':'111.23px',
    height:'33px',
    borderRadius:'4px',
    color: 'white',
    textTransform:'none',
    '&:hover':{
        backgroundColor: '#89d44a', 
    }
}))

const CustomDivider = styled(Divider)(({ theme }) => ({
  width: "100%",
  marginBottom: "25px",
  color: "#33333380",
  marginTop: "25px",
  textTransform: "uppercase",
  "&::before": {
    borderColor: "#C3C3C3", // Change this to the desired divider line color
  },
}));

export default {
  styledText,
  icon,
  closeBtn,
  divider,
  crossBtn,
  CustomDivider,
  confirmBtn
};
