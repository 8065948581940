import styled from "@mui/system/styled";
import {Typography, Button, Box } from "@mui/material";
import Mulish from "../../../themes/fonts/Mulish-ExtraBold.woff2";
import aboutImages from "../about-page-images";

const sliderBox = styled(Box)(({ theme, isIpadLandscape, isTl }) => ({
    backgroundImage: `url(${aboutImages['sliderBoxDesktopBackground']})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    height: isIpadLandscape && !isTl ? '398px' : '595px',
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    color: 'white',
    alignItems: 'center',
    paddingLeft: '100px',
    paddingRight: '100px'
}))

const SliderLogo = styled('img')(({ theme }) => ({
    height: '66px',
    width: '202px',
    marginBottom: '45px'
}))

const SliderText = styled(Typography)(({ theme }) => ({
    marginTop: '15px',
    marginBottom: '20px',
    fontFamily: 'Mulish',
    fontWeight: 800,
    fontSize: '72px',
    color: '#7DC243',
    lineHeight: '68px',
    '@font-face': {
        fontFamily: 'Mulish',
        src: `url(${Mulish}) format('woff2')`,
        fontWeight: '800',
        fontStyle: 'normal',
    },
}))

const sliderText2 = styled(Typography)(({ theme }) => ({
    fontFamily: 'Open Sans',
    fontWeight: 300,
    fontSize: '32px',
    color: '#A680F6',
    lineHeight: '36px',
    fontStyle: 'normal',
    marginBottom:'40px'
}))
const sliderText3 = styled('span')(({ theme }) => ({
    fontWeight: '800',
    '@font-face': {
        fontFamily: 'Mulish',
        src: `url(${Mulish}) format('woff2')`,
        fontWeight: '800',
        fontStyle: 'normal',
    }, 
}))

const SliderBtn = styled(Button)(({ theme }) => ({
    width: '247px',
    height: '49px',
    borderRadius:'4px',
    backgroundColor: "#F96D30",
    color: theme.palette.primary.contrastText,
    textTransform: "none",
    fontSize:'18px !important'
}))

const SliderImage = styled('img')(({ theme, isIpadLandscape, isTl }) => ({
    width: isIpadLandscape && !isTl ? '400.5px' : '520px',
    height: isIpadLandscape && !isTl ? '284.72px' : '410px',
    objectFit: 'contain',
    marginTop: '-10px'
}))

const SliderDotBox = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    marginTop: '20px',
    justifyContent: 'center'
}))

const FeaturesBox = styled(Box)(({ theme, isIpadLandscape, isTl }) => ({
    backgroundColor: 'white',
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: isIpadLandscape && !isTl ? '250px' : '330px',
    alignItems: 'center',
    justifyContent: 'space-evenly',
}))

const ForTeachersBox = styled(Box)(({ theme, isIpadLandscape, isTl }) => ({
    backgroundColor: '#F3F3F3',
    width: '100%',
    height: isIpadLandscape && !isTl ? '492px' : '500px',
    //     paddingTop:'109px',
    //    paddingLeft:'2%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-around',
    paddingLeft: '2%',
    paddingTop:'2%',
    paddingBottom:'2%'
}))

const ForStudentsBox = styled(Box)(({ theme, isTl, isIpadLandscape }) => ({
    backgroundColor: 'white',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    alignItems:'center',
    width: '100%',
    height: isIpadLandscape && !isTl ? '444px' : '444px',
    paddingLeft: '2%',
    paddingTop:'2%',
    paddingBottom:'2%'
}))

const CreateQuizBox = styled(Box)(({ theme,isTl, isIpadLandscape }) => ({
    width: '100%',
    height: '357px',
    backgroundColor:'#FFFFFF',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
}))

const SchoolBox = styled(Box)(({ theme, isTl, isIpadLandscape }) => ({
    backgroundColor: '#F3F3F3',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    width: '100%',
    height: isIpadLandscape && !isTl ? '362px' :  '362px',
    alignItems: 'center'
}))

const ReviewBox = styled(Box)(({ theme,isTl, isIpadLandscape  }) => ({
    backgroundColor: '#F3F3F3',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    width: '100%',
    height: isIpadLandscape && !isTl ? '380px' :'400px',
    alignItems: 'center',
}))

const FeatureIcon = styled('img')(({ theme }) => ({
    width: '60px',
    height: '60px',
    marginLeft: '40%'
}))

const FeatureText = styled(Typography)(({ theme, isIpadLandscape, isTl }) => ({
    fontFamily: 'Open Sans',
    fontWeight: 400,
    lineHeight: '24.51px',
    textAlign: 'center',
    fontSize: '18px',
}))

const featureBoxHeading = styled(Typography)(({theme, isIpadLandscape, isTl})=>({
    fontFamily: 'Open Sans',
    fontWeight: 700,
    lineHeight: isIpadLandscape && !isTl ? '31px' : '43.58px',
    textAlign: 'center',
    fontSize: isIpadLandscape && !isTl ? '21px' : '32px',
}))

const DoubleQuotesBlack = styled('img')(({ theme }) => ({
    width: '62px',
    height: '62px',
    marginTop:'15px'
}))

const TeacherLongDesc = styled(Typography)(({ theme, isTl, isIpadLandscape }) => ({
    fontFamily: 'Open Sans',
    lineHeight: '32px',
    fontSize: '21px',
    fontWeight: 400,
    width: isIpadLandscape && !isTl ? '474px':'570px',
    marginLeft:'20px'
}))

const TeacherImg = styled('img')(({ theme, isIpadLandscape, isTl }) => ({
    width: isIpadLandscape && !isTl ? '380px' : '380px',
    height: isIpadLandscape && !isTl ? '252px' : '252px',
    marginTop: '50px',
}))

const StudentImg = styled('img')(({ theme, isIpadLandscape, isTl }) => ({
    width: isIpadLandscape && !isTl ? '380px' : '380px',
    height: isIpadLandscape && !isTl ? '226px' : '226px',
    marginTop: '-20px'
}))

const SchoolImg = styled('img')(({ theme, isIpadLandscape, isTl }) => ({
    width: isIpadLandscape && !isTl ? '404px' : '380px',
    height: isIpadLandscape && !isTl ? '262px' : '262px',
}))

const SchoolDesc = styled(Typography)(({ theme, isIpadLandscape, isTl }) => ({
    fontFamily: 'Open Sans',
    lineHeight: '32px',
    fontSize: '21px',
    fontWeight: 400,
    width:isIpadLandscape && !isTl ? '450px':'546px',
    marginLeft:'30px'
}))

const StudentLongDesc = styled(Typography)(({ theme, isIpadLandscape, isTl }) => ({
    fontFamily: 'Open Sans',
    lineHeight: '28.6px',
    fontSize: '21px',
    fontWeight: 400,
    width:isIpadLandscape && !isTl ? '474px':'unset',
}))

const GenerateQuizBtn = styled(Button)(({ theme }) => ({
    width: '229px',
    height: '56px',
    borderRadius:'5px',
    backgroundColor: "#F96D30",
    color: theme.palette.primary.contrastText,
    textTransform: "none",
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize:'21px'
}))

const QuizContentBox = styled(Box)(({ theme, isTl, isIpadLandscape }) => ({
    display: 'flex',
    flexDirection: 'row',
    marginTop: '30px',
    width: isIpadLandscape && !isTl ? '884px':'980px',
    height:'191px',
    backgroundColor:'#7D00BA1A',
    justifyContent:'center',
    alignItems:'center',
    paddingLeft:'30px',
    paddingRight:'30px',
    marginLeft:'2%'
}))

const QuizContentBoxImage  = styled('img')(({ theme }) => ({
    width: '36px',
    height: '48px',
    marginBottom:'15px'
}))

const QuizContentBoxText = styled(Typography)(({ theme, isIpadLandscape, isTl }) => ({
    fontFamily: 'Open Sans',
    lineHeight: isIpadLandscape && !isTl ?'42px':'24x',
    fontSize: isIpadLandscape && !isTl ?'26px':'18px',
    fontWeight: 400,
    color:'#7D00BA',
    textAlign:'center'
}))

const ArrowIcon  = styled('img')(({ theme }) => ({
    width: '32px',
    height: '15.73px',
    marginRight:'30px',
    marginLeft:'30px'
}))

const Review = styled(Box)(({ theme,isTl,isIpadLandscape }) => ({
    display: 'flex',
    flexDirection: 'column',
    height: '250px',
    width: isIpadLandscape && !isTl ?'557px':'557px',
    backgroundColor: 'white',
    marginBottom: '3px',
    justifyContent: 'center',
    paddingLeft: '68px',
    paddingRight: '43px'
}))

const RatingStar = styled('img')(({ theme }) => ({
    height: '28px',
    width: '28px',
}))

const ArrowBtn = styled('img')(({ theme }) => ({
    height: '20.62px',
    width: '24px',
    cursor:'pointer'
}))

export default {
    sliderBox,
    FeaturesBox,
    ForTeachersBox,
    ForStudentsBox,
    CreateQuizBox,
    SchoolBox,
    ReviewBox,
    FeatureIcon,
    FeatureText,
    DoubleQuotesBlack,
    TeacherLongDesc,
    TeacherImg,
    StudentImg,
    SchoolImg,
    StudentLongDesc,
    GenerateQuizBtn,
    Review,
    RatingStar,
    SliderLogo,
    SliderText,
    SliderBtn,
    SliderDotBox,
    SliderImage,
    sliderText2,
    sliderText3,
    featureBoxHeading,
    SchoolDesc,
    QuizContentBox,
    QuizContentBoxImage,
    QuizContentBoxText,
    ArrowIcon,
    ArrowBtn
}