const pal = {
  primary: {
    light: "#2062BB",
    main: "#2062bb",
    dark: "#7d00ba",
    contrastText: "#ffffff",
  },
  secondary: {
    light: "#7e7e7e",
    main: "#7dc242",
    dark: "#333333",
    contrastText: "#333333",
  },
  warning: { main: "#f44236", light:"#FFA500" },
  success: { main: "#7dc242" },
  info: { main: "#c1c1c1", dark:"#aaaaaa" },
};

export default pal;
