import intlMessages from "../../locales/en";
import Box from "@mui/material/Box";
import Feedback from "../feedback/component";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";
import { Button, useMediaQuery } from "@mui/material";
import { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import DrawerMenu from "../drawer/component";
import { IconButton } from "@mui/material";
import Styled from "./styles";
import ConnectionModal from "../connection-modal/component";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import ExportMenu from "../export-menu/component";
import AvatarUI from "../avatar/component";
import { getNavigateURL } from "../../util/signin-user-data";
import { useNavigate } from "react-router-dom";
import { Categories, Events, logEvent } from "../../analytics";
import GuruDropDown from "../../common/guru/component-dropdown";
import GuruCall from "../../common/guru/component-call-modal";
import { getGuestUniqueId } from "../../common/guru/service";
import { store } from "../../redux/store";
import QuizLibrary from "../quiz-library/component";
import svgs from "../../common/svgs";
import PricingModal from "../pricing/component";
import SubscriptionModal from "../subscription-modal/component";
import PlanIFPS from "../../common/plan-ifps-modal/component";
import TrialInfoModal from "../../common/trial-info-modal/component";
import IFPSModal from "../../common/ifps-modal/component";
import AlertBox from "../alert/component";
import {
  setOpenPaymentModal,
  setOpenPricingModal,
  setOpenSubscriptionModal,
} from "../../redux/slices/featuresSlice";
import { USER_STATE } from "../../settings";
import { initAdds } from "../../adds_conversion";
import { setOpenExpiryModal } from "../../redux/slices/logInSlice";
import { getPriceDetails } from "../../api/payment/methods";

function GenerateQuizMobileLandscapeHeader(props) {
  const {
    isAboutPage,
    isMobileLandscape,
    wordCount,
    sentenceCount,
    isQuizPage,
    backClickHandler,
    handleExportMenuItemSelection,
    mainBoxHeight,
    isIpad,
    isIpadLandscape,
    isMobile,
    isSignedIn,
    isSignUpPage,
    isGoogleForm,
    isLoginPage,
    userNameData,
    isTl
  } = props;
  const { showQuizContentPage, quizData, showGuruCall } = useSelector(
    (state) => state.quiz
  );
  const { openLibrary } = useSelector((state) => state.library);
  const navigate = useNavigate();
  const [isOpenFeedback, setIsOpenFeedback] = useState(false);
  const [openDrawer, setOpenDrawer] = useState(false);
  const drawerbuttonRef = useRef(null);
  const [btnPosition, setbtnPosition] = useState({});
  const [isOpenConnectionModal, setIsOpenConnectionModal] = useState(false);
  const [iconMarginTop, setIconMarginTop] = useState(60);
  const [isClickedGuru, setIsClickedGuru] = useState(false);
  const [openGuruCallModal, setOpenGuruCallModal] = useState(false);
  const [isAudio, setIsAudio] = useState(false);
  const [clickedGuruIcon, setClickedGuruIcon] = useState(false);
  const [snackBarstate, setSnackBar] = useState({
    openSnackBar: false,
    vertical: "top",
    horizontal: "right",
    type: "success",
    message: "",
  });
  const { vertical, horizontal, openSnackBar } = snackBarstate;
  const [ifpsModalState, setIfpsModalState] = useState(false);
  const [trialModalText, setTrialModalText] = useState({
    heading: "",
    desc: "",
  });
  const [openTrialModal, setOpenTrialModal] = useState(false);
  const { price, plan, duration } = useSelector((state) => state.payment);
  const { openPricingModal, openSubscriptionModal, openPaymentModal } =
    useSelector((state) => state.feature);
  const { userData, userState, stripeStatus } = useSelector(
    (state) => state.logIn
  );
  const dispatch = useDispatch();
  const [feedbackHeading, setFeedbackHeading] = useState(false);
  const [purpose, setPurpose] = useState("");
  const [openAlertBox, setOpenAlertBox] = useState(false);
  const [alertMsg, setAlertMsg] = useState({
    message: "",
    icon: "",
  });
  const [lastModalVisited, setLastModalVisited] = useState(false);
  const [showBadge,setShowBadge]= useState(false)
  const [subscriptionModalFlag,setSubscriptionModalFlag]= useState(false)

  useEffect(() => {
    const callInProgress = store.getState().guru.callInProgress;
    const callConnected = store.getState().guru.callConnected;
    const cancelClick = store.getState().guru.cancelClick;
    if (callInProgress && !cancelClick) {
      // open call modal
      setOpenGuruCallModal(true);
    }
  }, []);
  
  const pricingBtnHandler = () => {
    dispatch(setOpenPricingModal(true));
  };

  const handleOpenFeedback = () => {
    setIsOpenFeedback(true);
  };

  const handleCloseFeedback = () => {
    setIsOpenFeedback(false);
  };
  const handleOpenDrawer = () => {
    handleCloseGuru();
    const btnBottom = drawerbuttonRef.current.getBoundingClientRect().bottom;
    let top =
      window.innerHeight - btnBottom > 200
        ? 220
        : window.innerHeight - btnBottom - 25;
    setbtnPosition(top);
    setOpenDrawer(true);
  };

  const viewFeedbackSubmissionMessage = () => {
    return setSnackBar({
      ...snackBarstate,
      type: "success",
      message: intlMessages.feedbackSubmissionMsg,
      openSnackBar: true,
    });
  };
  const showErrorMessage = (msgText) => {
    return setSnackBar({
      ...snackBarstate,
      type: "error",
      message: msgText,
      openSnackBar: true,
    });
  };
  const handleOpenConnectionModal = () => {
    setIsOpenConnectionModal(true);
  };
  const handleCloseConnectionModal = () => {
    setIsOpenConnectionModal(false);
  };
  const handleCloseSnackBar = () => {
    setSnackBar({ ...snackBarstate, openSnackBar: false });
  };

  const handleCloseGuru = () => {
    setIsClickedGuru(false);
  };

  const handleOpenGuru = () => {
    setIsClickedGuru(true);
  };

  const openClickedGuruIcon = () => {
    // console.log("inside here open icon clic ked");
    setClickedGuruIcon(true);
  };

  const closeClickedGuruIcon = () => {
    setClickedGuruIcon(false);
  };

  const initiateCall = (data) => {
    handleCloseGuru();
    // send params to start the call
    setIsAudio(data.isAudio);
    setOpenGuruCallModal(true);
  };

  const closeGuruCallModal = () => {
    setOpenGuruCallModal(false);
  };

  const navigateToUrl = (url, eventType) => {
    logEvent(Categories.user_management, eventType);
    const forgotpasswordUrl = getNavigateURL(url);
    // // console.log(forgotpasswordUrl);
    navigate(forgotpasswordUrl);
  };

  useEffect(()=>{
    let timer = null
    if(isSignedIn===true && stripeStatus !== null &&
      userData?.subscriptionPlan !== null){
        timer = setTimeout(() => {  
          setShowBadge(true)
        }, 100);
      }
    else{
      setShowBadge(false)
    }
    return ()=>clearTimeout(timer)
  },[isSignedIn,stripeStatus])

  const renderSnackBar = () => {
    return (
      <Snackbar
        open={openSnackBar}
        anchorOrigin={{ vertical, horizontal }}
        autoHideDuration={3000}
        onClose={handleCloseSnackBar}
        key={vertical + horizontal}
      >
        <MuiAlert
          elevation={6}
          variant="filled"
          sx={{ color: "white" }}
          onClose={handleCloseSnackBar}
          severity={snackBarstate.type}
        >
          {snackBarstate.message}
        </MuiAlert>
      </Snackbar>
    );
  };

  return (
    <Styled.HeaderMainContainer>
      {renderSnackBar()}
      <Box sx={{ position: "fixed", top: 25 }}>
        <Styled.PlanBox
          sx={{
            display:
              showBadge===true?"flex":"none"
          }}
        >
          <Styled.PlanText>
            {userData?.isScreenSubscription === true
              ? intlMessages.subscriptionModalIPSTrialText
              : userData?.stripeStatus === 0
              ? intlMessages.headerTrialLabel
                  .replace(
                    "#",
                    userData?.trialRemainingDays !== 0
                      ? userData?.trialRemainingDays
                      : userData?.trialRemainingHours != 0
                      ? userData?.trialRemainingHours
                      : userData?.trialRemainingMinutes
                  )
                  .replace(
                    "#2",
                    userData?.trialRemainingDays !== 0
                      ? intlMessages.daysLeftLabel
                      : userData?.trialRemainingHours != 0
                      ? intlMessages.hoursLeftLabel
                      : intlMessages.minutesLeftLabel
                  )
              : userData?.subscriptionPlan === "Pro"
              ? userData?.pricePlan === "Monthly"
                ? intlMessages.headerProPlanMonthLabel
                : intlMessages.headerProPlanYearLabel
              : intlMessages.headerBasicPlanLabel}
          </Styled.PlanText>
        </Styled.PlanBox>
        {showQuizContentPage && (
          <Styled.StyledBackBtn
            variant={"contained"}
            onClick={backClickHandler}
          >
            {intlMessages.back}
          </Styled.StyledBackBtn>
        )}
        {showQuizContentPage &&
          quizData.questionAndAnswers.find(
            (question) => question.isChecked
          ) && (
            <ExportMenu
              handleMenuItemSelection={handleExportMenuItemSelection}
              isMobileLandscape={isMobileLandscape ? true : false}
            />
          )}
        {/* <Box sx={{ display: showQuizContentPage ? "none" : "unset" }}>
          <Typography
            sx={{
              fontSize: "12px",
              fontWeight: "400",
              color: "#FFFFFF",
              textAlign: "center",
            }}
          >
            {sentenceCount}
          </Typography>
          <Typography
            sx={{
              fontSize: "12px",
              fontWeight: "400",
              color: "#FFFFFF",
              textAlign: "center",
            }}
          >
            {intlMessages.sentencesLabel}
          </Typography>
          <Typography
            sx={{
              fontSize: "12px",
              fontWeight: "400",
              color: "#FFFFFF",
              textAlign: "center",
              mt: "30%",
            }}
          >
            {wordCount}
          </Typography>
          <Typography
            sx={{
              fontSize: "12px",
              fontWeight: "400",
              color: "#FFFFFF",
              textAlign: "center",
            }}
          >
            {intlMessages.wordsLabel}
          </Typography>
        </Box> */}
      </Box>
      <Box
        sx={{
          position: "fixed",
          marginTop: `${
            isSignedIn ? mainBoxHeight + 30 : mainBoxHeight + 30
          }px`,
        }}
      >
        <Styled.IconContainer>
          <Styled.SignUpButtonMobile
            variant="contained"
            color="secondary"
            sx={{
              display:
                !isSignedIn && !isSignUpPage && !isGoogleForm ? "" : "none",
            }}
            onClick={() =>
              navigateToUrl(
                "/signup",
                Events.user_management_signup_click_generate_quiz_btn
              )
            }
          >
            {" "}
            {intlMessages.freesignUpWithoutspace}{" "}
          </Styled.SignUpButtonMobile>
          <AvatarUI
            isIpad={isIpad}
            isAboutPage={isAboutPage}
            isIpadLandscape={isIpadLandscape}
            isMobile={isMobile}
            isMobileLandscape={isMobileLandscape}
            isSignedIn={isSignedIn}
            isSignUpPage={isSignUpPage}
            isGoogleForm={isGoogleForm}
            isLoginPage={isLoginPage}
            userNameData={userNameData}
          />
          {/* <IconButton
            aria-label="feedback"
            sx={{ p: 0 }}
            onClick={handleOpenFeedback}
            color="primary"
          >
            <Styled.FeedbackLogo src="/svgs/Feedback.svg"></Styled.FeedbackLogo>

          </IconButton> */}
          <IconButton
            ref={drawerbuttonRef}
            aria-label="drawer"
            sx={{ p: 0, marginTop: "30px" }}
            onClick={handleOpenDrawer}
            color="primary"
          >
            <Styled.DrawerLogo
              src={openDrawer ? svgs["drawer-green"] : svgs["drawer-white"]}
            ></Styled.DrawerLogo>
          </IconButton>
          {isOpenFeedback && (
            <Feedback
              isOpen={isOpenFeedback}
              closeFeedbackCallback={handleCloseFeedback}
              viewFeedbackSubmissionMessage={viewFeedbackSubmissionMessage}
              showErrorMessageCallback={showErrorMessage}
              handleOpenConnectionModalCallback={handleOpenConnectionModal}
            />
          )}

          {openDrawer && (
            <DrawerMenu
              isOpen={openDrawer}
              isAboutPage={isAboutPage}
              isQuizPage={isQuizPage}
              top={btnPosition}
              isMobileLandscape={isMobileLandscape}
              onClose={(flag) => setOpenDrawer(flag)}
              handleOpenFeedback={handleOpenFeedback}
              handleOpenGuru={openClickedGuruIcon}
            ></DrawerMenu>
          )}
        </Styled.IconContainer>
      </Box>
      {isOpenConnectionModal && (
        <ConnectionModal
          isOpen={isOpenConnectionModal}
          handleCloseConnectionModal={handleCloseConnectionModal}
        />
      )}
      {clickedGuruIcon && (
        <GuruDropDown
          isIpadLandscape={isIpadLandscape}
          isMobile={isMobile}
          isMobileLandscape={isMobileLandscape}
          isIpad={isIpad}
          isClicked={isClickedGuru}
          handleCloseGuru={handleCloseGuru}
          initiateCall={initiateCall}
          clickedGuruIcon={clickedGuruIcon}
          closeCallModal={closeClickedGuruIcon}
        ></GuruDropDown>
      )}

      {openGuruCallModal && (
        <GuruCall
          isAudio={isAudio}
          guestUniqueId={getGuestUniqueId()}
          closeCallModal={closeGuruCallModal}
          isIpadLandscape={isIpadLandscape}
          isMobile={isMobile}
          isMobileLandscape={isMobileLandscape}
          isIpad={isIpad}
        />
      )}
      {openLibrary && (
        <QuizLibrary
          isIpad={isIpad ? true : false}
          isMobile={isMobile ? true : false}
          isMobileLandscape={isMobileLandscape ? true : false}
          isIpadLandscape={isIpadLandscape ? true : false}
        />
      )}
      {openPricingModal && (
        <PricingModal
          isTl={isTl}
          isIpadLandscape={isIpadLandscape}
          isMobile={isMobile}
          isMobileLandscape={isMobileLandscape}
          isIpad={isIpad}
          open={openPricingModal}
          onClose={() => {
            if (userState === USER_STATE.EXPIRED_USER) {
              dispatch(setOpenExpiryModal(true));
            }
            dispatch(setOpenPricingModal(false));
          }}
          onSubmit={() => {}}
        />
      )}
      {openSubscriptionModal && (
        <SubscriptionModal
          isIpadLandscape={isIpadLandscape}
          isMobile={isMobile}
          isMobileLandscape={isMobileLandscape}
          isIpad={isIpad}
          open={openSubscriptionModal}
          onClose={() => dispatch(setOpenSubscriptionModal(false))}
        ></SubscriptionModal>
      )}
      {openPaymentModal && (
        <PlanIFPS
          packagPrice={price}
          packageType={duration}
          open={openPaymentModal}
          onClose={() => {
            dispatch(setOpenPaymentModal(false));
            if (userState === USER_STATE.EXPIRED_USER) {
              dispatch(setOpenExpiryModal(true));
            }
          }}
          onSubmitIFPS={(flag,isSubscriptionModal) => {
            dispatch(setOpenPaymentModal(false));
            setSubscriptionModalFlag(isSubscriptionModal)
            setIfpsModalState(flag);
          }}
        ></PlanIFPS>
      )}
      {openTrialModal && (
        <TrialInfoModal
          text={trialModalText}
          open={openTrialModal}
          onClose={(flag) => setOpenTrialModal(flag)}
        ></TrialInfoModal>
      )}
      {ifpsModalState && (
        <IFPSModal
          open={ifpsModalState}
          subscriptionModalFlag={subscriptionModalFlag}
          openFeedbackModal={(open, isIFPS) => {
            setFeedbackHeading(isIFPS);
            setPurpose(intlMessages.feedbackModalSerialSubject);
            setLastModalVisited(true);
            setIsOpenFeedback(open);
            setIfpsModalState(false);
          }}
          onClose={(flag, trialModalFlag, cancelBtnFlag) => {
            setIfpsModalState(flag);
            if (!!cancelBtnFlag && userState !== USER_STATE.EXPIRED_USER)
              dispatch(setOpenPaymentModal(true));
            if (!!trialModalFlag) {
              initAdds();
              setTrialModalText({
                heading: intlMessages.trialModalYearHeading.replace(
                  "#",
                  userData?.trialDays
                ),
                desc: intlMessages.trialModalYearDesc,
              });
              setOpenTrialModal(true);
            }
            if (userState === USER_STATE.EXPIRED_USER) {
              dispatch(setOpenExpiryModal(true));
            }
          }}
        ></IFPSModal>
      )}
      {openAlertBox && (
        <AlertBox
          isIpad={isIpad ? true : false}
          isMobile={isMobile ? true : false}
          isMobileLandscape={isMobileLandscape ? true : false}
          isIpadLandscape={isIpadLandscape ? true : false}
          message={alertMsg}
          isOpen={openAlertBox}
          onClose={() => {
            setOpenAlertBox(false);
            if (userState === USER_STATE.EXPIRED_USER) {
              dispatch(setOpenExpiryModal(true));
            }
          }}
        ></AlertBox>
      )}
    </Styled.HeaderMainContainer>
  );
}

export default GenerateQuizMobileLandscapeHeader;
