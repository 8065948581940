import { userSignUp } from "../../api/sign-up/methods";

const validateInput = (i) => {
  let _input = i;
  if (/^\s/.test(_input)) _input = _input.trimStart();
  return _input;
};

const validateEmail = (inputText) => {
  const mailformat =
    /^[a-zA-Z0-9]+([._%+-][a-zA-Z0-9]+)*@[a-zA-Z0-9]+([.-][a-zA-Z0-9]+)*\.[a-zA-Z]{2,}$/;
  return inputText.match(mailformat);
};

const validateName = (name) => {
  const regex = /^[A-Za-z ]+$/;
  return regex.test(name.trimEnd());
};

const validatePassword = (password) => {
  const regex = /^(?=.*[A-Z])(?=.*[a-z]).{6,}$/;
  return regex.test(password);
};

const signUp = async (
  firstname,
  lastname,
  email,
  password,
  confirmpassword,
  userrole,
  provider,
  newsletterChecked
) => {
  const response = await userSignUp(
    firstname.trimEnd(),
    lastname.trimEnd(),
    email,
    password,
    confirmpassword,
    userrole,
    provider,
    newsletterChecked
  );
  return response;
};

export { validateInput, validateName, validateEmail, validatePassword, signUp };
