import { useState, useEffect } from "react";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  IconButton,
  Link,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import AddIcon from "@mui/icons-material/Add";
import Styled from "./styles";
import CircularProgress from "@mui/material/CircularProgress";
import {
  validateInput,
  validateEmail,
  getInternetConnectionStatus,
  setUserData,
  getUserData,
  updateUserData,
} from "../review/service";
import { getWordCount, COMMENT_MAX_WORD_COUNT } from "../quiz/service";
import intlMessages from "../../locales/en";

export default function EmailModal(props) {
  const {
    openConnectionErrorCallback,
    emailSubmissionCallback,
    isOpen,
    closeEmailModalCallback,
  } = props;
  const [openReviewModal, setOpenReviewModal] = useState(isOpen);
  const [checked, setChecked] = useState(true);
  const [review, setReview] = useState({
    rating: 0,
    email: "",
    comment: "",
  });
  const [errors, setErrors] = useState({
    reviewEmailError: null,
    reviewRatingError: null,
    reviewCommentError: null,
  });
  const { reviewEmailError, reviewRatingError } = errors;
  useEffect(() => {
    const userData = getUserData();
    if (userData) {
      const { email } = userData;
      const { rating } = userData;
      setReview({
        email: email ? email : "",
        rating: rating ? rating : 0,
      });
    }
  }, []);
  const [isQuizLoading, setIsQuizLoading] = useState(false);

  const closeReviewModal = () => {
    closeEmailModalCallback();
    setReview({
      rating: 0,
      email: "",
      comment: "",
    });
    setErrors({
      ...errors,
      reviewEmailError: null,
      reviewRatingError: null,
    });
  };

  const handleChange = (event) => {
    setChecked(event.target.checked);
  };

  const onChangeReviewRating = (e, newValue) => {
    const inputText = newValue;
    const { reviewRatingError } = errors;
    if (
      parseFloat(inputText) !== (0 || null || undefined) &&
      reviewRatingError
    ) {
      setErrors({ ...errors, reviewRatingError: null });
    }
    setReview({ ...review, rating: inputText });
  };

  const onChangeReviewEmail = (e) => {
    const inputText = validateInput(e.target.value);
    const { reviewEmailError } = errors;
    if (
      (inputText.length > 0 &&
        reviewEmailError === intlMessages.emptyEmailError) ||
      (validateEmail(inputText) &&
        reviewEmailError === intlMessages.incorrectEmailFormat)
    ) {
      setErrors({ ...errors, reviewEmailError: null });
    }
    setReview({ ...review, email: inputText });
  };

  const onChangeReviewComment = (e) => {
    const inputText = validateInput(e.target.value);
    if (
      errors.reviewCommentError ===
        intlMessages.maxCommentWordCount.replace(
          "{0}",
          COMMENT_MAX_WORD_COUNT
        ) &&
      getWordCount(inputText) <= COMMENT_MAX_WORD_COUNT
    ) {
      setErrors({ ...errors, reviewCommentError: null });
    }
    setReview({ ...review, comment: inputText });
  };

  const callbackUpdateUserData = (uEmail, uRating) => {
    const uData = getUserData();
    const userData = {
      email: uEmail,
      rating: uRating,
    };
    if (uData) {
      updateUserData(userData);
    } else {
      setUserData(userData);
    }
  };

  const onSubmitReview = async () => {
    const { email, rating, comment } = review;
    setIsQuizLoading(true);
    if (email.length === 0) {
      setIsQuizLoading(false);
      return setErrors({
        ...errors,
        reviewEmailError: intlMessages.emptyEmailError,
        reviewRatingError: intlMessages.emptyRatingError,
      });
    }
    if (email.length === 0) {
      setIsQuizLoading(false);
      return setErrors({
        ...errors,
        reviewEmailError: intlMessages.emptyEmailError,
      });
    }
    if (!validateEmail(email)) {
      setIsQuizLoading(false);
      return setErrors({
        ...errors,
        reviewEmailError: intlMessages.incorrectEmailFormat,
      });
    }
    const internetConnection = await getInternetConnectionStatus();
    if (!internetConnection?.isConnected) {
      setIsQuizLoading(false);
      return openConnectionErrorCallback();
    }
    const userEmail = {
      email,
    };
    // closeReviewModal();
    emailSubmissionCallback(userEmail, checked);
    callbackUpdateUserData(email, rating);
    // setReview({
    //   rating: 0,
    //   email: "",
    //   comment: "",
    // });
  };

  return (
    <Modal
      open={openReviewModal}
      aria-labelledby="review-modal"
      aria-describedby="review-description"
    >
      <Styled.ModalBox sx={Styled.customStyling.exportModal}>
        <Styled.ModalHeader>
          <Typography
            id="review-modal-title"
            variant="h5"
            component="h5"
            style={{ fontWeight: "bold" }}
          >
            {intlMessages.exportYouQuizHeader}
          </Typography>
          <IconButton
            aria-label="closeReviewModal"
            onClick={() => {
              closeReviewModal();
            }}
          >
            <CloseIcon color="seondary.light" />
          </IconButton>
        </Styled.ModalHeader>
        <Styled.ModalContentBox>
          <Typography color="secondary.dark" variant="h6" mb={2}>
            {`${intlMessages.shareEmailText} *`}
          </Typography>
          <Box mt={2} sx={{ display: "flex", flexDirection: "column" }}>
            <Styled.ModalTextField
              value={review.email}
              onChange={onChangeReviewEmail}
              placeholder={`${intlMessages.emailLabel} *`}
              type="email"
              required
              helperText={reviewEmailError}
              error={reviewEmailError ? true : false}
            />
          </Box>
          <Box mt={"15px"} sx={{ display: "flex", flexDirection: "column" }}>
            <FormGroup>
              <Styled.StyledFormControlLabel
                checked={checked}
                sx={{
                  color: "GrayText",
                  textDecoration: "none",
                  fontSize: "14px",
                }}
                onChange={handleChange}
                control={<Checkbox color="secondary" />}
                label={intlMessages.emailModalCheckboxText}
              />
            </FormGroup>
          </Box>
        </Styled.ModalContentBox>
        <Styled.ModalActions>
          <Styled.CreateQuizButton
            variant="contained"
            color="secondary"
            onClick={onSubmitReview}
            loading={isQuizLoading}
            loadingPosition="start"
            disabled={isQuizLoading ? "true" : undefined}
            loadingIndicator={<CircularProgress color="inherit" size={16} />}
          >
            {intlMessages.submitLabel}
          </Styled.CreateQuizButton>
          {/* <Button
            sx={{
              textTransform: "none",
              marginLeft: 1,
            }}
            variant="outlined"
            color="secondary"
            disabled={isQuizLoading ? "true" : undefined}
            onClick={() => closeEmailModalCallback()}
          >
            {intlMessages.cancelLabel}
          </Button> */}
        </Styled.ModalActions>
      </Styled.ModalBox>
    </Modal>
  );
}
