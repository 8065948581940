import styled from "@mui/system/styled";
import Divider from "@mui/material/Divider";
import Dialog from '@mui/material/Dialog';

const Seperator = styled(Divider)(({ theme }) => ({
    backgroundColor: theme.palette.info.main,
    width:2,
    marginLeft:"5%",
    marginRight:"5%"
  }));

const StyledMobileModal = styled(Dialog)(({ theme }) => ({
    backgroundColor:'transparent',
    [`${theme.breakpoints.down("md")}  and (orientation: landscape)`]: {
        height:'90vh',
        width:'90vw',
        top:'0vh',
        // left:'-4vw'
      },
  }));

  export default {
    Seperator,
    StyledMobileModal
  };