import { useState, useEffect } from "react";
import intlMessages from "../../locales/en";
import {
  Button,
  Typography,
  IconButton,
  Modal,
  Box,
  DialogTitle,
  Icon,
  CircularProgress,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Styled from "./styles";
import { useNavigate } from "react-router-dom";
import { getBillingURL } from "./service";
import { useDispatch, useSelector } from "react-redux";
import { setOpenPricingModal, setOpenSubscriptionModal } from "../../redux/slices/featuresSlice";
import { Categories, Events, logEvent } from "../../analytics";
import { FREE_PLAN_SUBSCRIPTION_COST } from "../../settings";
// import TrialInfoModal from "../trial-info-modal/component";

export default function PlanIFPS(props) {
  const {
    open,
    onClose,
    onSubmitIFPS,
    packagPrice = 0,
    packageType = "yearly",
  } = props;
  const {
    quizInputText,
    quizData
  } = useSelector((state) => state.quiz);
  const { userData,stripeStatus } = useSelector((state) => state.logIn);
  const { isScreenPanelBtnClicked } = useSelector((state) => state.payment);
  const [isSaving, setIsSaving] = useState(false);
  const dispatch = useDispatch();

  const handleOnClose = () => {
    logEvent(
      Categories.select_payment_method_modal,
      Events.select_payment_method_modal_close_btn_clicked
    );
    onClose(false);
  };
  const showStripePage = async () => {
    setIsSaving(true)
    logEvent(
      Categories.select_payment_method_modal,
      Events.select_payment_method_modal_pay_card_btn_clicked
    );
    let quizDataExists = false
    if(quizInputText.length!==0){
      localStorage.setItem("quizContent",quizInputText)
      localStorage.setItem("quizData",JSON.stringify(quizData))
      quizDataExists=true
    }
    const stripeDetails = await getBillingURL(packageType,quizDataExists);
    setIsSaving(false)
    window.location.href = stripeDetails?.data?.checkoutUrl || "#";
  };

  useEffect(()=>{
    if(isScreenPanelBtnClicked===true){
      onSubmitIFPS(true,true)
    }
  },[])

  const toggleIfpsModal = () => {
    logEvent(
      Categories.select_payment_method_modal,
      Events.select_payment_method_modal_own_screen_btn_clicked
    );
    onSubmitIFPS(true);
  };
  return (
    <>
      <Modal open={open}>
        <Styled.ModalBox sx={Styled.customStyling.exportModal}>
          <Styled.ModalHeader>
            <DialogTitle>{intlMessages.SelectPaymentMethod}</DialogTitle>
            <IconButton
              aria-label="closeWarningParagraphModal"
              onClick={handleOnClose}
              sx={{ mr: "10px" }}
            >
              <CloseIcon color="seondary.light" />
            </IconButton>
          </Styled.ModalHeader>
          <Styled.ModalContentBox>
            <br></br>
            <Styled.PriceSection>
              <Styled.TextContainer>
                {(stripeStatus===0 && userData?.subscriptionPlan==="Trial")?intlMessages.Subscribeto1YearProTrial:packageType === "yearly"
                  ? intlMessages.SubscribetoProYearlyPlan
                  : intlMessages.SubscribetoProMonthlyPlan}
              </Styled.TextContainer>
              <Styled.PriceTextContainer>
                <Styled.StyledHeading>{(stripeStatus===0 && userData?.subscriptionPlan==="Trial")?`$${FREE_PLAN_SUBSCRIPTION_COST}`:`$${packagPrice}`}</Styled.StyledHeading>
                <Typography
                  sx={{
                    fontWeight: 400,
                    fontSize: "16px",
                    lineHeight: "21.78px",
                    mt: "5px",
                  }}
                >
                  {packageType === "yearly" || (stripeStatus===0 && userData?.subscriptionPlan==="Trial")
                    ? intlMessages.subscriptionModalPerYearLabel
                    : intlMessages.subscriptionModalPerMonthLabel}
                </Typography>
              </Styled.PriceTextContainer>
            </Styled.PriceSection>
            <Styled.StyledDivider orientation="horizontal"></Styled.StyledDivider>

            <Styled.PaymentOptionContainer>
              <Styled.TextContainer>
                {intlMessages.Howwouldyouliketopay}
              </Styled.TextContainer>
              <Styled.ModalButton
                disabled={isSaving===true || (stripeStatus===0 && userData?.subscriptionPlan==="Trial")?true:false}
                borderColor="#7DC242"
                disabledColor={isSaving===true || (stripeStatus===0 && userData?.subscriptionPlan==="Trial")?true:false}
                onClick={() => showStripePage()}
              >
                {isSaving===true &&
                <CircularProgress
                    color="inherit"
                    sx={{ marginRight: "10px" }}
                    size={16}
                  />}
                <Styled.ButtonIcon disabledColor={isSaving===true || (stripeStatus===0 && userData?.subscriptionPlan==="Trial")?true:false} src="svgs/Card.svg"></Styled.ButtonIcon>
                {intlMessages.Payviacard}
              </Styled.ModalButton>
              <Styled.StyledBtnDivider orientation="horizontal">
                {intlMessages.quizInputBoxPlaceholderText3}
              </Styled.StyledBtnDivider>

              <Styled.ModalButton
                borderColor="#333333"
                onClick={() => toggleIfpsModal()}
                disabled={isSaving===true?true:false}
                disabledColor={isSaving===true?true:false}
              >
                <Styled.ButtonIcon disabledColor={isSaving===true?true:false} src="svgs/IFP.svg"></Styled.ButtonIcon>{" "}
                {intlMessages.IownIFPS}
              </Styled.ModalButton>
              <Styled.SubscriptionPolicyBtn
                  href="subscription-policy.html"
                  target="_blank"
                >
                  {intlMessages.subscriptionPolicyLabel}
                </Styled.SubscriptionPolicyBtn>
            </Styled.PaymentOptionContainer>
            <Styled.ModalFooter>
              <Styled.FooterButton
                onClick={() => {
                  logEvent(
                    Categories.select_payment_method_modal,
                    Events.select_payment_method_modal_back_btn_clicked
                  );
                  onClose(false);
                  if(isScreenPanelBtnClicked===false){
                    dispatch(setOpenPricingModal(true));
                  }
                }}
                sx={{ ":hover": { bgcolor: "#89d44a" }, width: "83px" }}
                backgroundColor="#7DC242"
                fontColor="#ffffff"
                borderColor="#7DC242"
              >
                {intlMessages.back}
              </Styled.FooterButton>
              <Styled.FooterButton
                sx={{
                  textTransform: "none",
                  marginLeft: 2,
                  border: 1,
                }}
                backgroundColor=""
                fontColor="#A6A6A6"
                borderColor="#A6A6A6"
                onClick={() => {
                  logEvent(
                    Categories.select_payment_method_modal,
                    Events.select_payment_method_modal_close_btn_clicked
                  );
                  onClose(false);
                }}
              >
                {intlMessages.cancelLabel}
              </Styled.FooterButton>
            </Styled.ModalFooter>
          </Styled.ModalContentBox>
        </Styled.ModalBox>
      </Modal>
    </>
  );
}
