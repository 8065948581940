import React, { useEffect, useRef } from "react";
import intlMessages from "../../../locales/en";
import Styled from "./styles";
import { APP_NAME, COMPANY_NAME } from "../../../settings";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";
import { Dialog } from "@mui/material";
import quizwizlogo from "./quizwiz-logo";

export default function MobileWelcomeModal(props) {
  let {
    isOpen,
    isIpad,
    isIpadLandscape,
    isMobile,
    isMobileLandscape,
    theme,
    handleClose,
    checked,
    handleChange,
    slide,
    handleConsentClick,
    consentColor,
    list,
    handleTrialBtn,
  } = props;
  const scrollRef = useRef(null);
  useEffect(() => {
    if (isMobile || isMobileLandscape) {
      setTimeout(function () {
        scrollRef?.current?.scrollIntoView({
          block: "end",
          behavior: "smooth",
        });
      }, 1);
      return () => {};
    }
  }, [isMobile, isMobileLandscape]);

  return (
    <Dialog
      fullScreen
      componentsProps={{
        backdrop: { style: { backgroundColor: "rgba(0, 0, 0, 0.8)" } },
      }}
      sx={{
        bgcolor: "background.paper",
        background: "transparent",
      }}
      PaperProps={{
        style: {
          backgroundColor: "transparent",
          boxShadow: "none",
        },
      }}
      open={isOpen}
    >
      <Styled.MobileContainer
        isIpad={isIpad ? true : false}
        isIpadLandscape={isIpadLandscape ? true : false}
        ref={scrollRef}
        container
        isMobileLandscape={isMobileLandscape ? true : false}
      >
        <Grid container>
          <Grid item xs={12} sm={6}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Styled.MobilelogoImage src={`${quizwizlogo}`} />
              </Grid>
              <Grid
                item
                xs={12}
                sx={{ ml: isMobileLandscape ? "unset" : -4, mb: "0%", mt: -3 }}
              >
                <Styled.MobileSlidesImage
                  src={slide.imgSrc}
                  isMobile={isMobile ? true : false}
                ></Styled.MobileSlidesImage>
              </Grid>
              <Grid item xs={12} sx={{ ml: isMobileLandscape ? 2 : 0 }}>
                <Styled.MobileStyledSlideDesc
                  sx={{ width: "80%" }}
                  variant="h3"
                >
                  {slide.desc}
                </Styled.MobileStyledSlideDesc>
              </Grid>
              <Grid
                item
                xs={12}
                sx={{
                  mb: isMobileLandscape ? "3%" : "1%",
                  mt: isMobile ? "3%" : "3%",
                  ml: isMobileLandscape ? "unset" : -3,
                }}
              >
                <Styled.MobileStyledDotDiv>{list}</Styled.MobileStyledDotDiv>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Grid container>
              <Grid
                item
                xs={12}
                sx={{ mt: "15%", mr: isIpad ? "20px" : "unset" }}
              >
                <Styled.MobiledescText variant="h5">
                  {intlMessages.SplashScreenMobileDesc.replace("{0}", APP_NAME)}
                </Styled.MobiledescText>
              </Grid>
              <Grid item xs={12} sx={{ mt: isMobile ? 5 : "15%" }}>
                <Button
                  variant="contained"
                  sx={{
                    ":hover": {
                      bgcolor: "#c0c2c4",
                    },
                    backgroundColor: "rgba(255, 255, 255, 0.90)",
                    color: "#7DC242",
                    textTransform: "none",
                    marginRight: { sm: "10px", xs: "10px" },
                    height: 34,
                    padding: "9px 15px",
                    overflowWrap: "break-word",
                    whiteSpace: "nowrap",
                    width: isIpad ? "130px" : "121px",
                  }}
                  onClick={handleClose}
                >
                  {intlMessages.SplashScreenGenerateQuizBtnText}
                </Button>
                <Button
                  variant="contained"
                  sx={{
                    ":hover": {
                      bgcolor: "#d6511d",
                    },
                    backgroundColor: "#F96D30",
                    color: theme.palette.primary.contrastText,
                    textTransform: "none",
                    height: 34,
                    padding: "9px 15px",
                    overflowWrap: "break-word",
                    whiteSpace: "nowrap",
                    width: isIpad ? "130px" : "121px",
                  }}
                  onClick={handleTrialBtn}
                >
                  {isIpad || isIpadLandscape
                    ? intlMessages.SplashScreenTrialBtnText
                    : intlMessages.SplashScreenMobileTrialBtnText}
                </Button>
              </Grid>
              <Grid
                item
                xs={12}
                sx={{
                  mt: isMobileLandscape ? "10%" : "10%",
                  mb: isMobile ? 2 : "unset",
                }}
              >
                <Grid container>
                  <Grid item xs={12}>
                    <Styled.MobileConsentBox>
                      <Styled.StyledCheckbox
                        sx={{ height: 0, mt: "1%" }}
                        icon={<Styled.StyledUnCheckedIcon />}
                        checkedIcon={<img src="/svgs/Checkbox.svg"></img>}
                        className="consent-checkbox"
                        checked={checked}
                        onChange={handleChange}
                      />
                      <Styled.MobiledescText2
                        className={"noSelect"}
                        variant="h6"
                        sx={{
                          color: consentColor ? "red" : "white",
                          opacity: consentColor ? 1 : 0.5,
                          textAlign: "justify",
                        }}
                      >
                        <span onClick={handleConsentClick}>
                          {intlMessages.SplashScreenConsentText}{" "}
                        </span>
                        <a
                          href="terms.html"
                          target="_blank"
                          style={{
                            cursor: "pointer",
                            textDecoration: "none",
                            fontSize: "0.8rem",
                            color: "#0bbde0",
                          }}
                        >
                          {intlMessages.signUpConsentText2}
                        </a>{" "}
                        <span>{intlMessages.signUpConsentText3}</span>{" "}
                        <a
                          href="privacy-policies.html"
                          target="_blank"
                          style={{
                            cursor: "pointer",
                            textDecoration: "none",
                            fontSize: "0.8rem",
                            color: "#0bbde0",
                          }}
                        >
                          {intlMessages.signUpConsentText4}
                        </a>
                        <span>{intlMessages.signUpConsentText5}</span>
                      </Styled.MobiledescText2>
                    </Styled.MobileConsentBox>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Styled.MobileContainer>
    </Dialog>
  );
}
