import api from "../index";
import { checkInternetConnection } from "../service";
import {
  sendSignUpConversionEvent,
  sendSigUpEmailConversionEvent,
} from "../../adds_conversion";
import { Events, Categories, logEvent } from "../../analytics";

async function userSignUp(
  firstName,
  lastName,
  email,
  password,
  confirmPassword,
  userRole,
  provider,
  newsletterChecked,
  abortControllerSignal
) {
  try {
    const payload = {
      provider: provider,
      firstName: firstName,
      lastName: lastName,
      email: email,
      password: password,
      confirmPassword: confirmPassword,
      userRole: userRole,
      enableNewsLetter: newsletterChecked,
    };
    const connectionStatus = await checkInternetConnection();
    const isInternetConnected = connectionStatus.isConnected;
    if (!isInternetConnected) return connectionStatus;
    const res = await api.post(`/user/signup`, payload, {
      signal: abortControllerSignal,
    });
    sendSignUpConversionEvent();
    sendSigUpEmailConversionEvent();
    logEvent(Categories.user_management, Events.signup);
    logEvent(Categories.user_management, Events.signup_by_email);

    return res;
  } catch (e) {
    return e;
  }
}

async function resendEmail(email) {
  try {
    const params = {
      email: email,
    };
    const connectionStatus = await checkInternetConnection();
    const isInternetConnected = connectionStatus.isConnected;
    if (!isInternetConnected) return connectionStatus;
    const res = await api.get(`/user/resendVerify`, { params });
    return res;
  } catch (e) {
    return e;
  }
}

async function UpdateUserConsent(consent) {
  try {
    const payload = {
      enableNewsLetter: consent,
    };
    const connectionStatus = await checkInternetConnection();
    const isInternetConnected = connectionStatus.isConnected;
    if (!isInternetConnected) return connectionStatus;
    const res = await api.patch(`/user/profile`, payload);
    return res;
  } catch (e) {
    return e;
  }
}

export { userSignUp, resendEmail, UpdateUserConsent };
