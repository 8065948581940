import styled from "@mui/system/styled";
import {Typography, Button} from "@mui/material";

const confirmBtn = styled(Button)(({isMobile})=>({
    backgroundColor:'#7DC243',
    width: isMobile?'140px':'160px',
    height:'33px',
    borderRadius:'4px',
    color:'white',
    textTransform:'none',
    marginRight:'15px',
    '&:hover':{
        backgroundColor: '#89d44a', 
    }
}))

const closeBtn = styled(Button)(({isMobile})=>({
    width: isMobile?'81px':'101px',
    height:'33px',
    borderRadius:'4px',
    color:'#7DC243',
    textTransform:'none',
    marginRight:'15px',
    border:'1px solid #7DC243',
}))

const deleteHeading = styled(Typography)(({})=>({
    fontFamily:'Open Sans',
    fontWeight:'600',
    lineHeight:'23.15px',
    fontSize:'17px',
    color: '#333333',
}))

const deleteDesc = styled(Typography)(({isMobile,isMobileLandscape})=>({
    fontFamily:'Open Sans',
    fontWeight:'400',
    lineHeight: isMobile?'24px':'28px',
    fontSize:'16px',
    color: '#333333',
    width:isMobile?'305px':'410px',
    marginTop:isMobileLandscape?'20px':'30px',
    marginBottom:isMobileLandscape?'0px':'10px',
    paddingRight:'5%'
}))


export default {
    confirmBtn,
    deleteHeading,
    deleteDesc,
    closeBtn
}