import { IconButton, MenuItem } from "@mui/material";
import styled from "@mui/system/styled";


const IconBtn = styled(IconButton)(({theme})=>({
    color:'#757575',
    width:'6px',
    height:'20px'
}))

const menuItem = styled(MenuItem)(({theme})=>({
    fontSize:'14px',
    fontWeight:'600',
    lineHeight:'19.07px',
    fontFamily:'Open Sans',
    paddingTop:0,
    paddingBottom:0
}))


export default {
    IconBtn,
    menuItem,
  };
  