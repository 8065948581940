import styled from "@mui/system/styled";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { Button, Divider, Typography } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";

const ModalPrmissionSignInBox = styled(Box)(({ theme }) => ({
  width: "35%",
  [theme.breakpoints.down("lg")]: {
    width: "70%",
  },
  [theme.breakpoints.down("sm")]: {
    width: "95%",
  },
}));

const ModalContentBox = styled(Box)({
  marginTop: 30,
  marginLeft: 25,
  marginRight: 25,
  marginBottom: 30,
  display: "flex",
  justifyContent: "center",
});
const customStyling = {
  exportModal: {
    position: "absolute",
    top: { xs: "45%", sm: "50%" },
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    borderRadius: 2,
    boxShadow: 24,
    overflowY: "auto",
    maxHeight: "90%",
    border: "none",
  },
};

const LoadingText = styled(Typography)(({ theme }) => ({
  fontSize: "16px",
  color: "#333333E5",
  fontWeight: 400,
  color: "#333333",
  marginBottom: "30px",
  textAlign: "center",
}));

const ContainerBox = styled(Box)(({ theme }) => ({
  width: "282px",
}));

export default {
  ModalContentBox,
  ModalPrmissionSignInBox,
  ContainerBox,
  LoadingText,
  customStyling,
};
