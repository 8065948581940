import React from "react";
import intlMessages from "../../locales/en";
import Styled from "./styles";
import CloseIcon from "@mui/icons-material/Close";
import { Box, Button, IconButton, Modal, Typography } from "@mui/material";

export default function ExportFileModal(props) {
  const { isOpen, onClose, handleExportFile } = props;
  return (
    <Modal open={isOpen}>
      <Styled.ModalBox sx={Styled.customStyling().exportModal}>
        <Styled.ModalHeader>
          <Typography
            id="download-options-modal-title"
            variant="h5"
            component="h5"
            style={{ fontWeight: "bold" }}
          >
            {intlMessages.exportFileLabel}
          </Typography>
          <IconButton
            aria-label="downloadOptionsModal"
            onClick={() => {
              onClose();
            }}
          >
            <CloseIcon color="seondary.light" />
          </IconButton>
        </Styled.ModalHeader>
        <Styled.ModalContentBox>
          <Box
            sx={{
              display: "flex",
              mt: 1.5,
              alignItems: "left",
              flexDirection: "column",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                borderBottom: "1px solid grey",
                pb: "5px",
              }}
            >
              <Styled.ModalDesc>
                {intlMessages.exportFileTypeModalDesc}
              </Styled.ModalDesc>
            </Box>
            <Styled.DownloadContainer onClick={() => handleExportFile("xml")}>
              <Box sx={{ display: "flex", flexDirection: "row" }}>
                <Styled.DownloadImage src="/svgs/download.svg"></Styled.DownloadImage>
                <Styled.DownloadLabel>
                  {intlMessages.exportXMLLabel}
                </Styled.DownloadLabel>
              </Box>
              <Styled.DownloadDesc>
                {intlMessages.exportXMLDesc}
              </Styled.DownloadDesc>
            </Styled.DownloadContainer>
            <Styled.DownloadContainer onClick={() => handleExportFile("text")}>
              <Box sx={{ display: "flex", flexDirection: "row" }}>
                <Styled.DownloadImage src="/svgs/download.svg"></Styled.DownloadImage>
                <Styled.DownloadLabel>
                  {intlMessages.exportAikenLabel}
                </Styled.DownloadLabel>
              </Box>
              <Styled.DownloadDesc>
                {intlMessages.exportAikenDesc}
              </Styled.DownloadDesc>
            </Styled.DownloadContainer>
            <Styled.DownloadContainer onClick={() => handleExportFile("zip")}>
              <Box sx={{ display: "flex", flexDirection: "row" }}>
                <Styled.DownloadImage src="/svgs/download.svg"></Styled.DownloadImage>
                <Styled.DownloadLabel>
                  {intlMessages.exportQTLabel}
                </Styled.DownloadLabel>
              </Box>
              <Styled.DownloadDesc>
                {intlMessages.exportQTDesc}
              </Styled.DownloadDesc>
            </Styled.DownloadContainer>
            <Styled.DownloadContainer onClick={() => handleExportFile("csv")}>
              <Box sx={{ display: "flex", flexDirection: "row" }}>
                <Styled.DownloadImage src="/svgs/download.svg"></Styled.DownloadImage>
                <Styled.DownloadLabel>
                  {intlMessages.exportGoogleSheetLabel}
                </Styled.DownloadLabel>
              </Box>
              <Styled.DownloadDesc>
                {intlMessages.exportGoogleSheetDesc}
              </Styled.DownloadDesc>
            </Styled.DownloadContainer>
          </Box>
        </Styled.ModalContentBox>
        <Styled.ModalActions>
            <Button
              sx={{
                textTransform: "none",
              }}
              variant="contained"
              color="secondary"
              onClick={() => {
                onClose();
              }}
            >
              {intlMessages.doneLabel}
            </Button>
          </Styled.ModalActions>
      </Styled.ModalBox>
    </Modal>
  );
}
