import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Styled from "./styles";
import intlMessages from "../../../locales/en";
import { Events, logEvent, Categories } from "../../../analytics";
import Footer from "../../footer/component";
import aboutImages from "../about-page-images";
import { Avatar } from "@mui/material";
import { getFirstCharacters } from "../../../util/signin-user-data";

function DesktopAboutPage(props) {
  const {
    sliderImage,
    list,
    reviews,
    navigateToMainPage,
    isIpadLandscape,
    isTl,
    navigateToSignUp,
    userState,
    userReviews,
    userReviewCurrentIndex,
    setReviewCurrentIndex,
  } = props;
  return (
    <Box>
      <Styled.sliderBox
        isTl={isTl ? true : false}
        isIpadLandscape={isIpadLandscape ? true : false}
      >
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <Typography
            sx={{
              fontFamily: "Open Sans",
              fontSize: "48px",
              fontWeight: 300,
              color: "white",
              lineHeight: "33.64px",
            }}
          >
            {intlMessages.aboutSliderText1}
          </Typography>
          <Styled.SliderText>{intlMessages.aboutSliderText2}</Styled.SliderText>
          <Styled.sliderText2>
            {intlMessages.aboutPageSliderText2}
            <Styled.sliderText3>
              {intlMessages.aboutPageSliderText3}
            </Styled.sliderText3>
          </Styled.sliderText2>
          <Box sx={{ display: "flex", flexDirection: "row" }}>
            <Styled.SliderBtn
              onClick={() => {
                navigateToSignUp();
              }}
              sx={{
                ":hover": { bgcolor: "#d6511d" },
                display: userState ? "none" : "block",
              }}
            >
              {intlMessages.aboutPageCreateBtnText}
            </Styled.SliderBtn>
          </Box>
        </Box>
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <Box>
            <Styled.SliderImage
              isTl={isTl ? true : false}
              isIpadLandscape={isIpadLandscape ? true : false}
              src={sliderImage}
            ></Styled.SliderImage>
          </Box>
          <Styled.SliderDotBox>{list}</Styled.SliderDotBox>
        </Box>
      </Styled.sliderBox>
      <Styled.FeaturesBox
        isTl={isTl ? true : false}
        isIpadLandscape={isIpadLandscape ? true : false}
      >
        <Styled.featureBoxHeading>
          {intlMessages.aboutPageWhyText}
        </Styled.featureBoxHeading>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-evenly",
            width: "80%",
          }}
        >
          <Box>
            <Styled.FeatureIcon
              src={aboutImages["feature1Img"]}
            ></Styled.FeatureIcon>
            <Styled.FeatureText
              isTl={isTl ? true : false}
              isIpadLandscape={isIpadLandscape ? true : false}
            >
              {intlMessages.aboutFeatureText1}
            </Styled.FeatureText>
            <Styled.FeatureText
              isTl={isTl ? true : false}
              isIpadLandscape={isIpadLandscape ? true : false}
            >
              {intlMessages.aboutFeatureText2}
            </Styled.FeatureText>
          </Box>
          <Box>
            <Styled.FeatureIcon
              src={aboutImages["feature2Img"]}
            ></Styled.FeatureIcon>
            <Styled.FeatureText
              isTl={isTl ? true : false}
              isIpadLandscape={isIpadLandscape ? true : false}
            >
              {intlMessages.aboutFeatureText3}
            </Styled.FeatureText>
            <Styled.FeatureText>
              {intlMessages.aboutFeatureText4}
            </Styled.FeatureText>
          </Box>
          <Box>
            <Styled.FeatureIcon
              src={aboutImages["feature3Img"]}
            ></Styled.FeatureIcon>
            <Styled.FeatureText>
              {intlMessages.aboutFeatureText5}
            </Styled.FeatureText>
            <Styled.FeatureText>
              {intlMessages.aboutFeatureText6}
            </Styled.FeatureText>
          </Box>
        </Box>
      </Styled.FeaturesBox>
      <Styled.ForTeachersBox
        isTl={isTl ? true : false}
        isIpadLandscape={isIpadLandscape ? true : false}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            width: isIpadLandscape && !isTl ? "884px" : "980px",
          }}
        >
          <Styled.DoubleQuotesBlack
            src={aboutImages["blackQuotes"]}
          ></Styled.DoubleQuotesBlack>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              ml: "25px",
            }}
          >
            <Typography
              sx={{
                fontFamily: "Open Sans",
                fontSize: "16px",
                fontWeight: 400,
                lineHeight: "21.79px",
                mt: "15px",
              }}
            >
              {reviews[0].comment}
            </Typography>
            <Typography
              sx={{
                fontFamily: "Open Sans",
                fontSize: "18px",
                fontWeight: 700,
                lineHeight: "21.79px",
                mt: "15px",
              }}
            >
              {reviews[0].name} <span style={{ color: "#3333334D" }}>|</span>{" "}
              <span
                style={{
                  fontSize: "18px",
                  fontWeight: 700,
                  lineHeight: "24.51px",
                  color: "#7DC242",
                }}
              >
                {reviews[0].role}
              </span>
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            border: "1px #00000033 solid",
            width: isIpadLandscape && !isTl ? "884px" : "980px",
            mt: "30px",
          }}
        ></Box>
        <Box sx={{ display: "flex", flexDirection: "row" }}>
          <Styled.TeacherImg
            isTl={isTl ? true : false}
            isIpadLandscape={isIpadLandscape ? true : false}
            src={aboutImages["teacherImg"]}
          ></Styled.TeacherImg>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              width: "570px",
              pt: "35px",
            }}
          >
            <Styled.TeacherLongDesc
              isTl={isTl ? true : false}
              isIpadLandscape={isIpadLandscape ? true : false}
            >
              {intlMessages.aboutPageTeacherBoxText1}
            </Styled.TeacherLongDesc>
            <Styled.TeacherLongDesc
              isTl={isTl ? true : false}
              isIpadLandscape={isIpadLandscape ? true : false}
              sx={{ mt: "20px", width: "95%" }}
            >
              {intlMessages.aboutPageTeacherBoxText2}
            </Styled.TeacherLongDesc>
          </Box>
        </Box>
      </Styled.ForTeachersBox>

      <Styled.ForStudentsBox
        isTl={isTl ? true : false}
        isIpadLandscape={isIpadLandscape ? true : false}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            width: isIpadLandscape && !isTl ? "884px" : "980px",
          }}
        >
          <Styled.DoubleQuotesBlack
            src={aboutImages["blackQuotes"]}
          ></Styled.DoubleQuotesBlack>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              ml: "25px",
            }}
          >
            <Typography
              sx={{
                fontFamily: "Open Sans",
                fontSize: "16px",
                fontWeight: 400,
                lineHeight: "21.79px",
                mt: "15px",
              }}
            >
              {reviews[1].comment}
            </Typography>
            <Typography
              sx={{
                fontFamily: "Open Sans",
                fontSize: "18px",
                fontWeight: 700,
                lineHeight: "21.79px",
                mt: "15px",
              }}
            >
              {reviews[1].name} <span style={{ color: "#3333334D" }}>|</span>{" "}
              <span
                style={{
                  fontSize: "18px",
                  fontWeight: 700,
                  lineHeight: "24.51px",
                  color: "#7DC242",
                }}
              >
                {reviews[1].role}
              </span>
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            border: "1px #00000033 solid",
            width: isIpadLandscape && !isTl ? "884px" : "980px",
            mt: "30px",
            mb: "20px",
          }}
        ></Box>
        <Box sx={{ display: "flex", flexDirection: "row" }}>
          <Box
            sx={{
              width: isIpadLandscape && !isTl ? "474px" : "570px",
              mr: "30px",
            }}
          >
            <Styled.StudentLongDesc
              isTl={isTl ? true : false}
              isIpadLandscape={isIpadLandscape ? true : false}
              sx={{ mb: "30px" }}
            >
              {intlMessages.aboutPageStudentBoxText1}
            </Styled.StudentLongDesc>
            <Styled.StudentLongDesc
              isTl={isTl ? true : false}
              isIpadLandscape={isIpadLandscape ? true : false}
            >
              {intlMessages.aboutPageStudentBoxText2}
            </Styled.StudentLongDesc>
          </Box>
          <Styled.StudentImg
            isTl={isTl ? true : false}
            isIpadLandscape={isIpadLandscape ? true : false}
            src={aboutImages["studentImg"]}
          ></Styled.StudentImg>
        </Box>
      </Styled.ForStudentsBox>

      <Styled.SchoolBox
        isTl={isTl ? true : false}
        isIpadLandscape={isIpadLandscape ? true : false}
      >
        <Styled.SchoolImg
          isTl={isTl ? true : false}
          isIpadLandscape={isIpadLandscape ? true : false}
          src={aboutImages["schoolImg"]}
        ></Styled.SchoolImg>
        <Box>
          <Styled.SchoolDesc
            isTl={isTl ? true : false}
            isIpadLandscape={isIpadLandscape ? true : false}
            sx={{ mb: "20px" }}
          >
            {intlMessages.aboutPageSchoolBoxText1}
          </Styled.SchoolDesc>
          <Styled.SchoolDesc
            isTl={isTl ? true : false}
            isIpadLandscape={isIpadLandscape ? true : false}
          >
            {intlMessages.aboutPageSchoolBoxText2}
          </Styled.SchoolDesc>
        </Box>
      </Styled.SchoolBox>

      <Styled.CreateQuizBox
        isTl={isTl ? true : false}
        isIpadLandscape={isIpadLandscape ? true : false}
      >
        <Styled.GenerateQuizBtn
          onClick={() => {
            logEvent(
              Categories.about_page,
              Events.about_screen_tagline_generate_quiz_button_clicked
            );
            navigateToMainPage();
          }}
          sx={{ ":hover": { bgcolor: "#d6511d" } }}
        >
          {intlMessages.aboutGenerateQuizBtnText}
        </Styled.GenerateQuizBtn>
        <Styled.QuizContentBox
          isTl={isTl ? true : false}
          isIpadLandscape={isIpadLandscape ? true : false}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Styled.QuizContentBoxImage
              src={aboutImages["pasteIcon"]}
            ></Styled.QuizContentBoxImage>
            <Styled.QuizContentBoxText>
              {intlMessages.aboutPageCreateQuizBoxText1}
            </Styled.QuizContentBoxText>
          </Box>
          <Styled.ArrowIcon src={aboutImages["arrowIcon"]}></Styled.ArrowIcon>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Styled.QuizContentBoxImage
              src={aboutImages["selectIcon"]}
            ></Styled.QuizContentBoxImage>
            <Styled.QuizContentBoxText>
              {intlMessages.aboutPageCreateQuizBoxText2}
            </Styled.QuizContentBoxText>
          </Box>
          <Styled.ArrowIcon src={aboutImages["arrowIcon"]}></Styled.ArrowIcon>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Styled.QuizContentBoxImage
              src={aboutImages["exportIcon"]}
            ></Styled.QuizContentBoxImage>
            <Styled.QuizContentBoxText>
              {intlMessages.aboutPageCreateQuizBoxText3}
            </Styled.QuizContentBoxText>
          </Box>
        </Styled.QuizContentBox>
      </Styled.CreateQuizBox>

      <Styled.ReviewBox
        isTl={isTl ? true : false}
        isIpadLandscape={isIpadLandscape ? true : false}
      >
        <Box sx={{ display: "flex", flexDirection: "row" }}>
          <Typography
            sx={{
              fontFamily: "Open Sans",
              fontSize: "24px",
              fontWeight: 400,
              lineHeight: "30px",
            }}
          >
            {intlMessages.aboutReviewLabel1}
          </Typography>
          <Typography
            sx={{
              fontFamily: "Open Sans",
              fontSize: "24px",
              fontWeight: 700,
              lineHeight: "30px",
              mr: 1,
              ml: 1,
            }}
          >
            {intlMessages.aboutReviewLabel2}
          </Typography>
          <Typography
            sx={{
              fontFamily: "Open Sans",
              fontSize: "24px",
              fontWeight: 400,
              lineHeight: "30px",
            }}
          >
            {intlMessages.aboutReviewLabel3}
          </Typography>
        </Box>
        <Box
          sx={{
            mt: "20px",
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Styled.ArrowBtn
            sx={{
              mr: "30px",
              cursor: userReviewCurrentIndex === 0 ? "not-allowed" : "pointer",
            }}
            src={
              userReviewCurrentIndex === 0
                ? aboutImages["greyBackArrow"]
                : aboutImages["blackBackArrow"]
            }
            onClick={() => {
              if (userReviewCurrentIndex > 0)
                setReviewCurrentIndex(userReviewCurrentIndex - 1);
            }}
          ></Styled.ArrowBtn>
          <Styled.Review
            isTl={isTl ? true : false}
            isIpadLandscape={isIpadLandscape ? true : false}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <Avatar
                sx={{
                  bgcolor: "#7D00BA",
                  fontSize: "50px",
                  height: "100px",
                  width: "100px",
                  ml: "-5px",
                }}
              >
                {
                  getFirstCharacters(
                    userReviews[userReviewCurrentIndex]?.name,
                    null
                  ).avatar
                }
              </Avatar>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  width: "300px",
                  marginLeft: "40px",
                }}
              >
                <Typography
                  sx={{
                    fontSize: "16px",
                    fontFamily: "Open Sans",
                    lineHeight: "21px",
                    fontWeight: "700",
                    mb: "10px",
                  }}
                >
                  {userReviews[userReviewCurrentIndex]?.name}
                </Typography>
                <Box sx={{ display: "flex", flexDirection: "row", mb: "10px" }}>
                  {Array.from(
                    {
                      length: Math.round(
                        userReviews[userReviewCurrentIndex]?.rating
                      ),
                    },
                    (_, index) => (
                      <Styled.RatingStar
                        key={index}
                        src={
                          userReviews[userReviewCurrentIndex]?.rating === 4.5 &&
                          index === 4
                            ? aboutImages["halfFilledStar"]
                            : aboutImages["star"]
                        }
                      ></Styled.RatingStar>
                    )
                  )}
                </Box>
                <Typography
                  sx={{
                    fontSize: "16px",
                    fontFamily: "Open Sans",
                    lineHeight: "30px",
                    fontWeight: "400",
                  }}
                >
                  {userReviews[userReviewCurrentIndex]?.comment}
                </Typography>
              </Box>
            </Box>
          </Styled.Review>
          <Styled.ArrowBtn
            sx={{
              ml: "30px",
              cursor: userReviewCurrentIndex === 4 ? "not-allowed" : "pointer",
            }}
            src={
              userReviewCurrentIndex === 4
                ? aboutImages["greyForwardArrow"]
                : aboutImages["blackForwardArrow"]
            }
            onClick={() => {
              if (userReviewCurrentIndex < 4)
                setReviewCurrentIndex(userReviewCurrentIndex + 1);
            }}
          ></Styled.ArrowBtn>
        </Box>
      </Styled.ReviewBox>
      <Footer></Footer>
    </Box>
  );
}

export default DesktopAboutPage;
