import styled from "@mui/system/styled";
import {
  Grid,
  Typography,
  Button,
  Box,
  TextField,
  Checkbox,
  Divider,
} from "@mui/material";

const MainContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-start",
  margin: "0% 3%",
  borderRadius: "10px",
  backgroundColor: "white",
  // height: `${mainBoxHeight}px`,
  overflow: "auto",
  // paddingTop:'7%',
  // alignItems: 'center'
}));

const backButton = styled(Button)(({ theme }) => ({
  color: "white",
  backgroundColor: "#616161",
  width: "84px",
  marginTop: "40px",
  marginLeft: "44px",
  textTransform: "none",
  height: "27px",
  borderRadius: "4px",
  "&:hover": {
    backgroundColor: "#969595",
  },
}));

const formContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
}));

const profileLabel = styled(Typography)(
  ({ theme, isMobile, isMobileLandscape }) => ({
    fontFamily: "Open Sans",
    fontWeight: "700",
    fontSize: "32px",
    lineHeight: "32px",
    textAlign: "center",
    color: "#222222",
    marginTop: isMobile || isMobileLandscape ? "60px" : "70px",
  })
);

const accountBox = styled(Box)(
  ({ theme, isMobile, isMobileLandscape, isIpad }) => ({
    width: isMobile || isMobileLandscape ? "unset" : isIpad ? "600px" : "690px",
    height: isMobile || isMobileLandscape ? "unset" : "379px",
    border: isMobile || isMobileLandscape ? "unset" : "1px solid #D5D5D5",
    borderRadius: "10px",
    marginTop: "35px",
    marginBottom: isMobile || isMobileLandscape ? "25px" : "unset",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  })
);

const infoBox = styled(Box)(
  ({ theme, isMobile, isMobileLandscape, isIpad, height }) => ({
    width: isMobile || isMobileLandscape ? "unset" : isIpad ? "600px" : "690px",
    height:
      isMobile || isMobileLandscape ? "unset" : height ? "350px" : "690px",
    border: isMobile || isMobileLandscape ? "unset" : "1px solid #D5D5D5",
    borderRadius: "10px",
    marginTop: "35px",
    marginBottom: isMobile || isMobileLandscape ? "25px" : "unset",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  })
);

const heading = styled(Typography)(
  ({ theme, isMobile, isMobileLandscape }) => ({
    fontFamily: "Roboto",
    fontWeight: "400",
    fontSize: "24px",
    lineHeight: "12px",
    color: "#7DC242",
    marginBottom: isMobile || isMobileLandscape ? "20px" : "35px",
    width: isMobile ? "250px" : isMobileLandscape ? "410px" : "490px",
  })
);

const nameTextField = styled(TextField)(
  ({ theme, isMobile, isMobileLandscape, provider }) => ({
    background: "#F6F6F6",
    "& .MuiInputBase-root": {
      background: "#F6F6F6",
    },
    "& input::placeholder": {
      color: "#000000",
      opacity: 1,
    },
    "& .MuiFormHelperText-root.Mui-error": {
      position: "absolute",
      top: "100%",
    },
    width: isMobile ? "250px" : isMobileLandscape ? "410px" : "235px",
    display: provider ? "block" : "none",
    borderRadius: "4px",
    "& .MuiInputBase-root": {
      paddingLeft: "20px",
    },
  })
);

const largeTextField = styled(TextField)(
  ({ theme, successMsg, isMobile, isMobileLandscape, isInfoModal }) => ({
    background: "#F6F6F6",
    "& .MuiInputBase-root": {
      background: "#F6F6F6",
    },
    "& input::placeholder": {
      color: "#000000",
      opacity: 1,
    },
    "& .MuiFormHelperText-root.Mui-error": {
      position: "absolute",
      top: "100%",
    },
    width: isMobile ? "250px" : isMobileLandscape ? "410px" : "490px",
    borderRadius: "4px",
    "& .MuiFormHelperText-root": {
      color: successMsg ? "#7DC242" : "red",
    },
    "& .MuiInputBase-root": {
      paddingLeft: isInfoModal ? "unset" : "20px",
      // height: isInfoModal ? "37px" : "",
    },
  })
);

const TextTypography = styled(Typography)(
  ({ theme, isMobile, isMobileLandscape }) => ({
    fontSize: "14px",
    color: "#333",
    //   marginLeft: "15px",
    fontWeight: 400,
    marginBottom: "5px",
    width: isMobile ? "250px" : isMobileLandscape ? "410px" : "490px",
  })
);

const saveBtn = styled(Button)(
  ({ theme, isMobile, isMobileLandscape, isIpad, isUserInfoBtn }) => ({
    width:
      isMobile || isMobileLandscape
        ? isUserInfoBtn
          ? "130px"
          : "193px"
        : isUserInfoBtn
        ? "130px"
        : "213px",
    height: isMobile || isMobileLandscape ? "48px" : "54px",
    color: "white",
    backgroundColor: "#7DC243",
    borderRadius: "4px",
    textTransform: "none",
    marginTop: isMobile || isMobileLandscape ? "15px" : "35px",
    marginBottom: isMobile || isMobileLandscape ? "30px" : "unset",
    display: "block",
    marginRight: isMobile || isMobileLandscape ? "unset" : "auto",
    marginLeft:
      isMobile || isMobileLandscape ? "unset" : isIpad ? "55px" : "99px",
    fontsize: "16px",
    fontWeight: 600,
    "&:hover": {
      backgroundColor: "#89d44a",
    },
  })
);

const passwordBox = styled(Box)(
  ({ theme, isMobile, isMobileLandscape, provider, isIpad }) => ({
    width: isMobile || isMobileLandscape ? "unset" : isIpad ? "600px" : "690px",
    height: isMobile || isMobileLandscape ? "unset" : "468px",
    border: isMobile || isMobileLandscape ? "unset" : "1px solid #D5D5D5",
    marginBottom: isMobile || isMobileLandscape ? "25px" : "unset",
    borderRadius: "10px",
    marginTop: "35px",
    display: provider === "local" ? "flex" : "none",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  })
);

const deleteBox = styled(Box)(
  ({ theme, isMobile, isMobileLandscape, isIpad }) => ({
    width: isMobile || isMobileLandscape ? "unset" : isIpad ? "600px" : "690px",
    height: isMobile || isMobileLandscape ? "unset" : "394px",
    border: isMobile || isMobileLandscape ? "unset" : "1px solid #D5D5D5",
    borderRadius: "10px",
    marginTop: "35px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: "81px",
  })
);

const deleteLongDesc = styled(Typography)(
  ({ theme, isMobile, isMobileLandscape }) => ({
    fontFamily: "Roboto",
    fontWeight: "400",
    fontSize: "15px",
    lineHeight: "24px",
    color: "#000000",
    marginBottom: "30px",
    width: isMobile ? "250px" : isMobileLandscape ? "410px" : "495px",
    '& li': {
      display: 'flex',
      alignItems: 'flex-start',
    },
    '& li::before': {
      content: '"•"',
      marginRight: '10px',
      fontSize: '15px',
    },
  })
);

const deleteBtn = styled(Button)(
  ({ theme, isMobile, isMobileLandscape, isIpad, disableBtn }) => ({
    width: isMobile || isMobileLandscape ? "193px" : "213px",
    height: isMobile || isMobileLandscape ? "48px" : "54px",
    color: "#EC0E0E",
    borderRadius: "4px",
    textTransform: "none",
    border: "1px solid #EC0E0E",
    marginRight: disableBtn
      ? "unset"
      : isMobile || isMobileLandscape
      ? "unset"
      : "auto",
    marginLeft: disableBtn
      ? "unset"
      : isMobile || isMobileLandscape
      ? "unset"
      : isIpad
      ? "55px"
      : "99px",
    fontSize: "16px",
    fontWeight: 600,
    "&:disabled": {
      color: "#999999",
      border: "1px solid #999999",
    },
  })
);

const cancelBtn = styled(Button)(
  ({ theme, isMobile, isMobileLandscape, isIpad }) => ({
    width: isMobile || isMobileLandscape ? "203px" : "223px",
    height: isMobile || isMobileLandscape ? "48px" : "22px",
    color: "#2062BB",
    borderRadius: "0px",
    textTransform: "none",
    border: "none",
    fontSize: "16px",
    fontWeight: 400,
    marginLeft: "10px",
  })
);

const DeleteDisclaimerText = styled(Typography)(
  ({ theme, isMobile, isMobileLandscape }) => ({
    fontFamily: "Open Sans",
    fontWeight: "400",
    fontSize: "12px",
    lineHeight: "18px",
    color: "#777777",
    marginTop: "30px",
    // marginBottom: "30px",
  })
);

const divider = styled(Divider)(
  ({ theme, isMobile, isMobileLandscape, provider }) => ({
    display:
      provider === "local" && (isMobile || isMobileLandscape)
        ? "block"
        : "none",
    width: isMobile ? "250px" : "410px",
    border: "1px #D5D5D5 solid",
  })
);

export default {
  MainContainer,
  backButton,
  formContainer,
  profileLabel,
  accountBox,
  heading,
  nameTextField,
  largeTextField,
  saveBtn,
  passwordBox,
  deleteBox,
  deleteLongDesc,
  deleteBtn,
  DeleteDisclaimerText,
  cancelBtn,
  divider,
  TextTypography,
  infoBox,
};
