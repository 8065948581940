import { Box, Button, Typography } from "@mui/material";
import styled from "@mui/system/styled";

const Container = styled(Box)(({theme,height})=>({
    width:'100%',
    height:height,
    backgroundColor:'#E9E9E9',
    display:'flex',
    flexDirection:'column',
    justifyContent:'space-around',
    alignItems:'flex-start',
    paddingLeft:'20px',
    paddingRight:'20px',
}))

const MobileContainer = styled(Box)(({theme,height,isIpad,isIpadLandscape})=>({
    width:'100%',
    height:height,
    backgroundColor:'#E9E9E9',
    display:'flex',
    flexDirection:'column',
    justifyContent:'space-around',
    alignItems:height===60?'center':'flex-start',
    paddingLeft:'20px',
    paddingRight:'20px',
    position: isIpad || isIpadLandscape?'unset':'absolute',
    bottom: isIpad || isIpadLandscape?'unset':'6vh',
    paddingBottom:height===60?'unset':'20px',
}))

const MobileSentenceBox = styled(Box)(({theme,height})=>({
    display:'flex',
    flexDirection:'row',
    justifyContent:'space-between',
    alignItems:'center',
    width:'100%',
    height:'unset',
    "@media screen and (max-width: 375px) and (max-height:670px) and (orientation: portrait) and  (hover: none) and (pointer: coarse)": {
        marginBottom: height===60?'10px':'unset',
    },
}))

const SentenceLabel = styled(Typography)(({theme})=>({
    fontFamily:'Open Sans',
    fontWeight:400,
    fontSize:'12px',
    lineHeight:'14px',
    opacity:'70%'
}))

const QuizSettingBtn = styled(Button)(({theme})=>({
    height:'14px',
    backgroundColor:'transparent',
    textTransform: "none",
    color:'#2062BB',
    fontFamily:'Open Sans',
    fontWeight:400
}))

export default {
    Container,
    SentenceLabel,
    QuizSettingBtn,
    MobileContainer,
    MobileSentenceBox
};