import {
  checkInternetConnection,
  getBaseUrlOfBrowser,
  extractBaseTokenFromURL,
  isUserSignedIn,
} from "../../api/service";
import api from "../../api/index";

const getInternetConnectionStatus = async () => {
  const connectionStatus = await checkInternetConnection();
  return connectionStatus;
};

const handleErrorMsg = (e) => {
  if (e.response) {
    let resMessage = e?.response?.data?.message;
    if (Array.isArray(resMessage)) {
      resMessage = resMessage[0];
    }
    e.response.isConnected = false;
    e.response.data.message = resMessage;
    return e?.response;
  }
  return e;
};

async function exportToGoogle(
  questionAndAnswers,
  fileType,
  quiz_id,
  quiz_hash,
  prety,
  isQuiz = true,
  googleTitle,
  feed_back,
  email,
  exportId
) {
  try {
    const payload = {
      ques_ans_pairs: questionAndAnswers,
      file_type: fileType,
      quiz_id: quiz_id,
      quiz_hash: quiz_hash,
      prety: prety,
      googleTitle,
      isQuiz: true,
      feed_back: feed_back,
      file_name: googleTitle,
      email: email,
      exportId: exportId,
    };
    const connectionStatus = await checkInternetConnection();
    const isInternetConnected = connectionStatus.isConnected;
    if (!isInternetConnected) return connectionStatus;
    const res = await api.post(`/quiz/export-google`, payload);
    return res;
  } catch (e) {
    return handleErrorMsg(e);
  }
}

export {
  getInternetConnectionStatus,
  getBaseUrlOfBrowser,
  exportToGoogle,
  extractBaseTokenFromURL,
  isUserSignedIn,
};
