import { useState, useEffect, useRef } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { CircularProgress, Divider, IconButton, Snackbar } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import MuiAlert from "@mui/material/Alert";
import Styled from "./styles";
import intlMessages from "../../locales/en";
import { Categories, Events, logEvent } from "../../analytics";
import { useDispatch, useSelector } from "react-redux";
import { GOOGLE_LOGIN_URL, USER_STATE } from "../../settings";
import CreateAndGetTokenminiWindow from "../../util/mini_window_handler";
import {
  setAuthToken,
  setIsSignedIn,
  setUserData,
  setUserState,
} from "../../redux/slices/logInSlice";
import { decodeToken } from "../../common/sign_in_service";

const GooglePermissionModal = (props) => {
  const { isSignedIn } = useSelector((state) => state.logIn);
  const { userData } = useSelector((state) => state.logIn);
  const {
    openGooglePermissionForm,
    signInparams,
    queryParams,
    texts,
    closeGooglePermissionModal,
    isMobile,
  } = props;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [isSaving, setIsSaving] = useState(false);
  const [permissionError, setpermissionError] = useState(true);
  const [sharedOnGoogle, setsharedOnGoogle] = useState(false);
  const [miniWindow, setMiniWindow] = useState(null);
  const miniWindowRef = useRef(null);
  const [paragraphText, setParagraphText] = useState({
    header: intlMessages.googleFormsLabelCapital,
    text: intlMessages.googleFormsTitlePermission,
  });
  const [snackBarstate, setSnackBar] = useState({
    openSnackBar: false,
    vertical: "top",
    horizontal: "right",
    type: "warning",
    message: "",
    title: null,
    timeout: 3000,
  });
  const { vertical, horizontal, openSnackBar, title, type, message, timeout } =
    snackBarstate;

  useEffect(() => {
    setParagraphText({
      ...paragraphText,
      header: texts.header || paragraphText.header,
      text: texts.text || paragraphText.text,
    });
  }, [texts]);
  const openMiniWindow = async () => {
    setIsSaving(true);
    let url = GOOGLE_LOGIN_URL;
    if (isSignedIn) {
      url = `${url}?userId=${userData?.id}&permissionType=${queryParams}`;
      let eventType = Events.google_forms_login_modal_for_loggedin_user;
      if (queryParams == "drive") {
        eventType = Events.google_drive_login_modal_for_loggedin_user;
      }
      logEvent(Categories.user_management, eventType);
    }
    // console.log("Google_login_url", url);
    let resp = await CreateAndGetTokenminiWindow(url)
      .then(async (tokenData) => {
        if (tokenData.isFirstTimeUser) {
          navigate(`?v=1`);
        }
        const token = tokenData.token;
        let eventType = Events.user_management_login_google_for_forms;
        if (queryParams == "drive") {
          eventType = Events.user_management_login_google_for_drive;
        }
        logEvent(Categories.user_management, eventType, "google-forms");
        dispatch(setAuthToken(token));
        const decodedData = await decodeToken(token);
        dispatch(setUserData(decodedData));
        dispatch(setIsSignedIn(true));
        setIsSaving(false);
        setpermissionError(false);
        dispatch(setUserState(USER_STATE.SIGNED_IN_USER));
        closeGooglePermissionModal(decodedData);
      })
      .catch((error) => {
        if (error === "allowpopup") {
          showWarningMessage(
            intlMessages.popUpError,
            intlMessages.popUpErrorTitle
          );
          // return;
        }
        if (error === "windowError") {
          showWarningMessage(
            intlMessages.googleFormsUnauthorizedError,
            intlMessages.googleFormsUnauthorizedTitie
          );
          // return;
        }
        // // console.log(error);
        setIsSaving(false);
      });
  };

  const showWarningMessage = (msgText, title = null) => {
    return setSnackBar({
      ...snackBarstate,
      type: "warning",
      message: msgText,
      openSnackBar: true,
      title: title,
      timeout: 7000,
    });
  };

  const handleCloseSnackBar = () => {
    // console.log("onclosed call");
    setSnackBar({ ...snackBarstate, openSnackBar: false });
  };

  const renderSnackBar = () => {
    return (
      <Snackbar
        open={openSnackBar}
        anchorOrigin={{ vertical, horizontal }}
        autoHideDuration={snackBarstate.timeout}
        onClose={handleCloseSnackBar}
        key={vertical + horizontal}
      >
        <MuiAlert
          elevation={6}
          variant="filled"
          sx={{ color: "white" }}
          onClose={handleCloseSnackBar}
          severity={snackBarstate.type}
        >
          {snackBarstate.message}
        </MuiAlert>
      </Snackbar>
    );
  };

  return (
    <>
      {renderSnackBar()}
      <Modal
        open={openGooglePermissionForm}
        aria-labelledby="review-modal"
        aria-describedby="review-description"
      >
        <Styled.ModalPrmissionSignInBox sx={Styled.customStyling.exportModal}>
          <Styled.ModalHeader>
            <Typography
              id="review-modal-title"
              variant="h5"
              component="h5"
              style={{ fontWeight: "bold" }}
            >
              {paragraphText.header}
            </Typography>
            <IconButton
              aria-label="closeReviewModal"
              onClick={() => {
                closeGooglePermissionModal();
              }}
            >
              <CloseIcon color="seondary.light" />
            </IconButton>
          </Styled.ModalHeader>
          <Styled.ModalContentBox>
            <Typography
              color="secondary.dark"
              variant="body1"
              mt={2}
              mb={3}
              style={{
                fontSize: "18px",
                display: !sharedOnGoogle ? "" : "none",
                color: "#231F20",
              }}
            >
              {`${paragraphText.text} `}
            </Typography>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                opacity: isSaving ? 0.5 : 1,
                pointerEvents: isSaving ? "none" : "auto",
              }}
              onClick={openMiniWindow}
            >
              <Styled.ContinueGoogleBtn isMobile={isMobile}>
                {isSaving && (
                  <CircularProgress
                    color="inherit"
                    sx={{ marginRight: "10px" }}
                    size={16}
                  />
                )}
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    mr: isMobile ? 2 : "unset",
                    ml: isMobile ? 2 : "unset",
                  }}
                >
                  <img
                    style={{ height: 24, marginRight: "3px" }}
                    src={"/svgs/Google.svg"}
                  />
                  <Typography
                    variant="body1"
                    style={{
                      fontSize: "16px",
                      color: "#000000",
                      marginLeft: "15px",
                    }}
                  >
                    {intlMessages.googleFormQuizSIgnInBtnText}
                  </Typography>
                </Box>
              </Styled.ContinueGoogleBtn>
            </Box>
          </Styled.ModalContentBox>
        </Styled.ModalPrmissionSignInBox>
      </Modal>
    </>
  );
};

export default GooglePermissionModal;
