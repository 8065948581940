import { useState, useEffect } from "react";
import Modal from "@mui/material/Modal";
import Rating from "@mui/material/Rating";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { IconButton, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Styled from "./styles";
import {
  validateInput,
  validateEmail,
  getInternetConnectionStatus,
  setUserData,
  getUserData,
  updateUserData,
} from "./service";
import { getWordCount, COMMENT_MAX_WORD_COUNT } from "../quiz/service";
import intlMessages from "../../locales/en";
import { useSelector } from "react-redux";
import { feedbackOptions, feedbackResponse } from "../../api/service";
import { useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";

export default function Review(props) {
  const {
    openConnectionErrorCallback,
    reviewSubmissionCallback,
    isOpen,
    closeReviewModalCallback,
  } = props;
  const { isSignedIn } = useSelector((state) => state.logIn);
  const { userData } = useSelector((state) => state.logIn);
  const theme = useTheme();
  const [openReviewModal, setOpenReviewModal] = useState(isOpen);
  const [review, setReview] = useState({
    rating: 0,
    email: "",
    comment: "",
  });
  const [errors, setErrors] = useState({
    reviewEmailError: null,
    reviewRatingError: null,
    reviewCommentError: null,
  });
  const [rating, setRating] = useState({
    ratingText: "",
    ratingQuestion: "",
  });
  const { reviewEmailError, reviewRatingError } = errors;
  const [feedbackTags, setFeedbackTags] = useState([]);
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"), {
    noSsr: true,
  });
  const isMobileLandscape = useMediaQuery(
    `${theme.breakpoints.down(
      "md"
    )}  and (orientation: landscape) and (hover: none) and (pointer: coarse)`,
    {
      noSsr: true,
    }
  );

  const isIpad = useMediaQuery(
    window.navigator.userAgent.includes("OneScreen_TL7")
      ? "(min-width:620px) and (max-width:960px) and (orientation: landscape) "
      : "(min-width:620px) and (max-width:840px) and (orientation: portrait) and  (hover: none) and (pointer: coarse)",
    {
      noSsr: true,
    }
  );
  const isIpadLandscape = useMediaQuery(
    "(min-width: 800px) and (max-width: 1400px) and (orientation: landscape) and  (hover: none) and (pointer: coarse)",
    {
      noSsr: true,
    }
  );
  const isTl7 = navigator?.userAgent?.indexOf("OneScreen_TL7") >= 0;

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (openReviewModal && event.ctrlKey && event.key === "z") {
        event.preventDefault();
      }
    };
    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [openReviewModal]);
  useEffect(() => {
    setIsSignedInOrNot();
    const userDataOld = getUserData();
    if (userDataOld) {
      const { email } = userDataOld;
      const { rating } = userDataOld;
      setReview({
        email: email ? email : "",
        rating: rating ? rating : 0,
      });
      handleRatingText(rating);
    }
  }, []);

  const closeReviewModal = () => {
    closeReviewModalCallback();
    setReview({
      rating: 0,
      email: "",
      comment: "",
    });
    setErrors({
      ...errors,
      reviewEmailError: null,
      reviewRatingError: null,
    });
  };

  const handleRatingText = (rating) => {
    if (rating > 0 && rating <= 1.5) {
      return setRating({
        ratingText: feedbackResponse[0].ratingText,
        ratingQuestion: feedbackResponse[0].ratingQuestion,
      });
    }
    if (rating > 1.5 && rating <= 2.5) {
      return setRating({
        ratingText: feedbackResponse[1].ratingText,
        ratingQuestion: feedbackResponse[1].ratingQuestion,
      });
    }
    if (rating > 2.5 && rating <= 3.5) {
      return setRating({
        ratingText: feedbackResponse[2].ratingText,
        ratingQuestion: feedbackResponse[2].ratingQuestion,
      });
    }
    if (rating > 3.5 && rating <= 4.5) {
      return setRating({
        ratingText: feedbackResponse[3].ratingText,
        ratingQuestion: feedbackResponse[3].ratingQuestion,
      });
    }
    if (rating === 5) {
      return setRating({
        ratingText: feedbackResponse[4].ratingText,
        ratingQuestion: feedbackResponse[4].ratingQuestion,
      });
    }
    return setRating({
      ratingText: "",
      ratingQuestion: "",
    });
  };

  const onChangeReviewRating = (e, newValue) => {
    const inputText = newValue;
    const { reviewRatingError } = errors;
    if (
      parseFloat(inputText) !== (0 || null || undefined) &&
      reviewRatingError
    ) {
      setErrors({ ...errors, reviewRatingError: null });
    }
    setReview({ ...review, rating: inputText });
    handleRatingText(inputText);
  };

  const onChangeReviewEmail = (e) => {
    const inputText = validateInput(e.target.value);
    const { reviewEmailError } = errors;
    if (
      (inputText.length > 0 &&
        reviewEmailError === intlMessages.emptyEmailError) ||
      (validateEmail(inputText) &&
        reviewEmailError === intlMessages.incorrectEmailFormat)
    ) {
      setErrors({ ...errors, reviewEmailError: null });
    }
    setReview({ ...review, email: inputText });
  };

  const onChangeReviewComment = (e) => {
    const inputText = validateInput(e.target.value);
    if (
      errors.reviewCommentError ===
        intlMessages.maxCommentWordCount.replace(
          "{0}",
          COMMENT_MAX_WORD_COUNT
        ) &&
      getWordCount(inputText) <= COMMENT_MAX_WORD_COUNT
    ) {
      setErrors({ ...errors, reviewCommentError: null });
    }
    setReview({ ...review, comment: inputText });
  };

  const callbackUpdateUserData = (uEmail, uRating) => {
    const uData = getUserData();
    const userDataOld = {
      email: uEmail,
      rating: uRating,
    };
    if (uData) {
      updateUserData(userDataOld);
    } else {
      setUserData(userDataOld);
    }
  };

  // useEffect(()=>{
  //   const setIsSignedInOrNot = async () => {
  //     if(isSignedIn){
  //       const userDataOld = getUserData();
  //         const { rating } = userDataOld;
  //         setReview({ ...review, email: userData.username, rating: rating ? rating : 0 });
  //     }
  //   }
  //   setIsSignedInOrNot();
  // },[]);

  const setIsSignedInOrNot = async () => {
    if (isSignedIn) {
      const userDataOld = getUserData();
      let rating = 0;
      {
        if (userDataOld) {
          rating = userDataOld.rating;
        }
      }
      setReview({
        ...review,
        email: userData.username,
        rating: rating ? rating : 0,
      });
      callbackUpdateUserData(userData.username, rating || 0);
    }
  };

  const onSubmitReview = async () => {
    const { email, rating, comment } = review;
    if (!rating) {
      return setErrors({
        ...errors,
        reviewRatingError: intlMessages.emptyRatingError,
      });
    }
    if (!rating) {
      return setErrors({
        ...errors,
        reviewRatingError: intlMessages.emptyRatingError,
      });
    }
    if (getWordCount(comment) > COMMENT_MAX_WORD_COUNT) {
      return setErrors({
        ...errors,
        reviewCommentError: intlMessages.maxCommentWordCount.replace(
          "{0}",
          COMMENT_MAX_WORD_COUNT
        ),
      });
    }
    const internetConnection = await getInternetConnectionStatus();
    if (!internetConnection?.isConnected) {
      return openConnectionErrorCallback();
    }
    const newReview = {
      email,
      rating,
      comments: comment,
      tags: feedbackTags.toString(),
    };
    closeReviewModal();
    reviewSubmissionCallback(newReview);
    callbackUpdateUserData(email, rating);
    setReview({
      rating: 0,
      email: "",
      comment: "",
    });
  };

  const handleOptionClick = (event, element) => {
    if (event.target && event.target.style.color !== "rgb(125, 194, 66)") {
      event.target.style.border = "1px #7DC242 solid";
      event.target.style.color = "#7DC242";
      setFeedbackTags([...feedbackTags, element]);
    } else {
      event.target.style.border = "1px solid #B0B0B0";
      event.target.style.color = "#333333";
      const updatedTags = feedbackTags.filter((item) => item !== element);
      setFeedbackTags(updatedTags);
    }
  };

  return (
    <Modal
      open={openReviewModal}
      aria-labelledby="review-modal"
      aria-describedby="review-description"
    >
      <Styled.ModalBox sx={Styled.customStyling.exportModal}>
        <Styled.ModalHeader>
          <Styled.headerText id="review-modal-title">
            {intlMessages.reviewHeader}
          </Styled.headerText>
          <IconButton
            aria-label="closeReviewModal"
            onClick={() => {
              closeReviewModal();
            }}
          >
            <CloseIcon color="seondary.light" />
          </IconButton>
        </Styled.ModalHeader>
        <Styled.ModalContentBox isIpad={isIpad?true:false} isIpadLandscape={isIpadLandscape?true:false} isMobile={isMobile?true:false} isMobileLandscape={isMobileLandscape?true:false} isTl7={isTl7?true:false} rating={review.rating!==0 && review.rating!==null?true:false}>
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Styled.ratingText>
              {rating.ratingText}
            </Styled.ratingText>
            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                textAlign: "flex-start",
              }}
            >
              <Rating
                sx={{ fontSize: 30 }}
                name="half-rating"
                value={review.rating}
                onChange={(e, newValue) => onChangeReviewRating(e, newValue)}
                precision={0.5}
              />
              <Styled.ErrorText variant="p">
                {reviewRatingError}
              </Styled.ErrorText>
            </Box>
          </Box>
          <Box sx={{ display: review.rating ? "block" : "none" }}>
            <Styled.questionText>{rating.ratingQuestion}</Styled.questionText>
            <Styled.optionBox>
              {feedbackOptions.map((element, index) => (
                <Styled.optionBtn
                  onClick={(event) => handleOptionClick(event, element)}
                  key={`review-${index}`}
                >
                  {element}
                </Styled.optionBtn>
              ))}
            </Styled.optionBox>
          </Box>
          <Box mt={2} sx={{ display: "flex", flexDirection: "column" }}>
            <Styled.ModalTextField
              value={review.email}
              onChange={onChangeReviewEmail}
              placeholder={`${intlMessages.emailLabel} *`}
              type="email"
              required
              helperText={reviewEmailError}
              error={reviewEmailError ? true : false}
              disabled={isSignedIn}
              InputProps={{
                readOnly: isSignedIn ? true : false,
              }}
              sx={{ display: "none" }}
            />
            <Styled.ModalTextField
              value={review.comment}
              onChange={onChangeReviewComment}
              placeholder={intlMessages.commentPlaceholder}
              type="text"
              multiline
              rows={3}
              helperText={errors.reviewCommentError}
              error={errors.reviewCommentError ? true : false}
              sx={{ mt: 1, "& textarea::placeholder": {
                color: "black",
                opacity: 1, 
              }, }}
            />
          </Box>
        </Styled.ModalContentBox>
        <Styled.ModalActions>
          <Styled.CreateQuizButton
            variant="contained"
            color="secondary"
            startIcon={<CloseIcon sx={{ display: "none" }} />}
            onClick={onSubmitReview}
          >
            {intlMessages.continueLabel}
          </Styled.CreateQuizButton>
          <Button
            sx={{
              textTransform: "none",
              marginLeft: 1,
            }}
            variant="outlined"
            color="secondary"
            onClick={() => closeReviewModalCallback()}
          >
            {intlMessages.skipLabel}
          </Button>
        </Styled.ModalActions>
      </Styled.ModalBox>
    </Modal>
  );
}
