import { useEffect, useState } from "react";
import intlMessages from "../../locales/en";
import { IconButton, Modal, Grid, Box } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Styled from "./styles";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  FREE_PLAN_SUBSCRIPTION_COST,
  USER_STATE,
} from "../../settings";
import ClearIcon from "@mui/icons-material/Clear";
import DoneIcon from "@mui/icons-material/Done";
import {
  setOpenPaymentModal,
  setOpenPricingModal,
  setOpenSubscriptionModal,
} from "../../redux/slices/featuresSlice";
import {
  setDuration,
  setIsCardBtnClicked,
  setIsScreenPanelBtnClicked,
  setPlan,
  setPrice,
} from "../../redux/slices/paymentSlice";
import DowngradModal from "../../common/downgrade-modal/component";
import { cancelPlanDowngrade } from "../subscription-modal/service";
import ConnectionModal from "../connection-modal/component";
import TrialInfoModal from "../../common/trial-info-modal/component";
import { Events, logEvent, Categories } from "../../analytics";
import { setStripeStatus, setUserState } from "../../redux/slices/logInSlice";
import { downgradSubscription } from "../../common/downgrade-modal/service";
import {
  setCanCaptureImage,
  setCanCopyToClipboard,
  setCanDeleteQuiz,
  setCanEditQuiz,
  setCanExportAikenFile,
  setCanExportGoogleSheet,
  setCanExportPdfFile,
  setCanExportQT12File,
  setCanExportToGoogleForm,
  setCanExportTxtFile,
  setCanExportXMLFile,
  setCanExtractDataFromUrl,
  setCanImportFromGoogleDrive,
  setCanImportSampleText,
  setCanImportTextFromSystem,
  setCanManageProfile,
  setCanSaveQuiz,
  setCanShareQuiz,
  setCanUploadExcelSheet,
  setCanUploadGoogleDoc,
  setCanUploadGoogleSheet,
  setCanUploadGoogleSlide,
  setCanUploadImage,
  setCanUploadPdf,
  setCanUploadPowerPointSlide,
  setCanUploadWordDoc,
  setDifficultyLevel,
  setGuruSupport,
  setQuestionCount,
  setQuizContentLimit,
  setQuizGenerationCount,
  setQuizGenerationType,
  setTrialDays,
} from "../../redux/slices/featuresSlice";
import { setQuizCount, setQuizDifficultyLevel, setQuizType } from "../../redux/slices/quizSlice";
import { initAdds } from "../../adds_conversion";
export default function PricingModal(props) {
  const {
    open,
    onClose,
    isMobile,
    isMobileLandscape,
    isIpad,
    isIpadLandscape,
    isTl,
  } = props;
  const { userData, userState,stripeStatus,rechargeTries } = useSelector((state) => state.logIn);
  const { duration,isCardBtnClicked,basicPlanPrice,proMonthlyPlanPrice,proYearlyPlanPrice } = useSelector((state) => state.payment);
  const { trialDays } = useSelector((state) => state.feature);
  const navigate = useNavigate();
  const [monthSelected, setIsMonthSelected] = useState(
    duration === "monthly" ? true : false
  );
  const [showFeatures, setIsShowFeatures] = useState(false);
  const [downgradeModalState, setDowngradeModalStat] = useState(false);
  const [openConnectionModal, setIsOpenConnectionModal] = useState(false);
  const [openTrialModal, setOpenTrialModal] = useState(false);
  const [trialModalText, setTrialModalText] = useState({
    heading: "",
    desc: "",
  });
  const [tabSelected, setTabSelected] = useState(false);
  const dispatch = useDispatch();

  useEffect(()=>{
    if(userData===null)
    initAdds()
  },[])

  const handleOnClose = () => {
    logEvent(Categories.pricing_modal, Events.pricing_modal_cross_icon_clicked);
    onClose(false);
    if(isCardBtnClicked===true){
      dispatch(setOpenSubscriptionModal(true))
      dispatch(setIsCardBtnClicked(false))
    }
  };

  const yearClickedHandler = () => {
    logEvent(
      Categories.pricing_modal,
      Events.pricing_modal_yearly_tab_btn_selected
    );
    dispatch(setDuration("yearly"));
    setIsMonthSelected(false);
  };

  const monthClickedHandler = () => {
    logEvent(
      Categories.pricing_modal,
      Events.pricing_modal_monthly_tab_btn_selected
    );
    dispatch(setDuration("monthly"));
    setIsMonthSelected(true);
  };
  const handleDowngradeBtnClick = async () => {
    const res = await downgradSubscription(
      userData?.pricePlan,
      userData?.username,
      null,
      true
    );
    if (res?.data?.statusCode === 0) {
      openConnectionModal(true);
      return;
    }
    if (res?.status === 201 || res?.status === 409
    ) {
      if (!!res?.data?.data) {
        dispatch(setTrialDays(res?.data?.data?.trialDays));
        dispatch(setQuizGenerationCount(res?.data?.data?.quizGenerationCount));
        dispatch(setQuizContentLimit(res?.data?.data?.quizContentLimit));
        dispatch(setQuestionCount(res?.data?.data?.questionCount));
        dispatch(setQuizGenerationType(res?.data?.data?.quizType));
        dispatch(setDifficultyLevel(res?.data?.data?.quizDifficultyLevel));
        dispatch(setCanImportSampleText(res?.data?.data?.canImportSampleText));
        dispatch(
          setCanImportTextFromSystem(res?.data?.data?.canImportTextFromSystem)
        );
        dispatch(
          setCanImportFromGoogleDrive(res?.data?.data?.canImportFromGoogleDrive)
        );
        dispatch(setCanUploadPdf(res?.data?.data?.canUploadPDF));
        dispatch(
          setCanUploadGoogleSheet(res?.data?.data?.canUploadGoogleSheet)
        );
        dispatch(setCanUploadExcelSheet(res?.data?.data?.canUploadExcelSheet));
        dispatch(
          setCanUploadPowerPointSlide(res?.data?.data?.canUploadPowerPointSlide)
        );
        dispatch(
          setCanUploadGoogleSlide(res?.data?.data?.canUploadGoogleSlide)
        );
        dispatch(setCanUploadWordDoc(res?.data?.data?.canUploadWordDoc));
        dispatch(setCanUploadGoogleDoc(res?.data?.data?.canUploadGoogleDoc));
        dispatch(setCanUploadImage(res?.data?.data?.canUploadImage));
        dispatch(setCanCaptureImage(res?.data?.data?.canCaptureImage));
        dispatch(
          setCanExtractDataFromUrl(res?.data?.data?.canExtractDataFromUrl)
        );
        dispatch(setCanSaveQuiz(res?.data?.data?.canSaveQuiz));
        dispatch(setCanEditQuiz(res?.data?.data?.canEditQuiz));
        dispatch(setCanDeleteQuiz(res?.data?.data?.canDeleteQuiz));
        dispatch(setCanShareQuiz(res?.data?.data?.canShareQuiz));
        dispatch(setCanManageProfile(res?.data?.data?.canManageProfile));
        dispatch(setGuruSupport(res?.data?.data?.guruSupport));
        dispatch(setCanExportPdfFile(res?.data?.data?.canExportPdfFile));
        dispatch(setCanExportTxtFile(res?.data?.data?.canExportTxtFile));
        dispatch(setCanExportXMLFile(res?.data?.data?.canExportXMLFile));
        dispatch(setCanExportAikenFile(res?.data?.data?.canExportAikenFile));
        dispatch(setCanExportQT12File(res?.data?.data?.canExportQT12File));
        dispatch(
          setCanExportGoogleSheet(res?.data?.data?.canExportGoogleSheet)
        );
        dispatch(
          setCanExportToGoogleForm(res?.data?.data?.canExportToGoogleForm)
        );
        dispatch(setCanCopyToClipboard(res?.data?.data?.canCopyToClipboard));
        dispatch(setQuizType("Multiple choice"))
        dispatch(setQuizCount(5))
        dispatch(setQuizDifficultyLevel('Medium'))
        dispatch(setUserState(USER_STATE.SIGNED_IN_USER));
      }
      handleOnClose();
      return;
    }
  };

  const handleProBtnClicked = () => {
    dispatch(setOpenPricingModal(false));
    if (
      userData?.subscriptionPlan === "Basic" ||
      userState === USER_STATE.EXPIRED_USER
    ) {
      if (monthSelected === false) {
        dispatch(setPrice(proYearlyPlanPrice));
        dispatch(setDuration("yearly"));
      } else {
        dispatch(setPrice(proMonthlyPlanPrice));
        dispatch(setDuration("monthly"));
      }
      dispatch(setPlan("Pro"));
      dispatch(setIsScreenPanelBtnClicked(false))
      dispatch(setOpenPaymentModal(true));
      logEvent(
        Categories.pricing_modal,
        Events.select_payment_method_modal_opened
      );
    } else if (userData?.subscriptionPlan === "Pro") {
      logEvent(
        Categories.pricing_modal,
        Events.pricing_modal_pro_plan_current_plan_btn_clicked
      );
    } else {
      logEvent(
        Categories.pricing_modal,
        Events.pricing_modal_pro_plan_free_btn_clicked
      );
      navigate("/signup");
    }
  };

  const handleBasicBtnClicked = () => {
    if (userData === null) {
      logEvent(
        Categories.pricing_modal,
        Events.pricing_modal_basic_plan_free_btn_clicked
      );
      dispatch(setOpenPricingModal(false));
      navigate("/signup");
      return;
    }
    if(stripeStatus===-2 || (rechargeTries >0 && rechargeTries<= 3) ||(userState === USER_STATE.EXPIRED_USER && (stripeStatus === 13|| stripeStatus===0 || stripeStatus=== 12))){
      logEvent(
        Categories.pricing_modal,
        Events.pricing_modal_basic_plan_free_btn_clicked
      );
      handleDowngradeBtnClick()
      return
    }
    dispatch(setPlan("Basic"));
    dispatch(setPrice(FREE_PLAN_SUBSCRIPTION_COST));
    if (monthSelected === false) dispatch(setDuration("yearly"));
    else dispatch(setDuration("monthly"));
    if (stripeStatus === 13) {
      logEvent(
        Categories.pricing_modal,
        Events.pricing_and_subscription_modal_cancel_downgrade_btn_clicked
      );
      return cancelDowngrade();
    }
    if (userData?.subscriptionPlan === "Pro") {
      logEvent(
        Categories.pricing_modal,
        Events.pricing_and_subscription_modal_downgrade_btn_clicked
      );
      return setDowngradeModalStat(true);
    }
    logEvent(
      Categories.pricing_modal,
      Events.pricing_modal_basic_plan_current_plan_btn_clicked
    );
  };

  const toggleDowngradeModalState = (flag) => {
    setDowngradeModalStat(flag);
  };

  const cancelDowngrade = async () => {
    const res = await cancelPlanDowngrade();
    if (res?.data?.statusCode === 0) {
      setIsOpenConnectionModal(true);
      return;
    }
    if (
      res?.status === 201 &&
      res?.data.message === "subscription resumed successfully"
    ) {
      setTrialModalText({
        desc: intlMessages.cancelDowngradeRequestMessage,
      });
      setOpenTrialModal(true);
      dispatch(setStripeStatus(1));
      return;
    }
  };

  const calculatePercentage = (originalPrice,discountedPrice)=>{
    originalPrice = originalPrice*12
    discountedPrice = discountedPrice*12
    return (((originalPrice-discountedPrice)/originalPrice)*100).toFixed(2)
  }

  const customStyling = Styled.customStyling();

  return (
    <>
      <Modal open={open}>
        <Styled.ModalBox sx={customStyling.exportModal}>
          <Styled.ModalHeader>
            <IconButton
              sx={{ position: "sticky", top: 0, zIndex: 1 }}
              aria-label="closeWarningParagraphModal"
              onClick={handleOnClose}
            >
              <CloseIcon color="seondary.light" />
            </IconButton>
          </Styled.ModalHeader>
          <Styled.ModalContentBox>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Styled.StyledTrialHeading
                isMobile={isMobile ? true : false}
                isMobileLandscape={isMobileLandscape ? true : false}
                isIpad={isIpad ? true : false}
                isIpadLandscape={isIpadLandscape ? true : false}
                sx={{ display: userData == null ? "block" : "none" }}
              >
                {intlMessages.pricingTabtrialDurationText.replace(
                  "#",
                  userData?.trialDays === undefined
                    ? trialDays
                    : userData?.trialDays
                )}
              </Styled.StyledTrialHeading>
              <Styled.StyledTrialDesc
                isMobile={isMobile ? true : false}
                isMobileLandscape={isMobileLandscape ? true : false}
                isIpad={isIpad ? true : false}
                isIpadLandscape={isIpadLandscape ? true : false}
                sx={{ display: userData == null ? "block" : "none" }}
              >
                {intlMessages.pricingTabTrialDesc.replace(
                  "#",
                  userData?.trialDays === undefined
                    ? trialDays
                    : userData?.trialDays
                )}
              </Styled.StyledTrialDesc>
              <Styled.TrialBtn
                isMobile={isMobile ? true : false}
                isMobileLandscape={isMobileLandscape ? true : false}
                isIpad={isIpad ? true : false}
                isIpadLandscape={isIpadLandscape ? true : false}
                sx={{ display: userData == null ? "block" : "none" }}
                onClick={() => {
                  logEvent(
                    Categories.pricing_modal,
                    Events.pricing_modal_start_trial_btn_clicked
                  );
                  navigate("/signup");
                  onClose();
                }}
              >
                {intlMessages.pricingTabStartFreeBtnText}
              </Styled.TrialBtn>
              <Styled.StyledDivider
                isMobile={isMobile ? true : false}
                isMobileLandscape={isMobileLandscape ? true : false}
                isIpad={isIpad ? true : false}
                isIpadLandscape={isIpadLandscape ? true : false}
                sx={{ display: userData == null ? "block" : "none" }}
              ></Styled.StyledDivider>
              <Styled.StyledTrialHeading
                isMobile={isMobile ? true : false}
                isMobileLandscape={isMobileLandscape ? true : false}
                isIpad={isIpad ? true : false}
                isIpadLandscape={isIpadLandscape ? true : false}
              >
                {intlMessages.pricingTabPlanHeading}
              </Styled.StyledTrialHeading>
              <Styled.StyledBtnContainer
                isMobile={isMobile ? true : false}
                isMobileLandscape={isMobileLandscape ? true : false}
                isIpad={isIpad ? true : false}
                isIpadLandscape={isIpadLandscape ? true : false}
              >
                <Styled.StyledSavingText
                  isMobile={isMobile ? true : false}
                  isMobileLandscape={isMobileLandscape ? true : false}
                  isIpad={isIpad ? true : false}
                  isIpadLandscape={isIpadLandscape ? true : false}
                >
                  {intlMessages.pricingTabSavingText.replace("#",calculatePercentage(proMonthlyPlanPrice,(parseInt(proYearlyPlanPrice)/12).toFixed(2)))}
                </Styled.StyledSavingText>
                <Styled.StyledDurationContainer
                  isMobile={isMobile ? true : false}
                  isMobileLandscape={isMobileLandscape ? true : false}
                  isIpad={isIpad ? true : false}
                  isIpadLandscape={isIpadLandscape ? true : false}
                  proTabSelected = {tabSelected === true}
                >
                  <Styled.StyledYearBtn
                    isMonthSelected={monthSelected ? true : false}
                    onClick={yearClickedHandler}
                  >
                    {intlMessages.pricingTabYearlyBtnText}
                  </Styled.StyledYearBtn>
                  <Styled.StyledMonthBtn
                    isMonthSelected={monthSelected ? true : false}
                    onClick={monthClickedHandler}
                  >
                    {intlMessages.pricingTabMonthlyBtnText}
                  </Styled.StyledMonthBtn>
                </Styled.StyledDurationContainer>
              </Styled.StyledBtnContainer>
              <Styled.StyledCardContainer
                isMobile={isMobile ? true : false}
                isMobileLandscape={isMobileLandscape ? true : false}
                isIpad={isIpad ? true : false}
                isIpadLandscape={isIpadLandscape ? true : false}
              >
                <Styled.StyledCard
                  isMobile={isMobile ? true : false}
                  isMobileLandscape={isMobileLandscape ? true : false}
                  isIpad={isIpad ? true : false}
                  isIpadLandscape={isIpadLandscape ? true : false}
                  proTabSelected={tabSelected ? true : false}
                >
                  <Styled.NavButtonContainer
                    sx={{
                      display: isMobile || isMobileLandscape ? "flex" : "none",
                    }}
                  >
                    <Styled.NavButton
                      isMobile={isMobile ? true : false}
                      isMobileLandscape={isMobileLandscape ? true : false}
                      isIpad={isIpad ? true : false}
                      isIpadLandscape={isIpadLandscape ? true : false}
                      onClick={() => {
                        logEvent(
                          Categories.pricing_modal,
                          Events.pricing_modal_basic_plan_tab_selected
                        );
                        setTabSelected(false);
                      }}
                      isSelected={tabSelected === false ? true : false}
                      sx={{ mr: "4px" }}
                    >
                      {intlMessages.pricingTabBasicPlanLabel}
                    </Styled.NavButton>
                    <Styled.NavButton
                      isMobile={isMobile ? true : false}
                      isMobileLandscape={isMobileLandscape ? true : false}
                      isIpad={isIpad ? true : false}
                      isIpadLandscape={isIpadLandscape ? true : false}
                      onClick={() => {
                        logEvent(
                          Categories.pricing_modal,
                          Events.pricing_modal_pro_plan_tab_selected
                        );
                        setTabSelected(true);
                      }}
                      isSelected={tabSelected === true ? true : false}
                    >
                      {intlMessages.pricingTabProPlanLabel}
                    </Styled.NavButton>
                  </Styled.NavButtonContainer>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent:
                        isMobile || isMobileLandscape
                          ? "space-between"
                          : "unset",
                    }}
                  >
                    <Styled.PlanHeading>
                      {tabSelected === true
                        ? intlMessages.pricingTabProPlanLabel
                        : intlMessages.pricingTabBasicPlanLabel}
                    </Styled.PlanHeading>
                    <Styled.PopularText
                      isMobile={isMobile ? true : false}
                      isMobileLandscape={isMobileLandscape ? true : false}
                      isIpad={isIpad ? true : false}
                      isIpadLandscape={isIpadLandscape ? true : false}
                      proTabSelected={tabSelected === true ? true : false}
                    >
                      {intlMessages.pricingTabPopularText}
                    </Styled.PopularText>
                  </Box>
                  <Styled.Price>
                    {tabSelected === true
                      ? monthSelected === false
                        ? intlMessages.pricingProAnnualPrice.replace("#",(parseInt(proYearlyPlanPrice)/12).toFixed(2))
                        : intlMessages.pricingTabPlanCost.replace(
                            "#",
                            proMonthlyPlanPrice
                          )
                      : intlMessages.pricingTabPlanCost.replace(
                          "#",
                          basicPlanPrice
                        )}
                    <Styled.PriceSubscript>
                      {tabSelected === true
                        ? monthSelected === false
                          ? intlMessages.pricingTabProYearLabel
                          : intlMessages.pricingTabMonthLabel
                        : monthSelected === false
                        ? intlMessages.pricingTabMonthLabel
                        : intlMessages.pricingTabMonthLabel}
                    </Styled.PriceSubscript>
                  </Styled.Price>
                  <Styled.PriceDesc
                    isMobile={isMobile ? true : false}
                    isMobileLandscape={isMobileLandscape ? true : false}
                    isIpad={isIpad ? true : false}
                    isIpadLandscape={isIpadLandscape ? true : false}
                  >
                    {tabSelected === true
                      ? monthSelected === false
                        ? intlMessages.pricingTabPerMonthText.replace("#",calculatePercentage(proMonthlyPlanPrice,parseInt(proYearlyPlanPrice)/12))
                        : ""
                      : intlMessages.pricingTabFreeForeeverText}
                  </Styled.PriceDesc>
                  <Styled.FeatureContainer
                    isMobile={isMobile ? true : false}
                    isMobileLandscape={isMobileLandscape ? true : false}
                    isIpad={isIpad ? true : false}
                    isIpadLandscape={isIpadLandscape ? true : false}
                  >
                    <Styled.Feature
                      sx={{
                        width: isMobile ? "156px" : "unset",
                      }}
                    >
                      {intlMessages.pricingTabMaxWordsText}
                    </Styled.Feature>
                    <Styled.Feature>
                      {tabSelected === true
                        ? intlMessages.pricingTabProSubscriptionContentLimit
                        : intlMessages.pricingTabFreeSubscriptionContentLimit}
                    </Styled.Feature>
                  </Styled.FeatureContainer>
                  <Styled.FeatureContainer
                    isMobile={isMobile ? true : false}
                    isMobileLandscape={isMobileLandscape ? true : false}
                    isIpad={isIpad ? true : false}
                    isIpadLandscape={isIpadLandscape ? true : false}
                  >
                    <Styled.Feature
                      sx={{
                        width: isMobile ? "156px" : "unset",
                      }}
                    >
                      {intlMessages.pricingTabGenerateQuizText}
                    </Styled.Feature>
                    <Styled.Feature>
                      {tabSelected === true
                        ? intlMessages.pricingTabProSubscriptionGenerateQuizCount
                        : intlMessages.pricingTabFreeSubscriptionGenerateQuizCount}
                    </Styled.Feature>
                  </Styled.FeatureContainer>
                  <Styled.FeatureContainer
                    isMobile={isMobile ? true : false}
                    isMobileLandscape={isMobileLandscape ? true : false}
                    isIpad={isIpad ? true : false}
                    isIpadLandscape={isIpadLandscape ? true : false}
                  >
                    <Styled.Feature
                      sx={{
                        width: isMobile ? "156px" : isIpad || isTl || isIpadLandscape?"157px":"unset",
                      }}
                    >
                      {intlMessages.pricingTabQuizTypeText}
                    </Styled.Feature>
                    <Styled.Feature>
                      {tabSelected === true
                        ? intlMessages.pricingTabProSubscriptionQuizType
                        : intlMessages.pricingTabFreeSubscriptionQuizType}
                    </Styled.Feature>
                  </Styled.FeatureContainer>
                  <Styled.FeatureContainer
                    isMobile={isMobile ? true : false}
                    isMobileLandscape={isMobileLandscape ? true : false}
                    isIpad={isIpad ? true : false}
                    isIpadLandscape={isIpadLandscape ? true : false}
                  >
                    <Styled.Feature
                      sx={{
                        width: isMobile ? "156px" : "unset",
                      }}
                    >
                      {intlMessages.pricingTabQuestionCountText}
                    </Styled.Feature>
                    <Styled.Feature>
                      {tabSelected === true
                        ? intlMessages.pricingTabProSubscriptionQuestionCount
                        : intlMessages.pricingTabFreeSubscriptionQuestionCount}
                    </Styled.Feature>
                  </Styled.FeatureContainer>
                  <Styled.FeatureContainer
                    isMobile={isMobile ? true : false}
                    isMobileLandscape={isMobileLandscape ? true : false}
                    isIpad={isIpad ? true : false}
                    isIpadLandscape={isIpadLandscape ? true : false}
                  >
                    <Styled.Feature
                      sx={{
                        width: isMobile ? "156px" : "unset",
                      }}
                    >
                      {intlMessages.pricingTabSaveQuizText}
                    </Styled.Feature>
                    {tabSelected === true ? (
                      <DoneIcon
                        sx={{
                          color: "#7DC242",
                          height: "18px",
                          ml: isMobile || isMobileLandscape ? "-5px" : "unset",
                        }}
                      ></DoneIcon>
                    ) : (
                      <ClearIcon
                        sx={{
                          color: "#AAAAAAE5",
                          height: "18px",
                          ml: isMobile || isMobileLandscape ? "-5px" : "unset",
                        }}
                      ></ClearIcon>
                    )}
                  </Styled.FeatureContainer>
                  <Styled.FreePlanBtn
                    sx={{
                      marginTop:
                        isMobile || isMobileLandscape ? "unset" : "15px",
                      width:
                        stripeStatus === 13 &&
                        (isMobile || isMobileLandscape)
                          ? "150px"
                          : isMobile || isMobileLandscape
                          ? "133px":
                          stripeStatus === 13 &&
                        !isMobile && !isMobileLandscape?
                        "170px"
                          : "165px",
                      marginLeft:
                        stripeStatus === 13 && isMobile
                          ? "20%"
                          : isMobileLandscape
                          ? "35%"
                          : "23%",
                      fontSize:isMobile || isMobileLandscape?"15px !important":"unset",
                      lineHeight:isMobile || isMobileLandscape?"20.43px !important":"unset",
                      color:tabSelected===true && (userData?.subscriptionPlan === "Pro" ||stripeStatus === 0)?"white !important":(tabSelected===false && (userData?.subscriptionPlan === "Basic"||(userState !== USER_STATE.EXPIRED_USER && stripeStatus === 0)|| userData?.isScreenSubscription===true || userData?.isDeleted===1))?"grey !important":tabSelected==true?"white !important": "#7DC242 !important",
                      borderColor:tabSelected===true && (userData?.subscriptionPlan === "Pro" ||stripeStatus === 0)?"none":(tabSelected===false && (userData?.subscriptionPlan === "Basic"||(userState !== USER_STATE.EXPIRED_USER && stripeStatus === 0)|| userData?.isScreenSubscription===true || userData?.isDeleted===1))?"grey !important": "#7DC242 !important",
                      backgroundColor:tabSelected===true && userState === USER_STATE.EXPIRED_USER && (stripeStatus === 13|| stripeStatus===0)?"#7DC242 !important":tabSelected===true && (userData?.subscriptionPlan === "Pro" ||stripeStatus === 0)?"grey !important":tabSelected==true?"#7DC242 !important": "unset",
                    }}
                    proTabSelected={tabSelected === true ? true : false}
                    isMobile={isMobile ? true : false}
                    isMobileLandscape={isMobileLandscape ? true : false}
                    isIpad={isIpad ? true : false}
                    isIpadLandscape={isIpadLandscape ? true : false}
                    disabled={
                      tabSelected===true && userState === USER_STATE.EXPIRED_USER && (stripeStatus === 13 || stripeStatus===0) || (userState === USER_STATE.EXPIRED_USER && stripeStatus===0)?false:
                      ((stripeStatus === 0|| userData?.isScreenSubscription===true) && tabSelected === false) || ((tabSelected===true && (userData?.subscriptionPlan === "Pro" ||stripeStatus === 0)) || (tabSelected===false && userData?.subscriptionPlan === "Basic") || userData?.isDeleted===1)
                        ? true
                        : false
                    }
                    
                    onClick={
                      tabSelected === true
                        ? handleProBtnClicked
                        : handleBasicBtnClicked
                    }
                  >
                    {tabSelected === true
                      ? userData?.subscriptionPlan === "Basic" ||
                        userState === USER_STATE.EXPIRED_USER
                        ? intlMessages.pricingTabBuyNowLabel
                        : userData?.subscriptionPlan === "Pro"
                        ? intlMessages.pricingTabCurrentLabel
                        : stripeStatus === 0
                        ? intlMessages.trialCurrentPlanText
                        : intlMessages.pricingTabStartFreeBtnText
                      : userState === USER_STATE.EXPIRED_USER && (stripeStatus === 13 || stripeStatus===0)?
                      intlMessages.pricingTabStartFreeBtnText
                      :stripeStatus === 13
                      ? intlMessages.cancelDowngradeBtnText
                      : userData?.subscriptionPlan === "Basic"
                      ? intlMessages.pricingTabCurrentLabel
                      : userData?.subscriptionPlan === "Pro" ||
                        stripeStatus === 0
                      ? intlMessages.pricingTabDowngradeLabel
                      : intlMessages.pricingTabStartFreeBtnText}
                  </Styled.FreePlanBtn>
                </Styled.StyledCard>
                <Styled.StyledCard
                 isMobile={isMobile ? true : false}
                 isMobileLandscape={isMobileLandscape ? true : false}
                 isIpad={isIpad ? true : false}
                 isIpadLandscape={isIpadLandscape ? true : false}
                  sx={{
                    borderColor: "#7D00BA",
                    display: isMobile || isMobileLandscape ? "none" : "flex",
                  }}
                >
                  <Styled.PopularBox>
                    <Styled.PlanHeading>
                      {intlMessages.pricingTabProPlanLabel}
                    </Styled.PlanHeading>
                    <Styled.StyledDurationContainer
                  isMobile={isMobile ? true : false}
                  isMobileLandscape={isMobileLandscape ? true : false}
                  isIpad={isIpad ? true : false}
                  isIpadLandscape={isIpadLandscape ? true : false}
                  sx={{display:'flex',mr:'10px'}}
                >
                  <Styled.StyledYearBtn
                    isMonthSelected={monthSelected ? true : false}
                    onClick={yearClickedHandler}
                  >
                    {intlMessages.pricingTabYearlyBtnText}
                  </Styled.StyledYearBtn>
                  <Styled.StyledMonthBtn
                    isMonthSelected={monthSelected ? true : false}
                    onClick={monthClickedHandler}
                  >
                    {intlMessages.pricingTabMonthlyBtnText}
                  </Styled.StyledMonthBtn>
                </Styled.StyledDurationContainer>
                   
                  </Styled.PopularBox>
                  <Box sx={{display:'flex',flexDirection:'row',alignContent:'center',justifyContent:'space-between'}}>
                    
                  <Styled.Price>
                    {monthSelected === false
                      ? intlMessages.pricingProAnnualPrice.replace("#",(parseInt(proYearlyPlanPrice)/12).toFixed(2))
                      : intlMessages.pricingTabPlanCost.replace(
                          "#",
                          proMonthlyPlanPrice
                        )}
                    <Styled.PriceSubscript>
                      {monthSelected === false
                        ? intlMessages.pricingTabProYearLabel
                        : intlMessages.pricingTabMonthLabel}
                    </Styled.PriceSubscript>
                  </Styled.Price>
                  <Styled.PopularText sx={{display:'block',mt:'10px'}}>
                      {intlMessages.pricingTabPopularText}
                    </Styled.PopularText>
                  </Box>
                  <Styled.PriceDesc sx={{visibility:monthSelected === true?"hidden":"unset"}}>
                    {intlMessages.pricingTabPerMonthText.replace("#",calculatePercentage(proMonthlyPlanPrice,(parseInt(proYearlyPlanPrice)/12).toFixed(2)))}
                  </Styled.PriceDesc>
                  <Styled.FeatureContainer>
                    <Styled.Feature>
                      {intlMessages.pricingTabMaxWordsText}
                    </Styled.Feature>
                    <Styled.Feature>
                      {intlMessages.pricingTabProSubscriptionContentLimit}
                    </Styled.Feature>
                  </Styled.FeatureContainer>
                  <Styled.FeatureContainer>
                    <Styled.Feature>
                      {intlMessages.pricingTabGenerateQuizText}
                    </Styled.Feature>
                    <Styled.Feature>
                      {intlMessages.pricingTabProSubscriptionGenerateQuizCount}
                    </Styled.Feature>
                  </Styled.FeatureContainer>
                  <Styled.FeatureContainer>
                    <Styled.Feature>
                      {intlMessages.pricingTabQuizTypeText}
                    </Styled.Feature>
                    <Styled.Feature>
                      {intlMessages.pricingTabProSubscriptionQuizType}
                    </Styled.Feature>
                  </Styled.FeatureContainer>
                  <Styled.FeatureContainer>
                    <Styled.Feature>
                      {intlMessages.pricingTabQuestionCountText}
                    </Styled.Feature>
                    <Styled.Feature>
                      {intlMessages.pricingTabProSubscriptionQuestionCount}
                    </Styled.Feature>
                  </Styled.FeatureContainer>
                  <Styled.FeatureContainer>
                    <Styled.Feature>
                      {intlMessages.pricingTabSaveQuizText}
                    </Styled.Feature>
                    <DoneIcon
                      sx={{ color: "#7DC242", height: "18px" }}
                    ></DoneIcon>
                  </Styled.FeatureContainer>
                  <Styled.ProPlanBtn disabled={userState === USER_STATE.EXPIRED_USER && (stripeStatus === 13 || stripeStatus===0)?false:userData?.subscriptionPlan === "Pro" ||stripeStatus === 0?true:false }  sx={{color:"white !important",
                      backgroundColor:userState === USER_STATE.EXPIRED_USER && (stripeStatus === 13 || stripeStatus===0)?"#7DC242":userData?.subscriptionPlan === "Pro" ||stripeStatus === 0 ?"grey !important":"#7DC242"}} onClick={handleProBtnClicked}>
                    {userData?.subscriptionPlan === "Basic" ||
                    userState === USER_STATE.EXPIRED_USER 
                      ? intlMessages.pricingTabBuyNowLabel
                      : userData?.subscriptionPlan === "Pro"
                     ? intlMessages.pricingTabCurrentLabel
                      : stripeStatus === 0
                      ? intlMessages.trialCurrentPlanText
                      : intlMessages.pricingTabStartFreeBtnText}
                  </Styled.ProPlanBtn>
                </Styled.StyledCard>
              </Styled.StyledCardContainer>
              <Styled.DropdownContainer
                className={"noSelect"}
                onClick={() => {
                  logEvent(
                    Categories.pricing_modal,
                    Events.pricing_modal_full_feature_breakdown_btn_clicked
                  );
                  setIsShowFeatures(!showFeatures);
                }}
              >
                <Styled.StyledFeatureBreakdownText
                  showFeatures={showFeatures ? true : false}
                  isMobile={isMobile ? true : false}
                  isMobileLandscape={isMobileLandscape ? true : false}
                  isIpad={isIpad ? true : false}
                  isIpadLandscape={isIpadLandscape ? true : false}
                >
                  {intlMessages.pricingTabFeatureBreakdownText}
                </Styled.StyledFeatureBreakdownText>
                <Styled.DropDownIcon
                  isMobile={isMobile ? true : false}
                  isMobileLandscape={isMobileLandscape ? true : false}
                  isIpad={isIpad ? true : false}
                  isIpadLandscape={isIpadLandscape ? true : false}
                  className="noSelect"
                  showFeatures={showFeatures ? true : false}
                  src={
                    showFeatures
                      ? "svgs/collapse-icon.svg"
                      : "svgs/expand-icon.svg"
                  }
                ></Styled.DropDownIcon>
              </Styled.DropdownContainer>
              <Styled.StyledFeatureBreakdownDesc
                isMobile={isMobile ? true : false}
                isMobileLandscape={isMobileLandscape ? true : false}
                isIpad={isIpad ? true : false}
                isIpadLandscape={isIpadLandscape ? true : false}
                showFeatures={showFeatures ? true : false}
              >
                {intlMessages.pricingTabFeatureBreakdownDesc}
              </Styled.StyledFeatureBreakdownDesc>
              <Box
                sx={{
                  width: isMobile ? "unset" : "100%",
                  paddingLeft: isMobile
                    ? "unset"
                    : isMobileLandscape
                    ? "7%"
                    : "2%",
                  paddingRight: isMobile
                    ? "unset"
                    : isMobileLandscape
                    ? "7%"
                    : "2%",
                }}
              >
                <Styled.StyledFeatures
                  isMobile={isMobile ? true : false}
                  isMobileLandscape={isMobileLandscape ? true : false}
                  isIpad={isIpad ? true : false}
                  isIpadLandscape={isIpadLandscape ? true : false}
                  showFeatures={showFeatures ? true : false}
                  src={
                    isMobile
                      ? "svgs/Details_Mobile.svg"
                      : isMobileLandscape
                      ? "svgs/Details_Landscape.svg"
                      : "svgs/Details.svg"
                  }
                ></Styled.StyledFeatures>
              </Box>
              <Grid
                container
                sx={{ mb: "35px", display: showFeatures ? "flex" : "none" }}
              >
                <Grid item xs={3} sm={isIpad?5.5:5} md={6}></Grid>
                <Grid item xs={4} sm={3.5} md={3.5}>
                  <Styled.FreePlanBtn
                    disabled={userState === USER_STATE.EXPIRED_USER && stripeStatus===0?false:stripeStatus === 0 || userData?.isScreenSubscription===true || userData?.subscriptionPlan === "Basic" || userData?.isDeleted===1 ? true : false}
                    isMobile={isMobile ? true : false}
                    isMobileLandscape={isMobileLandscape ? true : false}
                    isIpad={isIpad ? true : false}
                    isIpadLandscape={isIpadLandscape ? true : false}
                    onClick={handleBasicBtnClicked}
                    sx={{
                      width:
                        stripeStatus === 13 &&
                        (isMobile || isMobileLandscape)
                          ? "150px"
                          : isMobile || isMobileLandscape
                          ? "108px":
                          stripeStatus === 13 &&
                        !isMobile && !isMobileLandscape?
                        "170px"
                          : "155px",
                      height: isMobile || isMobileLandscape ? "33px" : "43px",
                      color:userState === USER_STATE.EXPIRED_USER && stripeStatus===0?"#7DC242":stripeStatus === 0 || userData?.isScreenSubscription===true || userData?.subscriptionPlan === "Basic" || userData?.isDeleted===1 ?"grey !important":"#7DC242",
                      borderColor:userState === USER_STATE.EXPIRED_USER && stripeStatus===0?"#7DC242":stripeStatus === 0 || userData?.isScreenSubscription===true || userData?.subscriptionPlan === "Basic" || userData?.isDeleted===1?"grey !important":"#7DC242",
                    }}
                  >
                    {userState === USER_STATE.EXPIRED_USER && (stripeStatus === 13|| stripeStatus===0)?
                    intlMessages.pricingTabGetForFreeText:
                    stripeStatus === 13
                      ? intlMessages.cancelDowngradeBtnText
                      : userData?.subscriptionPlan === "Basic"
                      ? intlMessages.pricingTabCurrentLabel
                      : userData?.subscriptionPlan === "Pro" ||
                        stripeStatus === 0
                      ? intlMessages.pricingTabDowngradeLabel
                      : userData === null
                      ? intlMessages.pricingTabStartFreeBtnText
                      : intlMessages.pricingTabGetForFreeText}
                  </Styled.FreePlanBtn>
                </Grid>
                <Grid item xs={4} sm={2} md={2}>
                  <Styled.ProPlanBtn
                    isMobile={isMobile ? true : false}
                    isMobileLandscape={isMobileLandscape ? true : false}
                    isIpad={isIpad ? true : false}
                    isIpadLandscape={isIpadLandscape ? true : false}
                    onClick={handleProBtnClicked}
                    disabled={userState === USER_STATE.EXPIRED_USER && (stripeStatus === 13|| stripeStatus===0)?false:userData?.subscriptionPlan === "Pro" ||stripeStatus === 0?true:false }
                    sx={{
                      width: isMobile || isMobileLandscape ? "108px" : "155px",
                      height: isMobile || isMobileLandscape ? "33px" : "43px",
                      ml: isMobile || isMobileLandscape ? "30px" : "10px",
                      color:"white !important",
                      backgroundColor:userState === USER_STATE.EXPIRED_USER && (stripeStatus === 13|| stripeStatus===0)?"#7DC242":userData?.subscriptionPlan === "Pro" ||stripeStatus === 0?"grey !important":"#7DC242"
                    }}
                  >
                    {userData?.subscriptionPlan === "Basic" ||
                    userState === USER_STATE.EXPIRED_USER
                      ? intlMessages.pricingTabBuyNowLabel
                      : userData?.subscriptionPlan === "Pro"
                      ? intlMessages.pricingTabCurrentLabel
                      : stripeStatus === 0
                      ? intlMessages.trialCurrentPlanText
                      : userData === null
                      ? intlMessages.pricingTabStartFreeBtnText
                      : intlMessages.pricingTabGetForFreeText}
                  </Styled.ProPlanBtn>
                </Grid>
              </Grid>
            </Box>
          </Styled.ModalContentBox>
        </Styled.ModalBox>
      </Modal>
      {downgradeModalState && (
        <DowngradModal
          open={downgradeModalState}
          onClose={(flag, openTrialModal) => {
            if (!!openTrialModal) {
              setTrialModalText({
                desc: intlMessages.downgradeModalText,
              });
              setOpenTrialModal(true);
              dispatch(setStripeStatus(13));
            }
            toggleDowngradeModalState(flag);
          }}
        ></DowngradModal>
      )}
      {openTrialModal && (
        <TrialInfoModal
          downgradeFlag={true}
          text={trialModalText}
          open={openTrialModal}
          onClose={(flag) => setOpenTrialModal(flag)}
        ></TrialInfoModal>
      )}
      {openConnectionModal && (
        <ConnectionModal
          isOpen={openConnectionModal}
          handleCloseConnectionModal={() => setIsOpenConnectionModal(false)}
        />
      )}
    </>
  );
}
