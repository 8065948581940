import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Styled from "./styles";
import "./LoaderWithCss.css";
import intlMessages from "../../locales/en";

const ImportLoaderModal = (props) => {
  const { openImportLoaderModal,isWebURL,isImportedFromScrybe } = props;
  return (
    <>
      <Modal
        open={openImportLoaderModal}
        aria-labelledby="review-modal"
        aria-describedby="review-description"
      >
        <Styled.ModalPrmissionSignInBox sx={Styled.customStyling.exportModal}>
          <Styled.ModalContentBox>
            <Styled.ContainerBox>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                }}
                onClick={() => {}}
              >
                <img
                  src="svgs/Icon4_QuizWiz.svg"
                  alt="Icon"
                  style={{
                    height: "90px",
                    width: "90px",
                    marginBottom: "30px",
                  }}
                />
                <Styled.LoadingText variant="body1">
                  {isWebURL!==undefined && isWebURL===true?intlMessages.webURLImportingFileText:isImportedFromScrybe===true?intlMessages.scrybeLoadContentText:intlMessages.driveImportingFileText}
                </Styled.LoadingText>
                <div className="bouncing-loader">
                  <div></div>
                  <div></div>
                  <div></div>
                </div>
              </Box>
            </Styled.ContainerBox>
          </Styled.ModalContentBox>
        </Styled.ModalPrmissionSignInBox>
      </Modal>
    </>
  );
};

export default ImportLoaderModal;
