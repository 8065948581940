import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import styled from "@mui/system/styled";
import Poppins from "../../../themes/fonts/Poppins-Medium.woff2";
import Checkbox from "@mui/material/Checkbox";
import mobilelandscapeimageUrl from "./mobile-landscape-bg.js";
import mobileportraitimageUrl from "./mobile-portrait-bg.js";

const MobilelogoImage = styled("img")(({ theme }) => ({
  width: "70%",
  height: "70%",
  marginTop: "5%",
  marginLeft: "-5%",
}));

const MobileSlidesImage = styled("img")(({ theme, isMobile }) => ({
  width: "100%",
  height: isMobile ? "130px" : "150px",
}));

const MobileStyledSlideDesc = styled(Typography)(({ theme }) => ({
  fontFamily: "Poppins",
  textAlign: "center",
  color: "#7DC242",
  display: "flex",
  justifyContent: "center",
  height: 10,
  "@font-face": {
    fontFamily: "Poppins",
    src: `url(${Poppins}) format('woff2')`,
    fontWeight: "normal",
    fontStyle: "normal",
  },
  fontWeight: "400",
  fontSize: "0.9rem",
}));

const MobileStyledDotDiv = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  height: 10,
}));

const MobiledescText = styled("Typography")(({ theme }) => ({
  fontSize: "1rem",
  color: "rgb(249 245 245)",
  width: "70%",
}));

const MobileConsentBox = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  marginLeft: "-3%",
}));

const MobiledescText2 = styled("Typography")(({ theme }) => ({
  fontSize: "0.8rem",
  width: "70%",
}));

const StyledCheckbox = styled(Checkbox)({
  "&.consent-checkbox .MuiSvgIcon-root": {
    color: "#7DC242",
  },
});

const StyledUnCheckedIcon = styled("div")({
  borderRadius: 3,
  border: "2px solid #7DC242",
  width: 22,
  height: 22,
});

const MobileContainer = styled(Grid)(
  ({ theme, isMobileLandscape, isIpad, isIpadLandscape }) => ({
    backgroundImage: isMobileLandscape
      ? `url(${mobilelandscapeimageUrl})`
      : `url(${mobileportraitimageUrl})`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgrounColor: "transparent",
    width:
      isIpad || isIpadLandscape
        ? "720px"
        : isMobileLandscape
        ? "630px"
        : "350px",
    height:
      isIpad || isIpadLandscape
        ? "400px"
        : isMobileLandscape
        ? "340px"
        : "650px",
    marginTop: isMobileLandscape ? 15 : 15,
    position: "absolute",
    left: "50%",
    top: isIpad || isIpadLandscape ? "40%" : isMobileLandscape ? "46%" : "46%",
    [theme.breakpoints.down("xxsm")]: {
      top:
        isIpad || isIpadLandscape ? "40%" : isMobileLandscape ? "50%" : "50%",
      transform: isMobileLandscape
        ? "translate(-50%, -30%)"
        : "translate(-50%, -43%)",
    },
    transform: isMobileLandscape
      ? "translate(-50%, -30%)"
      : "translate(-50%, -50%)",

    paddingLeft: isMobileLandscape ? 20 : 40,
    paddingTop: isMobileLandscape ? "unset" : 12,
    borderRadius: "30px !important",
  })
);
export default {
  StyledCheckbox,
  StyledUnCheckedIcon,
  MobilelogoImage,
  MobileSlidesImage,
  MobileStyledSlideDesc,
  MobileStyledDotDiv,
  MobiledescText,
  MobileConsentBox,
  MobiledescText2,
  MobileContainer,
};
