import api from "../index";
import { checkInternetConnection} from "../service";

async function getQuizList(payload){
    try {
      const { page, sortBy,order, size, search, signal } =
      payload;
    let params = {
      page,
      sortBy,
      order,
      size,
      search,
    };
    params = Object.entries(params).reduce(
      (a, [k, v]) => (v ? ((a[k] = v), a) : a),
      {}
    );
    const connectionStatus = await checkInternetConnection();
        const isInternetConnected = connectionStatus.isConnected;
        if (!isInternetConnected)
            return connectionStatus;
      const res = await api.get(`/library`,{ params,signal });
      return res
    } catch (e) {
      return e;
    }
  }

  async function getQuiz(id){
    try {
    const connectionStatus = await checkInternetConnection();
        const isInternetConnected = connectionStatus.isConnected;
        if (!isInternetConnected)
            return connectionStatus;
      const res = await api.get(`/library/${id}`);
      return res
    } catch (e) {
      return e;
    }
  }

  async function editQuiz(id,title,quiz){

    const payload = {
      title:title,
      quiz: quiz
    }
    try {
    const connectionStatus = await checkInternetConnection();
        const isInternetConnected = connectionStatus.isConnected;
        if (!isInternetConnected)
            return connectionStatus;
      const res = await api.put(`/library/update/${id}`,payload);
      return res
    } catch (e) {
      return e;
    }
  }

  async function forceCreateQuiz(title,quiz){
    const payload = {
      title:title,
      quiz: quiz
    }
    try {
    const connectionStatus = await checkInternetConnection();
        const isInternetConnected = connectionStatus.isConnected;
        if (!isInternetConnected)
            return connectionStatus;
      const res = await api.put(`/library/force`,payload);
      return res
    } catch (e) {
      return e;
    }
  }

  async function deleteQuiz(id){
    try {
    const connectionStatus = await checkInternetConnection();
        const isInternetConnected = connectionStatus.isConnected;
        if (!isInternetConnected)
            return connectionStatus;
      const res = await api.delete(`/library/delete/${id}`);
      return res
    } catch (e) {
      return e;
    }
  }


  export {
    getQuizList,
    getQuiz,
    editQuiz,
    deleteQuiz,
    forceCreateQuiz
}