import styled from "@mui/system/styled";
import { Typography, Button, Box } from "@mui/material";
import Mulish from "../../../themes/fonts/Mulish-ExtraBold.woff2";
import aboutImages from "../about-page-images";

const sliderBox = styled(Box)(({ theme, isMobileLandscape, isIpad }) => ({
  backgroundImage: `url(${aboutImages["sliderBoxMobileBackground"]})`,
  backgroundRepeat: "no-repeat",
  backgroundSize: "cover",
  height: isMobileLandscape ? "550px" : isIpad ? "928px" : "654px",
  width: "100%",
  display: "flex",
  flexDirection: "column-reverse",
  justifyContent: "center",
  color: "white",
  alignItems: "center",
  paddingTop: "50px",
  marginTop: isMobileLandscape ? "unset" : isIpad ? "-20px" : "-10px",
}));

const SliderLogo = styled("img")(({ theme, isMobileLandscape }) => ({
  height: isMobileLandscape ? "36px" : "66px",
  width: isMobileLandscape ? "110.18px" : "202px",
  marginBottom: "30px",
}));

const SliderText = styled(Typography)(
  ({ theme, isIpad, isMobileLandscape }) => ({
    marginTop: "15px",
    marginBottom: isMobileLandscape ? "20px" : "30px",
    fontFamily: "Mulish",
    fontWeight: 800,
    fontSize: isIpad ? "72px" : isMobileLandscape ? "54px" : "48px",
    color: "#7DC243",
    lineHeight: isIpad ? "68px" : isMobileLandscape ? "54px" : "49px",
    "@font-face": {
      fontFamily: "Mulish",
      src: `url(${Mulish}) format('woff2')`,
      fontWeight: "800",
      fontStyle: "normal",
    },
  })
);
const sliderText2 = styled(Typography)(({ theme, isMobileLandscape }) => ({
  fontFamily: "Open Sans",
  fontWeight: 300,
  fontSize: isMobileLandscape ? "24px" : "22px",
  color: "#A680F6",
  lineHeight: "36px",
  fontStyle: "normal",
  marginBottom: isMobileLandscape ? "20px" : "40px",
}));
const sliderText3 = styled("span")(({ theme }) => ({
  fontWeight: "800",
  "@font-face": {
    fontFamily: "Mulish",
    src: `url(${Mulish}) format('woff2')`,
    fontWeight: "800",
    fontStyle: "normal",
  },
}));

const SliderBtn = styled(Button)(({ theme, isIpad, isMobileLandscape }) => ({
  width: isMobileLandscape ? "247px" : isIpad ? "291.58px" : "247px",
  height: "49px",
  backgroundColor: "#F96D30",
  color: theme.palette.primary.contrastText,
  textTransform: "none",
  marginBottom: isIpad ? "84px" : "30px",
  marginLeft: isMobileLandscape ? "10px" : "unset",
  fontSize:'18px !important'
}));

const SliderImage = styled("img")(({ theme, isMobileLandscape, isIpad }) => ({
  width: isMobileLandscape ? "309.79px" : isIpad ? "531.29px" : "280px",
  height: isMobileLandscape ? "205.6px" : isIpad ? "418.94px" : "215px",
  objectFit: "contain",
}));

const SliderDotBox = styled(Box)(({ theme, isMobileLandscape }) => ({
  display: "flex",
  flexDirection: "row",
  marginTop: "20px",
  justifyContent: "center",
  marginBottom: isMobileLandscape ? "20px" : "45px",
}));

const FeaturesBox = styled(Box)(({ theme, isIpad, isMobileLandscape }) => ({
  backgroundColor: "white",
  display: "flex",
  flexDirection: "column",
  width: "100%",
  height: isIpad ? "450px" : isMobileLandscape ? "450px" : "510px",
  alignItems: "center",
  justifyContent: "center",
  paddingTop: "20px",
}));

const featureBoxHeading = styled(Typography)(
  ({ theme, isIpadLandscape, isTl }) => ({
    fontFamily: "Open Sans",
    fontWeight: 700,
    lineHeight: isIpadLandscape && !isTl ? "31px" : "43.58px",
    textAlign: "center",
    fontSize: isIpadLandscape && !isTl ? "21px" : "32px",
  })
);

const ForTeachersBox = styled(Box)(({ theme, isMobileLandscape, isIpad }) => ({
  backgroundColor: "#F3F3F3",
  width: "100%",
  height: isMobileLandscape ? "786px" : isIpad ? "650px" : "840px",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  paddingLeft: "3%",
  paddingRight: "3%",
}));

const ForStudentsBox = styled(Box)(({ theme, isMobileLandscape, isIpad }) => ({
  backgroundColor: "white",
  width: "100%",
  height: isMobileLandscape ? "663px" : isIpad ? "591px" : "600px",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  paddingLeft: "3%",
  paddingRight: "3%",
}));

const SchoolBox = styled(Box)(({ theme, isMobileLandscape, isIpad }) => ({
  backgroundColor: "#F3F3F3",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  width: "100%",
  height: isMobileLandscape ? "509px" : isIpad ? "437px" : "565px",
  alignItems: "center",
}));

const ReviewBox = styled(Box)(({ theme, isIpad, isMobileLandscape }) => ({
  backgroundColor: "#F3F3F3",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  width: "100%",
  height: isIpad ? "420px" : isMobileLandscape ? "430px" : "510px",
  alignItems: "center",
}));

const FeatureIcon = styled("img")(({ theme }) => ({
  width: "60px",
  height: "60px",
  marginRight: "auto",
  marginLeft: "auto",
  display: "block",
}));

const FeatureText = styled(Typography)(
  ({ theme, isMobileLandscape, isIpad }) => ({
    fontFamily: "Open Sans",
    fontWeight: 400,
    lineHeight: "21.79px",
    textAlign: "center",
    fontSize: "16px",
    marginBottom: isIpad ? "30px" : "20px",
    marginTop: "10px",
    paddingRight: isMobileLandscape ? "unset" : isIpad ? "unset" : "15%",
    paddingLeft: isMobileLandscape ? "unset" : isIpad ? "unset" : "15%",
  })
);

const DoubleQuotesBlack = styled("img")(({ theme }) => ({
  width: "48px",
  height: "48px",
}));

const TeacherLongDesc = styled(Typography)(
  ({ theme, isIpad, isMobileLandscape }) => ({
    fontFamily: "Open Sans",
    lineHeight: "32px",
    fontSize: "18px",
    fontWeight: 400,
    marginLeft: "5px",
    textAlign: "left",
    // marginTop: '-3px',
    width: isMobileLandscape ? "420px" : isIpad ? "726px" : "280px",
  })
);

const TeacherImg = styled("img")(({ theme, isIpad, isMobileLandscape }) => ({
  width: isIpad ? "380px" : isMobileLandscape ? "380px" : "280px",
  height: isIpad ? "252px" : isMobileLandscape ? "252px" : "191px",
  marginTop: "20px",
  marginBottom: "30px",
}));

const StudentImg = styled("img")(({ theme, isIpad, isMobileLandscape }) => ({
  width: isIpad ? "380px" : isMobileLandscape ? "380px" : "280px",
  height: isIpad ? "226px" : isMobileLandscape ? "226px" : "182px",
  marginBottom: "30px",
}));

const StudentLongDesc = styled(Typography)(
  ({ theme, isIpad, isMobileLandscape }) => ({
    fontFamily: "Open Sans",
    lineHeight: "24.51px",
    fontSize: "18px",
    fontWeight: 400,
    width: isMobileLandscape ? "420px" : isIpad ? "726px" : "280px",
  })
);

const SchoolImg = styled("img")(({ theme, isMobileLandscape, isIpad }) => ({
  width: isMobileLandscape ? "325px" : isIpad ? "325px" : "280px",
  height: isMobileLandscape ? "211px" : isIpad ? "211px" : "211px",
  marginBottom: "10px",
}));

const SchoolDesc = styled(Typography)(
  ({ theme, isMobileLandscape, isIpad }) => ({
    fontFamily: "Open Sans",
    lineHeight: "24px",
    fontSize: "18px",
    fontWeight: 400,
    width: isMobileLandscape ? "420px" : isIpad ? "646px" : "280px",
  })
);

const CreateQuizBox = styled(Box)(({ theme, isMobileLandscape, isIpad }) => ({
  width: "100%",
  height: isMobileLandscape ? "671px" : isIpad ? "381px" : "743px",
  backgroundColor: "#FFFFFF",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
}));

const QuizContentBox = styled(Box)(({ theme, isMobileLandscape, isIpad }) => ({
  display: "flex",
  flexDirection: isIpad ? "row" : "column",
  marginTop: "30px",
  height: isMobileLandscape ? "525px" : isIpad ? "215px" : "597px",
  backgroundColor: "#7D00BA1A",
  justifyContent: "center",
  alignItems: "center",
  paddingLeft: "30px",
  paddingRight: "30px",
  width: isMobileLandscape ? "440px" : isIpad ? "726px" : "280px",
}));

const QuizContentBoxImage = styled("img")(({ theme }) => ({
  width: "36px",
  height: "48px",
  marginBottom: "15px",
}));

const QuizContentBoxText = styled(Typography)(
  ({ theme, isIpadLandscape, isTl }) => ({
    fontFamily: "Open Sans",
    lineHeight: isIpadLandscape && !isTl ? "42px" : "24x",
    fontSize: isIpadLandscape && !isTl ? "26px" : "16px",
    fontWeight: 400,
    color: "#7D00BA",
    textAlign: "center",
  })
);

const ArrowIcon = styled("img")(({ theme, isIpad }) => ({
  marginTop: isIpad ? "unset" : "30px",
  marginBottom: isIpad ? "unset" : "30px",
  marginLeft: isIpad ? "30px" : "unset",
  marginRight: isIpad ? "30px" : "unset",
}));

const GenerateQuizBtn = styled(Button)(({ theme, isMobileLandscape }) => ({
  width: "229px",
  height: "56px",
  backgroundColor: "#F96D30",
  color: theme.palette.primary.contrastText,
  textTransform: "none",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  borderRadius: "5px",
  fontSize:'21px !important'
}));

const Review = styled(Box)(({ theme, isIpad, isMobileLandscape }) => ({
  display: "flex",
  flexDirection: "column",
  height: isIpad ? "280px" : isMobileLandscape ? "300px" : "380px",
  width: isMobileLandscape ? "470px" : isIpad ? "557px" : "unset",
  backgroundColor: "white",
  marginBottom: "3px",
  justifyContent: "center",
  paddingLeft: "3%",
  paddingRight: "3%",
  marginLeft: "3%",
  marginRight: "3%",
}));

const RatingStar = styled("img")(({ theme }) => ({
  height: "28px",
  width: "27.78px",
}));

const ArrowBtn = styled("img")(({ theme, isIpad }) => ({
  display: isIpad ? "block" : "none",
  height: "20.62px",
  width: "24px",
  cursor: "pointer",
}));

export default {
  sliderBox,
  FeaturesBox,
  ForTeachersBox,
  ForStudentsBox,
  ReviewBox,
  FeatureIcon,
  FeatureText,
  DoubleQuotesBlack,
  TeacherLongDesc,
  TeacherImg,
  StudentImg,
  StudentLongDesc,
  GenerateQuizBtn,
  Review,
  RatingStar,
  SliderLogo,
  SliderText,
  SliderBtn,
  SliderDotBox,
  SliderImage,
  sliderText2,
  sliderText3,
  featureBoxHeading,
  SchoolBox,
  SchoolDesc,
  SchoolImg,
  QuizContentBox,
  QuizContentBoxImage,
  QuizContentBoxText,
  ArrowIcon,
  CreateQuizBox,
  ArrowBtn,
};
