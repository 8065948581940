import api from "../index";
import { checkInternetConnection } from "../service";

async function updateAccountDetails(user) {
    try {
        const payload = {
            firstName: user?.firstName,
            lastName: user?.lastName,
        };
        const connectionStatus = await checkInternetConnection();
        const isInternetConnected = connectionStatus.isConnected;
        if (!isInternetConnected)
            return connectionStatus;
        const res = await api.patch(`/user/profile`, payload);
        return res;
    } catch (e) {
        return e;
    }
}

async function updatePassword(password) {
    try {
        const payload = {
            oldPassword: password?.currentPassword,
            newPassword: password?.newPassword,
        };
        const connectionStatus = await checkInternetConnection();
        const isInternetConnected = connectionStatus.isConnected;
        if (!isInternetConnected)
            return connectionStatus;
        const res = await api.post(`/user/changepassword`, payload);
        return res;
    } catch (e) {
        return e;
    }
}

async function deleteAccount() {
    try {
        const connectionStatus = await checkInternetConnection();
        const isInternetConnected = connectionStatus.isConnected;
        if (!isInternetConnected)
            return connectionStatus;
        const res = await api.delete(`/user/user`);
        return res;
    } catch (e) {
        return e;
    }
}

async function initiateDeleteAccount(email,remarks,type) {
    try {
        const connectionStatus = await checkInternetConnection();
        const isInternetConnected = connectionStatus.isConnected;
        const payload = {
            email:email,
            remarks:remarks.toString(),
            type:type
        };
        if (!isInternetConnected)
            return connectionStatus;
        const res = await api.patch(`/user/initiateDeleteAccount`,payload);
        return res;
    } catch (e) {
        return e;
    }
}

async function cancelDeleteAccount(email) {
    try {
        const connectionStatus = await checkInternetConnection();
        const isInternetConnected = connectionStatus.isConnected;
        const payload = {
            email:email,
        };
        if (!isInternetConnected)
            return connectionStatus;
        const res = await api.patch(`/user/cancelDeleteAccount`,payload);
        return res;
    } catch (e) {
        return e;
    }
}
export {
    updateAccountDetails,
    updatePassword,
    deleteAccount,
    initiateDeleteAccount,
    cancelDeleteAccount
}