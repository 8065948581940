import { useEffect, useState } from "react";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { Button, FormHelperText, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Styled from "./styles";
import intlMessages from "../../locales/en";
import SelectMenu from "../../common/select-component/component";
import { STATES_ARRAY } from "./states";
import { Categories, Events, logEvent } from "../../analytics";
import { updateStatus } from "./service";
import { useSelector } from "react-redux";

const UserInformation = (props) => {
  const { userInformation,userData } = useSelector((state) => state.logIn);
  const {
    openUserInformationModal,
    closeUserInformationModal,
    isMobile,
    isFirstTime = false,
    isEditModal = false,
    isMobileLandscape,
    isIpadLandscape,
    isIpad,
  } = props;
  const [isSaving, setIsSaving] = useState(false);
  const [userRolesArray, setUserRolesArray] = useState(
    userInformation?.role[0] || ""
  );
  const setUserRolesArraySelector = (value) => {
    setUserRolesArray(value);
  };
  const [userGradeArray, setUserGradeArray] = useState([]);
  const setUserGradeArraySelector = (value) => {
    setUserGradeArray(typeof value === "string" ? value.split(",") : value);
  };

  const [userSubjectArray, setUserSubjectArray] = useState([]);
  const setUserSubjectArraySelector = (value) => {
    setUserSubjectArray(typeof value === "string" ? value.split(",") : value);
  };

  const closeModal = () => {
    logEvent(
      Categories.user_management,
      Events.user_managment_info_modal_closed_without_savings
    );
    closeUserInformationModal();
    return;
  };

  const [userStateArray, setUserstateArray] = useState("");
  const setUserStateArraySelector = (value) => {
    setUserstateArray(value);
  };

  const [userUseArray, setUserUseArray] = useState("");
  const setUserUseArraySelector = (value) => {
    setUserUseArray(value);
  };

  const [schoolName, setSchoolName] = useState("");
  const [schoolError, setSchoolError] = useState("");

  const onChangeSchoolname = (e) => {
    setSchoolError("");
    setSchoolName(e.target.value);
    removeError();
  };

  const [errorsList, setErrorList] = useState([]);

  const [orgName, setOrgName] = useState("");
  const [orgError, setOrgError] = useState("");

  const pushError = (newError) => {
    setErrorList((prevErrors) => [...prevErrors, newError]);
  };

  const removeError = () => {
    setErrorList([]);
  };

  const onChangeOrgname = (e) => {
    setOrgError("");
    setOrgName(e.target.value);
    removeError();
  };

  const setDefaultStates = () => {
    // setUserRolesArray("");
    setUserGradeArray([]);
    setUserSubjectArray([]);
    setUserstateArray("");
    setOrgName("");
    setSchoolName("");
    setUserUseArray([]);
  };

  useEffect(() => {
    if (!!userInformation) {
      if (userInformation.role.length > 0) {
        setUserRolesArray(userInformation.role[0]);
      }
      if (userInformation.grade_list.length > 0) {
        setUserGradeArray(
          typeof userInformation.grade_list === "string"
            ? userInformation.grade_list.split(",")
            : userInformation.grade_list
        );
      }
      if (userInformation.subjects.length > 0) {
        setUserSubjectArray(
          typeof userInformation.subjects === "string"
            ? userInformation.subjects.split(",")
            : userInformation.subjects
        );
      }
      if (userInformation.state.length > 0) {
        setUserstateArray(userInformation.state[0]);
      }

      if (!!userInformation?.orgName) {
        setOrgName(userInformation.orgName);
      }
      if (!!userInformation?.use?.length > 0) {
        setUserUseArray(userInformation.use[0]);
      }
      if (!!userInformation?.schoolName) {
        setSchoolName(userInformation.schoolName);
      }
    }
    return () => {
      removeError();
    };
  }, []);

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      // onSubmitGoogleForms();
    }
  };

  const updateUserInfo = (isSkipped) => {
    if (isFirstTime && isSkipped === 1) closeUserInformationModal();
    setIsSaving(true);
    // check for all fields
    if (isSkipped !== 1 && checkErrorsExists()) {
      setIsSaving(false);
      return;
    }
    if (isFirstTime && isSkipped == 1) {
      logEvent(
        Categories.user_management,
        Events.user_managment_info_modal_info_skipped
      );
    } else {
      logEvent(
        Categories.user_management,
        Events.user_managment_info_modal_info_saved,
        JSON.stringify({
          role: userRolesArray,
          grade_list: userGradeArray,
          subjects: userSubjectArray,
          state: userStateArray,
          orgName,
          schoolName,
          use: userUseArray,
        })
      );
    }
    // create a function that returns value
    updateStatus(isSkipped, {
      role: userRolesArray,
      grade_list: userGradeArray,
      subjects: userSubjectArray,
      state: userStateArray,
      orgName,
      schoolName,
      use: userUseArray,
    })
      .then((res) => {
        setIsSaving(false);
        closeUserInformationModal();
      })
      .catch((err) => {
        setIsSaving(false);
        closeUserInformationModal();
      });
  };

  const checkErrorsExists = () => {
    const localErrorList = [];
    if (
      userRolesArray === intlMessages.userInfoModalRole1 ||
      userRolesArray === intlMessages.userInfoModalRole2
    ) {
      if (userGradeArray.length === 0) {
        //show error
        pushError(intlMessages.userInfoModalGrade);
        localErrorList.push(intlMessages.userInfoModalGrade);
      }
      if (userSubjectArray.length === 0) {
        //show error
        pushError(intlMessages.userInfoModalSubject);
        localErrorList.push(intlMessages.userInfoModalSubject);
      }
    } else if (userRolesArray === intlMessages.userInfoModalRole3) {
      if (schoolName?.trim()?.length === 0 || schoolName.trim().length < 2) {
        pushError(intlMessages.schoolName);
        localErrorList.push(intlMessages.schoolName);
      }
    } else if (userRolesArray === intlMessages.userInfoModalRole4) {
      if (orgName?.trim()?.length === 0 || orgName.trim().length < 2) {
        pushError(intlMessages.orgName);
        localErrorList.push(intlMessages.orgName);
      }
      if (userUseArray.length == 0) {
        pushError(intlMessages.userInfoModalUse);
        localErrorList.push(intlMessages.userInfoModalUse);
      }
    } else if (userRolesArray === intlMessages.userInfoModalRole5) {
      if (userUseArray.length == 0) {
        pushError(intlMessages.userInfoModalUse);
        localErrorList.push(intlMessages.userInfoModalUse);
      }
    }
    if (userStateArray.length === 0) {
      //show error
      pushError(intlMessages.userInfoModalState);
      localErrorList.push(intlMessages.userInfoModalState);
    }
    if (localErrorList.length > 0) {
      return true;
    } else return false;
  };
  return (
    <>
      <Modal
        open={openUserInformationModal}
        aria-labelledby="review-modal"
        aria-describedby="review-description"
      >
        <Styled.ModalPrmissionSignInBox sx={Styled.customStyling.exportModal}>
          <Styled.ModalHeader>
            <Typography
              id="review-modal-title"
              variant="h5"
              component="h5"
              style={{ fontWeight: "bold" }}
            >
              {intlMessages.userInfoModalTitle}
            </Typography>
            <IconButton
              aria-label="closeReviewModal"
              onClick={() => {
                isFirstTime ? updateUserInfo(1) : closeModal();
              }}
            >
              <CloseIcon color="seondary.light" />
            </IconButton>
          </Styled.ModalHeader>
          <Styled.ModalContentBox>
            <Styled.ContainerBox
              isMobile={
                isMobile || isMobileLandscape || isIpad || isIpadLandscape
              }
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "start",
                  justifyContent: "flex-start",
                  // width: isMobile || isMobileLandscape || isIpad ? "100%" : "",
                }}
                onClick={() => {}}
              >
                <Styled.TextTypography>
                  {intlMessages.userInfoModalRole}
                </Styled.TextTypography>
                <SelectMenu
                  width={
                    isMobile || isMobileLandscape || isIpad || isIpadLandscape
                      ? "100%"
                      : "410px"
                  }
                  setQuizSettings={setUserRolesArraySelector}
                  listTitle={intlMessages.userInforModalRoleTitle}
                  // userInfoArray={userRolesArray}
                  list={[
                    intlMessages.userInfoModalRole1,
                    intlMessages.userInfoModalRole2,
                    intlMessages.userInfoModalRole3,
                    intlMessages.userInfoModalRole4,
                    intlMessages.userInfoModalRole5,
                  ]}
                  isUserInfoDropdown={true}
                  defaultValue={userRolesArray}
                  removeError={removeError}
                  setDefaultStates={setDefaultStates}
                  isMobile={
                    isMobile || isMobileLandscape || isIpad || isIpadLandscape
                  }
                ></SelectMenu>
              </Box>
              <Box
                sx={{
                  display: userRolesArray.length < 1 ? "none" : "",
                  width: "100%",
                }}
              >
                <Styled.BoxComponent
                  sx={{
                    display:
                      userRolesArray === intlMessages.userInfoModalRole3 ||
                      userRolesArray === intlMessages.userInfoModalRole4 ||
                      userRolesArray === intlMessages.userInfoModalRole5
                        ? "none"
                        : "",
                  }}
                >
                  <Styled.TextTypography>
                    {intlMessages.userInfoModalGrade}
                  </Styled.TextTypography>
                  <SelectMenu
                    width={
                      isMobile || isMobileLandscape || isIpad || isIpadLandscape
                        ? "100%"
                        : "410px"
                    }
                    setQuizSettings={setUserGradeArraySelector}
                    listTitle={intlMessages.userInfoModalGrade}
                    userInfoArray={userGradeArray}
                    list={[
                      intlMessages.userInfoModalGrade1,
                      intlMessages.userInfoModalGrade2,
                      intlMessages.userInfoModalGrade3,
                      intlMessages.userInfoModalGrade4,
                      intlMessages.userInfoModalGrade5,
                      intlMessages.userInfoModalGrade6,
                      intlMessages.userInfoModalGrade7,
                      intlMessages.userInfoModalGrade8,
                      intlMessages.userInfoModalGrade9,
                      intlMessages.userInfoModalGrade10,
                      intlMessages.userInfoModalGrade11,
                      intlMessages.userInfoModalGrade12,
                      intlMessages.userInfoModalGrade13,
                      intlMessages.userInfoModalGrade14,
                    ]}
                    isUserInfoProps={true}
                    isError={errorsList.includes(
                      intlMessages.userInfoModalGrade
                    )}
                    removeError={removeError}
                  ></SelectMenu>
                </Styled.BoxComponent>
                <Styled.BoxComponent
                  sx={{
                    display:
                      userRolesArray === intlMessages.userInfoModalRole3 ||
                      userRolesArray === intlMessages.userInfoModalRole4 ||
                      userRolesArray === intlMessages.userInfoModalRole5
                        ? "none"
                        : "",
                  }}
                >
                  <Styled.TextTypography>
                    {intlMessages.userInfoModalSubject}
                  </Styled.TextTypography>
                  <SelectMenu
                    width={
                      isMobile || isMobileLandscape || isIpad || isIpadLandscape
                        ? "100%"
                        : "410px"
                    }
                    setQuizSettings={setUserSubjectArraySelector}
                    listTitle={intlMessages.userInfoModalSubject}
                    userInfoArray={userSubjectArray}
                    list={[
                      intlMessages.userInfoModalSubject1,
                      intlMessages.userInfoModalSubject2,
                      intlMessages.userInfoModalSubject3,
                      intlMessages.userInfoModalSubject4,
                      intlMessages.userInfoModalSubject5,
                      intlMessages.userInfoModalSubject6,
                    ]}
                    isUserInfoProps={true}
                    openUpWard={true}
                    isError={errorsList.includes(
                      intlMessages.userInfoModalSubject
                    )}
                    removeError={removeError}
                  ></SelectMenu>
                </Styled.BoxComponent>

                <Styled.BoxComponent
                  sx={{
                    display:
                      userRolesArray !== intlMessages.userInfoModalRole3
                        ? "none"
                        : "",
                  }}
                >
                  <Styled.TextTypography>
                    {intlMessages.schoolName}
                  </Styled.TextTypography>
                  <Styled.ModalTextField
                    value={schoolName}
                    onChange={onChangeSchoolname}
                    placeholder={`${intlMessages.enterSchoolName}`}
                    type="email"
                    required
                    helperText={schoolError}
                    error={
                      errorsList.includes(intlMessages.schoolName)
                        ? true
                        : false
                    }
                    sx={{
                      width:
                        isMobile ||
                        isMobileLandscape ||
                        isIpad ||
                        isIpadLandscape
                          ? "100%"
                          : "410px",
                    }}
                    // ref={textFieldRef}
                    onKeyDown={handleKeyDown}
                  />
                </Styled.BoxComponent>

                <Styled.BoxComponent
                  sx={{
                    display:
                      userRolesArray !== intlMessages.userInfoModalRole4
                        ? "none"
                        : "",
                  }}
                >
                  <Styled.TextTypography>
                    {intlMessages.orgName}
                  </Styled.TextTypography>
                  <Styled.ModalTextField
                    value={orgName}
                    onChange={onChangeOrgname}
                    placeholder={`${intlMessages.orgNamePlaceday}`}
                    type="email"
                    required
                    helperText={orgError}
                    error={
                      errorsList.includes(intlMessages.orgName) ? true : false
                    }
                    sx={{
                      width:
                        isMobile ||
                        isMobileLandscape ||
                        isIpad ||
                        isIpadLandscape
                          ? "100%"
                          : "410px",
                    }}
                    // ref={textFieldRef}
                    onKeyDown={handleKeyDown}
                  />
                </Styled.BoxComponent>

                <Styled.BoxComponent
                  sx={{
                    display:
                      userRolesArray === intlMessages.userInfoModalRole4 ||
                      userRolesArray === intlMessages.userInfoModalRole5
                        ? ""
                        : "none",
                  }}
                >
                  <Styled.TextTypography>
                    {intlMessages.userInfoModalUse}
                  </Styled.TextTypography>
                  <SelectMenu
                    width={
                      isMobile || isMobileLandscape || isIpad || isIpadLandscape
                        ? "100%"
                        : "410px"
                    }
                    setQuizSettings={setUserUseArraySelector}
                    listTitle={intlMessages.userInfoModalUseTitle}
                    // userInfoArray={userUseArray}
                    list={[
                      intlMessages.userInfoModalUse1,
                      intlMessages.userInfoModalUse2,
                      intlMessages.userInfoModalUse3,
                      intlMessages.userInfoModalUse4,
                    ]}
                    openUpWard={true}
                    isUserInfoDropdown={true}
                    defaultValue={userUseArray}
                    isError={errorsList.includes(intlMessages.userInfoModalUse)}
                    removeError={removeError}
                  ></SelectMenu>
                </Styled.BoxComponent>

                <Styled.BoxComponent>
                  <Styled.TextTypography>
                    {intlMessages.userInfoModalState}
                  </Styled.TextTypography>
                  <SelectMenu
                    width={
                      isMobile || isMobileLandscape || isIpad || isIpadLandscape
                        ? "100%"
                        : "410px"
                    }
                    setQuizSettings={setUserStateArraySelector}
                    listTitle={intlMessages.userInfoModalState0}
                    // userInfoArray={userStateArray}
                    list={STATES_ARRAY}
                    openUpWard={true}
                    isUserInfoDropdown={true}
                    defaultValue={userStateArray}
                    isError={errorsList.includes(
                      intlMessages.userInfoModalState
                    )}
                    removeError={removeError}
                  ></SelectMenu>
                </Styled.BoxComponent>
              </Box>
              {errorsList.length > 0 && (
                <FormHelperText error sx={{}}>
                  {intlMessages.SignUpEmptyFieldsError}
                </FormHelperText>
              )}
            </Styled.ContainerBox>
          </Styled.ModalContentBox>
          <Styled.ModalActions>
            <Styled.SubmitButton
              isEditModal={isEditModal}
              disabled={userRolesArray.length < 1 ? true : false}
              variant="contained"
              color="secondary"
              onClick={() => {
                updateUserInfo(2);
              }}
              sx={{
                opacity: isSaving ? 0.5 : 1,
                pointerEvents: isSaving ? "none" : "auto",
              }}
            >
              {isEditModal
                ? intlMessages.profileSaveBtnText
                : intlMessages.loginContinueBtnText}
            </Styled.SubmitButton>
            <Button
              sx={{
                textTransform: "none",
                marginLeft: 2,
                width: "105px",
                opacity: isSaving ? 0.5 : 1,
                pointerEvents: isSaving ? "none" : "auto",
              }}
              variant="outlined"
              color="secondary"
              onClick={() => {
                isFirstTime ? updateUserInfo(1) : closeModal();
              }}
              // disabled={userRolesArray.length < 1 ? true : false}
            >
              {intlMessages.cancelLabel}
            </Button>
          </Styled.ModalActions>
        </Styled.ModalPrmissionSignInBox>
      </Modal>
    </>
  );
};

export default UserInformation;
