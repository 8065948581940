import { configureStore } from "@reduxjs/toolkit";
import quizReducer from "./slices/quizSlice";
import signUpReducer from "./slices/signUpSlice";
import loginReducer from "./slices/logInSlice";
import libraryReducer from "./slices/librarySlice";
import guruReducer from "./slices/guruSlice";
import featureReducer from "./slices/featuresSlice"
import paymentReducer from "./slices/paymentSlice"
import { DISABLE_DEV_TOOLS } from "../settings";

const reducer = {
  quiz: quizReducer,
  signUp: signUpReducer,
  logIn: loginReducer,
  library: libraryReducer,
  guru: guruReducer,
  feature: featureReducer,
  payment: paymentReducer
};

export const store = configureStore({
  reducer: reducer,
  devTools:  DISABLE_DEV_TOOLS === true?true:false,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});
