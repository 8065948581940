import { useState, useEffect, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Footer from "../footer/component";
import intlMessages from "../../locales/en";
import { useTheme } from "@mui/material/styles";
import Styled from "./styles";
import ConnectionModal from "../connection-modal/component";
import { resendEmailAddress } from "./service";
import {
  decodeToken,
  getToken,
  userLogOut,
} from "../../common/sign_in_service";
import { Box, useMediaQuery } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { BounceLoader } from "react-spinners";
import { USER_STATE } from "../../settings";
import CircularProgress from "@mui/material/CircularProgress";
import { iOS, isMobileDevice} from "../quiz/service";
import { getNavigateURL } from "../../util/signin-user-data";
import { setIsVerifyPage } from "../../redux/slices/logInSlice";

function VerifyEmail() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"), {
    noSsr: true,
  });
  const isMobileLandscape = useMediaQuery(
    `${theme.breakpoints.down(
      "md"
    )}  and (orientation: landscape) and (hover: none) and (pointer: coarse)`,
    {
      noSsr: true,
    }
  );

  const isIpad = useMediaQuery(
    window.navigator.userAgent.includes("OneScreen_TL7")
      ? "(min-width:620px) and (max-width:960px) and (orientation: landscape) "
      : "(min-width:620px) and (max-width:840px) and (orientation: portrait) and  (hover: none) and (pointer: coarse)",
    {
      noSsr: true,
    }
  );
  const isIpadLandscape = useMediaQuery(
    "(min-width: 768px) and (max-width: 1024px) and (orientation: landscape) and  (hover: none) and (pointer: coarse)",
    {
      noSsr: true,
    }
  );
  const { isLoading } = useSelector((state) => state.quiz);
  const [isSaving, setIsSaving] = useState(false);
  const [userEmail, setUserEmail] = useState("");
  const navigate = useNavigate();
  const [mainBoxHeight, setMainBoxHeight] = useState(0);
  const verificationRef = useRef();
  const [openConnectionModal, setIsOpenConnectionModal] = useState(false);
  const [error, setErrorMsg] = useState(false);
  const location = useLocation();
  const { userState } = useSelector((state) => state.logIn);
  const dispatch = useDispatch()

  useEffect(() => {
    const navigateToSignUp = async () => {
      const result = await getUserEmail();
      if (!result) {
        const signupURL = getNavigateURL("/signup")
        navigate(signupURL);
      }
    };
    navigateToSignUp();
  }, []);

  async function handleOrientationChange() {
    const screenStats = window.screen;
    let mainBoxHeight_;
    switch (true) {
      case (navigator.standalone === "boolean" ||
        navigator.userAgent.match(/iPhone/i) ||
        navigator.userAgent.match(/iPod/i)) &&
        iOS() &&
        window.matchMedia(
          "screen and (max-device-height: 900px) and (orientation: landscape)"
        ).matches:
        mainBoxHeight_ = 160;
        break;
      case window.matchMedia(
        "(min-width: 800px) and (max-width: 1400px) and (orientation: portrait) and (hover: none) and (pointer: coarse)"
      ).matches:
        mainBoxHeight_ = screenStats.availHeight * 0.74; // iPad Portrait
        break;
      case screenStats.availHeight > 600 && screenStats.availHeight <= 700 && window.matchMedia('(pointer: coarse)').matches:     
        mainBoxHeight_ = screenStats.availHeight; // Small mobiles
        break;
      case screenStats.availHeight > 650 && screenStats.availHeight <= 700:
        mainBoxHeight_ = screenStats.availHeight - 260;
        break;
      case screenStats.availHeight > 700 &&
        screenStats.availHeight < 750 &&
        window.matchMedia("(pointer: fine)").matches &&
        window.screen.width < 1100:
        mainBoxHeight_ = screenStats.availHeight - 275; // Mobile landscape
        break;
      case screenStats.availWidth > 750 && screenStats.availWidth <= 960 && screenStats.availHeight < 930 : //mobile Landscape
      if(window.innerHeight>350){
        mainBoxHeight_ = screenStats.availHeight-200 ;
      }
      else{
        mainBoxHeight_ = screenStats.availHeight-250
      }
        break;
      case screenStats.availHeight > 800 && window.matchMedia('(orientation: portrait)').matches && window.matchMedia('(pointer: coarse)').matches:
        mainBoxHeight_ = screenStats.availHeight - 450 // iPad
        break;
      case screenStats.availHeight === 720 && screenStats.availWidth === 1280:
        var headerHeight = document.getElementById('header').offsetHeight;
        var footerHeight = document.getElementById('footer2').offsetHeight;
        var headerFooterSum = headerHeight + footerHeight;
        var fullPageHeight = window.innerHeight;
        var contentHeight = fullPageHeight - headerFooterSum;
        mainBoxHeight_ = contentHeight // TL
        break;
      case screenStats.availWidth >= 1920 && screenStats.height > 1000:
        var headerHeight = document.getElementById('header').offsetHeight;
      var footerHeight = document.getElementById('footer2').offsetHeight;
      var headerFooterSum = headerHeight + footerHeight;
      var fullPageHeight = window.innerHeight;
      var contentHeight = fullPageHeight - headerFooterSum;
      mainBoxHeight_ = contentHeight // Desktop
        break;
     case window.matchMedia('(min-width: 800px) and (max-width: 1400px) and (orientation: landscape) and (hover: none) and (pointer: coarse)').matches:
      mainBoxHeight_ = screenStats.availHeight - 535; // iPad Landscape
        if(mainBoxHeight_ < 400) 
          mainBoxHeight_ = 430;
      break;
     case screenStats.height > 800:
      var headerHeight = document.getElementById('header').offsetHeight;
      var footerHeight = document.getElementById('footer2').offsetHeight;
      var headerFooterSum = headerHeight + footerHeight;
      var fullPageHeight = window.innerHeight;
      var contentHeight = fullPageHeight - headerFooterSum;
      mainBoxHeight_ = contentHeight // Desktop
        break;
     default:
        mainBoxHeight_ = screenStats.availHeight - 260;
        if(mainBoxHeight_ < 200) 
           mainBoxHeight_ = 173;
           break;   
    }
    const height = window.innerHeight-200
    var viewport = document.querySelector("meta[name=viewport]");
    isMobileDevice() && !iOS() && (window.screen.orientation.type==="landscape-primary" || window.screen.orientation.type==="landscape-secondary" )?viewport.setAttribute("content", viewport.content + ", height=" + mainBoxHeight_):
    viewport.setAttribute("content", viewport.content + ", height=" + height);
    setMainBoxHeight(mainBoxHeight_)
  }

  useEffect(() => {
    handleOrientationChange();
    window.addEventListener("resize", handleOrientationChange);
    return () => {
      window.removeEventListener("resize", handleOrientationChange);
    };
  }, []);

  // useEffect(()=>{
  //   let timer
  //   const isUserVerified = ()=>{
  //     timer = setTimeout(async() => {

  //      const token = getToken()
  //      const userData = await getUserInformation(token)
  //      if(userData && userData?.status === 200 ){
  //        navigate('/main');
  //      }
  //      else{

  //        isUserVerified()
  //      }
  //    }, 1500);
  //  }
  //    isUserVerified()
  //    return ()=>{
  //     clearTimeout(timer)
  //    }
  // },[])

  const getQueryString = () => {
    const { search } = location;
    const queryParams = new URLSearchParams(search);
    if (queryParams.get("error") === "1") {
      setErrorMsg(true);
    } else {
      setErrorMsg(false);
    }
  };

  useEffect(() => {
    dispatch(setIsVerifyPage(true))
    getQueryString();
  }, []);

  const getUserEmail = async () => {
    const token = getToken();
    if (!!token) {
      const user = await decodeToken(token);
      setUserEmail(user?.username);
      return true;
    }
    return false;
  };

  const resendEmail = () => {
    if(error){
      navigate("/verify", { replace: true });
    }
    setErrorMsg(false);
    setIsSaving(true);
    resendEmailAddress(userEmail)
      .then((res) => {
        if (res?.data?.statusCode === 0) {
          setIsOpenConnectionModal(true);
          verificationRef.current.style.display = "none";
          setIsSaving(false);
          return;
        }
        if (
          res?.response?.data?.statusCode === 400 &&
          res?.response?.data?.message === "User not found."
        ) {
          setIsSaving(false);
          verificationRef.current.style.display = "block";
          verificationRef.current.style.color = "red";
          verificationRef.current.innerText =
            intlMessages.verifyEmailNoUserFoundText;
        }
        if (res?.status === 200 && res?.data?.message === "success") {
          setIsSaving(false);
          verificationRef.current.style.display = "block";
          verificationRef.current.style.color = "#7DC242";
          verificationRef.current.innerText =
            intlMessages.verifyEmailConfirmationMessage;
        }
        setTimeout(() => {
          verificationRef.current.style.display = "none";
        }, 1500);
      })
      .catch((error) => {
        verificationRef.current.style.display = "none";
        setIsSaving(false);
      });
  };

  const logOut = () => {
    userLogOut();
    const loginURL = getNavigateURL("/login")
    navigate(loginURL);
  };

  useEffect(() => {
    if (userState === USER_STATE.SIGNED_IN_USER) {
      const quizURL = getNavigateURL('/')
      navigate(quizURL);
    }
  }, [userState]);

  return (
    <>
      {isLoading ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            pt: isMobile?'50%': isIpad?"43%":"15%",
            height: `${mainBoxHeight - 40}px`,
          }}
        >
          <BounceLoader color="#6dd615" size={"150px"} />
        </Box>
      ) : (
        <Styled.MainContainer
          height={mainBoxHeight}
          isMobile={isMobile ? true : false}
          isMobileLandscape={isMobileLandscape ? true : false}
        >
          <Styled.verifyText
            isMobile={isMobile ? true : false}
            isMobileLandscape={isMobileLandscape ? true : false}
          >
            {intlMessages.verifyEmailText1}
          </Styled.verifyText>
          <Styled.verifyTextDesc
            isMobile={isMobile ? true : false}
            isMobileLandscape={isMobileLandscape ? true : false}
          >
            {intlMessages.verifyEmailText2}
          </Styled.verifyTextDesc>
          <Styled.accountVerificationText
            isMobile={isMobile ? true : false}
            isMobileLandscape={isMobileLandscape ? true : false}
            ref={verificationRef}
          >
            {intlMessages.verifyEmailConfirmationMessage}
          </Styled.accountVerificationText>
          <Styled.resendBtn
            isMobile={isMobile ? true : false}
            isMobileLandscape={isMobileLandscape ? true : false}
            variant="contained"
            loading={isSaving}
            loadingPosition="start"
            disabled={isSaving ? true : false}
            loadingIndicator={<CircularProgress color="inherit" size={16} />}
            onClick={resendEmail}
          >
            {intlMessages.verifyEmailResendBtnText}
          </Styled.resendBtn>
          <Styled.logOutBtn
            isMobile={isMobile ? true : false}
            isMobileLandscape={isMobileLandscape ? true : false}
            onClick={logOut}
          >
            {intlMessages.verifyEmailLogOutBtnText}
          </Styled.logOutBtn>
          {error && (
            <Styled.tokenExpiredText
              isMobile={isMobile ? true : false}
              isMobileLandscape={isMobileLandscape ? true : false}
            >
              {intlMessages.verifyEmailTokenExpiredMsg}
            </Styled.tokenExpiredText>
          )}
        </Styled.MainContainer>
      )}
      <Box>
        <Footer></Footer>
      </Box>
      {openConnectionModal && (
        <ConnectionModal
          isOpen={openConnectionModal}
          handleCloseConnectionModal={() => setIsOpenConnectionModal(false)}
        />
      )}
    </>
  );
}

export default VerifyEmail;
