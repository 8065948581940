import api from "../index";
import { checkInternetConnection } from "../service";

async function downgradePlan(duration, email, reasons, immediateDowngrade) {
  try {
    const payload = {
      planDuration: duration,
      email: email,
      reasons: reasons,
      immediateDowngrade: immediateDowngrade,
    };
    const connectionStatus = await checkInternetConnection();
    const isInternetConnected = connectionStatus.isConnected;
    if (!isInternetConnected) return connectionStatus;
    const res = await api.post(`/user/downgrade/subscription`, payload);
    return res;
  } catch (e) {
    return e;
  }
}

async function getBillingInvoice(chargeId, invoiceId) {
  try {
    const params = {
      chargeId: chargeId,
      invoiceId: invoiceId,
    };
    const connectionStatus = await checkInternetConnection();
    const isInternetConnected = connectionStatus.isConnected;
    if (!isInternetConnected) return connectionStatus;
    const res = await api.get(`/user/invoice`, { params });
    return res;
  } catch (e) {
    return e;
  }
}

async function getBillingHistory(currentPage, size) {
  try {
    const params = {
      page: currentPage,
      pageSize: size,
    };
    const connectionStatus = await checkInternetConnection();
    const isInternetConnected = connectionStatus.isConnected;
    if (!isInternetConnected) return connectionStatus;
    const res = await api.get(`/user/billing/history`, { params });
    return res;
  } catch (e) {
    return e;
  }
}

const getCheckoutUrl = async (packageType,isQuizContentExists) => {
  try {
    const params = {
      plan: packageType,
      pageState:isQuizContentExists
    };
    const connectionStatus = await checkInternetConnection();
    const isInternetConnected = connectionStatus.isConnected;
    if (!isInternetConnected) return connectionStatus;
    const res = await api.get(`/user/createcheckout/session`, { params });
    return res;
  } catch (e) {
    return e;
  }
};

async function cancelDowngradeRequest() {
  try {
    const connectionStatus = await checkInternetConnection();
    const isInternetConnected = connectionStatus.isConnected;
    if (!isInternetConnected) return connectionStatus;
    const res = await api.post(`/user/cancel/downgrade`);
    return res;
  } catch (e) {
    return e;
  }
}

async function verifySerialNumber(serialNumber) {
  try {
    const payload = {
      serialNumber: serialNumber,
    };
    const connectionStatus = await checkInternetConnection();
    const isInternetConnected = connectionStatus.isConnected;
    if (!isInternetConnected) return connectionStatus;
    const res = await api.post(`/user/verify/serialnumber`, payload);
    return res;
  } catch (e) {
    return e;
  }
}

const getPriceDetails = async () => {
  try {
    const connectionStatus = await checkInternetConnection();
    const isInternetConnected = connectionStatus.isConnected;
    if (!isInternetConnected) return connectionStatus;
    const res = await api.get(`/user/price/details`);
    return res;
  } catch (e) {
    return e;
  }
};

export {
  downgradePlan,
  getBillingHistory,
  getBillingInvoice,
  getCheckoutUrl,
  cancelDowngradeRequest,
  verifySerialNumber,
  getPriceDetails
};
