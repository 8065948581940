import styled from "@mui/system/styled";
import Box from "@mui/material/Box";
import { Button, Divider, Icon, Typography } from "@mui/material";

const ModalHeader = styled(Box)({
  padding: 8,
  paddingRight: 12,
  paddingLeft: 25,
  alignItems: "center",
  display: "flex",
  flexDirection: "row",
  justifyContent: "flex-end",
});

const ModalActions = styled(Box)({
  padding: 5,
  paddingTop: 15,
  paddingBottom: 15,
  borderTop: "1px solid silver",
  display: "flex",
  justifyContent: "center",
});

const ModalBox = styled(Box)(({ theme }) => ({
  width: "65%",

  [theme.breakpoints.down("lg")]: {
    width: "97%",
  },
  [theme.breakpoints.down("sm")]: {
    width: "95%",
  },
}));

const StyledTrialHeading = styled(Typography)(({ theme,isMobile,isMobileLandscape,isIpad,isIpadLandscape }) => ({
  fontFamily: "Open Sans",
  fontWeight: 600,
  fontSize: isMobile || isMobileLandscape?"24px":"32px",
  lineHeight: isMobile || isMobileLandscape?"32.68px":"43.58px",
  textAlign: "center",
  marginBottom: "15px",
  width:isMobile?'290px':'unset'
}));

const StyledTrialDesc = styled(Typography)(({ theme,isMobile,isMobileLandscape,isIpad,isIpadLandscape }) => ({
  fontFamily: "Open Sans",
  fontWeight: 400,
  fontSize: isMobile || isMobileLandscape?"14px":"17px",
  lineHeight: isMobile || isMobileLandscape?"19.07px":"23.15px",
  textAlign: "center",
  marginBottom: "15px",
  width:isMobile?'290px':'unset'
}));

const TrialBtn = styled(Button)(({theme,isMobile,isMobileLandscape,isIpad,isIpadLandscape})=>({
  color: "white",
  textTransform: "none",
  height: isMobile || isMobileLandscape?"37px":"43px",
  width: isMobile || isMobileLandscape?"141px":"165px",
  marginBottom: isMobile || isMobileLandscape?"15px":"25px",
  fontSize: isMobile || isMobileLandscape?"17px !important":"unset",
  lineHeight:isMobile || isMobileLandscape?"23.15px !important":"unset",
  backgroundColor: "#7DC242",
  "&:hover": {
    backgroundColor: "#89d44a",
  },
}));

const StyledHeading = styled(Typography)(({ theme }) => ({
  fontFamily: "Open Sans",
  fontWeight: 600,
  fontSize: "24px",
  lineHeight: "20px",
  textAlign: "center",
}));

const StyledDesc = styled(Typography)(({ theme }) => ({
  fontFamily: "Open Sans",
  fontWeight: 400,
  fontSize: "18px",
  lineHeight: "20px",
  textAlign: "center",
}));

const ModalContentBox = styled(Box)({
  overflowY: "auto",
  flex: 1,
});

const StyledDivider = styled(Divider)(({theme,isMobile,isMobileLandscape,isIpad,isIpadLandscape}) => ({
  width: isMobileLandscape?"85%":"90%",
  marginBottom: isMobile || isMobileLandscape?"15px":"25px",
  color: "#333333",
}));

const StyledBtnContainer = styled(Box)(({theme,isMobile,isMobileLandscape,isIpad,isIpadLandscape})=>({
  display: "flex",
  flexDirection: isMobile || isMobileLandscape?"column":"row",
  justifyContent: "space-between",
  width: isMobile ?"290px":isMobileLandscape?"85%":"90%",
  marginBottom: "25px",
}));

const StyledDurationContainer = styled(Box)(({theme,isMobile,isMobileLandscape,isIpad,isIpadLandscape,proTabSelected})=>({
  display: proTabSelected && (isMobile || isMobileLandscape)?"flex":"none",
  flexDirection: "row",
  alignSelf:isMobile || isMobileLandscape?"flex-end":'unset',
  width: "139px",
  height: "25px", 
  marginTop: isMobile || isMobileLandscape?"8px":'-10px'
}));

const StyledYearBtn = styled(Button)(({ theme, isMonthSelected }) => ({
  color: isMonthSelected ? "#7DC242" : "white",
  borderRadius: "5px 0px 0px 5px",
  textTransform: "none",
  height: "25px",
  width: "62px",
  backgroundColor: isMonthSelected ? "white" : "#7DC242",
  border: isMonthSelected ? "1px solid #7DC242" : "unset",
  "&:hover": {
    backgroundColor: isMonthSelected ? "#F5F5F5" : "#89d44a",
  },
}));

const StyledMonthBtn = styled(Button)(({ theme, isMonthSelected }) => ({
  color: isMonthSelected ? "white" : "#7DC242",
  backgroundColor: isMonthSelected ? "#7DC242" : "white",
  border: isMonthSelected ? "unset" : "1px solid #7DC242",
  borderRadius: "0px 5px 5px 0px",
  textTransform: "none",
  height: "25px",
  width: "77px",
  "&:hover": {
    backgroundColor: isMonthSelected ? "#89d44a" : "	#F5F5F5",
  },
}));

const StyledCardContainer = styled(Box)(({ theme,isMobile,isMobileLandscape,isIpad,isIpadLandscape }) =>({
  display: "flex",
  flexDirection: "row",
  marginBottom: "25px",
  alignItems: "center",
  justifyContent:isMobile || isMobileLandscape?"center":"space-between",
  width: isMobile?"310px":isMobileLandscape?"95%":"100%",
  paddingLeft: isMobile || isMobileLandscape?"unset":"24px",
  paddingRight: isMobile || isMobileLandscape?"unset":"24px",
}));

const StyledCard = styled(Box)(({ theme,isMobile,isMobileLandscape,isIpad,isIpadLandscape,proTabSelected }) => ({
  display: "flex",
  flexDirection: "column",
  width: isMobile?"95%":isMobileLandscape?"90%":isIpad?"49%":"48%",
  height: isMobile?"420px":isMobileLandscape?"380px":isIpad?"380px":"357px",
  border: "1px solid #00000033",
  borderRadius: "10px",
  justifyContent: "center",
  paddingLeft:isMobile?"7%":"5%",
  borderColor: proTabSelected===true?"#7D00BA":"#00000033"
}));

const NavButtonContainer = styled(Box)(({ theme,isMobile,isMobileLandscape,isIpad,isIpadLandscape }) => ({
  display:'flex',
  flexDirection:'row',
  alignItems:'center',
  marginTop:'20px',
  marginBottom:'15px'
}));

const NavButton = styled(Box)(({ theme,isSelected,isMobile,isMobileLandscape,isIpad,isIpadLandscape }) => ({
  width:isMobileLandscape?'45%':'123px',
  height:'30px',
  borderBottom:'1px solid',
  borderColor:isSelected===true?'#7DC242':'#333333E5',
  color: isSelected===true?'#7DC242':'#333333E5',
  cursor:'pointer',
  textAlign:'center'
}));

const StyledSavingText = styled(Typography)(({ theme,isMobile,isMobileLandscape,isIpad,isIpadLandscape }) => ({
  fontFamily: "Open Sans",
  fontWeight: 600,
  fontSize: isMobile|| isMobileLandscape?"14px":"16px",
  lineHeight: isMobile || isMobileLandscape?"19.07px":"21.79px",
  color: "#7DC242",
  margin:'auto'
}));

const StyledFeatureBreakdownText = styled(Typography)(
  ({ theme, showFeatures,isMobile,isMobileLandscape,isIpad,isIpadLandscape  }) => ({
    fontFamily: "Open Sans",
    fontWeight: 600,
    fontSize: isMobile || isMobileLandscape?"14px":"17px",
    lineHeight: isMobile || isMobileLandscape?"19.07px":"23.15px",
    color: "#2062BB",
    marginBottom: showFeatures === true ? "10px" : "50px",
  })
);

const StyledFeatureBreakdownDesc = styled(Typography)(
  ({ theme, showFeatures,isMobile,isMobileLandscape,isIpad,isIpadLandscape }) => ({
    fontFamily: "Open Sans",
    fontWeight: 600,
    fontSize: isMobile || isMobileLandscape?"18px":"28px",
    lineHeight: isMobile || isMobileLandscape?"24.51px":"38.13px",
    color: "#333333",
    marginBottom: "20px",
    display: showFeatures == true ? "block" : "none",
  })
);

const StyledFeatures = styled("img")(({ theme, showFeatures,isMobile,isMobileLandscape,isIpad,isIpadLandscape  }) => ({
  width: "100%",
  marginBottom: "10px",
  marginLeft: isMobile?"3%":"unset",
  display: showFeatures == true ? "block" : "none",
}));

const DropDownIcon = styled("img")(({ theme, showFeatures,isMobile,isMobileLandscape,isIpad,isIpadLandscape }) => ({
  marginTop: showFeatures === true ? "-10px" : "-50px",
  marginLeft: "10px",
  width: isMobile || isMobileLandscape?'12px':'unset'
}));

const PlanHeading = styled(Typography)(({ theme }) => ({
  fontFamily: "Open Sans",
  fontWeight: 600,
  fontSize: "17px",
  lineHeight: "23.15px",
  color: "#333333",
}));

const Price = styled(Typography)(({ theme }) => ({
  fontFamily: "Open Sans",
  fontWeight: 700,
  fontSize: "28px",
  lineHeight: "38.13px",
  color: "#333333",
}));

const PriceSubscript = styled("span")(({ theme }) => ({
  fontFamily: "Open Sans",
  fontWeight: 400,
  fontSize: "14px",
  lineHeight: "19.07px",
  color: "#333333",
  marginLeft: "5px",
}));

const PriceDesc = styled(Typography)(({ theme,isMobile,isMobileLandscape,isIpad,isIpadLandscape }) => ({
  fontFamily: "Open Sans",
  fontWeight: 400,
  fontSize: isMobile|| isMobileLandscape?"12px":"14px",
  lineHeight: "19.07px",
  color: "#777777",
  marginBottom: "15px",
}));

const FeatureContainer = styled(Box)(({ theme,isMobile,isMobileLandscape,isIpad,isIpadLandscape }) => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: isMobile?"flex-start":"space-between",
  width: isMobileLandscape?"95%":isMobile?"90%":"80%",
  borderBottom: "1px solid #00000033",
  marginBottom: "10px",
}));

const DropdownContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  cursor: "pointer",
}));

const Feature = styled(Typography)(({ theme }) => ({
  fontFamily: "Open Sans",
  fontWeight: 400,
  fontSize: "12px",
  lineHeight: "16.34px",
  color: "#333333",
  marginBottom: "5px",
}));

const FreePlanBtn = styled(Button)(({ theme,isMobile,isMobileLandscape,isIpad,isIpadLandscape,proTabSelected }) =>({
  color: proTabSelected===true?"white":"#7DC242",
  marginLeft: isMobileLandscape?"35%":"25%",
  textTransform: "none",
  height: isMobile || isMobileLandscape?"33px":"43px",
  width: isMobile || isMobileLandscape?"108px":"165px",
  borderRadius: "5px",
  marginTop: "15px",
  border: proTabSelected===true?"unset":"1px solid #7DC242",
  backgroundColor: proTabSelected===true?"#7DC242":"white",
  fontSize:isMobile || isMobileLandscape?"14px !important":"unset",
  lineHeight:isMobile || isMobileLandscape?"19.07px !important":"unset",
}));

const ProPlanBtn = styled(Button)(({ theme,isMobile,isMobileLandscape,isIpad,isIpadLandscape }) =>({
  color: "white",
  marginLeft: "25%",
  textTransform: "none",
  height: isMobile || isMobileLandscape?"33px":"43px",
  width: isMobile || isMobileLandscape?"108px":"165px",
  fontSize:isMobile || isMobileLandscape?"14px !important":"unset",
  lineHeight:isMobile || isMobileLandscape?"19.07px !important":"unset",
  borderRadius: "5px",
  marginTop: "15px",
  backgroundColor: "#7DC242",
  "&:hover": {
    backgroundColor: "#89d44a",
  },
}));

const PopularBox = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
}));

const PopularText = styled(Typography)(({ theme,isMobile,isMobileLandscape,isIpad,isIpadLandscape,proTabSelected }) => ({
  fontFamily: "Open Sans",
  fontWeight: 600,
  fontSize: "12px",
  lineHeight: "16.34px",
  color: "white",
  backgroundColor: "#7D00BA",
  width: "75px",
  height: "20px",
  paddingTop: "2px",
  textAlign: "center",
  textTransform: "uppercase",
  borderTopLeftRadius: "4px",
  borderBottomLeftRadius: "4px",
  display:proTabSelected===true?"block":'none'
}));

const customStyling = () => ({
  exportModal: {
    position: "absolute",
    top: { xs: "50%", sm: "50%" },
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    borderRadius: "10px",
    boxShadow: 24,
    maxHeight: "90%",
    border: "none",
    overflow: "hidden",
    display: "flex",
    flexDirection: "column",
    fontSmooth: 'always',
    WebkitFontSmoothing: 'antialiased',
    MozOsxFontSmoothing: 'grayscale',
  },
});

export default {
  customStyling,
  ModalHeader,
  ModalActions,
  ModalBox,
  ModalContentBox,
  StyledHeading,
  TrialBtn,
  StyledBtnContainer,
  StyledDurationContainer,
  StyledCardContainer,
  StyledDesc,
  StyledDivider,
  StyledSavingText,
  StyledYearBtn,
  StyledMonthBtn,
  StyledCard,
  StyledFeatureBreakdownDesc,
  StyledFeatureBreakdownText,
  StyledTrialHeading,
  StyledTrialDesc,
  StyledFeatures,
  PlanHeading,
  Price,
  PriceDesc,
  FeatureContainer,
  Feature,
  FreePlanBtn,
  ProPlanBtn,
  PopularBox,
  PopularText,
  PriceSubscript,
  DropdownContainer,
  DropDownIcon,
  NavButtonContainer,
  NavButton
};
