import React, { useEffect, useState } from "react";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { IconButton, TextField, Input } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Styled from "./styles";
import CircularProgress from "@mui/material/CircularProgress";
import intlMessages from "../../locales/en";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import { event } from "react-ga";
import {
  QUIZ_SETTINGS_DATA,
  setQuestSettingsData,
  getQuestSettingsData,
  updateQuestSettingsData,
  DifficultyLevel,
  QuizType,
} from "../../util/set_quiz_settings";

export default function SettingsModal(props) {
  const { isOpen, closeSettingsModalCallback } = props;

  const [openSettingsModal, setopenSettingsModal] = useState(isOpen);
  const [type, setType] = React.useState(QuizType.BOOLEAN);
  const [difficulty, setDifficulty] = React.useState(DifficultyLevel.AUTO);
  const [count, setCount] = React.useState("0");
  const [questionCount, setQuestionCount] = React.useState(5);
  const [isSaving, setIsSaving] = React.useState(false);

  useEffect(() => {
    const questionData = getQuestSettingsData(QUIZ_SETTINGS_DATA);
    setType(questionData.type);
    setDifficulty(questionData.difficulty);
    setCount(questionData.count);
    setQuestionCount(questionData.count == "0" ? 5 : +questionData.count);
  }, []);
  const closeSettingsModal = () => {
    closeSettingsModalCallback();
  };

  const handleTypeRadioChange = (event) => {
    // console.log((event.target).value);
    setType(event.target.value);
  };

  const handleDifficultyRadioChange = (event) => {
    // console.log((event.target).value);
    setDifficulty(event.target.value);
  };

  const handleCountRadioChange = (event) => {
    // console.log((event.target).value);
    setCount(event.target.value);
  };

  const handleQuestionCountChange = (event) => {
    // console.log((event.target).value);
    setQuestionCount(event.target.value);
  };

  const resetQuestionsData = () => {
    setQuestSettingsData(QUIZ_SETTINGS_DATA);
  };

  const saveSettings = () => {
    // save all settings for quiz settings
    setIsSaving(true);
    const settings = {
      type,
      difficulty,
      count: count == "0" ? "0" : questionCount,
    };
    // console.log(settings);
    setQuestSettingsData(QUIZ_SETTINGS_DATA, settings);
    setTimeout((e) => {
      setIsSaving(false);
      closeSettingsModal();
    }, 300);
  };

  return (
    <Modal
      open={openSettingsModal}
      aria-labelledby="review-modal"
      aria-describedby="review-description"
    >
      <Styled.ModalBox sx={Styled.customStyling.exportModal}>
        <Styled.ModalHeader>
          <Typography
            id="review-modal-title"
            variant="h5"
            component="h5"
            style={{ fontWeight: "bold" }}
          >
            {intlMessages.questionsettings}
          </Typography>
          <IconButton
            aria-label="closeReviewModal"
            onClick={() => {
              closeSettingsModal();
            }}
          >
            <CloseIcon color="seondary.light" />
          </IconButton>
        </Styled.ModalHeader>
        <Styled.ModalContentBox>
          <Typography color="secondary.dark" variant="h6">
            Type
          </Typography>
          <Box mt={1.4} sx={{ display: "flex", flexDirection: "column" }}>
            <RadioGroup
              row
              aria-labelledby="demo-form-control-label-placement"
              name="position"
              defaultValue="top"
              value={type}
              onChange={handleTypeRadioChange}
            >
              <Styled.StyledFormControlLabel
                value={QuizType.ALL}
                control={<Styled.RadioBtn />}
                label="All"
                sx={{ display: "none" }}
              />
              <Styled.StyledFormControlLabel
                value={QuizType.MCQS}
                control={<Styled.RadioBtn />}
                label="MCQ`s"
              />
              <Styled.StyledFormControlLabel
                value={QuizType.BOOLEAN}
                control={<Styled.RadioBtn />}
                label="True/False"
              />
            </RadioGroup>
          </Box>
          <Typography color="secondary.dark" variant="h6" mt={4}>
            Difficulty
          </Typography>
          <Box mt={1.4} sx={{ display: "flex", flexDirection: "column" }}>
            <RadioGroup
              row
              aria-labelledby="demo-form-control-label-placement"
              name="position"
              defaultValue="top"
              value={difficulty}
              onChange={handleDifficultyRadioChange}
            >
              <Styled.StyledFormControlLabel
                value={DifficultyLevel.AUTO}
                control={<Styled.RadioBtn />}
                label="All"
              />
              <Styled.StyledFormControlLabel
                value={DifficultyLevel.EASY}
                control={<Styled.RadioBtn />}
                label="Easy"
              />
              <Styled.StyledFormControlLabel
                value={DifficultyLevel.MEDIUM}
                control={<Styled.RadioBtn />}
                label="Medium"
              />
              <Styled.StyledFormControlLabel
                value={DifficultyLevel.HARD}
                control={<Styled.RadioBtn />}
                label="Hard"
              />
            </RadioGroup>
          </Box>
          <Typography color="secondary.dark" variant="h6" mt={4}>
            Count
          </Typography>
          <Box mt={1.4} sx={{ display: "flex", flexDirection: "column" }}>
            <RadioGroup
              row
              aria-labelledby="demo-form-control-label-placement"
              name="position"
              defaultValue="top"
              value={count}
              onChange={handleCountRadioChange}
            >
              <Styled.StyledFormControlLabel
                value="0"
                control={<Styled.RadioBtn />}
                label="Auto"
              />
              <Styled.RadioBtn value="1" checked={count != "0"} />
              <TextField
                type="number"
                min="1"
                max="50"
                value={questionCount}
                InputProps={{
                  inputProps: { min: 1, max: 50, pattern: "[0-9]*" },
                }}
                sx={Styled.customStyling.inputNumberField}
                onChange={(event) => {
                  setQuestionCount(event.target.value);
                }}
                disabled={count == "0" ? "true" : undefined}
              />
              <Styled.typographyText variant="span">
                {intlMessages.Optimalnthecontentlengthis}
              </Styled.typographyText>
            </RadioGroup>
          </Box>
        </Styled.ModalContentBox>
        <Styled.ModalActions>
          <Styled.CreateQuizButton
            variant="contained"
            color="secondary"
            loading={isSaving}
            loadingPosition="start"
            disabled={isSaving ? "true" : undefined}
            onClick={() => {
              saveSettings();
            }}
            loadingIndicator={<CircularProgress color="inherit" size={16} />}
          >
            {intlMessages.updateLabel}
          </Styled.CreateQuizButton>
          <Button
            sx={{
              textTransform: "none",
              marginLeft: 1,
            }}
            variant="outlined"
            color="secondary"
            disabled={false ? "true" : undefined}
            onClick={() => {
              closeSettingsModal();
            }}
          >
            {intlMessages.closeLabel}
          </Button>
        </Styled.ModalActions>
      </Styled.ModalBox>
    </Modal>
  );
}
