import styled from "@mui/system/styled";
import { Typography, Button, Box, TextField } from "@mui/material";
import { LoadingButton } from "@mui/lab";

const MainContainer = styled(Box)(({ theme,isMobile,isMobileLandscape,delay,height}) => ({
    display: delay?'none':'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    margin: "0% 3%",
    borderRadius: "10px",
    backgroundColor: 'white',
    overflow: 'auto',
    alignItems: 'center',
    height: isMobile || isMobileLandscape?'unset':height,
    // [theme.breakpoints.up("md")]: {
    //     height: height
    //   },
}))

const verifyText = styled(Typography)(({ theme,isMobile,isMobileLandscape }) => ({
    fontFamily: 'Open Sans',
    fontWeight: '700',
    fontSize: isMobile?'16px':isMobileLandscape?'28px':'32px',
    lineHeight: isMobile?'24px':'32px',
    textAlign: 'center',
    color: '#222222',
    marginBottom: isMobile || isMobileLandscape?'10px':'1%',
    marginTop: isMobile || isMobileLandscape?'25px':'unset'
}))

const verifyTextDesc = styled(Typography)(({ theme,isMobile,isMobileLandscape }) => ({
    fontFamily: 'Open Sans',
    fontWeight: '400',
    fontSize: isMobile?'16px':'18px',
    lineHeight: isMobile?'32px':'32px',
    textAlign: 'center',
    color: '#222222',
    marginBottom: isMobile|| isMobileLandscape?'25px':'40px',
    paddingLeft: '20%',
    paddingRight: '20%',
}))

const resendBtn = styled(LoadingButton)(({ theme,isMobile }) => ({
    width: '210px',
    height: isMobile?'48px':'54px',
    color: 'white',
    backgroundColor: '#7DC243',
    borderRadius: '4px',
    textTransform: 'none',
    marginBottom: isMobile?'25px':'40px',
    '.MuiLoadingButton-loadingIndicator':{
        left:"30px"
    },
    '&:hover': {
        backgroundColor: '#89d44a',
    }
}))

const logOutBtn = styled(Button)(({ theme,isMobile,isMobileLandscape }) => ({
    height: '24px',
    fontSize: '14px',
    lineHeight: '24px',
    fontWeight: '600px',
    color: '#1A73E8',
    textTransform: 'none',
    marginBottom: isMobile || isMobileLandscape?'20px':'unset'
}))

const largeTextField = styled(TextField)(({ theme,successMsg,isMobile,isMobileLandscape,isError }) => ({
    background: '#F6F6F6',
    '& .MuiInputBase-root': {
        background: '#F6F6F6',
    },  
    '& input::placeholder': {
      color: '#000000',
      opacity: 1,
    },
    '& .MuiFormHelperText-root.Mui-error': {
        position: 'absolute',
        top: '100%'
      },
    width: isMobile?'250px':isMobileLandscape?'410px':'476px',
    height: '54px',
    borderRadius: '4px',
    '& .MuiFormHelperText-root': {
        color: successMsg? '#7DC242' : 'red',
    },
    marginBottom: isError && (isMobile || isMobileLandscape)
    ? "70px": isError && !isMobile && !isMobileLandscape? "50px"
    : (isMobile || isMobileLandscape) && !isError
    ? "25px"
    : "40px"
}))

export default {
    MainContainer,
    verifyText,
    verifyTextDesc,
    resendBtn,
    logOutBtn,
    largeTextField
}