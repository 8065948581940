import Styled from "./styles";
import intlMessages from "../../locales/en";
import { Box, Typography } from "@mui/material";
import {
  cancelDowngradeRequest,
  getBillingHistory,
  getBillingInvoice,
} from "../../api/payment/methods";
import { store } from "../../redux/store";
import { setOpenErrorBox } from "../../redux/slices/paymentSlice";
import { Categories, Events, logEvent } from "../../analytics";

const getDateAndTime = (dateString) => {
  const date = new Date(dateString);
  const day = date.getDate().toString().padStart(2, "0");
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const year = date.getFullYear();
  const hours = date.getHours();
  const minutes = date.getMinutes().toString().padStart(2, "0");
  const isAMOrPM = hours >= 12 ? "PM" : "AM";
  const formattedHours = (hours % 12 || 12).toString().padStart(2, "0");
  return {
    date: `${month}/${day}/${year}`,
    time: `${formattedHours}:${minutes} ${isAMOrPM}`,
  };
};

const AmountHeader = (params) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        height: "39px",
      }}
    >
      <Typography
        sx={{
          fontSize:
            params?.isMobile === true || params?.isMobileLandscape === true
              ? "13px"
              : "14px",
          lineHeight:
            params?.isMobile === true || params?.isMobileLandscape === true
              ? "17.7px"
              : "19.07px",
          width: params?.isMobile === true ? "60px" : "71px",
        }}
      >
        {intlMessages.billingModalAmountLabel}
      </Typography>
    </Box>
  );
};

const SubscriptionHeader = (params) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        height: "39px",
      }}
    >
      <Typography
        sx={{
          fontSize:
            params?.isMobile === true || params?.isMobileLandscape === true
              ? "13px"
              : "14px",
          lineHeight:
            params?.isMobile === true || params?.isMobileLandscape === true
              ? "17.7px"
              : "19.07px",
          width: params?.isMobile === true ? "90px" : "177px",
        }}
      >
        {params?.isMobile === true || params?.isMobileLandscape === true
          ? intlMessages.billingModalMobileSubscriptionPlanLabel
          : intlMessages.billingModalSubscriptionPlanLabel}
      </Typography>
    </Box>
  );
};

const DateHeader = (params) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        height: "39px",
      }}
    >
      <Typography
        sx={{
          fontSize:
            params?.isMobile === true || params?.isMobileLandscape === true
              ? "13px"
              : "14px",
          lineHeight:
            params?.isMobile === true || params?.isMobileLandscape === true
              ? "17.7px"
              : "19.07px",
          width: params?.isMobile === true ? "79px" : "131px",
          textAlign:
            params?.isMobileLandscape === true
              ? "center"
              : params?.isMobile === true
              ? "right"
              : "unset",
        }}
      >
        {params?.isMobile === true || params?.isMobileLandscape === true
          ? intlMessages.billingModalMobilePaidDateLabel
          : intlMessages.billingModalPaidDateLabel}
      </Typography>
    </Box>
  );
};

const AmountCell = (params) => {
  return (
    <Styled.styledTableRow>
      <Typography sx={{ fontSize: "14px", lineHeight: "19.07px" }}>
        {`$${params?.row?.amount}`}
      </Typography>
    </Styled.styledTableRow>
  );
};

const PlanCell = (params) => {
  return (
    <Styled.styledTableRow>
      <Typography
        sx={{
          fontSize: "14px",
          lineHeight: "19.07px",
          fontWeight: 400,
        }}
      >
        {params?.row?.isScreenSubscription===true?intlMessages.billingHistoryScreenProTrialLabel:params?.row?.subscriptionPlan}
      </Typography>
    </Styled.styledTableRow>
  );
};

const getInvoice = async (chargeId, invoiceId) => {
  logEvent(
    Categories.pricing_and_subscription_modal,
    Events.billing_history_invoice_btn_clicked
  );
  const res = await getBillingInvoice(chargeId, invoiceId);
  if (res?.data === "unable to generate pdf from ") {
    store.dispatch(setOpenErrorBox(true));
    return;
  }
  setTimeout(()=>{
    window.open(res.data, "_blank");
  })
};

const DateCell = (params) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: params?.isMobile === true ? "column" : "row",
        justifyContent: params?.isMobile === true ? "unset" : "space-between",
        alignItems: "center",
        width: "100%",
        marginRight: params?.isMobile === true ? "unset" : "25%",
      }}
    >
      <Styled.styledTableRow
        sx={{
          display: "block",
        }}
      >
        <Typography sx={{ fontSize: "14px", lineHeight: "19.07px" }}>
          {getDateAndTime(params?.row?.purchasedDate * 1000)?.date}
        </Typography>
      </Styled.styledTableRow>
      <Styled.BillingActionBtn
        onClick={() =>
          getInvoice(params?.row?.chargeId, params?.row?.invoiceId)
        }
      >
        {intlMessages.billingModalViewInvoiceLabel}
      </Styled.BillingActionBtn>
    </Box>
  );
};
const getDatagridColumns = (isMobile, isMobileLandscape) => {
  const columns = [
    {
      field: "amount",
      headerName: intlMessages.billingModalAmountLabel,
      editable: false,
      flex: isMobile ? 0.6 : 0.5,
      sortable: false,
      renderHeader: (params) => {
        const parameters = { isMobile, isMobileLandscape, ...params };
        return <AmountHeader {...parameters} />;
      },
      renderCell: (params) => {
        const parameters = { isMobile, isMobileLandscape, ...params };
        return <AmountCell {...parameters} />;
      },
    },

    {
      field: "subscriptionplan",
      headerName:
        isMobile || isMobileLandscape
          ? intlMessages.billingModalMobileSubscriptionPlanLabel
          : intlMessages.billingModalSubscriptionPlanLabel,
      editable: false,
      flex: 1,
      sortable: false,
      renderHeader: (params) => {
        const parameters = { isMobile, isMobileLandscape, ...params };
        return <SubscriptionHeader {...parameters} />;
      },
      renderCell: (params) => {
        const parameters = { isMobile, isMobileLandscape, ...params };
        return <PlanCell {...parameters} />;
      },
    },
    {
      field: "paiddate",
      headerName:
        isMobile || isMobileLandscape
          ? intlMessages.billingModalMobilePaidDateLabel
          : intlMessages.billingModalPaidDateLabel,
      editable: false,
      flex: isMobile ? 1 : isMobileLandscape ? 2 : 1.5,
      sortable: false,
      renderHeader: (params) => {
        const parameters = { isMobile, isMobileLandscape, ...params };
        return <DateHeader {...parameters} />;
      },
      renderCell: (params) => {
        const parameters = { isMobile, isMobileLandscape, ...params };
        return <DateCell {...parameters} />;
      },
    },
  ];
  return columns;
};

const getHistory = async (currentPage, size) => {
  return await getBillingHistory(currentPage, size);
};

const cancelPlanDowngrade = async () => {
  return await cancelDowngradeRequest();
};

export {
  getDateAndTime,
  getDatagridColumns,
  getHistory,
  cancelPlanDowngrade,
};
