import { useState, useEffect, useRef } from "react";
import { useTheme } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import { useDispatch } from "react-redux";
import Footer from "../footer/component";
import intlMessages from "../../locales/en";
import Styled from "./styles";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { InputAdornment, useMediaQuery } from "@mui/material";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import ConnectionModal from "../connection-modal/component";
import CircularProgress from "@mui/material/CircularProgress";
import {
  validateInput,
  validateEmail,
  validateName,
  validatePassword,
  signUp,
} from "./service";
import { setUserEmail } from "../../redux/slices/signUpSlice";
import { decodeToken, setToken } from "../../common/sign_in_service";
import {
  setAuthToken,
  setIsSignedIn,
  setUserData,
  setUserState,
} from "../../redux/slices/logInSlice";
import { USER_STATE } from "../../settings";
import { Categories, Events, logEvent } from "../../analytics";
import { iOS, isMobileDevice } from "../quiz/service";
import { updateUserData } from "../user/service";
import { getNavigateURL } from "../../util/signin-user-data";
import { initAdds } from "../../adds_conversion";

function SignUp() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"), {
    noSsr: true,
  });
  const isMobileLandscape = useMediaQuery(
    `${theme.breakpoints.down(
      "md"
    )}  and (orientation: landscape) and (hover: none) and (pointer: coarse)`,
    {
      noSsr: true,
    }
  );

  const isIpad = useMediaQuery(
    window.navigator.userAgent.includes("OneScreen_TL7")
      ? "(min-width:620px) and (max-width:960px) and (orientation: landscape) "
      : "(min-width:620px) and (max-width:840px) and (orientation: portrait) and  (hover: none) and (pointer: coarse)",
    {
      noSsr: true,
    }
  );
  const isIpadLandscape = useMediaQuery(
    "(min-width: 768px) and (max-width: 1024px) and (orientation: landscape) and  (hover: none) and (pointer: coarse)",
    {
      noSsr: true,
    }
  );
  const dispatch = useDispatch();
  const [checked, setChecked] = useState(false);
  const [showpassword, setShowPassword] = useState(false);
  const [showconfirmpassword, setShowConfirmPassword] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [newsletterChecked, setNewsletterChecked] = useState(true);
  const [openConnectionModal, setIsOpenConnectionModal] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const consentTextRef = useRef();
  const termsRef = useRef();
  const privacyRef = useRef();
  const [error, setError] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    confirmPassword: "",
  });
  const navigate = useNavigate();

  async function handleOrientationChange() {
    const screenStats = window.screen;
    let mainBoxHeight_;
    switch (true) {
      case (navigator.standalone === "boolean" ||
        navigator.userAgent.match(/iPhone/i) ||
        navigator.userAgent.match(/iPod/i)) &&
        iOS() &&
        window.matchMedia(
          "screen and (max-device-height: 900px) and (orientation: landscape)"
        ).matches:
        mainBoxHeight_ = 160;
        break;
      case window.matchMedia(
        "(min-width: 800px) and (max-width: 1400px) and (orientation: portrait) and (hover: none) and (pointer: coarse)"
      ).matches:
        mainBoxHeight_ = screenStats.availHeight * 0.74; // iPad Portrait
        break;
      case screenStats.availHeight > 600 &&
        screenStats.availHeight <= 700 &&
        window.matchMedia("(pointer: coarse)").matches:
        mainBoxHeight_ = screenStats.availHeight; // Small mobiles
        break;
      case screenStats.availHeight > 650 && screenStats.availHeight <= 700:
        mainBoxHeight_ = screenStats.availHeight - 260;
        break;
      case screenStats.availHeight > 700 &&
        screenStats.availHeight < 750 &&
        window.matchMedia("(pointer: fine)").matches &&
        window.screen.width < 1100:
        mainBoxHeight_ = screenStats.availHeight - 275; // Mobile landscape
        break;
      case screenStats.availWidth > 750 &&
        screenStats.availWidth <= 960 &&
        screenStats.availHeight < 930: //mobile Landscape
        if (window.innerHeight > 350) {
          mainBoxHeight_ = screenStats.availHeight - 200;
        } else {
          mainBoxHeight_ = screenStats.availHeight - 250;
        }
        break;
      case screenStats.availHeight > 800 &&
        window.matchMedia("(orientation: portrait)").matches &&
        window.matchMedia("(pointer: coarse)").matches:
        mainBoxHeight_ = screenStats.availHeight - 450; // iPad
        break;
      case screenStats.availHeight === 720 && screenStats.availWidth === 1280:
        mainBoxHeight_ = screenStats.availHeight - 290; // TL
        break;
      case screenStats.availWidth >= 1920 && screenStats.height > 1000:
        mainBoxHeight_ = screenStats.availHeight - 265; // Desktop
        break;
      case window.matchMedia(
        "(min-width: 800px) and (max-width: 1400px) and (orientation: landscape) and (hover: none) and (pointer: coarse)"
      ).matches:
        mainBoxHeight_ = screenStats.availHeight - 535; // iPad Landscape
        if (mainBoxHeight_ < 400) mainBoxHeight_ = 430;
        break;
      case screenStats.height > 800:
        mainBoxHeight_ = screenStats.availHeight - 265; // Desktop
        break;
      default:
        mainBoxHeight_ = screenStats.availHeight - 260;
        if (mainBoxHeight_ < 200) mainBoxHeight_ = 173;
        break;
    }
    const height = window.innerHeight - 200;
    var viewport = document.querySelector("meta[name=viewport]");
    isMobileDevice() &&
    !iOS() &&
    (window.screen.orientation.type === "landscape-primary" ||
      window.screen.orientation.type === "landscape-secondary")
      ? viewport.setAttribute(
          "content",
          viewport.content + ", height=" + mainBoxHeight_
        )
      : viewport.setAttribute(
          "content",
          viewport.content + ", height=" + height
        );
  }

  useEffect(() => {
    handleOrientationChange();
    window.addEventListener("resize", handleOrientationChange);
    return () => {
      window.removeEventListener("resize", handleOrientationChange);
    };
  }, []);

  const handleChange = (event) => {
    consentTextRef.current.style.color = "#777777";
    termsRef.current.style.color = "#1A73E8";
    privacyRef.current.style.color = "#1A73E8";
    setChecked(event.target.checked);
  };

  const handleNewsletterChange = (event) => {
    setNewsletterChecked(event.target.checked);
  };

  const handleFirstName = (e) => {
    const inp = validateInput(e.target.value);
    if (
      inp.length > 0 ||
      error.firstName === intlMessages.signUpFirstNameEmptyMessage ||
      inp.length < 200 ||
      validateName(inp)
    ) {
      if (error.confirmPassword === intlMessages.SignUpEmptyFieldsError) {
        setError({
          ...error,
          firstName: null,
          confirmPassword: null,
        });
      } else {
        setError({
          ...error,
          firstName: null,
        });
      }
    }
    setFirstName(inp);
  };

  const handleLastName = (e) => {
    const inp = validateInput(e.target.value);
    if (
      inp.length > 0 ||
      error.lastName === intlMessages.signUpLastNameEmptyMessage ||
      inp.length < 200 ||
      validateName(inp)
    ) {
      if (error.confirmPassword === intlMessages.SignUpEmptyFieldsError) {
        setError({
          ...error,
          lastName: null,
          confirmPassword: null,
        });
      } else {
        setError({
          ...error,
          lastName: null,
        });
      }
    }
    setLastName(inp);
  };

  const handleEmail = (e) => {
    const inp = validateInput(e.target.value);
    if (
      inp.length > 0 ||
      error.email === intlMessages.signUpEmailEmptyMessage ||
      validateEmail(inp)
    ) {
      if (error.confirmPassword === intlMessages.SignUpEmptyFieldsError) {
        setError({
          ...error,
          email: null,
          confirmPassword: null,
        });
      } else {
        setError({
          ...error,
          email: null,
        });
      }
    }
    setEmail(inp);
  };

  const handlePassword = (e) => {
    const inp = validateInput(e.target.value);
    if (
      inp.length > 0 ||
      error.password === intlMessages.signUpPasswordEmptyMessage ||
      validatePassword(inp)
    ) {
      if (
        error.confirmPassword === intlMessages.SignUpEmptyFieldsError ||
        error.confirmPassword === intlMessages.SignUpPasswordDontMatchError
      ) {
        setError({
          ...error,
          password: null,
          confirmPassword: null,
        });
      } else {
        setError({
          ...error,
          password: null,
        });
      }
    }
    setPassword(inp);
  };

  const handleConfirmPassword = (e) => {
    const inp = validateInput(e.target.value);
    if (
      inp.length > 0 ||
      error.confirmPassword === intlMessages.signUpConfirmPasswordEmptyMessage
    ) {
      setError({
        ...error,
        confirmPassword: null,
      });
    }
    setConfirmPassword(inp);
  };

  const passedInpFieldsValidation = () => {
    if (
      firstName.length == 0 &&
      lastName.length == 0 &&
      email.length == 0 &&
      password.length == 0 &&
      confirmPassword.length == 0
    ) {
      return setError({
        firstName: null,
        lastName: null,
        email: null,
        password: null,
        confirmPassword: intlMessages.SignUpEmptyFieldsError,
      });
    }
    if (firstName.length == 0) {
      return setError({
        ...error,
        firstName: intlMessages.signUpFirstNameEmptyMessage,
        confirmPassword: null,
      });
    }
    if (firstName.length > 200 || !validateName(firstName)) {
      return setError({
        ...error,
        firstName: intlMessages.SignUpInvalidFirstNameError,
        confirmPassword: null,
      });
    }
    if (lastName.length == 0) {
      return setError({
        ...error,
        lastName: intlMessages.signUpLastNameEmptyMessage,
        confirmPassword: null,
      });
    }
    if (lastName.length > 200 || !validateName(lastName)) {
      return setError({
        ...error,
        lastName: intlMessages.SignUpInvalidLastNameError,
        confirmPassword: null,
      });
    }
    if (email.length == 0) {
      return setError({
        ...error,
        email: intlMessages.signUpEmailEmptyMessage,
        confirmPassword: null,
      });
    }
    if (!validateEmail(email)) {
      return setError({
        ...error,
        email: intlMessages.SignUpInvalidEmailError,
        confirmPassword: null,
      });
    }
    if (password.length == 0) {
      return setError({
        ...error,
        password: intlMessages.signUpPasswordEmptyMessage,
        confirmPassword: null,
      });
    }
    if (!validatePassword(password)) {
      return setError({
        ...error,
        password: intlMessages.SignUpInvalidPasswordError,
        confirmPassword: null,
      });
    }
    if (confirmPassword.length == 0) {
      return setError({
        ...error,
        confirmPassword: intlMessages.signUpConfirmPasswordEmptyMessage,
      });
    }
    if (confirmPassword !== password) {
      return setError({
        ...error,
        confirmPassword: intlMessages.SignUpPasswordDontMatchError,
      });
    }
    if (checked == false) {
      consentTextRef.current.style.color = "red";
      // termsRef.current.style.color = "red";
      // privacyRef.current.style.color = "red";
    }
    return true;
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      submitForm();
    }
  };

  const submitForm = () => {
    if (
      passedInpFieldsValidation() &&
      Object.values(error).every((val) => val === null) &&
      checked
    ) {
      setIsSaving(true);
      logEvent(Categories.user_management, Events.user_management_signup_local);
      signUp(
        firstName,
        lastName,
        email,
        password,
        confirmPassword,
        "user",
        "local",
        newsletterChecked
      )
        .then(async (res) => {
          if (res?.data?.statusCode === 0) {
            setIsOpenConnectionModal(true);
            setIsSaving(false);
            return;
          }
          if (
            res?.response?.data?.statusCode === 400 &&
            res?.response?.data?.message ===
              "This email is already associated with an account."
          ) {
            setIsSaving(false);
            return setError({
              ...error,
              email: intlMessages.SignUpAlreadyUsedEmail,
            });
          }
          if (res?.status === 201) { // && res?.statusText == "Created"
            setIsSaving(false);
            setToken(res?.data?.access_token);
            const user = await decodeToken(res?.data?.access_token);
            updateUserData({
              email: user.username,
              rating: null,
            });
            dispatch(setUserEmail(user.username));
            setUserDataState(res?.data?.access_token);
            initAdds();
            // navigate('/verify')
          }
        })
        .catch((error) => {
          setIsSaving(false);
          // // console.log(error)
        });
    } else {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
    return passedInpFieldsValidation();
  };

  const navigateToLogin = () => {
    logEvent(
      Categories.user_management,
      Events.user_management_login_click_signup_page
    );
    const loginURL = getNavigateURL("/login");
    navigate(loginURL);
  };

  const setUserDataState = async (token) => {
    dispatch(setAuthToken(token));
    const decodedData = await decodeToken(token);
    dispatch(setUserData(decodedData));
    dispatch(setIsSignedIn(true));
    dispatch(setUserState(USER_STATE.SIGNED_IN_USER));
  };

  return (
    <>
      <Box style={{ minHeight: "82vh" }}>
        <Box
          sx={{
            background: "linear-gradient(90deg, #4E0F89 0%, #7D00BA 100%)",
            minHeight: isIpad ? "77vh" : "80vh",
          }}
        >
          <Styled.MainContainer>
            <Styled.backButton
              onClick={() => {
                const signupURL = "/signup";
                navigate(signupURL);
              }}
              startIcon={<ArrowBackIcon />}
            >
              {intlMessages.signUpBackBtn}
            </Styled.backButton>
            <Styled.formContainer>
              <Styled.continueEmailText
                isMobile={isMobile ? true : false}
                isMobileLandscape={isMobileLandscape ? true : false}
              >
                {intlMessages.signUpContinueEmailText}
              </Styled.continueEmailText>
              <Box
                sx={{
                  display: "flex",
                  flexDirection:
                    isMobile || isMobileLandscape ? "column" : "row",
                  alignItems:
                    isMobile || isMobileLandscape ? "center" : "unset",
                  mb: isMobile || isMobileLandscape ? "unset" : "25px",
                }}
              >
                <Styled.nameTextField
                  isMobile={isMobile ? true : false}
                  isMobileLandscape={isMobileLandscape ? true : false}
                  value={firstName}
                  isError={error.firstName ? true : false}
                  onChange={handleFirstName}
                  required
                  variant="outlined"
                  placeholder={intlMessages.signUpFirstNamePlaceholder}
                  sx={{ mr: isMobile || isMobileLandscape ? "unset" : "20px" }}
                  helperText={error.firstName}
                  onKeyDown={handleKeyDown}
                  error={
                    error.confirmPassword ===
                    intlMessages.SignUpEmptyFieldsError
                      ? true
                      : error.firstName
                      ? true
                      : false
                  }
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <img src="/svgs/user-icon.svg" alt="Icon" />
                      </InputAdornment>
                    ),
                  }}
                ></Styled.nameTextField>
                <Styled.nameTextField
                  isMobile={isMobile ? true : false}
                  isMobileLandscape={isMobileLandscape ? true : false}
                  isError={error.lastName ? true : false}
                  onChange={handleLastName}
                  onKeyDown={handleKeyDown}
                  required
                  variant="outlined"
                  placeholder={intlMessages.signUpLastNamePlaceholder}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <img src="/svgs/user-icon.svg" alt="Icon" />
                      </InputAdornment>
                    ),
                  }}
                  helperText={error.lastName}
                  error={
                    error.confirmPassword ===
                    intlMessages.SignUpEmptyFieldsError
                      ? true
                      : error.lastName
                      ? true
                      : false
                  }
                ></Styled.nameTextField>
              </Box>
              <Styled.largeTextField
                isMobile={isMobile ? true : false}
                isMobileLandscape={isMobileLandscape ? true : false}
                onChange={handleEmail}
                onKeyDown={handleKeyDown}
                isError={error.email ? true : false}
                required
                type="email"
                variant="outlined"
                placeholder={intlMessages.signUpEmailPlaceholder}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <img src="/svgs/email-icon.svg" alt="Icon" />
                    </InputAdornment>
                  ),
                }}
                helperText={error.email}
                error={
                  error.confirmPassword === intlMessages.SignUpEmptyFieldsError
                    ? true
                    : error.email
                    ? true
                    : false
                }
              ></Styled.largeTextField>
              <Styled.largeTextField
                isMobile={isMobile ? true : false}
                isMobileLandscape={isMobileLandscape ? true : false}
                isError={error.password ? true : false}
                onChange={handlePassword}
                onKeyDown={handleKeyDown}
                required
                type={showpassword ? "text" : "password"}
                variant="outlined"
                placeholder={intlMessages.signUpPasswordPlaceholder}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <img src="/svgs/password-icon.svg" alt="Icon" />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">
                      {showpassword ? (
                        <RemoveRedEyeOutlinedIcon
                          style={{ cursor: "pointer" }}
                          onClick={() => setShowPassword(false)}
                        />
                      ) : (
                        <img
                          style={{ cursor: "pointer" }}
                          onClick={() => setShowPassword(true)}
                          src="/svgs/eye-slash.svg"
                          alt="Icon"
                        />
                      )}
                    </InputAdornment>
                  ),
                }}
                helperText={error.password}
                error={
                  error.confirmPassword ===
                    intlMessages.SignUpEmptyFieldsError ||
                  error.confirmPassword ===
                    intlMessages.SignUpPasswordDontMatchError
                    ? true
                    : error.password
                    ? true
                    : false
                }
              ></Styled.largeTextField>
              <Styled.largeTextField
                isMobile={isMobile ? true : false}
                isError={error.confirmPassword ? true : false}
                isMobileLandscape={isMobileLandscape ? true : false}
                onChange={handleConfirmPassword}
                onKeyDown={handleKeyDown}
                required
                type={showconfirmpassword ? "text" : "password"}
                variant="outlined"
                placeholder={intlMessages.signUpConfirmPasswordlaceholder}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <img src="/svgs/password-icon.svg" alt="Icon" />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">
                      {showconfirmpassword ? (
                        <RemoveRedEyeOutlinedIcon
                          style={{ cursor: "pointer" }}
                          onClick={() => setShowConfirmPassword(false)}
                        />
                      ) : (
                        <img
                          style={{ cursor: "pointer" }}
                          onClick={() => setShowConfirmPassword(true)}
                          src="/svgs/eye-slash.svg"
                          alt="Icon"
                        />
                      )}
                    </InputAdornment>
                  ),
                }}
                helperText={error.confirmPassword}
                error={
                  error.confirmPassword === intlMessages.SignUpEmptyFieldsError
                    ? true
                    : error.confirmPassword
                    ? true
                    : false
                }
              ></Styled.largeTextField>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  mb: isMobile || isMobileLandscape ? "0px" : "25px",
                  width: isMobile
                    ? "250px"
                    : isMobileLandscape
                    ? "410px"
                    : "unset",
                }}
              >
                <Styled.checkBox
                  isMobile={isMobile ? true : false}
                  isMobileLandscape={isMobileLandscape ? true : false}
                  icon={<Styled.unCheckedIcon />}
                  sx={{ml: isMobile || isMobileLandscape?'-10px':'20px'}}
                  checkedIcon={
                    <img
                      style={{ width: "24px", height: "24px" }}
                      src="/svgs/Checkbox.svg"
                    ></img>
                  }
                  onChange={handleChange}
                  checked={checked}
                ></Styled.checkBox>
                <Styled.consentText
                  isMobile={isMobile ? true : false}
                  isMobileLandscape={isMobileLandscape ? true : false}
                  ref={consentTextRef}
                >
                  <span
                    style={{ cursor: "pointer" }}
                    onClick={() => setChecked(!checked)}
                  >
                    {intlMessages.signUpConsentText1}
                  </span>{" "}
                  <a
                    ref={termsRef}
                    href="terms.html"
                    target="_blank"
                    style={{
                      cursor: "pointer",
                      textDecoration: "none",
                      fontSize: isMobile || isMobileLandscape ? "12px" : "14px",
                      color: "#1A73E8",
                    }}
                  >
                    {intlMessages.signUpConsentText2}
                  </a>{" "}
                  <span>{intlMessages.signUpConsentText3}</span>{" "}
                  <a
                    ref={privacyRef}
                    href="privacy-policies.html"
                    target="_blank"
                    style={{
                      cursor: "pointer",
                      textDecoration: "none",
                      fontSize: isMobile || isMobileLandscape ? "12px" : "14px",
                      color: "#1A73E8",
                    }}
                  >
                    {intlMessages.signUpConsentText4}
                  </a>
                </Styled.consentText>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  mb: isMobile || isMobileLandscape ? "10px" : "25px",
                  ml: isMobile || isMobileLandscape ? "0px" : "-25px",
                  width: isMobile
                    ? "250px"
                    : isMobileLandscape
                    ? "410px"
                    : "unset",
                }}
              >
                <Styled.checkBox
                  isMobile={isMobile ? true : false}
                  isMobileLandscape={isMobileLandscape ? true : false}
                  icon={<Styled.unCheckedIcon />}
                  checkedIcon={
                    <img
                      style={{ width: "24px", height: "24px" }}
                      src="/svgs/Checkbox.svg"
                    ></img>
                  }
                  onChange={handleNewsletterChange}
                  checked={newsletterChecked}
                ></Styled.checkBox>
                <Styled.consentText
                  sx={{ cursor: "pointer" }}
                  onClick={() => setNewsletterChecked(!newsletterChecked)}
                  isMobile={isMobile ? true : false}
                  isMobileLandscape={isMobileLandscape ? true : false}
                >
                  {intlMessages.SignUpNewsletterText}
                </Styled.consentText>
              </Box>
              <Styled.signUpBtn
                isMobile={isMobile ? true : false}
                isMobileLandscape={isMobileLandscape ? true : false}
                variant="contained"
                loading={isSaving}
                loadingPosition="start"
                disabled={isSaving ? true : false}
                loadingIndicator={
                  <CircularProgress color="inherit" size={16} />
                }
                onClick={submitForm}
              >
                {intlMessages.signUpLabel}
              </Styled.signUpBtn>
              <Styled.loginText
                isMobile={isMobile ? true : false}
                isMobileLandscape={isMobileLandscape ? true : false}
              >
                {intlMessages.signUpAlreadyText}
                <span
                  onClick={navigateToLogin}
                  style={{
                    color: "#1A73E8",
                    marginLeft: "5px",
                    cursor: "pointer",
                  }}
                >
                  {intlMessages.signUpLoginText}
                </span>
              </Styled.loginText>
            </Styled.formContainer>
          </Styled.MainContainer>
        </Box>
        <Footer></Footer>
      </Box>
      {openConnectionModal && (
        <ConnectionModal
          isOpen={openConnectionModal}
          handleCloseConnectionModal={() => setIsOpenConnectionModal(false)}
        />
      )}
    </>
  );
}

export default SignUp;
