import styled from "@mui/system/styled";
import { Typography, Box, TextField, Divider } from "@mui/material";
import { LoadingButton } from "@mui/lab";

const MainContainer = styled(Box)({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  margin:'0% 3%',
  borderRadius: "10px",
  backgroundColor: "white",
  overflow: "auto",
});

const WelcomeTextBox = styled(Box)(
  ({isMobile, isMobileLandscape }) => ({
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    marginTop: isMobile || isMobileLandscape ? "20px" : "50px",
  })
);

const WelcomeText = styled(Typography)(
  ({isMobile, isMobileLandscape }) => ({
    fontFamily: "Open Sans",
    fontWeight: "400",
    lineHeight: "36px",
    fontSize: isMobile || isMobileLandscape ? "16px" : "18px",
    textAlign: "center",
  })
);

const imageAndFormContainer = styled(Box)(
  ({ isMobile, isMobileLandscape,isIpad,isIpadLandscape }) => ({
    display: "flex",
    flexDirection: isMobile || isMobileLandscape || isIpad || isIpadLandscape ? "column-reverse" : "row",
    alignItems: isMobile || isMobileLandscape || isIpad || isIpadLandscape ? "center" : "flex-start",
    justifyContent: "center",
  })
);

const imageContainer = styled(Box)(
  ({isMobile, isMobileLandscape,isIpad,isIpadLandscape }) => ({
    paddingTop: isMobile || isMobileLandscape || isIpad || isIpadLandscape ? "0px" : "48px",
    display: "flex",
    flexDirection: "column",
    width: isMobile || isMobileLandscape || isIpad || isIpadLandscape ? "100%" : "35%",
  })
);

const image = styled("img")(({isMobile, isMobileLandscape,isIpad,isIpadLandscape }) => ({
  height: isMobile || isMobileLandscape ? "233px" :isIpad?"348px" :isIpadLandscape?"402px": "432px",
  // width:'600px'
}));

const imageText = styled(Typography)(
  ({ isMobile, isMobileLandscape }) => ({
    fontWeight: "400",
    fontFamily: "Open Sans",
    lineHeight: "28px",
    fontSize: "18px",
    textAlign: "center",
    width: isMobile ? "290px" : isMobileLandscape ? "410px" : "unset",
    display: isMobile || isMobileLandscape ? "block" : "unset",
    margin: isMobile || isMobileLandscape ? "auto" : "unset",
    paddingLeft: isMobile || isMobileLandscape ? "unset" : "10%",
    paddingRight: isMobile || isMobileLandscape ? "unset" : "10%",
    marginTop: isMobile || isMobileLandscape ? "10px" : "50px",
    marginBottom: isMobile || isMobileLandscape ? "20px" : "30px",
  })
);

const formContainer = styled(Box)(
  ({ theme}) =>({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  width: "50%",
  [theme.breakpoints.up("xxxl")]: {
    paddingTop: "10px",
  },
}));

const trialText = styled(Typography)({
  color: "#7DC242",
  fontWeight: "600",
  fontFamily: "Open Sans",
  lineHeight: "32px",
  fontSize: "24px",
  textAlign: "center",
  marginTop: "22px",
  marginBottom: "22px",
});

const iconAndTextBox = styled(Box)(
  ({isMobile,isIpad,isIpadLandscape }) => ({
    height: "54px",
    width: isMobile ? "250px" : isIpad?'540px': isIpadLandscape?"490px": "410px",
    border: "1px solid #A7D5EC",
    borderRadius: "4px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: "22px",
    backgroundColor: "#FFFFFF",
    cursor: "pointer",
    fontWeight: "400",
    fontFamily: "Open Sans",
    lineHeight: "24px",
    fontSize: "16px",
    textAlign: "center",
  })
);

const icon = styled("img")(({isMobile, isMobileLandscape }) => ({
  height: isMobile || isMobileLandscape ? "18px" : "24px",
  width: isMobile || isMobileLandscape ? "18px" : "24px",
  marginRight: isMobile || isMobileLandscape ? "10px" : "15px",
}));

const divider = styled(Divider)(({isMobile, isMobileLandscape,isIpad,isIpadLandscape }) => ({
  width: isMobile ? "250px" : isIpad?"540px":isIpadLandscape?"490px": "410px",
  marginBottom: isMobile || isMobileLandscape ? "22px" : "22px",
  color: "#DDDDDD",
}));

const largeTextField = styled(TextField)(
  ({ isMobile, isError,isIpad,isIpadLandscape }) => ({
    width: isMobile? "250px" : isIpad?"540px" : isIpadLandscape?"490px":"410px",
    height: "54px",
    borderRadius: "4px",
    marginBottom: isError ? "30px" : "22px",
    background: '#F6F6F6',
    '& .MuiInputBase-root': {
        background: '#F6F6F6',
    },  
    '& input::placeholder': {
      color: '#000000',
      opacity: 1,
    },
    '& .MuiFormHelperText-root.Mui-error': {
      position: 'absolute',
      top: '100%'
    },
  })
);

const newsletterText = styled(Typography)(
  ({ theme, isMobile,isIpad,isIpadLandscape }) => ({
    fontFamily: "Open Sans",
    fontSize: "12px",
    lineHeight: "24px",
    color: "#999999",
    width: isMobile? "250px" : isIpad?"540px": isIpadLandscape?"490px": "410px",
    marginBottom: "22px",
  })
);

const forgotPassword = styled(Typography)({
  fontFamily: "Open Sans",
  fontSize: "14px",
  lineHeight: "24px",
  color: "#1A73E8",
  cursor: "pointer",
  marginBottom: "22px",
});

const loginBtn = styled(LoadingButton)(
  ({ isMobile, isMobileLandscape }) => ({
    width: "210px",
    height: isMobile || isMobileLandscape ? "48px" : "54px",
    borderRadius: "4px",
    backgroundColor: "#7DC243",
    color: "white",
    marginBottom: "22px",
    textTransform: "none",
    ".MuiLoadingButton-loadingIndicator": {
      left: "30px",
    },
    "&:hover": {
      backgroundColor: "#89d44a",
    },
  })
);

const loginText = styled(Typography)(
  ({isMobile}) => ({
    fontFamily: "Open Sans",
    fontWeight: "400",
    lineHeight: "24px",
    fontSize: "14px",
    marginBottom: "30px",
    width: isMobile ? "250px" : "unset",
  })
);

export default {
  MainContainer,
  WelcomeTextBox,
  WelcomeText,
  imageAndFormContainer,
  imageContainer,
  image,
  imageText,
  formContainer,
  trialText,
  iconAndTextBox,
  loginText,
  largeTextField,
  loginBtn,
  forgotPassword,
  newsletterText,
  divider,
  icon,
};
