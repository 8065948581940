import { useState } from "react";
import "./styles.css";
import { useRef } from "react";
import { useEffect } from "react";
import intlMessages from "../../locales/en";
import { getGuestUniqueId, getGuruList, setGuestUniqueId } from "./service";
import GuruCall from "./component-call-modal";
import { setCallData, setUserDetailsGuru } from "../../redux/slices/guruSlice";
import { useDispatch } from "react-redux";
import { header_images_base64 } from "../../components/header/header-images-base64";
import { Categories, Events, logEvent } from "../../analytics";

export default function GuruDropDown(props) {
  const {
    openConnectionErrorCallback,
    isMobile,
    isIpadLandscape,
    isIpad,
    isMobileLandscape,
    isClicked,
    handleCloseGuru,
    initiateCall,
    clickedGuruIcon,
    closeCallModal,
  } = props;
  const [isExpanded, setIsExpended] = useState(false);
  const [guruLists, setGuruLists] = useState([]);
  const componentRef = useRef(null);
  const dispatch = useDispatch();

  useEffect(() => {
    // Add a click event listener to the document when the component mounts
    // document.addEventListener("click", handleDocumentClick);
    if (clickedGuruIcon) {
      dropDownClick();
    }
    // Remove the event listener when the component unmounts
    return () => {
      // document.removeEventListener("click", handleDocumentClick);
    };
  }, [isClicked]);

  const handleDocumentClick = (e) => {
    // Check if the click event target is not within the component's DOM
    if (componentRef.current && !componentRef.current.contains(e.target)) {
      // The click occurred outside the component's DOM
      setCloseIsExpanded();
      if (handleCloseGuru()) {
        handleCloseGuru();
      }
    }
  };

  const addClickOutsideListener = () => {
    document.addEventListener("click", handleDocumentClick);
  };

  const removeClickOutsideListener = () => {
    document.removeEventListener("click", handleDocumentClick);
  };

  const setOpenIsExpanded = () => {
    setIsExpended(true);
  };

  const setCloseIsExpanded = () => {
    setIsExpended(false);
    closeCallModal();
    // handleCloseGuru();
  };

  const callGuru = (callData, isAudio = false) => {
    setCloseIsExpanded();
    dispatch(setCallData(callData));
    // setStartCall(true);
    logEvent(
      Categories.guru,
      isAudio ? Events.guru_audio_call_started : Events.guru_video_call_started
    );
    initiateCall({ callData, isAudio });
  };

  const getLanguageCode = (language) => {
    let AVAILABLE_LANGUAGE = {
      lang_en: "English",
      lang_ur: "اردو",
      lang_zh: "中文",
      lang_es: "Español",
      lang_ko: "한국어",
    };
    return AVAILABLE_LANGUAGE[language];
  };

  // const getGuestUniqueId = () => {
  //   let uuid = localStorage.getItem(`OS-guestUniqueId`);
  //   return uuid;
  // };

  // const setGuestUniqueId = () => {
  //   let currentTimeStamp = Math.floor(new Date().getTime() / 1000);
  //   let uuid = `guestUniqueId-${currentTimeStamp}${Math.floor(
  //     Math.random(10000) * 10000
  //   )}`;
  //   localStorage.setItem(`OS-guestUniqueId`, uuid);
  // };

  const dropDownClick = async () => {
    if (isExpanded) {
      setCloseIsExpanded();
    } else {
      logEvent(Categories.guru, Events.guru_call_icon_clicked);
      setOpenIsExpanded();
      try {
        if (!getGuestUniqueId()) {
          setGuestUniqueId();
        }
      } catch (err) {
        // console.error(err);
      }
      try {
        setTimeout((e) => {
          addClickOutsideListener();
        }, 500);
        const guruList = await getGuruList();
        // console.log(guruList);
        if (guruList.status == 200 && guruList.data.status == 0) {
          dispatch(setUserDetailsGuru(guruList.data.authToken));
          setGuruLists(guruList.data.data);
        }
      } catch (err) {
        // console.log(err);
        return openConnectionErrorCallback();
      }
    }
  };

  return (
    <>
      <div
        className={
          "scrollable " +
          (isExpanded ? "" : " display-n ") +
          (isMobile
            ? " scrollable-mobile"
            : isMobileLandscape
            ? " scrollable-mobile-landscape"
            : isIpad
            ? " scrollable-ipad-portrait"
            : " scrollable-desktop")
        }
        ref={componentRef}
      >
        <div className={"fullWidth"}>
          <img
            src="/svgs/upper_green_carot.svg"
            className={
              isMobile
                ? " carot-mobile"
                : isMobileLandscape || isIpad
                ? "display-n"
                : "carot-desktop"
            }
          ></img>
        </div>
        <div className={"guruListHeading"}>
          <p className={" guruItemHeading1 guruHeadingText guruText"}>
            {intlMessages.GURU_STR_32}
          </p>
          <p className={"guruItemHeading2 guruText"}>
            {intlMessages.GURU_STR_33}
          </p>
        </div>
        <div id="guruListsDiv" className={"guruListsDiv"}>
          {guruLists.length == 0 ? (
            <div className={"spinnerStyle"} id="guruListsDivSpinner">
              <div className={"spinner"} id=""></div>
            </div>
          ) : (
            guruLists.map((singleList, index) => (
              <div
                key={singleList.id}
                value={singleList}
                className={"dropdownItem guruDropdownItem"}
              >
                <div className={"guruNameDiv"}>
                  <span className={"guruNameCount"}>
                    <span>GURU {index + 1}</span>
                    <span className={"guruLangSpan"}>
                      ( {getLanguageCode(singleList.language)} )
                    </span>
                    <span
                      className={"guruNameActual"}
                      title={singleList.username}
                    >
                      {singleList.username}
                    </span>
                  </span>
                </div>
                <div className={"guruIDiv"}>
                  <a
                    title={intlMessages.GURU_STR_26}
                    className={"guruAnchoTag"}
                    onClick={() => callGuru(singleList, false)}
                  >
                    <span style={{ fontSize: "22px" }}>
                      <i className={"guruDropdownI fas fa-video"}>
                        <img
                          className={"guruSvgStyle"}
                          src={`${header_images_base64[4]}`}
                        ></img>
                      </i>
                    </span>
                  </a>
                  <a
                    title={intlMessages.GURU_STR_27}
                    className={"guruAnchoTag"}
                    onClick={() =>
                      callGuru(singleList, true)
                    } /*onClick={()=>callGuru(singleList, true)}*/
                  >
                    <span style={{ fontSize: "22px" }}>
                      <i className={"guruDropdownI fas fa-headphones"}>
                        <img
                          className={"guruSvgStyle"}
                          src={`${header_images_base64[5]}`}
                        ></img>
                      </i>
                    </span>
                  </a>
                </div>
              </div>
            ))
          )}
        </div>
        <div className={"repliesClass"} style={{ paddingTop: "0" }}>
          <p style={{ marginBottom: "0" }}>{intlMessages.GURU_STR_34}</p>
        </div>
      </div>
    </>
  );
}
