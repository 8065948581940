import { createSlice } from "@reduxjs/toolkit";
import { USER_STATE } from "../../settings";

const initialState = {
  isSignedIn: false,
  authToken: "",
  userData: null,
  userState: USER_STATE.LOGGED_OUT_USER,
  isSkipped: -1, // not shown to user
  userInformation: null,
  isShowWelcomeModal: true,
  stripeStatus:null,
  openExpiryModal:false,
  isVerifyPage:false,
  verified:false,
  rechargeTries:0,
  isScreenSubscription:false
};

const loginSlice = createSlice({
  name: "logIn",
  initialState,
  reducers: {
    setIsSignedIn: (state, action) => {
      state.isSignedIn = action.payload;
    },
    setAuthToken: (state, action) => {
      state.authToken = action.payload;
    },
    setUserData: (state, action) => {
      state.userData = action.payload;
    },
    setUserState: (state, action) => {
      state.userState = action.payload;
    },
    setIsSkippedState: (state, action) => {
      state.isSkipped = action.payload;
    },
    setUserInformationState: (state, action) => {
      state.userInformation = action.payload;
    },
    setIsShowWelcomeModal: (state, action) => {
      state.isShowWelcomeModal = action.payload;
    },
    setStripeStatus:(state, action) => {
      state.stripeStatus = action.payload;
    },
    setOpenExpiryModal: (state,action)=>{
      state.openExpiryModal = action.payload
    },
    setIsVerifyPage: (state,action)=>{
      state.isVerifyPage = action.payload
    },
    setIsVerified:(state,action)=>{
      state.verified= action.payload
    },
    setRechargeTries:(state,action)=>{
      state.rechargeTries= action.payload
    },
    setIsScreenSubscription:(state,action)=>{
      state.isScreenSubscription= action.payload
    }
  },
});

export const {
  setIsSignedIn,
  setAuthToken,
  setUserData,
  setUserState,
  setIsSkippedState,
  setUserInformationState,
  setIsShowWelcomeModal,
  setStripeStatus,
  setOpenExpiryModal,
  setIsVerifyPage,
  setIsVerified,
  setRechargeTries,
  setIsScreenSubscription
} = loginSlice.actions;
const { reducer } = loginSlice;
export default reducer;
