import intlMessages from "../locales/en";
import api from "./index";
const BASE_URL_DEV = "https://quizbot.onescreensolutions.com:3000";
const API_VERSION = 1;
const BASE_API_PATH_PARAMS = `/api/v${API_VERSION}`;
const BASE_URL_CLIENT = window.location.origin;
const API_DELAY_TIME = 1500;

const getBaseUrlOfBrowser = () => {
  if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
    return BASE_URL_DEV;
  } else {
    return BASE_URL_CLIENT;
  }
};

async function getHomePageData() {
  try {
    const res = await api.get(`/quiz/`, {});
    return res;
  } catch (e) {
    return e;
  }
}

const checkInternetConnection = async () => {
  const isDataFetched = await getHomePageData();
  const networkErrorMessage = "No internet! Please check your connection.";
  const isNetworkError = isDataFetched.request.status === 0;
  const networkErrorData = {
    data: {
      statusCode: 0,
      message: networkErrorMessage,
    },
    isConnected: false,
  };
  if (isNetworkError) return networkErrorData;
  return { data: null, isConnected: true };
};

const extractBaseTokenFromURL = (urlString) => {
  const url = new URL(urlString);
  const token = url.searchParams.get("token");
  localStorage.setItem("authToken", token);
  return token;
};

const isFisrtTime = (urlString) => {
  const url = new URL(urlString);
  const version = url.searchParams.get("v");
  return !!version;
};

const getAuthToken = () => {
  const token = localStorage.getItem("authToken");
  return token;
};

const deleteAuthToken = () => {
  localStorage.removeItem("authToken");
};

const isUserSignedIn = () => {
  return !!getAuthToken();
};

const protectedRoutes = [
  "/user/user-info",
  "/user/changepassword",
  "/quiz/export-google",
  "/user/profile",
  "/profile",
  "/changepassword",
  "/user/user",
  "/quiz/export",
  "/library/save",
  "/library",
  "/quiz/import-drive",
  "/quiz/import-drive",
  "/quiz/generate-quiz",
  "/user/updateUserInfo",
  "/quiz/generate",
  "/user/verify/serialnumber",
  "/user/start/trial",
  "/user/downgrade/subscription",
  "/user/createcheckout/session",
  "/user/initiateDeleteAccount",
  "/user/cancelDeleteAccount",
  "/quiz/import-Url",
  "/quiz/generate-quiz",
  "/user/downgrade/subscription",
  "/user/billing/history",
  "/user/invoice",
  "/user/cancel/downgrade",
  "/quiz/scrybe-content"
];

const authUserNotAllowedRoutes = ["/login", "/signup", "/forgotpassword"];

const feedbackResponse = [
  {
    ratingText: intlMessages.reviewModalRatingOneText,
    ratingQuestion: intlMessages.reviewModalRatingOneQuestion,
  },
  {
    ratingText: intlMessages.reviewModalRatingTwoText,
    ratingQuestion: intlMessages.reviewModalRatingTwoQuestion,
  },
  {
    ratingText: intlMessages.reviewModalRatingThreeText,
    ratingQuestion: intlMessages.reviewModalRatingThreeQuestion,
  },
  {
    ratingText: intlMessages.reviewModalRatingFourText,
    ratingQuestion: intlMessages.reviewModalRatingFourQuestion,
  },
  {
    ratingText: intlMessages.reviewModalRatingFiveText,
    ratingQuestion: intlMessages.reviewModalRatingFiveQuestion,
  },
];

const feedbackOptions = [
  "Add content options",
  "Quiz export options",
  "Quiz quality",
  "Customization features",
  "Others",
];

export {
  BASE_URL_DEV,
  BASE_URL_CLIENT,
  API_VERSION,
  BASE_API_PATH_PARAMS,
  API_DELAY_TIME,
  getBaseUrlOfBrowser,
  checkInternetConnection,
  extractBaseTokenFromURL,
  getAuthToken,
  isUserSignedIn,
  protectedRoutes,
  authUserNotAllowedRoutes,
  deleteAuthToken,
  isFisrtTime,
  feedbackResponse,
  feedbackOptions,
};
