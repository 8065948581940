import { Box, Typography } from "@mui/material";
import intlMessages from "../../locales/en";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { useTheme } from "@mui/material/styles";
import { useNavigate, useLocation } from "react-router-dom";
import { conf } from "../../version";
import {
  COMPANY_NAME,
  FACEBOOK_LINK,
  INSTAGRAM_LINK,
  TWITTER_LINK,
  USER_STATE,
} from "../../settings";
import { Events, logEvent, Categories } from "../../analytics";
import { useEffect, useState } from "react";
import ListItemIcon from "@mui/material/ListItemIcon";
import Divider from "@mui/material/Divider";
import { deleteAuthToken } from "../../api/service";
import { useDispatch, useSelector } from "react-redux";
import {
  setAuthToken,
  setIsSignedIn,
  setUserData,
  setUserState,
} from "../../redux/slices/logInSlice";
import { userLogOut } from "../../common/sign_in_service";
import { getNavigateURL } from "../../util/signin-user-data";
import { setOpenLibrary } from "../../redux/slices/librarySlice";
import { setOpenSubscriptionModal } from "../../redux/slices/featuresSlice";
const ver = conf.version.replace("v", "Ver ");

export default function UserDrawerMenu(props) {
  const { userState, userData } = useSelector((state) => state.logIn);
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    isOpen,
    onClose,
    isIpad,
    isAboutPage,
    isIpadLandscape,
    isMobile,
    isMobileLandscape,
    isSignedIn,
    isSignUpPage,
    isGoogleForm,
    isLoginPage,
    userNameData,
    isForgotResetPage,
    anchorEl,
  } = props;
  const location = useLocation();
  const isProfilePage = location.pathname === "/profile";
  const isGenerateQuizPage = location.pathname === "/";
  const [hideLinks, setHideLinks] = useState(false);
  const handleCloseMenuBtn = () => {
    onClose(false);
  };

  useEffect(() => {
    // // console.log("props", props)
    const handleScroll = () => {
      handleCloseMenuBtn();
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    window.addEventListener("orientationchange", handleCloseMenuBtn);
    return () => {
      window.removeEventListener("orientationchange", handleCloseMenuBtn);
    };
  }, []);
  useEffect(() => {
    // // console.log("ismobile", isMobile);
    if (
      userState === USER_STATE.BLOCKED_USER_USER ||
      userState === USER_STATE.EXPIRED_USER ||
      userState === USER_STATE.NOT_VERIFIED_USER
    ) {
      setHideLinks(true);
    } else if (
      userState === USER_STATE.LOGGED_OUT_USER ||
      userState === USER_STATE.SIGNED_IN_USER
    ) {
      setHideLinks(false);
    }
  }, [userState]);

  const logout = () => {
    // deleteAuthToken();
    // dispatch(setAuthToken(''));
    // dispatch(setUserData(null));
    // dispatch(setIsSignedIn(false));
    // dispatch(setUserState(USER_STATE.LOGGED_OUT_USER));
    logEvent(
      Categories.user_management,
      Events.user_management_logout_click_btn
    );
    userLogOut();
    const loginURL = getNavigateURL("/login");
    navigate(loginURL);
  };

  const navigateToProfile = () => {
    logEvent(
      Categories.user_management,
      Events.user_management_profile_link_click
    );
    const profileURL = getNavigateURL("/profile");
    navigate(profileURL);
  };

  const handleOpenLibrary = () => {
    logEvent(Categories.quiz_library, Events.quiz_library_button_clicked);
    dispatch(setOpenLibrary(true));
  };

  const handleOpenSubscription = () => {
    logEvent(
      Categories.pricing_and_subscription_modal,
      Events.pricing_and_subscription_modal_btn_clicked
    );
    dispatch(setOpenSubscriptionModal(true));
  };

  return (
    <>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={isOpen}
        onClose={onClose}
        onClick={onClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            boxShadow: "10px 4px 15px 0px rgba(0, 0, 0, 0.45)",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display:
                isMobileLandscape && isGenerateQuizPage ? "none" : "block",
              position: "absolute",
              top: 0,
              right:
                isMobile ||
                (isMobileLandscape && (isAboutPage || isForgotResetPage))
                  ? "24%"
                  : isMobileLandscape && isProfilePage
                  ? "35%"
                  : "50%",
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{
          vertical:
            isMobileLandscape && !isAboutPage && !isForgotResetPage
              ? "top"
              : "top",
          horizontal:
            isMobileLandscape && !isAboutPage && !isForgotResetPage
              ? "right"
              : "right",
        }}
        anchorOrigin={{
          vertical:
            isMobileLandscape && !isAboutPage && !isForgotResetPage
              ? "top"
              : "bottom",
          horizontal:
            isMobileLandscape && !isAboutPage && !isForgotResetPage
              ? "left"
              : "right",
        }}
        style={{
          marginLeft:
            isMobileLandscape && isForgotResetPage
              ? "10px"
              : isMobileLandscape && isProfilePage
              ? "80px"
              : "unset",
          marginTop: isMobileLandscape && isProfilePage ? "25px" : "unset",
        }}

        // transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        // anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <MenuItem
          onClick={navigateToProfile}
          disabled={isProfilePage}
          sx={{ display: hideLinks ? "none" : "" }}
        >
          <ListItemIcon>
            <img src={"/svgs/profile_icon.svg"}></img>
          </ListItemIcon>
          <Typography
            variant="body1"
            style={{ fontSize: "16px", color: "#1A73E8", fontWeight: 400 }}
          >
            {intlMessages.profileLabel}
          </Typography>
        </MenuItem>
        <Divider sx={{ display: hideLinks ? "none" : "" }} />
        <MenuItem
          onClick={handleOpenLibrary}
          sx={{ display: hideLinks ? "none" : "" }}
        >
          <ListItemIcon>
            <img src={"/svgs/library-icon.svg"}></img>
          </ListItemIcon>
          <Typography
            variant="body1"
            style={{ fontSize: "16px", color: "#1A73E8", fontWeight: 400 }}
          >
            {intlMessages.libraryTitle}
          </Typography>
        </MenuItem>
        <Divider sx={{ display: hideLinks ? "none" : "" }} />
        <MenuItem
          onClick={handleOpenSubscription}
          sx={{ display: hideLinks ? "none" : "" }}
        >
          <ListItemIcon
            sx={{
              mt: userData?.isScreenSubscription === true ? "-30px" : "unset",
            }}
          >
            <img src={"/svgs/subscription.svg"}></img>
          </ListItemIcon>
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Typography
              variant="body1"
              style={{ fontSize: "16px", color: "#1A73E8", fontWeight: 400 }}
            >
              {intlMessages.subscriptionLabel}
            </Typography>
            <Typography
              variant="body1"
              style={{
                fontSize: "14px",
                color: "#333333B2",
                fontWeight: 400,
                lineHeight: "16.94px",
                display:
                  userData?.isScreenSubscription === true ? "block" : "none",
              }}
            >
              {intlMessages.subscriptionModalIPSText}
            </Typography>
            <Typography
              variant="body1"
              style={{
                fontSize: "14px",
                color: "#333333B2",
                fontWeight: 400,
                lineHeight: "16.94px",
                display: userData?.subscriptionPlan !== null ? "block" : "none",
              }}
            >
              {userData?.isScreenSubscription === true
                ? intlMessages.subscriptionModalIPSTrialText
                : userData?.stripeStatus === 0
                ? intlMessages.proTrialText
                .replace(
                  "#",
                  userData?.trialRemainingDays !== 0
                    ? userData?.trialRemainingDays
                    : userData?.trialRemainingHours != 0
                    ? userData?.trialRemainingHours
                    : userData?.trialRemainingMinutes
                )
                .replace(
                  "#2",
                  userData?.trialRemainingDays !== 0
                    ? intlMessages.daysLeftLabel
                    : userData?.trialRemainingHours != 0
                    ? intlMessages.hoursLeftLabel
                    : intlMessages.minutesLeftLabel
                )
                : userData?.subscriptionPlan === "Pro"
                ? userData?.pricePlan === "Monthly"
                  ? intlMessages.userMenuProMonthDesc.replace(
                      "#",
                      userData?.price
                    )
                  : intlMessages.userMenuProYearDesc.replace(
                      "#",
                      userData?.price
                    )
                : intlMessages.pricingTabBasicPlanLabel}
            </Typography>
          </Box>
        </MenuItem>
        <Divider sx={{ display: hideLinks ? "none" : "" }} />
        <MenuItem onClick={logout}>
          <ListItemIcon>
            <img src={"/svgs/logout_icon.svg"}></img>
          </ListItemIcon>
          <Typography
            variant="body1"
            style={{ fontSize: "16px", color: "#1A73E8", fontWeight: 400 }}
          >
            {intlMessages.verifyEmailLogOutBtnText}
          </Typography>
        </MenuItem>
      </Menu>
    </>
  );
}
