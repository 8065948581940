import palette from "./palette"

const themeBody = {
    background: `linear-gradient(90deg, #4E0F89 0%, #7D00BA 100%)`,
    '@media only screen and (max-width: 620px)':{
        backgroundColor: "#ffffff",
        backgroundImage: `none`,
    },
};
export default themeBody;
