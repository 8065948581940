import { useState, useEffect } from "react";
import { Button, Typography, Modal, IconButton, Box } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Styled from "./styles";
import CircularProgress from "@mui/material/CircularProgress";
import intlMessages from "../../locales/en";
import { useDispatch, useSelector } from "react-redux";
import { setQuizTitle } from "../../redux/slices/quizSlice";
import { Categories, Events, logEvent } from "../../analytics";
import { useNavigate, useSearchParams } from "react-router-dom";
import { isValidURL } from "../../components/quiz/service";

export default function TextfieldModal(props) {
  const {
    title,
    label,
    placeholder,
    btn1Text,
    btn2Text,
    open,
    onClose,
    onSubmit,
    emptyErrorMsg,
    lengthErrorMsg,
    invalidErrorMsg,
    pasteURL
  } = props;
  const { enableEditMode, enableShareMode } = useSelector((state) => state.library);
  const { quizTitle } = useSelector((state) => state.quiz);
  const [Title, setTitle] = useState("");
  const [error, setError] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    if (pasteURL===false && (enableEditMode || enableShareMode)) {
      setTitle(quizTitle);
    }
  }, [quizTitle]);

  const handleOnChange = (e) => {
    if (
      e.target.value?.trim()?.length > 0 ||
      error === emptyErrorMsg ||
      error === lengthErrorMsg
    ) {
      setError("");
    }
    setTitle(e.target.value);
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      handleOnSubmit()
    }
  };

  function validateName(name) {
    const nameRegex = /^\S.*$/;
    return nameRegex.test(name);
  }

  const quizTitleValidation = () => {
    if (Title.length === 0) {
      return setError(emptyErrorMsg);
    }
    if(isValidURL(Title)===false && pasteURL===true){
      return setError(invalidErrorMsg)
    }
    if(validateName(Title)===false && pasteURL===false){
      return setError(invalidErrorMsg)
    }
    if (Title.length > 150 && pasteURL===false) {
      return setError(lengthErrorMsg);
    }
    return true;
  };
  const handleRemoveQueryParams = () => {
    if (
      sessionStorage.getItem("save-quiz") &&
      sessionStorage.getItem("save-quiz") === "true"
    ) {
      searchParams.delete("save-quiz");
      sessionStorage.removeItem("save-quiz");
      navigate(`?${searchParams.toString()}`);
    }
  };
  const handleOnSubmit = () => {
    if(pasteURL===true){
      quizTitleValidation() &&
      onSubmit(false,!Title.match(/^(https?|ftp):\/\//i)?"https://"+Title:Title,pasteURL)
      logEvent(Categories.paste_web_url,Events.paste_web_url_confirm_button_clicked)
      return
    }
    handleRemoveQueryParams();
    if (quizTitleValidation()) {
      logEvent(
        Categories.quiz_library,
        Events.quiz_library_save_modal_confirm_button_clicked
      );
      dispatch(setQuizTitle(Title.trimEnd()));
      onSubmit(false, Title.trimEnd());
    }
  };

  const handleOnClose = () => {
    if(pasteURL===true){
    logEvent(Categories.paste_web_url,Events.paste_web_url_cancel_button_clicked)
    }
    else{
    handleRemoveQueryParams();
    logEvent(
      Categories.quiz_library,
      Events.quiz_library_save_modal_cancel_button_clicked
    );
    }
    setError("");
    onClose(false);
  };
  return (
    <Modal open={open}>
      <Styled.ModalBox sx={Styled.customStyling.exportModal}>
        <Styled.ModalHeader>
          <Typography
            variant="h5"
            component="h5"
            style={{ fontWeight: "bold" }}
          >
            {title}
          </Typography>
          <IconButton onClick={handleOnClose}>
            <CloseIcon color="seondary.light" />
          </IconButton>
        </Styled.ModalHeader>
        <Styled.ModalContentBox>
          <Typography
            color="secondary.dark"
            variant="body1"
            mt={2}
            style={{ fontSize: "14px", marginBottom: "10px" }}
          >
            {`${label} `}
          </Typography>
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Styled.largeTextField
              placeholder={placeholder}
              size="small"
              value={Title}
              variant="outlined"
              error={error ? true : false}
              helperText={error}
              onChange={handleOnChange}
              onKeyDown={handleKeyDown}
            ></Styled.largeTextField>
          </Box>
        </Styled.ModalContentBox>
        <Box>
          <Styled.ModalActions>
            <Styled.CreateQuizButton
              variant="contained"
              color="secondary"
              onClick={handleOnSubmit}
              //   loading={isSaving}
              loadingPosition="start"
              //   disabled={isSaving ? "true" : undefined}
              loadingIndicator={
                <CircularProgress
                  sx={{ ml: "25px" }}
                  color="inherit"
                  size={16}
                />
              }
              //   ref={createGoogleForm}
            >
              {btn1Text}
            </Styled.CreateQuizButton>
            <Button
              sx={{
                textTransform: "none",
                marginLeft: 1,
              }}
              variant="outlined"
              color="secondary"
              onClick={handleOnClose}
              //   disabled={isSaving ? "true" : undefined}
            >
              {btn2Text}
            </Button>
          </Styled.ModalActions>
        </Box>
      </Styled.ModalBox>
    </Modal>
  );
}
