import styled from "@mui/system/styled";
import { Grid,Box } from "@mui/material";

const HeaderMainContainer = styled(Grid)(({ theme }) => ({
    background: 'linear-gradient(90deg, #4E0F89 0%, #7D00BA 100%)',
    width:'71px',
    height:'320px',
    display:'flex',
    flexDirection:'column',
    alignItems:'center',
    justifyContent:'space-between',
    paddingTop:'12px',
    paddingBottom:'18px'

}));

const QuizWizLogo = styled('img')(({ theme }) => ({
    width: '44px',
    height: '44px',
    cursor:'pointer'
}))

const IconContainer = styled(Box)(({ theme }) => ({
    display:'flex',
    flexDirection:'column',
    alignItems:'center',
}))

const FeedbackLogo = styled('img')(({ theme }) => ({
    width: '31px',
    height: '31px',
}))

const DrawerLogo = styled('img')(({ theme }) => ({
    width: '27.62px',
    height: '17px',
}))


export default {
    HeaderMainContainer,
    QuizWizLogo,
    IconContainer,
    FeedbackLogo,
    DrawerLogo
};
