import { styled } from "@mui/material/styles";
import { MenuItem, Select, Typography } from "@mui/material";
import { Box } from "@mui/system";
import intlMessages from "../../locales/en";

const SelectInputBase = styled(Select)(
  ({ theme, isMobile, isMobileLandscape }) => ({
    padding: 0,
    paddingTop: isMobile || isMobileLandscape ? "unset" : "3px",
    paddingLeft: "10px",
    color: theme.palette.secondary.main,
    ".MuiSelect-select": {
      padding: 5,
      color: "#000000",
      // opacity: "0.5",
      outline: "none",
      border: "none",
      fontSize: "14px",
      fontWeight: 400,
    },
    ".MuiOutlinedInput-notchedOutline": {
      border: "none",
    },
    ".MuiSvgIcon-root": {
      color: "#222222",
    },
    ".MuiButtonBase-root-MuiMenuItem-root.Mui-selected": {
      backgroundColor: "none !important",
    },
    ".MuiMenu-paper": {
      backgroundColor: "red",
    },
    ".MuiSelect-icon": {
      marginTop: isMobile || isMobileLandscape ? "unset" : "3px",
    },
  })
);
const BoxContainer = styled(Box)(({}) => ({}));

const StyledMenuItem = styled(MenuItem)(
  ({ theme, isSelected, isUserInfoProps }) => ({
    fontSize: "14px",
    fontWeight: "400",
    fontFamily: "Open Sans",
    lineHeight: "24px",
    "&:hover": {
      opacity: isSelected || isUserInfoProps ? 1 : 0.7,
      backgroundColor: isUserInfoProps ? "unset" : "",
    },
    backgroundColor: isSelected
      ? "rgba(125, 194, 66, 0.20) !important"
      : "unset",
    color: isSelected ? "#7BBF42 !important" : "#333333",
    borderRadius: isSelected ? "4px" : "unset",
    display: "flex",
    justifyContent: "flex-start",
    height: "25px !important",
    marginLeft: "10px",
    marginRight: "10px",
  })
);

const StyledMenuItemLabel = styled(Typography)(({}) => ({
  width: "100%",
  display: "flex",
  justifyContent: "flex-start",
  color: "silver",
  fontSize: "0.9em",
}));

const MenuItemText = styled(Typography)(({}) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  fontSize: "14px",
  fontWeight: "400",
  fontFamily: "Open Sans",
  lineHeight: "24px",
}));

const SvgIcon = styled("img")(({ theme }) => ({
  height: 18,
  color: theme.palette.secondary.main,
  marginRight: 14,
  marginLeft: 5,
}));

const HorizontalDivider = styled(Box)(({}) => ({
  background: "gray",
  padding: 1,
  borderRadius: 5,
  width: "100%",
}));

const customStyling = function (listTitle) {
  return {
    menu: {
      ".MuiMenu-list": {
        backgroundColor: "#FFFFFF",
        padding: 0,
        paddingBottom: "15px",
        paddingTop: "15px",
      },
      ".MuiMenu-paper": {
        backgroundColor: "transparent !important",
        borderRadius: "4px",
      },
      ".MuiMenuItem-root": {
        height: "50px",
        "&.Mui-disabled": {
          color: "#333333",
          opacity: 1,
        },
        ".MuiButtonBase-root-MuiMenuItem-root.Mui-selected": {
          backgroundColor: "red",
        },
      },
      ".MuiPaper-root": {
        "@media (max-width:600px) and (orientation: portrait)": {
          left:
            listTitle === intlMessages.quizDifficultyLabel
              ? "7% !important"
              : listTitle === intlMessages.quizCountLabel
              ? "52.5% !important"
              : "6.75% !important",
        },
        "@media (min-width:1400px)": {
          left:
            listTitle === intlMessages.quizDifficultyLabel
              ? "19.8% !important"
              : listTitle === intlMessages.quizCountLabel
              ? "34.3% !important"
              : "5% !important",
        },
        "@media (min-width:1600px)": {
          left:
            listTitle === intlMessages.quizDifficultyLabel
              ? "19.3% !important"
              : listTitle === intlMessages.quizCountLabel
              ? "34.5% !important"
              : "4% !important",
        },
        "@media (max-width: 800px) and (orientation: landscape)": {
          left:
            listTitle === intlMessages.quizDifficultyLabel
              ? "32.5% !important"
              : listTitle === intlMessages.quizCountLabel
              ? "60.5% !important"
              : "2.5% !important",
              height:'150px'
        },
        "@media (max-width: 960px) and (min-width: 800px) and (orientation: landscape)":
          {
            left:
              listTitle === intlMessages.quizDifficultyLabel
                ? "33% !important"
                : listTitle === intlMessages.quizCountLabel
                ? "63.5% !important"
                : "3% !important",
                height:'150px'
          },
        "@media screen and (min-width: 1280px) and (max-width: 1280px)": {
          left:
            listTitle === intlMessages.quizDifficultyLabel
              ? "21% !important"
              : listTitle === intlMessages.quizCountLabel
              ? "35.5% !important"
              : "6% !important",
        },
        "@media (min-width:620px) and (max-width:840px) and (orientation: portrait) and (hover: none) and (pointer: coarse)":
          {
            left:
              listTitle === intlMessages.quizDifficultyLabel
                ? "9.5% !important"
                : listTitle === intlMessages.quizCountLabel
                ? "30.5% !important"
                : "9.5% !important",
          },
        "@media screen and (min-width: 1000px) and (max-width: 1400px) and (orientation: landscape) and  (hover: none) and (pointer: coarse)":
          {
            left:
              listTitle === intlMessages.quizDifficultyLabel
                ? "7.2% !important"
                : listTitle === intlMessages.quizCountLabel
                ? "27.5% !important"
                : "7.2% !important",
          },
          "@media screen and (max-width: 1280px) and (min-width: 1280px) and (max-height:540px) and (min-height:540px)":
          {
            left:
            listTitle === intlMessages.quizDifficultyLabel
              ? "21% !important"
              : listTitle === intlMessages.quizCountLabel
              ? "35.5% !important"
              : "6% !important",
          },
        // left: "5.8% !important",
      },
    },
    user_info_role: {
      ".MuiPaper-root": {
        padding: 0,
        // height:'50px',
        // width: "210px !important",
        // height:'100%'
        // marginTop:{sm:1.6, xs:0.2},
      },
      ".MuiList-root": {
        paddingTop: "0px",
      },
    },
  };
};
export default {
  SelectInputBase,
  StyledMenuItem,
  customStyling,
  StyledMenuItemLabel,
  SvgIcon,
  MenuItemText,
  BoxContainer,
  HorizontalDivider,
};
