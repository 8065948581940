import { extractBaseTokenFromURL, isFisrtTime } from "../api/service";

export default function CreateAndGetTokenminiWindow(url) {
  return new Promise((resolve, reject) => {
    const w = 600;
    const h = 480;
    const left = window.screen.width / 2 - w / 2;
    const top = window.screen.height / 2 - h / 2;
    const options = `width=${w},height=${h},left=${left},top=${top}`;
    const currentMiniWindow = window.open(
      `${url}`,
      "QuizWiz google signin",
      options
    );
    if (!currentMiniWindow) {
      // allow popup
      reject("allowpopup");
      return;
    }
    const checkMiniWindow = () => {
      if (currentMiniWindow && currentMiniWindow.closed) {
        clearInterval(miniWondowTimeInterval);
        const url = localStorage.getItem("miniWindowToken");
        if (!!url && url.indexOf("?token=") > 0) {
          const token = extractBaseTokenFromURL(url);
          const isFirstTimeUser = isFisrtTime(url);
          localStorage.removeItem("miniWindowToken");
          resolve({ token, isFirstTimeUser });
          return;
        }
        reject("error");
      } else if (currentMiniWindow) {
        try {
          let location = currentMiniWindow.location.href;
          // console.log(location);
          if (location.indexOf("?token=") > 0) {
            clearInterval(miniWondowTimeInterval);
            currentMiniWindow.close();
            const token = extractBaseTokenFromURL(location);
            const isFirstTimeUser = isFisrtTime(location);
            resolve({ token, isFirstTimeUser });
          }
          if (location.indexOf("error") > 0) {
            clearInterval(miniWondowTimeInterval);
            currentMiniWindow.close();
            reject("windowError");
          }
        } catch (error) {
          // console.error(error);
        }
      } else if (!currentMiniWindow) {
        clearInterval(miniWondowTimeInterval);
        reject("error");
      }
    };

    const miniWondowTimeInterval = setInterval(checkMiniWindow, 2000);
  });
}
