import { Box, IconButton,TableRow, TextField } from "@mui/material";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import styled from "@mui/system/styled";

const container = styled(Grid)(({isIpad,isIpadLandscape,theme,isMobile,isMobileLandscape})=>( {
  width: isMobile|| isMobileLandscape?'100%':isIpad?'85%':'65%',
  // height: '60%',
  top: isMobile|| isMobileLandscape?'unset':`48%`,
  left: isMobile|| isMobileLandscape?'unset':`50%`,
  position:isMobile|| isMobileLandscape?'unset':"absolute",
  transform: isMobile|| isMobileLandscape?'unset':`translate(-50%, -50%)`,
  // borderRadius: "8px !important",
  backgroundColor: "#F8F8F8",
  [`${theme.breakpoints.down("xxl")}  and (orientation: landscape) and (hover: none) and (pointer: coarse)`]: {
    width:'85%'
  },
  [`${theme.breakpoints.down("md")}`]: {
    width: isMobile|| isMobileLandscape?'100%':'95%'
  },
 }));

 const headingBar = styled(Box)(({})=>({
    height:'53px',
    backgroundColor:'white',
    display:'flex',
    flexDirection:'row',
    justifyContent:'space-between',
    alignItems:'center',
    paddingLeft:'30px',
    paddingRight:'20px',
    marginBottom:'30px',
    borderRadius: "8px !important",
 }))

 const heading = styled(Typography)(({})=>({
    fontFamily:'Open Sans',
    fontWeight:600,
    fontSize:'17px',
    lineHeight:'23.15px',
    color:'#333333',
 }))

 const crossBtn = styled(IconButton)(({})=>({
    width:'14px',
    height:'14px',
    color:'black',
}))

 const searchBox = styled(Box)(({})=>({
   display:'flex',
   flexDirection:'row',
   justifyContent:'space-between',
   alignItems:'center',
   margin:'0px 30px 15px 30px',
 }))

 const quizLabel = styled(Typography)(({})=>({
   fontFamily:'Open Sans',
   fontWeight:400,
   fontSize:'16px',
   lineHeight:'21.79px',
   color:'#333333',
 }))

 const searchField = styled(TextField)(
   ({ isMobile, isMobileLandscape }) => ({
     background: '#F6F6F6',
     '& .MuiInputBase-root': {
         background: '#F6F6F6',
         paddingLeft: "15px",
     },  
     '& input::placeholder': {
       color: '#000000',
       opacity: 1,
     },
     width:  isMobile?"188px":isMobileLandscape?"348px":"276px",
     borderRadius: "4px",
   })
 );

 const libraryTableContainer = styled(Box)(()=>({
   margin: '0px 30px 30px 30px',
   height:'35vh',
   overflow:'auto'
 }))

 const tableHeader = styled(TableRow)(({})=>({
   backgroundColor:'#333333',
   borderRadius:'4px'
 }))

 const tableheaderTitle = styled(Typography)(({})=>({
   fontFamily:'Open Sans',
   fontWeight:400,
   fontSize:'16px',
   lineHeight:'21.79px',
   color:'#FFFFFF',
 }))

 const titleAndIconContainer = styled(Box)(({})=>({
      display:'flex',
      flexDirection:'row',
      alignItems:'center',
      height:'56px'
 }))

 const iconContainer = styled(Box)(({})=>({
   display:'flex',
   flexDirection:'column',
   justifyContent:'center',
   marginLeft:"10px"
}))

const styledTableRow = styled(Box)(({})=>({
   display:'flex',
   flexDirection:'column',
   fontFamily:'Open Sans',
    fontWeight:400,
    color:'#333333',
}))

const actionBox = styled(Box)(({})=>({
  display:'flex',
  flexDirection:'row',
}))

const actionBtn = styled('img')(({})=>({
  marginRight:'15px',
  padding:0,
  height:'34px',
  width:'48px',
  cursor: 'pointer'
}))


 export default {
  container,
  headingBar,
  heading,
  crossBtn,
  searchBox,
  quizLabel,
  searchField,
  libraryTableContainer,
  tableHeader,
  tableheaderTitle,
  titleAndIconContainer,
  iconContainer,
  styledTableRow,
  actionBox,
  actionBtn
 }