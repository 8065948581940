import Styled from "./styles";
import FormControl from "@mui/material/FormControl";
import { Box } from "@mui/material";
import intlMessages from "../../locales/en";
import { useState, useEffect } from "react";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import {
  logEvent,
  Categories,
  Events,
  logPageView,
  initGA,
} from "./../../analytics";
import { useMediaQuery } from "@mui/material";
import { useSelector } from "react-redux";

export default function ImportMenu(props) {
  const { handleMenuItemSelection, isIpad, isMobile, isMobileLandscape } =
    props;
  const [open, setOpen] = useState(false);
  const { canExtractDataFromUrl}= useSelector((state)=>state.feature)

  const isIpadLandscape = useMediaQuery(
    "(min-width: 800px) and (max-width: 1400px) and (orientation: landscape) and  (hover: none) and (pointer: coarse)",
    {
      noSsr: true,
    }
  );
  const onClickMenuItem = (e) => {
    const clickedItemText = e.target.innerText;
    handleClose();
    handleMenuItemSelection(clickedItemText);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    logEvent(Categories.content_for_quiz, Events.import_menu_click);
    setOpen(true);
  };

  async function handleOrientationChange_() {
    setOpen(false);
  }
  useEffect(() => {
    window.addEventListener("orientationchange", handleOrientationChange_);
    return () => {
      window.removeEventListener("orientationchange", handleOrientationChange_);
    };
  }, []);

  return (
    <Styled.BoxContainer
      sx={{
        minWidth: isIpad ? 5 : 120,
        marginTop: isIpad ? "-22px" : "0px",
        marginRight: isIpad ? "-33px" : "unset",
        marginLeft: isMobile
          ? "-7%"
          : isMobileLandscape
          ? "-3%"
          : isIpadLandscape
          ? "-18px"
          : "-1%",
      }}
    >
      <FormControl
        sx={{
          paddingTop: isIpad ? "10px" : "unset",
          border: isIpad ? "none" : "0.7px solid #7dc242",
          borderRadius: 1,
          height: isMobile || isMobileLandscape ? "33.84px" : "42px",
          width: isMobile ? "85px" : "96px",
        }}
      >
        <Styled.SelectInputBase
          IconComponent={isIpad ? MoreVertIcon : ArrowDropUpIcon}
          value={""}
          displayEmpty
          open={open}
          onClose={handleClose}
          onOpen={handleOpen}
          isMobile={isMobile ? true : false}
          isMobileLandscape={isMobileLandscape ? true : false}
          MenuProps={{
            sx: Styled.customStyling.menu,
            anchorOrigin: {
              vertical: "top",
              horizontal: "left",
            },
            transformOrigin: {
              vertical: isIpad ? "top" : "bottom",
              horizontal: "left",
            },
            getContentAnchorEl: null,
          }}
        >
          {isIpad ? null : (
            <Styled.StyledMenuItem
              value={""}
              onClick={(e) => onClickMenuItem(e)}
              sx={{ display: "none", fontSize: "1.0666666666666667rem" }}
            >
              {intlMessages.quizImportBtnLabel}
            </Styled.StyledMenuItem>
          )}

          <Box>
            <Styled.StyledMenuItem
              onClick={(e) => onClickMenuItem(e)}
            >
              <Styled.MenuItemText
                sx={{
                  pointerEvents: "none",
                  pl:'11px',
                  pt:'2px'
                }}
              >
                <img
                  style={{ height: 20, marginRight: "9px", marginLeft: "3px" }}
                  src={"/svgs/upload-green.svg"}
                />
                {intlMessages.importModalHeadercapitalsmall}
              </Styled.MenuItemText>
            </Styled.StyledMenuItem>
            <Styled.HorizontalDivider
            />
            <Styled.StyledMenuItem
              sx={{ justifyContent:'center' }}
              onClick={(e) => onClickMenuItem(e)}
            >
              <Styled.MenuItemText sx={{ pointerEvents: "none" }}>
                <img
                  style={{ height: 20, marginRight: "9px" }}
                  src={"/svgs/import-green.svg"}
                />
                {intlMessages.quizImportSampleTextLabel}
              </Styled.MenuItemText>
            </Styled.StyledMenuItem>
            <Styled.HorizontalDivider
            />
            <Styled.StyledMenuItem
              sx={{ justifyContent:'flex-start',ml:'15px' }}
              onClick={(e) => onClickMenuItem(e)}
            >
              <Styled.MenuItemText sx={{ pointerEvents: "none" }}>
                <img
                  style={{ height: 20, marginRight: "9px" }}
                  src={"/svgs/Link.svg"}
                />
                {intlMessages.pasteURLModalHeader}
                {canExtractDataFromUrl.enable===false && <img style={{position:'absolute',right:"20px"}} src="/svgs/grey-crown.svg"></img>}
              </Styled.MenuItemText>
            </Styled.StyledMenuItem>
            {/* <Styled.HorizontalDivider sx={{mt:isMobile ||isMobileLandscape?'unset':2}} />
            <Styled.StyledMenuItem sx={{ml:2,mr:2,mb:-1,}} onClick={(e) => onClickMenuItem(e)}>
              <Styled.MenuItemText sx={{pointerEvents: "none",}}>
              <img style={{ height: 16, marginRight: "5px",marginLeft:'3px'}} src={"/svgs/Settings.svg"} />
                {intlMessages.questionsettings}
              </Styled.MenuItemText>
            </Styled.StyledMenuItem> */}
          </Box>
        </Styled.SelectInputBase>
      </FormControl>
    </Styled.BoxContainer>
  );
}
