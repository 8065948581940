import React, { useState, useEffect } from "react";
import { useTheme } from "@mui/material/styles";
import Footer from "../footer/component";
import intlMessages from "../../locales/en";
import Styled from "./styles";
import ConnectionModal from "../connection-modal/component";
import { Box, InputAdornment, useMediaQuery } from "@mui/material";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import DeleteAccountModal from "../delete-account-modal/component";
import { decodeToken, getToken, setToken } from "../../common/sign_in_service";
import {
  validateInput,
  validateName,
  validatePassword,
} from "../sign-up/service";
import {
  cancelDelete,
  updateUserAccountDetails,
  updateUserPassword,
} from "./service";
import AlertBox from "../alert/component";
import { Categories, Events, logEvent } from "../../analytics";
import { useDispatch, useSelector } from "react-redux";
import { setUserData } from "../../redux/slices/logInSlice";
import { useNavigate } from "react-router-dom";
import { getAuthToken } from "../../api/service";
import { getNavigateURL } from "../../util/signin-user-data";
import { ArrowDropDown } from "@mui/icons-material";
import UserInformation from "../user-information/component";

function UserProfile() {
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { userData, isSkipped, userInformation } = useSelector(
    (state) => state.logIn
  );
  const { navigateToQuizPage } = useSelector((state) => state.library);
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"), {
    noSsr: true,
  });
  const isMobileLandscape = useMediaQuery(
    `${theme.breakpoints.down(
      "md"
    )}  and (orientation: landscape) and (hover: none) and (pointer: coarse)`,
    {
      noSsr: true,
    }
  );

  const isIpad = useMediaQuery(
    window.navigator.userAgent.includes("OneScreen_TL7")
      ? "(min-width:620px) and (max-width:960px) and (orientation: landscape) "
      : "(min-width:620px) and (max-width:840px) and (orientation: portrait) and  (hover: none) and (pointer: coarse)",
    {
      noSsr: true,
    }
  );
  const isIpadLandscape = useMediaQuery(
    "(min-width: 1000px) and (max-width: 1400px) and (orientation: landscape) and  (hover: none) and (pointer: coarse)",
    {
      noSsr: true,
    }
  );
  const [showpassword, setShowPassword] = useState({
    currentPassword: false,
    newPassword: false,
    confirmPassword: false,
  });
  const [openConnectionModal, setIsOpenConnectionModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openAlertBox, setOpenAlertBox] = useState(false);
  const [provider, setProvider] = useState("");
  const [openUserInfoModal, setOpenUserInfoModal] = useState(false);

  const closeOpenUserInfoModal = () => {
    setOpenUserInfoModal(false);
  };

  const showOpenUserInfoModal = () => {
    logEvent(
      Categories.user_management,
      Events.user_managment_info_modal_opened_from_profile
    );
    setOpenUserInfoModal(true);
  };
  const [alertMsg, setAlertMsg] = useState({
    message: "",
    icon: "",
  });
  const [user, setUser] = useState({
    firstName: "",
    lastName: "",
    username: "",
  });
  const [accountDetailsError, setAcountDetailsError] = useState({
    firstName: "",
    lastName: "",
  });
  const [password, setPassword] = useState({
    currentPassword: "",
    newPassword: "",
    confirmPassword: "",
  });

  const [passwordError, setPasswordError] = useState({
    currentPassword: "",
    newPassword: "",
    confirmPassword: "",
  });
  const { authToken } = useSelector((state) => state.logIn);
  useEffect(() => {
    (async () => {
      const token = getToken();
      const data = await decodeToken(token);
      setUser({
        firstName: data?.firstName,
        lastName: data?.lastName,
        username: data?.username,
      });
      setProvider(data?.provider);
    })();
  }, [userData]);

  useEffect(() => {
    if (getAuthToken() === null) {
      const quizUrl = getNavigateURL("/");
      navigate(quizUrl);
    }
  }, [authToken]);

  const handleAccountDetailsChange = (e, key) => {
    const inp = validateInput(e.target.value);
    if (inp.length > 0) {
      if (
        accountDetailsError?.firstName === intlMessages.SignUpEmptyFieldsError
      ) {
        setAcountDetailsError({
          ...accountDetailsError,
          [key]: null,
          firstName: null,
        });
      } else {
        setAcountDetailsError({
          ...accountDetailsError,
          [key]: null,
        });
      }
    }
    // if (accountDetailsError[key] === intlMessages.signUpFirstNameEmptyMessage || accountDetailsError[key] === intlMessages.signUpLastNameEmptyMessage || inp.length < 200 && validateName(inp)) {
    //     setAcountDetailsError({
    //         [key]: null
    //     })
    // }
    setUser({
      ...user,
      [key]: inp,
    });
  };

  const passedAccountInpFieldsValidation = () => {
    if (user.firstName.length == 0 && user.lastName.length == 0) {
      return setAcountDetailsError({
        firstName: intlMessages.SignUpEmptyFieldsError,
        lastName: null,
      });
    }
    if (
      (user.firstName.length > 200 || !validateName(user.firstName)) &&
      (user.lastName.length > 200 || !validateName(user.lastName))
    ) {
      return setAcountDetailsError({
        firstName: intlMessages.SignUpInvalidFirstNameError,
        lastName: intlMessages.SignUpInvalidLastNameError,
      });
    }
    if (user.firstName.length == 0) {
      return setAcountDetailsError({
        ...accountDetailsError,
        firstName: intlMessages.signUpFirstNameEmptyMessage,
      });
    }
    if (user.firstName.length > 200 || !validateName(user.firstName)) {
      return setAcountDetailsError({
        ...accountDetailsError,
        firstName: intlMessages.SignUpInvalidFirstNameError,
      });
    }
    if (user.lastName.length == 0) {
      return setAcountDetailsError({
        ...accountDetailsError,
        lastName: intlMessages.signUpLastNameEmptyMessage,
        firstName: null,
      });
    }
    if (user.lastName.length > 200 || !validateName(user.lastName)) {
      return setAcountDetailsError({
        ...accountDetailsError,
        lastName: intlMessages.SignUpInvalidLastNameError,
        firstName: null,
      });
    }
    return true;
  };

  const handleAccountDetailsSubmission = async () => {
    if (passedAccountInpFieldsValidation()) {
      logEvent(
        Categories.user_management,
        Events.user_management_update_username
      );
      const response = await updateUserAccountDetails(user);
      if (response?.data?.statusCode === 0) {
        setIsOpenConnectionModal(true);
        return;
      }
      if (response?.response?.data?.statusCode === 400) {
        setAlertMsg({
          message: intlMessages.profileAccountDetailsErrorMsg,
          icon: "/svgs/erroricon.svg",
          isError: true,
        });
        setOpenAlertBox(true);
        return;
      }
      if (response?.status === 200) {
        setToken(response?.data?.access_token);
        const decodedData = await decodeToken(response?.data?.access_token);
        dispatch(setUserData(decodedData));
        setAlertMsg({
          message: intlMessages.profileAccountDetailsConfirmationMsg,
          icon: "/svgs/successicon.svg",
          isError: false,
        });
        setOpenAlertBox(true);
        return;
      }
    }
  };

  const handlePasswordChange = (e, key) => {
    const inp = validateInput(e.target.value);
    if (inp.length > 0) {
      if (
        passwordError?.confirmPassword ===
          intlMessages.SignUpEmptyFieldsError ||
        passwordError?.confirmPassword ===
          intlMessages.SignUpPasswordDontMatchError
      ) {
        setPasswordError({
          ...passwordError,
          [key]: null,
          confirmPassword: null,
        });
      } else {
        setPasswordError({
          ...passwordError,
          [key]: null,
        });
      }
    }
    // if (passwordError[key] === intlMessages.SignUpEmptyFieldsError || passwordError[key]  === intlMessages.profileNewPasswordEmptyMsg || passwordError[key]  === intlMessages.profileCurrentPasswordEmptyMsg || passwordError[key]  === intlMessages.signUpConfirmPasswordEmptyMessage || validatePassword(inp)) {
    //     setPasswordError({
    //         ...passwordError,
    //         [key]: null
    //     })
    // }
    setPassword({
      ...password,
      [key]: inp,
    });
  };

  const passwordFieldsValidation = () => {
    if (
      password.currentPassword.length === 0 &&
      password.newPassword.length === 0 &&
      password.confirmPassword.length === 0
    ) {
      return setPasswordError({
        currentPassword: null,
        newPassword: null,
        confirmPassword: intlMessages.SignUpEmptyFieldsError,
      });
    }
    if (password.currentPassword.length === 0) {
      return setPasswordError({
        ...passwordError,
        currentPassword: intlMessages.profileCurrentPasswordEmptyMsg,
        confirmPassword: null,
      });
    }
    // if(!validatePassword(password.currentPassword)){
    //     return setPasswordError({
    //         ...passwordError,
    //         currentPassword: intlMessages.profileCurrentPasswordIncorrectMsg,
    //         confirmPassword: null
    //     })
    // }
    if (password.newPassword.length === 0) {
      return setPasswordError({
        ...passwordError,
        newPassword: intlMessages.profileNewPasswordEmptyMsg,
        confirmPassword: null,
      });
    }
    if (!validatePassword(password.newPassword)) {
      return setPasswordError({
        ...passwordError,
        newPassword: intlMessages.SignUpInvalidPasswordError,
        confirmPassword: null,
      });
    }
    if (password.confirmPassword.length === 0) {
      return setPasswordError({
        ...passwordError,
        confirmPassword: intlMessages.signUpConfirmPasswordEmptyMessage,
      });
    }
    if (password.newPassword !== password.confirmPassword) {
      return setPasswordError({
        ...passwordError,
        confirmPassword: intlMessages.SignUpPasswordDontMatchError,
      });
    }

    return true;
  };

  const handlePasswordSubmission = async () => {
    // // console.log(passwordError);
    if (passwordFieldsValidation()) {
      logEvent(
        Categories.user_management,
        Events.user_management_update_password
      );
      const res = await updateUserPassword(password);
      if (res?.data?.statusCode === 0) {
        setIsOpenConnectionModal(true);
        return;
      }
      if (res?.status === 201 && res?.data?.error === "Invalid oldpassword") {
        return setPasswordError({
          ...passwordError,
          currentPassword: intlMessages.profileCurrentPasswordIncorrectMsg,
        });
      }
      if (
        res?.response?.data?.statusCode === 400 &&
        res?.response?.data?.message === "Invalid new password."
      ) {
        setAlertMsg({
          message: intlMessages.profilePasswordErrorMsg,
          icon: "/svgs/erroricon.svg",
          isError: true,
        });
        setOpenAlertBox(true);
        return;
      }
      if (res?.status === 201 && res?.data?.message === "Password updated") {
        setAlertMsg({
          message: intlMessages.profilePasswordUpdationMsg,
          icon: "/svgs/successicon.svg",
          isError: false,
        });
        setOpenAlertBox(true);
        return;
      }
    }
  };

  const handleKeyDownAccnt = (event) => {
    if (event.key === "Enter") {
      handleAccountDetailsSubmission();
    }
  };

  const handleKeyDownPassword = (event) => {
    if (event.key === "Enter") {
      handlePasswordSubmission();
    }
  };

  const handleDeleteBtn = () => {
    logEvent(
      Categories.user_management,
      Events.user_management_delete_account_btn_clicked
    );
    setOpenDeleteModal(true);
  };

  const handleCancelDeleteAccount = async () => {
    logEvent(
      Categories.user_management,
      Events.user_management_cancel_delete_account_btn_clicked
    );
    const res = await cancelDelete(user?.username);
    if (res?.data?.statusCode === 0) {
      setIsOpenConnectionModal(true);
      return;
    }
  };

  useEffect(() => {
    if (navigateToQuizPage === true) {
      navigate("/");
    }
  }, [navigateToQuizPage]);

  const [userRolesArray, setUserRolesArray] = useState(
    userInformation?.role[0] || ""
  );

  useEffect(() => {
    if (!!userInformation) {
      if (userInformation.role.length > 0) {
        setUserRolesArray(userInformation.role[0]);
      }
    }
  }, [userInformation]);

  return (
    <>
      <Box
        sx={{
          background: "linear-gradient(90deg, #4E0F89 0%, #7D00BA 100%)",
        }}
      >
        <Styled.MainContainer>
          {/* <Styled.backButton onClick={()=>navigate('/')} startIcon={<ArrowBackIcon />}>
                    {intlMessages.signUpBackBtn}
                </Styled.backButton> */}
          <Styled.formContainer>
            <Styled.profileLabel
              isMobile={isMobile}
              isMobileLandscape={isMobileLandscape}
            >
              {intlMessages.profileLabel}
            </Styled.profileLabel>
            <Styled.accountBox
              isMobile={isMobile ? true : false}
              isMobileLandscape={isMobileLandscape ? true : false}
              isIpad={isIpad ? true : false}
            >
              <Styled.heading
                isMobile={isMobile ? true : false}
                isMobileLandscape={isMobileLandscape ? true : false}
              >
                {intlMessages.profileAccountDetailsLabel}
              </Styled.heading>
              <Box
                sx={{
                  display: "flex",
                  flexDirection:
                    isMobile || isMobileLandscape ? "column" : "row",
                  mb:
                    (isMobile || isMobileLandscape) &&
                    accountDetailsError.lastName !== null &&
                    accountDetailsError.lastName.trim() !== ""
                      ? "30px"
                      : (isMobile || isMobileLandscape) &&
                        (accountDetailsError.lastName === null ||
                          accountDetailsError.lastName.trim() === "")
                      ? "20px"
                      : "35px",
                }}
              >
                <Styled.largeTextField
                  fullWidth
                  sx={{ display: provider === "local" ? "none" : "block" }}
                  isMobile={isMobile ? true : false}
                  isMobileLandscape={isMobileLandscape ? true : false}
                  required
                  variant="outlined"
                  disabled={true}
                  value={user?.firstName}
                  InputProps={{
                    readOnly: true,
                    startAdornment: (
                      <InputAdornment position="start">
                        <img src="/svgs/user-icon.svg" alt="Icon" />
                      </InputAdornment>
                    ),
                  }}
                ></Styled.largeTextField>
                <Styled.nameTextField
                  fullWidth
                  provider={provider === "local" ? true : false}
                  isMobile={isMobile ? true : false}
                  isMobileLandscape={isMobileLandscape ? true : false}
                  helperText={accountDetailsError.firstName}
                  error={accountDetailsError.firstName ? true : false}
                  onChange={(e) => handleAccountDetailsChange(e, "firstName")}
                  value={user?.firstName}
                  disabled={provider === "local" ? false : true}
                  sx={{
                    mr: isMobile || isMobileLandscape ? "unset" : "20px",
                    mb:
                      (isMobile || isMobileLandscape) &&
                      accountDetailsError.firstName !== null &&
                      accountDetailsError.firstName.trim() !== ""
                        ? "30px"
                        : (isMobile || isMobileLandscape) &&
                          (accountDetailsError.firstName === null ||
                            accountDetailsError.firstName.trim() === "")
                        ? "20px"
                        : "unset",
                  }}
                  required
                  variant="outlined"
                  placeholder={intlMessages.signUpFirstNamePlaceholder}
                  InputProps={{
                    readOnly: provider === "local" ? false : true,
                    startAdornment: (
                      <InputAdornment position="start">
                        <img src="/svgs/user-icon.svg" alt="Icon" />
                      </InputAdornment>
                    ),
                  }}
                  onKeyDown={handleKeyDownAccnt}
                ></Styled.nameTextField>
                <Styled.nameTextField
                  fullWidth
                  provider={provider === "local" ? true : false}
                  isMobile={isMobile ? true : false}
                  isMobileLandscape={isMobileLandscape ? true : false}
                  helperText={accountDetailsError.lastName}
                  error={
                    accountDetailsError.firstName ===
                    intlMessages.SignUpEmptyFieldsError
                      ? true
                      : accountDetailsError.lastName
                      ? true
                      : false
                  }
                  value={user?.lastName}
                  onChange={(e) => handleAccountDetailsChange(e, "lastName")}
                  required
                  variant="outlined"
                  disabled={provider === "local" ? false : true}
                  placeholder={intlMessages.signUpLastNamePlaceholder}
                  InputProps={{
                    readOnly: provider === "local" ? false : true,
                    startAdornment: (
                      <InputAdornment position="start">
                        <img src="/svgs/user-icon.svg" alt="Icon" />
                      </InputAdornment>
                    ),
                  }}
                  onKeyDown={handleKeyDownAccnt}
                ></Styled.nameTextField>
              </Box>
              <Styled.largeTextField
                isMobile={isMobile ? true : false}
                isMobileLandscape={isMobileLandscape ? true : false}
                required
                type="email"
                variant="outlined"
                disabled={true}
                placeholder={intlMessages.signUpEmailPlaceholder}
                value={user?.username}
                InputProps={{
                  readOnly: true,
                  startAdornment: (
                    <InputAdornment position="start">
                      <img src="/svgs/email-icon.svg" alt="Icon" />
                    </InputAdornment>
                  ),
                }}
              ></Styled.largeTextField>
              <Styled.saveBtn
                isIpad={isIpad ? true : false}
                isMobile={isMobile ? true : false}
                isMobileLandscape={isMobileLandscape ? true : false}
                onClick={handleAccountDetailsSubmission}
                sx={{ display: provider === "local" ? "block" : "none" }}
              >
                {intlMessages.profileSaveBtnText}
              </Styled.saveBtn>
            </Styled.accountBox>

            <Styled.divider
              provider={provider}
              isMobile={isMobile ? true : false}
              isMobileLandscape={isMobileLandscape ? true : false}
            ></Styled.divider>

            {/*================================== user information block========================================= */}
            <Styled.infoBox
              isMobile={isMobile ? true : false}
              isMobileLandscape={isMobileLandscape ? true : false}
              isIpad={isIpad ? true : false}
              height={!userInformation || userInformation.role.length == 0}
            >
              <Styled.heading
                isMobile={isMobile ? true : false}
                isMobileLandscape={isMobileLandscape ? true : false}
              >
                {intlMessages.userInfoModalProfile}
              </Styled.heading>
              <Styled.TextTypography
                isMobile={isMobile ? true : false}
                isMobileLandscape={isMobileLandscape ? true : false}
              >
                {intlMessages.userInfoModalRole}
              </Styled.TextTypography>
              <Styled.largeTextField
                fullWidth
                sx={{
                  display: "block",
                  mb: "15px",
                }}
                isMobile={isMobile ? true : false}
                isMobileLandscape={isMobileLandscape ? true : false}
                isInfoModal={true}
                required
                variant="outlined"
                disabled={true}
                value={
                  !userInformation || userInformation.role.length == 0
                    ? intlMessages.userInforModalRoleTitle
                    : userInformation.role.join(", ")
                }
                InputProps={{
                  readOnly: true,
                  endAdornment: (
                    <InputAdornment position="end">
                      <ArrowDropDown />
                    </InputAdornment>
                  ),
                }}
              ></Styled.largeTextField>
              <Box
                sx={{
                  display:
                    !userInformation || userInformation.role.length == 0
                      ? "none"
                      : "",
                }}
              >
                <Box
                  sx={{
                    display:
                      userRolesArray === intlMessages.userInfoModalRole3 ||
                      userRolesArray === intlMessages.userInfoModalRole4 ||
                      userRolesArray === intlMessages.userInfoModalRole5
                        ? "none"
                        : "",
                  }}
                >
                  <Styled.TextTypography
                    isMobile={isMobile ? true : false}
                    isMobileLandscape={isMobileLandscape ? true : false}
                  >
                    {intlMessages.userInfoModalGrade}
                  </Styled.TextTypography>
                  <Styled.largeTextField
                    fullWidth
                    sx={{
                      display: "block",
                      mb: "15px",
                    }}
                    isMobile={isMobile ? true : false}
                    isMobileLandscape={isMobileLandscape ? true : false}
                    isInfoModal={true}
                    required
                    variant="outlined"
                    disabled={true}
                    value={
                      !userInformation || userInformation.grade_list.length == 0
                        ? intlMessages.userInfoModalGrade0
                        : userInformation.grade_list.join(", ")
                    }
                    InputProps={{
                      readOnly: true,
                      endAdornment: (
                        <InputAdornment position="end">
                          <ArrowDropDown />
                        </InputAdornment>
                      ),
                    }}
                  ></Styled.largeTextField>
                </Box>

                <Box
                  sx={{
                    display:
                      userRolesArray === intlMessages.userInfoModalRole3 ||
                      userRolesArray === intlMessages.userInfoModalRole4 ||
                      userRolesArray === intlMessages.userInfoModalRole5
                        ? "none"
                        : "",
                  }}
                >
                  <Styled.TextTypography
                    isMobile={isMobile ? true : false}
                    isMobileLandscape={isMobileLandscape ? true : false}
                  >
                    {intlMessages.userInfoModalSubject}
                  </Styled.TextTypography>
                  <Styled.largeTextField
                    fullWidth
                    sx={{
                      display: "block",
                      mb: "15px",
                    }}
                    isMobile={isMobile ? true : false}
                    isMobileLandscape={isMobileLandscape ? true : false}
                    isInfoModal={true}
                    required
                    variant="outlined"
                    disabled={true}
                    value={
                      !userInformation || userInformation.subjects.length == 0
                        ? intlMessages.userInfoModalSubject0
                        : userInformation.subjects.join(", ")
                    }
                    InputProps={{
                      readOnly: true,
                      endAdornment: (
                        <InputAdornment position="end">
                          <ArrowDropDown />
                        </InputAdornment>
                      ),
                    }}
                  ></Styled.largeTextField>
                </Box>

                <Box
                  sx={{
                    display:
                      userRolesArray !== intlMessages.userInfoModalRole3
                        ? "none"
                        : "",
                  }}
                >
                  <Styled.TextTypography
                    isMobile={isMobile ? true : false}
                    isMobileLandscape={isMobileLandscape ? true : false}
                  >
                    {intlMessages.schoolName}
                  </Styled.TextTypography>
                  <Styled.largeTextField
                    fullWidth
                    sx={{
                      display: "block",
                      mb: "15px",
                    }}
                    isMobile={isMobile ? true : false}
                    isMobileLandscape={isMobileLandscape ? true : false}
                    isInfoModal={true}
                    required
                    variant="outlined"
                    disabled={true}
                    value={
                      !userInformation ||
                      userInformation?.schoolName?.length === 0
                        ? intlMessages.enterSchoolName
                        : userInformation.schoolName
                    }
                  ></Styled.largeTextField>
                </Box>
                <Box
                  sx={{
                    display:
                      userRolesArray !== intlMessages.userInfoModalRole4
                        ? "none"
                        : "",
                  }}
                >
                  <Styled.TextTypography
                    isMobile={isMobile ? true : false}
                    isMobileLandscape={isMobileLandscape ? true : false}
                  >
                    {intlMessages.orgName}
                  </Styled.TextTypography>
                  <Styled.largeTextField
                    fullWidth
                    sx={{
                      display: "block",
                      mb: "15px",
                    }}
                    isMobile={isMobile ? true : false}
                    isMobileLandscape={isMobileLandscape ? true : false}
                    isInfoModal={true}
                    required
                    variant="outlined"
                    disabled={true}
                    value={
                      !userInformation || userInformation?.orgName?.length === 0
                        ? intlMessages.orgNamePlaceday
                        : userInformation.orgName
                    }
                  ></Styled.largeTextField>
                </Box>

                <Box
                  sx={{
                    display:
                      userRolesArray === intlMessages.userInfoModalRole4 ||
                      userRolesArray === intlMessages.userInfoModalRole5
                        ? ""
                        : "none",
                  }}
                >
                  <Styled.TextTypography
                    isMobile={isMobile ? true : false}
                    isMobileLandscape={isMobileLandscape ? true : false}
                  >
                    {intlMessages.userInfoModalUse}
                  </Styled.TextTypography>
                  <Styled.largeTextField
                    fullWidth
                    sx={{
                      display: "block",
                      mb: "15px",
                    }}
                    isMobile={isMobile ? true : false}
                    isMobileLandscape={isMobileLandscape ? true : false}
                    isInfoModal={true}
                    required
                    variant="outlined"
                    disabled={true}
                    value={
                      !userInformation || userInformation.use?.length == 0
                        ? intlMessages.userInfoModalUseTitle
                        : userInformation.use?.join(", ")
                    }
                    InputProps={{
                      readOnly: true,
                      endAdornment: (
                        <InputAdornment position="end">
                          <ArrowDropDown />
                        </InputAdornment>
                      ),
                    }}
                  ></Styled.largeTextField>
                </Box>
                <Styled.TextTypography
                  isMobile={isMobile ? true : false}
                  isMobileLandscape={isMobileLandscape ? true : false}
                >
                  {intlMessages.userInfoModalState}
                </Styled.TextTypography>
                <Styled.largeTextField
                  fullWidth
                  sx={{
                    display: "block",
                    mb: "15px",
                  }}
                  isMobile={isMobile ? true : false}
                  isMobileLandscape={isMobileLandscape ? true : false}
                  isInfoModal={true}
                  required
                  variant="outlined"
                  disabled={true}
                  value={
                    !userInformation || userInformation.state.length == 0
                      ? intlMessages.userInfoModalState0
                      : userInformation.state.join(", ")
                  }
                  InputProps={{
                    readOnly: true,
                    endAdornment: (
                      <InputAdornment position="end">
                        <ArrowDropDown />
                      </InputAdornment>
                    ),
                  }}
                ></Styled.largeTextField>
              </Box>
              <Styled.saveBtn
                isIpad={isIpad ? true : false}
                isMobile={isMobile ? true : false}
                isMobileLandscape={isMobileLandscape ? true : false}
                isUserInfoBtn={true}
                onClick={showOpenUserInfoModal}
                sx={{ display: "block" }}
              >
                {intlMessages.editLabel}
              </Styled.saveBtn>
            </Styled.infoBox>
            <Styled.divider
              provider={provider}
              isMobile={isMobile ? true : false}
              isMobileLandscape={isMobileLandscape ? true : false}
            ></Styled.divider>
            <Styled.passwordBox
              provider={provider}
              isMobile={isMobile ? true : false}
              isMobileLandscape={isMobileLandscape ? true : false}
              isIpad={isIpad ? true : false}
            >
              <Styled.heading
                isMobile={isMobile ? true : false}
                isMobileLandscape={isMobileLandscape ? true : false}
              >
                {intlMessages.loginPasswordPlaceholderText}
              </Styled.heading>
              <Styled.largeTextField
                isMobile={isMobile ? true : false}
                isMobileLandscape={isMobileLandscape ? true : false}
                helperText={passwordError.currentPassword}
                onKeyDown={handleKeyDownPassword}
                error={
                  passwordError.confirmPassword ===
                  intlMessages.SignUpEmptyFieldsError
                    ? true
                    : passwordError.currentPassword
                    ? true
                    : false
                }
                onChange={(e) => handlePasswordChange(e, "currentPassword")}
                value={password?.currentPassword}
                sx={{
                  mb:
                    (isMobile || isMobileLandscape) &&
                    passwordError.currentPassword !== null &&
                    passwordError.currentPassword.trim() !== ""
                      ? "30px"
                      : (isMobile || isMobileLandscape) &&
                        (passwordError.currentPassword === null ||
                          passwordError.currentPassword.trim() === "")
                      ? "20px"
                      : "35px",
                }}
                required
                type={showpassword?.currentPassword ? "text" : "password"}
                variant="outlined"
                placeholder={intlMessages.profileCurrentPasswordPlaceholder}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <img src="/svgs/password-icon.svg" alt="Icon" />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">
                      {showpassword?.currentPassword ? (
                        <RemoveRedEyeOutlinedIcon
                          style={{ cursor: "pointer" }}
                          onClick={() =>
                            setShowPassword({
                              ...showpassword,
                              currentPassword: false,
                            })
                          }
                        />
                      ) : (
                        <img
                          style={{ cursor: "pointer" }}
                          onClick={() =>
                            setShowPassword({
                              ...showpassword,
                              currentPassword: true,
                            })
                          }
                          src="/svgs/eye-slash.svg"
                          alt="Icon"
                        />
                      )}
                    </InputAdornment>
                  ),
                }}
              ></Styled.largeTextField>
              <Styled.largeTextField
                isMobile={isMobile ? true : false}
                isMobileLandscape={isMobileLandscape ? true : false}
                helperText={passwordError.newPassword}
                error={
                  passwordError.confirmPassword ===
                    intlMessages.SignUpEmptyFieldsError ||
                  passwordError.confirmPassword ===
                    intlMessages.SignUpPasswordDontMatchError
                    ? true
                    : passwordError.newPassword
                    ? true
                    : false
                }
                onChange={(e) => handlePasswordChange(e, "newPassword")}
                onKeyDown={handleKeyDownPassword}
                value={password?.newPassword}
                sx={{
                  mb:
                    (isMobile || isMobileLandscape) &&
                    passwordError.newPassword !== null &&
                    passwordError.newPassword.trim() !== ""
                      ? "70px"
                      : (!isMobile || !isMobileLandscape) &&
                        passwordError.newPassword
                      ? "50px"
                      : (isMobile || isMobileLandscape) &&
                        (passwordError.newPassword === null ||
                          passwordError.newPassword.trim() === "")
                      ? "20px"
                      : "35px",
                }}
                required
                type={showpassword?.newPassword ? "text" : "password"}
                variant="outlined"
                placeholder={intlMessages.profileNewPasswordPlaceholder}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <img src="/svgs/password-icon.svg" alt="Icon" />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">
                      {showpassword?.newPassword ? (
                        <RemoveRedEyeOutlinedIcon
                          style={{ cursor: "pointer" }}
                          onClick={() =>
                            setShowPassword({
                              ...showpassword,
                              newPassword: false,
                            })
                          }
                        />
                      ) : (
                        <img
                          style={{ cursor: "pointer" }}
                          onClick={() =>
                            setShowPassword({
                              ...showpassword,
                              newPassword: true,
                            })
                          }
                          src="/svgs/eye-slash.svg"
                          alt="Icon"
                        />
                      )}
                    </InputAdornment>
                  ),
                }}
              ></Styled.largeTextField>
              <Styled.largeTextField
                sx={{
                  mb:
                    (isMobile || isMobileLandscape) &&
                    passwordError.confirmPassword !== null &&
                    passwordError.confirmPassword.trim() !== ""
                      ? "20px"
                      : "0px",
                }}
                isMobile={isMobile ? true : false}
                isMobileLandscape={isMobileLandscape ? true : false}
                helperText={passwordError.confirmPassword}
                error={passwordError.confirmPassword ? true : false}
                onChange={(e) => handlePasswordChange(e, "confirmPassword")}
                value={password?.confirmPassword}
                required
                type={showpassword?.confirmPassword ? "text" : "password"}
                onKeyDown={handleKeyDownPassword}
                variant="outlined"
                placeholder={intlMessages.signUpConfirmPasswordlaceholder}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <img src="/svgs/password-icon.svg" alt="Icon" />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">
                      {showpassword?.confirmPassword ? (
                        <RemoveRedEyeOutlinedIcon
                          style={{ cursor: "pointer" }}
                          onClick={() =>
                            setShowPassword({
                              ...showpassword,
                              confirmPassword: false,
                            })
                          }
                        />
                      ) : (
                        <img
                          style={{ cursor: "pointer" }}
                          onClick={() =>
                            setShowPassword({
                              ...showpassword,
                              confirmPassword: true,
                            })
                          }
                          src="/svgs/eye-slash.svg"
                          alt="Icon"
                        />
                      )}
                    </InputAdornment>
                  ),
                }}
              ></Styled.largeTextField>
              <Styled.saveBtn
                isMobile={isMobile ? true : false}
                isMobileLandscape={isMobileLandscape ? true : false}
                isIpad={isIpad ? true : false}
                onClick={handlePasswordSubmission}
              >
                {intlMessages.profileSaveBtnText}
              </Styled.saveBtn>
            </Styled.passwordBox>
            <Styled.divider
              provider={provider}
              isMobile={isMobile ? true : false}
              isMobileLandscape={isMobileLandscape ? true : false}
            ></Styled.divider>
            <Styled.deleteBox
              isMobile={isMobile ? true : false}
              isMobileLandscape={isMobileLandscape ? true : false}
              isIpad={isIpad ? true : false}
            >
              <Styled.heading
                isMobile={isMobile ? true : false}
                isMobileLandscape={isMobileLandscape ? true : false}
                sx={{ mb: "10px" }}
              >
                {intlMessages.profileDeleteAccountLabel}
              </Styled.heading>
              <Styled.deleteLongDesc
                isMobile={isMobile ? true : false}
                isMobileLandscape={isMobileLandscape ? true : false}
              >
                {intlMessages.profileDeleteAccountDesc}
                <li>{intlMessages.profileDeleteAccountDesc2}</li>
                <li>{intlMessages.profileDeleteAccountDesc3}</li>
                <li>{intlMessages.profileDeleteAccountDesc4}</li>
                <li>{intlMessages.profileDeleteAccountDesc5}</li>
              </Styled.deleteLongDesc>
              {userData?.isDeleted === 0 && (
                <Styled.deleteBtn
                  isMobile={isMobile ? true : false}
                  isMobileLandscape={isMobileLandscape ? true : false}
                  isIpad={isIpad ? true : false}
                  onClick={handleDeleteBtn}
                  disableBtn={userData?.isDeleted === 1 ? true : false}
                >
                  {intlMessages.profileDeleteAccountBtnText}
                </Styled.deleteBtn>
              )}
              <Box
                sx={{
                  display: userData?.isDeleted === 1 ? "flex" : "none",
                  flexDirection: isMobile ? "column" : "row",
                  justifyContent: isMobile ? "flex-start" : "flex-start",
                  alignItems: isMobile ? "flex-start" : "center",
                  ml: isMobile ? "unset" : "-55px",
                }}
              >
                <Styled.deleteBtn
                  isMobile={isMobile ? true : false}
                  isMobileLandscape={isMobileLandscape ? true : false}
                  isIpad={isIpad ? true : false}
                  onClick={handleDeleteBtn}
                  disableBtn={userData?.isDeleted === 1 ? true : false}
                  disabled={userData?.isDeleted === 1 ? true : false}
                >
                  {intlMessages.profileDeleteAccountBtnText}
                </Styled.deleteBtn>
                <Styled.cancelBtn onClick={handleCancelDeleteAccount}>
                  {intlMessages.profileDeleteAccountCancelBtnText}
                </Styled.cancelBtn>
              </Box>
              <Styled.DeleteDisclaimerText
                sx={{
                  display: userData?.isDeleted === 1 ? "block" : "none",
                  marginRight: "auto",
                  marginLeft: "100px",
                }}
              >
                {intlMessages.profileDeleteAccountDisclaimer}
              </Styled.DeleteDisclaimerText>
            </Styled.deleteBox>
          </Styled.formContainer>
        </Styled.MainContainer>
        <Footer></Footer>
      </Box>
      {openConnectionModal && (
        <ConnectionModal
          isOpen={openConnectionModal}
          handleCloseConnectionModal={() => setIsOpenConnectionModal(false)}
        />
      )}
      {openDeleteModal && (
        <DeleteAccountModal
          isIpad={isIpad ? true : false}
          isMobile={isMobile ? true : false}
          isMobileLandscape={isMobileLandscape ? true : false}
          isIpadLandscape={isIpadLandscape ? true : false}
          alertBox={(flag) => setOpenAlertBox(flag)}
          errorMsg={(msg) => setAlertMsg(msg)}
          OpenConnectionModal={(flag) => setIsOpenConnectionModal(flag)}
          userEmail={user?.username}
          isOpen={openDeleteModal}
          onClose={() => setOpenDeleteModal(false)}
        />
      )}
      {openAlertBox && (
        <AlertBox
          isIpad={isIpad ? true : false}
          isMobile={isMobile ? true : false}
          isMobileLandscape={isMobileLandscape ? true : false}
          isIpadLandscape={isIpadLandscape ? true : false}
          message={alertMsg}
          isOpen={openAlertBox}
          onClose={() => {
            setPassword({
              currentPassword: "",
              newPassword: "",
              confirmPassword: "",
            });
            setOpenAlertBox(false);
          }}
        ></AlertBox>
      )}

      {openUserInfoModal && (
        <UserInformation
          openUserInformationModal={openUserInfoModal}
          closeUserInformationModal={closeOpenUserInfoModal}
          isMobile={isMobile}
          isEditModal={true}
          isFirstTime={false}
          isMobileLandscape={isMobileLandscape}
          isIpad={isIpad}
          isIpadLandscape={isIpadLandscape}
        />
      )}
    </>
  );
}

export default UserProfile;
