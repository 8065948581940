import palette from "./palette";
import typography from "./typography";
import breakpoints from "./breakpoints";
import font from "./font";
import body from "./body";

import { createTheme } from "@mui/material/styles";

const myTheme = {
  palette,
  typography,
  breakpoints,
  components: {
    MuiCssBaseline: {
      styleOverrides:{
        "@font-face": [font],
        body,
        '@media (max-width:620px)': {
          "html, body, #root":{
            height:'100%',
          },
        },
        "textarea": {   
          cursor: "auto",
        },
        '*::-webkit-scrollbar': {
          width: '0.5em',
          paddingTop:'0.2px',
          paddingBottom:'0.2px',
        },
        '*::-webkit-scrollbar-track': {
          '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)',
          paddingTop:'0.2px',
          paddingBottom:'0.2px',
        },
        '*::-webkit-scrollbar-thumb': {
          backgroundColor: 'rgba(0,0,0,.1)',
          borderRadius:'5px',
          paddingTop:'0.2px',
          paddingBottom:'0.2px',
        }

      }
    },
    MuiButton: {
      styleOverrides: {
        contained: {
          '&:hover': {
            'background-color':palette.secondary.main,
            'color':'#fff'
          },
          'color':'#fff'
        },
        root: {
          '@media (max-width:960px)  and (orientation: landscape) and (hover: none) and (pointer: coarse)': {
            fontSize: '0.8rem',
            paddingTop:4,
            paddingBottom:4
          },
          '@media (max-width:620px)': {
            fontSize: '0.78rem',
          },
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
          input: {
            '@media (max-width:960px)  and (orientation: landscape) and (hover: none) and (pointer: coarse)': {
              fontSize: '0.8rem',
            },
            '@media (max-width:800px)': {
              fontSize: '0.9rem',
            },
          }
      }
  }
  }
};
export default {
  default: createTheme(myTheme),
};
