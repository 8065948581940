import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  callStatusMessageMap,
  createCall,
  updateCallStatus,
  pollCallStatus,
} from "../../common/guru/service";

const initialState = {
  callStatusString: "callStatusMessageMap(0)",
  refId: "",
  disableBtns: false,
  cancelClick: false,
  callHref: "",
  callConnected: false,
  userDetails: {},
  callInProgress: false,
  callData: {},
};

// thungs will be polling,update,createcall

export const createGuruCall = createAsyncThunk(
  "create_guru_call_status",
  async (payload, thunkApi) => {
    try {
      const res = await createCall(payload);
      if (res?.status === 200 && res.statusText === "OK") {
        const data = res?.data;
        if (data) {
          return data;
        } else {
          return thunkApi.rejectWithValue("Payload is undefined");
        }
      }
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const updateCallAPI = createAsyncThunk(
  "update_guru_call_status",
  async (payload, thunkApi) => {
    try {
      const res = await updateCallStatus(payload);
      if (res?.status === 200 && res.statusText === "OK") {
        const data = res?.data;
        if (data) {
          return data;
        } else {
          return thunkApi.rejectWithValue("Payload is undefined");
        }
      }
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const pollCallAPI = createAsyncThunk(
  "poll_guru_call_status",
  async (payload, thunkApi) => {
    try {
      const res = await pollCallStatus(payload);
      if (res?.status === 200 && res.statusText === "OK") {
        const data = res?.data;
        if (data) {
          return data;
        } else {
          return thunkApi.rejectWithValue("Payload is undefined");
        }
      }
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

const guruSlice = createSlice({
  name: "guru",
  initialState,
  reducers: {
    setCallStatusString: (state, action) => {
      state.callStatusString = action.payload;
    },
    setRefId: (state, action) => {
      state.refId = action.payload;
    },
    setDisableBtns: (state, action) => {
      state.disableBtns = action.payload;
    },
    setCancelClick: (state, action) => {
      state.cancelClick = action.payload;
    },
    setCallHref: (state, action) => {
      state.callHref = action.payload;
    },
    setCallConnected: (state, action) => {
      state.callConnected = action.payload;
    },
    setUserDetailsGuru: (state, action) => {
      state.userDetails = action.payload;
    },
    setCallInProgress: (state, action) => {
      state.callInProgress = action.payload;
    },
    setCallData: (state, action) => {
      state.callData = action.payload;
    },
    setResetStates: (state, action) => {
      // state.callStatusString = callStatusMessageMap(0);
      state.refId = "";
      state.disableBtns = false;
      state.cancelClick = false;
      state.callHref = "";
      state.callConnected = false;
      state.userDetails = {};
      state.callInProgress = false;
      state.callData = {};
    },
  },
  extraReducers: {
    [createGuruCall.fulfilled]: (state, action) => {
      if (action.payload.data) {
        const { referenceId } = action.payload.data;
        state.refId = referenceId;
        if (!state.cancelClick)
          state.callStatusString = callStatusMessageMap(0);
        state.callInProgress = true;
      } else {
        state.callStatusString = callStatusMessageMap(4);
        state.callInProgress = false;
      }
    },
    [createGuruCall.rejected]: (state, action) => {
      state.callStatusString = callStatusMessageMap(4);
    },
    [updateCallAPI.fulfilled]: (state, action) => {
      if (action?.payload?.data) {
        state.setCallData = {};
        // setResetStates();
        // state.callStatusString = callStatusMessageMap(0);
      } else {
        state.callStatusString = callStatusMessageMap(4);
      }
    },
    [updateCallAPI.rejected]: (state, action) => {
      state.callStatusString = callStatusMessageMap(4);
    },
    [pollCallAPI.rejected]: (state, action) => {
      state.callStatusString = callStatusMessageMap(4);
    },
  },
});

export const {
  setCallConnected,
  setUserDetailsGuru,
  setCallHref,
  setCallStatusString,
  setCancelClick,
  setDisableBtns,
  setRefId,
  setResetStates,
  setCallInProgress,
  setCallData,
} = guruSlice.actions;
const { reducer } = guruSlice;
export default reducer;
