import React, { useEffect, useState } from "react";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import CloseIcon from "@mui/icons-material/Close";
import Styled from "./styles";
import CircularProgress from "@mui/material/CircularProgress";
import intlMessages from "../../locales/en";
import {
  FormGroup,
  FormHelperText,
  IconButton,
  FormControl,
} from "@mui/material";
import {
  validateInput,
  validateEmail,
  getInternetConnectionStatus,
  setUserData,
  getUserData,
  updateUserData,
} from "../review/service";
import { saveSelectedQuzies } from "./service";

import { validateEmailList } from "./service";

export default function ShareModal(props) {
  const {
    isOpen,
    closeShareModalCallback,
    openConnectionErrorCallback,
    quizData,
  } = props;

  const [openShareModal, setopenShareModal] = useState(isOpen);
  const [shareWithoutAnswers, setshareWithoutAnswers] = React.useState(false);
  const [shareWithAnswers, setshareWithAnswers] = React.useState(false);
  const [emailList, setemailList] = React.useState("");
  const [description, setdescription] = React.useState("");
  const [isSaving, setIsSaving] = React.useState(false);
  const [sharedQuiz, setSharedQuiz] = React.useState(false);
  const [errors, setErrors] = useState({
    reviewEmailError: null,
    reviewRatingError: null,
    reviewCommentError: null,
    checkBoxError: null,
  });
  const { reviewEmailError, shareEmailError, checkBoxError } = errors;
  const [review, setReview] = useState({
    rating: 0,
    email: "",
    comment: "",
  });

  useEffect(() => {
    setSharedQuiz(false);
    const userData = getUserData();
    if (userData) {
      const { email } = userData;
      const { rating } = userData;
      setReview({
        email: email ? email : "",
        rating: rating ? rating : 0,
      });
    }
  }, []);

  // useEffect(() => {
  //   handleCheckBoxErrorState();
  // }, [shareWithAnswers, shareWithoutAnswers]);

  const closeShareModal = () => {
    setSharedQuiz(false);
    setSharedQuiz(false);
    closeShareModalCallback();
  };

  const onChangeEmail = (e) => {
    const inputText = validateInput(e.target.value);
    const { reviewEmailError } = errors;
    if (
      (inputText.length > 0 &&
        reviewEmailError === intlMessages.emptyEmailError) ||
      (validateEmail(inputText) &&
        reviewEmailError === intlMessages.incorrectEmailFormat)
    ) {
      setErrors({ ...errors, reviewEmailError: null });
    }
    setReview({ ...review, email: inputText });
  };

  const callbackUpdateUserData = (uEmail, uRating) => {
    const uData = getUserData();
    const userData = {
      email: uEmail,
      rating: uRating,
    };
    if (uData) {
      updateUserData(userData);
    } else {
      setUserData(userData);
    }
  };

  const handlesetshareWithoutAnswersChange = (event) => {
    // console.log(event.target.checked);
    setshareWithoutAnswers(event.target.checked);
  };

  const handlesetshareWithAnswersChange = (event) => {
    // console.log(event.target.checked);
    setshareWithAnswers(event.target.checked);
  };

  const handleCheckBoxErrorState = () => {
    if ((shareWithAnswers || shareWithoutAnswers) && !!checkBoxError) {
      return setErrors({
        ...errors,
        checkBoxError: null,
      });
    }
  };

  const shareContent = async () => {
    // save all settings for quiz settings
    setIsSaving(true);
    if (!shareWithAnswers && !shareWithoutAnswers) {
      setIsSaving(false);
      return setErrors({
        ...errors,
        checkBoxError: intlMessages.shareAtleastOneFileCheckRequired,
      });
    }
    const { email } = review;
    if (email.length === 0) {
      setIsSaving(false);
      return setErrors({
        ...errors,
        reviewEmailError: intlMessages.emptyEmailError,
      });
    }
    if (!validateEmail(email)) {
      setIsSaving(false);
      return setErrors({
        ...errors,
        reviewEmailError: intlMessages.incorrectEmailFormat,
      });
    }
    callbackUpdateUserData(email, 2);
    if (emailList.length == 0) {
      setIsSaving(false);
      return setErrors({
        ...errors,
        shareEmailError: intlMessages.emptyEmailError,
      });
    }

    if (!validateEmailList(emailList)) {
      setIsSaving(false);
      return setErrors({
        ...errors,
        shareEmailError: intlMessages.incorrectEmailFormat,
      });
    }
    const internetConnection = await getInternetConnectionStatus();
    if (!internetConnection?.isConnected) {
      setIsSaving(false);
      return openConnectionErrorCallback();
    }
    let shareEmailType = null;
    if (shareWithAnswers && shareWithoutAnswers) {
      shareEmailType = "2";
    } else if (shareWithAnswers && !shareWithoutAnswers) {
      shareEmailType = "1";
    } else if (!shareWithAnswers && shareWithoutAnswers) {
      shareEmailType = "0";
    }
    const { quiz_id, quiz_hash } = quizData;
    let data = quizData.questionAndAnswers.filter((item) => item.isChecked);
    //await saveFeedBack(quizData.exportData.id, userReview);
    let resp = await saveSelectedQuzies(
      data,
      "email",
      quiz_id,
      quiz_hash,
      true,
      review.email,
      emailList,
      shareEmailType
    );
    // console.log("resp", resp);
    setTimeout((e) => {
      setIsSaving(false);
      setSharedQuiz(true);
    }, 300);
  };

  return (
    <Modal
      open={openShareModal}
      aria-labelledby="review-modal"
      aria-describedby="review-description"
    >
      <Styled.ModalBox sx={Styled.customStyling.exportModal}>
        <Styled.ModalHeader>
          <Typography
            id="review-modal-title"
            variant="h5"
            component="h5"
            style={{ fontWeight: "bold" }}
          >
            {intlMessages.shareQuizTitle}
          </Typography>
          <IconButton
            aria-label="closeReviewModal"
            onClick={() => {
              closeShareModal();
            }}
          >
            <CloseIcon color="seondary.light" />
          </IconButton>
        </Styled.ModalHeader>
        <Styled.ModalContentBox>
          <Box
            sx={{
              display: sharedQuiz ? "flex" : "none",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <img
              style={{ height: 74, width: 74 }}
              src={"/svgs/share_tick_icon.svg"}
            />
            <Typography
              color="secondary.main"
              variant="body1"
              mt={2}
              style={{ fontSize: "16px" }}
            >
              {`${intlMessages.shareQuizSuccess}`}
            </Typography>
          </Box>
          <Box sx={{ display: !sharedQuiz ? "" : "none" }}>
            <Typography
              color="secondary.dark"
              variant="body1"
              mb={1}
              style={{ fontSize: "14px" }}
            >
              {`${intlMessages.shareQuizWithOthersLabel}`}
            </Typography>
            <Box mt={1.4} sx={{ display: "flex", flexDirection: "column" }}>
              <FormControl error={checkBoxError ? true : false}>
                <FormGroup>
                  <Styled.StyledFormControlLabel
                    value={shareWithoutAnswers}
                    onChange={handlesetshareWithoutAnswersChange}
                    control={<Styled.CheckBoxBtn />}
                    label={intlMessages.shareQuizWithoutanswers}
                  />
                  <Styled.StyledFormControlLabel
                    value={shareWithAnswers}
                    onChange={handlesetshareWithAnswersChange}
                    control={<Styled.CheckBoxBtn />}
                    label={intlMessages.shareQuizWithanswers}
                  />
                </FormGroup>
                {!!checkBoxError && (
                  <FormHelperText>
                    {intlMessages.shareAtleastOneFileCheckRequired}
                  </FormHelperText>
                )}
              </FormControl>
            </Box>
            <Typography
              color="secondary.dark"
              variant="body1"
              mt={2}
              style={{ fontSize: "13px" }}
            >
              {`${intlMessages.shareEnterYourEmail} `}
            </Typography>
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Styled.ModalTextField
                value={review.email}
                placeholder={`${intlMessages.emailLabel} *`}
                type="email"
                required
                helperText={reviewEmailError}
                error={reviewEmailError ? true : false}
                onChange={onChangeEmail}
              />
            </Box>
            <Typography
              color="secondary.dark"
              variant="body1"
              mt={2}
              style={{ fontSize: "13px" }}
            >
              {`${intlMessages.shareEnterEmailAddress} `}
              <Typography color="secondary.dark" variant="caption">
                {" "}
                {`${intlMessages.shareEmailAddressSmall} `}{" "}
              </Typography>
            </Typography>
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Styled.ModalTextArea
                value={emailList}
                placeholder={`${intlMessages.shareEmailPlaceholderText} *`}
                type="email"
                multiline
                rows={4}
                required
                helperText={shareEmailError}
                error={shareEmailError ? true : false}
                inputProps={{
                  style: {
                    fontSize: "12px",
                    padding: "0px 5px",
                    height: "65px",
                  },
                }}
                onChange={(e) => setemailList(e.target.value)}
              />
            </Box>
            <Typography
              color="secondary.dark"
              variant="body1"
              mt={2}
              style={{ fontSize: "12px" }}
            >
              {`${intlMessages.shareDescription} `}
              <Typography color="secondary.dark" variant="caption">
                {" "}
                {`${intlMessages.shareSmallDescription} `}{" "}
              </Typography>
            </Typography>
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Styled.ModalTextArea
                value={description}
                type="email"
                multiline
                rows={4}
                inputProps={{
                  style: {
                    fontSize: "12px",
                    padding: "0px 5px",
                    height: "53px",
                  },
                }}
                onChange={(e) => setdescription(e.target.value)}
              />
            </Box>
          </Box>
        </Styled.ModalContentBox>
        <Styled.ModalActions>
          <Styled.CreateQuizButton
            variant="contained"
            color="secondary"
            loading={isSaving}
            loadingPosition="start"
            disabled={isSaving ? "true" : undefined}
            onClick={() => {
              shareContent();
            }}
            loadingIndicator={<CircularProgress color="inherit" size={16} />}
            sx={{
              display: !sharedQuiz ? "" : "none",
            }}
          >
            {intlMessages.shareLabel}
          </Styled.CreateQuizButton>
          <Button
            sx={{
              textTransform: "none",
              marginLeft: 1,
              display: !sharedQuiz ? "" : "none",
            }}
            variant="outlined"
            color="secondary"
            disabled={false ? "true" : undefined}
            onClick={() => {
              closeShareModal();
            }}
          >
            {intlMessages.cancelLabel}
          </Button>
          <Styled.CreateQuizButton
            variant="contained"
            color="secondary"
            onClick={() => {
              closeShareModal();
            }}
            sx={{ display: sharedQuiz ? "" : "none" }}
          >
            {intlMessages.closeLabel}
          </Styled.CreateQuizButton>
        </Styled.ModalActions>
      </Styled.ModalBox>
    </Modal>
  );
}
