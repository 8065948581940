import { IconButton, MenuItem } from "@mui/material";
import styled from "@mui/system/styled";


const IconBtn = styled(IconButton)(({theme,isMobileLandscape})=>({
    color:'#757575',
    width:'6px',
    height:'20px',
    marginTop:isMobileLandscape?'-5px':'unset'
}))

const menuItem = styled(MenuItem)(({theme,isMobileLandscape})=>({
    fontSize:'14px',
    fontWeight:'600',
    lineHeight:'19.07px',
    fontFamily:'Open Sans',
   height:isMobileLandscape?'unset':'24px'
}))


export default {
    IconBtn,
    menuItem,
  };
  