import styled from "@mui/system/styled";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { Button, Divider, Link, Typography } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";

const ModalHeader = styled(Box)(({ theme, isSignedIn }) => ({
  padding: 8,
  paddingRight: 12,
  boxShadow: "rgba(100, 100, 111, 0.2) 0px 3px 29px 0px",
  paddingLeft: 25,
  alignItems: "center",
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
}));

const ModalActions = styled(Box)({
  padding: 5,
  paddingTop: 25,
  paddingBottom: 15,
  borderTop: "1px solid silver",
  display: "flex",
  justifyContent: "center",
});

const SubmitButton = styled(Button)(({ theme, isEditModal }) => ({
  color: theme.palette.primary.contrastText,
  textTransform: "none",
  width: isEditModal ? "150px" : "105px",
}));

const ModalPrmissionSignInBox = styled(Box)(({ theme }) => ({
  width: "470px",
  [theme.breakpoints.down("lg")]: {
    width: "470px",
  },
  [theme.breakpoints.down("sm")]: {
    width: "95%",
  },
}));

const ModalContentBox = styled(Box)({
  marginTop: 30,
  marginLeft: 25,
  marginRight: 25,
  marginBottom: 30,
  display: "flex",
  justifyContent: "flex-start",
});

const TextTypography = styled(Typography)(({ theme }) => ({
  fontSize: "14px",
  color: "#333",
  //   marginLeft: "15px",
  fontWeight: 400,
  marginBottom: "5px",
}));
const HeadingTextTypography = styled(Typography)(({ theme }) => ({
  fontSize: "18px",
  color: "#333333",
  lineHeight:'21.78px',
  fontWeight: 400,
  marginBottom: "20px",
  fontFamily:'Open Sans'
}));

const largeTextField = styled(TextField)(({theme,error})=>({
  background: '#F6F6F6',
  '& .MuiInputBase-root': {
      background: '#F6F6F6',
  },  
  '& input::placeholder': {
    color: '#000000',
    opacity: 0.75,
  },
  '& .MuiFormHelperText-root.Mui-error': {
      position: 'absolute',
      top: '100%'
    },
  height: '39px',
  borderRadius:'1px',
  marginBottom:error===true?'50px':'20px',
  width:'322px'
}))


const SupportText = styled(Typography)(({ theme,showError }) => ({
  fontSize: 12,
  fontWeight:400,
  lineHeight:'16.34px',
  fontFamily:'Open Sans',
  marginTop: showError?"20px":"unset"
}));

const ContainerBox = styled(Box)(({ theme, isMobile }) => ({
  width: isMobile ? "100%" : "410px",
}));


const HeadingTextContainer = styled(Box)(({ theme, isMobile }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "column",
  width: "100%"
}));
const customStyling = {
  exportModal: {
    position: "absolute",
    top: { xs: "45%", sm: "50%" },
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    borderRadius: 2,
    boxShadow: 24,
    overflowY: "auto",
    maxHeight: "90%",
    border: "none",
  },
  button: {
    width: "392px",
    height: "54px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    background: "#EBF5FA",
    border: "1px solid #A7D5EC",
    borderRadius: "4px",
    textTransform: "none",
  },
};

export default {
  ModalHeader,
  ModalActions,
  ModalContentBox,
  customStyling,
  ModalPrmissionSignInBox,
  ContainerBox,
  TextTypography,
  SubmitButton,
  HeadingTextContainer,
  HeadingTextTypography,
  largeTextField,
  SupportText
};
