import { styled } from "@mui/material/styles";
import { MenuItem, Select, Typography } from "@mui/material";
import { Box } from "@mui/system";

const SelectInputBase = styled(Select)(({ theme,isMobile,isMobileLandscape }) => ({
  padding: 0,
  paddingTop:  isMobile || isMobileLandscape?'unset':'3px',
  paddingLeft:'10px',
  color: theme.palette.secondary.main,
  ".MuiSelect-select": {
    padding: 5,
    color: theme.palette.secondary.main,
    outline: "none",
    border: "none",
  },
  ".MuiOutlinedInput-notchedOutline": {
    border: "none",
  },
  ".MuiSvgIcon-root": {
    color: theme.palette.secondary.main,
  },
  ".MuiButtonBase-root-MuiMenuItem-root.Mui-selected": {
    backgroundColor: "none !important",
  },
  ".MuiMenu-paper": {
    backgroundColor: "red",
  },
  ".MuiSelect-icon":{
    marginTop: isMobile || isMobileLandscape?'unset':'3px',
  },

 
}));
const BoxContainer  = styled(Box)(({ }) => ({

  // '@media (max-width: 600px)': {
  //   select: {
  //     paddingRight: '20px',
  //   },
  // },

 // [theme.breakpoints.down('sm')]: {
    
 // },
}))

const StyledMenuItem = styled(MenuItem)(
  ({ theme, islastitem }) => ({
    borderTop: islastitem && "0.1px solid #e5e5e5",
    color: theme.palette.secondary.main,
    // backgroundColor: "transparent !important",
    '&:hover': {
      opacity: 0.7,
    },
    display: "flex",
    justifyContent: "flex-start",
    // width:'100%',
    // height:'100%'
    // padding:0
  })
);

const StyledMenuItemLabel = styled(Typography)(({}) => ({
  width: "100%",
  display: "flex",
  justifyContent: "flex-start",
  color: "silver",
  fontSize: "0.9em",
  //   marginLeft:15
}));

const MenuItemText = styled(Typography)(({}) => ({
    display: "flex",
    justifyContent: "center",
    alignItems:'center'
  }));


const SvgIcon = styled("img")(({ theme }) => ({
  height: 18,
  color: theme.palette.secondary.main,
  marginRight:14,
  marginLeft:5
}));

const HorizontalDivider = styled(Box)(({}) => ({
    background: "gray",
    padding: 1,
    borderRadius: 5,
    width: "100%",
    // marginTop: 5,
    // marginBottom: 5,
  }));

const customStyling = {
  menu: {
    ".MuiMenu-list": {
      backgroundColor: "#333333",
      padding:0
      // height:'90px',
    //   paddingLeft: 2,
    //   paddingRight: 2,
      // minWidth: 150,
      // borderRadius:2,
      // paddingTop:2,
      // paddingBottom:2
    },
    ".MuiMenu-paper": {
      backgroundColor: "transparent !important",
      borderRadius: 0,
      borderRadius:2,
    },
    ".MuiMenuItem-root": {
      padding: 0,
      height:'50px',
      width:'188px',
      // height:'100%'
      // marginTop:{sm:1.6, xs:0.2},
    },
    ".MuiPaper-root" : {
      "@media (min-width:620px) and (max-width:840px) and (orientation: portrait)": {
        left:"200px !important",
        top:'130px !important',
        position:'absolute',
      },
      "@media (max-width:600px) and (orientation: portrait)":{
        left:"16px !important",
      },
      "@media (min-width:1400px)":{
        left:"4.8% !important",
      },
      "@media (min-width:1600px)":{
        left:"3.8% !important",
      },
      "@media (max-width: 960px) and (orientation: landscape)":{
        left:"2% !important",
      },
      left:"5.8% !important",
    },
}}
export default {
  SelectInputBase,
  StyledMenuItem,
  customStyling,
  StyledMenuItemLabel,
  SvgIcon,
  MenuItemText,
  BoxContainer,
  HorizontalDivider
};
