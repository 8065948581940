import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Styled from "./styles";
import Modal from "@mui/material/Modal";
import CloseIcon from "@mui/icons-material/Close";
import { IconButton } from "@mui/material";
import { useState, useEffect } from "react";
import { submitQuizFeedback } from "../../api/quiz/methods";
import { setUserData, getUserData, updateUserData } from "../user/service";
import { getWordCount, COMMENT_MAX_WORD_COUNT } from "../quiz/service";
import { Events, logEvent } from "../../analytics";
import intlMessages from "../../locales/en";
import { useSelector } from "react-redux";
import { getFirstCharacters } from "../../util/signin-user-data";

const validateInput = (i) => {
  let _input = i;
  if (/^\s/.test(_input)) _input = _input.trimStart();
  return _input;
};

export default function Feedback(props) {
  const userDataOld = getUserData();
  const [submitBtn, setSubmitBtn] = useState(false);
  const name = userDataOld && userDataOld.name ? userDataOld.name : "";
  const email = userDataOld && userDataOld.email ? userDataOld.email : "";
  const { isSignedIn } = useSelector((state) => state.logIn);
  const { userData } = useSelector((state) => state.logIn);
  const {
    viewFeedbackSubmissionMessage,
    closeFeedbackCallback,
    isOpen,
    showErrorMessageCallback,
    handleOpenConnectionModalCallback,
    purpose,
    closeFeedbackOnSubmit,
    heading,
  } = props;
  const [openFeedbackModal, setOpenFeedbackModal] = useState(isOpen);
  const [error, setError] = useState({
    email: "",
    message: "",
    number: "",
    connection: "",
  });
  const [feedback, setFeedback] = useState({
    name: name,
    email: email,
    number: "",
    message: heading === true?intlMessages.feedbaclModalSerialMessageText:"",
    purpose: purpose || intlMessages.feedbackActiveUserFlag,
  });

  const clearFieldsAndErrors = () => {
    setError({
      email: "",
      message: "",
      connection: "",
      name: "",
    });
    setFeedback({
      name: "",
      email: "",
      message: heading === true?intlMessages.feedbaclModalSerialMessageText:"",
      number: "",
      purpose: purpose || intlMessages.feedbackActiveUserFlag,
    });
  };

  const passedInpFieldsValidation = () => {
    const { email, message, name, number } = feedback;
    if (
      email.length === 0 &&
      message.length === 0 &&
      number.length > 0 &&
      !validatePhoneNumber(number)
    ) {
      return setError({
        ...error,
        email: intlMessages.emptyEmailError,
        message: intlMessages.emptyMessageError,
        number: intlMessages.invalidPhoneNumberError,
      });
    }
    if (!validateEmail(email) && message.length === 0) {
      return setError({
        ...error,
        email: intlMessages.incorrectEmailFormat,
        message: intlMessages.emptyMessageError,
      });
    }
    if (
      number.length > 0 &&
      !validatePhoneNumber(number) &&
      message.length === 0
    ) {
      return setError({
        ...error,
        number: intlMessages.invalidPhoneNumberError,
        message: intlMessages.emptyMessageError,
      });
    }
    if (message.length === 0 && name.length !== 0 && name.length > 150) {
      return setError({
        ...error,
        name: intlMessages.lengthNameError,
        message: intlMessages.emptyMessageError,
      });
    }
    if (email.length === 0) {
      return setError({ ...error, email: intlMessages.emptyEmailError });
    }
    if (!validateEmail(email)) {
      return setError({ ...error, email: intlMessages.incorrectEmailFormat });
    }
    if (message.length === 0) {
      return setError({ ...error, message: intlMessages.emptyMessageError });
    }
    if (name.length > 150) {
      return setError({
        ...error,
        name: intlMessages.lengthNameError,
      });
    }
    if (number.length > 0 && !validatePhoneNumber(number)) {
      return setError({
        ...error,
        number: intlMessages.invalidPhoneNumberError,
      });
    }
    if (getWordCount(message) > COMMENT_MAX_WORD_COUNT) {
      return setError({
        ...error,
        message: intlMessages.maxCommentWordCount.replace(
          "{0}",
          COMMENT_MAX_WORD_COUNT
        ),
      });
    }
    return true;
  };

  useEffect(() => {
    const handleCtrlZ = (event) => {
      if (openFeedbackModal && event.ctrlKey && event.key === "z") {
        event.preventDefault();
      }
    };
    window.addEventListener("keydown", handleCtrlZ);
    return () => {
      window.removeEventListener("keydown", handleCtrlZ);
    };
  }, [openFeedbackModal]);

  useEffect(() => {
    const setIsSignedInOrNot = async () => {
      if (isSignedIn) {
        // // console.log("inside loggedin user data", userData);
        const userNameDataObject = getFirstCharacters(
          userData.firstName,
          userData.lastName
        );
        // // console.log("userNameDataObject", userNameDataObject)
        setFeedback({
          name: userNameDataObject.name,
          email: userData.username,
          message: heading === true?intlMessages.feedbaclModalSerialMessageText:"",
          number: "",
          purpose: purpose || intlMessages.feedbackActiveUserFlag,
        });
      }
    };
    setIsSignedInOrNot();
  }, []);

  const callbackUpdateUserData = (uEmail, uName) => {
    const uData = getUserData();
    const userDataOld = {
      email: uEmail,
      name: uName,
    };
    if (uData) {
      updateUserData(userDataOld);
    } else {
      setUserData(userDataOld);
    }
  };

  const onSubmitFeedback = () => {
    if (passedInpFieldsValidation()) {
      setSubmitBtn(true);
      logEvent(Events.contact_us_event, "contact_us_submitted");
      const { name, email, message, purpose, number } = feedback;
      let msg =
        message + "<br/><p style='font-size:20px'>number: " + number + "<p>";
      submitQuizFeedback(name, email, msg, purpose,!!heading?true:false)
        .then((res) => {
          if (res.data.statusCode === 0) {
            return handleOpenConnectionModalCallback();
          }
          if (!res) {
            return showErrorMessageCallback(intlMessages.invalidError);
          }
          if (res?.data && res.data.statusCode !== 201 && res.data.message) {
            setError({ ...error, connection: res.data.message });
            return showErrorMessageCallback(res.data.message);
          }
          const isFeedbackSubmitted = res?.status === 201;
          if (isFeedbackSubmitted) {
            viewFeedbackSubmissionMessage();
            callbackUpdateUserData(email, name);
            logEvent(Events.contact_us_event, "contact_us_closed");
            clearFieldsAndErrors();
            closeFeedbackOnSubmit()
          }
        })
        .catch((error) => {
          return false;
        });
    }
  };

  const closeFeedbackModal = () => {
    logEvent(Events.contact_us_event, "contact_us_closed");
    clearFieldsAndErrors();
    closeFeedbackCallback();
  };

  function validateEmail(inputText) {
    const mailformat =
      /^[a-zA-Z0-9]+([._%+-][a-zA-Z0-9]+)*@[a-zA-Z0-9]+([.-][a-zA-Z0-9]+)*\.[a-zA-Z]{2,}$/;
    return inputText.match(mailformat);
  }

  function validatePhoneNumber(inputText) {
    const phoneNumberRegex = /^(\+|00)\d{11,15}$/;
    return !!inputText.match(phoneNumberRegex);
  }

  const onChangeEmail = (e) => {
    const inpText = validateInput(e.target.value);
    if (
      (error.email === intlMessages.emptyEmailError && inpText.length > 0) ||
      (error.email === intlMessages.incorrectEmailFormat &&
        validateEmail(inpText))
    ) {
      setError({ ...error, email: null });
    }
    setFeedback({ ...feedback, email: inpText });
  };

  const onChangePhoneNumber = (e) => {
    const inpText = validateInput(e.target.value);
    if (
      error.number === intlMessages.invalidPhoneNumberError 
    ) {
      setError({ ...error, number: null });
    }
    setFeedback({ ...feedback, number: inpText });
  };

  const onChangeName = (e) => {
    const inpText = validateInput(e.target.value);
    if (error.name && inpText.length > 0) {
      setError({ ...error, name: null });
    }
    setFeedback({ ...feedback, name: inpText });
  };

  const onChangeMessage = (e) => {
    const inpText = validateInput(e.target.value);
    if (error.message && inpText.length > 0) {
      setError({ ...error, message: null });
    }
    if (
      error.message &&
      intlMessages.maxCommentWordCount.replace("{0}", COMMENT_MAX_WORD_COUNT) &&
      getWordCount(inpText) <= COMMENT_MAX_WORD_COUNT
    ) {
      setError({ ...error, message: null });
    }
    setFeedback({ ...feedback, message: inpText });
  };

  return (
    <div>
      <Modal
        open={openFeedbackModal}
        // onClose={closeFeedbackModal}
        aria-labelledby="feedback-modal"
        aria-describedby="feedback-description"
      >
        <Styled.ModalBox sx={Styled.customStyling.exportModal}>
          <Styled.ModalHeader>
            <Typography
              id="feedback-modal-title"
              variant="h5"
              component="h5"
              style={{ fontWeight: "bold" }}
            >
              {heading === true
                ? intlMessages.feedbackModalSerialText
                : intlMessages.feedbackHeaderLabel}
            </Typography>
            <IconButton
              aria-label="closeFeedbackModal"
              onClick={closeFeedbackModal}
            >
              <CloseIcon color="seondary.light" />
            </IconButton>
          </Styled.ModalHeader>
          <Styled.ModalContentBox>
            <Box mt={2} sx={{ display: "flex", flexDirection: "column" }}>
              <Styled.ModalTextField
                value={feedback.name}
                onChange={onChangeName}
                placeholder={intlMessages.nameLabel}
                type="text"
                helperText={error.name}
                disabled={isSignedIn}
                InputProps={{
                  readOnly: isSignedIn ? true : false,
                }}
                error={error.name ? true : false}
              />

              <Styled.ModalTextField
                value={feedback.email}
                onChange={onChangeEmail}
                placeholder={`${intlMessages.emailLabel} *`}
                type="email"
                required
                helperText={error.email}
                sx={{ mt: 1 }}
                disabled={isSignedIn}
                InputProps={{
                  readOnly: isSignedIn ? true : false,
                }}
                error={error.email ? true : false}
              />
              <Styled.ModalTextField
                value={feedback.number}
                onChange={onChangePhoneNumber}
                placeholder={`${intlMessages.phoneLabel}`}
                type="text"
                required
                helperText={error.number}
                sx={{ mt: 1 }}
                error={error.number ? true : false}
              />
              <Styled.ModalTextField
                value={feedback.message}
                onChange={onChangeMessage}
                placeholder={`${intlMessages.messageLabel} *`}
                type="text"
                multiline
                rows={4}
                helperText={error.message}
                error={error.message ? true : false}
                sx={{
                  mt: 1,
                  "& textarea::placeholder": {
                    color: "black",
                    opacity: 1,
                  },
                }}
              />
              <Styled.ErrorText>{error.connection}</Styled.ErrorText>
            </Box>
          </Styled.ModalContentBox>
          <Styled.ModalActions>
            <Styled.SubmitButton
              disabled={submitBtn ? true : false}
              variant="contained"
              color="secondary"
              onClick={onSubmitFeedback}
            >
              {intlMessages.submitLabel}
            </Styled.SubmitButton>
            <Button
              sx={{
                textTransform: "none",
                marginLeft: 1,
              }}
              variant="outlined"
              color="secondary"
              onClick={closeFeedbackModal}
            >
              {intlMessages.cancelLabel}
            </Button>
          </Styled.ModalActions>
        </Styled.ModalBox>
      </Modal>
    </div>
  );
}
