import intlMessages from "../../../locales/en";

import Styled from "./styles";
import { APP_NAME, COMPANY_NAME } from "../../../settings";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import quizwizLogo from "./quizwiz-logo";

export default function WelcomeModalDesktop(props) {
  let {
    isOpen,
    isIpad,
    theme,
    handleClose,
    checked,
    handleChange,
    slide,
    handleConsentClick,
    consentColor,
    list,
    handleTrialBtn,
  } = props;
  return (
    <Styled.modalStyle
      fullScreen
      open={isOpen}
      componentsProps={{
        backdrop: {
          style: {
            backgroundColor: "rgba(0, 0, 0, 0.81)",
            borderRadius: "30px !important",
          },
        },
      }}
      sx={{
        bgcolor: "background.paper",
        background: "transparent",
      }}
      PaperProps={{
        style: {
          backgroundColor: "transparent",
          boxShadow: "none",
        },
      }}
    >
      <Styled.contianer container isIpad={isIpad ? true : false}>
        <Grid xs={6} item>
          <Grid xs={12} style={{ overflow: "hidden" }}>
            <Styled.logoImage src={`${quizwizLogo}`} />
          </Grid>

          <Styled.DescDiv>
            <Styled.descText
              variant="h5"
              sx={{
                color: "rgb(249 245 245)",
                display: { xs: "none", sm: "block" },

                mr: 0,
              }}
            >
              {intlMessages.SplashScreenDesc.replace("{0}", APP_NAME)}
            </Styled.descText>

            <Styled.descText
              variant="h5"
              sx={{
                color: "rgb(249 245 245)",
                display: { xs: "none", sm: "block" },
                lineHeight: { lg: 1.75, xxxl: 1.5 },
                mr: 0,
              }}
            >
              {intlMessages.SplashScreenDesc2.replace("{0}", APP_NAME)}
            </Styled.descText>

            <Styled.descText
              variant="h5"
              sx={{
                color: "rgb(249 245 245)",
                display: { xs: "none", sm: "block" },
                lineHeight: { lg: 1.75, xxxl: 1.5 },
                mr: 0,
              }}
            >
              {intlMessages.SplashScreenDesc3.replace("{0}", APP_NAME)}
            </Styled.descText>

            <Styled.buttonDiv>
              <Button
                variant="contained"
                sx={{
                  ":hover": {
                    bgcolor: "#c0c2c4",
                  },
                  backgroundColor: "rgba(255, 255, 255, 0.90)",
                  color: "#7DC242",
                  fontSize: "14px",
                  marginRight: { sm: 2, xs: 0 },
                  padding: "9px 15px",
                  overflowWrap: "break-word",
                  whiteSpace: "nowrap",
                  textTransform: "none",
                  mb: { md: 5, xs: 0, xxxl: 4 },
                  width: "130px",
                }}
                onClick={handleClose}
              >
                {intlMessages.SplashScreenGenerateQuizBtnText}
              </Button>
              <Button
                variant="contained"
                sx={{
                  ":hover": {
                    bgcolor: "#d6511d",
                  },
                  backgroundColor: "#F96D30",
                  color: theme.palette.primary.contrastText,
                  textTransform: "none",
                  marginRight: { sm: 2, xs: 0 },
                  fontSize: "14px",
                  padding: "9px 15px",
                  overflowWrap: "break-word",
                  whiteSpace: "nowrap",
                  mb: { md: 5, xs: 0, xxxl: 4 },
                  width: "130px",
                }}
                onClick={handleTrialBtn}
              >
                {intlMessages.SplashScreenTrialBtnText}
              </Button>
              <Grid container sx={{ marginLeft: "-10px" }}>
                <Grid item xs={0}>
                  {" "}
                  <Styled.StyledCheckbox
                    icon={<Styled.StyledUnCheckedIcon />}
                    checkedIcon={<img src="/svgs/Checkbox.svg"></img>}
                    className="consent-checkbox"
                    checked={checked}
                    onChange={handleChange}
                  />
                </Grid>

                <Grid item xs={6} alignItems="flex-start">
                  <Styled.descText2
                    className={"noSelect"}
                    variant="h6"
                    sx={{
                      width: "360px",
                      marginLeft: 0,
                      color: consentColor ? "red" : "white",
                      opacity: consentColor ? 1 : 0.699999988079071,
                      display: { xs: "none", sm: "block" },
                      textAlign: "justify",
                    }}
                  >
                    <span
                      style={{ cursor: "pointer" }}
                      onClick={handleConsentClick}
                    >
                      {intlMessages.SplashScreenConsentText}{" "}
                    </span>
                    <a
                      href="terms.html"
                      target="_blank"
                      style={{
                        cursor: "pointer",
                        textDecoration: "none",
                        fontSize: "0.95rem",
                        color: "#0bbde0",
                      }}
                    >
                      {intlMessages.signUpConsentText2}
                    </a>{" "}
                    <span style={{ cursor: "pointer" }}>
                      {intlMessages.signUpConsentText3}
                    </span>{" "}
                    <a
                      href="privacy-policies.html"
                      target="_blank"
                      style={{
                        cursor: "pointer",
                        textDecoration: "none",
                        fontSize: "0.95rem",
                        color: "#0bbde0",
                      }}
                    >
                      {intlMessages.signUpConsentText4}
                    </a>
                    <span style={{ cursor: "pointer" }}>
                      {intlMessages.signUpConsentText5}
                    </span>
                  </Styled.descText2>
                </Grid>
              </Grid>
            </Styled.buttonDiv>
          </Styled.DescDiv>
        </Grid>

        <Styled.Slides item xs={5}>
          <Styled.SlidesImage sx={{ width: "100%" }}>
            <img
              src={slide.imgSrc}
              style={{ width: "480px", height: "380px" }}
            ></img>
          </Styled.SlidesImage>

          <Grid sx={{ width: "100%" }}>
            <Styled.StyledSlideDesc variant="h3">
              {slide.desc}
            </Styled.StyledSlideDesc>
          </Grid>

          <Grid>
            <Styled.StyledDotDiv>{list}</Styled.StyledDotDiv>
          </Grid>
        </Styled.Slides>
      </Styled.contianer>
    </Styled.modalStyle>
  );
}
