import { getBaseUrlOfBrowser } from "./api/service";

const MAX_ALLOWED_ANSWERS = 5;
const MAX_QUIZ_WORDS = 6000;
const MAX_WORD_LENGTH = 60;
const MIN_QUIZ_WORDS = 50;
const COMMENT_MAX_WORD_COUNT = 500;
const TEXT_EXPORT_FILE_TYPE = "txt";
const PDF_EXPORT_FILE_TYPE = "pdf";
const CLIPBOARD_EXPORT_TYPE = "clipboard";
const CORRECT_OPTION_SYMBOL = "✅";
const MAX_TXT_FILE_UPLOAD_SIZE = 50000; // bytes
const MAX_FILE_UPLOAD_SIZE = 5242880; // bytes
const APP_NAME = "QuizWiz";
const COMPANY_NAME = "OneScreen";
const CORRECT_OPTION_SYMBOL_PDF = `<img style="width:{0}px;border: 1px solid #7dc242;padding:0.3px; background-color:#ecfadc;" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAAAXNSR0IArs4c6QAAArBJREFUaEPt2UFrE0EUAOA32Rg0Ip5ECx4EE0U86iFYLfTkVYh6kZbSgwfBKkhTepF4EVMLEgPFBkSxx5I/4LEKFvFWaGsLggdpDwXBQxqiycgEpmw2O7szb6bZadkck523+72ZefOWEDgkH3JIHBBDbJvJeEbiGdHIwIPlh2tzucolvxAHZmmNLE1RBjiRqt+cy1U+ejEHAsIR/OHPD9RPFrOVP26M9RAvgj/8wlCp69mthogQfhhrIWEIL8ZKiCzCjbELQoGMfJpqYyq0NZDJr2NnthuntzAItvGtgNxfflTebR6dwCLYuMgh45+ffP/bTl7QQUQOGV0qUIrMpTXniGpl4jNGCKUfbswkpFuUmc18p7cpZGvGlx8W4SRav95fnz0r3TRyBB9gEoNFHE81XrzJladFe6kn216ESQwWkU7VL8/nKqtBBaELIkKYwGARje0fycW7i62wqrYHCUPoYLAIb2UKnRFZBAbDYq9sZcIS2vO7CqJzjqgiVDA8tipEFbF3IGIwhEBrMlNLilLtjqkCwSC6TnYMBgj8LGRq59yY0vqdi8Rpr7u/k4VgET0tChJTLmRqj1mwl5u3pynQ595ZkoHoIHx7LQyGABmmQKsAkPVbamEQXYSwacRggspSEMQEIrD7NYkRQP4tDJWOKNdlwYDAhtAUxgtJOc0vbwdfXTOFCJwRfhMTGDck7eyOzw++fmcSIQVhF+liOORY+vep6tXqjmmENEQXwyCmNrUoCdIvTcViMZG+txLahfrdyOT7jDaEBZhdu3WlnXS+qSyNfiCUlpZr8z8FgGcymH4hUBA2qLSRXyUEfP9w4cB+ItCQzubfyDeBgO+B1m+EFkRUyaJAaEO8mKgQRiAcEyXCGESmgu33NdIH4n4/iG78GKKbQdPj4xkxnVHdeP8BSVIA11+n6nIAAAAASUVORK5CYII="/>`;
const CONTACT_EMAIL = "quizwiz@onescreensolutions.com";
const FACEBOOK_LINK = "https://www.facebook.com/OneScreenQuizwiz";
const TWITTER_LINK =
  "https://twitter.com/MyQuizWiz?t=FlanYDHCHmRrSqw_W39GWA&s=31";
const INSTAGRAM_LINK =
  "https://www.instagram.com/myquizwiz/?igshid=cnBsc2lxZmMyd2Zh";
const GOOGLE_LOGIN_URL = `${getBaseUrlOfBrowser()}/api/v1/user/google`;
const MICROSOFT_LOGIN_URL = `${getBaseUrlOfBrowser()}/api/v1/user/microsoft`;
const GURU_SERVER_URL = `https://hype.onescreensolutions.com`;
const GURU_CODE = ``;
const ONESCREEN_WEBSITE_URL = "https://www.onescreensolutions.com/";
const DISABLE_DEV_TOOLS = true
let  ABORT_CONTROLLER_MAP = new Map()
const SPLIT_IO_API_KEY = "blhdloj4kl4pi34g0o8jecvspkqfs618cjuv"
const FREE_PLAN_SUBSCRIPTION_COST="0.00"
const PRO_PLAN_SUBSCRIPTION_MONTHLY_COST="9.00"
const PRO_PLAN_SUBSCRIPTION_YEARLY_COST="99.00"
const USER_STATE = Object.freeze({
  LOGGED_OUT_USER: 0,
  SIGNED_IN_USER: 1,
  NOT_VERIFIED_USER: 2,
  VERIFIED_USER: 3,
  BLOCKED_USER_USER: 4,
  EXPIRED_USER: 5,
  FRESH_USER: 6,
  PAYMENT_FAILED:7
});

export {
  MAX_ALLOWED_ANSWERS,
  MAX_QUIZ_WORDS,
  MIN_QUIZ_WORDS,
  TEXT_EXPORT_FILE_TYPE,
  PDF_EXPORT_FILE_TYPE,
  CLIPBOARD_EXPORT_TYPE,
  CORRECT_OPTION_SYMBOL,
  MAX_TXT_FILE_UPLOAD_SIZE,
  COMMENT_MAX_WORD_COUNT,
  CORRECT_OPTION_SYMBOL_PDF,
  APP_NAME,
  COMPANY_NAME,
  CONTACT_EMAIL,
  FACEBOOK_LINK,
  TWITTER_LINK,
  INSTAGRAM_LINK,
  GOOGLE_LOGIN_URL,
  MICROSOFT_LOGIN_URL,
  USER_STATE,
  GURU_CODE,
  GURU_SERVER_URL,
  ONESCREEN_WEBSITE_URL,
  MAX_WORD_LENGTH,
  MAX_FILE_UPLOAD_SIZE,
  DISABLE_DEV_TOOLS,
  ABORT_CONTROLLER_MAP,
  SPLIT_IO_API_KEY,
  FREE_PLAN_SUBSCRIPTION_COST,
  PRO_PLAN_SUBSCRIPTION_MONTHLY_COST,
  PRO_PLAN_SUBSCRIPTION_YEARLY_COST
};
