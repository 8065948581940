import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    userEmail:''
}

const signUpSlice = createSlice({
    name:'signUp',
    initialState,
    reducers:{
        setUserEmail: (state, action) => {
            state.userEmail = action.payload;
          },
    }
})

export const { setUserEmail } = signUpSlice.actions;
const { reducer } = signUpSlice;
export default reducer;