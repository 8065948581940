import styled from "@mui/system/styled";
import Grid from "@mui/material/Grid";

const Children = styled(Grid)(({ theme }) => ({
     marginTop: 80,
    [`${theme.breakpoints.up("sm")} and (max-width: 1400px) and (max-height: 800px)`]: {
      marginTop:68,
    },
    [`${theme.breakpoints.down("md")} and (orientation: landscape) and (hover: none) and (pointer: coarse)`]: {
      marginTop:58
    },
    [theme.breakpoints.down('sm')]: {
        paddingTop:53.3,
        marginTop: 0,
        height:'100%'
    },
}));

export default {
  Children,
};
