import { useState, useEffect } from "react";
import intlMessages from "../../locales/en";
import Styled from "./styles";
import { Dialog, IconButton, InputAdornment } from "@mui/material";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import { deleteSelectedQuiz, getDatagridColumns,handleEditQuiz,handleShareQuiz } from "./service";
import DataGridTable from "../../common/data-grid-table/component";
import AlertBox from "../alert/component";
import { useDispatch, useSelector } from "react-redux";
import {
  getList,
  setLibraryPage,
  setMobileSortingLoader,
  setOpenDeleteBox,
  setSearchQuery,
  setSortOrder,
} from "../../redux/slices/librarySlice";
import { setOpenLibrary } from "../../redux/slices/librarySlice";
import ConnectionModal from "../connection-modal/component";
import {setIsDisableQuizSettings, setIsQuizLoaded, setIsQuizSaved, setLibraryWarning, setQuizGenerationCurrentState } from "../../redux/slices/quizSlice";
import _ from "lodash";
import { Close } from "@mui/icons-material";
import { Categories, Events, logEvent } from "../../analytics";

export default function QuizLibrary(props) {
  let { isIpad, isIpadLandscape, isMobile, isMobileLandscape } = props;
  const dispatch = useDispatch();
  const [openConnectionModal, setIsOpenConnectionModal] = useState(false);
  const {
    quizList,
    page,
    sortBy,
    order,
    size,
    search,
    totalQuizzes,
    openDeleteBox,
    openLibrary,
    totalPages,
    mobileSortingLoader,
  } = useSelector((state) => state.library);
  let controller = null;
  const [libraryLoading, setLibraryLoading] = useState(false);
  const { quizId, isSaved,libraryWarning,quizData } = useSelector((state) => state.quiz);
  const [isPageChanged, setisPageChanged] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const handleOnClose = () => {
    logEvent(Categories.quiz_library, Events.quiz_library_close_button_clicked);
    setLibraryLoading(false);
    dispatch(setIsQuizSaved(false));
    dispatch(setOpenLibrary(false));
    clearSearch();
  };
  useEffect(() => {
    const controller = new AbortController();
    const filters = {
      page,
      sortBy,
      order,
      size,
      search,
    };
    const signal = controller.signal;
    const payload = { signal, ...filters };
    if (mobileSortingLoader === true) {
      setLibraryLoading(true);
    }
    dispatch(getList(payload))
      .unwrap()
      .then((res) => {})
      .catch((error) => {});
    if (libraryLoading === true) {
      setLibraryLoading(false);
      dispatch(setMobileSortingLoader(false));
    }
    return () => {
      controller.abort();
    };
  }, [libraryLoading, mobileSortingLoader, search, isSaved,order]);

  const shareQuiz = async ()=>{
    dispatch(setQuizGenerationCurrentState(0));
    await handleShareQuiz(quizId)
  }

  const editQuiz = async()=>{
    dispatch(setQuizGenerationCurrentState(0));
    await handleEditQuiz(quizId)
  }

  const handleDeleteQuiz = async () => {
    setLibraryLoading(true);
    const response = await deleteSelectedQuiz(quizId);
    if (response?.data?.statusCode === 0) {
      setIsOpenConnectionModal(true);
      return;
    }
    if (
      response?.status === 200 &&
      response?.data?.message === "Item deleted successfully."
    ) {
      dispatch(setOpenDeleteBox(false));
      return;
    }
    setLibraryLoading(false);
  };

  const handlePageDataChange = (selectedPage) => {
    logEvent(
      Categories.quiz_library,
      Events.quiz_library_pagination_button_clicked
    );
    setLibraryLoading(true);
    controller?.abort();
    if (selectedPage === page) return setisPageChanged(!isPageChanged);
    dispatch(setLibraryPage(selectedPage));
  };

  const handleSortModelChange = (sortModel) => {
    setLibraryLoading(true);
    if (sortModel?.length > 0) {
      const { field, sort } = sortModel[0];
      if (field === "title")
        logEvent(
          Categories.quiz_library,
          Events.quiz_library_sort_by_quiz_title_button_clicked
        );
      if (field === "createdAt")
        logEvent(
          Categories.quiz_library,
          Events.quiz_library_sort_by_created_date_button_clicked
        );
      const sortOrder = {
        sortBy: field,
        order: sort === "asc" ? 1 : -1,
      };
      dispatch(setSortOrder(sortOrder));
    }
  };
  const searchDebounced = _.debounce((value) => {
    dispatch(setSearchQuery(value));
  }, 300);

  const handleTextFieldOnChange = (e) => {
    setLibraryLoading(true);
    const value = e.target.value;
    if (value?.trim()?.length === 0) {
      clearSearch();
    }
    setSearchValue(value);
    searchDebounced(value);
  };

  const clearSearch = () => {
    logEvent(
      Categories.quiz_library,
      Events.quiz_library_search_clear_button_clicked
    );
    setLibraryLoading(true);
    setSearchValue("");
    dispatch(setSearchQuery(""));
  };

  return (
    <>
      <Dialog
        fullScreen
        open={openLibrary}
        onClose={handleOnClose}
        componentsProps={{
          backdrop: {
            style: {
              backgroundColor: "rgba(0, 0, 0, 0.51)",
            },
          },
        }}
        sx={{
          bgcolor: "background.paper",
          background: "transparent",
        }}
        PaperProps={ isMobile || isMobileLandscape? {}:{
          style: {
            backgroundColor: "transparent",
            boxShadow: "none",
          },
        }}
      >
        <Styled.container
          isIpad={isIpad ? true : false}
          isIpadLandscape={isIpadLandscape ? true : false}
          isMobile={isMobile?true:false}
          isMobileLandscape={isMobileLandscape?true:false}
        >
          <Styled.headingBar>
            <Styled.heading>{intlMessages.libraryTitle}</Styled.heading>
            <Styled.crossBtn onClick={handleOnClose}>
              <CloseOutlinedIcon />
            </Styled.crossBtn>
          </Styled.headingBar>
          <Styled.searchBox>
            <Styled.quizLabel>
              {isMobile || isMobileLandscape
                ? intlMessages.libraryTotalMobileQuizLabel
                : intlMessages.libraryTotalQuizLabel}
              : {totalQuizzes}
            </Styled.quizLabel>
            <Styled.searchField
              isMobile={isMobile ? true : false}
              isMobileLandscape={isMobileLandscape ? true : false}
              size={"small"}
              value={searchValue}
              onChange={handleTextFieldOnChange}
              placeholder={intlMessages.librarySearchPlaceholder}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <img src="/svgs/search-field-icon.svg" alt="Icon" />
                  </InputAdornment>
                ),
                endAdornment: (
                  <IconButton
                    aria-label="clear-search-text"
                    onClick={() => clearSearch()}
                    edge="end"
                    sx={{
                      color: "gray",
                      display: search.length !== 0 ? "flex" : "none",
                    }}
                  >
                    <Close />
                  </IconButton>
                ),
              }}
            ></Styled.searchField>
          </Styled.searchBox>
          <DataGridTable
            rows={quizList}
            disableSelectionOnClick
            columns={getDatagridColumns(isMobile, isMobileLandscape)}
            isDisabledPagination={false}
            height={isMobileLandscape?"60vh": isMobile?"73vh":"48vh"}
            textLabels={intlMessages}
            isLoading={libraryLoading}
            handleChangeSelectedItem={() => {}}
            handlePageDataChange={handlePageDataChange}
            handleSortModelChange={handleSortModelChange}
            handleTableRowsOnResizePage={() => {}}
            totalPagesCount={totalPages}
            isMobile={isMobile ? true : false}
            isMobileLandscape={isMobileLandscape ? true : false}
            sortBy={""}
            order={1}
            page={page}
            size={size}
          />
        </Styled.container>
      </Dialog>
      {openDeleteBox && 
        <AlertBox
          isIpad={isIpad ? true : false}
          isMobile={isMobile ? true : false}
          isMobileLandscape={isMobileLandscape ? true : false}
          isIpadLandscape={isIpadLandscape ? true : false}
          message={libraryWarning}
          loginBtn={false}
          libraryBtn={false}
          editFlag={libraryWarning?.hasEditFlag===true?true:false}
          shareFlag={libraryWarning?.hasShareFlag===true?true:false}
          deleteBtn={libraryWarning?.hasEditFlag===true || libraryWarning?.hasShareFlag===true?false:true}
          handleDeleteBtnOnClick={libraryWarning?.hasEditFlag===true?editQuiz:libraryWarning?.hasShareFlag===true?shareQuiz:handleDeleteQuiz}
          handleLibraryBtnOnClick={() => {}}
          isOpen={openDeleteBox}
          onClose={() => {
            // dispatch(setIsQuizLoaded(false))
            dispatch(setIsDisableQuizSettings(false));
            dispatch(setQuizGenerationCurrentState(1));
            if(quizData.questionAndAnswers.length!==0){
              dispatch(setIsQuizLoaded(false))
               const createBtn = document.getElementById("create-btn")
              if(!!createBtn){
                createBtn.style.color = "white";
                createBtn.style.backgroundColor = "#7DC242";
              }
              }
            dispatch(setOpenDeleteBox(false))
            dispatch(setLibraryWarning(null))
          }}
        />
      }
      {openConnectionModal && (
        <ConnectionModal
          isOpen={openConnectionModal}
          handleCloseConnectionModal={() => setIsOpenConnectionModal(false)}
        />
      )}
    </>
  );
}
