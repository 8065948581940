const getNavigateURL = (url, isFirstTime = false) => {
  const queryParams = new URL(window.location);
  let params = queryParams.search;
  if (!!params && isFirstTime) {
    return `${url}${params}&v=1`;
  } else if (!params && isFirstTime) {
    return `${url}?v=1`;
  }
  return `${url}${params}`;
};

const checkUrlIsGoogleForm = () => {
  const queryParams = new URLSearchParams(window.location.search);
  const googleFormsParam = queryParams.get("google-forms");
  return !!googleFormsParam;
};

const checkUrlIsGoogleDrive = () => {
  const queryParams = new URLSearchParams(window.location.search);
  const googleFormsParam = queryParams.get("google-drive");
  return !!googleFormsParam;
};

const checkUrlIsSaveQuiz = () => {
  const queryParams = new URLSearchParams(window.location.search);
  const saveQuizParam = queryParams.get("save-quiz");
  return !!saveQuizParam;
};

const getFirstCharacters = (string, lastname) => {
  try {
    const wordsArray = string.split(" ");
    if (wordsArray.length >= 1) {
      const firstCharacter1 = wordsArray[0].charAt(0);
      if (wordsArray.length >= 2) {
        const firstCharacter2 = wordsArray[1].charAt(0);
        return {
          avatar: firstCharacter1 + firstCharacter2,
          name: wordsArray[0] + " " + wordsArray[1],
        };
      } else {
        if (wordsArray.length == 1 && !!lastname) {
          return {
            avatar: firstCharacter1 + lastname.charAt(0),
            name: wordsArray[0] + " " + lastname,
          };
        }
        return {
          avatar: firstCharacter1,
          name: wordsArray[0] + " " + lastname,
        };
      }
    } else {
      return { avatar: "--", name: string + " " + lastname };
    }
  } catch (ex) {
    return { avatar: "--", name: "---" };
  }
};

export {
  getFirstCharacters,
  getNavigateURL,
  checkUrlIsGoogleForm,
  checkUrlIsGoogleDrive,
  checkUrlIsSaveQuiz
};
