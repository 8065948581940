import { styled } from "@mui/material/styles";
import { MenuItem, Select, Typography } from "@mui/material";
import { Box } from "@mui/system";

const SelectInputBase = styled(Select)(({ theme, hideborder,isMobileLandscape,isMobile}) => ({  
  padding: 0,
  paddingTop: isMobile || isMobileLandscape?'unset':'3px',
  paddingLeft: isMobile || isMobileLandscape?'unset':'3px',
  color: theme.palette.secondary.main,
  ".MuiSelect-select": {
    padding: 5,
    color: theme.palette.primary.contrastText,
    backgroundColor:theme.palette.secondary.main,
    outline: "none",
    border: "none",
  },
  ".MuiOutlinedInput-notchedOutline": {
    border: "none",
  },
  ".MuiSvgIcon-root": {
    color: theme.palette.primary.contrastText,
  },
  ".MuiButtonBase-root-MuiMenuItem-root.Mui-selected": {
    backgroundColor: "none !important",
  },
  ".MuiMenu-paper": {
    backgroundColor: "red",
  },
  ".MuiSelect-icon":{
    marginRight: isMobileLandscape?'10px':'unset',
    marginTop: isMobileLandscape?'-5px': isMobile ?'unset':'3px'
  },
}));
const BoxContainer  = styled(Box)(({ theme, isMobileLandscape,isIpad }) => ({
  height: isMobileLandscape? '62px':'unset',
  width: isMobileLandscape? '63px':'unset',
  textTransform: isMobileLandscape? 'none':'unset',
  backgroundColor: isMobileLandscape?'#7DC243':'unset',
  color: isMobileLandscape?'white':'unset',
  borderRadius: isMobileLandscape?'4px':'unset',
  margin: isMobileLandscape?'5px':'unset',
  minWidth: isMobileLandscape?'62px':'150px',
  fontSize: isMobileLandscape?'14px':'unset',
  marginTop: isIpad?'1px':'unset'
}))

const StyledMenuItem = styled(MenuItem)(
  ({ theme, islastitem, isselected }) => ({
    borderTop: islastitem && "0.1px solid #e5e5e5",
    color: theme.palette.secondary.main,
    backgroundColor: "transparent !important",
    display: "flex",
    justifyContent: "flex-start",
    width:110,
  })
);

const StyledMenuItemLabel = styled(Typography)(({ theme }) => ({
  width: "100%",
  display: "flex",
  justifyContent: "flex-start",
  color: "silver",
  fontSize: "0.9em",
  //   marginLeft:15
}));

const MenuItemText = styled(Typography)(({ theme }) => ({
    display: "flex",
    justifyContent: "center",
    alignItems:'center'
  }));

const HorizontalDivider = styled(Box)(({ theme }) => ({
  background: "gray",
  padding: 1,
  borderRadius: 5,
  width: "100%",
  marginTop: 15,
  marginBottom: 15,
}));

const SvgIcon = styled("img")(({ theme }) => ({
  // height: 22,
  // color: theme.palette.secondary.main,
}));

const BoxBtn = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  width: '110px',
  color: '#7DC242',
  '&:hover': {
    opacity: 0.7,
  },
}));

const CircledBox = styled(Box)(({ theme }) => ({
  width: 38,
  height: 38,
  borderRadius: '30px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: '#303030',
  '&:hover': {
    // backgroundColor: '#FFFFFF26',
    opacity: 0.7,
  },
  cursor: 'pointer'
}));

const TypographyText = styled(Typography)(({ theme, type }) => ({
  fontSize: '14px',
  fontWeight: 600,
  width: type === "google" ? '50px' : '',
  textAlign: 'center', 
  cursor: 'pointer', 
  '&:hover': {
    opacity: 0.7,
  },
}));


const customStyling = function(theme) {
  return {
  menu: {
    ".MuiMenu-list": {
      backgroundColor: "#333333",
      // paddingLeft: 2,
      // paddingRight: 2,
      minWidth: 170,
      borderRadius:2,
      paddingTop:2,
      paddingBottom:2,
    },
    ".MuiMenu-paper": {
      backgroundColor: "transparent !important",
      borderRadius: 0,
      borderRadius:2,
    },
    ".MuiMenuItem-root": {
      padding: 0,
      marginTop:{sm:1.6, xs:0.2},
    },
    ".MuiPaper-root" : { 
      
    },
    "@media (max-width: 980px) and (orientation: landscape)":{
      left:"-7% !important",
    },
  },
 } 
};
export default {
  SelectInputBase,
  StyledMenuItem,
  customStyling,
  StyledMenuItemLabel,
  HorizontalDivider,
  SvgIcon,
  MenuItemText,
  BoxContainer,
  BoxBtn,
  CircledBox,
  TypographyText,
};
