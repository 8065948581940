import jwt_decode from "jwt-decode";
import { userInfo } from "../api/log-in/methods";
import { store } from "../redux/store";
import {
  setQuizData,
  setQuizInputText,
  setisMicrosoftEnabled,
  setisGoogleEnabled,
  setAllQuestionsSelected,
  setShowQuizContentPage,
  setquizLandscapeBackBtnClicked,
  setisDriveEnabled,
  setisFromsEnabled,
  setGoogleDriveClientId,
  setGoogleDriveApiKey,
  setisGuruCallEnabled,
  setQuizType,
  setQuizCount,
  setQuizDifficultyLevel,
  setIsQuizLoaded,
} from "../redux/slices/quizSlice";
import {
  setAuthToken,
  setIsSignedIn,
  setIsSkippedState,
  setStripeStatus,
  setUserData,
  setUserInformationState,
  setUserState,
} from "../redux/slices/logInSlice";
import { ABORT_CONTROLLER_MAP, USER_STATE } from "../settings";
import { setEnableEditMode } from "../redux/slices/librarySlice";
import { initAdds } from "../adds_conversion";
import { setBasicPlanPrice, setProMonthlyPlanPrice, setProYearlyPlanPrice } from "../redux/slices/paymentSlice";

const decodeToken = async (token) => {
  return new Promise((resolve, reject) => {
    try {
      var decoded = jwt_decode(token);
      resolve(decoded);
    } catch (err) {
      // // console.log(err);
      return "error";
    }
  });
};

const getToken = () => {
  return localStorage.getItem("authToken");
};

const setToken = (token) => {
  localStorage.setItem("authToken", token);
};

const deleteToken = () => {
  localStorage.removeItem("authToken");
};

const getUserInformation = async () => {
  const response = await userInfo();
  return response;
};

const userLogOut = () => {
  const controller = ABORT_CONTROLLER_MAP.get("quizGenerationSignal");
    if (controller) {
      controller.abort();
      ABORT_CONTROLLER_MAP.delete("quizGenerationSignal");
    }
  deleteToken();
  store.dispatch(setUserState(USER_STATE.LOGGED_OUT_USER));
  store.dispatch(setQuizData({ questionAndAnswers: [] }));
  store.dispatch(setUserData(null));
  store.dispatch(setAuthToken(""));
  store.dispatch(setIsSignedIn(false));
  store.dispatch(setQuizInputText(""));
  store.dispatch(setQuizInputText(""));
  store.dispatch(setAllQuestionsSelected(true));
  store.dispatch(setShowQuizContentPage(false));
  store.dispatch(setquizLandscapeBackBtnClicked(false));
  store.dispatch(setIsQuizLoaded(false));
  store.dispatch(setEnableEditMode(false));
  store.dispatch(setIsSkippedState(-1));
  store.dispatch(setUserInformationState(null));
  store.dispatch(setQuizType('Multiple choice'))
  store.dispatch(setQuizCount(5))
  store.dispatch(setQuizDifficultyLevel('Medium'))
  store.dispatch(setBasicPlanPrice("0.00"))
  store.dispatch(setProMonthlyPlanPrice("0.00"))
  store.dispatch(setProYearlyPlanPrice("0.00"))
  localStorage.removeItem("userData");
  window.history.replaceState({}, document.title, window.location.pathname);
  sessionStorage.removeItem("google-form");
  sessionStorage.removeItem("payment-modal")
  store.dispatch(setStripeStatus(null))
  initAdds();
};

const setMicrosoftEnabled = (isEnabled) => {
  store.dispatch(setisMicrosoftEnabled(isEnabled));
};

const setGoogleEnabled = (isEnabled) => {
  store.dispatch(setisGoogleEnabled(isEnabled));
};

const setDriveEnabled = (isEnabled) => {
  store.dispatch(setisDriveEnabled(isEnabled));
};
const setFormsEnabled = (isEnabled) => {
  store.dispatch(setisFromsEnabled(isEnabled));
};

const setGoogleDriveClientIdRedux = (clientId) => {
  store.dispatch(setGoogleDriveClientId(clientId));
};

const setGoogleDriveApiKeyRedux = (apiKey) => {
  store.dispatch(setGoogleDriveApiKey(apiKey));
};

const setGuruCallEabled = (isEnabled) => {
  store.dispatch(setisGuruCallEnabled(isEnabled));
};

export {
  decodeToken,
  getToken,
  setToken,
  getUserInformation,
  deleteToken,
  userLogOut,
  setMicrosoftEnabled,
  setGoogleEnabled,
  setDriveEnabled,
  setFormsEnabled,
  setGoogleDriveApiKeyRedux,
  setGoogleDriveClientIdRedux,
  setGuruCallEabled,
};
