import styled from "@mui/system/styled";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import LoadingButton from "@mui/lab/LoadingButton";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import Checkbox from "@mui/material/Checkbox";
import Dialog from "@mui/material/Dialog";
import { IconButton } from "@mui/material";

const MainContainer = styled(Box)(({ theme }) => ({
  marginLeft: theme.spacing(6.5),
  marginRight: theme.spacing(6.5),
  [theme.breakpoints.down("sm")]: {
    marginLeft: 0,
    marginRight: 0,
    height: "100%",
  },
}));

const MainContentContainer = styled("div")(
  ({ isleftbox, theme, mainBoxHeight, isMobile }) => ({
    borderTopRightRadius: !isleftbox && !isMobile ? 12 : 0,
    borderBottomRightRadius: !isleftbox && !isMobile ? 10 : 0,
    borderTopLeftRadius: isleftbox && !isMobile ? 12 : 0,
    borderBottomLeftRadius: isleftbox && !isMobile ? 12 : 0,
    backgroundColor: theme.palette.primary.contrastText,
    height: mainBoxHeight,
    [theme.breakpoints.down("sm")]: {
      height: "100%",
      // borderRadius: 10,
    },
    [`${theme.breakpoints.down(
      "md"
    )}  and (orientation: landscape) and (hover: none) and (pointer: coarse)`]:
      {
        // height: isleftbox?mainBoxHeight+160:mainBoxHeight+220,
        height: mainBoxHeight + 160,
        borderTopRightRadius: !isleftbox ? 4 : 0,
        borderBottomRightRadius: !isleftbox ? 4 : 0,
        borderTopLeftRadius: isleftbox ? 4 : 0,
        borderBottomLeftRadius: isleftbox ? 4 : 0,
        // overflowY:'hidden'
        // marginBottom: 10,
      },
  })
);

const ContentGridBox = styled(Grid)(({ iscontentbox, theme }) => ({
  [`${theme.breakpoints.up("sm")}`]: {
    width: "50%",
    height: "50%",
  },
  width: "100%",
  [`${theme.breakpoints.down("sm")} and (orientation: portrait)`]: {
    width: "100%",
    height: iscontentbox && "92%",
  },
  [`${theme.breakpoints.down(
    "md"
  )}  and (orientation: landscape) and (hover: none) and (pointer: coarse)`]: {
    width: "100%",
    height: iscontentbox && "100%",
  },
}));

const BoxHeader = styled(Box)(({ isleftbox, theme }) => ({
  borderTopRightRadius: !isleftbox ? 10 : 0,
  borderTopLeftRadius: isleftbox ? 10 : 0,
  padding: 13,
  paddingRight: 30,
  backgroundColor: theme.palette.secondary.dark,
  color: theme.palette.primary.contrastText,
  display: "flex",
  justifyContent: "space-between",

  [theme.breakpoints.down("sm")]: {
    borderTopRightRadius: 10,
    borderTopLeftRadius: 10,
    display: "none",
  },
  [`${theme.breakpoints.down(
    "md"
  )}  and (orientation: landscape) and (hover: none) and (pointer: coarse)`]: {
    display: "none",
  },
}));

const HeaderLabels = styled(Typography)({
  overflow: "hidden",
  maxWidth: 180,
  whiteSpace: "nowrap",
  "text-overflow": "ellipsis",
});

const ContentInputMainBox = styled(Box)(
  ({ theme, isMobileLandscape, isIpad }) => ({
    position: isMobileLandscape || isIpad ? "relative" : "unset",
    width: "100%",
    height: isMobileLandscape ? "95%" : "auto",
    textAlign: "center",
    verticalAlign: "middle",
    [theme.breakpoints.down("sm")]: {
      // height:"94% !important",
    },
    "@media screen and (max-height: 555px) and (orientation: portrait) and  (hover: none) and (pointer: coarse)":
      {
        height: "90% !important",
      },
  })
);

const InputTextField = styled(TextField)(
  ({ theme, iswarning, iserror, mainBoxHeight, isKeyboardOpen, isIos,mobileHeight }) => ({
    padding: 11,
    ".MuiFormHelperText-root.Mui-error": {
      color: iswarning && !iserror && theme.palette.warning.light,
      [theme.breakpoints.down("sm")]: {
        marginLeft: 10,
        marginRight: 0,
        paddingTop: "0px !important",
        marginTop: "0 !Important",
        display: isKeyboardOpen ? "none" : "block",
      },
    },
    "& .MuiInputBase-input.MuiOutlinedInput-input.Mui-disabled": {
      opacity: 1,
      WebkitTextFillColor: "black",
      color: "black",
    },
    " .MuiInputBase-root": {
      paddingLeft: "0px",
      height: isKeyboardOpen ? "98% !important" : "100%",
    },
    ".MuiOutlinedInput-input": {
      // display:alert(window.innerWidth),
      paddingLeft: 5,
      paddingRight: 5,
      marginTop: 12,
      height: `${
        iserror || iswarning ? mainBoxHeight - 153 : mainBoxHeight - 129
      }px!important`,
      [theme.breakpoints.down("md")]: {
        height: `${
          iserror || iswarning ? mainBoxHeight - 184 : mainBoxHeight - 160
        }px!important`,
      },
      [`${theme.breakpoints.down(
        "md"
      )} and (orientation: portrait) and (hover: none) and (pointer: coarse)`]:
        {
          height: `${
            iserror || iswarning ? mainBoxHeight + 300 : mainBoxHeight + 300
          }px!important`,
        },
      [`${theme.breakpoints.down(
        "md"
      )} and (min-height:800px) and (orientation: portrait) and (hover: none) and (pointer: coarse)`]:
        {
          height: `${
            iserror || iswarning ? mainBoxHeight +300 : mainBoxHeight + 310
          }px!important`,
        },
        [`${theme.breakpoints.down(
          "md"
        )}  and (orientation: portrait) and (min-width:620px) and (max-width:840px) and (hover: none) and (pointer: coarse)`]:
        {
          height: `${
            iserror || iswarning ? mobileHeight === 160? (mainBoxHeight -240):(mainBoxHeight -140) :  mobileHeight === 160? (mainBoxHeight -240): (mainBoxHeight -110)  //ipad
          }px!important`,
        },
      [`${theme.breakpoints.down(
        "md"
      )} and (max-height:800px) and (orientation: portrait) and (hover: none) and (pointer: coarse)`]:
        {
          height: isIos
            ? `${mainBoxHeight + 120}px !important`
            : `${
                iserror || iswarning ? mainBoxHeight + 170 : mainBoxHeight + 170
              }px!important`,
        },

      [`${theme.breakpoints.down(
        "md"
      )}  and (orientation: landscape) and (hover: none) and (pointer: coarse)`]:
        {
          height: `${
            iserror || iswarning ? mainBoxHeight + 50 : mainBoxHeight + 72
          }px!important`, // mobile landscape
        },
      [`${theme.breakpoints.down(
        "sm"
      )} and (max-height:645px)  and (orientation: portrait) and (hover: none) and (pointer: coarse)`]:
        {
          height: `${
            iserror || iswarning ? mainBoxHeight - 5 : mainBoxHeight + 20
          }px !important`,
        }, // for smaller mobiles
        "@media screen and (max-width: 375px) and (min-width: 375px) and (max-height:667px) and (orientation: portrait) and  (hover: none) and (pointer: coarse)":
        {
          height: `${
            iserror || iswarning ? mainBoxHeight - 280 : mainBoxHeight - 240
          }px !important`,
          paddingLeft: 5.8,
          paddingRight: 5.8,
          paddingBottom: isKeyboardOpen ? "40px" : "unset",
        }, // for smaller iphones
        "@media screen and (max-width: 375px) and (min-width: 375px) and (min-height:635px) and (max-height:667px) and (orientation: portrait) and  (hover: none) and (pointer: coarse)":
        {
          height:`${
            iserror || iswarning ? mainBoxHeight +130 : mainBoxHeight+150
          }px !important`,
          paddingLeft: 5.8,
          paddingRight: 5.8,
          paddingBottom: isKeyboardOpen ? "40px" : "unset",
        },// for smaller iphones (Iphone X)
        
        "@media screen and (min-width: 1000px) and (max-width: 1400px) and (orientation: landscape) and  (hover: none) and (pointer: coarse)":{
          // height: `${
          //   iserror || iswarning ? mobileHeight === 160? (mainBoxHeight -240):(mainBoxHeight -140) :  mobileHeight === 160? (mainBoxHeight -240): (mainBoxHeight -110)  //ipad
          // }px!important`,
           //ipad landscape
        },
    },
    [theme.breakpoints.down("sm")]: {
      padding: 2,
      marginLeft: theme.spacing(1.4),
      marginRight: 0,
      paddingTop: theme.spacing(1),
    },
  })
);

const PlaceHolderArea = styled(Box)(
  ({
    theme,
    istext,
    img,
    iskeyboardopen,
    windowHeight,
    isios,
    iserror,
    mainBoxHeight,
    mobileHeight,
    mobileLandscapeHeight,
    isT7
  }) => ({
    outline: "0px solid transparent",
    border: "none",
    width: "100%",
    height: mainBoxHeight - 115,
    maxHeight: mainBoxHeight - 115,
    overflow: "auto",
    backgroundImage: `url("svgs/${img}.svg")`,
    textAlign: "center",
    display: "flex",
    justifyContent: "center",
    backgroundRepeat: "no-repeat",
    alignItems: "center",
    backgroundSize: img === "Quiz" ? 55 : 100,
    backgroundPosition:
      img === "Quiz" && mobileLandscapeHeight == 34
        ? "left 50% top 46%"
        : img === "Quiz" && mobileLandscapeHeight == 79
        ? "left 50% top 42%"
        : "left 50% top 32%",
    backgroundColor: "transparent",
    color: !istext && "#bababa",
    background: istext && "none",
    [theme.breakpoints.down("md")]: {
      height: mainBoxHeight - 143,
      maxHeight: mainBoxHeight - 143,
    },
    [`${theme.breakpoints.down(
      "md"
    )}  and (orientation: portrait) and (min-width:620px) and (max-width:840px) and (hover: none) and (pointer: coarse)`]:
    {
      height: `${mobileHeight === 160 ? mainBoxHeight-276 : mainBoxHeight-176}px !important`,
      maxHeight: `${mobileHeight === 160 ? mainBoxHeight-276 : mainBoxHeight-176}px !important`,
      backgroundPosition:
      img === "Quiz" && mobileHeight === 60
        ? "left 50% top 48% !important"
        : img === "Quiz" && mobileHeight === 160
        ? "left 50% top 48%"
        : "left 50% top 31%",
       //ipad
    },
    [theme.breakpoints.down("sm")]: {
      height:
        iskeyboardopen && istext && !isios ? `${windowHeight - 105}px` : `90%`,
      maxHeight:
        iskeyboardopen && istext && !isios ? `${windowHeight - 105}px` : `90%`,
      backgroundSize: img === "Quiz" ? 45 : 55,
    },
    "@media screen and (max-width: 375px) and (min-width: 375px) and (max-height:667px) and (orientation: portrait) and  (hover: none) and (pointer: coarse)":
      {
        height:
          iskeyboardopen && istext && !isios
            ? `${windowHeight - 105}px`
            : mobileHeight === 160
            ? `300px`
            : `390px`,
        maxHeight:
          iskeyboardopen && istext && !isios
            ? `${windowHeight - 105}px`
            : mobileHeight === 160
            ? `300px`
            : `390px`,
        backgroundSize: img === "Quiz" ? 45 : 55, //  for smaller iphones
      },
    [`${theme.breakpoints.down(
      "md"
    )}  and (orientation: landscape) and (hover: none) and (pointer: coarse)`]:
      {
        height: `${mobileLandscapeHeight === 34 ? 74 : 59}% !important`,
        maxHeight: `${mobileLandscapeHeight === 34 ? 74 : 59}% !important`,
        backgroundSize: img === "Quiz" ? "40px" : "40px", // for mobile landscape
      },
    [`${theme.breakpoints.up(
      "xl"
    )} and (max-width: 1400px) and (max-height: 800px)`]: {
      backgroundSize: img === "Quiz" ? 60 : 75,
      backgroundPosition: "left 50% top 29%",
    },
    [`${theme.breakpoints.up(
      "xl"
    )} and (min-width: 1100px) and (max-height: 730px)`]: {
      backgroundSize: img === "Quiz" ? 50 : 65,
      backgroundPosition:
        img === "Quiz" && mobileLandscapeHeight == 34
          ? "left 50% top 45%"
          : img === "Quiz" && mobileLandscapeHeight == 79
          ? "left 50% top 38%"
          : "left 50% top 31%",
    },
    [`${theme.breakpoints.up(
      "xl"
    )} and (min-width: 1100px) and (max-width: 1370px) and (max-height: 635px)`]: {
      backgroundSize: img === "Quiz" ? 50 : 65,
      backgroundPosition:
        img === "Quiz" && mobileLandscapeHeight == 34
          ? "left 50% top 25%"
          : img === "Quiz" && mobileLandscapeHeight == 79
          ? "left 50% top 20.5%"
          : "left 50% top 28%",
    }, //chrome os for height 633

    [!isT7 ? `
    @media screen and (min-width: 1000px) and (max-width: 1400px) and (orientation: landscape) and (hover: none) and (pointer: coarse) {
      height: ${mobileHeight === 160 ? mainBoxHeight - 276 : mainBoxHeight - 176}px !important;
      maxHeight: ${mobileHeight === 160 ? mainBoxHeight - 276 : mainBoxHeight - 176}px !important; //ipad landscape
      backgroundSize: ${img === "Quiz" ? "50px" : "50px"};
      backgroundPosition: ${
        img === "Quiz" && mobileHeight === 60
          ? "left 50% top 45% !important"
          : img === "Quiz" && mobileHeight === 160
          ? "left 50% top 40%"
          : "left 50% top 31%"
      };
    }
  ` : "" ]: {},
    [`@media screen and (min-width: 1280px) and (max-width: 1280px)`]: {
      backgroundSize:
        mobileLandscapeHeight === 34
          ? img === "Quiz"
            ? 55
            : 10
          : img === "Quiz"
          ? 55
          : 45,
      backgroundPosition:
        img === "Quiz" && mobileLandscapeHeight === 34
          ? "left 50% top 40%"
          : img === "Quiz" && mobileLandscapeHeight === 79
          ? "left 50% top 40%"
          : "left 50% top 31%",
    },
    "@media screen and (max-width: 1280px) and (min-width: 1280px) and (max-height:540px) and (min-height:540px)":
    {
      backgroundSize:
        mobileLandscapeHeight === 34
          ? img === "Quiz"
            ? 55
            : 10
          : img === "Quiz"
          ? 55
          : 45,
      backgroundPosition:
        img === "Quiz" && mobileLandscapeHeight === 34
          ? "left 50% top 40%"
          : img === "Quiz" && mobileLandscapeHeight === 79
          ? "left 50% top 40%"
          : "left 50% top 31%",
    } //T7
  })
);

const NoQuizText = styled(Typography)(({ theme,desktopHeight,isMobile,isMobileLandscape,mobileHeight,isIpad,isIpadLandscape,isT7 }) => ({
  wordWrap: "break-word",
  fontSize: "0.9rem",
  display: "flex",
  // paddingTop: 20,
  position:isMobile || isMobileLandscape?'unset':'relative',
  paddingTop: !isIpad && !isIpadLandscape? (desktopHeight===79? isT7?80:60: 100): isIpadLandscape && !isT7 && mobileHeight===160?140:isIpadLandscape && !isT7?"20%":"30%",
  height: "100%",
  textAlign: "center",
  justifyContent: "center",
  alignItems: "center",
  [`${theme.breakpoints.down(
    "md"
  )}  and (orientation: landscape) and (hover: none) and (pointer: coarse)`]: {
    fontSize: 11,
    paddingTop: 40,
  },
}));

const createQuizBox = styled(Box)(
  ({ theme, iskeyboardopen, isios, position, isIpad }) => ({
    padding: 24,
    paddingTop: 13,
    paddingBottom: 16,
    borderTop: "2px solid #e5e5e5",
    textAlign: "center",
    display: "flex",
    justifyContent: isIpad ? "end" : position ? "space-between" : "end",
    alignItems: "center",
    position: isIpad ? "absolute" : "unset",
    left: isIpad ? 0 : "unset",
    right: isIpad ? 0 : "unset",
    [theme.breakpoints.down("md")]: {
      // flexDirection: "column",
      alignItems: "flex-start",
    },
    [theme.breakpoints.down("sm")]: {
      position: iskeyboardopen && isios ? "relative" : "fixed",
      backgroundColor: "white",
      bottom: 0,
      left: 0,
      paddingTop: 8.5,
      paddingBottom: 10,
      width: "-webkit-fill-available",
      flexDirection: "row",
      border: "0.7px solid #e5e5e5",
      textAlign: "center",
      display: "flex",
      justifyContent: position ? "space-between" : "end",
      alignItems: "center",
      paddingLeft: theme.spacing(2.5),
      paddingRight: theme.spacing(2.5),
      width: "100%",
    },
    [`${theme.breakpoints.down(
      "md"
    )}  and (orientation: landscape) and (hover: none) and (pointer: coarse)`]:
      {
        flexDirection: "row",
        padding: 10,
        paddingBottom: 10,
        paddingTop: 10,
        paddingLeft: theme.spacing(2.5),
        paddingRight: theme.spacing(2.5),
        justifyContent: position ? "space-between" : "end",
        position: "absolute",
        right: 0,
        left: 0,
      },
  })
);

const SentenceAndWordCount = styled(Typography)(({ theme }) => ({
  textAlign: "center",
  height: "100%",
  color: "grey",
  fontSize: 14,
  maxWidth: "90%",
  whiteSpace: "nowrap",
  textOverflow: "ellipsis",
  overflow: "hidden",
  [theme.breakpoints.down("md")]: {
    marginBottom: 5,
  },
  "@media (max-width: 380px)": {
    alignItems: "unset",
  },
  // [theme.breakpoints.down("sm")]: {
  //   display: "none",
  // },
  // [`${theme.breakpoints.down("md")}  and (orientation: landscape) and (hover: none) and (pointer: coarse)`]: {
  //   display: "none",
  // },
}));

const SentenceAndWordCountMobile = styled(Typography)(({ theme }) => ({
  textAlign: "left",
  height: "100%",
  color: "grey",
  fontSize: 11,
  [theme.breakpoints.up("sm")]: {
    display: "none",
  },
  [`${theme.breakpoints.down(
    "md"
  )}  and (orientation: landscape) and (hover: none) and (pointer: coarse)`]: {
    display: "block",
  },
}));

const CreateQuizButton = styled(LoadingButton)(
  ({ theme, isquizloading, order,isDisabled }) => ({
    color: isDisabled===true?"white":theme.palette.primary.contrastText,
    backgroundColor:isDisabled===true?"#CCCCCC":"#7DC243",
    textTransform: "none",
    fontSize: "1.0666666666666667rem",
    paddingLeft: isquizloading ? 35 : order ? 10 : 15,
    [`${theme.breakpoints.down(
      "md"
    )}  and (orientation: landscape) and (hover: none) and (pointer: coarse)`]:
      {
        paddingRight: isquizloading && 0,
        marginLeft: "8px",
        clipPath: order
          ? "polygon(0 0, 59% 0, 87% 0, 100% 50%, 88% 99%, 53% 100%, 0 100%)"
          : "unset",
      },
  })
);

const RightArrowBtn = styled(LoadingButton)(
  ({ theme, isquizloading, isarrow,isDisabled  }) => ({
    color: isDisabled===true?"white":theme.palette.primary.contrastText,
    backgroundColor:isDisabled===true?"#CCCCCC":"#7DC243",
    textTransform: "none",
    paddingLeft: isquizloading && isarrow ? 35 : 15,
    paddingRight: isarrow && 30,
    clipPath:
      isarrow &&
      "polygon(0 0, 59% 0, 87% 0, 100% 50%, 88% 99%, 53% 100%, 0 100%)",
  })
);

const LeftArrowBtn = styled(Button)(({ theme }) => ({
  color: theme.palette.primary.contrastText,
  textTransform: "none",
  paddingRight: 22,
  paddingLeft: 25,
  clipPath:
    "polygon(14% 0, 43% 0, 100% 0, 100% 100%, 48% 100%, 13% 100%, 0% 50%)",
}));

const QuizContent = styled(Box)(
  ({ theme, isvisibleactionbox, isquizloading, mainBoxHeight }) => ({
    paddingTop: 0,
    paddingBottom: 0,
    overflow: "scroll",
    "-webkit-overflow-scrolling": "touch",
    overflowX: "hidden",
    overflowY: isquizloading && "hidden",
    height: `${isvisibleactionbox ? mainBoxHeight - 153 + "px" : "79.3%"}`,
    [`${theme.breakpoints.down("xxxl")}`]: {
      height: `${mainBoxHeight - 153.5}px !important`,
    },
    "@media screen and (min-width: 1280px) and (max-width: 1280px) and (hover: none) and (pointer: coarse)":
      {
        height: `${mainBoxHeight - 153.5}px !important`,
      },
    [theme.breakpoints.down("md")]: {
      height: `${mainBoxHeight - 153}px !important`,
    },
    [`${theme.breakpoints.down(
      "md"
    )}  and (orientation: landscape) and (hover: none) and (pointer: coarse)`]:
      {
        height: `${
          isvisibleactionbox
            ? mainBoxHeight + 68.5 + "px"
            : mainBoxHeight + 123.5 + "px"
        }!important`,
        paddingTop: 2,
        paddingBottom: 2,
      },
    [`${theme.breakpoints.down("xmd")} and (orientation: portrait)`]: {
      height: `${
        isvisibleactionbox ? mainBoxHeight - 150 + "px" : "83%"
      }!important`,
      paddingTop: 5,
      paddingBottom: 5,
      // boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",
    },
    [theme.breakpoints.down("sm")]: {
      paddingTop: 0,
      paddingBottom: 0,
      height: `${isquizloading ? "100%" : `91%`}!important`,
      boxShadow: "none",
    },
  })
);

const QuestionAnswersBox = styled(Box)(({ theme, showactionbtns }) => ({
  borderTop: "1.5px solid #e5e5e5",
  paddingTop: 8,
  paddingBottom: 15,
  paddingLeft: 30,
  paddingRight: !showactionbtns ? 30 : 17,
  width: "100%",
  alignItems: "flex-start",
  "&:hover": {
    background: "#fafafa",
  },
  [theme.breakpoints.down("sm")]: {
    paddingTop: 0,
    paddingLeft: theme.spacing(2.5),
    paddingRight: theme.spacing(2.5),
  },
  [`${theme.breakpoints.down(
    "md"
  )}  and (orientation: landscape) and (hover: none) and (pointer: coarse)`]: {
    paddingTop: 0,
    borderTopRightRadius: 4,
  },
}));

const FlexGrid = styled(Grid)(({ theme }) => ({
  display: "flex",
  [theme.breakpoints.down("xmd")]: {
    flexDirection: "column",
  },
  [`${theme.breakpoints.down(
    "md"
  )}  and (orientation: landscape) and (hover: none) and (pointer: coarse)`]: {
    flexDirection: "column",
  },
}));

const QuestionAnswerTextField = styled(TextField)(({ theme, iscorrect }) => ({
  borderRadius: 3,
  padding: "0!important",
  marginTop: 9,
  ".MuiOutlinedInput-input": {
    padding: 2,
    // border:'1px solid red'
    [theme.breakpoints.down("sm")]: {
      fontSize: "13px !important",
    },
  },
  "& fieldset": {
    border: iscorrect && `2.5px solid #76f20c`,
  },
  width: "99%",
  [theme.breakpoints.down("sm")]: {
    marginTop: 5,
  },
  [`${theme.breakpoints.down(
    "md"
  )}  and (orientation: landscape) and (hover: none) and (pointer: coarse)`]: {
    marginTop: 5,
  },
}));

const QuizActionsBox = styled(Box)(({ theme, isvisible, isIpad, isIos }) => ({
  marginTop: 2,
  padding: 24,
  paddingTop: 12,
  paddingBottom: 22,
  borderSpacing: 40,
  marginRight: 0,
  paddingRight: isIpad && isIos ? 15 : 20,
  borderTop: "2px solid #e5e5e5",
  textAlign: "center",
  display: isvisible ? "flex" : "none",
  justifyContent: "flex-end",
  alignItems: "center",
  [theme.breakpoints.down("md")]: {
    flexDirection: "row",
    alignItems: "flex-end",
  },
  [`${theme.breakpoints.down("xmd")} and (orientation: portrait)`]: {
    flexDirection: "row",
    justifyContent: "flex-end",
    padding: 15,
    paddingTop: 12,
    paddingRight: "20px",
  },
  [`${theme.breakpoints.down(
    "md"
  )}  and (orientation: landscape) and (hover: none) and (pointer: coarse)`]: {
    flexDirection: "row",
    justifyContent: "flex-end",
    padding: 10,
    paddingTop: 11.5,
    paddingBottom: 11,
    paddingRight: 20,
  },
  [theme.breakpoints.down("xsm")]: {
    flexDirection: "row",
    justifyContent: "flex-end",
  },
  [theme.breakpoints.down("sm")]: {
    display: "none",
  },
}));

const QuizActionsText = styled(Typography)(({ theme }) => ({
  display: "block",
  [`${theme.breakpoints.down(
    "md"
  )}  and (orientation: landscape) and (hover: none) and (pointer: coarse)`]: {
    display: "none",
  },
}));

const MobileQuizActionsBox = styled(Box)(({ theme }) => ({
  position: "fixed",
  bottom: 0,
  left: 0,
  paddingTop: 10,
  paddingBottom: 10,
  width: "-webkit-fill-available",
  flexDirection: "row",
  borderTop: "none",
  border: "0.7px solid #e5e5e5",
  width: "100%",
  textAlign: "center",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  background: "white",
  paddingLeft: theme.spacing(2.5),
  paddingRight: theme.spacing(2.5),
  [theme.breakpoints.up("sm")]: {
    display: "none",
  },
}));

const AnswerLabel = styled(Typography)(({ theme, showactionbtns }) => ({
  fontWeight: "bold",
  marginLeft: 45,
  marginRight: !showactionbtns && 25.8,
  marginTop: 14,
  [theme.breakpoints.down("xmd")]: {
    marginLeft: 0,
    marginRight: 0,
    marginTop: 4,
  },
  [`${theme.breakpoints.down(
    "md"
  )}  and (orientation: landscape) and (hover: none) and (pointer: coarse)`]: {
    marginLeft: 0,
    marginRight: 0,
    marginTop: 4,
  },
}));

const MobileTransparentActionBtn = styled(Button)(({ theme }) => ({
  marginLeft: 9,
  textTransform: "none",
  paddingRight: 0,
  minWidth: "auto",
  fontSize: 12,
  "& > *:first-child": { marginRight: 2 },
}));

const QuestionLabel = styled(Typography)(({ theme }) => ({
  fontWeight: "bold",
  marginRight: 24,
  marginTop: 7,
  [theme.breakpoints.down("sm")]: {
    marginRight: 0,
    marginTop: 9,
  },
}));

const AnswersBox = styled(Box)(({ theme, isnoanswer }) => ({
  display: "flex",
  flexDirection: "column",
  width: "100%",
  alignItems: isnoanswer ? "flex-end" : "flex-start",
}));

const MyDivider = styled(Divider)(({ theme }) => ({
  marginLeft: 10,
  marginRight: 10,
  marginTop: 5,
  marginBottom: 5,
  borderRightWidth: 2,
  [theme.breakpoints.down("xl")]: {
    marginLeft: 5,
    marginRight: 5,
  },
  [theme.breakpoints.down("md")]: {
    display: "none",
  },
  [`${theme.breakpoints.down("xmd")} and (orientation: portrait)`]: {
    display: "block",
  },
  [theme.breakpoints.down("xsm")]: {
    display: "none",
  },
}));

const AnswerAndIconBox = styled("div")(({ theme }) => ({
  width: "100%",
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
}));

const ModalHeader = styled(Box)({
  padding: 8,
  paddingRight: 12,
  boxShadow: "rgba(100, 100, 111, 0.2) 0px 3px 29px 0px",
  paddingLeft: 25,
  alignItems: "center",
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
});

const ModalActions = styled(Box)({
  padding: 5,
  paddingTop: 15,
  paddingBottom: 15,
  borderTop: "1px solid silver",
  display: "flex",
  justifyContent: "center",
});

const ModalTextField = styled(TextField)(({ theme }) => ({
  borderRadius: 3,
  // padding:5,
  "& .MuiInputBase-root": {
    background: "#F6F6F6",
  },
  "& input::placeholder": {
    color: "#000000",
    opacity: 1,
  },
  "& .MuiFormHelperText-root.Mui-error": {
    position: "absolute",
    top: "100%",
  },
  ".MuiOutlinedInput-input": {
    padding: 7,
  },
  width: "100%",
  [theme.breakpoints.down("md")]: {},
}));

const ModalBox = styled(Box)(({ theme }) => ({
  width: "30%",
  [theme.breakpoints.down("lg")]: {
    width: "60%",
  },
  [theme.breakpoints.down("sm")]: {
    width: "95%",
  },
}));

const ModalContentBox = styled(Box)({
  marginTop: 30,
  marginLeft: 25,
  marginRight: 25,
  marginBottom: 30,
});

const ImgButton = styled("img")({
  height: 13,
});

const ErrorText = styled(Typography)(({ theme }) => ({
  color: theme.palette.warning.main,
  fontSize: 12,
  padding: 2,
  display: "flex",
  textAlign: "flex-start",
}));

const CircularLoaderBox = styled(Box)({
  display: "flex",
  justifyContent: "center",
  width: "100%",
  height: "90%",
  alignItems: "center",
});

const placeHolderDivText = styled(Typography)(
  ({ theme, istext, isMobile, isMobileLandscape, isIpadLandscape, isTl7 }) => ({
    position: "absolute",
    wordWrap: "break-word",
    fontSize: "0.9rem",
    height: isMobile
      ? "auto"
      : isMobileLandscape
      ? "auto"
      : isIpadLandscape
      ? "auto"
      : isTl7
      ? "auto"
      : "auto",
    width: isMobile ? "unset" : isMobileLandscape ? "unset" : "unset",
    paddingTop: isIpadLandscape ? 10 : 20,
    display: istext && "none",
    textAlign: "center",
    justifyContent: "center",
    alignItems: "center",
    [`${theme.breakpoints.down(
      "md"
    )}  and (orientation: landscape) and (hover: none) and (pointer: coarse)`]:
      {
        fontSize: 11,
        paddingTop: 38,
      },
  })
);

const SelectTextBox = styled("div")(({ theme }) => ({
  paddingLeft: 30,
  paddingBottom: 6,
  marginTop: 3,
  overflow: "hidden",
  whiteSpace: "nowrap",
  "text-overflow": "ellipsis",
  // borderBottom:"0.5px solid #e5e5e5",
  [theme.breakpoints.down("sm")]: {
    marginTop: 3,
    marginBottom: 0,
    paddingLeft: theme.spacing(2.5),
  },
  [`${theme.breakpoints.down(
    "md"
  )}  and (orientation: landscape) and (hover: none) and (pointer: coarse)`]: {
    marginBottom: 0,
    marginTop: 0,
    paddingRight: "2%",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
}));

const CreateQuizBtnDesktopBox = styled(Box)(
  ({ theme, order, isMobileLandscape }) => ({
    display: "flex",
    flexDirection: isMobileLandscape && order ? "row-reverse" : "unset",
    marginTop: 0,
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  })
);

const CreateQuizBtnMobileBox = styled(Box)(({ theme }) => ({
  display: "flex",
  marginTop: 1,
  [theme.breakpoints.up("sm")]: {
    display: "none",
  },
}));

const customStyling = (obj) => {
  const isErrorOrWarning = obj?.isError || obj?.isWarning;
  const isKeyboardOpen = obj?.iskeyboardopen;
  const inputFieldCustom = {
    inputFieldCustom: {
      "& fieldset": {
        border: "none",
      },
      ".MuiInputBase-root": {
        padding: 0,
        paddingLeft: { xs: 0, sm: 1.5 },
        // paddingRight: { xs: 0, sm: 1.5 },
        height: {
          xs: isErrorOrWarning ? (isKeyboardOpen ? "93%" : "96%") : "100%",
          sm: "100%",
        },
      },
      height: { xs: isErrorOrWarning ? "96%" : "100%", sm: "100%" },
      padding: 0,
      "-webkit-overflow-scrolling": "touch",
      overflowX: "hidden",
      overflow: "hidden",
    },
    questionAnswerCustom: {
      ".MuiOutlinedInput-input": {
        paddingTop: { sm: 0.2, xs: 0.2 },
        paddingBottom: { sm: 0.2, xs: 0.2 },
        paddingLeft: 0.5,
        paddingRight: 0.5,
      },
      ".MuiOutlinedInput-root": {
        paddingTop: { sm: 0.2, xs: 0.2 },
        paddingBottom: { sm: 0.2, xs: 0.2 },
        paddingLeft: 0.5,
        paddingRight: 0.5,
      },
    },
    exportModal: {
      position: "absolute",
      top: { xs: "45%", sm: "50%" },
      left: "50%",
      transform: "translate(-50%, -50%)",
      bgcolor: "background.paper",
      borderRadius: 2,
      boxShadow: 24,
      overflowY: "auto",
      maxHeight: "90%",
      border: "none",
    },
  };
  return inputFieldCustom;
};
const QuestionCountCheckBox = styled(Checkbox)({
  "&.question-count .MuiSvgIcon-root": {
    color: "#7DC242",
  },
});

const StyledUnCheckedIcon = styled("div")({
  borderRadius: 2,
  backgroundColor: "#7DC242",
  width: 20,
  height: 20,
});

const SelectAllText = styled(Typography)(({ theme }) => ({
  [theme.breakpoints.down("md")]: {
    overflow: "hidden",
    maxWidth: 230,
    whiteSpace: "nowrap",
    "text-overflow": "ellipsis",
  },
}));

const Seperator = styled(Divider)(({ theme }) => ({
  backgroundColor: theme.palette.info.main,
  width: 2,
  marginLeft: "5%",
  marginRight: "5%",
}));

const SampleTextModal = styled(Dialog)(({ theme }) => ({
  position: "absolute",
  color: "black",
  width: "40vw",
  left: "6.5vw",
  [theme.breakpoints.down("lg")]: {
    width: "45vw",
    bottom: "1%",
  },
}));

const MobileSentenceBox = styled(Box)(
  ({ theme, isIpad, isIos, isMobileLandscape }) => ({
    backgroundColor: "#E5E5E5",
    height: isIpad ? "3vh" : "5.5vh",
    position:
      isIos && !isIpad && !isMobileLandscape
        ? "absolute"
        : isIpad
        ? "sticky"
        : "absolute",
    zIndex: isMobileLandscape ? 20 : "unset",
    bottom: isIpad ? 0 : "6vh",
    // paddingBottom:isIpad?'unset':'10px',
    width: "100%",
    [`${theme.breakpoints.down(
      "md"
    )}  and (orientation: landscape)  and (hover: none) and (pointer: coarse) `]:
      {
        bottom: 0,
      },
    [theme.breakpoints.down("sm")]: {
      paddingBottom: "5px",
    },
    "@media screen and (max-height: 605px) and (orientation: portrait) and  (hover: none) and (pointer: coarse)":
      {
        paddingTop: isIos ? "3px" : "unset",
        paddingBottom: isIos ? "unset" : "13px",
      },
  })
);

const WriteQuizImg = styled('img')(({ theme,hasText,isMobileLandscape,isIpadLandscape,isT7 }) => ({
  display:hasText ?"none":"block",
  zIndex:10,
  cursor:'pointer',
  margin:'auto',
  position:'relative',
  marginBottom: isT7?"9px":'15px',
  width:isMobileLandscape?"48px":isIpadLandscape?"70px":"unset",
  height: isMobileLandscape?"48px":isIpadLandscape?"70px":"unset"
}));

const PlaceholderDivider = styled(Divider)(({ theme,isMobileLandscape,isIpadLandscape,isT7}) => ({
  marginTop: isMobileLandscape?"5px":isT7?"unset":"15px",
  marginBottom: isMobileLandscape?"5px":isT7?"unset":"15px",
  width: isMobileLandscape?"230px":"300px",
  marginLeft: "auto",
  marginRight: "auto",
  color: "#333333B2",
}));

const PlaceholderIconBtn = styled(IconButton)(({ theme,isVariationEnabled,isT7}) => ({
  marginRight: "12px",
  color: isVariationEnabled===false?"#2062BB":"#7DC242",
  zIndex: 5,
  cursor: "pointer",
  fontSize: "14px",
  lineHeight: "19.07px",
  "&:hover": { opacity: 0.8 },
  marginTop: isT7?'-8px':"unset"
}));

export default {
  MainContainer,
  BoxHeader,
  MainContentContainer,
  ContentInputMainBox,
  InputTextField,
  HeaderLabels,
  customStyling,
  NoQuizText,
  createQuizBox,
  SentenceAndWordCount,
  SentenceAndWordCountMobile,
  CreateQuizButton,
  QuizContent,
  QuestionAnswersBox,
  QuestionAnswerTextField,
  FlexGrid,
  CreateQuizBtnDesktopBox,
  CreateQuizBtnMobileBox,
  QuizActionsBox,
  QuizActionsText,
  AnswersBox,
  AnswerLabel,
  QuestionLabel,
  MyDivider,
  AnswerAndIconBox,
  ModalHeader,
  ModalActions,
  ModalTextField,
  ModalBox,
  ModalContentBox,
  ContentGridBox,
  ErrorText,
  ImgButton,
  CircularLoaderBox,
  PlaceHolderArea,
  RightArrowBtn,
  LeftArrowBtn,
  SelectTextBox,
  placeHolderDivText,
  MobileQuizActionsBox,
  MobileTransparentActionBtn,
  QuestionCountCheckBox,
  StyledUnCheckedIcon,
  SelectAllText,
  Seperator,
  SampleTextModal,
  MobileSentenceBox,
  WriteQuizImg,
  PlaceholderDivider,
  PlaceholderIconBtn
};
