import styled from "@mui/system/styled";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { Typography } from "@mui/material";

const ModalActions = styled(Box)({
  padding: "12px 5px",
  borderTop: "1px solid silver",
  display: "flex",
  justifyContent: "center",
});

const ModalPrmissionSignInBox = styled(Box)(({ theme }) => ({
  width: "470px",
  [theme.breakpoints.down("lg")]: {
    width: "470px",
  },
  [theme.breakpoints.down("sm")]: {
    width: "95%",
  },
}));

const ModalContentBox = styled(Box)({
  marginTop: 30,
  marginLeft: 25,
  marginRight: 25,
  marginBottom: 30,
  display: "flex",
  justifyContent: "flex-start",
});

const TextTypography = styled(Typography)(({ theme }) => ({
  fontSize: "14px",
  color: "#333",
  //   marginLeft: "15px",
  fontWeight: 400,
}));
const HeadingTextTypography = styled(Typography)(({ theme }) => ({
  fontSize: "24px",
  color: "#231F20",
  fontWeight: 600,
  marginBottom: "5px",
  textAlign: "center",
  lineHeight:'27px',
  marginBottom:'20px'
}));

const DescText = styled(Typography)(({ theme,downgradeFlag }) => ({
  fontSize: downgradeFlag===true?"24px":"14px",
  color: "#231F20",
  fontWeight: 400,
  textAlign: "center",
  lineHeight:downgradeFlag===true?"32.68px":'20px'
}));


const ContainerBox = styled(Box)(({ theme, isMobile }) => ({
  width: isMobile ? "100%" : "410px",
}));

const HeadingTextContainer = styled(Box)(({ theme, isMobile }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "column",
  width: "100%"
}));
const customStyling = {
  exportModal: {
    position: "absolute",
    top: { xs: "45%", sm: "50%" },
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    borderRadius: 2,
    boxShadow: 24,
    overflowY: "auto",
    maxHeight: "90%",
    border: "none",
  },
  button: {
    width: "392px",
    height: "54px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    background: "#EBF5FA",
    border: "1px solid #A7D5EC",
    borderRadius: "4px",
    textTransform: "none",
  },
};

export default {
  ModalActions,
  ModalContentBox,
  customStyling,
  ModalPrmissionSignInBox,
  ContainerBox,
  TextTypography,
  HeadingTextContainer,
  HeadingTextTypography,
  DescText
};
