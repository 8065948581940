import styled from "@mui/system/styled";
import AppBar from "@mui/material/AppBar";
import Grid from "@mui/material/Grid";
import { Box, Divider, Typography } from "@mui/material";
import Button from "@mui/material/Button";
const OneScreenLogo = styled("img")(({ theme }) => ({
  cursor: "pointer",
  height: 52.5,
  [`${theme.breakpoints.down(
    "md"
  )}  and (orientation: landscape) and (hover: none) and (pointer: coarse)`]: {
    height: 39,
  },
  [`${theme.breakpoints.down("xmd")} and (orientation: portrait)`]: {
    height: 50,
  },
  [theme.breakpoints.down("sm")]: {
    height: 37,
  },
}));

const BetaLogo = styled("img")(({ theme }) => ({
  height: 13,
  marginTop: 10,
  opacity: 0,
  [theme.breakpoints.down("sm")]: {
    height: 10,
    marginTop: 5,
  },
  [`${theme.breakpoints.down(
    "md"
  )}  and (orientation: landscape) and (hover: none) and (pointer: coarse)`]: {
    height: 10,
    marginTop: 5,
  },
}));

const version = styled("p")(({ theme }) => ({
  height: 0,
  marginTop: 37,
  marginLeft:-72,
  color: "lightgray",
  fontSize: 12,
  [theme.breakpoints.down("sm")]: {
    marginLeft: -75.5,
    marginTop: 25,
    fontSize: "0.6em",
  },
  [`${theme.breakpoints.down(
    "md"
  )}  and (orientation: landscape) and (hover: none) and (pointer: coarse)`]: {
    // marginLeft: "-53%",
    // marginTop:'18.4%',
    // marginLeft:'-90%',
    // marginTop:'50%',
    position: "absolute",
    marginLeft: "-77px",
    top: "-3px",
    fontSize: 10,
  },
}));

const FeedbackLogo = styled("img")(({ theme, isIpad }) => ({
  width: isIpad ? "46.55px" : "32px",
  height: isIpad ? "40.18px" : "28.55px",
  marginTop: 5,
  [theme.breakpoints.down("sm")]: {
    height: 18,
  },
  [`${theme.breakpoints.down(
    "md"
  )}  and (orientation: landscape) and (hover: none) and (pointer: coarse)`]: {
    height: 18,
  },
}));

const AppBarContainer = styled(AppBar)(
  ({ theme, isvisible, isIpad, isAboutPage }) => ({
    background: `linear-gradient(90deg, #4E0F89 0%, #7D00BA 100%)`,
    height: isIpad ? "80px" : "unset",
    paddingLeft: isIpad && !isAboutPage ? "6%" : "unset",
    paddingRight: isIpad && !isAboutPage ? "6%" : "unset",
    display: "flex",
    justifyContent: "center",
    [`${theme.breakpoints.down(
      "md"
    )}  and (orientation: landscape) and (hover: none) and (pointer: coarse)`]:
      {
        display: !isvisible && "none",
      },
  })
);

const FeedbackContainer = styled(Grid)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  // marginRight: isAboutPage?'25%':'unset',
  [theme.breakpoints.down("sm")]: {
    marginLeft: 0,
    alignItems: "left",
  },
}));

const Toolbar = styled("div")(({ theme, isMobile, isMobileLandscape }) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  paddingTop: 13,
  paddingBottom: 13,
  marginLeft: theme.spacing(10),
  marginRight: theme.spacing(10),
  [theme.breakpoints.down("sm")]: {
    display: "none",
  },
  [`${theme.breakpoints.down(
    "md"
  )}  and (orientation: landscape) and (hover: none) and (pointer: coarse)`]: {
    paddingTop: 6,
    paddingBottom: 6,
    ...(isMobileLandscape && { display: "none" }),
  },
  "@media screen and (max-width: 1400px) and (max-height: 800px)": {
    paddingTop: 7.5,
    paddingBottom: 7,
    ...(isMobileLandscape && { display: "none" }),
  },
  " @media screen and (min-width:620px) and (max-width:960px)": {
    display: "none",
  },
  " @media screen and (min-width:620px) and (max-width:840px) and (orientation: portrait) and  (hover: none) and (pointer: coarse)":
    {
      display: "none",
    },
}));

const ToolbarMobile = styled("div")(({ theme, isMobileLandscape }) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  paddingTop: 6.5,
  paddingBottom: 7.5,
  marginLeft: theme.spacing(2.5),
  marginRight: theme.spacing(2),
  [theme.breakpoints.up("sm")]: {
    display: isMobileLandscape ? "flex" : "none",
  },
  " @media screen and (min-width:620px) and (max-width:960px) ": {
    display: "flex",
  },
}));

const VerticalSeperator = styled(Divider)(({ theme }) => ({
  backgroundColor: theme.palette.info.main,
}));

const CopyrightText = styled(Typography)(({}) => ({
  color: "lightgray",
  fontSize: "0.6em",
  "@media screen and (min-width: 1366px) and (max-width: 1366px)": {
    bottom: "6%",
  },
}));

const OneScreenLink = styled("a")(({}) => ({
  color: "lightgray",
  marginLeft: 3,
}));

const HeaderButton = styled(Button)(({}) => ({
  height: "28px",
  width: "121px",
  color: "white",
  backgroundColor: "transparent",
  textTransform: "none",
  marginRight: "30px",
  minWidth: "unset",
  maxWidth: "unset",
  padding: "unset",
}));

const SignUpButton = styled(Button)(({ theme }) => ({
  color: theme.palette.primary.contrastText,
  textTransform: "none",
  marginRight: "30px",
  minWidth: "144px",
  height: "31px",
}));
const LoginButton = styled(Button)(({ theme }) => ({
  color: theme.palette.secondary.main,
  height: "31px",
  width: "81px",
  textTransform: "none",
  marginRight: "30px",
  borderRadius: "4px",
  backgroundColor: "rgba(255, 255, 255, 0.9)",
  "&:hover": {
    backgroundColor: "#fff",
    color: theme.palette.secondary.main,
  },
}));

const SignUpButtonMobile = styled(Button)(
  ({ theme, isMobileLandscape, isIpad, isMobile }) => ({
    color: theme.palette.primary.contrastText,
    textTransform: "none",
    marginRight: "20px",
    width: isMobileLandscape
      ? "106px"
      : isIpad
      ? "150px"
      : isMobile
      ? "120px"
      : "104px",
    fontWeight: "600",
    fontSize: "14px",
  })
);

const PlanBox = styled(Box)(
  ({ theme,isMobile,isMobileLandscape,isIpad,isIpadLandscape,isTl,isAboutPage}) => ({
    backgroundColor:'#7DC242',
    color:'#FFFFFF',
    // width:'48px',
    height:'14px',
    display:'flex',
    flexDirection:'row',
    justifyContent:'center',
    alignItems:'center',
    position:'absolute',
    top: isMobile || isMobileLandscape?"35px":isIpadLandscape || isTl?'46px':'52px',
    left: isMobile || isMobileLandscape?"60px":isIpad && isAboutPage?"75px":isIpad?"125px": isIpadLandscape || isTl?"180px":'190px',
    borderRadius:'2px',
    paddingRight:'10px',
    paddingLeft:'10px',
  })
);

const PlanText = styled(Typography)(
  ({ theme}) => ({
    fontFamily:'Open Sans',
    fontSize:'8px',
    fontWeight:600,
    lineHeight:'10.89px',
  })
);

const customStyling = {
  divider: {
    ml: 3.5,
    mr: 3.5,
    height: 19,
    mt: 0.6,
    opacity: 0.7,
    borderRadius: 3,
  },
};

const DrawerIcon = styled("img")(({ isIpad, isMobile, isMobileLandscape }) => ({
  width: isIpad
    ? "41.01px"
    : isMobile || isMobileLandscape
    ? "26px"
    : "20.56px",
  height: isIpad ? "23px" : isMobile || isMobileLandscape ? "26px" : "10.65px",
}));

export default {
  OneScreenLogo,
  BetaLogo,
  FeedbackLogo,
  Toolbar,
  AppBarContainer,
  FeedbackContainer,
  VerticalSeperator,
  customStyling,
  version,
  ToolbarMobile,
  CopyrightText,
  OneScreenLink,
  HeaderButton,
  DrawerIcon,
  SignUpButton,
  LoginButton,
  SignUpButtonMobile,
  PlanBox,
  PlanText
};
