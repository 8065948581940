import Styled from "./styles";
import intlMessages from "../../locales/en";
import { Box, Typography } from "@mui/material";
import { deleteQuiz, getQuiz } from "../../api/library/method";
import { store } from "../../redux/store";
import {
  setQuizData,
  setQuizInputText,
  setAllQuestionsSelected,
  setShowQuizContentPage,
  setquizLandscapeBackBtnClicked,
  setQuizTitle,
  setQuizId,
  setOpenExportMenu,
  setQuizType,
  setQuizDifficultyLevel,
  setQuizCount,
  setIsQuizLoaded,
  setQuizGenerationCurrentState,
  setLibraryWarning,
  setIsDisableQuizSettings,
} from "../../redux/slices/quizSlice";
import {
  setOpenLibrary,
  setEnableEditMode,
  setOpenDeleteBox,
  setNavigateToQuizPage,
  setEnableShareMode,
} from "../../redux/slices/librarySlice";
import DropDownMenu from "../../common/drop-down-menu/component";
import SortMenu from "../../common/sort-menu/component";
import { Categories, Events, logEvent } from "../../analytics";
import { ABORT_CONTROLLER_MAP } from "../../settings";
import {
  setOpenUpgradeModel,
  setUpgradeModalText,
} from "../../redux/slices/featuresSlice";

const getDateAndTime = (dateString) => {
  const date = new Date(dateString);
  const day = date.getDate().toString().padStart(2, "0");
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const year = date.getFullYear();
  const hours = date.getHours();
  const minutes = date.getMinutes().toString().padStart(2, "0");
  const isAMOrPM = hours >= 12 ? "PM" : "AM";
  const formattedHours = (hours % 12 || 12).toString().padStart(2, "0");
  return {
    date: `${month}/${day}/${year}`,
    time: `${formattedHours}:${minutes} ${isAMOrPM}`,
  };
};

const MobileHeader = (params) => {
  const sortLabel = store?.getState()?.library?.mobileSortLabel;
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        height: "56px",
      }}
    >
      <Typography
        sx={{
          fontSize:
            params?.isMobile === true || params?.isMobileLandscape === true
              ? "13px"
              : "16px",
          lineHeight:
            params?.isMobile === true || params?.isMobileLandscape === true
              ? "17.7px"
              : "21.79px",
          width: "70px",
        }}
      >
        {params?.isMobile === true || params?.isMobileLandscape === true
          ? sortLabel
          : intlMessages.libraryQuizTitle}
      </Typography>
      {(params?.isMobile === true || params?.isMobileLandscape === true) && (
        <SortMenu
          isMobile={params?.isMobile === true ? true : false}
          isMobileLandscape={params?.isMobileLandscape === true ? true : false}
        />
      )}
    </Box>
  );
};

const TitleCell = (params) => {
  return (
    <Styled.styledTableRow>
      <Typography sx={{ fontSize: "16px", lineHeight: "21.79px" }}>
        {params?.row?.title}
      </Typography>
      <Typography sx={{ fontSize: "12px", lineHeight: "16.34px" }}>
        {intlMessages.libraryTableQuestionsCount.replace(
          "#",
          params?.row?.numberOfQuizes
        )}
      </Typography>
      <Box
        sx={{
          display:
            params?.isMobile === true || params?.isMobileLandscape === true
              ? "flex"
              : "none",
          marginTop: "5px",
          flexDirection: "column",
        }}
      >
        <Typography
          sx={{
            fontSize: "12px",
            lineHeight: "16.34px",
            color: "#333333",
            marginRight: "5px",
          }}
        >
          {(params?.row?.questionType === "MCQ"
            ? intlMessages.libraryMCQLabel
            : intlMessages.libraryMCQLabel) +
            "," +
            (params?.row?.difficultyLevel?.charAt(0).toUpperCase() +
              params?.row?.difficultyLevel?.slice(1).toLowerCase()) +
            "," +
            params?.row?.noOfQuestions}
        </Typography>
        <Box
          sx={{
            display: params?.isMobileLandscape === true ? "none" : "flex",
            flexDirection: "row",
            mt: "5px",
          }}
        >
          <Typography
            sx={{
              fontSize: "12px",
              lineHeight: "16.34px",
              color: "#333333B2",
              marginRight: "5px",
            }}
          >
            {getDateAndTime(params?.row.createdAt)?.date}
          </Typography>
          <Typography
            sx={{ fontSize: "12px", lineHeight: "16.34px", color: "#333333B2" }}
          >
            {getDateAndTime(params?.row.createdAt)?.time}
          </Typography>
        </Box>
      </Box>
    </Styled.styledTableRow>
  );
};

const SettingCell = (params) => {
  return (
    <Styled.styledTableRow>
      <Typography
        sx={{
          fontSize: "12px",
          lineHeight: "16.34px",
          fontWeight: 400,
          opacity: 0.9,
        }}
      >
        {(params?.row?.questionType === "MCQ"
          ? intlMessages.libraryMCQLabel
          : params?.row?.questionType === "TrueFalse"
          ? intlMessages.quizTrueFalseType
          : intlMessages.quizFillBlankType) + ","}
      </Typography>
      <Typography
        sx={{ fontSize: "12px", lineHeight: "16.34px", opacity: 0.9 }}
      >
        {params?.row?.difficultyLevel?.charAt(0).toUpperCase() +
          params?.row?.difficultyLevel?.slice(1).toLowerCase() +
          ", " +
          params?.row?.noOfQuestions}
      </Typography>
    </Styled.styledTableRow>
  );
};

const DateCell = (params) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        justifyContent:
          params?.isMobile || params?.isMobileLandscape === true
            ? "flex-end"
            : "space-between",
        alignItems: "center",
        width: "100%",
        marginRight:
          params?.isMobile === true || params?.isMobileLandscape === true
            ? "10px"
            : "unset",
      }}
    >
      {(params?.isMobile === true || params?.isMobileLandscape === true) && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-end",
          }}
        >
          <DropDownMenu
            row={params?.row}
            editQuiz={(id) => handleEditQuiz(id)}
            deleteQuiz={(id) => handleDeleteQuiz(id)}
            shareQuiz={(id) => handleShareQuiz(id)}
          />
          <Box
            sx={{
              display: params?.isMobileLandscape === true ? "flex" : "none",
              flexDirection: "row",
              mt: "15%",
            }}
          >
            <Typography
              sx={{
                fontSize: "12px",
                lineHeight: "16.34px",
                color: "#333333B2",
                marginRight: "5px",
              }}
            >
              {getDateAndTime(params?.row.createdAt)?.date}
            </Typography>
            <Typography
              sx={{
                fontSize: "12px",
                lineHeight: "16.34px",
                color: "#333333B2",
              }}
            >
              {getDateAndTime(params?.row.createdAt)?.time}
            </Typography>
          </Box>
        </Box>
      )}
      <Styled.styledTableRow
        sx={{
          display:
            params?.isMobile === true || params?.isMobileLandscape === true
              ? "none"
              : "block",
        }}
      >
        <Typography sx={{ fontSize: "16px", lineHeight: "21.79px" }}>
          {getDateAndTime(params?.row.createdAt)?.date}
        </Typography>
        <Typography sx={{ fontSize: "12px", lineHeight: "16.34px" }}>
          {getDateAndTime(params?.row.createdAt)?.time}
        </Typography>
      </Styled.styledTableRow>
      <Styled.actionBox
        sx={{
          display:
            params?.isMobile === true || params?.isMobileLandscape === true
              ? "none"
              : "block",
        }}
      >
        <Styled.actionBtn
          src={
            store.getState().feature.canEditQuiz.enable === false
              ? "/svgs/edit-icon-crown.svg"
              : "/svgs/edit-icon.svg"
          }
          sx={{
            cursor:
              store.getState().feature.canEditQuiz.enable === true
                ? "pointer"
                : "unset",
          }}
          onClick={() => {
            logEvent(
              Categories.quiz_library,
              Events.quiz_library_edit_button_clicked
            );
            if (store.getState().feature.canEditQuiz.enable === true)
              handleEditQuiz(params?.row?._id);
            else {
              store.dispatch(
                setUpgradeModalText({
                  heading: intlMessages.upgradeModelHeading,
                  desc: intlMessages.upgradeModelDescText,
                })
              );
              store.dispatch(setOpenUpgradeModel(true));
            }
          }}
          onMouseOver={(e) => {
            store.getState().feature.canEditQuiz.enable === true &&
              (e.currentTarget.src = "/svgs/edit-icon-green.svg");
          }}
          onMouseOut={(e) => {
            store.getState().feature.canEditQuiz.enable === true &&
              (e.currentTarget.src = "/svgs/edit-icon.svg");
          }}
        />
        <Styled.actionBtn
          src="/svgs/delete-icon.svg"
          onMouseOver={(e) =>
            (e.currentTarget.src = "/svgs/delete-icon-green.svg")
          }
          onMouseOut={(e) => (e.currentTarget.src = "/svgs/delete-icon.svg")}
          onClick={() => {
            logEvent(
              Categories.quiz_library,
              Events.quiz_library_delete_button_clicked
            );
            handleDeleteQuiz(params?.row?._id);
          }}
        />
        <Styled.actionBtn
          src="/svgs/share-icon.svg"
          onClick={() => {
            logEvent(
              Categories.quiz_library,
              Events.quiz_library_share_button_clicked
            );
            handleShareQuiz(params?.row?._id);
          }}
          onMouseOver={(e) =>
            (e.currentTarget.src = "/svgs/share-icon-green.svg")
          }
          onMouseOut={(e) => (e.currentTarget.src = "/svgs/share-icon.svg")}
        />
      </Styled.actionBox>
    </Box>
  );
};
const getDatagridColumns = (isMobile, isMobileLandscape) => {
  const columns = [
    {
      field: "title",
      headerName: intlMessages.libraryQuizTitle,
      editable: false,
      flex: isMobile ? 5 : isMobileLandscape ? 3 : 1,
      sortable: isMobile || isMobileLandscape ? false : true,
      renderHeader: (params) => {
        const parameters = { isMobile, isMobileLandscape, ...params };
        return <MobileHeader {...parameters} />;
      },
      renderCell: (params) => {
        const parameters = { isMobile, isMobileLandscape, ...params };
        return <TitleCell {...parameters} />;
      },
    },

    {
      field: "quizSettings",
      headerName:
        isMobile || isMobileLandscape
          ? ""
          : intlMessages.libraryQuizSettingLabel,
      editable: false,
      flex: isMobile || isMobileLandscape ? 0.1 : 0.5,
      sortable: false,
      renderCell: (params) => {
        const parameters = { isMobile, isMobileLandscape, ...params };
        return isMobile || isMobileLandscape ? null : (
          <SettingCell {...parameters} />
        );
      },
    },
    {
      field: "createdAt",
      headerName:
        isMobile || isMobileLandscape ? "" : intlMessages.libraryCreatedAtLabel,
      editable: false,
      flex: isMobile ? 0.5 : isMobileLandscape ? 3 : 1.5,
      sortable: isMobile || isMobileLandscape ? false : true,
      renderCell: (params) => {
        const parameters = { isMobile, isMobileLandscape, ...params };
        return <DateCell {...parameters} />;
      },
    },
  ];
  return columns;
};

const deleteSelectedQuiz = async (id) => {
  const response = await deleteQuiz(id);
  return response;
};

const handleEditQuiz = async (id) => {
  const response = await getQuiz(id);
  if (
    store.getState().quiz.isQuizLoaded === true &&
    store.getState().quiz.quizGenerationCurrentState === 1
  ) {
    store.dispatch(setQuizId(id));
    store.dispatch(setOpenDeleteBox(true));
    store.dispatch(
      setLibraryWarning({
        message: intlMessages.quizGenerationWarning,
        icon: "/svgs/warning.svg",
        isError: null,
        hasEditFlag: true,
      })
    );
    store.dispatch(setIsQuizLoaded(true));
    store.dispatch(setQuizGenerationCurrentState(-1));
  } else {
    const controller = ABORT_CONTROLLER_MAP.get("quizGenerationSignal");
    if (controller) {
      controller.abort();
      ABORT_CONTROLLER_MAP.delete("quizGenerationSignal");
    }
    store.dispatch(
      setQuizData({
        questionAndAnswers: response?.data?.ques_ans_pairs,
        quiz_hash: response?.data?.hash,
        quiz_id: response?.data?.quizId,
      })
    );
    let selectedQuestions = 0;
    response?.data?.ques_ans_pairs.forEach((element) => {
      if (element.isChecked) {
        selectedQuestions++;
      }
    });
    const questionType =
      response?.data?.questionType === "MCQ"
        ? intlMessages.libraryMCQLabel
        : response?.data?.questionType === "TrueFalse"
        ? intlMessages.quizTrueFalseType
        : intlMessages.quizFillBlankType;
    const difficultyLevel =
      response?.data?.difficultyLevel?.charAt(0).toUpperCase() +
      response?.data?.difficultyLevel?.slice(1).toLowerCase();
    const questionCount = response?.data?.noOfQuestions;
    store.dispatch(setQuizInputText(response?.data?.text));
    store.dispatch(setShowQuizContentPage(true));
    // store.dispatch(setquizLandscapeBackBtnClicked(true));
    store.dispatch(setQuizTitle(response?.data?.title));
    store.dispatch(setEnableEditMode(true));
    store.dispatch(setQuizId(response?.data?._id));
    store.dispatch(setIsQuizLoaded(false));
    store.dispatch(setOpenDeleteBox(false));
    store.dispatch(setOpenLibrary(false));
    store.dispatch(setNavigateToQuizPage(true));
    store.dispatch(
      setAllQuestionsSelected(
        response?.data?.ques_ans_pairs.length === selectedQuestions
      )
    );
    store.dispatch(setQuizType(questionType));
    store.dispatch(setQuizDifficultyLevel(difficultyLevel));
    store.dispatch(setLibraryWarning(null));
    store.dispatch(setQuizCount(questionCount));
    store.dispatch(setIsDisableQuizSettings(false));
    const createBtn = document.getElementById("create-btn");
    if (!!createBtn) {
      createBtn.style.color = "white";
      createBtn.style.backgroundColor = "#7DC242";
    }
  }
};

const handleDeleteQuiz = (id) => {
  store.dispatch(setQuizId(id));
  store.dispatch(setOpenDeleteBox(true));
  store.dispatch(setEnableEditMode(false));
  store.dispatch(setQuizTitle(""));
  store.dispatch(
    setLibraryWarning({
      message: intlMessages.quizLibraryDeleteText,
      icon: "/svgs/erroricon.svg",
      isError: true,
    })
  );
};

const handleShareQuiz = async (id) => {
  const response = await getQuiz(id);
  if (
    store.getState().quiz.isQuizLoaded === true &&
    store.getState().quiz.quizGenerationCurrentState === 1
  ) {
    store.dispatch(setQuizId(id));
    store.dispatch(setOpenDeleteBox(true));
    store.dispatch(
      setLibraryWarning({
        message: intlMessages.quizGenerationWarning,
        icon: "/svgs/warning.svg",
        isError: null,
        hasShareFlag: true,
      })
    );
    store.dispatch(setIsQuizLoaded(true));
    store.dispatch(setQuizGenerationCurrentState(-1));
  } else {
    const controller = ABORT_CONTROLLER_MAP.get("quizGenerationSignal");
    if (controller) {
      controller.abort();
      ABORT_CONTROLLER_MAP.delete("quizGenerationSignal");
    }
    store.dispatch(
      setQuizData({
        questionAndAnswers: response?.data?.ques_ans_pairs,
        quiz_hash: response?.data?.hash,
        quiz_id: response?.data?.quizId,
      })
    );
    let selectedQuestions = 0;
    response?.data?.ques_ans_pairs.forEach((element) => {
      if (element.isChecked) {
        selectedQuestions++;
      }
    });
    const questionType =
      response?.data?.questionType === "MCQ"
        ? intlMessages.libraryMCQLabel
        : response?.data?.questionType === "TrueFalse"
        ? intlMessages.quizTrueFalseType
        : intlMessages.quizFillBlankType;
    const difficultyLevel =
      response?.data?.difficultyLevel?.charAt(0).toUpperCase() +
      response?.data?.difficultyLevel?.slice(1).toLowerCase();
    const questionCount = response?.data?.noOfQuestions;
    store.dispatch(setQuizInputText(response?.data?.text));
    store.dispatch(setShowQuizContentPage(true));
    store.dispatch(setEnableEditMode(false));
    // store.dispatch(setquizLandscapeBackBtnClicked(true));
    store.dispatch(setQuizTitle(response?.data?.title));
    store.dispatch(setQuizId(response?.data?._id));
    store.dispatch(setIsQuizLoaded(false));
    store.dispatch(setLibraryWarning(null));
    store.dispatch(setOpenDeleteBox(false));
    store.dispatch(setOpenLibrary(false));
    store.dispatch(setOpenExportMenu(true));
    store.dispatch(setNavigateToQuizPage(true));
    store.dispatch(setEnableShareMode(true));
    store.dispatch(
      setAllQuestionsSelected(
        response?.data?.ques_ans_pairs.length === selectedQuestions
      )
    );
    store.dispatch(setQuizType(questionType));
    store.dispatch(setQuizDifficultyLevel(difficultyLevel));
    store.dispatch(setQuizCount(questionCount));
    if(store.getState().logIn.userData?.subscriptionPlan==="Basic"){
      store.dispatch(setIsDisableQuizSettings(true));
    }
    const createBtn = document.getElementById("create-btn");
    if (!!createBtn) {
      createBtn.style.color = "white";
      createBtn.style.backgroundColor = "#7DC242";
    }
  }
};

export {
  getDateAndTime,
  getDatagridColumns,
  deleteSelectedQuiz,
  handleEditQuiz,
  handleShareQuiz,
};
