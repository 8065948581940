import styled from "@mui/system/styled";
import Box from "@mui/material/Box";

const ModalHeader = styled(Box)({
  padding: 8,
  paddingRight: 12,
  boxShadow: "rgba(100, 100, 111, 0.2) 0px 3px 29px 0px",
  paddingLeft: 25,
  alignItems: "center",
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
});

const ModalActions = styled(Box)({
  padding: 5,
  paddingTop: 15,
  paddingBottom: 15,
  borderTop: "1px solid silver",
  display: "flex",
  justifyContent: "center",
});

const ModalBox = styled(Box)(({ theme }) => ({
  width: "30%",
  [theme.breakpoints.down("lg")]: {
    width: "60%",
  },
  [theme.breakpoints.down("sm")]: {
    width: "95%",
  },
}));

const ModalContentBox = styled(Box)({
  marginTop: 30,
  marginLeft: 25,
  marginRight: 25,
  marginBottom: 30,
});


const customStyling = {
    exportModal: {
      position: "absolute",
      top: { xs: "45%", sm: "50%" },
      left: "50%",
      transform: "translate(-50%, -50%)",
      bgcolor: "background.paper",
      borderRadius: 2,
      boxShadow: 24,
      overflowY: "auto",
      maxHeight: "90%",
      border: "none",
    },
  };

export default {
  customStyling,
  ModalHeader,
  ModalActions,
  ModalBox,
  ModalContentBox,
};
