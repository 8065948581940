import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Styled from "./styles";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import intlMessages from "../../locales/en";
import { Box, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { getNavigateURL } from "../../util/signin-user-data";
import { setOpenLibrary } from "../../redux/slices/librarySlice";
import { useDispatch } from "react-redux";
import { Categories, Events, logEvent } from "../../analytics";
import { setOpenPricingModal } from "../../redux/slices/featuresSlice";

function AlertBox(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    message,
    isOpen,
    onClose,
    loginBtn,
    isMobile,
    isMobileLandscape,
    isIpad,
    isIpadLandscape,
    libraryBtn,
    deleteBtn,
    handleDeleteBtnOnClick,
    showDifferentChooser,
    backToMainMenu,
    editFlag,
    shareFlag,
  } = props;
  const handleClose = () => {
    if (loginBtn) {
      const loginURL = getNavigateURL("/login");
      navigate(loginURL);
    }
    if (deleteBtn) {
      logEvent(
        Categories.quiz_library,
        Events.quiz_library_delete_modal_cancel_button_clicked
      );
    } else {
      logEvent(Categories.alert_modal, Events.alert_modal_close_btn_clicked);
    }
    onClose();
  };

  const handleLibraryOnClick = () => {
    logEvent(Categories.quiz_library, Events.quiz_library_button_clicked);
    dispatch(setOpenLibrary(true));
    onClose();
  };

  const handleDeleteBtnClick = () => {
    logEvent(
      Categories.quiz_library,
      Events.quiz_library_delete_modal_confirm_button_clicked
    );
    handleDeleteBtnOnClick();
  };
  return (
    <>
      <Dialog open={isOpen}>
        <DialogTitle>
          <Box
            sx={{
              display: loginBtn ? "none" : "flex",
              justifyContent: "flex-end",
            }}
          >
            <Styled.crossBtn onClick={handleClose}>
              <CloseOutlinedIcon />
            </Styled.crossBtn>
          </Box>
        </DialogTitle>
        <DialogContent>
          <Styled.icon src={message?.icon}></Styled.icon>
          <DialogContentText id="alert-dialog-description">
            <Styled.styledText
              isMobile={isMobile ? true : false}
              isMobileLandscape={isMobileLandscape ? true : false}
              isError={message?.isError}
            >
              {libraryBtn ? (
                <span>
                  {message?.message}
                  <span
                    onClick={handleLibraryOnClick}
                    style={{ color: "#2F95C7", cursor: "pointer" }}
                  >
                    {intlMessages.libraryTitle}
                  </span>
                </span>
              ) : message?.message && Array.isArray(message?.message) ? (
                <>
                  {message?.message[0]}
                  <span
                    onClick={() => {
                      handleClose();
                      dispatch(setOpenPricingModal(true));
                    }}
                    style={{
                      color: "#2062BB",
                      cursor: "pointer",
                      marginLeft: "3px",
                      marginRight: "0px",
                    }}
                  >
                    {message?.message[1]}
                  </span>
                  <span>{message?.message[2]}</span>
                </>
              ) : (
                <span>
                {message?.message}
                </span>
              )}
            </Styled.styledText>
          </DialogContentText>
        </DialogContent>
        <Styled.CustomDivider
          sx={{ display: showDifferentChooser ? "" : "none" }}
        ></Styled.CustomDivider>
        <Box
          sx={{
            display: showDifferentChooser ? "flex" : "none",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            opacity: 1,
            pointerEvents: "auto",
          }}
        >
          <Typography
            variant="body1"
            style={{
              fontSize: "16px",
              color: "#1A73E8",
              cursor: "pointer",
            }}
            onClick={backToMainMenu}
          >
            {intlMessages.importDriveModalAgainText}
          </Typography>
        </Box>
        <Styled.divider
          sx={{ display: showDifferentChooser ? "none" : "" }}
        ></Styled.divider>
        <DialogActions
          sx={{
            height: "60px",
            display: "flex",
            justifyContent:
              deleteBtn || editFlag || shareFlag ? "center" : "unset",
          }}
        >
          {(deleteBtn || editFlag || shareFlag) && (
            <Styled.confirmBtn
              deleteBtn={deleteBtn || editFlag || shareFlag ? true : false}
              isMobile={isMobile ? true : false}
              isMobileLandscape={isMobileLandscape ? true : false}
              onClick={handleDeleteBtnClick}
            >
              {editFlag || shareFlag
                ? intlMessages.yesLabel
                : intlMessages.deleteAccountConfirmBtnText}
            </Styled.confirmBtn>
          )}
          <Styled.closeBtn
            deleteBtn={deleteBtn || editFlag || shareFlag ? true : false}
            isMobile={isMobile ? true : false}
            isMobileLandscape={isMobileLandscape ? true : false}
            onClick={handleClose}
          >
            {editFlag || shareFlag
              ? intlMessages.noLabel
              : loginBtn
              ? intlMessages.loginLabel
              : deleteBtn
              ? intlMessages.deleteAccountCancelBtnText
              : intlMessages.closeLabel}
          </Styled.closeBtn>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default AlertBox;
