
import api from "../../api";
const startTrial = async (email) => {
  try {
    const payload = {
      email
    };
  const res = await api.post(`/user/start/trial`, payload);
    return res;
} catch (e) {
    return e;
}
}
export { startTrial };