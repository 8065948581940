import styled from "@mui/system/styled";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { Typography } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import Radio from "@mui/material/Radio"
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

const ModalHeader = styled(Box)({
  padding: 8,
  paddingRight: 12,
  boxShadow: "rgba(100, 100, 111, 0.2) 0px 3px 29px 0px",
  paddingLeft: 25,
  alignItems: "center",
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
});

const ModalActions = styled(Box)({
  padding: 5,
  paddingTop: 15,
  paddingBottom: 15,
  borderTop: "1px solid silver",
  display: "flex",
  justifyContent: "center",
});

const ModalTextField = styled(TextField)(({ theme }) => ({
  borderRadius: 3,
  // padding:5,

  ".MuiOutlinedInput-input": {
    padding: 7,
  },
  width: "100%",
  [theme.breakpoints.down("md")]: {},
}));

const ModalTextArea = styled(TextField)(({ theme }) => ({
    borderRadius: 3,
    // padding:5,
    "&::placeholder": {
      color: "gray"
    },
    ".MuiOutlinedInput-root": {
      padding: '0px 5px',
    },
    width: "100%",
    [theme.breakpoints.down("md")]: {},
  }));

const ModalBox = styled(Box)(({ theme }) => ({
  width: "33%",
  [theme.breakpoints.down("lg")]: {
    width: "60%",
  },
  [theme.breakpoints.down("sm")]: {
    width: "95%",
  },
}));

const ModalContentBox = styled(Box)({
  marginTop: 22,
  marginLeft: 25,
  marginRight: 25,
  marginBottom: 30,
});

const CreateQuizButton = styled(LoadingButton)(({ theme, isquizloading }) => ({
    color: theme.palette.primary.contrastText,
    textTransform: "none",
    paddingLeft: isquizloading ? 35 : 15,
    [`${theme.breakpoints.down("md")}  and (orientation: landscape) and (hover: none) and (pointer: coarse)`]:{
      paddingRight: isquizloading && 0,
    },
    '.MuiLoadingButton-loadingIndicator':{
        left:"30px"
    }
}));

const ErrorText = styled(Typography)(({ theme }) => ({
    color: theme.palette.warning.main,
    fontSize: 12,
    padding: 2,
    display: "flex",
    textAlign: "flex-start",
  }));

const customStyling = {
  exportModal: {
    position: "absolute",
    top: {xs:'45%' ,sm:'50%'},
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    borderRadius: 2,
    boxShadow: 24,
    overflowY: "auto",
    maxHeight: "90%",
    border: "none",
  },
  // inputNumberField: {
  //   width: "72px",
  //   height: "23px",
  //   // border: "1px solid #0000004D !important",
  //   top: "7px",
  //   borderRadius: "0px !important",
  //   "&:hover": {
  //     color: '#0000004D'
  //   },
  //   '& .MuiOutlinedInput-root:hover': {
  //     borderColor: "#0000004D",
  //     border: "none"
  //   },
  //   '& .MuiOutlinedInput-root': {
  //     height: "21px !important",
  //     borderRadius: "0 !important",
  //     "&.Mui-focused fieldset": {
  //       borderColor: "#0000004D"
  //     }
  //   },
  // }
  // inputNumberField: {
  //   width: "72px",
  //   height: "23px",
  //   top: "7px",
  //   borderRadius: "0px !important",
  //   '& .MuiOutlinedInput-root:hover': {
  //     borderColor: "#0000004D !important",
  //     borderWidth: "1px",
  //   },
  //   '& .MuiOutlinedInput-root': {
  //     height: "21px !important",
  //     borderRadius: "0 !important",
  //     "&.Mui-focused fieldset": {
  //       borderColor: "#0000004D !important",
  //       borderWidth: "1px",
  //     }
  //   },
  // }
  inputNumberField: {
    width: "72px",
    height: "27px",
    top: "4px",
    borderRadius: "0px !important",
    '& .MuiOutlinedInput-root:hover fieldset': {
      borderColor: "#0000004D !important",
      borderWidth: "1px",
    },
    '& .MuiOutlinedInput-root': {
      height: "27px !important",
      borderRadius: "0 !important",
      '&.Mui-focused': {
        '& fieldset': {
          borderColor: "#0000004D !important",
          borderWidth: "1px",
        }
      }
    },
    '& .MuiOutlinedInput-input': {
      padding: "0px 0px 0px 4px",
  },
}
};

const CheckBoxBtn = styled(Checkbox)(({ theme }) => ({
  color: theme.palette.secondary.main,
  padding: "2px 9px",
    '&.Mui-checked': {
      color: theme.palette.secondary.main,
    },
    '& .MuiCheckbox-root': {
      padding: "2px 9px"
    },
}));

const RadioBtn = styled(Radio)(({ theme }) => ({
    color: theme.palette.secondary.main,
      '&.Mui-checked': {
        color: theme.palette.secondary.main,
      },
  }));

const StyledFormControlLabel = styled(FormControlLabel)(({ theme }) => ({
  root: {
    fontSize: '0.813rem',
  },
  '& .MuiTypography-root': {
    fontSize: '0.813rem',
  },
}));

const typographyText = styled(Typography)(({ theme }) => ({
  marginTop: "2px",
  marginLeft: "5px",
  width: "40%",
  fontSize: "11px",
  color: "#333333B2",
  fontWeight: "400",
  lineHeight: "14.98px",
  [theme.breakpoints.down("sm")]: {
    width: "47%",
    marginLeft: "109px"
  },
}));


export default {
  CreateQuizButton,
  ModalHeader,
  ModalActions,
  ModalTextField,
  ModalBox,
  ModalContentBox,
  customStyling,
  ErrorText,
  StyledFormControlLabel ,
  typographyText,
  CheckBoxBtn,
  RadioBtn,
  ModalTextArea,
};
