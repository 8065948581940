const breakpoints = {
  values: {
    xs: 0,
    xxsm:420,
    xsm:460,
    sm: 620,
    md: 980,
    xmd: 800,
    lg: 1100,
    xl: 1200,
    xxl: 1400,
    xxxl:1920,
    tl6:1280,
  },
};

export default breakpoints;
