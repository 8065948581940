import React, { useState, useEffect } from "react";
import { useTheme } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import { useDispatch, useSelector } from "react-redux";
import Footer from "../footer/component";
import intlMessages from "../../locales/en";
import sliderImages from "../welcome-modal/slider";
import Styled from "./styles";
import { InputAdornment, useMediaQuery } from "@mui/material";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import { validateEmail, validateInput } from "../sign-up/service";
import { logIn } from "./service";
import ConnectionModal from "../connection-modal/component";
import { getUserInformation, setToken } from "../../common/sign_in_service";
import CreateAndGetTokenminiWindow from "../../util/mini_window_handler";
import { decodeToken } from "../../common/sign_in_service";

import {
  setAuthToken,
  setIsSignedIn,
  setUserData,
  setUserState,
} from "../../redux/slices/logInSlice";
import {
  GOOGLE_LOGIN_URL,
  MICROSOFT_LOGIN_URL,
  USER_STATE,
} from "../../settings";
import { Snackbar } from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import {
  checkUrlIsGoogleForm,
  getNavigateURL,
} from "../../util/signin-user-data";
import AlertBox from "../alert/component";
import CircularProgress from "@mui/material/CircularProgress";
import { Categories, Events, logEvent } from "../../analytics";
import { updateUserData } from "../user/service";
import { getAuthToken } from "../../api/service";
import { initAdds } from "../../adds_conversion";

function LogIn() {
  const { microsoftEnabled, googleEnabled } = useSelector(
    (state) => state.quiz
  );
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"), {
    noSsr: true,
  });
  const isMobileLandscape = useMediaQuery(
    `${theme.breakpoints.down(
      "md"
    )}  and (orientation: landscape) and (hover: none) and (pointer: coarse)`,
    {
      noSsr: true,
    }
  );

  const isIpad = useMediaQuery(
    window.navigator.userAgent.includes("OneScreen_TL7")
      ? "(min-width:620px) and (max-width:960px) and (orientation: landscape) "
      : "(min-width:620px) and (max-width:840px) and (orientation: portrait) and  (hover: none) and (pointer: coarse)",
    {
      noSsr: true,
    }
  );
  const isIpadLandscape = useMediaQuery(
    "(min-width: 800px) and (max-width: 1400px) and (orientation: landscape) and  (hover: none) and (pointer: coarse)",
    {
      noSsr: true,
    }
  );
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showpassword, setShowPassword] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [openConnectionModal, setIsOpenConnectionModal] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [error, setError] = useState({
    email: "",
    password: "",
  });
  const [snackBarstate, setSnackBar] = useState({
    openSnackBar: false,
    vertical: "top",
    horizontal: "right",
    type: "warning",
    message: "",
    title: null,
    timeout: 3000,
  });
  const { vertical, horizontal, openSnackBar, title, type, message } =
    snackBarstate;
  const [openAlertBox, setOpenAlertBox] = useState(false);
  const alertMsg = {
    message: intlMessages.accountExpiredToken,
    icon: "/svgs/erroricon.svg",
    isError: true,
  };

  const [isGoogleForm, setIsGoogleForm] = useState(false);
  const { authToken } = useSelector((state) => state.logIn);

  useEffect(() => {
    setIsGoogleForm(checkUrlIsGoogleForm());
  }, []);

  useEffect(() => {
    if (getAuthToken() !== null) {
      const quizUrl = getNavigateURL("/");
      navigate(quizUrl);
    }
  }, [authToken]);

  const handleEmail = (e) => {
    const inp = validateInput(e.target.value);
    if (inp.length > 0) {
      setError({
        ...error,
        email: null,
        password: null,
      });
    }
    if (
      error.email === intlMessages.signUpEmailEmptyMessage ||
      validateEmail(inp)
    ) {
      setError({
        ...error,
        email: null,
        password: null,
      });
    }
    setEmail(inp);
  };

  const setUserDataState = async (token, stripeData) => {
    dispatch(setAuthToken(token));
    const decodedData = await decodeToken(token);
    decodedData.stripeData = stripeData;
    dispatch(setUserData(decodedData));
    dispatch(setIsSignedIn(true));
    dispatch(setUserState(USER_STATE.SIGNED_IN_USER));
  };

  const handlePassword = (e) => {
    const inp = validateInput(e.target.value);
    if (inp.length > 0) {
      setError({
        ...error,
        password: null,
      });
    }
    if (error.password === intlMessages.signUpPasswordEmptyMessage) {
      setError({
        ...error,
        password: null,
      });
    }
    setPassword(inp);
  };

  const passedInputValidations = () => {
    if (email.length === 0 && password.length === 0) {
      return setError({
        email: null,
        password: intlMessages.SignUpEmptyFieldsError,
      });
    }
    if (email.length == 0) {
      return setError({
        ...error,
        email: intlMessages.signUpEmailEmptyMessage,
        password: null,
      });
    }
    if (!validateEmail(email)) {
      return setError({
        ...error,
        email: intlMessages.SignUpInvalidEmailError,
        password: null,
      });
    }
    if (password.length == 0) {
      return setError({
        ...error,
        password: intlMessages.signUpPasswordEmptyMessage,
      });
    }
    return true;
  };

  const handleFormSubmition = () => {
    if (passedInputValidations()) {
      setIsSaving(true);
      logEvent(
        Categories.user_management,
        Events.user_management_login_Clicked
      );
      logIn(email, password)
        .then(async (res) => {
          updateUserData({
            email: email,
            rating: null,
          });
          if (res?.data?.statusCode === 0) {
            setIsSaving(false);
            setIsOpenConnectionModal(true);
            return;
          }
          if (
            res?.response?.data?.statusCode === 401 &&
            res?.response?.data?.message === "Unauthorized"
          ) {
            setIsSaving(false);
            return setError({
              ...error,
              password: intlMessages.loginInvalidCredentialsError,
            });
          }
          if (res?.status === 201) { //&& res?.statusText == "Created"
            setIsSaving(false);
            setToken(res?.data?.access_token);
            setUserDataState(res?.data?.access_token, res?.data.stripeData);
            const userData = await getUserInformation();
            // if (userData && userData?.response?.data?.statusCode === 412 && userData?.response?.data?.message === "Your account is not verified.") {
            //   dispatch(setUserState(USER_STATE.NOT_VERIFIED_USER))
            // }
            // if (userData && userData?.response?.data?.statusCode === 403 && userData?.response?.data?.message === "Your account is blocked.") {
            //   dispatch(setUserState(USER_STATE.BLOCKED_USER_USER))
            // }
            // if (userData && userData?.response?.data?.statusCode === 405 && userData?.response?.data?.message === "Your account has expired.") {
            //   dispatch(setUserState(USER_STATE.EXPIRED_USER))
            // }
            if (
              userData &&
              userData?.response?.data?.statusCode === 401 &&
              userData?.response?.data?.message === "Unauthorized"
            ) {
              setOpenAlertBox(true);
            }
            if (userData && userData?.status === 200) {
              const quizUrl = getNavigateURL("/");
              navigate(quizUrl);
              dispatch(setAuthToken(res?.data?.access_token));
              initAdds();
            }
          }
        })
        .catch(() => {
          setIsSaving(false);
        });
    }
  };

  const openSSOLogin = async (login_url, eventType) => {
    logEvent(Categories.user_management, eventType);
    await CreateAndGetTokenminiWindow(login_url)
      .then(async (tokenData) => {
        setUserDataState(tokenData.token);
        const decodedData = await decodeToken(tokenData.token);
        const data = {email:decodedData.username,rating:null}
        localStorage.setItem("userData",JSON.stringify(data))
        const url = getNavigateURL("/", tokenData.isFirstTimeUser);
        initAdds();
        navigate(`${url}`);
        // dispatch(setAuthToken(token));
        // const decodedData = await decodeToken(token);
        // dispatch(setUserData(decodedData));
        // dispatch(setIsSignedIn(true));
        // navigate('/verify')
      })
      .catch((error) => {
        if (error === "allowpopup") {
          showWarningMessage(
            intlMessages.popUpError,
            intlMessages.popUpErrorTitle
          );
          return;
        }
        if (error === "windowError") {
          showWarningMessage(
            intlMessages.googleFormsUnauthorizedError,
            intlMessages.googleFormsUnauthorizedTitie
          );
          return;
        }
        // // console.log(error);
      });
  };

  const navigateToSignUp = () => {
    logEvent(
      Categories.user_management,
      Events.user_management_signup_click_login_page
    );
    const signupUrl = getNavigateURL("/signup");
    // // console.log(signupUrl);
    navigate(signupUrl);
  };

  const navigateToForgotPassword = () => {
    const forgotpasswordUrl = getNavigateURL("/forgotpassword");
    logEvent(
      Categories.user_management,
      Events.user_management_forgot_password
    );
    // // console.log(forgotpasswordUrl);
    navigate(forgotpasswordUrl);
  };

  const handleCloseSnackBar = () => {
    setSnackBar({ ...snackBarstate, openSnackBar: false });
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      handleFormSubmition();
    }
  };

  const showWarningMessage = (msgText, title = null) => {
    return setSnackBar({
      ...snackBarstate,
      type: "warning",
      message: msgText,
      openSnackBar: true,
      title: title,
      timeout: 7000,
    });
  };

  // const handleFocus = () => {
  //   // console.log("focused");
  //   setIsFocused(true);
  // };

  // const handleBlur = () => {
  //   // console.log("blurred");
  //   setIsFocused(false);
  // };

  const renderSnackBar = () => {
    return (
      <Snackbar
        open={openSnackBar}
        anchorOrigin={{ vertical, horizontal }}
        autoHideDuration={snackBarstate.timeout}
        onClose={handleCloseSnackBar}
        key={vertical + horizontal}
      >
        <MuiAlert
          elevation={6}
          variant="filled"
          sx={{ color: "white" }}
          onClose={handleCloseSnackBar}
          severity={snackBarstate.type}
        >
          {snackBarstate.message}
        </MuiAlert>
      </Snackbar>
    );
  };

  return (
    <>
      {renderSnackBar()}
      <Box
        sx={{
          background: "linear-gradient(90deg, #4E0F89 0%, #7D00BA 100%)",
        }}
      >
        <Styled.MainContainer>
          <Styled.WelcomeTextBox
            isMobile={isMobile ? true : false}
            isMobileLandscape={isMobileLandscape ? true : false}
          >
            <Styled.WelcomeText
              sx={{
                fontSize: isMobile || isMobileLandscape ? "24px" : "32px",
                fontWeight: "700",
                lineHeight: "32px",
                mb: "5px",
              }}
            >
              {intlMessages.signUpWelcomeText1}
            </Styled.WelcomeText>
            <Styled.WelcomeText
              sx={{
                pl: isMobile ? "5%" : "unset",
                pr: isMobile ? "5%" : "unset",
              }}
              isMobile={isMobile ? true : false}
              isMobileLandscape={isMobileLandscape ? true : false}
            >
              {intlMessages.signUpWelcomeText2}
            </Styled.WelcomeText>
          </Styled.WelcomeTextBox>
          <Styled.imageAndFormContainer
            isMobile={isMobile ? true : false}
            isMobileLandscape={isMobileLandscape ? true : false}
            isIpad={isIpad ? true : false}
            isIpadLandscape={isIpadLandscape ? true : false}
          >
            <Styled.imageContainer
              isMobile={isMobile ? true : false}
              isMobileLandscape={isMobileLandscape ? true : false}
              isIpad={isIpad ? true : false}
              isIpadLandscape={isIpadLandscape ? true : false}
            >
              <Styled.image
                isMobile={isMobile ? true : false}
                isMobileLandscape={isMobileLandscape ? true : false}
                isIpad={isIpad ? true : false}
                isIpadLandscape={isIpadLandscape ? true : false}
                src={sliderImages[3]}
              ></Styled.image>
              <Styled.imageText
                isMobile={isMobile ? true : false}
                isMobileLandscape={isMobileLandscape ? true : false}
              >
                {intlMessages.loginImageDesc}
              </Styled.imageText>
            </Styled.imageContainer>
            <Styled.formContainer>
              <Styled.trialText>{intlMessages.loginLabel}</Styled.trialText>
              <Styled.iconAndTextBox
                isMobile={isMobile ? true : false}
                isMobileLandscape={isMobileLandscape ? true : false}
                isIpad={isIpad ? true : false}
                isIpadLandscape={isIpadLandscape ? true : false}
                onClick={() =>
                  openSSOLogin(
                    GOOGLE_LOGIN_URL,
                    Events.user_management_login_google,
                    "local"
                  )
                }
                sx={{ display: googleEnabled ? "" : "none" }}
              >
                <Styled.icon
                  isMobile={isMobile ? true : false}
                  isMobileLandscape={isMobileLandscape ? true : false}
                  src={"/svgs/google-icon.svg"}
                ></Styled.icon>
                {intlMessages.signInGoogleText}
              </Styled.iconAndTextBox>
              <Styled.iconAndTextBox
                isMobile={isMobile ? true : false}
                isMobileLandscape={isMobileLandscape ? true : false}
                isIpad={isIpad ? true : false}
                isIpadLandscape={isIpadLandscape ? true : false}
                sx={{ mb: "22px", display: microsoftEnabled ? "" : "none" }}
                onClick={() =>
                  openSSOLogin(
                    MICROSOFT_LOGIN_URL,
                    Events.user_management_login_microsoft
                  )
                }
              >
                <Styled.icon
                  isMobile={isMobile ? true : false}
                  isMobileLandscape={isMobileLandscape ? true : false}
                  src={"/svgs/microsoft-icon.svg"}
                ></Styled.icon>
                {intlMessages.signInMicrosoftText}
              </Styled.iconAndTextBox>
              <Styled.divider
                isMobile={isMobile ? true : false}
                isMobileLandscape={isMobileLandscape ? true : false}
                isIpad={isIpad ? true : false}
                isIpadLandscape={isIpadLandscape ? true : false}
                orientation="horizontal"
                sx={{
                  display: microsoftEnabled && googleEnabled ? "" : "none",
                }}
              >
                {intlMessages.signUpOrText}
              </Styled.divider>
              <Styled.largeTextField
                isMobile={isMobile ? true : false}
                isMobileLandscape={isMobileLandscape ? true : false}
                isIpad={isIpad ? true : false}
                isIpadLandscape={isIpadLandscape ? true : false}
                helperText={error.email}
                error={
                  error.password === intlMessages.SignUpEmptyFieldsError ||
                  error.password === intlMessages.loginInvalidCredentialsError
                    ? true
                    : error.email
                    ? true
                    : false
                }
                onChange={handleEmail}
                required
                type="email"
                variant="outlined"
                isError={error.email ? true : false}
                placeholder={intlMessages.signUpEmailPlaceholder}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <img src="/svgs/email-icon.svg" alt="Icon" />
                    </InputAdornment>
                  ),
                }}
                // onFocus={handleFocus}
                // onBlur={handleBlur}
                onKeyDown={handleKeyDown}
              ></Styled.largeTextField>
              <Styled.largeTextField
                isMobile={isMobile ? true : false}
                isMobileLandscape={isMobileLandscape ? true : false}
                isIpad={isIpad ? true : false}
                isIpadLandscape={isIpadLandscape ? true : false}
                onChange={handlePassword}
                onKeyDown={handleKeyDown}
                helperText={error.password}
                isError={error.password ? true : false}
                error={error.password ? true : false}
                required
                type={showpassword ? "text" : "password"}
                variant="outlined"
                placeholder={intlMessages.signUpPasswordPlaceholder}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <img src="/svgs/password-icon.svg" alt="Icon" />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">
                      {showpassword ? (
                        <RemoveRedEyeOutlinedIcon
                          style={{ cursor: "pointer" }}
                          onClick={() => setShowPassword(false)}
                        />
                      ) : (
                        <img
                          style={{ cursor: "pointer" }}
                          onClick={() => setShowPassword(true)}
                          src="/svgs/eye-slash.svg"
                          alt="Icon"
                        />
                      )}
                    </InputAdornment>
                  ),
                }}
                // onFocus={handleFocus}
                // onBlur={handleBlur}
              ></Styled.largeTextField>
              <Styled.newsletterText
                isIpad={isIpad ? true : false}
                isIpadLandscape={isIpadLandscape ? true : false}
                isMobile={isMobile ? true : false}
                isMobileLandscape={isMobileLandscape ? true : false}
              >
                {intlMessages.loginNewsletterText1}{" "}
                <a
                  href="terms.html"
                  target="_blank"
                  style={{
                    cursor: "pointer",
                    textDecoration: "none",
                    color: "#1A73E8",
                  }}
                  onClick={() => {
                    logEvent(
                      Categories.user_management,
                      Events.user_management_terms_of_service
                    );
                  }}
                >
                  {intlMessages.loginNewsletterText4}
                </a>{" "}
                <span>{intlMessages.loginNewsletterText3}</span>{" "}
                <a
                  href="privacy-policies.html"
                  target="_blank"
                  style={{
                    cursor: "pointer",
                    textDecoration: "none",
                    color: "#1A73E8",
                  }}
                  onClick={() => {
                    logEvent(
                      Categories.user_management,
                      Events.user_management_privacy_policy
                    );
                  }}
                >
                  {intlMessages.loginNewsletterText2}
                </a>
              </Styled.newsletterText>
              <Box
                sx={{
                  width: isMobile
                    ? "250px"
                    : isIpad
                    ? "540px"
                    : isIpadLandscape
                    ? "490px"
                    : "410px",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-end",
                }}
              >
                <Styled.forgotPassword onClick={navigateToForgotPassword}>
                  {intlMessages.loginForgotPasswordText}
                </Styled.forgotPassword>
              </Box>
              <Styled.loginBtn
                isMobile={isMobile ? true : false}
                isMobileLandscape={isMobileLandscape ? true : false}
                variant="contained"
                loading={isSaving}
                loadingPosition="start"
                disabled={isSaving ? true : false}
                loadingIndicator={
                  <CircularProgress color="inherit" size={16} />
                }
                onClick={handleFormSubmition}
              >
                {intlMessages.loginContinueBtnText}
              </Styled.loginBtn>
              <Styled.loginText
                isMobile={isMobile ? true : false}
                isMobileLandscape={isMobileLandscape ? true : false}
              >
                {intlMessages.loginDontHaveAccountText}
                <span
                  onClick={navigateToSignUp}
                  style={{
                    color: "#1A73E8",
                    marginLeft: "5px",
                    cursor: "pointer",
                  }}
                >
                  {intlMessages.loginSignUp}
                </span>
              </Styled.loginText>
            </Styled.formContainer>
          </Styled.imageAndFormContainer>
        </Styled.MainContainer>
        <Footer></Footer>
      </Box>
      {openConnectionModal && (
        <ConnectionModal
          isOpen={openConnectionModal}
          handleCloseConnectionModal={() => setIsOpenConnectionModal(false)}
        />
      )}
      {openAlertBox && (
        <AlertBox
          isIpad={isIpad ? true : false}
          isMobile={isMobile ? true : false}
          isMobileLandscape={isMobileLandscape ? true : false}
          isIpadLandscape={isIpadLandscape ? true : false}
          message={alertMsg}
          loginBtn={true}
          isOpen={openAlertBox}
          onClose={() => setOpenAlertBox(false)}
        ></AlertBox>
      )}
    </>
  );
}

export default LogIn;
