import { useEffect } from 'react';
import {conf} from "./version";
// Replace with your actual Clarity ID

export const trackingCode = `
  (function(c,l,a,r,i,t,y){
    c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
    t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
    y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
  })(window, document, "clarity", "script", "${conf.clarity_Id}");
`;

const useClarity = () => {
  useEffect(() => {
    if(!conf.clarity_Id) return;
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.async = true;
    script.innerHTML = trackingCode;
    document.head.appendChild(script);

    return () => {
      document.head.removeChild(script);
    };
  }, []);
};

export default useClarity;
