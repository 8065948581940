const USER_DATA_KEY = "userData"

const setUserData = (data) => {
    localStorage.setItem(USER_DATA_KEY,JSON.stringify(data));
}

const getUserData = () => {
    const userData = JSON.parse(localStorage.getItem(USER_DATA_KEY));
    return userData;
}

const updateUserData = (newData) => {
    const userData = JSON.parse(localStorage.getItem(USER_DATA_KEY));
    const updatedData = JSON.stringify({...userData,...newData})
    localStorage.setItem(USER_DATA_KEY, updatedData);
}

export {
    USER_DATA_KEY,
    setUserData,
    getUserData,
    updateUserData
}