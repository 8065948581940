import { Avatar, Box, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import UserDrawerMenu from "../user-menu/component";
import { useLocation } from "react-router-dom";

const AvatarUI = (props) => {
  const location = useLocation();
  const isProfilePage = location.pathname === "/profile";

  const [isProfileMenu, setIsProfileMenu] = useState(false);
  const {
    isIpad,
    isAboutPage,
    isIpadLandscape,
    isMobile,
    isMobileLandscape,
    isSignedIn,
    isSignUpPage,
    isGoogleForm,
    isLoginPage,
    userNameData,
    isQuizPage,
    isForgotResetPage,
    openQuizLibrary,
  } = props;

  const [profileAnchorEl, setProfileAnchorEl] = useState(null);
  const open = Boolean(profileAnchorEl);

  async function handleOrientationChange_() {
    setProfileAnchorEl(false);
  }
  useEffect(() => {
    window.addEventListener("orientationchange", handleOrientationChange_);
    setIsProfileMenu(location.pathname === "/profile");
    return () => {
      window.removeEventListener("orientationchange", handleOrientationChange_);
    };
  }, []);

  useEffect(() => {
    setIsProfileMenu(location.pathname === "/profile");
    return () => {
      handleClose();
    };
  }, [location]);

  const handleClick = (event) => {
    setProfileAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setProfileAnchorEl(null);
  };

  return (
    <>
      <Box
        sx={{
          display: isSignedIn ? "flex" : "none",
          flexDirection: "row",
          alignItems: "center",
          cursor: "pointer",
          marginBottom: isIpad ? "10px" : "unset",
          pt: isMobileLandscape ? "5px" : "unset",
          position: isMobile ? "absolute" : "",
          right: isMobile ? "50px" : "",
          top: isMobile ? "7px" : "",
        }}
        onClick={handleClick}
        aria-controls={open ? "account-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
      >
        <Avatar
          sx={{
            bgcolor: isProfileMenu ? "#7DC243" : "#F5F5F5",
            color: isProfileMenu ? "#F5F5F5" : "#7DC242",
            marginRight:
              isMobile || (isMobileLandscape && isAboutPage) ? "20px" : "7px",
            marginLeft: isMobileLandscape ? "7px" : "10px",
            textTransform: "uppercase",
            width: "38px",
            height: "38px",
            fontSize: "16px",
            fontWeight: 600,
          }}
        >
          {userNameData.avatar}
        </Avatar>
        <Typography
          color="secondary.dark"
          variant="body1"
          style={{
            fontSize: "16px",
            color: isProfileMenu ? "#7DC243" : "#FFFFFF",
            marginRight: isIpad ? "10px" : "20px",
            display: isMobile || isMobileLandscape ? "none" : "",
          }}
        >
          {userNameData.name}
        </Typography>
      </Box>

      {profileAnchorEl && (
        <UserDrawerMenu
          isOpen={profileAnchorEl}
          isIpad={isIpad}
          isAboutPage={isAboutPage}
          isIpadLandscape={isIpadLandscape}
          isMobile={isMobile}
          isMobileLandscape={isMobileLandscape}
          isSignedIn={isSignedIn}
          isSignUpPage={isSignUpPage}
          isGoogleForm={isGoogleForm}
          isLoginPage={isLoginPage}
          userNameData={userNameData}
          onClose={(flag) => handleClose(flag)}
          anchorEl={profileAnchorEl}
          isForgotResetPage={isForgotResetPage}
        ></UserDrawerMenu>
      )}
    </>
  );
};

export default AvatarUI;
