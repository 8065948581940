

import { useMediaQuery } from "@mui/material";
import WelcomeModalDesktop from "./desktopModel/index"
import { Events, logEvent, Categories } from "../../analytics";
import { useDispatch } from "react-redux";
import {setIsConsentBtnClicked} from "../../redux/slices/quizSlice";

import React,{ useEffect, useState, useRef } from "react";
import intlMessages from "../../locales/en";
import { useTheme } from "@mui/material/styles";
import MobileWelcomeModal from "./mobile-welcome-modal/component";
import sliderImages from "./slider";
import { useNavigate } from "react-router-dom";
import { getNavigateURL } from "../../util/signin-user-data";

export default function WelcomeModal(props) {
  const {onClose} = props
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [isOpen, setIsOpen] = useState(false);
  useEffect(()=>{
   const timer =  setTimeout(()=>{
      setIsOpen(true)
    },500)
    return () => clearTimeout(timer);
  },[])

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"), {
    noSsr: true,
  });
  const isMobileLandscape = useMediaQuery(
    `${theme.breakpoints.down(
      "md"
    )}  and (orientation: landscape) and (hover: none) and (pointer: coarse)`,
    {
      noSsr: true,
    }
  );
  const isIpad = useMediaQuery(
    window.navigator.userAgent.includes("OneScreen_TL7")
      ? "(min-width:620px) and (max-width:960px) and (orientation: landscape) "
      : "(min-width:620px) and (max-width:840px) and (orientation: portrait) and  (hover: none) and (pointer: coarse)",
    {
      noSsr: true,
    }
  );
  const isIpadLandscape = useMediaQuery(
    '(min-width: 768px) and (max-width: 1024px) and (orientation: landscape) and  (hover: none) and (pointer: coarse)',
    {
        noSsr: true,
      }
)

  const handleClose = () => {
  
    
    logEvent(Categories.start_adding, Events.start_adding);
    checked? dispatch(setIsConsentBtnClicked(true)):dispatch(setIsConsentBtnClicked(false))
    if(!checked) {
      setConsentColor(true);
      return;
    }
    setConsentColor(false);
    setIsOpen(false);
    onClose(false)
  };

  const handleTrialBtn = ()=>{
    dispatch(setIsConsentBtnClicked(true))
    const signupURL = getNavigateURL('/signup')
    navigate(signupURL)
    onClose(false)
  }

  const [slide, setSlide] = useState({
    imgSrc: sliderImages[1],
    desc: intlMessages.SplashScreenSlide1Text
  })
  const [currentIndex, setCurrentIndex] = useState(1)
  const bgRef = useRef([])
  const [checked, setChecked] = useState(true);
  const [consentColor, setConsentColor] = useState(false)

  useEffect(() => {
    
    const interval = setInterval(() => {
      if (currentIndex >= 5) {
        setCurrentIndex(1);
      }

      if (bgRef.current.some(element => element !== null) && currentIndex < 5) {
        bgRef.current[currentIndex - 1].style.backgroundColor = "white";
        bgRef.current.filter((el, ind) => ind !== currentIndex - 1).map((element) => element.style.backgroundColor = "transparent");
        setSlide({
          imgSrc: sliderImages[currentIndex],
          desc: intlMessages[`SplashScreenSlide${currentIndex}Text`]
        });
        setCurrentIndex(currentIndex => currentIndex + 1);
      }
    }, 3000);
    return () => clearInterval(interval);
  }, [currentIndex])

  let list = []
  for (let i = 0; i < 4; i++) {
    list.push(<div ref={el => bgRef.current[i] = el} key={i} style={{
      backgroundColor: i == 0 ? "white" : "transparent",
      borderRadius: "50%",
      border: "2px solid white",
      width: 15,
      height: 15,
      marginRight: 10,
      cursor: "pointer"
    }}
      onClick={() => {
        handleClick(i)
      }}
    ></div>);
  }


  const handleChange = (event) => {
    setConsentColor(false)
    setChecked(event.target.checked);
  };

  const handleConsentClick = () => {
    setChecked(!checked);
    setConsentColor(false)
  }
  function handleClick(index) {
    bgRef.current[index].style.backgroundColor = "white"
    bgRef.current.filter((el, ind) => ind !== index).map((element) => element.style.backgroundColor = "transparent")
    setSlide(
      {
        imgSrc: sliderImages[index+1],
        desc: intlMessages[`SplashScreenSlide${index + 1}Text`]
      }
    )
    setCurrentIndex(index + 2);
    logEvent(Categories.start_adding, Events.slide_clicked);

  }

  return (
   isMobile || isMobileLandscape ||isIpad || isIpadLandscape ? <MobileWelcomeModal
   isOpen={isOpen} handleTrialBtn={handleTrialBtn} isIpad={isIpad} theme={theme} handleClose={handleClose} checked={checked} 
   handleChange={handleChange} handleConsentClick={handleConsentClick} consentColor={consentColor}
   slide={slide} list={list} isIpadLandscape={isIpadLandscape} isMobile={isMobile} isMobileLandscape={isMobileLandscape}
    /> :
    <WelcomeModalDesktop handleTrialBtn={handleTrialBtn} isOpen={isOpen} isIpad={isIpad} theme={theme} handleClose={handleClose} checked={checked} 
    handleChange={handleChange} handleConsentClick={handleConsentClick} consentColor={consentColor}
    slide={slide} list={list}/>
   
  )
}

