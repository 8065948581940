import { Box, ListItemIcon, Typography } from "@mui/material";
import intlMessages from "../../locales/en";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { useTheme } from "@mui/material/styles";
import { useNavigate, useLocation } from "react-router-dom";
import { conf } from "../../version";
import {
  COMPANY_NAME,
  FACEBOOK_LINK,
  INSTAGRAM_LINK,
  ONESCREEN_WEBSITE_URL,
  TWITTER_LINK,
} from "../../settings";
import { Events, logEvent, Categories } from "../../analytics";
import Styled from "./styles";
import socialImages from "./social-images";
import { useEffect, useState } from "react";
import {
  checkUrlIsGoogleForm,
  getNavigateURL,
} from "../../util/signin-user-data";
import { useDispatch, useSelector } from "react-redux";
import { header_images_base64 } from "../header/header-images-base64";
import { setOpenPricingModal } from "../../redux/slices/featuresSlice";
const ver = conf.version.replace("v", "Ver ");
export default function DrawerMenu(props) {
  const { isSignedIn, userData,stripeStatus } = useSelector((state) => state.logIn);

  const theme = useTheme();
  const navigate = useNavigate();
  const location = useLocation();
  const isLoginPage = location.pathname === "/login";
  const isSignUpPage =
    location.pathname === "/signup" || location.pathname === "/signup-email";
  const isGenerateQuizPage = location.pathname === "/";
  const isProfilePage = location.pathname === "/profile";
  const [isGoogleForm, setIsGoogleForm] = useState(false);
  const {
    isOpen,
    onClose,
    isMobileLandscape,
    isAboutPage,
    top,
    isIpad,
    isQuizPage,
    handleOpenFeedback,
    isForgotResetPage,
    isMobile,
    isIpadLandscape,
    handleOpenGuru,
  } = props;
  const dispatch = useDispatch();
  const handleCloseMenuBtn = () => {
    onClose(false);
  };

  const pricingBtnHandler = () => {
    if (userData?.subscriptionPlan === "Basic")
      logEvent(
        Categories.pricing_modal,
        Events.pricing_modal_upgrade_tab_clicked
      );
    else logEvent(Categories.pricing_modal, Events.pricing_modal_tab_clicked);
    dispatch(setOpenPricingModal(true));
  };
  const handleImageClick = (url) => {
    if (url === FACEBOOK_LINK)
      logEvent(Categories.social_links, Events.social_facebook_link_clicked);
    if (url === INSTAGRAM_LINK)
      logEvent(Categories.social_links, Events.social_instagram_link_clicked);
    if (url === TWITTER_LINK)
      logEvent(Categories.social_links, Events.social_twitter_link_clicked);
    window.open(url);
  };

  useEffect(() => {
    const handleScroll = () => {
      handleCloseMenuBtn();
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    // // console.log("isMobileLandscape && (isQuizPage || isSignUpPage || isLoginPage)", isMobileLandscape && isAboutPage)
    setIsGoogleForm(checkUrlIsGoogleForm());
    window.addEventListener("orientationchange", handleCloseMenuBtn);
    return () => {
      window.removeEventListener("orientationchange", handleCloseMenuBtn);
    };
  }, []);

  const navigateToUrl = (url, eventType) => {
    logEvent(Categories.user_management, eventType);
    const forgotpasswordUrl = getNavigateURL(url);
    navigate(forgotpasswordUrl);
  };

  return (
    <Menu
      disableScrollLock={true}
      PaperProps={{
        sx: {
          borderRadius: 0,
          boxShadow: "10px 4px 15px 0px rgba(0, 0, 0, 0.45)",
          overflow: "visible",
          "& .MuiAvatar-root": {},
          "&:before": {
            content: '""',
            display: isMobileLandscape && isGenerateQuizPage ? "none" : "block",
            position: "absolute",
            top: 0,
            right: "10%",
            width: 10,
            height: 10,
            bgcolor: "background.paper",
            transform: "translateY(-50%) rotate(45deg)",
            zIndex: 0,
          },
        },
      }}
      anchorOrigin={{
        vertical:
          isMobileLandscape && (!isAboutPage || !isAboutPage)
            ? "bottom"
            : "top",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical:
          isMobileLandscape && (!isAboutPage || !isAboutPage)
            ? "bottom"
            : "top",
        horizontal: "right",
      }}
      style={{
        marginTop:
          isMobileLandscape && isAboutPage
            ? "unset"
            : isIpad
            ? `${window.scrollY == 0 ? 0 : 65}px`
            : isMobileLandscape &&
              (isGenerateQuizPage || isSignUpPage || isLoginPage)
            ? "15px"
            : isMobileLandscape && isForgotResetPage
            ? "15px"
            : isMobileLandscape && isProfilePage
            ? "15px"
            : "35px",
        marginLeft:
          isMobileLandscape && isAboutPage
            ? "unset"
            : isMobileLandscape && (isGenerateQuizPage || isAboutPage)
            ? "-60px"
            : isMobileLandscape && isForgotResetPage
            ? "unset"
            : isIpad && !isSignedIn && !isAboutPage
            ? "-60px"
            : isIpad && isAboutPage
            ? "-10px"
            : isIpad && isSignedIn
            ? "-60px"
            : "unset",
      }}
      open={isOpen}
      onClose={handleCloseMenuBtn}
      MenuListProps={{
        "aria-labelledby": "basic-button",
      }}
      sx={{
        fontSize: "16px",
        ".MuiList-root": { padding: 0 },
        overflow: isIpad ? "unset" : "auto",
      }}
    >
      <MenuItem
        onClick={() => {
          const quizURL = getNavigateURL("/");
          navigate(quizURL);
          logEvent(Categories.page_view, Events.main_screen);
          onClose(false);
        }}
        sx={{
          pt: 2,
          pb: 2,
          fontSize: "16px",
          color: "#2062BB",
          backgroundColor: theme.palette.primary.contrastText,
        }}
        component="label"
      >
        {intlMessages.drawerGenerateQuizLabel}
      </MenuItem>

      <Box
        sx={{
          background: "rgba(0, 0, 0, 0.3)",
          height: "1px",
        }}
      ></Box>
      <MenuItem
        onClick={() => {
          const aboutURL = getNavigateURL("/about");
          navigate(aboutURL);
          logEvent(Categories.page_view, Events.about_screen);
          onClose(false);
        }}
        sx={{
          pt: 2,
          pb: 2,
          fontSize: "16px",
          color: "#2062BB",
          backgroundColor: theme.palette.primary.contrastText,
        }}
        component="label"
      >
        {intlMessages.aboutButtonLabel}
      </MenuItem>
      <Box
        sx={{
          background: "rgba(0, 0, 0, 0.3)",
          height: "1px",
        }}
      ></Box>
      <MenuItem
        onClick={() => {
          pricingBtnHandler();
          onClose(false);
        }}
        sx={{
          pt: 2,
          pb: 2,
          fontSize: "16px",
          color: "#2062BB",
          backgroundColor: theme.palette.primary.contrastText,
        }}
        component="label"
      >
        {stripeStatus === 12
          ? intlMessages.upgradeTabLabel
          : intlMessages.pricingTabLabel}
      </MenuItem>
      <Box
        sx={{
          background: "rgba(0, 0, 0, 0.3)",
          height: "1px",
        }}
      ></Box>
      <MenuItem
        onClick={() => {
          handleOpenFeedback();
          onClose(false);
        }}
        sx={{
          pt: 2,
          pb: 2,
          fontSize: "16px",
          color: "#2062BB",
          backgroundColor: theme.palette.primary.contrastText,
        }}
        component="label"
      >
        <ListItemIcon>
          <img
            src={
              isMobile || isMobileLandscape || isIpad
                ? "/svgs/feedback-mobile.svg"
                : "/svgs/Feedback-white.svg"
            }
          ></img>
        </ListItemIcon>
        <Typography
          variant="body1"
          style={{ fontSize: "16px", color: "#1A73E8", fontWeight: 400 }}
        >
          {intlMessages.feedback}
        </Typography>
      </MenuItem>
      <Box
        sx={{
          background: "rgba(0, 0, 0, 0.3)",
          height: "1px",
        }}
      ></Box>
      <MenuItem
        onClick={() => {
          handleOpenGuru();
          onClose(false);
        }}
        sx={{
          pt: 2,
          pb: 2,
          pl: "13px",
          fontSize: "16px",
          color: "#2062BB",
          backgroundColor: theme.palette.primary.contrastText,
        }}
        component="label"
      >
        <ListItemIcon>
          <img src={`${header_images_base64[3]}`} height={29} width={29}></img>
        </ListItemIcon>
        <Typography
          variant="body1"
          style={{
            fontSize: "16px",
            color: "#1A73E8",
            fontWeight: 400,
            marginLeft: "3px",
          }}
        >
          {intlMessages.GURU_STR_2}
        </Typography>
      </MenuItem>

      <Box
        sx={{
          background: "rgba(0, 0, 0, 0.3)",
          height: "1px",
          display:
            (!isSignedIn && isLoginPage) ||
            (!isSignedIn && isQuizPage) ||
            (!isSignedIn && isAboutPage)
              ? ""
              : "none",
        }}
      ></Box>
      <MenuItem
        onClick={() => {
          // navigate('/about');
          // logEvent(Categories.page_view, Events.about_screen);
          onClose(false);
        }}
        sx={{
          pt: 2,
          pb: 2,
          fontSize: "14px",
          backgroundColor: theme.palette.primary.contrastText,
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
          display:
            (!isSignedIn && isLoginPage) ||
            (!isSignedIn && isQuizPage) ||
            (!isSignedIn && isAboutPage)
              ? ""
              : "none",
        }}
        component="label"
      >
        <Styled.SignUpButtonMobile
          variant="contained"
          color="secondary"
          sx={{}}
          onClick={() =>
            navigateToUrl("/login", Events.user_management_login_click_header)
          }
        >
          {" "}
          {intlMessages.loginLabel}{" "}
        </Styled.SignUpButtonMobile>
      </MenuItem>
      <Box
        sx={{
          background: "rgba(0, 0, 0, 0.3)",
          height: "1px",
          display: !isSignedIn && isSignUpPage ? "" : "none",
        }}
      ></Box>
      <MenuItem
        onClick={() => {
          // navigate('/about');
          // logEvent(Categories.page_view, Events.about_screen);
          onClose(false);
        }}
        sx={{
          pt: 2,
          pb: 2,
          fontSize: "14px",
          backgroundColor: theme.palette.primary.contrastText,
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
          display: !isSignedIn && isSignUpPage ? "" : "none",
        }}
        component="label"
      >
        <Styled.SignUpButtonMobile
          variant="contained"
          color="secondary"
          sx={{}}
          onClick={() =>
            navigateToUrl("/signup", Events.user_management_signup_click_header)
          }
        >
          {" "}
          {intlMessages.mobileSignBtnText}{" "}
        </Styled.SignUpButtonMobile>
      </MenuItem>

      <Box
        sx={{
          background: "rgba(0, 0, 0, 0.3)",
          height: "1px",
          display: isAboutPage ? "none" : "block",
        }}
      ></Box>
      <MenuItem
        sx={{
          display: isAboutPage ? "none" : "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
          pt: 2,
          backgroundColor: theme.palette.primary.contrastText,
        }}
        component="label"
      >
        <Box>
          <Styled.FacebookLogo
            onClick={() => handleImageClick(FACEBOOK_LINK)}
            src={socialImages[0]}
          ></Styled.FacebookLogo>
          <Styled.TwitterLogo
            onClick={() => handleImageClick(TWITTER_LINK)}
            src={socialImages[1]}
          ></Styled.TwitterLogo>
          <Styled.TwitterLogo
            onClick={() => handleImageClick(INSTAGRAM_LINK)}
            src={socialImages[2]}
          ></Styled.TwitterLogo>
        </Box>
      </MenuItem>
      <Box
        sx={{
          background: "rgba(0, 0, 0, 0.3)",
          height: "1px",
          display: "block",
        }}
      ></Box>
      <MenuItem
        sx={{
          display: "block",
          pt: 2,
          fontSize: "12px",
          fontWeight: 400,
          color: "#727272",
          backgroundColor: theme.palette.primary.contrastText,
        }}
        component="label"
      >
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <Box>
            © {intlMessages.copyrightlabel}
            <a
              style={{ marginLeft: "5px" }}
              target="_blank"
              href={ONESCREEN_WEBSITE_URL}
            >
              {COMPANY_NAME}
            </a>
          </Box>
          <Typography
            sx={{
              fontSize: "12px",
              fontWeight: 400,
              color: "#727272",
              backgroundColor: theme.palette.primary.contrastText,
            }}
          >
            {ver}{" "}
          </Typography>
        </Box>
      </MenuItem>
    </Menu>
  );
}
