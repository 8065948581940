import styled from "@mui/system/styled";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import {
  Button,
  Divider,
  FormControlLabel,
  Radio,
  Typography,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";

const ModalHeader = styled(Box)(({ theme, isSignedIn }) => ({
  padding: 8,
  paddingRight: 12,
  boxShadow: "rgba(100, 100, 111, 0.2) 0px 3px 29px 0px",
  paddingLeft: 25,
  alignItems: "center",
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
}));

const ModalActions = styled(Box)({
  padding: 5,
  paddingTop: 25,
  paddingBottom: 15,
  borderTop: "1px solid silver",
  display: "flex",
  justifyContent: "center",
});

const ModalTextField = styled(TextField)(({ theme }) => ({
  borderRadius: 3,
  background: "#F6F6F6",
  "& .MuiInputBase-root": {
    background: "#F6F6F6",
  },
  "& input::placeholder": {
    color: "#000000",
    // opacity: 1,
  },
  "& .MuiFormHelperText-root.Mui-error": {
    position: "absolute",
    top: "100%",
  },
  // padding:5,

  ".MuiOutlinedInput-input": {
    padding: 7,
  },
  width: "100%",
  [theme.breakpoints.down("md")]: {},
}));

const ModalGoogleurlTextField = styled(TextField)(({ theme }) => ({
  background: "#F6F6F6",
  "& .MuiInputBase-root": {
    background: "#F6F6F6",
  },
  "& input::placeholder": {
    color: "#000000",
    // opacity: 1,
  },
  "& .MuiFormHelperText-root.Mui-error": {
    position: "absolute",
    top: "100%",
  },
  borderRadius: 3,
  // padding:5,
  ".MuiOutlinedInput-root": {
    padding: "2px 5px",
  },

  ".MuiOutlinedInput-input": {
    padding: 0,
  },
  width: "100%",
  [theme.breakpoints.down("md")]: {},
}));

const TextFieldIndex = styled(TextField)(({ theme }) => ({
  "& .MuiFormLabel-root": {
    fontWeight: 600,
    fontSize: "12px",
    // top: "-8px",
  },
  "& .MuiInputLabel-outlined": {
    top: "-8px",
  },
  "& .Mui-focused": {
    top: "0 !important",
  },
  "& .MuiFormLabel-filled": {
    top: "0 !important",
  },
}));

const ModalBox = styled(Box)(({ theme }) => ({
  width: "30%",
  [theme.breakpoints.down("lg")]: {
    width: "60%",
  },
  [theme.breakpoints.down("sm")]: {
    width: "95%",
  },
}));

const ModalSignInBox = styled(Box)(({ theme }) => ({
  width: "38%",
  [theme.breakpoints.down("lg")]: {
    width: "70%",
  },
  [theme.breakpoints.down("sm")]: {
    width: "95%",
  },
}));

const ModalPrmissionSignInBox = styled(Box)(({ theme, isTl7 }) => ({
  width: isTl7 ? "40%" : "35%",
  [theme.breakpoints.down("lg")]: {
    width: "70%",
  },
  [theme.breakpoints.down("sm")]: {
    width: "95%",
  },
}));

const ModalContentBox = styled(Box)(({ isTl7 }) => ({
  marginTop: 30,
  marginLeft: 25,
  marginRight: 25,
  marginBottom: 30,
}));

const ModalGoogleContentBox = styled(Box)({
  marginTop: 15,
  marginLeft: 20,
  marginRight: 20,
  marginBottom: 61,
});

const CreateQuizButton = styled(LoadingButton)(({ theme, isSaving }) => ({
  color: theme.palette.primary.contrastText,
  textTransform: "none",
  border: "none",
  "&:hover": {
    border: "none",
  },
}));

const ContinueGoogleBtn = styled(Button)(
  ({ theme, isMobileLandscape, isMobile, isIpad, isIpadLandscape }) => ({
    width: isMobile ? "260px" : "392px",
    height: "54px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    background: "#FFFFFF",
    border: "1px solid #A7D5EC",
    borderRadius: "4px",
    textTransform: "none",
  })
);

const SupportTextTypography = styled(Typography)(
  ({ theme, errorText = false }) => ({
    width: "100%",
    color: errorText ? "red" : "#999999",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "normal",
    marginTop: "5px",
  })
);

const RadioBtn = styled(Radio)(({ theme, isMobile }) => ({
  marginLeft: isMobile ? "-14px" : "",
  color: theme.palette.secondary.main,
  "&.Mui-checked": {
    color: theme.palette.secondary.main,
  },
  "& .MuiSvgIcon-root": {
    fontSize: 22,
  },
}));

const StyledFormControlLabel = styled(FormControlLabel)(({ theme }) => ({
  root: {
    fontSize: "0.875rem",
    marginRight: "40px",
  },
  "& .MuiFormControlLabel-label ": {
    // marginRight: "20px !important",
  },
  "& .MuiTypography-root": {
    fontSize: "0.875rem",
  },
  "& .MuiButtonBase-root ": {
    padding: "5px",
  },
  color: "#757575",
}));

const typographyText = styled(Typography)(({ theme }) => ({
  marginTop: "10px",
  marginLeft: "5px",
  width: "40%",
  fontSize: "12px",
  color: "#999999",
  fontWeight: "400",
  lineHeight: "14.98px",
  [theme.breakpoints.down("sm")]: {
    width: "47%",
    marginLeft: "109px",
  },
}));

const ErrorText = styled(Typography)(({ theme }) => ({
  color: theme.palette.warning.main,
  fontSize: 12,
  padding: 2,
  display: "flex",
  textAlign: "flex-start",
}));

const CustomDivider = styled(Divider)(({ theme }) => ({
  width: "100%",
  marginBottom: "25px",
  color: "#33333380",
  marginTop: "25px",
  textTransform: "uppercase",
  "&::before": {
    borderColor: "#C3C3C3", // Change this to the desired divider line color
  },
}));

const customStyling = {
  exportModal: {
    position: "absolute",
    top: { xs: "45%", sm: "50%" },
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    borderRadius: 2,
    boxShadow: 24,
    overflowY: "auto",
    maxHeight: "90%",
    border: "none",
  },
  button: {
    width: "392px",
    height: "54px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    background: "#EBF5FA",
    border: "1px solid #A7D5EC",
    borderRadius: "4px",
    textTransform: "none",
  },
  inputNumberField: {
    width: "110px",
    height: "30px",
    top: "4px",
    color: "#666",
    borderRadius: "4px !important",
    "& .MuiOutlinedInput-root:hover fieldset": {
      borderColor: "#BBBBBB !important",
      borderWidth: "1px",
    },
    "& .MuiOutlinedInput-root": {
      height: "30px !important",
      borderRadius: "4px !important",
      color: "#666",
      "&.Mui-focused": {
        "& fieldset": {
          borderColor: "#BBBBBB !important",
          borderWidth: "1px",
        },
      },
    },
    "& .MuiOutlinedInput-input": {
      padding: "0px 0px 0px 4px",
    },
  },
};

export default {
  CreateQuizButton,
  ModalHeader,
  ModalActions,
  ModalTextField,
  ModalBox,
  ModalContentBox,
  customStyling,
  ErrorText,
  ModalSignInBox,
  ModalGoogleContentBox,
  ModalGoogleurlTextField,
  ModalPrmissionSignInBox,
  ContinueGoogleBtn,
  CustomDivider,
  SupportTextTypography,
  StyledFormControlLabel,
  RadioBtn,
  typographyText,
  TextFieldIndex,
};
