import { getInternetConnectionStatus } from "../import-file/service";
import api from "../../api";
import { checkInternetConnection } from "../../api/service";

const handleErrorMsg = (e) => {
  if (e.response) {
    let resMessage = e?.response?.data?.message;
    if (Array.isArray(resMessage)) {
      resMessage = resMessage[0];
    }
    e.response.isConnected = false;
    e.response.data.message = resMessage;
    return e?.response;
  }
  return e;
};

async function createQuizFromData(fileId, fileUrl, startIndex, endIndex) {
  try {
    const payload = {
      file_id: fileId,
      file_url: fileUrl,
      start_index: startIndex,
      end_index: endIndex,
    };
    const connectionStatus = await checkInternetConnection();
    const isInternetConnected = connectionStatus.isConnected;
    if (!isInternetConnected) return connectionStatus;
    const res = await api.post(`/quiz/generate-quiz`, payload);
    return res;
  } catch (e) {
    return handleErrorMsg(e);
  }
}

const containsOnlyNewlines = (inputString) => {
  // Use a regular expression to check if the string contains only newline characters
  return /^(\n)+$/.test(inputString);
};

export {
  getInternetConnectionStatus,
  createQuizFromData,
  containsOnlyNewlines,
};
