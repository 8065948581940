import styled from "@mui/system/styled";
import { Typography, Button, Box } from "@mui/material";
import { LoadingButton } from "@mui/lab";

const MainContainer = styled(Box)(({ theme, isMobile, isMobileLandscape,height }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  margin: "0% 3%",
  borderRadius: "10px",
  backgroundColor: "white",
  alignItems: "center",
  height: isMobile || isMobileLandscape ? "unset" : height,
  // [theme.breakpoints.up("md")]: {
  //   height: '440px'
  // },
  // [theme.breakpoints.up("xxxl")]: {
  //   height: "645px",
  // },
}));

const verifyText = styled(Typography)(
  ({ theme, isMobile, isMobileLandscape }) => ({
    fontFamily: "Open Sans",
    fontWeight: "700",
    fontSize: isMobile ? "24px" : "32px",
    lineHeight: "32px",
    textAlign: "center",
    color: "#222222",
    marginBottom: isMobile ? "24px" : "2%",
    marginTop: isMobile || isMobileLandscape ? "20px" : "unset",
    marginLeft: isMobile?'2%':'unset',
    marginRight:isMobile?'2%':'unset'
  })
);

const verifyTextDesc = styled(Typography)(
  ({ theme, isMobile, isMobileLandscape }) => ({
    fontFamily: "Open Sans",
    fontWeight: "400",
    fontSize: isMobile || isMobileLandscape ? "14px" : "18px",
    lineHeight: isMobile || isMobileLandscape ? "24px" : "32px",
    textAlign: "center",
    color: "#222222",
    marginBottom: isMobile || isMobileLandscape ? "15px" : "40px",
    paddingLeft: isMobile || isMobileLandscape ? "10%" : "20%",
    paddingRight: isMobile || isMobileLandscape ? "10%" : "20%",
  })
);

const accountVerificationText = styled(Typography)(
  ({ theme, isMobile, isMobileLandscape }) => ({
    fontFamily: "Open Sans",
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "24px",
    textAlign: "center",
    color: "#7DC242",
    marginBottom: isMobile || isMobileLandscape ? "15px" : "40px",
    display: "none",
  })
);

const tokenExpiredText = styled(Typography)(
  ({ theme, isMobile, isMobileLandscape }) => ({
    fontFamily: "Open Sans",
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "24px",
    textAlign: "center",
    color: "red",
    marginTop: isMobile || isMobileLandscape ? "0px" : "20px",
    marginBottom: isMobile || isMobileLandscape ? "20px" : "unset",
    paddingLeft: isMobile || isMobileLandscape ? "5%" : "unset",
    paddingRight: isMobile || isMobileLandscape ? "5%" : "unset",
  })
);

const resendBtn = styled(LoadingButton)(
  ({ theme, isMobile, isMobileLandscape }) => ({
    width: "210px",
    height: isMobile || isMobileLandscape ? "48px" : "54px",
    color: "white",
    backgroundColor: "#7DC243",
    borderRadius: "4px",
    textTransform: "none",
    marginBottom: isMobile || isMobileLandscape ? "15px" : "40px",
    ".MuiLoadingButton-loadingIndicator": {
      left: "30px",
    },
    "&:hover": {
      backgroundColor: "#89d44a",
    },
  })
);

const logOutBtn = styled(Button)(({ theme, isMobile, isMobileLandscape }) => ({
  width: "100px",
  height: "24px",
  fontSize: "14px",
  lineHeight: "24px",
  fontWeight: "600px",
  color: "#1A73E8",
  textTransform: "none",
  marginBottom: isMobile || isMobileLandscape ? "20px" : "unset",
}));

export default {
  MainContainer,
  verifyText,
  verifyTextDesc,
  accountVerificationText,
  resendBtn,
  logOutBtn,
  tokenExpiredText,
};
