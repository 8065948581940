import intlMessages from "../../locales/en";
import { CircularProgress, IconButton, Modal } from "@mui/material";
import Styled from "./styles";
import CloseIcon from "@mui/icons-material/Close";
import { useDispatch, useSelector } from "react-redux";
import { setOpenPricingModal } from "../../redux/slices/featuresSlice";
import { downgradSubscription } from "../downgrade-modal/service";
import { getCheckoutUrl } from "../../api/payment/methods";
import {
  setCanCaptureImage,
  setCanCopyToClipboard,
  setCanDeleteQuiz,
  setCanEditQuiz,
  setCanExportAikenFile,
  setCanExportGoogleSheet,
  setCanExportPdfFile,
  setCanExportQT12File,
  setCanExportToGoogleForm,
  setCanExportTxtFile,
  setCanExportXMLFile,
  setCanExtractDataFromUrl,
  setCanImportFromGoogleDrive,
  setCanImportSampleText,
  setCanImportTextFromSystem,
  setCanManageProfile,
  setCanSaveQuiz,
  setCanShareQuiz,
  setCanUploadExcelSheet,
  setCanUploadGoogleDoc,
  setCanUploadGoogleSheet,
  setCanUploadGoogleSlide,
  setCanUploadImage,
  setCanUploadPdf,
  setCanUploadPowerPointSlide,
  setCanUploadWordDoc,
  setDifficultyLevel,
  setGuruSupport,
  setQuestionCount,
  setQuizContentLimit,
  setQuizGenerationCount,
  setQuizGenerationType,
  setTrialDays,
} from "../../redux/slices/featuresSlice";
import { Categories, Events, logEvent } from "../../analytics";
import { setQuizCount, setQuizDifficultyLevel, setQuizType } from "../../redux/slices/quizSlice";
import { useState } from "react";

export default function ExpiryModal(props) {
  const { onClose, openConnectionModal,isTl7 } = props;
  const { openExpiryModal, userData,stripeStatus,rechargeTries,isScreenSubscription } = useSelector((state) => state.logIn);
  const [isSaving, setIsSaving] = useState(false);
  const dispatch = useDispatch();
  const handleOnClose = () => {
    if(rechargeTries > 0 && rechargeTries <3){
      sessionStorage.setItem("payment-modal","true")
    }
    onClose(false);
  };

  const handleDowngradeBtnClick = async () => {
    logEvent(
      Categories.upgrade_modal,
      Events.upgrade_modal_continue_with_basic_plan_btn_clicked
    );
    const res = await downgradSubscription(
      userData?.pricePlan,
      userData?.username,
      null,
      true
    );
    if (res?.data?.statusCode === 0) {
      openConnectionModal(true);
      return;
    }
    if (res?.status === 201 || res?.status === 409
    ) {
      handleOnClose();
      if (!!res?.data?.data) {
        dispatch(setTrialDays(res?.data?.data?.trialDays));
        dispatch(setQuizGenerationCount(res?.data?.data?.quizGenerationCount));
        dispatch(setQuizContentLimit(res?.data?.data?.quizContentLimit));
        dispatch(setQuestionCount(res?.data?.data?.questionCount));
        dispatch(setQuizGenerationType(res?.data?.data?.quizType));
        dispatch(setDifficultyLevel(res?.data?.data?.quizDifficultyLevel));
        dispatch(setCanImportSampleText(res?.data?.data?.canImportSampleText));
        dispatch(
          setCanImportTextFromSystem(res?.data?.data?.canImportTextFromSystem)
        );
        dispatch(
          setCanImportFromGoogleDrive(res?.data?.data?.canImportFromGoogleDrive)
        );
        dispatch(setCanUploadPdf(res?.data?.data?.canUploadPDF));
        dispatch(
          setCanUploadGoogleSheet(res?.data?.data?.canUploadGoogleSheet)
        );
        dispatch(setCanUploadExcelSheet(res?.data?.data?.canUploadExcelSheet));
        dispatch(
          setCanUploadPowerPointSlide(res?.data?.data?.canUploadPowerPointSlide)
        );
        dispatch(
          setCanUploadGoogleSlide(res?.data?.data?.canUploadGoogleSlide)
        );
        dispatch(setCanUploadWordDoc(res?.data?.data?.canUploadWordDoc));
        dispatch(setCanUploadGoogleDoc(res?.data?.data?.canUploadGoogleDoc));
        dispatch(setCanUploadImage(res?.data?.data?.canUploadImage));
        dispatch(setCanCaptureImage(res?.data?.data?.canCaptureImage));
        dispatch(
          setCanExtractDataFromUrl(res?.data?.data?.canExtractDataFromUrl)
        );
        dispatch(setCanSaveQuiz(res?.data?.data?.canSaveQuiz));
        dispatch(setCanEditQuiz(res?.data?.data?.canEditQuiz));
        dispatch(setCanDeleteQuiz(res?.data?.data?.canDeleteQuiz));
        dispatch(setCanShareQuiz(res?.data?.data?.canShareQuiz));
        dispatch(setCanManageProfile(res?.data?.data?.canManageProfile));
        dispatch(setGuruSupport(res?.data?.data?.guruSupport));
        dispatch(setCanExportPdfFile(res?.data?.data?.canExportPdfFile));
        dispatch(setCanExportTxtFile(res?.data?.data?.canExportTxtFile));
        dispatch(setCanExportXMLFile(res?.data?.data?.canExportXMLFile));
        dispatch(setCanExportAikenFile(res?.data?.data?.canExportAikenFile));
        dispatch(setCanExportQT12File(res?.data?.data?.canExportQT12File));
        dispatch(
          setCanExportGoogleSheet(res?.data?.data?.canExportGoogleSheet)
        );
        dispatch(
          setCanExportToGoogleForm(res?.data?.data?.canExportToGoogleForm)
        );
        dispatch(setCanCopyToClipboard(res?.data?.data?.canCopyToClipboard));
        dispatch(setQuizType("Multiple choice"))
        dispatch(setQuizCount(5))
        dispatch(setQuizDifficultyLevel('Medium'))
      }
      return;
    }
  };

  const updatePaymentMethod = async () => {
    logEvent(Categories.upgrade_modal, Events.upgrade_modal_update_btn_clicked);
    if (rechargeTries > 0 && rechargeTries <=3) {
      setIsSaving(true);
      const url = await getCheckoutUrl(userData?.pricePlan);
      window.location.href = url?.data?.checkoutUrl || "#";
      setIsSaving(false);
    } else {
      dispatch(setOpenPricingModal(true));
    }
    onClose();
  };

  return (
    <>
      <Modal open={openExpiryModal}>
        <Styled.ModalBox sx={Styled.customStyling.exportModal}>
          <Styled.ModalHeader isTl7={isTl7?true:false} showCloseButton={rechargeTries > 0 && rechargeTries <3?true:false} isPaymentDue={rechargeTries>=3 ? true : false}>
            {rechargeTries > 0 && rechargeTries <3 && <IconButton
              aria-label="closeWarningParagraphModal"
              onClick={handleOnClose}
            >
              <CloseIcon color="seondary.light" />
            </IconButton>}
          </Styled.ModalHeader>
          <Styled.ModalContentBox>
            <Styled.StyledHeading>
              {rechargeTries > 0 && rechargeTries <=3
                ? intlMessages.paymentDueModalHeading:
                isScreenSubscription===true?
                intlMessages.screenTrialEndedText
                : stripeStatus === -2
                ? intlMessages.accountExpiredHeading
                : intlMessages.subscriptionExpiredText}
            </Styled.StyledHeading>

            <br></br>
            <Styled.StyledDesc
              isPaymentDue={rechargeTries > 0 && rechargeTries <=3 ? true : false}
            >
              {rechargeTries > 0 && rechargeTries <=3
                ? intlMessages.paymentDueModalDesc
                : intlMessages.accountExpiredDesc}
            </Styled.StyledDesc>
            <Styled.UpgradeBtn
            disabled={isSaving === true ? true : false}
              variant="contained"
              color="secondary"
              onClick={updatePaymentMethod}
            >
              {isSaving === true && (
                      <CircularProgress
                        color="inherit"
                        sx={{ marginRight: "10px" }}
                        size={16}
                      />
                    )}
              {rechargeTries > 0 && rechargeTries <=3
                ? intlMessages.paymentDueButtonText
                : intlMessages.upgradeModelBtnText}
            </Styled.UpgradeBtn>
            <Styled.StyledDivider orientation="horizontal">
              {intlMessages.quizInputBoxPlaceholderText3}
            </Styled.StyledDivider>
            <Styled.ActionText onClick={handleDowngradeBtnClick}>
              {intlMessages.upgradeModelContinueText1}
            </Styled.ActionText>
          </Styled.ModalContentBox>
        </Styled.ModalBox>
      </Modal>
    </>
  );
}
