import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import CloseIcon from "@mui/icons-material/Close";
import { IconButton, Button, CircularProgress } from "@mui/material";
import Styled from "./styles";
import intlMessages from "../../locales/en";
import { useDispatch, useSelector } from "react-redux";
import { Categories, Events, logEvent } from "../../analytics";
import { startTrial } from "./service";
import {
  setCanCaptureImage,
  setCanCopyToClipboard,
  setCanDeleteQuiz,
  setCanEditQuiz,
  setCanExportAikenFile,
  setCanExportGoogleSheet,
  setCanExportPdfFile,
  setCanExportQT12File,
  setCanExportToGoogleForm,
  setCanExportTxtFile,
  setCanExportXMLFile,
  setCanExtractDataFromUrl,
  setCanImportFromGoogleDrive,
  setCanImportSampleText,
  setCanImportTextFromSystem,
  setCanManageProfile,
  setCanSaveQuiz,
  setCanShareQuiz,
  setCanUploadExcelSheet,
  setCanUploadGoogleDoc,
  setCanUploadGoogleSheet,
  setCanUploadGoogleSlide,
  setCanUploadImage,
  setCanUploadPdf,
  setCanUploadPowerPointSlide,
  setCanUploadWordDoc,
  setDifficultyLevel,
  setGuruSupport,
  setQuestionCount,
  setQuizContentLimit,
  setQuizGenerationCount,
  setQuizGenerationType,
  setTrialDays,
} from "../../redux/slices/featuresSlice";
import {
  setQuizCount,
  setQuizDifficultyLevel,
  setQuizType,
} from "../../redux/slices/quizSlice";
import { useState } from "react";
import { downgradSubscription } from "../downgrade-modal/service";
const TrialInfoModal = (props) => {
  const { userData,stripeStatus } = useSelector((state) => state.logIn);
  const { open, onClose, text, downgradeFlag } = props;
  const [disabledTrialBtn, setDisabledTrialBtn] = useState(false);
  const dispatch = useDispatch();


  const updateFeatures = (response)=>{
    dispatch(setTrialDays(response?.data?.trialDays));
          dispatch(setQuizGenerationCount(response?.data?.quizGenerationCount));
          dispatch(setQuizContentLimit(response?.data?.quizContentLimit));
          dispatch(setQuestionCount(response?.data?.questionCount));
          dispatch(setQuizGenerationType(response?.data?.quizType));
          dispatch(setDifficultyLevel(response?.data?.quizDifficultyLevel));
          dispatch(setCanImportSampleText(response?.data?.canImportSampleText));
          dispatch(
            setCanImportTextFromSystem(response?.data?.canImportTextFromSystem)
          );
          dispatch(
            setCanImportFromGoogleDrive(
              response?.data?.canImportFromGoogleDrive
            )
          );
          dispatch(setCanUploadPdf(response?.data?.canUploadPDF));
          dispatch(
            setCanUploadGoogleSheet(response?.data?.canUploadGoogleSheet)
          );
          dispatch(setCanUploadExcelSheet(response?.data?.canUploadExcelSheet));
          dispatch(
            setCanUploadPowerPointSlide(
              response?.data?.canUploadPowerPointSlide
            )
          );
          dispatch(
            setCanUploadGoogleSlide(response?.data?.canUploadGoogleSlide)
          );
          dispatch(setCanUploadWordDoc(response?.data?.canUploadWordDoc));
          dispatch(setCanUploadGoogleDoc(response?.data?.canUploadGoogleDoc));
          dispatch(setCanUploadImage(response?.data?.canUploadImage));
          dispatch(setCanCaptureImage(response?.data?.canCaptureImage));
          dispatch(
            setCanExtractDataFromUrl(response?.data?.canExtractDataFromUrl)
          );
          dispatch(setCanSaveQuiz(response?.data?.canSaveQuiz));
          dispatch(setCanEditQuiz(response?.data?.canEditQuiz));
          dispatch(setCanDeleteQuiz(response?.data?.canDeleteQuiz));
          dispatch(setCanShareQuiz(response?.data?.canShareQuiz));
          dispatch(setCanManageProfile(response?.data?.canManageProfile));
          dispatch(setGuruSupport(response?.data?.guruSupport));
          dispatch(setCanExportPdfFile(response?.data?.canExportPdfFile));
          dispatch(setCanExportTxtFile(response?.data?.canExportTxtFile));
          dispatch(setCanExportXMLFile(response?.data?.canExportXMLFile));
          dispatch(setCanExportAikenFile(response?.data?.canExportAikenFile));
          dispatch(setCanExportQT12File(response?.data?.canExportQT12File));
          dispatch(
            setCanExportGoogleSheet(response?.data?.canExportGoogleSheet)
          );
          dispatch(
            setCanExportToGoogleForm(response?.data?.canExportToGoogleForm)
          );
          dispatch(setCanCopyToClipboard(response?.data?.canCopyToClipboard));
          dispatch(setQuizType("Multiple choice"));
          dispatch(setQuizCount(5));
          dispatch(setQuizDifficultyLevel("Medium"));
  }

  const closeModal = async () => {
    logEvent(
      Categories.confirmation_modal,
      Events.confirmation_modal_close_btn_clicked
    );
    if (disabledTrialBtn === false) {
      if (stripeStatus === -1) {
        setDisabledTrialBtn(true);
        const response = await startTrial(userData?.username);
        if (response?.status === 201) {
          updateFeatures(response)
        }
        setDisabledTrialBtn(false);
        onClose(false);
        return;
      }
      if(stripeStatus===-12){
        const res = await downgradSubscription(
          userData?.pricePlan,
          userData?.username,
          null,
          true
        );
        setDisabledTrialBtn(true);
        if (res?.status === 201 || res?.status === 409
        ) {
          if (!!res?.data?.data) {
            updateFeatures(res?.data)
          }
        }
        setDisabledTrialBtn(false);
        onClose(false);
        return
      }
    }
    if (userData?.isScreenSubscription === true) {
      onClose(false);
      window.location.reload();
      return;
    }
    onClose(false);
  };

  return (
    <>
      <Modal
        open={open}
        aria-labelledby="review-modal"
        aria-describedby="review-description"
      >
        <Styled.ModalPrmissionSignInBox sx={Styled.customStyling.exportModal}>
          <IconButton
            aria-label="closeReviewModal"
            disabled={disabledTrialBtn === true ? true : false}
            onClick={() => closeModal()}
            style={{ marginRight: "0px", right: "6px", position: "absolute" }}
          >
            <CloseIcon color="seondary.light" />
          </IconButton>
          <Styled.ModalContentBox>
            <Styled.ContainerBox
            // isMobile={
            //   isMobile || isMobileLandscape || isIpad || isIpadLandscape
            // }
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "start",
                  justifyContent: "flex-start",
                  // width: isMobile || isMobileLandscape || isIpad ? "100%" : "",
                }}
                onClick={() => {}}
              >
                <Styled.HeadingTextContainer>
                  <Styled.HeadingTextTypography>
                    {text.heading}
                  </Styled.HeadingTextTypography>
                  <Styled.DescText
                    downgradeFlag={
                      (downgradeFlag && downgradeFlag === true) || stripeStatus===-12 ? true : false
                    }
                  >
                    {text.desc}
                  </Styled.DescText>
                </Styled.HeadingTextContainer>
              </Box>
            </Styled.ContainerBox>
          </Styled.ModalContentBox>
          <Styled.ModalActions>
            <Button
              sx={{
                textTransform: "none",
                width: "96.44px",
                backgroundColor: "#7DC243",
                color: "white",
                ":hover": { bgcolor: "#89d44a" },
              }}
              disabled={disabledTrialBtn === true ? true : false}
              onClick={() => closeModal()}
            >
              {(stripeStatus === -1 || stripeStatus===-12) && disabledTrialBtn === true && (
                      <CircularProgress
                        color="inherit"
                        sx={{ marginRight: "10px" }}
                        size={16}
                      />
                    )}
              {intlMessages.closeLabel}
            </Button>
          </Styled.ModalActions>
        </Styled.ModalPrmissionSignInBox>
      </Modal>
    </>
  );
};

export default TrialInfoModal;
