import styled from "@mui/system/styled";
import Box from "@mui/material/Box";
import { Button, Divider, Link, Typography } from "@mui/material";

const ModalHeader = styled(Box)({
  padding: 8,
  paddingRight: 12,
  boxShadow: "rgba(100, 100, 111, 0.2) 0px 3px 29px 0px",
  paddingLeft: 25,
  alignItems: "center",
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
});

const ModalActions = styled(Box)(({ theme, isMobile, isMobileLandscape }) => ({
  padding: 5,
  paddingTop: 15,
  paddingBottom: 15,
  borderTop: "1px solid silver",
  display: "flex",
  justifyContent: "space-between",
  marginLeft: isMobile ? 10 : 25,
  marginRight: isMobile ? 10 : 25,
}));

const ModalBox = styled(Box)(({ theme }) => ({
  width: "50%",
  [theme.breakpoints.down("lg")]: {
    width: "80%",
  },
  [theme.breakpoints.down("sm")]: {
    width: "95%",
  },
}));

const ModalContentBox = styled(Box)(
  ({
    theme,
    selectedTab,
    isMobile,
    isMobileLandscape,
    isIpad,
    isIpadLandscape,
  }) => ({
    marginTop: 30,
    marginLeft: isMobile ? 10 : 25,
    marginRight: isMobile ? 10 : 25,
    marginBottom: selectedTab === true ? "unset" : 30,
  })
);

const NavigationBar = styled(Box)(
  ({
    theme,
    selectedTab,
    isMobile,
    isMobileLandscape,
    isIpad,
    isIpadLandscape,
  }) => ({
    width: "100%",
    height: "42px",
    backgroundColor: "#333333",
    color: "#FFFFFF",
    display: isMobile || isMobileLandscape ? "none" : "flex",
    flexDirection: "row",
    justifyContent: "space-evenly",
    alignItems: "center",
  })
);

const NavLabel = styled(Typography)(({ theme, selectedTab }) => ({
  fontFamily: "Open Sans",
  fontWeight: 600,
  fontSize: "16px",
  lineHeight: "21.79px",
  color: selectedTab === true ? "#7DC242" : "#FFFFFF",
  cursor: "pointer",
}));

const NavIcon = styled("img")(
  ({
    theme,
    selectedTab,
    isMobile,
    isMobileLandscape,
    isIpad,
    isIpadLandscape,
  }) => ({
    height: "14px",
    position: "relative",
    top: "-8px",
    left: selectedTab === 0 ? "20%" : selectedTab === 1 ? "50%" : "78%",
    zIndex: 10,
    display: isMobile || isMobileLandscape ? "none" : "unset",
  })
);

const SubscriptionDetailsContainer = styled(Box)(
  ({
    theme,
    selectedTab,
    isMobile,
    isMobileLandscape,
    isIpad,
    isIpadLandscape,
  }) => ({
    display: selectedTab == true ? "flex" : "none",
    flexDirection: isMobile || isMobileLandscape ? "column" : "row",
    justifyContent: "space-around",
    paddingLeft: isMobile || isMobileLandscape ? "20px" : "unset",
    width: "100%",
    marginTop: isMobile || isMobileLandscape ? "20px" : "40px",
  })
);

const SubscriptionCurrentPlanBox = styled(Box)(
  ({ theme, isMobile, isMobileLandscape, isIpad, isIpadLandscape }) => ({
    display: "flex",
    flexDirection: "column",
    width: "280px",
    marginRight: "31px",
    marginBottom: isMobile || isMobileLandscape ? "50px" : "unset",
  })
);

const SubscriptionCurrentPlanText = styled(Typography)(({ theme }) => ({
  fontFamily: "Open Sans",
  fontWeight: 600,
  fontSize: "18px",
  lineHeight: "24.51px",
  color: "#333333",
  marginBottom: "10px",
}));

const SubscriptionPlanDetailsBox = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  width: "280px",
}));

const SubscriptionPlanDetailsText = styled(Typography)(({ theme }) => ({
  fontFamily: "Open Sans",
  fontWeight: 600,
  fontSize: "18px",
  lineHeight: "24.51px",
  color: "#333333",
  marginBottom: "5px",
}));

const SubscriptionDescText = styled(Typography)(({ theme }) => ({
  fontFamily: "Open Sans",
  fontWeight: 400,
  fontSize: "14px",
  lineHeight: "28px",
  color: "#333333",
}));

const StyledDivider = styled(Divider)(({}) => ({
  width: "100%",
  marginBottom: "10px",
  color: "#00000033",
}));

const DateContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "flex-start",
  alignItems: "center",
  marginBottom: "10px",
}));

const ViewDetailsBtn = styled(Button)(
  ({ theme, isMobile, isMobileLandscape, isIpad, isIpadLandscape }) => ({
    color: "#1A73E8",
    textTransform: "none",
    height: "20px",
    fontFamily: "Open Sans",
    fontWeight: 400,
    fontSize: isMobile ? "12px" : "14px",
    lineHeight: "20px",
    paddingTop: "20px",
  })
);

const DowngradeBtn = styled(Button)(
  ({ theme, isMobile, isMobileLandscape, isIpad, isIpadLandscape }) => ({
    color: "#333333",
    textTransform: "none",
    width: isMobile ? "165px" : "212px",
    height: "34px",
    borderRadius: "4px",
    border: "1px solid #333333",
    fontFamily: "Open Sans",
    fontWeight: 400,
    fontSize: isMobile ? "12px" : "14px",
    lineHeight: "24px",
  })
);

const PaymentDetailsContainer = styled(Box)(
  ({
    theme,
    selectedTab,
    isMobile,
    isMobileLandscape,
    isIpad,
    isIpadLandscape,
  }) => ({
    display: selectedTab === true ? "flex" : "none",
    flexDirection: "column",
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
    paddingLeft: isMobile || isMobileLandscape ? "20px" : "unset",
    paddingRight: isMobile || isMobileLandscape ? "20px" : "unset",
  })
);

const PaymentMethodLabel = styled(Typography)(
  ({
    theme,
    isMobile,
    isMobileLandscape,
    isIpad,
    isIpadLandscape,
    isTl,
    trialStarted,
    showUpdateBtn,
  }) => ({
    fontFamily: "Open Sans",
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "19.07px",
    color: "#333333",
    marginBottom: "15px",
    width: isMobile || isMobileLandscape ? "100%" : "100%",
    marginTop: isMobile || isMobileLandscape ? "20px" : "unset",
    textAlign: isMobile ? "unset" : "center",
    marginRight:
      showUpdateBtn === true
        ? isMobile
          ? "unset"
          : isIpadLandscape || isTl || isIpad || isMobileLandscape
          ? "320px"
          : "330px"
        : isMobile
        ? "-30px"
        : isIpadLandscape || isTl || isIpad || isMobileLandscape
        ? "200px"
        : "210px",
    display: trialStarted ? "none" : "unset",
  })
);

const PaymentBtnContainer = styled(Box)(
  ({ theme, isScreenSubscription, trialStarted }) => ({
    display: trialStarted ? "none" : "flex",
    flexDirection: "row",
    width: "100%",
    alignItems: "center",
    justifyContent: "center",
    marginLeft: isScreenSubscription === true ? "unset" : "unset",
  })
);

const PaymentBtn = styled(Button)(({ theme }) => ({
  color: "#333333",
  textTransform: "none",
  width: "330px",
  height: "50px",
  borderRadius: "5px",
  border: "1px solid #7DC242",
  fontFamily: "Open Sans",
  fontWeight: 400,
  fontSize: "16px",
  lineHeight: "21.79px",
  justifyContent: "flex-start",
  paddingLeft: "50px",
}));

const PaymentUpdateBtn = styled(Button)(({ theme }) => ({
  color: "#1A73E8",
  textTransform: "none",
  width: "90px",
  height: "62px",
  fontFamily: "Open Sans",
  fontWeight: 400,
  fontSize: "14px",
  lineHeight: "20px",
  marginLeft: "30px",
}));

const SubscriptionPolicyBtn = styled(Link)(
  ({
    theme,
    showUpdateBtn,
    isMobile
  }) => ({
    cursor: "pointer",
    textDecoration: "none",
    color: "#5e9beb",
    fontSize: "11px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "normal",
    marginTop: "10px",
    marginLeft:
    showUpdateBtn === true
      ? isMobile
        ? "40px"
        : "100px"
      : isMobile
      ? "68%"
      : "220px",
  })
);

const HistoryContainer = styled(Box)(
  ({
    theme,
    selectedTab,
    isScreenSubscription,
    isMobile,
    isMobileLandscape,
    isIpad,
    isIpadLandscape,
    isTl,
  }) => ({
    display: selectedTab === true ? "flex" : "none",
    flexDirection: "row",
    width: "100%",
    height:
      isMobile || isMobileLandscape
        ? "250px"
        : isScreenSubscription === true
        ? "141px"
        : isIpadLandscape || isTl
        ? "300px"
        : "400px",
    overflow: "auto",
    marginTop:
      isMobile || isMobileLandscape
        ? "unset"
        : isScreenSubscription === true
        ? "unset"
        : "-26px",
    justifyContent: isScreenSubscription === true ? "center" : "unset",
    alignItems: isScreenSubscription === true ? "center" : "unset",
  })
);

const ScreenText = styled(Typography)(({ theme }) => ({
  fontFamily: "Open Sans",
  fontWeight: 400,
  fontSize: "18px",
  lineHeight: "24.51px",
  color: "#333333",
  textAlign: "center",
  marginTop: "10px",
}));

const styledTableRow = styled(Box)(({}) => ({
  display: "flex",
  flexDirection: "column",
  fontFamily: "Open Sans",
  fontWeight: 400,
  color: "#333333",
}));

const BillingActionBtn = styled(Button)(({}) => ({
  height: "34px",
  width: "112px",
  cursor: "pointer",
  textTransform: "none",
  fontFamily: "Open Sans",
  fontWeight: 400,
  fontSize: "14px",
  lineHeight: "19.07px",
  color: "#1A73E8",
}));

const MobileNavigationContainer = styled(Box)(
  ({ theme, isMobile, isMobileLandscape, isIpad, isIpadLandscape }) => ({
    width: "100%",
    backgroundColor: "#333333",
    borderTopRightRadius: "4px",
    borderTopLeftRadius: "4px",
    display: isMobile || isMobileLandscape ? "flex" : "none",
    flexDirection: "column",
  })
);

const MobileNavButton = styled(Button)(
  ({
    theme,
    isMobile,
    isMobileLandscape,
    isIpad,
    isIpadLandscape,
    selectedTab,
  }) => ({
    height: "32px",
    width: "100%",
    color: selectedTab === true ? "#7DC242" : "white",
    backgroundColor: "#333333",
    borderBottom: "1px solid #FFFFFF33",
    fontSize: "16px",
    lineHeight: "21.79px",
    fontWeight: 400,
    fontFamily: "Open Sans",
    textTransform: "none",
  })
);

const customStyling = {
  exportModal: {
    position: "absolute",
    top: { xs: "45%", sm: "50%" },
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    borderRadius: 2,
    boxShadow: 24,
    overflow: "hidden",
    maxHeight: "90%",
    border: "none",
  },
};

export default {
  customStyling,
  ModalHeader,
  ModalActions,
  ModalBox,
  ModalContentBox,
  NavigationBar,
  NavLabel,
  NavIcon,
  SubscriptionDetailsContainer,
  SubscriptionCurrentPlanBox,
  SubscriptionCurrentPlanText,
  SubscriptionPlanDetailsBox,
  SubscriptionPlanDetailsText,
  SubscriptionDescText,
  StyledDivider,
  DateContainer,
  ViewDetailsBtn,
  DowngradeBtn,
  PaymentDetailsContainer,
  PaymentMethodLabel,
  PaymentBtnContainer,
  PaymentBtn,
  PaymentUpdateBtn,
  HistoryContainer,
  styledTableRow,
  BillingActionBtn,
  ScreenText,
  MobileNavigationContainer,
  MobileNavButton,
  SubscriptionPolicyBtn,
};
