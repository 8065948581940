import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

import styled from "@mui/system/styled";

import Poppins from "../../../themes/fonts/Poppins-Medium.woff2";
import Checkbox from '@mui/material/Checkbox';
import { Dialog } from "@mui/material";
import backgroundImage from "./background-image";

const screenStats = window.screen;

const mainBoxHeight =
screenStats.availHeight > 650 && screenStats.availHeight <= 700
  ? screenStats.availHeight - 159
  : screenStats.availHeight > 700 && screenStats.availHeight < 750
  ? screenStats.availHeight - 240
  : screenStats.availHeight - 218;

function returnMargin() {

  return  screenStats.availHeight > 650 && screenStats.availHeight <= 700
  ?  159
  : screenStats.availHeight > 700 && screenStats.availHeight < 750
  ?  240
  : 218;

}


function getScreenSize() {
  if (window.matchMedia('(max-width: 575.98px)').matches) {
    return 'xs';
  } else if (window.matchMedia('(min-width: 576px) and (max-width: 767.98px)').matches) {
    return 'sm';
  } else if (window.matchMedia('(min-width: 768px) and (max-width: 991.98px)').matches) {
    return 'md';
  } else if (window.matchMedia('(min-width: 992px) and (max-width: 1199.98px)').matches) {
    return 'lg';
  } else if (window.matchMedia('(min-width: 1200px) and (max-width: 1399.98px)').matches) {
    return 'xl';
  } else if (window.matchMedia('(min-width: 1400px) and (max-width: 1599.98px)').matches) {
    return 'xxl';
  } else if (window.matchMedia('(min-width: 1600px) and (max-width: 1799.98px)').matches) {
    return 'xxxl';
  } else if (window.matchMedia('(min-width: 1800px)').matches) {
    return 'xxxxl';
  } else {
    return 'unknown';
  }
}

function getLogoImgWH() {
  let size = getScreenSize();
  let data = {};
  data.width="380";
  data.height="380";
  if(size == "sm" || size == "xs") {
    data.width =  "300";
    data.height = "260";
  }

  if(size == "md" || size == "lg") {
    data.width =  "340";
    data.height = "300";
  }
  return data;
}

function getmodolHeightWidth(isIpad) {
  let size = getScreenSize();
  let data = {};
  if(window.matchMedia('(min-width: 800px) and (max-width: 1400px) and (orientation: landscape) and (hover: none) and (pointer: coarse)').matches){
    data.width= 1060+"px";
    data.height=580+"px";
  }
  else{
    data.width= 1100+"px";
    data.height=580+"px";
  }

  

  return data;
}

const DescDiv =styled(Grid)(({ theme })=>( {

  position: "absolute", 
  marginTop: getLogoImgWH().height/1.58+"px", 
  marginLeft: "5%" 

}));


const contianer = styled(Grid)(({ theme,isIpad })=>( {
  width:getmodolHeightWidth(isIpad).width,
  // marginTop:getmodolHeightWidth().marginTop ,
  height: getmodolHeightWidth(isIpad).height,
  top: `50%`,
  left: `50%`,
  position:"absolute",
  transform: `translate(-50%, -50%)`,
  // marginLeft:getmodolHeightWidth().marginLeft,
  borderRadius: "30px !important",
  backgroundImage:`url(${backgroundImage})`,
  backgroundSize: "cover",
  

  // [theme.breakpoints.up("xmd")]: {
  //   height:"75% !important",
  // },

  // [theme.breakpoints.down("xxl")]: {
  //   height:"100% !important",
  // },
  // [theme.breakpoints.up("xxl")]: {
  //   height:"95% !important",
  // },
 
  // item: {
  //   gridArea: '1 / 1 / 2 / 2', // this will position the item at the top left corner
  // },
 }));



//  const contianer = styled(Grid)(({ theme })=>({

//   width:"110%",
//   height:"110%",
//   padding:0,
//   margin:"-18%",
//   marginLeft:"-55%",
//   backgroundImage:"url(/svgs/logo-bglogo1.svg)",
//   backgroundSize: '120% 90%',
//   "background-repeat": "no-repeat",

//  }));

 const Logo = styled(Grid)(({ theme })=>({
     width:"50%",
    height:"70%",    
 }));
 const Slides = styled(Grid)(({ theme })=>({
  width:"100%",
  height:"100%",
}));
const SlidesImage = styled(Grid)(({ theme })=>({
  
  marginTop: (getLogoImgWH().height *0.13)+"px", 
  justifyContent: "center",
}));


 const modalStyle = styled(Dialog)(({ theme })=>({

  // width: "80%",
  // height: "70%",
  // bgcolor: 'background.paper',

  // backgroundImage:"linear-gradient(90deg, rgba(78,15,137,0.8604691876750701) 0%, rgba(125,0,186,0.9276960784313726) 17%, rgba(84,45,135,1) 100%)",

  // // boxShadow: 24,
  // borderRadius: "30px",
  // // backgroundImage:'url(/svgs/logo-bglogo1.svg)',
  // backgroundPosition: "left",
  // "background-repeat": "no-repeat",
  // "background-attachment": "fixed",
  // "background-position": "center",
  // backgroundRepeat: "no-repeat",
  // backgroundColor:"rgba(125,0,186,0.9276960784313726) !important",
  // p: 4,
  // outline: 'none',
  // overflow: "hidden",

  // [theme.breakpoints.up("tl6")]: {
  //   top:"42%",
  // },
  // [theme.breakpoints.up("sm")]: {
  //   top:"46%",
  // },
  // [theme.breakpoints.up("xxl")]: {
  //   top:"52%",
  // },


  
}));
const SplashScreenDesc =  styled(Grid)(({ theme })=>({

  "fontsize": "1.078rem",
 }));

const logoImage = styled('img')(({ theme }) =>({


  width:getLogoImgWH().width+"px",
  maxHeight:getLogoImgWH().height+"px",
  "margin-left": "0%",
  "margin-top": "0",
  position: "absolute"
  
}));
const descText2 = styled('Typography')(({ theme }) =>({
  fontSize:"0.95rem",
  marginTop:5
  // [theme.breakpoints.down("sm")]: {
  //   fontSize:"0.7rem"
  // },
  // [theme.breakpoints.up("xmd")]: {
  //   fontSize:"0.9rem"
  // },
  // [theme.breakpoints.up("md")]: {
  //   fontSize:"0.9rem"
  // },

  // [theme.breakpoints.up("lg")]: {
  //   fontSize:"0.9rem"
  // },
  // [theme.breakpoints.up("xl")]: {
  //   fontSize:"0.9rem"
  // },
  // [theme.breakpoints.up("xxl")]: {
  //   fontSize:"0.9rem"
  // },
  // [theme.breakpoints.up("xxxl")]: {
  //   fontSize:"0.9rem"
  // },
}));



const descText = styled('Typography')(({ theme }) =>({
  marginRight:100,
  marginLeft:"1%",
  fontSize:"1.2rem"
  //  [theme.breakpoints.down("sm")]: {
  //    fontSize:10
  //  },
  //  [theme.breakpoints.up("xmd")]: {
  //    fontSize:11
  //  },
  //  [theme.breakpoints.up("md")]: {
  //    fontSize:16
  //  },
 
  //  [theme.breakpoints.up("lg")]: {
  //    fontSize:16
  //  },
  //  [theme.breakpoints.up("xl")]: {
  //    fontSize:18
  //  },
  //  [theme.breakpoints.up("xxl")]: {
  //    fontSize:19
  //  },
  //  [theme.breakpoints.up("xxxl")]: {
  //    fontSize:25
  //  },
  
 }));
 

const StyledDotDiv = styled('div')(({ theme }) =>({
  display:"flex",
  flexDirection:"row",
  justifyContent:"center",
  height:60,
  marginBottom:"10px"
 

}));

const StyledSlideDesc = styled(Typography)(({theme})=>({
  fontFamily: 'Poppins',
  textAlign: 'center',
  color: '#7DC242',
  display:'flex',
  justifyContent: "center",
  height: 70,

  marginTop: "30px", 
  
  '@font-face': {
    fontFamily: 'Poppins',
    src: `url(${Poppins}) format('woff2')`,
    fontWeight: 'normal',
    fontStyle: 'normal',
  },

  fontSize: '18px',
  fontWeight: '400',
  fontSize:"1.29rem"

}))
const buttonDiv = styled('div')(({theme})=>({
  marginTop:"55px",
  marginLeft: "10px"
}));

const StyledCheckbox = styled(Checkbox)({
  '&.consent-checkbox .MuiSvgIcon-root': {
    color: '#7DC242',
  },
});

const StyledUnCheckedIcon = styled("div")({
  borderRadius: 3, 
  border: '2px solid #7DC242', 
  width: 22,
  height: 22
})
 export default {

  contianer,
  modalStyle,
  Logo,
  logoImage,
  SplashScreenDesc,
  Slides,
  SlidesImage,
  StyledSlideDesc,
  StyledDotDiv,
  descText,
  buttonDiv,
  StyledCheckbox,
  StyledUnCheckedIcon,
  descText2,
  DescDiv
 }