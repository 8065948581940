import { useState } from "react";
import Menu from "@mui/material/Menu";
import Styled from "./styles";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import { Box, Typography } from "@mui/material";
import intlMessages from "../../locales/en.js";
import { ArrowDownward } from "@mui/icons-material";
import { useRef } from "react";
import {
  setMobileSortLabel,
  setMobileSortingLoader,
  setSortOrder,
} from "../../redux/slices/librarySlice";
import { useDispatch, useSelector } from "react-redux";

export default function SortMenu(props) {
  const { isMobileLandscape } = props;
  const { sortBy, order } = useSelector((state) => state.library);
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [toggleFlag, setToggleFlag] = useState(false);
  const [toggleDateFlag, setToggleDateFlag] = useState(false);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <Styled.IconBtn isMobileLandscape={isMobileLandscape} onClick={handleClick}>
        <img src="/svgs/arrow-up.svg" />
      </Styled.IconBtn>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            marginLeft: "-40px",
            backgroundColor: "white",
            width: isMobileLandscape?"unset":"113px",
            // height:'160px'
          },
        }}
      >
        <Styled.menuItem disableRipple isMobileLandscape={isMobileLandscape} sx={{ borderBottom: "1px solid #00000080" }}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Typography sx={{ fontSize: "13px", lineHeight: "17.7px" }}>
              {intlMessages.quizLibrarySortByText}
            </Typography>
          </Box>
        </Styled.menuItem>
        <Styled.menuItem isMobileLandscape={isMobileLandscape} sx={{ borderBottom: "1px solid #0000001A" }}>
          <Box
            onClick={() => {
              setToggleFlag(!toggleFlag);
              dispatch(
                setSortOrder({
                  sortBy: "title",
                  order: toggleFlag === false ? 1 : -1,
                })
              );
              dispatch(setMobileSortLabel(intlMessages.libraryQuizTitle));
              dispatch(setMobileSortingLoader(true))
              setAnchorEl(null);
            }}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Styled.IconBtn
              sx={{
                marginRight: "10px",
                color: "#7DC243",
                display: order !== "" && sortBy === "title" ? "flex" : "none",
              }}
            >
              {order === -1 && sortBy === "title" ? (
                <ArrowDownward sx={{ fontSize: "20px" }} />
              ) : (
                <ArrowUpwardIcon sx={{ fontSize: "20px" }} />
              )}
            </Styled.IconBtn>
            <Typography
              sx={{
                fontSize: "13px",
                lineHeight: "17.7px",
                color: sortBy === "title" ? "#7DC243" : "#333333",
              }}
            >
              {intlMessages.libraryQuizTitle}
            </Typography>
          </Box>
        </Styled.menuItem>
        <Styled.menuItem 
        isMobileLandscape={isMobileLandscape}
          onClick={() => {
            dispatch(
              setSortOrder({
                sortBy: "createdAt",
                order: toggleDateFlag === false ? 1 : -1,
              })
            );
            setToggleDateFlag(!toggleDateFlag);
            dispatch(setMobileSortLabel(intlMessages.libraryCreatedAtLabel));
            dispatch(setMobileSortingLoader(true))
            setAnchorEl(null);
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Styled.IconBtn
              sx={{
                marginRight: "10px",
                color: "#7DC243",
                display:
                  order !== "" && sortBy === "createdAt" ? "flex" : "none",
              }}
            >
              {order === -1 && sortBy === "createdAt" ? (
                <ArrowDownward sx={{ fontSize: "20px" }} />
              ) : (
                <ArrowUpwardIcon sx={{ fontSize: "20px" }} />
              )}
            </Styled.IconBtn>
            <Typography
              sx={{
                fontSize: "13px",
                lineHeight: "17.7px",
                color: sortBy === "createdAt" ? "#7DC243" : "#333333",
              }}
            >
              {intlMessages.libraryCreatedAtLabel}
            </Typography>
          </Box>
        </Styled.menuItem>
      </Menu>
    </div>
  );
}
